<app-header></app-header>
<section class="ensureGlueProductsContainer" *ngIf="subCatCode == '4001'">
  <div class="bannerOut">
    <img
      class="img-fluid"
      src="../../../assets/images/pediasure-product/hero-banner.png"
      alt="hero-banner"
    />
  </div>
  <section class="ensureBgOut">
    <div class="container">
      <div class="row mt40">
        <div class="col-12">
          <h1 class="darkPurpleForeColor">pediasure hương vani</h1>
        </div>
      </div>
    </div>
    <div class="pediasureBg">
      <div class="topProdOut">
        <i class="pediasureVanila">
          <img
            class="img-fluid"
            src="../../../assets/images/pediasure-vanila/pediasure-vanila.png"
            alt="pediasure-vanila"
          />
        </i>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <small class="whiteTxt text-center d-block mb20 mt40 greenHealthTxt"
              >Thực phẩm dinh dưỡng y học</small
            >
            <p class="whiteTxt font-italic SiBrandonGrotesqueBold">
              Thông tin dưới đây là thông tin trên nhãn được cơ quan có thẩm
              quyền phê duyệt
            </p>
            <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">
              Dinh dưỡng đầy đủ, cân đối+ giúp trẻ tăng trưởng tốt.
            </p>
            <p class="whiteTxt">
              Được phát triển liên tục từ năm 1988,
              <strong>PediaSure<sup>®</sup></strong> là dinh dưỡng bổ sung dành
              cho trẻ được đặc chế khoa học để đáp ứng nhu cầu dinh dưỡng đặc
              biệt của trẻ đang tăng trưởng.
              <strong>PediaSure<sup>®</sup></strong> mới được bổ sung thêm
              arginin và vitamin K2 tự nhiên cùng đầy đủ các chất dinh đưỡng
              thiết yếu giúp trẻ nhanh chóng bắt kịp đà tăng trưởng khoẻ mạnh.
              Các nghiên cứu lâm sàng trong hơn 25 năm qua đã chứng minh
              <strong>PediaSure<sup>®</sup></strong> giúp trẻ thiếu dinh dưỡng
              hay có nguy cơ thiếu hụt dinh dưỡng đạt được mức tăng trưởng và
              phát triển tốt.<br />
              Đáp ứng 100% DRI Hoa Kỳ về nhu cầu hàng ngày của đạm cùng 25
              vitamin và khoáng chất với 1.000 ml (trẻ 1-8 tuổi) và 1.500 ml
              (trẻ 9-13 tuổi).
            </p>
            <small class="whiteTxt"
              >+ Theo FDA và luật cộng đồng Châu Âu 1999/21/EC</small
            >
            <div class="text-center mt30 mb20 d-flex justify-content-center">
              <a
                href=" https://c.lazada.vn/t/c.063oUW?intent=false&fallback=true&url=https%3A%2F%2Fwww.lazada.vn%2Fshop%2Fpediasure-chinh-hang"
                target="_blank"
                class="orangeBtn boxShadaow plr"
                >Mua ngay</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="greenHealthBg mt40">
      <div class="grayLinearBg mt66">
        <div class="carouselOut pediasureCarousel">
          <h2>hệ dưỡng chất triplesure</h2>
          <div
            id="carouselIndicatorsPedia"
            class="carousel slide"
            data-ride="carousel"
            data-interval="false"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="contentOut">
                  <img
                    class="img-fluid"
                    src="../../../assets/images/pediasure-product/f1.png"
                    alt="dam"
                  />
                  <div class="carousel-caption d-md-block">
                    <p class="mt-3">
                      Giàu đạm, canxi, vitamin D, bổ sung thêm arginin và
                      vitamin K<sub>2</sub> tự nhiên giúp tăng chiều cao tốt
                      hơn.
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="contentOut">
                  <img
                    class="img-fluid"
                    src="../../../assets/images/pediasure-product/f2.png"
                    alt="28"
                  />
                  <div class="carousel-caption d-md-block">
                    <p class="mt-3">
                      Dinh dưỡng đầy đủ, cân đối giúp tăng cân khoẻ mạnh.
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="contentOut">
                  <img
                    class="img-fluid"
                    src="../../../assets/images/pediasure-product/f3.png"
                    alt="fos"
                  />
                  <div class="carousel-caption d-md-block">
                    <div class="relativePos">
                      <p>
                        Prebiotic (chất xơ FOS) và Probiotic (các vi sinh vật có
                        lợi) giúp tăng cường miễn dịch và giảm số ngày ốm của
                        trẻ<sup>**</sup>.
                      </p>
                      <small class="d-block blackColor text-justify"
                        ><sup>**</sup> Nghiên cứu trên trẻ có nguy cơ thiếu dinh
                        dưỡng</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ol class="carousel-indicators darkPurpleIndicators">
              <li
                data-target="#carouselIndicatorsPedia"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#carouselIndicatorsPedia" data-slide-to="1"></li>
              <li data-target="#carouselIndicatorsPedia" data-slide-to="2"></li>
            </ol>
            <a
              class="carousel-control-prev darkPurpleGradient"
              href="#carouselIndicatorsPedia"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              class="carousel-control-next darkPurpleGradient"
              href="#carouselIndicatorsPedia"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </a>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="text-center mt70 pt-3">
                  Vitamin và khoáng chất thiết yếu giúp trẻ ăn ngon miệng. DHA,
                  cholin và taurin giúp phát triển não bộ và thị giác.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="accordionOut mt20">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div id="accordion">
                  <div class="card">
                    <div class="card-header blueberry" id="headingOne">
                      <a
                        href="javascript:;"
                        class="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Công dụng
                      </a>
                    </div>
                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                          <strong>PediaSure<sup>®</sup></strong> bổ sung dinh
                          dưỡng đầy đủ và cân đối, vitamin, khoáng chất giúp
                          phục hồi sức khỏe, tăng trưởng tốt.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header blueberry" id="headingTwo">
                      <a
                        href="javascript:;"
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Đối tượng sử dụng
                      </a>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                          <strong>PediaSure<sup>®</sup></strong> dành cho trẻ 1
                          – 10 tuổi, đặc biệt phù hợp với:
                        </p>
                        <ul>
                          <li>
                            - Trẻ tăng trưởng kém (trẻ ốm, nhẹ cân, thấp còi,
                            suy dinh dưỡng).
                          </li>
                          <li>- Trẻ biếng ăn, ăn không ngon miệng.</li>
                          <li>- Trẻ có nhu cầu năng lượng tăng cao</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header blueberry" id="headingThree">
                      <a
                        href="javascript:;"
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        hướng dẫn sử dụng & Bảo quản
                      </a>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                    >
                      <div class="card-body">
                        <p>
                          <img
                            class="img-fluid"
                            src="../../../assets/images/pediasure-product/tri-circle.png"
                            alt="tri-circle"
                          />
                        </p>
                        <p class="SiBrandonGrotesqueBold text-center">
                          1 ly pha chuẩn (225 ml) = 48,6 g bột + 190 ml nước
                        </p>
                        <h5 class="mt20 darkPurpleForeColor">
                          hướng dẫn sử dụng
                        </h5>
                        <p class="text-justify">
                          Để có 225 ml
                          <strong>PediaSure<sup>®</sup></strong> pha chuẩn, cho
                          190 ml nước chín để nguội (≤37<sup>°</sup>C) vào ly.
                          Vừa cho từ từ 5 muỗng gạt ngang bột
                          <strong>PediaSure<sup>®</sup></strong> (muỗng có sẵn
                          trong hộp) vừa khuấy cho tan đều. Để bổ sung dinh
                          dưỡng: 2 ly/ ngày (trẻ 1- 8 tuổi), 2-3 ly/ ngày (trẻ 9
                          - 10 tuổi) hoặc theo hướng dẫn của chuyên viên dinh
                          dưỡng. <br />
                          Thay thế hoàn toàn bữa ăn qua ống thông cho trẻ ốm:
                          theo hướng dẫn của Bác sĩ/ chuyên viên dinh dưỡng.
                        </p>
                        <h5 class="mt20 darkPurpleForeColor">Chú ý</h5>
                        <p class="text-justify">
                          Để tối ưu hiệu quả của vi sinh vật có lợi (probiotic),
                          nên pha <strong>PediaSure<sup>®</sup></strong> với
                          nước đun sôi để nguội (≤37<sup>°</sup>C). Không được
                          hâm nóng <strong>PediaSure<sup>®</sup></strong> bằng
                          lò vi sóng vì có thể gây bỏng.
                        </p>
                        <h5 class="mt20 darkPurpleForeColor">
                          HIỆU QUẢ CỦA PEDIASURE ĐÃ ĐƯỢC NGHIÊN CỨU LâM SÀNG
                          CHỨNG MINH
                        </h5>
                        <p class="text-justify">
                          Nghiên cứu lâm sàng trên nhóm trẻ biếng ăn có nguy cơ
                          thiếu hụt dinh dưỡng đã chứng minh việc bổ sung
                          <strong>PediaSure<sup>®</sup></strong> cùng với chế độ
                          dinh dưỡng hàng ngày giúp trẻ phát triển tốt hơn về
                          chiều cao và cân nặng so với chế độ dinh dưỡng thông
                          thường.<sup>‡</sup>
                        </p>
                        <small>
                          (*) Khảo sát của IQVIA từ tháng 01/2020 đến tháng
                          12/2020. <br />
                          (‡) Alarcon et al. Effect of oral supplementation on
                          catch-up growth in picky eater. Clin Pediatr. (2003)
                        </small>
                        <h5 class="mt20 darkPurpleForeColor">
                          SỬ DỤNG QUA ỐNG THÔNG
                        </h5>
                        <p class="text-justify">
                          <strong>PediaSure<sup>®</sup></strong> thích hợp để
                          dùng uống hoặc nuôi ăn qua ống thông. Khi dùng qua ống
                          thông hay dùng làm nguồn nuôi ăn duy nhất cho bệnh
                          nhân, cần tuân theo sự hướng dẫn của Bác sĩ/ chuyên
                          viên dinh dưỡng.
                        </p>
                        <h5 class="mt20 darkPurpleForeColor">Bảo quản</h5>
                        <p class="text-justify">
                          Bảo quản hộp chưa mở ở nhiệt độ phòng. Hộp đã mở phải
                          được đậy kín và bảo quản ở nơi khô mát (nhưng không
                          cho vào tủ lạnh) và sử dụng trong vòng 3 tuần.
                          <strong>PediaSure<sup>®</sup></strong> vừa pha phải
                          được dùng ngay hay đậy kín, cho vào tủ lạnh và dùng
                          trong vòng 24 giờ.<br /><br />
                          Không chứa Gluten. Rất ít Lactose, phù hợp cho người
                          bất dung nạp Lactose.<br /><br />
                          Không dùng cho trẻ bị bệnh Galactosemia.<br /><br />
                          Không dùng cho trẻ dưới 1 tuổi, trừ khi được Bác sĩ
                          chỉ định.<br /><br />
                          Tham khảo Bác sĩ/ chuyên viên dinh dưỡng về nhu cầu
                          dinh dưỡng cụ thể của con bạn.
                        </p>
                        <h5 class="mt20 darkPurpleForeColor">
                          KHÔNG DÙNG NUÔI ĂN QUA ĐƯỜNG TĨNH MẠCH
                        </h5>
                        <p>
                          Sử dụng cho người bệnh với sự giám sát của nhân viên y
                          tế.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header blueberry" id="headingFour">
                      <a
                        href="javascript:;"
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Thành phần & Phân tích thành phần
                      </a>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                    >
                      <div class="card-body">
                        <h5 class="mt20 darkPurpleForeColor">Thành phần</h5>
                        <p class="text-justify">
                          Tinh bột bắp thủy phân, ĐẠM (đạm sữa cô đặc, đạm đậu
                          nành tinh chế), DẦU (dầu hướng dương giàu oleic, dầu
                          đậu nành, dầu hạt cải, dầu MCT (triglycerid chuỗi
                          trung bình)), fructo-oligosaccharid
                          <strong class="font-italic">(prebiotic)</strong>,
                          KHOÁNG CHẤT (canxi phosphat, kali clorid, magiê
                          phosphat, kali citrat, natri citrat, kali phosphat,
                          natri clorid, canxi carbonat, sắt sulfat, kẽm sulfat,
                          mangan sulfat, đồng sulfat, kali iodid, crôm clorid,
                          natri selenit, natri molybdat), fructose, chất nhũ hóa
                          lecithin đậu nành, hương vani tổng hợp, hương sữa
                          giống tự nhiên, <strong>L-arginin</strong>, acid
                          docosahexaenoic (DHA) từ dầu C. cohnii, cholin clorid,
                          VITAMIN (acid ascorbic, dl-alpha tocopheryl acetat,
                          d-canxi pantothenat, niacinamid, pyridoxin
                          hydroclorid, thiamin hydroclorid, riboflavin, Vitamin
                          A palmitat, acid folic, phylloquinon, biotin, Vitamin
                          D3, menaquinon-7
                          <strong>(Vitamin K<sub>2</sub> tự nhiên)</strong>,
                          cyanocobalamin), ascorbyl palmitat, taurin,
                          m-inositol, L-carnitin, Lactobacillus acidophilus
                          <strong class="font-italic">(prebiotic)</strong>, hỗn
                          hợp tocopherol.<br /><br />
                          1 lít <strong>PediaSure<sup>®</sup></strong> hương
                          vani ít ngọt pha chuẩn cần 216 gram bột.
                        </p>
                        <img
                          class="img-fluid"
                          src="../../../assets/images/pediasure-product/pedisure-vanila-table.png"
                          alt="pedisure-vanila-table"
                        />
                        <small class="d-block mt10"
                          >(1) Mức đáp ứng RNI cho các lứa tuổi khác tham khảo
                          tại www.pediasure.com</small
                        >
                        <h5 class="mt20 darkPurpleForeColor">
                          kiểm tra Ngày sản xuất (MFG) & hạn sử dụng (EXP) ở đáy
                          hộp.
                        </h5>
                        <address>
                          <strong>Sản xuất tại: </strong><br />
                          Abbott Manufacturing Singapore Private Limited 26
                          Tuas<br />
                          South Avenue 10, Singapore 637437<br />
                          A subsidiary of Abbott Laboratories, North Chicago,
                          IL<br />
                          60064, USA<br /><br />

                          <strong>Đăng ký bởi: </strong><br />
                          VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A,
                          toà<br />
                          nhà Handi Resco, 521 Kim Mã, Ba Đình, Hà Nội.<br /><br />

                          <strong>Nhập khẩu & phân phối:</strong><br />
                          Công ty TNHH Dinh dưỡng 3A (Việt Nam)<br />
                          Centec Tower, 72-74 Nguyễn Thị Minh Khai,<br />
                          phường 6, quận 3, thành phố Hồ Chí Minh
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
<!--Pediasure10+ start--->
<section class="ensureGlueProductsContainer pediasure10Container" *ngIf="subCatCode == '4002'">
  <div class="bannerOut">
    <img
      class="img-fluid"
      src="../../../assets/images/pediasure-product/pediasure10/hero-banner-pediasure10.png"
      alt="hero-banner"
    />
  </div>
  <section class="ensureBgOut">
    <div class="container">
      <div class="row mt40">
        <div class="col-12">
          <img
            class="headline"
            src=".../../../assets/images/pediasure-product/pediasure10/headline-pediasure10.png"
          />
        </div>
      </div>
    </div>
    <div class="pediasureBg">
      <div class="topProdOut">
        <i class="pediasureVanila">
          <img
            class="img-fluid"
            src=".../../../assets/images/pediasure-product/pediasure10/product-img-pediasure10.png"
            alt="pediasure-10+"
          />
        </i>
      </div>
      <div>
        <div class="row">
          <div class="col-12">
            <img
              class="img-fluid"
              src=".../../../assets/images/pediasure-product/pediasure10/intro-pediasure10.png"
              alt="intro-pediasure10"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="greenHealthBg">
      <div class="grayLinearBg">
        <div class="carouselOut pediasureCarousel">
          <div
            id="carouselIndicatorsPediaPlus"
            class="carousel slide"
            data-ride="carousel"
            data-interval="false"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="contentOut">
                  <img
                    class="img-fluid"
                    src=".../../../assets/images/pediasure-product/pediasure10/item-1-tang-chieu-cao-pediasure10.png"
                    alt="item-1-tang-chieu-cao-pediasure10"
                  />
                </div>
              </div>
              <div class="carousel-item">
                <div class="contentOut">
                  <img
                    class="img-fluid"
                    src=".../../../assets/images/pediasure-product/pediasure10/item-2-tang-can-nang-pediasure10.png"
                    alt="item-2-tang-can-nang-pediasure10"
                  />
                </div>
              </div>
              <div class="carousel-item">
                <div class="contentOut">
                  <img
                    class="img-fluid"
                    src=".../../../assets/images/pediasure-product/pediasure10/item-3-tang-suc-de-khang-pediasure10.png"
                    alt="item-3-tang-suc-de-khang-pediasure10"
                  />
                </div>
              </div>
            </div>
            <ol class="carousel-indicators darkPurpleIndicators">
              <li
                data-target="#carouselIndicatorsPediaPlus"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#carouselIndicatorsPediaPlus" data-slide-to="1"></li>
              <li data-target="#carouselIndicatorsPediaPlus" data-slide-to="2"></li>
            </ol>
            <a
              class="carousel-control-prev darkPurpleGradient"
              href="#carouselIndicatorsPediaPlus"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              class="carousel-control-next darkPurpleGradient"
              href="#carouselIndicatorsPediaPlus"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </a>
          </div>
        </div>
        <div class="accordionOut mt20">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div id="accordion">
                  <div class="card">
                    <div class="card-header blueberry" id="headingPlusOne">
                      <a
                        href="javascript:;"
                        class="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapsePlusOne"
                        aria-expanded="true"
                        aria-controls="collapsePlusOne"
                      >
                        Đối tượng sử dụng
                      </a>
                    </div>
                    <div
                      id="collapsePlusOne"
                      class="collapse show"
                      aria-labelledby="headingPlusOne"
                    >
                      <div class="card-body">
                        <img
                          class="img-fluid"
                          src=".../../../assets/images/pediasure-product/pediasure10/pedaisure10-plus-accordian-1.png"
                          alt="pedaisure10-plus-accordian-1"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header blueberry" id="headingPlusThree">
                      <a
                        href="javascript:;"
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapsePlusThree"
                        aria-expanded="false"
                        aria-controls="collapsePlusThree"
                      >
                        hướng dẫn sử dụng & Bảo quản
                      </a>
                    </div>
                    <div
                      id="collapsePlusThree"
                      class="collapse"
                      aria-labelledby="headingPlusThree"
                    >
                      <div class="card-body">
                        <p>
                          <img
                            class="img-fluid"
                            src=".../../../assets/images/pediasure-product/pediasure10/tri-circle-pediasure10.png"
                            alt="tri-circle"
                          />
                        </p>
                        <img
                          class="img-fluid"
                          src=".../../../assets/images/pediasure-product/pediasure10/tri-circle-pediasure10-content-1.png"
                          alt="tri-circle"
                        />

                        <h5 class="mt20 darkPurpleForeColor">Chú ý</h5>
                        <p class="text-justify">
                          Không được hâm nóng
                          <img
                            class="img-pediasure-icon"
                            src=".../../../assets/images/pediasure-product/pediasure10/pedaisure10-plus-icon.png"
                            alt="pedaisure10-plus-icon"
                          />
                          đã pha bằng lò vi sóng vì có thể gây bỏng.
                        </p>
                        <h5 class="mt20 darkPurpleForeColor">Bảo quản</h5>

                        <p class="text-justify">
                          Bảo quản hộp chưa mở ở nhiệt độ phòng. Hộp đã mở phải
                          được đậy kín và bảo quản ở nơi khô mát (nhưng không
                          cho vào tủ lạnh) và sử dụng trong vòng 3 tuần.
                          <img
                            class="img-pediasure-icon"
                            src=".../../../assets/images/pediasure-product/pediasure10/pedaisure10-plus-icon.png"
                            alt="pedaisure10-plus-icon"
                          />
                          vừa pha phải được dùng ngay hay đậy kín cho vào tủ
                          lạnh và dùng trong vòng 24 giờ.
                        </p>
                        <p class="text-justify">
                          Không chứa gluten, phù hợp cho người bất dung nạp
                          gluten.
                        </p>
                        <p class="text-justify">
                          Không dùng cho trẻ bị bệnh Galactosemia.
                        </p>
                        <p class="text-justify">
                          Không dùng nuôi qua đường tĩnh mạch.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header blueberry" id="headingPlusFour">
                      <a
                        href="javascript:;"
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapsePlusFour"
                        aria-expanded="false"
                        aria-controls="collapsePlusFour"
                      >
                        Thành phần & Phân tích thành phần
                      </a>
                    </div>
                    <div
                      id="collapsePlusFour"
                      class="collapse"
                      aria-labelledby="headingPlusFour"
                    >
                      <div class="card-body">
                        <p class="text-justify">
                          <img
                            class="img-fluid"
                            src=".../../../assets/images/pediasure-product/pediasure10/than-pan-pediasure10-content.png"
                            alt="than-pan-pediasure10-content"
                          />
                        </p>
                        <img
                          class="img-fluid mb-4"
                          src=".../../../assets/images/pediasure-product/pediasure10/pedisure10-plus-table.png"
                          alt="pedisure10-plus-table"
                        />

                        <address>
                          <strong>Sản xuất tại: </strong><br />
                          Abbott Manufacturing Singapore Private Limited<br />
                          26 Tuas South Avenue 10, Singapore 637437<br />
                          A subsidiary of Abbott Laboratories, North Chicago,<br />
                          IL 60064, USA.<br /><br />

                          <strong>Xuất xứ:</strong> Singapore.<br />
                          <strong> Công bố bởi: </strong>
                          VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A, toà
                          nhà Handi Resco, 521 Kim Mã, Ba Đình, Hà Nội.<br /><br />

                          <strong>Nhập khẩu và phân phối bởi:</strong><br />
                          Công ty TNHH Dinh Dưỡng 3A (Việt Nam),<br />
                          Centec Tower, 72-74 Nguyễn Thị Minh Khai,<br />
                          phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh.
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
<!--Pediasure10+ end--->

<div class="manufacturerShareAndCareContainer">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="mb-0 careShareText text-uppercase">care & share</h3>
        <h3 class="homeText text-uppercase">home</h3>
        <p>Tham gia để nhận được <br /><strong>các lợi ích hấp dẫn</strong></p>
        <button class="orangeBtn" routerLink="/sign-up/1">Đăng ký ngay</button>
        <p>
          Liên hệ hotline <a class="telNum" href="tel:19001519">19001519 </a
          ><br />để biết thêm chi tiết <br /><small>PED-C-76-22</small>
        </p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<app-nav-menu></app-nav-menu>
