<div class="navBarContainer">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-6">
                <a routerLink="/profile-home" class="navbar-brand"><img src="../../../assets/images/share_care_logo.png" alt="logo" /></a>
            </div>
            <div class="col-6 text-right">
                <!-- <a href="https://nutrition.abbott/vn" class="navbar-brand"><img src="../../../assets/images/abbot-logo-copy.png" alt="logo" /></a> -->
                <a href="javascript:;" class="navbar-brand"><img src="../../../assets/images/abbot-logo-copy.png" alt="logo" /></a>
            </div>
        </div>
    </div>
</div>