
import { Component, OnInit, ChangeDetectorRef,ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import * as moment from 'moment';
import 'moment/locale/es'
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-crm-signup',
  templateUrl: './crm-signup.component.html',
  styleUrls: ['./crm-signup.component.scss']
})
export class CrmSignupComponent implements OnInit {

  crmSignUpForm: FormGroup;
  submitted = false;
  milkCodeDetails: any = [];
  milkCode: any;
  subCatCode: any;
  content: any = [];
  scancodeData: any;
  mobile_no: any;
  sessionIdValue: any;
  formArr: FormGroup
  prodCategory: any;
  registerForm: any = {};
  present: any;
  storeRepeated: any = [];
  emailInValid: any;
  mandatoryCheck = true;
  dateError: Boolean = false;
  yearList = [];
  motherDOByear = [];
  is_milk: Boolean = false;
  track_milkCode: any = {};
  show: any;
  show1: any;
  isGeneric: any;
  yearList1: any;
  isPregnent: boolean = true;
  adultCheck: boolean = false;
  hiddenBtn: boolean = false;
  formarrayone: any[];
  locale = 'vi';
  locales = listLocales();
  currentDate:any;
  maxDate: Date;
  iSprintData: any;

  daysInMonth: number[];
  months: number[];
  years: number[];
  selectedYear:any;
  selectedMonth:any;
  yearList2: any;
  @ViewChild('privacyPolicyModal', { static: true }) privacyPolicyModal: ModalDirective;
  constructor(private formBuilder: FormBuilder, private apiService: ApiService,
    private toastr: ToastrService, private localeService: BsLocaleService, private spinner: NgxSpinnerService,  private route: ActivatedRoute, private router: Router, private cdref: ChangeDetectorRef, private bsService: BugsnagService) {
    window.scrollTo({ top: 0 });
    this.localeService.use(this.locale);
    this.currentDate = moment.locale('vi');
    this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    console.log(this.iSprintData);
    this.prodCategory = this.iSprintData?.category_code
    this.isGeneric = sessionStorage.getItem('isGeneric');

    console.log(this.isGeneric, 'this.isGeneric')
    console.log(this.adultCheck, 'this.adultCheck')
    if(this.route.snapshot.params.id == 'removeGeneric'){
      sessionStorage.removeItem('isGeneric');
      console.log(sessionStorage.getItem('isGeneric'));

    }

    

  }

  ngOnInit(): void {
    this.spinner.hide()
    this.crmSignUpForm = this.formBuilder.group({
      phoneNumber: this.isGeneric == 'true' ? [''] : ['', Validators.required],
      firstName: this.isGeneric == 'true' ? [''] : ['', Validators.required],
      lastName: this.isGeneric == 'true' ? [''] : ['', Validators.required],
      email: this.isGeneric == 'true' ? [''] : ['', [Validators.email]],
      password: this.isGeneric == 'true' ? [''] : ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$')
      ]],
      confirmPassword: this.isGeneric == 'true' ? [''] : ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$')]],
      mandatoryCheck: [true, Validators.requiredTrue],
    
      // dob_day: (this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') ? ['', Validators.required] : [''],
      // dob_month: (this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') ? ['', Validators.required] : [''],
      // dob_year: (this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') ? ['', Validators.required] : [''],

      dob_day:(this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') ? ['', Validators.required] : [''],
      dob_month:(this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') ? ['', Validators.required] : [''],
      dob_year:(this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') ? ['', Validators.required] : [''],

      parents_dob_day: this.prodCategory == 'Grow' ? ['', Validators.required] : [''],
      parents_dob_month: this.prodCategory == 'Grow' ? ['', Validators.required] : [''],
      parents_dob_year: this.prodCategory == 'Grow' ? ['', Validators.required] : [''],

      moms_dob_day: this.prodCategory == 'Similac' ? ['', Validators.required] : [''],
      moms_dob_month: this.prodCategory == 'Similac' ? ['', Validators.required] : [''],
      moms_dob_year: this.prodCategory == 'Similac' ? ['', Validators.required] : [''],
    
      purchase_purpose: (this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure') ? ['', Validators.required] : [''],
      user_height: (this.prodCategory == 'Glucerna') ? ['', Validators.required] : [''],

      gender: this.isGeneric == 'true' ? [''] : ['', Validators.required],

      experienced: [false],
      subscription: [false],
      // purchasechannel: this.QrcodeType == 2 ? ['', Validators.required] : [''],
      purchasechannel:  ['', Validators.required],

      // firstChildFirstName: ['', Validators.required],
      // firstChildLastName: ['', Validators.required],
      // firstChildAgeRange: [''],
      // day: [''],
      // month: [''],
      // year: [''],
      // day1: [''],
      // month1: [''],
      // year1: [''],

      milkcodeForm: this.formBuilder.array([this.addMilkCodeFields()]),
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      })



      
    this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));

    this.mobile_no = sessionStorage.getItem('mobileNo');
    this.sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    this.getProductDescCode();
    this.yearList = this.apiService.findRange();
    this.yearList1 = this.apiService.findRange1();
    console.log(this.yearList1, 'this.yearList1')
    this.motherDOByear = this.apiService.findMotherDOBRange();
    this.track_milkCode = this.apiService.trackChildMilk();
    console.log(this.track_milkCode, 'this.track_milkCode---')
    console.log(this.motherDOByear, 'this.motherDOByear')
    this.yearList2 = this.findRange();
  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }



  addMilkCodeFields() {
    return this.formArr = this.formBuilder.group({
      firstChildFirstName: (this.adultCheck &&
        (this.adultCheck == true && this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure')
        || (this.isPregnent == false && this.prodCategory == 'Similac') ||
        (this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure')
      ) ? ['', Validators.required] : [''],
      firstChildLastName: (this.adultCheck && (this.adultCheck == true && this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure') || (this.isPregnent == false && this.prodCategory == 'Similac') || (this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure')) ? ['', Validators.required] : [''],
      // firstChildFirstName:  [''],
      // firstChildLastName: [''],
      // firstChildAgeRange: [''],
      day: (this.submitted && (this.adultCheck == true && this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure') || (this.isPregnent == false && this.prodCategory == 'Similac') || (this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure')) ? ['', Validators.required] : [''],
      month: (this.submitted && (this.adultCheck == true && this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure') || (this.isPregnent == false && this.prodCategory == 'Similac') || (this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure')) ? ['', Validators.required] : [''],
      year: (this.submitted && (this.adultCheck == true && this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure') || (this.isPregnent == false && this.prodCategory == 'Similac') || (this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure')) ? ['', Validators.required] : [''],

      day1: this.isPregnent == true && this.prodCategory == 'Similac' ? ['', Validators.required] : [''],
      month1: this.isPregnent == true && this.prodCategory == 'Similac' ? ['', Validators.required] : [''],
      // year1: (this.submitted && (this.adultCheck == true && this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure' ) ) ? ['', Validators.required] : [''],
      year1: this.isPregnent == true && this.prodCategory == 'Similac' ? ['', Validators.required] : [''],
      //year1: [''],

      similacmomtype: [''],
      adultme: [''],
      adultrelative: [''],
      // typeofdiabetes: (this.submitted && (this.prodCategory == 'Glucerna')) ? ['', Validators.required] : [''],
      typeofdiabetes: this.prodCategory == 'Glucerna' ? ['', Validators.required] : ['']



    })
  }

  GetStats(event) {
    if (event.target.value == 'Tôi') {
      this.adultCheck = false;
      if (this.adultCheck == false) {
        console.log(this.adultCheck, 'this.adultCheck 1')
        let year = this.formArr.get('year1')
        year.clearValidators();
        year.updateValueAndValidity();

        let childFirstName = this.formArr.get('firstChildFirstName');
        childFirstName.clearValidators();
        childFirstName.updateValueAndValidity()

        let childLastName = this.formArr.get('firstChildLastName');
        childLastName.clearValidators();
        childLastName.updateValueAndValidity()


      }
    } else if (event.target.value == 'Thêm người thân') {
      this.adultCheck = true;
      if (this.adultCheck == true) {
        console.log(this.adultCheck, 'this.adultCheck 2')
        let year = this.formArr.get('year1')

        year.setValidators([Validators.required]);
        year.updateValueAndValidity();

      }
      console.log(this.adultCheck, 'this.adultCheck')

    }
  }

  addConditionalValidators() {
    // let ageRange = this.formArr.get('firstChildAgeRange');
    let day = this.formArr.get('day')
    let month = this.formArr.get('month')
    let year = this.formArr.get('year')
    // if(this.isPregnent == true && this.prodCategory == 'Similac'){
    //   let year = this.formArr.get('year1')
    //   year.setValidators([Validators.required]);
    // }else{
    //   let year = this.formArr.get('year1')
    //   year.clearValidators();
    // }
    if (this.milkCode == 1) {
      // ageRange.setValidators([Validators.required]);
      day.clearValidators();
      month.clearValidators();
      year.clearValidators();

    }
    else {
      // ageRange.clearValidators();
      // day.setValidators([Validators.required]);
      // month.setValidators([Validators.required]);
      // year.setValidators([Validators.required]);
    }
    // ageRange.updateValueAndValidity();
    day.updateValueAndValidity();
    month.updateValueAndValidity();
    year.updateValueAndValidity();
  }

  get milkCodeFormArray() {
    return <FormArray>this.crmSignUpForm.get('milkcodeForm');
  }

  get crmFormVal() {
    return this.crmSignUpForm.controls;
  }
  isInvalidDate( event ){
    let test = event.target.value;
    if ( test == 'Invalid date' ){
      event.target.value = ''; // Change it here
    }
  }

  addMilkFieldValue(index) {
    if (index <= 1) {
      this.present = this.storeRepeated.includes(index)
    }
    if (!this.present) {
      this.storeRepeated.push(index)
      this.milkCodeFormArray.push(this.addMilkCodeFields());
      this.milkCodeDetails = this.crmSignUpForm.get('milkcodeForm').value
      console.log(this.milkCodeDetails, 'this.milkCodeDetails')
    }
  }

  RemoveMilkFieldValue(index) {
    this.storeRepeated.pop(index)
    this.milkCodeFormArray.removeAt(index);
  }

  onCrmFormSubmit() {
    
    // let registerForm: any;
    this.dateError = false;
    this.submitted = true;
    this.hiddenBtn = true;

    // if(this.adultCheck == true){
    //   console.log(this.adultCheck, 'this.adultCheck 1')
    //   let year = this.formArr.get('year1')
    //   year.setValidators([Validators.required]);
    // }

    if (this.adultCheck == false) {
      this.formArr.get('firstChildFirstName').clearValidators();
      this.formArr.get('firstChildLastName').clearValidators();
      this.formArr.get('year1').clearValidators();
      console.log(this.adultCheck, "adultCheck")
    }




    if (this.crmSignUpForm.invalid) {
      this.hiddenBtn = false;
      console.log(this.crmSignUpForm.controls)
      // console.log(this.getFormErrors(this.crmSignUpForm))
      console.log(this.crmSignUpForm.controls.milkcodeForm['controls'][0]['controls']);

      // console.log(JSON.stringify(this.crmSignUpForm.controls))
      let formarrayone = []
      Object.keys(this.crmSignUpForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.crmSignUpForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
           formarrayone.push('Key control: ' + key + ', keyError: ' + keyError + ', err value: ' + controlErrors[keyError])
          });
        }
      });

      console.log(this.crmSignUpForm.controls.milkcodeForm['controls'][0].status, 'milkcode  form')
      
      if(this.crmSignUpForm.controls.milkcodeForm['controls'][0].status == "INVALID"){
        Object.keys(this.crmSignUpForm.controls.milkcodeForm['controls'][0]['controls']).forEach(key => {
          const controlErrors: ValidationErrors = this.crmSignUpForm.controls.milkcodeForm['controls'][0].get(key).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
            formarrayone.push('Key control: ' + key + ', keyError: ' + keyError + ', err value: ' + controlErrors[keyError])

            });
          }
        });
      }
      console.log((JSON.stringify(formarrayone) + ': : : - : : : ' + JSON.stringify(this.crmSignUpForm.value)), 'formarrayone');
      return;
    }
    else {
      let data = this.crmSignUpForm.value;


      // if(this.milkCode == 0 ){

      if ((data.milkcodeForm[0].day == '' || data.milkcodeForm[0].month == '' || data.milkcodeForm[0].year == '')) {

        // if (data.milkcodeForm[0].day1 != '' && data.milkcodeForm[0].month1 != '' && data.milkcodeForm[0].year1 != '') {
        //   this.dateError = false;
        // }
        // if (data.milkcodeForm[0].day1 == '' || data.milkcodeForm[0].month1 == '' || data.milkcodeForm[0].year1 == '') {
        //   console.log('any one row fields are required');
        //   this.dateError = true;
        //   return;
        // }

      }


      if ((data.milkcodeForm[0].day1 == '' || data.milkcodeForm[0].month1 == '' || data.milkcodeForm[0].year1 == '')) {

        // if (data.milkcodeForm[0].day != '' && data.milkcodeForm[0].month != '' && data.milkcodeForm[0].year != '') {
        //   this.dateError = false;

        // }
        // if (data.milkcodeForm[0].day == '' || data.milkcodeForm[0].month == '' || data.milkcodeForm[0].year == '') {
        //   console.log('any one row fields are required');
        //   this.dateError = true;
        //   return;
        // }

      }
      //}
      if (this.isGeneric == 'true') {
        //this.router.navigateByUrl('/otp')
        let authToken = sessionStorage.getItem('authToken');
        this.spinner.show();
        let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(this.registerForm)
          }
          let updatecustresp;
        this.apiService.updateCapCustomer(this.registerForm, this.mobile_no, authToken).pipe(first())
          .subscribe(res => {
            updatecustresp = res;
            let myuuid = uuidv4();
            let loggerFormData = {
              "unique_id": myuuid,
              "message": JSON.stringify(res)
            }
            this.spinner.hide();
            if (res['status'].code == 200) {

              this.router.navigateByUrl('/additional-salesforce-signup')
              sessionStorage.removeItem('isGeneric');
            }
            else {
              console.log(res['status'].message)
              //this.toastr.info(res['status'].message)
            }
          }, err => {
            this.bsService.notifyError(err, this.registerForm);
            this.spinner.hide()
            this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
            console.log(err, '=== get customer error =======')
          });

      } else {
        this.apiService.getPosition().then(pos => {
          let lat = pos.lat
          let lang = pos.lng
          let latitude = lat.toString()
          let longitude = lang.toString()
          let authToken = sessionStorage.getItem('authToken');
          let authKey = sessionStorage.getItem('authKey');
          let userData = this.crmSignUpForm.value;
          console.log(userData, 'userData=======')
          //   // Put the object into storage
          let filledFormValues = this.getuserForm(userData, latitude, longitude);
          console.log(this.registerForm, 'this.registerForm');
          this.spinner.show();
          this.apiService.checkExistingEmail(encodeURIComponent(userData.email)).pipe(first())
            .subscribe((res: any) => {
              this.hiddenBtn = false;
              this.spinner.hide();
              if (res.result.statusCode == 200) {
                this.spinner.show();
            



                this.router.navigateByUrl('/otp')
                let otpGenerateData = {
                  "identifierType": "MOBILE",
                  "identifierValue": this.mobile_no,
                  "deviceId": env.DEVICE_ID,
                  "brand": env.BRAND,
                  "sessionId": this.sessionIdValue
                }
                let myuuid = uuidv4();
                console.log('Your UUID is: ' + myuuid);
                let loggerFormData = {
                  "unique_id": myuuid,
                  "message": JSON.stringify(otpGenerateData)
                }
                this.spinner.show();
                let otpGenerateresp;
                this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
                  .subscribe(res => {
                    this.hiddenBtn = false;
                    let myuuid = uuidv4();
                    console.log('Your UUID is: ' + myuuid);
                    let loggerFormData = {
                      "unique_id": myuuid,
                      "message": JSON.stringify(res)
                    }
                    this.spinner.hide();
                    if (res['status'].code == 200) {

                      sessionStorage.setItem('RegisterData', JSON.stringify(userData))
                      console.log(JSON.parse(sessionStorage.getItem('RegisterData')), 'get user data === ')
                      sessionStorage.setItem('SalesForceData', 'false');


                      // this.router.navigateByUrl('/otp')



                    }
                    else {
                      //this.toastr.error(res['status'].message)
                      console.log(res['status'].message)
                    }
                  }, err => {
                    this.spinner.hide();
                    this.hiddenBtn = false;
                    this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    console.log(err, '=== OTP gererate error list =======')
                    this.bsService.notifyError(err, otpGenerateData);
                  });

              }
            }, err => {
              this.hiddenBtn = false;
              //this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
              this.emailInValid = true;
              console.log(this.emailInValid, 'emailInValid')
              console.log(err, 'err')
              //this.toastr.info('Email is already exists. Please enter new email')
              this.spinner.hide();
            })

        }) //position api call ending
      }
    }

  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  QrcodeType: any;
  getProductDescCode() {
    let mobWitZero = sessionStorage.getItem('addMobNum');
    this.crmSignUpForm.patchValue({
      phoneNumber: mobWitZero
    })
    this.crmSignUpForm.controls["phoneNumber"].disable();
    this.content = this.scancodeData?.product?.txt;
    this.QrcodeType = this.iSprintData?.type;
    console.log(this.QrcodeType, ' this.QrcodeType========')
    this.milkCode = this.iSprintData?.is_milk_code;
    this.subCatCode = this.iSprintData?.sub_category_code;
    this.milkCode == 0 || this.milkCode == undefined ? this.is_milk = false : this.is_milk = true;
    // if (this.content) {
    //   this.content.forEach((item: any) => {
    //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.subCatCode = parsedPrimaryValue.value;
    //     }
    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;
    //       this.milkCode == 0 || this.milkCode == undefined ? this.is_milk = false : this.is_milk = true;
    //     }
    //   });
    // }
    this.addConditionalValidators();
  }
  parseFunction(value) {
    return JSON.parse(value);
  }

  getuserForm(userData, latitude, longitude) {
    enum trackmilk {
      non_milk = "false",
      milk = "true"
    }
    let Signupmode = sessionStorage.getItem('SignupMode')
    return this.registerForm = {
      "root": {
        "customer": [
          {
            "mobile": this.mobile_no,
            "email": userData.email,
            "firstname": userData.firstName,
            "lastname": userData.lastName,
            "custom_fields": {
              "field": [
                // {
                //   "name": "babys_age_1",
                //   "value": userData.milkcodeForm[0]?.firstChildAgeRange ? userData.milkcodeForm[0].firstChildAgeRange : null
                // },
                {
                  "name": "babys_name_1",
                  "value": userData.milkcodeForm[0]?.firstChildFirstName ? userData.milkcodeForm[0].firstChildFirstName : null
                },
                {
                  "name": "babys_surname_1",
                  "value": userData.milkcodeForm[0]?.firstChildLastName ? userData.milkcodeForm[0].firstChildLastName : null
                },
                {
                  "name": "mothers_due_date",
                  "value": this.prodCategory == 'Similac' ? userData.milkcodeForm[0].year1 + '-' + userData.milkcodeForm[0].month1 + '-' + userData.milkcodeForm[0].day1 : null,
                },
                {
                  "name": "date_of_birth_1",
                  "value": (this.prodCategory == 'Similac' || this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure') ? userData.milkcodeForm[0].year + '-' + userData.milkcodeForm[0].month + '-' + userData.milkcodeForm[0].day : null,
                },
                {
                  "name": "adultdob",
                  "value": (this.prodCategory == 'Ensure' ||  this.prodCategory == 'Glucerna') ? userData.milkcodeForm[0].year1 + '-' + userData.milkcodeForm[0].month1 + '-' + userData.milkcodeForm[0].day1 : null,
                },
                {
                  "name": "typeofdiabetes",
                  "value": userData.milkcodeForm[0]?.typeofdiabetes ? userData.milkcodeForm[0].typeofdiabetes : null
                },
                {
                  "name": "purchasechannel",
                  "value": userData?.purchasechannel ? userData?.purchasechannel : null
                },
                {
                  "name": "similacmomtype",
                  "value": userData.milkcodeForm[0]?.similacmomtype ? userData.milkcodeForm[0].similacmomtype : null
                },

                {
                  "name": "Signupmode",
                  "value": Signupmode
                },
                {
                  "name": "past_usage_product",
                  "value": JSON.stringify(userData.experienced)
                },
                {
                  "name": "categorycode",
                  "value": this.prodCategory ? this.prodCategory : null
                },
                {
                  "name": "reglat",
                  "value": latitude
                },
                {
                  "name": "reglong",
                  "value": longitude
                },
                {
                  "name": "milk_code",
                  "value": this.milkCode == undefined ? this.milkCode = '0' : this.milkCode
                },
                {
                  "name": "moms_dob",
                  "value": userData?.parents_dob_day && userData?.parents_dob_month && userData?.parents_dob_year ? userData?.parents_dob_year + '-' + userData?.parents_dob_month + '-' + userData?.parents_dob_day : null
                },
                {
                  "name": "parents_dob",
                  "value": userData?.moms_dob_day && userData?.moms_dob_month && userData?.moms_dob_year ? userData?.moms_dob_year + '-' + userData?.moms_dob_month + '-' + userData?.moms_dob_day : null
                },
                {
                  "name": "user_height",
                  "value": userData?.user_height
                },
                {
                  "name": "purchase_purpose",
                  "value": userData?.purchase_purpose
                },
              ]
            },
            "extended_fields": { // tracking child 1 milk code
              "field": [
                {
                  "name": this.track_milkCode.child_1_milkcode,
                  "value": this.is_milk == true ? 'true' : 'false'
                },
                {
                  "name": 'gender',
                  "value": userData?.gender
                },
                {
                  "name": 'dob',
                  "value": userData?.dob_day && userData?.dob_month && userData?.dob_year ? userData?.dob_year + '-' + userData?.dob_month + '-' + userData?.dob_day : null
                }
              ]
            },
            "subscriptions": {
              "subscription": [
                {
                  "priority": "BULK",
                  "scope": "all",
                  "is_subscribed": userData.subscription == true ? '1' : '0',
                  "channel": "email"
                },
                {
                  "priority": "BULK",
                  "scope": "all",
                  "is_subscribed": userData.subscription == true ? '1' : '0',
                  "channel": "sms"
                }
              ]
            }
          }
        ]
      }
    }

  }
  showPassword() {
    this.show = !this.show;
  }
  showPassword1() {
    this.show1 = !this.show1;
  }
   generateDateList() {
    const today = moment();
    const maxDate = today.clone().add(10, 'months');
  
    const dateList = [];
    let currentDate = today.clone();
  
    while (currentDate.isSameOrBefore(maxDate)) {
      dateList.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }
  
    return dateList;
  }
  
  getLastSixYearsDates() {
    const today = moment();
    const sixYearsAgo = today.clone().subtract(6, 'years');
    
    const dateList = [];
    let currentDate = sixYearsAgo.clone();
  
    while (currentDate.isSameOrBefore(today)) {
      dateList.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }
  
    return dateList;
  }
    
    
  
  
 
  

  momtype(event) {
    console.log(event.target.value, 'momtype')
    if (event.target.value == 'Tôi đang mang thai') {
      this.isPregnent = true;
      const today = new Date();
      const maxDate = new Date(today.getFullYear(), today.getMonth() + 10, today.getDate());
      
      // Update the available years, months, and days
      this.years = Array.from({ length: 100 }, (_, index) => maxDate.getFullYear() - index);
      this.months = Array.from({ length: maxDate.getMonth() + 1 }, (_, index) => index + 1);
      this.daysInMonth = Array.from({ length: maxDate.getDate() }, (_, index) => index + 1);
    
    } else if (event.target.value == 'Tôi đã có con') {
      this.isPregnent = false;
    }

    if (!this.isPregnent) {
      let year = this.formArr.get('year');
      year.setValidators([Validators.required]);
      year.updateValueAndValidity()

      let month = this.formArr.get('month');
      month.setValidators([Validators.required]);
      month.updateValueAndValidity()

      let day = this.formArr.get('day');
      day.setValidators([Validators.required]);
      day.updateValueAndValidity()
      //-----------------------------------------
      let year1 = this.formArr.get('year1');
      year1.clearValidators();
      year1.updateValueAndValidity()

      let month1 = this.formArr.get('month1');
      month1.clearValidators();
      month1.updateValueAndValidity()

      let day1 = this.formArr.get('day1');
      day1.clearValidators();
      day1.updateValueAndValidity()


    }

    console.log(this.crmSignUpForm.controls.milkcodeForm['controls'][0]['controls'])
    console.log(this.adultCheck, ": adultCheck")

  }

  trimEmail(event){
    event.target.value = event.target.value.trim();
    this.crmSignUpForm.patchValue({
      email: event.target.value, 
    });
  }

  checkboxClicked(){
   
    this.privacyPolicyModal.show()
  }
  closeModalOne(){
    this.privacyPolicyModal.hide()
  }
  onYearChange(event): void {
    this.selectedYear = event.target.value;
    this.crmSignUpForm.get('milkcodeForm.0.month1').setValue('');
    this.crmSignUpForm.get('milkcodeForm.0.day1').setValue('');

    var currentYear = moment().year();
    this.selectedYear = event.target.value || currentYear
    const dateList = this.generateDateList();

    const filterRecord = dateList.filter(date => date.startsWith(this.selectedYear));
    const uniqueYears = [...new Set(dateList.map(date => parseInt(date.slice(0, 4))))];
    const uniqueMonths = [...new Set(filterRecord.map(date => parseInt(date.slice(5, 7))))];
     this.months = uniqueMonths
    const filteredDates = dateList.filter(date => {
      const currentDate = new Date(date);
      return currentDate.getMonth() == parseInt(String(this.months)) - 1 && currentDate.getFullYear() == this.selectedYear;
  });
    const extractedDays = filteredDates.map(dateString => {
      const day = new Date(dateString).getDate();
      return day;
    });
     this.daysInMonth = extractedDays    
  }

  onMonthChange(event): void {
    this.selectedMonth = event.target.value
    const selectedMonth = this.selectedMonth
    const selectedYear = this.selectedYear
    // Date after 10 months
    const dateAfter10Months = moment().add(10, 'months');
    const dateList = this.generateDateList()
    const uniqueYears = Array.from(new Set(dateList.map(dateString => new Date(dateString).getFullYear())));
    
    if(dateAfter10Months.format('YYYY') == selectedYear && dateAfter10Months.format('M') == selectedMonth  ){
      const filteredDates = dateList.filter(date => {
        const currentDate = new Date(date);
        return currentDate.getMonth() == parseInt(selectedMonth)-1  && currentDate.getFullYear() == selectedYear;
      });      
      const extractedDays = filteredDates.map(dateString => {
        const day = new Date(dateString).getDate();
        return day;
      });
      this.daysInMonth = extractedDays
     }else if(uniqueYears.includes(parseInt(selectedYear))){
      const filteredDates = dateList.filter(date => {
        const currentDate = new Date(date);
        return currentDate.getMonth() == parseInt(selectedMonth)-1  && currentDate.getFullYear() == selectedYear;
      });      
      const extractedDays = filteredDates.map(dateString => {
        const day = new Date(dateString).getDate();
        return day;
      });
      this.daysInMonth = extractedDays

    }else{

    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    this.daysInMonth = Array.from({ length: daysInMonth }, (_, index) => index + 1);

    }

  }
  onYearChangeForDOB(event): void {
    this.crmSignUpForm.get('milkcodeForm.0.month').setValue('');
    this.crmSignUpForm.get('milkcodeForm.0.day').setValue('');
    var currentYear = moment().year();
    this.selectedYear = event.target.value || currentYear
    // Reset days and months based on the selected year
    if (this.selectedYear == new Date().getFullYear()) {
      this.daysInMonth = Array.from({ length: new Date().getDate() }, (_, index) => index + 1);
      this.months = Array.from({ length: new Date().getMonth() + 1 }, (_, index) => index + 1);
    }else if(moment().subtract(6, 'years').format('YYYY') == this.selectedYear){
      const lastSixYearsDates = this.getLastSixYearsDates();
      const filterRecord = lastSixYearsDates.filter(date => date.startsWith(this.selectedYear));
     
      const uniqueMonths = [...new Set(filterRecord.map(date => parseInt(date.slice(5, 7))))];
      this.months = uniqueMonths
      
      const filteredDates = lastSixYearsDates.filter(date => {
        const currentDate = new Date(date);
        return currentDate.getMonth() == parseInt(String(this.months)) - 1 && currentDate.getFullYear() == this.selectedYear;
    });
      const extractedDays = filteredDates.map(dateString => {
        const day = new Date(dateString).getDate();
        return day;
      });
      this.daysInMonth = extractedDays  
      
    } else {
      this.daysInMonth = Array.from({ length: 31 }, (_, index) => index + 1);
      this.months = Array.from({ length: 12 }, (_, index) => index + 1);
    }
  }

  onMonthChangeForDOB(event): void {
    var currentYear = moment().year();
    this.selectedMonth = event.target.value
    const selectedMonth = this.selectedMonth
    const selectedYear = this.selectedYear || currentYear
    const currentMonthIndex = moment().month();
    const currentMonth = currentMonthIndex + 1;

    if(this.selectedYear == new Date().getFullYear()){
      if(this.selectedMonth == currentMonth ){
        this.daysInMonth = Array.from({ length: new Date().getDate() }, (_, index) => index + 1);
      }else{
          // Update the number of days based on the selected month and year
      const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
      this.daysInMonth = Array.from({ length: daysInMonth }, (_, index) => index + 1);

      }
    }else if(moment().subtract(6, 'years').format('YYYY') == selectedYear && moment().subtract(6, 'years').format('M') == selectedMonth  ){
        const dateList = this.getLastSixYearsDates();
        const filteredDates = dateList.filter(date => {
          const currentDate = new Date(date);
          return currentDate.getMonth() == parseInt(selectedMonth)-1  && currentDate.getFullYear() == selectedYear;
        });
        const extractedDays = filteredDates.map(dateString => {
          const day = new Date(dateString).getDate();
          return day;
        });
        this.daysInMonth = extractedDays      
      } else{
      // Update the number of days based on the selected month and year
      const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
      this.daysInMonth = Array.from({ length: daysInMonth }, (_, index) => index + 1);

    }
  }
  findRange() {
      let curr_date = new Date();
      let yearValues: number[] = [];
  
      let start = moment(curr_date).format('YYYY-MM-DD');
      let end = moment(curr_date).subtract(6, 'years').format('YYYY-MM-DD');
      let startDate = moment(start);
      let endDate = moment(end);
  
      // Get years between start and end dates
      while (startDate.isSameOrAfter(endDate)) {
        yearValues.push(startDate.year());
        startDate.subtract(1, 'year');
      }
  
      // Reverse the array to get the years in ascending order
      this.yearList2 = yearValues.reverse();
  
      return this.yearList2;
    }

}

