import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import * as moment from 'moment';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  scancodeData: any;
  otpForm: FormGroup;
  submitted = false;
  sessionIdValue: any;
  mobile_no: any;
  salesforceData: any;
  otpError: boolean = false;
  milkCode: any;
  subCatCode: any;
  content: any = [];
  addMobNum: any;
  registerForm: any = {}
  track_milkCode: any = {};
  is_milk: any;
  prodCategory: any;
  salesforceApiResponse: any;
  showResendOtp: boolean = false;
  timeLeft: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: true,
    inputStyles: {
      'width': '50px',
      'height': '50px',
    },
    inputClass: true
  };
  iSprintData: any;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,
    private apiService: ApiService, private toastr: ToastrService, private spinner: NgxSpinnerService, private bsService: BugsnagService) {
    this.addMobNum = sessionStorage.getItem('addMobNum')
    let testsalesforce = sessionStorage.getItem('SalesForceData');
    console.log(testsalesforce, 'testsalesforce in otp')

  }

  QrcodeType: any;
  ngOnInit(): void {
    this.sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    this.mobile_no = sessionStorage.getItem('mobileNo');
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    // this.QrcodeType = this.scancodeData?.uaidInfo.type;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;
    this.QrcodeType = this.iSprintData?.type;
    console.log(this.QrcodeType, ' this.QrcodeType========')
    this.getProductDescCode();
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });
    this.track_milkCode = this.apiService.trackChildMilk();
  }

  get otpFormVal() {
    return this.otpForm.controls;
  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  getProductDescCode() {
    this.content = this.scancodeData?.product?.txt;
    if (this.content) {
      this.content.forEach((item: any) => {
        if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
          let parsedPrimaryValue = this.parseFunction(item.value);
          this.milkCode = parsedPrimaryValue.value;
          this.milkCode == 0 || this.milkCode == undefined ? this.is_milk = false : this.is_milk = true;
        }
      });
    }
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  onOtpSubmit() {
    console.log("inside on submit")
    this.submitted = true;
    this.otpError = false;
    if (this.otpForm.invalid) {
      console.log("herer")
      return;
    }
    else {

      let filledFormValues: any = {}
      let SalesForceRegisterData: any = {};
      let CrmRegisterData: any = {}
      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": this.mobile_no,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionIdValue,
        "otp": this.otpForm.value.otp
      }

      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(otpSubmitData)
      }
      let otpsubmitdata;
      this.spinner.show();
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe(res => {
          console.log("otp validate success")
          otpsubmitdata = res;
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
          this.spinner.hide();
          if (res['status'].code == 200) {
            sessionStorage.setItem('authToken', res['auth'].token)
            sessionStorage.setItem('authKey', res['auth'].key)
            let getAuthKey = sessionStorage.getItem('authToken')
            let getAuthToken = sessionStorage.getItem('authKey')
            let getMobile = sessionStorage.getItem('mobileNo');

            this.apiService.getPosition().then(pos => {
              let lat = pos.lat
              let lang = pos.lng
              let latitude = lat.toString()
              let longitude = lang.toString()
              let authToken = sessionStorage.getItem('authToken');
              let authKey = sessionStorage.getItem('authKey');


              // Put the object into storage

              let salesForceData = sessionStorage.getItem('SalesForceData');
              console.log(salesForceData, 'salesForceData in otp')
              if (salesForceData == 'true') { // salesforce form           
                SalesForceRegisterData = JSON.parse(sessionStorage.getItem('SalesForceFormValue'));
                filledFormValues = this.getSalesForceUserForm(SalesForceRegisterData, latitude, longitude);
              }
              else { // crm-signup form
                CrmRegisterData = JSON.parse(sessionStorage.getItem('RegisterData'));
                console.log(CrmRegisterData, 'CrmRegisterData')
                filledFormValues = this.getuserForm(CrmRegisterData, latitude, longitude);
                this.apiService.setChild(CrmRegisterData.milkcodeForm[0]?.firstChildFirstName)

              }


              let formData =
              {
                "identifierType": "MOBILE",
                "identifierValue": this.mobile_no,
                "deviceId": env.DEVICE_ID,
                "brand": env.BRAND,
                "password": "test1234",
                "newPassword": salesForceData == 'true' ? SalesForceRegisterData.password : CrmRegisterData.password,
                "confirmPassword": salesForceData == 'true' ? SalesForceRegisterData.password : CrmRegisterData.confirmPassword,
                "token": authToken

              }

              let myuuid = uuidv4();
              let loggerFormData = {
                "unique_id": myuuid,
                "message": JSON.stringify(formData)
              }
              let changePasswordres;
              this.spinner.show();
              this.apiService.changePassword(formData).pipe(first())
                .subscribe(res => {
                  let changePasswordres = res;

                    let myuuid = uuidv4();
                    let loggerFormData = {
                      "unique_id": myuuid,
                      "message": JSON.stringify(res)
                    }
                  if (res['status'].code == 200) {

                    console.log(filledFormValues, 'filledFormValues--')

                    let myuuid = uuidv4();
                    let loggerFormData = {
                      "unique_id": myuuid,
                      "message": JSON.stringify(filledFormValues)
                    }
                    let addcustresp;
                    this.apiService.addCapCustomer(filledFormValues, this.mobile_no, authToken).pipe(first())
                      .subscribe(res => {
                        console.log("inside add cap customer")
                        addcustresp = res;
                        let myuuid = uuidv4();
                        let loggerFormData = {
                          "unique_id": myuuid,
                          "message": JSON.stringify(res)
                        }
                        this.spinner.hide();
                        if (res['status'].code == 200) {
                          console.log("insdie status 200")

                          if (this.QrcodeType == 2) {
                            console.log("inside if", this.QrcodeType)

                            this.router.navigateByUrl('/additional-salesforce-signup')
                          } else {
                            console.log("inside else", this.QrcodeType)
                            this.router.navigateByUrl('/user-profile')
                          }
                        }
                        else {

                          console.log(res['status'].message)
                          //this.toastr.info(res['status'].message); 
                        }



                      }, err => {
                        this.spinner.hide();
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                        console.log(err, 'login error')
                        this.bsService.notifyError(err, filledFormValues);
                      })

                  }

                  else if (res['status'].code == 1528) {

                    console.log(filledFormValues, 'filledFormValues--')
                    let myuuid = uuidv4();
                    let loggerFormData = {
                      "unique_id": myuuid,
                      "message": JSON.stringify(filledFormValues)
                    }
                    let addcustresp;
                    this.apiService.addCapCustomer(filledFormValues, this.mobile_no, authToken).pipe(first())
                      .subscribe(res => {
                        addcustresp = res;
                        let myuuid = uuidv4();
                        let loggerFormData = {
                          "unique_id": myuuid,
                          "message": JSON.stringify(res)
                        }
                        this.spinner.hide();
                        if (res['status'].code == 200) {

                          if (this.QrcodeType == 2) {
                            this.router.navigateByUrl('/additional-salesforce-signup')
                          } else {
                            this.router.navigateByUrl('/user-profile')
                          }
                        }
                        else {
                          console.log(res['status'].message)
                          //this.toastr.info(res['status'].message); 
                        }



                      }, err => {
                        this.spinner.hide();
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                        console.log(err, 'login error')
                        this.bsService.notifyError(err, filledFormValues);
                                              })



                  }
                  else { }

                },err=>{
                  this.spinner.hide();
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                        console.log(err, 'login error')
                        this.bsService.notifyError(err, formData);
                })
            },err=>{
              this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
              this.bsService.notifyError(err);
            })
          } // otp validate if 200
          else if (res['status'].code == 1506) {
            this.otpError = true;
          }
          else {
            //this.toastr.info(res['status'].message);
            console.log(res['status'].message)
          }
        }, err=>{
          this.spinner.hide();
          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          console.log(err, 'login error')
          this.bsService.notifyError(err, otpSubmitData);
        })

    }

  }


  resendOtp() {
    this.showResendOtp = false
    let otpGenerateData = {
      "identifierType": "MOBILE",
      "identifierValue": this.mobile_no,
      "deviceId": env.DEVICE_ID,
      "brand": env.BRAND,
      "sessionId": this.sessionIdValue
    }
    let otpgenresp;
    let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(otpGenerateData)
      }
    this.spinner.show();
    this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
      .subscribe(res => {
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        otpgenresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          // this.router.navigate(['/otp']);
          console.log("otp generate success")
        }
        else {
          //this.toastr.error(res['status'].message)
          console.log(res['status'].message)
        }
      }, err => {
        this.spinner.hide();
        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
        console.log(err, 'login error')
        this.bsService.notifyError(err, otpGenerateData);
        this.spinner.hide();
        console.log(err, '=== OTP gererate error list =======')
      });

  }

  getuserForm(userData, latitude, longitude) {
    console.log(userData, 'userData in otp pg--')
    let Signupmode = sessionStorage.getItem('SignupMode')
    return this.registerForm = {
      "root": {
        "customer": [
          {
            "mobile": this.mobile_no,
            "email": userData.email,
            "firstname": userData.firstName,
            "lastname": userData.lastName,
            "custom_fields": {
              "field": [
                // {
                //   "name": "babys_age_1",
                //   "value": userData.milkcodeForm[0]?.firstChildAgeRange ? userData.milkcodeForm[0].firstChildAgeRange : null
                // },
                {
                  "name": "babys_name_1",
                  "value": userData.milkcodeForm[0]?.firstChildFirstName ? userData.milkcodeForm[0].firstChildFirstName : null
                },
                {
                  "name": "babys_surname_1",
                  "value": userData.milkcodeForm[0]?.firstChildLastName ? userData.milkcodeForm[0].firstChildLastName : null
                },
                {
                  "name": "mothers_due_date",
                  "value": this.prodCategory == 'Similac' ? userData.milkcodeForm[0].year1 + '-' + userData.milkcodeForm[0].month1 + '-' + userData.milkcodeForm[0].day1 : null,
                },
                {
                  "name": "date_of_birth_1",
                  "value": (this.prodCategory == 'Similac' || this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure') ? userData.milkcodeForm[0].year + '-' + userData.milkcodeForm[0].month + '-' + userData.milkcodeForm[0].day : null,
                },
                {
                  "name": "adultdob",
                  "value": (this.prodCategory == 'Ensure' ||  this.prodCategory == 'Glucerna') ? userData.milkcodeForm[0].year1 + '-' + userData.milkcodeForm[0].month1 + '-' + userData.milkcodeForm[0].day1 : null,
                },
                {
                  "name": "typeofdiabetes",
                  "value": userData.milkcodeForm[0]?.typeofdiabetes ? userData.milkcodeForm[0].typeofdiabetes : null
                },
                {
                  "name": "purchasechannel",
                  "value": userData?.purchasechannel ? userData?.purchasechannel : null
                },
                {
                  "name": "similacmomtype",
                  "value": userData.milkcodeForm[0]?.similacmomtype ? userData.milkcodeForm[0].similacmomtype : null
                },
                {
                  "name": "Signupmode",
                  "value": Signupmode
                },
                {
                  "name": "past_usage_product",
                  "value": JSON.stringify(userData.experienced)
                },
                {
                  "name": "categorycode",
                  "value": this.prodCategory ? this.prodCategory : null
                },
                {
                  "name": "reglat",
                  "value": latitude
                },
                {
                  "name": "reglong",
                  "value": longitude
                },
                {
                  "name": "milk_code",
                  "value": this.milkCode == undefined ? this.milkCode = '0' : this.milkCode
                },
                {
                  "name": "moms_dob",
                  "value": userData?.parents_dob_day && userData?.parents_dob_month && userData?.parents_dob_year ? userData?.parents_dob_year + '-' + userData?.parents_dob_month + '-' + userData?.parents_dob_day : null
                },
                {
                  "name": "parents_dob",
                  "value": userData?.moms_dob_day && userData?.moms_dob_month && userData?.moms_dob_year ? userData?.moms_dob_year + '-' + userData?.moms_dob_month + '-' + userData?.moms_dob_day : null
                },
                {
                  "name": "user_height",
                  "value": userData?.user_height
                },
                {
                  "name": "purchase_purpose",
                  "value": userData?.purchase_purpose
                },
              ]
            },
            "extended_fields": { // tracking child 1 milk code
              "field": [
                {
                  "name": this.track_milkCode.child_1_milkcode,
                  "value": this.is_milk == true ? 'true' : 'false'
                },
                {
                  "name": 'gender',
                  "value": userData?.gender
                },
                {
                  "name": 'dob',
                  "value": userData?.dob_day && userData?.dob_month && userData?.dob_year ? userData?.dob_year + '-' + userData?.dob_month + '-' + userData?.dob_day : null
                }
              ]
            },
            "subscriptions": {
              "subscription": [
                {
                  "priority": "BULK",
                  "scope": "all",
                  "is_subscribed": userData.subscription == true ? '1' : '0',
                  "channel": "email"
                },
                {
                  "priority": "BULK",
                  "scope": "all",
                  "is_subscribed": userData.subscription == true ? '1' : '0',
                  "channel": "sms"
                }
              ]
            }
          }
        ]
      }
    }

  }


  getSalesForceUserForm(userData, latitude, longitude) {
    this.salesforceApiResponse = JSON.parse(sessionStorage.getItem('salesforceApiResponse'));
    let Signupmode = sessionStorage.getItem('SignupMode')
    return this.registerForm = {
      "root": {
        "customer": [
          {
            "mobile": this.mobile_no,
            "email": userData.email,
            "firstname": this.salesforceApiResponse?.FirstName,
            "lastname": this.salesforceApiResponse?.LastName,
            "custom_fields": {
              "field": [
                {
                  "name": "categorycode",
                  "value": this.prodCategory ? this.prodCategory : null
                },
                {
                  "name": "reglat",
                  "value": latitude
                },
                {
                  "name": "reglong",
                  "value": longitude
                },
                {
                  "name": "milk_code",
                  "value": this.milkCode == undefined ? this.milkCode = '0' : this.milkCode
                },
                {
                  "name": "Signupmode",
                  "value": Signupmode
                },
              ]
            }
          }
        ]
      }
    }



  }


  handleEvent(event) {
    console.log(event.left, 'event')
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

}


