import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glucerna-qc',
  templateUrl: './glucerna-qc.component.html',
  styleUrls: ['./glucerna-qc.component.scss']
})
export class GlucernaQcComponent implements OnInit {

  constructor() {
    window.scrollTo({top: 0});
   }

  ngOnInit(): void {
  }

}
