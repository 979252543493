import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensure-qc',
  templateUrl: './ensure-qc.component.html',
  styleUrls: ['./ensure-qc.component.scss']
})
export class EnsureQcComponent implements OnInit {

  constructor() { 
    window.scrollTo({top: 0});
  }

  ngOnInit(): void {
  }

}
