import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-no-animate-breadcrumb',
  templateUrl: './dynamic-no-animate-breadcrumb.component.html',
  styleUrls: ['./dynamic-no-animate-breadcrumb.component.scss']
})
export class DynamicNoAnimateBreadcrumbComponent implements OnInit {

  constructor() { }

  @Input() textone: string;
  @Input() texttwo: string;
  ngOnInit(): void {
  }

}
