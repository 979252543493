import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-qc-breadcrumb',
  templateUrl: './qc-breadcrumb.component.html',
  styleUrls: ['./qc-breadcrumb.component.scss']
})
export class QcBreadcrumbComponent implements OnInit {

  constructor(private _location: Location) { }

  ngOnInit(): void {
  }
  goBack(){
    this._location.back();
  }


}
