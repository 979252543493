<div class="noAnimateBreadcrumbOut">
    <nav aria-label="breadcrumb" class="breadCrumbOut">
        <div class="dotOut traceBread">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:;">{{ textone }}</a></li>
                <li class="breadcrumb-item"><a href="javascript:;">{{ texttwo }}</a></li>
            </ol>
        </div>
    </nav>
    <nav class="navbar navbar-light">
        <div class="menuBtnOut">
            <span class="menuText">Menu</span>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="javascript:;">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:;">Link</a>
                </li>
            </ul>
        </div> -->
    </nav>
</div>