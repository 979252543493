<app-header></app-header>

<section class="signUpContainer">
    <img class="img-fluid" src="../../../assets/images/signupbanner.png" />


    <form [formGroup]="salesForceSignUpForm" class="formContainer pb-5" (ngSubmit)="onSalesForceSignUpSubmit()">
        <div class="form-group mb-2 ">
            <input type="number" class="form-control" formControlName="phoneNumber" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder=""
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                [ngClass]="{'is-invalid': submitted && salesFormVal.phoneNumber.errors}" required>
            <label for="exampleInputEmail1">{{ 'SALES_FORCE_SIGNUP.LABEL1' | translate }}</label>

            <img *ngIf=" submitted && !salesFormVal.phoneNumber.errors" class="img-fluid tickmarkImage" src="../../../assets/images/tickmark.svg" />
            <div *ngIf="submitted && salesFormVal.phoneNumber.errors" class="invalid-feedback">
                <div *ngIf="salesFormVal.phoneNumber.errors.required"></div>
                <div *ngIf="salesFormVal.phoneNumber.errors.pattern">
                   
                </div> 
            </div>
        </div>
        <div class="form-group mb-2">
            <input type="text" class="form-control" formControlName="name" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder=""
                [ngClass]="{'is-invalid': submitted && salesFormVal.name.errors}" required>
            <label for="exampleInputEmail1">{{ 'SALES_FORCE_SIGNUP.LABEL2' | translate }}</label>
            <img class="img-fluid tickmarkImage"  *ngIf=" submitted && !salesFormVal.name.errors" src="../../../assets/images/tickmark.svg" />
            <div *ngIf="submitted && salesFormVal.name.errors" class="invalid-feedback">
                <div *ngIf="salesFormVal.name.errors.required"></div>
            </div>
        </div>
        <div class="form-group mb-2">
            <input type="email" class="form-control" formControlName="email" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder=""
                [ngClass]="{'is-invalid': submitted && salesFormVal.email.errors || emailInValid}" required>
            <label for="exampleInputEmail1">{{ 'SALES_FORCE_SIGNUP.LABEL3' | translate }}</label>
            <img class="img-fluid tickmarkImage" *ngIf="submitted && !salesFormVal.email.errors" src="../../../assets/images/tickmark.svg" />
            <div *ngIf="submitted && salesFormVal.email.errors" class="invalid-feedback">
                <div *ngIf="salesFormVal.email.errors.required"></div>
            </div>
            <div *ngIf="emailInValid" class="invalid-feedback">
                <div>Email này đã được sử dụng, vui lòng sử dụng email khác để đăng ký</div>
            </div>
        </div>
        <div class="form-group mb-2">


            <input type="password" class="form-control" formControlName="password" id="exampleInputEmail1"
                aria-describedby="passwordHelp" placeholder="{{ 'SALES_FORCE_SIGNUP.LABEL4' | translate }}"
                [ngClass]="{'is-invalid': submitted && salesFormVal.password.errors}" required>
            <!-- <label for="exampleInputEmail1">{{ 'SIGNUP.LABEL_SIGNUP_ONE' | translate }}</label> -->
            <img class="img-fluid tickmarkImage"  *ngIf="submitted && !salesFormVal.password.errors" src="../../../assets/images/tickmark.svg" />
            <div *ngIf="submitted && salesFormVal.password.errors" class="invalid-feedback">
                <!-- <div *ngIf="salesFormVal.password.errors.required"></div> -->
            </div>
        </div>

        <ul class="customListBlock">
           <p> {{ 'SALES_FORCE_SIGNUP.LIST_TITLE' | translate }} </p>
            <li>
                <p><img src="../../../assets/images/ellipse-525-copy-9.png" class="ellipseImg">{{ 'SALES_FORCE_SIGNUP.LIST_TEXT1' | translate }}</p>
            </li>
            <li>
                <p><img src="../../../assets/images/ellipse-525-copy-9.png" class="ellipseImg">{{ 'SALES_FORCE_SIGNUP.LIST_TEXT2' | translate }}</p>
                
            </li>
            <li>
                <p><img src="../../../assets/images/ellipse-525-copy-9.png" class="ellipseImg">{{ 'SALES_FORCE_SIGNUP.LIST_TEXT3' | translate }}</p>

            </li>
            <!-- <li>
                <p><img src="../../../assets/images/ellipse-525-copy-9.png" class="ellipseImg">{{ 'SALES_FORCE_SIGNUP.LIST_TEXT4' | translate }}</p>

            </li> -->
        </ul>
        <div class="form-group mb-2">
            <input type="password" class="form-control" formControlName="confirmPassword" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder="{{ 'SALES_FORCE_SIGNUP.LABEL5' | translate }}"
                [ngClass]="{'is-invalid': submitted && salesFormVal.confirmPassword.errors}" required>
            <!-- <label for="exampleInputEmail1">{{ 'SIGNUP.LABEL_SIGNUP_ONE' | translate }}</label> -->
            <img class="img-fluid tickmarkImage" *ngIf="submitted && !salesFormVal.confirmPassword.errors" src="../../../assets/images/tickmark.svg" />
            <div *ngIf="submitted && salesFormVal.confirmPassword.errors" class="invalid-feedback">
                <!-- <div *ngIf="salesFormVal.confirmPassword.errors.required"></div> -->
                <div *ngIf="salesFormVal.confirmPassword.errors.mustMatch">Mật khẩu chưa khớp. Vui lòng nhập lại</div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block orangeBtn mt-5">{{ 'SALES_FORCE_SIGNUP.BTN_TEXT' | translate }}</button>
    </form>
</section>
<app-auth-footer></app-auth-footer>
<app-nav-menu></app-nav-menu>