import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService } from 'ng-connection-service';
import { filter } from 'rxjs/operators';
import { ApiService } from './services/api.service';
import { RouterService } from './services/router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  // <div>
  //     <h2>{{ 'HOME.TITLE' | translate }}</h2>
  //     <label>
  //       {{ 'HOME.SELECT' | translate }}
  //       <select #langSelect (change)="translate.use(langSelect.value)">
  //         <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
  //       </select>
  //     </label>
  //   </div>
  // `,
})
export class AppComponent implements OnInit {
  status = 'ONLINE'; //initializing as online by default
  isConnected = true;
  previousUrl: string = null;
  currentUrl: string = null;
  constructor(public translate: TranslateService, private apiService: ApiService, private connectionService: ConnectionService, private router: Router, private routerService: RouterService) {
    translate.addLangs(['en_US', 'vi_VN']);
    translate.setDefaultLang('en_US');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|vi_VN/) ? browserLang : 'en_US');
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      } else {
        this.status = "OFFLINE"
      }
      if (this.status == "OFFLINE") this.apiService.showErrorMsg('Something went wrong, please check your network connection')
      console.log(this.status)
    });
  }


  ngOnInit(): void {
    // throw new Error('Method not implemented.');

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.routerService.setPreviousUrl(this.previousUrl);
    });
  }
}