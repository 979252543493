import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jsQR from 'jsqr';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  activePath: any;
  authToken: any;
  showActiveImage: boolean = false;
  showActiveRewardImage: boolean = false;
  showLibraryActiveImage: boolean = false;
  showProfileActiveImage: boolean = false;
  showActiveKnowledgeImage: boolean = false;


  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('successQrCodeModalOne', { static: true }) successQrCodeModalOne: ModalDirective;
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false
  };
  constructor(private route: ActivatedRoute, public router: Router) {
   this.activePath = this.route.snapshot.routeConfig.path;
    console.log('rouetInfo', this.activePath);
    this.authToken = sessionStorage.getItem('authToken');
    console.log(this.router.url, 'router url')
   }  


  ngOnInit(): void {
    if (this.activePath === 'profile-home') {
      this.showActiveImage = true;
     }
   if (this.activePath === 'rewards' || this.activePath === 'grow-spinwheel' || this.activePath === 'similac-spinwheel') {
    this.showActiveRewardImage = true;
   }

   if (this.activePath === 'library' || this.activePath === 'product-description' || this.activePath === 'similac-mom-product') {
    this.showLibraryActiveImage = true;
   }

   if (this.activePath === 'user-profile') {
    this.showProfileActiveImage = true;
   }
   if (this.activePath === 'knowledge') {
     this.showActiveKnowledgeImage = true;
   }
  }

  openQrCodeOneModal() {
    this.successQrCodeModalOne.show();
  }
  closeModalOne() {
    this.successQrCodeModalOne.hide();
  }

  
}
