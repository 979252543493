<app-header></app-header>
<!-- <app-manu-breadcrumb></app-manu-breadcrumb> -->
<div class="manufacturingContainer">
    <div class=" bannerContainer">
        <div class="container">
        <div class="row">
            <div class="col-8">
                <div class="bannerContents">
                <h1>{{ 'MANUFACTURING_HOLLAND.BANNER_TITLE' | translate }}</h1>
                <p >{{ 'MANUFACTURING_HOLLAND.BANNER_DESC' | translate }}</p>
                </div>
            </div>      
        </div>
        </div>
    </div>
    <div class="manufacturingContents">
        <div class="container">
            <div class="row">
                <div class="col-12">
                <div class="titleContainer">
                <h4>{{ 'MANUFACTURING_HOLLAND.MANUFACTURER_MAIN_TITLE1' | translate }}<br>{{ 'MANUFACTURING_HOLLAND.MANUFACTURER_MAIN_TITLE2' | translate }}</h4>
                <p>{{ 'MANUFACTURING_HOLLAND.MANUFACTURER_SUB_TITLE' | translate }}</p>
                </div>
                <p ><strong> {{ 'MANUFACTURING_HOLLAND.MANUFACTURING_CONTENTS.DESC_STRONG' | translate }}</strong>
                    {{ 'MANUFACTURING_HOLLAND.MANUFACTURING_CONTENTS.DESC1' | translate }}</p>
                    <figure><img src="../../../assets/images/abbott-factory-05.png"></figure>
                <p>{{ 'MANUFACTURING_HOLLAND.MANUFACTURING_CONTENTS.DESC2' | translate }}</p>
                <figure><img class="isoLogo" src="../../../assets/images/iso-22000-certifications.png"></figure>
                <p>{{ 'MANUFACTURING_HOLLAND.MANUFACTURING_CONTENTS.DESC3' | translate }}</p>
                <figure class="brandLogo"><img src="../../../assets/images/brand-logo.png"></figure>
                <p>{{ 'MANUFACTURING_HOLLAND.MANUFACTURING_CONTENTS.DESC4' | translate }}</p>
            </div>
            </div>
        </div>

         <div class="bannerSec">
            <div class="growBanner" *ngIf="prodCategory == 'Grow'">
                <img src="/assets/images/Group1_border.png" />
                <span class="smallText">GRO-C-273-20</span>
                <button class="orangeBtn blueBg" *ngIf="milkCode == '1'" (click)="openModal(template)">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
                <button class="orangeBtn blueBg" *ngIf="milkCode == '0'" routerLink="/product-description">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
            </div>
            <div class="similacBanner" *ngIf="prodCategory == 'Similac'">
                <img src="../../../assets/images/similac-bg.png" class="w-100" *ngIf="subCatCode == '2001' || subCatCode == '2002' || subCatCode == '2003' || subCatCode == '2004' || subCatCode == '2005' || subCatCode == '2006' || subCatCode == '2007' || subCatCode == '2008' || subCatCode == '2009' || subCatCode == '2010' || subCatCode == '2011' || subCatCode == '2012' || subCatCode == '2013' || subCatCode == '2014' || subCatCode == '2015' || subCatCode == '2016' || subCatCode == '2017'"  />  
                    <img src="../../../assets/images/vigor/vignew.png" class="w-100" *ngIf="subCatCode == '2018' || subCatCode == '2019' || subCatCode == '2020' || subCatCode == '2021'" />       
                    <div *ngIf="subCatCode == '2001' || subCatCode == '2002' || subCatCode == '2003' || subCatCode == '2004' || subCatCode == '2005' || subCatCode == '2006' || subCatCode == '2007' || subCatCode == '2008' || subCatCode == '2009' || subCatCode == '2010' || subCatCode == '2011' || subCatCode == '2012' || subCatCode == '2013' || subCatCode == '2014' || subCatCode == '2015' || subCatCode == '2016' || subCatCode == '2017'">
                        <button class="orangeBtn" *ngIf="milkCode == '1'" (click)="openModal(template)">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
                        <button class="orangeBtn" *ngIf="milkCode == '0'" routerLink="/similac-mom-product">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
                    </div>
                    <div *ngIf="subCatCode == '2018' || subCatCode == '2019' || subCatCode == '2020' || subCatCode == '2021'">
                        <a href="javascript:;" class="orangeBtn vigor" *ngIf="milkCode == '1'" (click)="openModal(template)">
                            <img src="../../../assets/images/vigor/ctavigor.png" alt="img" />
                        </a>
                        <a href="javascript:;" class="orangeBtn vigor" *ngIf="milkCode == '0'" routerLink="/similac-mom-product">
                            <img src="../../../assets/images/vigor/ctavigor.png" alt="img" />
                        </a>
                    </div>
            </div>
            <div class="similacWidget mt40" *ngIf="prodCategory == 'Ensure'">
            
            <div class="traceabilityBannerOut">
                <img src="../../../assets/images/traceability/traceability_ensure_bottom.png" class="w-100" />
                <button class="orangeBtn position1" routerLink="/ensure-product">Tìm hiểu thêm</button>
            </div>
        </div>
        
        <div class="similacWidget mt40" *ngIf="prodCategory == 'Glucerna'">
                
                <div class="traceabilityBannerOut">
                    <img src="../../../assets/images/traceability/traceability_glucerna_bottom.png" class="w-100" />
                    <button class="orangeBtn position2" routerLink="/glucerna-product">Tìm hiểu thêm</button>
                </div>
            </div>
        
            <div class="similacWidget mt40" *ngIf="prodCategory == 'PediaSure'">
                <!-- Traceability Pediasure Banner -->
                <div class="traceabilityBannerOut">
                    <img src="../../../assets/images/traceability/traceability_pediasure_bottom.png" class="w-100" />
                    <button class="orangeBtn position3" routerLink="/pediasure-product">Tìm hiểu thêm</button>
                </div>
            </div>
        </div>

    <div class="manufacturerShareAndCareContainer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-0 careShareText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.CARE_AND_SHARE' | translate }}</h3>
                    <h3 class="homeText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.HOME' | translate }}</h3>
                    <p>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE1' | translate }}
                        <br><strong>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE_STRONG' | translate }}</strong></p>
                    <button class="orangeBtn" (click)="gotoHome()" routerLink="/sign-up/0">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.JOIN_BUTTON' | translate }}</button>
                    <p>
                        {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE1' | translate }} 
                        <strong>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE_STRONG' | translate }}</strong> 
                        <br>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE2' | translate }}
                        <small> {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.REGISTRATION_CODE' | translate }}</small>
                    </p>
                   
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-body">
        <section class="checkMilkcodeMdl">
            <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
                <p>Vui lòng xác nhận bạn là nhân viên y tế hoặc có nhu cầu tìm hiểu thông tin sản phẩm</p>
                <div class="row mb-3">
                    <div class="col-6">
                        <a href="javascript:;"
                        class="primaryBtn" (click)="modalRef.hide()">Quay về trang chủ</a>
                    </div>
                    <div class="col-6">
                        <a href="javascript:;"
                        class="primaryBtn" (click)="gotoProdDesc()">Xác nhận</a>
                        
                    </div>
                </div>
        </section>
    </div>
</ng-template>

<app-footer></app-footer>
<app-nav-menu></app-nav-menu>
