<app-header></app-header>
<!-- <app-dynamic-no-animate-breadcrumb textone="Trang chủ" texttwo="Sản phẩm" class="libraryBreadcrumb"></app-dynamic-no-animate-breadcrumb> -->
<section class="similacMomProductContainer">
    <section class="similacInnerSectionOut pt-3 px-2">
        <section class="similacVannilaOut">
            
            <h1 class="headerBlueTitle">{{ 'LIBRARY.TITLE' | translate }}</h1>
            <ul class="nav nav-tabs tabsContainer" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active':(activeTabGrow || defaultActiveTab)}" id="grow-tab" data-toggle="tab" href="#grow" role="tab"
                        aria-controls="grow" aria-selected="true">{{ 'PROFILE_HOME.SIMILAC_SECTION.LEFT_BTN' | translate
                        }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active':activeTabSimilac}" id="similac-tab" data-toggle="tab" href="#similac" role="tab"
                        aria-controls="profile" aria-selected="false">{{ 'PROFILE_HOME.SIMILAC_SECTION.RIGHT_BTN' |
                        translate
                        }}</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" [ngClass]="{'show active':(activeTabGrow || defaultActiveTab)}" id="grow" role="tabpanel" aria-labelledby="grow-tab">
                    <h1 class="orangeHeaderTitle">{{ 'LIBRARY.TITLE1' | translate }}</h1>

                    <div class="LibraryCardList">
                        <div class="row m-0">
                            <div class="col-12 p-1">
                                <div class="card verticalCard">
                                    <div class="card-body pl-2 pt-0">

                                        <div class="row justify-content-between">
                                            <div class="col-4">
                                                <img class="leftCardImg"
                                                    src="../../../assets/images/grow-3-hireslist.png">
                                            </div>
                                            <div class="col-8 text-left pl-0 mt-2 pr-2">
                                                <p class="blackBoldText">{{ 'LIBRARY.GROW.CARD_PARA1' | translate }}</p>
                                                <p class="blackBoldText mt-0">{{ 'LIBRARY.GROW.CARD_PARA2' | translate }}</p>
                                                <p class="blackBoldText mt-0">{{ 'LIBRARY.GROW.CARD_PARA3' | translate }}</p>
                                                <p class="textBlack">{{ 'LIBRARY.GROW.CARD_PARA4' | translate }}<sup>{{ 'LIBRARY.GROW.CARD_PARA_SYMBOL' | translate }}</sup>{{ 'LIBRARY.GROW.CARD_PARA4_AFTER' | translate }}</p>
                                                <button class="orangeBtn cardBottomBtn ml-0" (click)="openMilkCodeModal('0', '1006', 'grow')">
                                                    {{ 'LIBRARY.GROW.BTN_TEXT' | translate }}
                                                </button>

                                                <!-- <button class="orangeBtn cardBottomBtn ml-0"  *ngIf="milkCode == '0'" (click)="openModal(template)" routerLink="/product-description">
                                                    {{ 'LIBRARY.GROW.BTN_TEXT' | translate }}
                                                </button> -->
                                               
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12 p-1">
                                <div class="card verticalCard">
                                    <div class="card-body pl-2 pt-0">

                                        <div class="row justify-content-between">
                                            <div class="col-4">
                                                <img class="leftCardImg"
                                                    src="../../../assets/images/grow-6-hireslist.png">
                                            </div>
                                            <div class="col-8 text-left pl-0 mt-2 pr-2">
                                                <p class="blackBoldText">{{ 'LIBRARY.GROW.CARD2_PARA1' | translate }}</p>
                                                <p class="blackBoldText mt-0"> {{ 'LIBRARY.GROW.CARD2_PARA2' | translate }}</p>
                                                <p class="blackBoldText mt-0">{{ 'LIBRARY.GROW.CARD2_PARA3' | translate }}</p>
                                                <p class="textBlack">{{ 'LIBRARY.GROW.CARD2_PARA4' | translate }}<sup>{{ 'LIBRARY.GROW.CARD2_PARA4_SYMBOL' | translate }}</sup> {{ 'LIBRARY.GROW.CARD2_PARA4_AFTER' | translate }}</p>
                                                <button class="orangeBtn cardBottomBtn ml-0" (click)="openMilkCodeModal('0', '1005', 'grow')">{{ 'LIBRARY.GROW.BTN_TEXT' | translate }}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="my-4 text-left">
                            <p class="smallContent">{{ 'LIBRARY.SMALL_TEXT' | translate }}</p>
                        </div>

                        <h1 class="orangeHeaderTitle">{{ 'LIBRARY.TITLE2' | translate }}</h1>

                        <div class="row m-0 justify-content-start">
                            <div class="col-6 px-1">
                                <div class="card cardTypeTwo py-3">
                                    <div class="imageBlock">
                                        <img class="card-img-top" src="../../../assets/images/abbott-grow-1-list.png">

                                    </div>
                                    <div class="card-body px-2 pt-0 pb-3">

                                        <p class="blackBoldText mt-1">{{ 'LIBRARY.GROW.CARD3_PARA1' | translate }}</p>
                                        <p class="cardContent mt-0">
                                            {{ 'LIBRARY.GROW.CARD3_PARA2' | translate }}
                                        </p>
                                    </div>
                                    <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','1001', 'grow')">{{ 'LIBRARY.GROW.BTN_TEXT' | translate }}</button>
                                </div>
                            </div>
                            <div class="col-6 px-1">
                                <div class="card cardTypeTwo py-3">
                                    <div class="imageBlock">
                                        <img class="card-img-top" src="../../../assets/images/abbott-grow-2-list.png">

                                    </div>
                                    <div class="card-body px-2 pt-0 pb-3">

                                        <p class="blackBoldText mt-1">{{ 'LIBRARY.GROW.CARD4_PARA1' | translate }}</p>
                                        <p class="cardContent mt-0">
                                            {{ 'LIBRARY.GROW.CARD4_PARA2' | translate }}
                                        </p>    
                                    </div>
                                    <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','1002', 'grow')">{{ 'LIBRARY.GROW.BTN_TEXT' | translate }}</button>
                                </div>
                            </div>
                            <div class="col-6 px-1 mt-3">
                                <div class="card cardTypeTwo py-3">
                                    <div class="imageBlock">
                                        <img class="card-img-top" src="../../../assets/images/abbott-grow-3-list.png">

                                    </div>
                                    <div class="card-body px-2 pt-0 pb-3">
                                        <p class="blackBoldText mt-1">{{ 'LIBRARY.GROW.CARD5_PARA1' | translate }} </p>
                                        <p class="cardContent mt-0">
                                            {{ 'LIBRARY.GROW.CARD5_PARA2' | translate }}
                                        </p>
                                    </div>
                                    <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','1003', 'grow')">{{ 'LIBRARY.GROW.BTN_TEXT' | translate }}</button>
                                </div>
                            </div>

                            <div class="col-6 px-1 mt-3">
                                <div class="card cardTypeTwo py-3">
                                    <div class="imageBlock">
                                        <img class="card-img-top" src="../../../assets/images/abbott-grow-4-list.png">

                                    </div>
                                    <div class="card-body px-2 pt-0 pb-3">

                                        <p class="blackBoldText mt-1">{{ 'LIBRARY.GROW.CARD6_PARA1' | translate }} </p>
                                        <p class="cardContent mt-0">                                            
                                            {{ 'LIBRARY.GROW.CARD6_PARA2' | translate }} 
                                        </p>
                                    </div>
                                    <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('0','1004', 'grow')">{{ 'LIBRARY.GROW.BTN_TEXT' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" [ngClass]="{'show active':activeTabSimilac}" id="similac" role="tabpanel" aria-labelledby="similac-tab">
                    <section>
                        <h1 class="orangeHeaderTitle">{{ 'LIBRARY.SIMILAC.TITLE' | translate }} </h1>
                        <div class="LibraryCardList">
                            <div class="row m-0 justify-content-start">
                                <div class="col-6 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top" src="../../../assets/images/homesimilaclist2.png">

                                        </div>
                                        <img class="similacLogo" src="../../../assets/images/logo-similac-mom.png" />
                                        <div class="card-body px-2 pb-3 pt-0">

                                            <p class="blackBoldText mt-1">{{ 'LIBRARY.SIMILAC.CARD_PARA1' | translate }}</p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('0','2011','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>
                                <div class="col-6 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top" src="../../../assets/images/homesimilaclist1.png">

                                        </div>
                                        <img class="similacLogo" src="../../../assets/images/logo-similac-mom.png" />
                                        <div class="card-body px-2 pb-3 pt-0">

                                            <p class="blackBoldText mt-1">
                                                {{ 'LIBRARY.SIMILAC.CARD1_PARA1' | translate }}
                                            </p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD1_PARA2' | translate }}
                                            </p>

                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('0','2010','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <h1 class="orangeHeaderTitle text-center my-4">{{ 'LIBRARY.TITLE3' | translate }}
                                    </h1>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top"
                                                src="../../../assets/images/similacnewbornonelist.png">
                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2"> {{ 'LIBRARY.SIMILAC.CARD2_PARA1' | translate }}<img class="eyeqPlusLogo"
                                                    src="../../../assets/images/logo-iqplus.png" /></p>
                                            <p class="cardContent mt-1">{{ 'LIBRARY.SIMILAC.CARD2_PARA2' | translate }}</p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2007','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top" src="../../../assets/images/similactwolist.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2">{{ 'LIBRARY.SIMILAC.CARD3_PARA1' | translate }}<img class="eyeqPlusLogo"
                                                    src="../../../assets/images/logo-iqplus.png" /> 2</p>
                                            <p class="cardContent mt-1">{{ 'LIBRARY.SIMILAC.CARD3_PARA2' | translate }}</p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2005','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>


                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top"
                                                src="../../../assets/images/similactotalconfortonelist.png">
                                        </div>

                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2">{{ 'LIBRARY.SIMILAC.CARD4_PARA1' | translate }}</p>
                                            <p class="cardContent mt-1">{{ 'LIBRARY.SIMILAC.CARD4_PARA2' | translate }}</p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2003','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top"
                                                src="../../../assets/images/similacisomillist.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2">{{ 'LIBRARY.SIMILAC.CARD5_PARA1' | translate }}</p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD5_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2008','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top"
                                                src="../../../assets/images/similacneosurelist.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">

                                            <p class="blackBoldText mt-2 mb-2"> {{ 'LIBRARY.SIMILAC.CARD6_PARA1' | translate }}<img class="eyeqPlusLogo"
                                                    src="../../../assets/images/logo-iqplus.png" /></p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD6_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2012','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top"
                                                src="../../../assets/images/similacalimentumlist.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2">{{ 'LIBRARY.SIMILAC.CARD7_PARA1' | translate }}</p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD7_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2001','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <h1 class="orangeHeaderTitle text-center my-4">{{ 'LIBRARY.TITLE4' | translate }}
                                    </h1>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top" src="../../../assets/images/similaceyeq3list.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2">  {{ 'LIBRARY.SIMILAC.CARD8_PARA1' | translate }}<img class="eyeqPlusLogo"
                                                    src="../../../assets/images/logo-iqplus.png" /> 3</p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD8_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2003','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top"
                                                src="../../../assets/images/similac-total-comfort-1list.png">
                                        </div>

                                        <div class="card-body px-2 pb-3 pt-0">
                                               
                                                <p class="blackBoldText mt-2 mb-2"> {{ 'LIBRARY.SIMILAC.CARD9_PARA1' | translate }}</p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD9_PARA2' | translate }}
                                                
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2006','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top" src="../../../assets/images/isomilpluslist.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">

                                            <p class="blackBoldText mt-2 mb-2">{{ 'LIBRARY.SIMILAC.CARD10_PARA1' | translate }}</p>
                                            <p class="cardContent">{{ 'LIBRARY.SIMILAC.CARD10_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('1','2009','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <h1 class="orangeHeaderTitle text-center my-4">
                                        {{ 'LIBRARY.TITLE5' | translate }}
                                    </h1>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top" src="../../../assets/images/similaceye4list.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2">{{ 'LIBRARY.SIMILAC.CARD11_PARA1_BEFORE' | translate }} <img class="eyeqPlusLogo"
                                                    src="../../../assets/images/logo-iqplus.png" />{{ 'LIBRARY.SIMILAC.CARD11_PARA1_AFTER' | translate }}</p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD11_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('0','2002','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                                <div class="col-6 mt-3 px-1">
                                    <div class="card py-3">
                                        <div class="imageBlock">
                                            <img class="card-img-top"
                                                src="../../../assets/images/similac-total-comfort-2list.png">

                                        </div>
                                        <div class="card-body px-2 pb-3 pt-0">
                                            <p class="blackBoldText mt-2 mb-2">
                                                {{ 'LIBRARY.SIMILAC.CARD12_PARA1' | translate }}
                                            </p>
                                            <p class="cardContent">
                                                {{ 'LIBRARY.SIMILAC.CARD12_PARA2' | translate }}
                                            </p>
                                        </div>
                                        <button class="orangeBtn cardBottomBtn" (click)="openMilkCodeModal('0','2004','similac')">{{ 'LIBRARY.SIMILAC.BTN_TEXT' | translate }}</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </section>
</section>

<div bsModal #milkCodeModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="milkCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                        <p>Vui lòng xác nhận bạn là nhân viên y tế hoặc có nhu cầu tìm hiểu thông tin sản phẩm</p>
                        <div class="row mb-3">
                            <div class="col-6">
                                <a href="javascript:;"
                                class="primaryBtn" (click)="milkCodeHide()">Quay về trang chủ</a>
                            </div>
                            <div class="col-6">
                                <a href="javascript:;"
                                class="primaryBtn"  (click)="gotoProdDesc()">Xác nhận</a>
                            </div>
                        </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-dynamic-footer code="COR-C-16-21"></app-dynamic-footer>
<app-nav-menu></app-nav-menu>