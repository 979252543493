<section class="navMenu">
    <div class="card">
        <ul>
            <li [ngClass]="{'activeClass': showActiveImage}">
                <a href="javascript:;" routerLinkActive="active" routerLink="/profile-home">
                    <img src="../../../assets/images/home.png"  class="mt-1" *ngIf="!showActiveImage" alt="home" />
                    <img src="../../../assets/images/homeblue.png" class="mt-1"  alt="home"  *ngIf="showActiveImage"/>
                    <span>{{ 'NAVIGATION.NAV_TEXT1' | translate }}</span>
                </a>
            </li>
            <li [ngClass]="{'activeClass': showActiveRewardImage}">
                <a href="javascript:;" routerLink="/rewards">
                    <img class="rewardsImg" src="../../../assets/images/gift.png" *ngIf="!showActiveRewardImage" alt="point" />
                    <img class="rewardsImg" src="../../../assets/images/giftblue.png" *ngIf="showActiveRewardImage" routerLink="" alt="" />

                    <span>{{ 'NAVIGATION.NAV_TEXT2' | translate }} <br> {{ 'NAVIGATION.NAV_TEXT3' | translate }}</span>
                </a>
            </li>
            <li [ngClass]="{'activeClass': showActiveKnowledgeImage}">
                <a href="javascript:;" routerLink="/knowledge" >
                    <img src="../../../assets/images/knowledge.png" *ngIf="!showActiveKnowledgeImage"  alt="knowledge" />
                    <img src="../../../assets/images/knowledgeblue.png" *ngIf="showActiveKnowledgeImage"  routerLink="" alt="" />
                    <!-- <span>{{ 'NAVIGATION.NAV_TEXT4' | translate }} <br> {{ 'NAVIGATION.NAV_TEXT5' | translate }}</span> -->
                    <span>Kiến thức<br> chăm sóc sức khỏe</span>
                </a>
            </li>
            <li [ngClass]="{'activeClass': this.router.url == '/home'}">
                <a href="javascript:;" routerLink="/scanner">
                    <img src="../../../assets/images/tracedarkblue.png" *ngIf="this.router.url != '/home'" alt="trace"  />
                    <img src="../../../assets/images/trace.png" *ngIf="this.router.url == '/home'" alt="trace" />
                    <span>{{ 'NAVIGATION.NAV_TEXT6' | translate }} <br> {{ 'NAVIGATION.NAV_TEXT7' | translate }}</span>
                </a>
            </li>
            <li [ngClass]="{'activeClass': showProfileActiveImage,'disableClass': !authToken}">
                <a href="javascript:;" routerLink="/user-profile">
                    <img src="../../../assets/images/user.png" *ngIf="!showProfileActiveImage" alt="trace"/>
                    <img src="../../../assets/images/userblue.png" *ngIf="showProfileActiveImage" alt="" />
                    <span>{{ 'NAVIGATION.NAV_TEXT8' | translate }} <br> {{ 'NAVIGATION.NAV_TEXT9' | translate }}</span>
                </a>
            </li>
        </ul>
    </div>
</section>


