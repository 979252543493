import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-manu-breadcrumb',
  templateUrl: './manu-breadcrumb.component.html',
  styleUrls: ['./manu-breadcrumb.component.scss']
})
export class ManuBreadcrumbComponent implements OnInit {

  constructor(private _location: Location) { }

  ngOnInit(): void {
  }
  goBack(){
    this._location.back();
  }

}
