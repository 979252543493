<div class="container-fluid p-0">
<header>
  <div class="row m0">
    <div class="col-6">
      <a href="https://nutrition.abbott/vn " class="logo">
        <img src="../../../assets/images/temp/logo.png" alt="Logo" />
      </a>
    </div>
    <div class="col-6">
      <ul>
        <li>
          <a href="javascript:;">
            <img src="../../../assets/images/temp/flag.png" alt="flag" />
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <img src="../../../assets/images/temp/menuIco.png" alt="flag" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>
<main>
  <article class="mainContent">
    <h1>{{ 'MAIN_CONTENT.HEADING_TXT' | translate }}</h1>
      <p>{{ 'MAIN_CONTENT.SUB_CONTENT_1' | translate }}</p>
      <p>{{ 'MAIN_CONTENT.SUB_CONTENT_2' | translate }}</p>
        <figure>
          <img src="../../../assets/images/temp/phone.png" alt="Phone" />
        </figure>
        <p>
          <strong>{{ 'STRONG_CONTENT.CONTENT' | translate }}</strong>
        </p>
        <form [formGroup]="mobSignupForm" (ngSubmit)="onSubmit()">
          <div class="form-group pr">
            <input type="text" [readonly]="successBtn" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" formControlName="mobilenum" name="mobilenum" class="form-control" placeholder="{{ 'FORM_GROUP.INPUT' | translate }}" 
            [ngClass]="{ 'is-invalid': submitted && f.mobilenum.errors }" />
            <p class="mt-3" *ngIf="successBtn"><small>Số điện thoại đã được gửi thành công!</small></p>
            <div *ngIf="submitted && f.mobilenum.errors" class="invalid-feedback">
              <div *ngIf="f.mobilenum.errors.required">
                {{ 'VALIDATORS.MOB_REQ' | translate }}
               </div>
              <div *ngIf="f.mobilenum.errors.minlength || f.mobilenum.errors.maxlength">
                {{ 'VALIDATORS.MOB_PATTERN' | translate }}
              </div> 
            </div>
            
            <button class="btn btn-primary" *ngIf="initialBtn">{{ 'FORM_GROUP.BUTTON' | translate }}</button>
            <button class="btn btn-primary success" *ngIf="successBtn"><i class="mdi mdi-check"></i></button>
          </div>
        </form>
        <div class="pannel">
          <span>{{ 'PANNEL.SMALL_TXT' | translate }}</span>
          <p><strong>{{ 'PANNEL.STRONG_TXT' | translate }}</strong></p>
          <h1>{{ 'PANNEL.NUMBER' | translate }}</h1>
        </div>
  </article>
</main>
</div>