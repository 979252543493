import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { CountryListService } from 'src/app/services/country-list.service';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-additional-sales-signup',
  templateUrl: './additional-sales-signup.component.html',
  styleUrls: ['./additional-sales-signup.component.scss']
})
export class AdditionalSalesSignupComponent implements OnInit {

  additionSalesForm: FormGroup;
  submitted = false;
  scancodeData: any;
  mobile_no: any;
  sessionIdValue: any
  content: any = [];
  milkCode: any;
  dataFromSalesForce: boolean = false;
  countryList: any = [];


  CRMretrievedData: any;
  reterivedSalesForceData: any;
  cityData: any = [];
  showInvalidContent = false;
  showValidContent = true;
  iSprintData: any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiService,
    private toastr: ToastrService, private spinner: NgxSpinnerService, private router: Router,
    private countryListData: CountryListService, private bsService: BugsnagService) {
    window.scrollTo({ top: 0 });

    this.countryList = this.countryListData.jsondata[0];
    console.log(this.countryList, 'this.countryList')
    this.cityData = Object.values(this.countryList);
    console.log(this.cityData, 'this.mainProvienceData')
    // this.cityData.sort()
    this.cityData.sort((a, b) => (a.display > b.display) ? 1 : -1)
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    console.log(this.iSprintData);
  }


  ngOnInit(): void {
    //  window.scrollTo({top: 0});s
    this.additionSalesForm = this.formBuilder.group({
      address: ['', Validators.required],
      cities: ['', Validators.required],
      district: ['', Validators.required],
      ward: ['', Validators.required]
    });
    this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    this.mobile_no = sessionStorage.getItem('mobileNo');
    this.sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    this.CRMretrievedData = sessionStorage.getItem('RegisterData') ? JSON.parse(sessionStorage.getItem('RegisterData')) : {};
    this.reterivedSalesForceData = sessionStorage.getItem('SalesForceFormValue') ? JSON.parse(sessionStorage.getItem('SalesForceFormValue')) : {};

    this.getProductDescCode();
  }

  getProductDescCode() {
    let result = JSON.parse(sessionStorage.getItem('SalesForceData'));
    if (result == 'true') {
      this.dataFromSalesForce = true
    }
    else {
      this.dataFromSalesForce = false;
    }
    this.milkCode = this.iSprintData?.is_milk_code
    console.log(this.milkCode)
    // this.content = this.scancodeData?.product?.txt;
    // if (this.content) {
    //   this.content.forEach((item: any) => {
    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;
    //     }
    //   });
    // }
    this.addConditionalValidators()

  }

  addConditionalValidators() {
    let address = this.additionSalesForm.get('address');
    if (this.milkCode == 1) {
      address.clearValidators();
    }
    else {
      address.setValidators([Validators.required]);
    }
    address.updateValueAndValidity();
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  get salesFormVal() {
    return this.additionSalesForm.controls;
  }

  onAdditionalSalesSubmit() {
    // Retrieve the object from storage
    this.submitted = true;
    if (this.additionSalesForm.invalid) {
      this.showInvalidContent = true;
      this.showValidContent = false;
      return;
    }
    if (this.additionSalesForm.valid) {
      let userData = this.additionSalesForm.value;
      let filledFormValues = this.getuserForm(userData);
      let authToken = sessionStorage.getItem('authToken');
      this.spinner.show();
      let updatecustresp;
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(filledFormValues)
      }
      this.apiService.updateCapCustomer(filledFormValues, this.mobile_no, authToken).pipe(first())
        .subscribe(res => {
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(filledFormValues)
          }
          updatecustresp = res;
          this.spinner.hide();
          if (res['status'].code == 200) {

            sessionStorage.removeItem('SalesForceData');
            sessionStorage.removeItem('SalesForceFormValue');
            sessionStorage.removeItem('RegisterData');
            sessionStorage.removeItem('salesforceApiResponse');

            sessionStorage.removeItem('firsttimegift')
            this.router.navigateByUrl('/signup-success')
          }
          else {
            console.log(res['status'].message)
            //this.toastr.info(res['status'].message)
          }
        }, err => {
          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          this.spinner.hide()
          console.log(err, '=== get customer error =======')
          this.bsService.notifyError(err, filledFormValues);
        });
    }
  }



  city = [
    {
      "city": "An Giang",
      "value": "AG",
      "districts": [
        {
          "name": "H. An Phú",
          "value": "AG.AP  ",
        },
        {
          "name": "H. Châu Phú",
          "value": "AG.CP",
        },
        {
          "name": "H. Châu Thành",
          "value": "AG.CT",
        },
        {
          "name": "H. Chợ Mới",
          "value": "AG.CM",
        },
        {
          "name": "H. Phú Tân",
          "value": "AG.PT",
        },
        {
          "name": "H. Thoại Sơn",
          "value": "AG.TS",
        },
        {
          "name": "H. Tịnh Biên",
          "value": "AG.TB",
        },
        {
          "name": "H. Tri Tôn  ",
          "value": "AG.TT  ",
        },
        {
          "name": "H. Vĩnh Trinh",
          "value": "AG.VT",
        },
        {
          "name": "TP. Châu Đốc",
          "value": "AG.TP.CD",
        },
        {
          "name": "TP. Long Xuyên",
          "value": "AG.TP.LX",
        },
        {
          "name": "TX. Tân Châu",
          "value": "AG.TX.TC",
        },
      ]
    },
    {
      "city": "Bà Rịa Vũng Tàu",
      "value": "BRVT",
      "districts": [
        {
          "name": "H. Châu Đức",
          "value": "BRVT.CD"
        },
        {
          "name": "H. Côn Đảo",
          "value": "BRVT.COD"
        },
        {
          "name": "H. Côn Đảo",
          "value": "BRVT.COD"
        },
        {
          "name": "H. Đất Đỏ",
          "value": "BRVT.DD"
        },
        {
          "name": "H. Long Đất",
          "value": "BRVT.LD"
        },
        {
          "name": "H. Long Điền",
          "value": "BRVT.LL"
        },
        {
          "name": "H. Tân Thành",
          "value": "BRVT.TT"
        },
        {
          "name": "H. Xuyên Mộc",
          "value": "BRVT.XM"
        },
        {
          "name": "TP. Bà Rịa",
          "value": "BRVT.TP.BR"
        },
        {
          "name": "TP. Vũng Tàu",
          "value": "BRVT.TP.VT"
        },
        {
          "name": "TX. Phú Mỹ",
          "value": "BRVT.TX.PM"
        },
      ]
    },
    {
      "city": "Bắc Cạn",
      "value": "BC",
      "districts": [
        {
          "name": "H. Ba Bể",
          "value": "BC.BB"
        },
        {
          "name": "H. Bạch Thông",
          "value": "BC.BT"
        },
        {
          "name": "H. Chợ Đồn",
          "value": "BC.CD"
        },
        {
          "name": "H. Chợ Mới",
          "value": "BC.CM"
        },
        {
          "name": "H. Na Rì",
          "value": "BC.NR  "
        },
        {
          "name": "H. Ngân Sơn",
          "value": "BC.NS"
        },
        {
          "name": "H. Pắc Nặm",
          "value": "BC.PN"
        },
        {
          "name": "TX. Bắc Cạn",
          "value": "BC.TX.BC"
        },
      ]
    },
    {
      "city": "Bắc Giang",
      "value": "BG",
      "districts": [
        {
          "name": "H. Hiệp Hòa",
          "value": "BG.HH"
        },
        {
          "name": "H. Lạng Giang",
          "value": "BG.LG"
        },
        {
          "name": "H. Lục Nam",
          "value": "BG.LM"
        },
        {
          "name": "H. Lục Ngạn",
          "value": "BG.LN"
        },
        {
          "name": "H. Sơn Động",
          "value": "BG.SD"
        },
        {
          "name": "H. Tân Yên",
          "value": "BG.TY"
        },
        {
          "name": "H. Việt Yên",
          "value": "BG.VY"
        },
        {
          "name": "H. Yên Dũng",
          "value": "BG.YD"
        },
        {
          "name": "H. Yên Thế",
          "value": "BG.YT"
        },
        {
          "name": "TP. Bắc Giang",
          "value": "BG.TP.BG"
        },
      ]
    },
    {
      "city": "Bạc Liêu",
      "value": "BL",
      "districts": [
        {
          "name": "H. Đông Hải",
          "value": "BL.DH"
        },
        {
          "name": "H. Hòa Bình",
          "value": "BL.HB"
        },
        {
          "name": "H. Hồng Dân",
          "value": "BL.HD"
        },
        {
          "name": "H. Phước Long",
          "value": "BL.PL"
        },
        {
          "name": "H. Thạnh Trị",
          "value": "BL.TT"
        },
        {
          "name": "H. Vĩnh Lợi",
          "value": "BL.VL"
        },
        {
          "name": "TP. Bạc Liêu",
          "value": "BL.TP.BL"
        },
        {
          "name": "TX. Giá Rai",
          "value": "BL.TX.GR"
        }
      ]
    },
    {
      "city": "Bắc Ninh",
      "value": "BN",
      "districts": [
        {
          "name": "H. Gia Bình",
          "value": "BN.GB"
        },
        {
          "name": "H. Lương Tài",
          "value": "BN.LT"
        },
        {
          "name": "H. Quế Võ",
          "value": "BN.QV"
        },
        {
          "name": "H. Thuận Thành",
          "value": "BN.TT"
        },
        {
          "name": "H. Tiên Du",
          "value": "BN.TD"
        },
        {
          "name": "H. Yên Phong",
          "value": "BN.YP"
        },
        {
          "name": "TP. Bắc Ninh",
          "value": "BN.TP.BN"
        },
        {
          "name": "TX. Từ Sơn",
          "value": "BN.TX.TS"
        }
      ]
    },
    {
      "city": "Bến Tre",
      "value": "BT",
      "districts": [
        {
          "name": "H. Ba Tri",
          "value": "BT.BT"
        },
        {
          "name": "H. Bình Đại",
          "value": "BT.BD"
        },
        {
          "name": "H. Châu Thành",
          "value": "BT.CT"
        },
        {
          "name": "H. Chợ Lách",
          "value": "BT.CL"
        },
        {
          "name": "H. Giồng Trôm",
          "value": "BT.GT"
        },
        {
          "name": "H. Mỏ Cày Bắc",
          "value": "BT.MCB"
        },
        {
          "name": "H. Mỏ Cày Nam",
          "value": "BT.MCN"
        },
        {
          "name": "H. Thạnh Phú",
          "value": "BT.TP"
        },
        {
          "name": "TP. Bến Tre",
          "value": "BT.TP.BT"
        }
      ]
    },
    {
      "city": "Bình Định",
      "value": "BH",
      "districts": [
        {
          "name": "H. An Lão",
          "value": "BH.AL"
        },
        {
          "name": "H. An Nhơn",
          "value": "BH.AN"
        },
        {
          "name": "H. Cư Bang",
          "value": "BH.CB"
        },
        {
          "name": "H. Hoài Ân",
          "value": "BH.HA"
        },
        {
          "name": "H. Hoài Nhơn",
          "value": "BH.HN"
        },
        {
          "name": "H. Phù Cát",
          "value": "BH.PC"
        },
        {
          "name": "H. Phù Mỹ",
          "value": "BH.PM"
        },
        {
          "name": "H. Tây Sơn",
          "value": "BH.TS"
        },
        {
          "name": "H. Tuy Phước",
          "value": "BH.TP"
        },
        {
          "name": "H. Vân Canh",
          "value": "BH.VC"
        },
        {
          "name": "H. Vĩnh Thạnh",
          "value": "BH.VT"
        },
        {
          "name": "TP. Quy Nhơn",
          "value": "BH.TP.QN"
        },
        {
          "name": "TX. An Nhơn",
          "value": "BH.TX.AN"
        },
      ]
    },
    {
      "city": "Bình Dương",
      "value": "BD",
      "districts": [
        {
          "name": "H. Bắc Tân Uyên",
          "value": "BD.BTU"
        },
        {
          "name": "H. Bàu Bàng",
          "value": "BD.BB"
        },
        {
          "name": "H. Dầu Tiếng",
          "value": "BD.DT"
        },
        {
          "name": "H. Lái Thiêu",
          "value": "BD.LT"
        },
        {
          "name": "H. Phú Giáo",
          "value": "BD.PG"
        },
        {
          "name": "H. Thuận An",
          "value": "BD.TA"
        },
        {
          "name": "TP. Mới",
          "value": "BD.TP.M"
        },
        {
          "name": "TP. Thủ Dầu Một",
          "value": "BD.TP.TD"
        },
        {
          "name": "TX. Bến Cát",
          "value": "BD.TX.BC"
        },
        {
          "name": "TX. Dĩ An",
          "value": "BD.TX.DA"
        },
        {
          "name": "TX. Tân Uyên",
          "value": "BD.TX.TU"
        },
        {
          "name": "TX. Thuận An",
          "value": "BD.TX.TA"
        }
      ]
    },

    {
      "city": "Bình Phước",
      "value": "BP",
      "districts": [
        {
          "name": "H. Bù Đăng",
          "value": "BP.BD"
        },
        {
          "name": "H. Bù Đốp",
          "value": "BP.BP"
        },
        {
          "name": "H. Bù Gia Mập",
          "value": "BP.BGM"
        },
        {
          "name": "H. Chơn Thành",
          "value": "BP.TT"
        },
        {
          "name": "H. Đồng Phú",
          "value": "BP.DP"
        },
        {
          "name": "H. Hớn Quản",
          "value": "BP.HQ"
        },
        {
          "name": "H. Lộc Ninh",
          "value": "BP.LN"
        },
        {
          "name": "H. Phú Riềng",
          "value": "BP.PR"
        },
        {
          "name": "TX. Bình Long",
          "value": "BP.TX.BL"
        },
        {
          "name": "TX. Đồng Xoài",
          "value": "BP.TX.DX"
        },
        {
          "name": "TX. Phước Long",
          "value": "BP.TX.PL"
        },
      ]
    },
    {
      "city": "Bình Thuận",
      "value": "BTN",
      "districts": [
        {
          "name": "H. Bắc Bình",
          "value": "BTN.BB"
        },
        {
          "name": "H. Đức Linh",
          "value": "BTN.DL"
        },
        {
          "name": "H. Hàm Tân",
          "value": "BTN.HT"
        },
        {
          "name": "H. Hàm Thuận Bắc",
          "value": "BTN.HTB"
        },
        {
          "name": "H. Hàm Thuận Nam",
          "value": "BTN.HTN"
        },
        {
          "name": "H. Phú Quý",
          "value": "BTN.PQ"
        },
        {
          "name": "H. Tánh Linh",
          "value": "BTN.TL"
        },
        {
          "name": "H. Tuy Phong",
          "value": "BTN.TP"
        },
        {
          "name": "TP. Phan Thiết",
          "value": "BTN.TP.PT"
        },
        {
          "name": "TX. La Gi",
          "value": "BTN.TX.LG"
        }
      ]
    },
    {
      "city": "Cà Mau",
      "value": "CM",
      "districts": [
        {
          "name": "H. Cái Nước",
          "value": "CM.CN"
        },
        {
          "name": "H. Đầm Dơi",
          "value": "CM.DD"
        },
        {
          "name": "H. Năm Căn",
          "value": "CM.NC"
        },
        {
          "name": "H. Ngọc Hiển",
          "value": "CM.NH"
        },
        {
          "name": "H. Phú Tân",
          "value": "CM.PT"
        },
        {
          "name": "H. Thới Bình",
          "value": "CM.TB"
        },
        {
          "name": "H. Trần Văn Thời",
          "value": "CM.TVT"
        },
        {
          "name": "H. U Minh",
          "value": "CM.UM"
        },
        {
          "name": "TP. Cà Mau",
          "value": "CM.TP.CM"
        },
      ]
    },
    {
      "city": "Cần Thơ",
      "value": "CT",
      "districts": [
        {
          "name": "H. Cờ Đỏ",
          "value": "CT.CD"
        },
        {
          "name": "H. Phong Điền",
          "value": "CT.PD"
        },
        {
          "name": "H. Thới Lai",
          "value": "CT.TL"
        },
        {
          "name": "H. Vĩnh Thạnh",
          "value": "CT.VT"
        },
        {
          "name": "Q. Bình Thủy",
          "value": "CT.BT"
        },
        {
          "name": "Q. Cái Răng",
          "value": "CT.CR"
        },
        {
          "name": "Q. Ninh Kiều",
          "value": "CT.NK"
        },
        {
          "name": "Q. Ô Môn",
          "value": "CT.OM"
        },
        {
          "name": "Q. Thốt Nốt",
          "value": "CT.TN"
        }
      ]
    },
    {
      "city": "Cao Bằng",
      "value": "CB",
      "districts": [
        {
          "name": "H. Bảo Lạc",
          "value": "CB.BL"
        },
        {
          "name": "H. Bảo Lâm",
          "value": "CB.BLM"
        },
        {
          "name": "H. Hạ Lang",
          "value": "CB.HL"
        },
        {
          "name": "H. Hà Quảng",
          "value": "CB.HQ"
        },
        {
          "name": "H. Hòa An",
          "value": "CB.HA"
        },
        {
          "name": "H. Nguyên Bình",
          "value": "CB.NB"
        },
        {
          "name": "H. Phục Hòa",
          "value": "CB.PH"
        },
        {
          "name": "H. Quảng Uyên",
          "value": "CB.QU"
        },
        {
          "name": "H. Thạch An",
          "value": "CB.TA"
        },
        {
          "name": "H. Thông Nông",
          "value": "CB.TN"
        },
        {
          "name": "H. Trà Lĩnh",
          "value": "CB.TL"
        },
        {
          "name": "H. Trùng Khánh",
          "value": "CB.TK"
        },
        {
          "name": "TP. Cao Bằng",
          "value": "CB.TP.CB"
        },
      ]
    },
    {
      "city": "Đà Nẵng",
      "value": "003",
      "districts": [
        {
          "name": "H. Hòa Vang",
          "value": "003.HV"
        },
        {
          "name": "H. Hoàng Sa",
          "value": "003.HS"
        },
        {
          "name": "Q. Cẩm Lệ",
          "value": "003.CL"
        },
        {
          "name": "Q. Hải Châu",
          "value": "003.HC"
        },
        {
          "name": "Q. Liên Chiểu",
          "value": "003.LC"
        },
        {
          "name": "Q. Ngũ Hành Sơn",
          "value": "003.NHS"
        },
        {
          "name": "Q. Sơn Trà",
          "value": "003.ST"
        }
      ]
    },
    {
      "city": "Đăk Lăk",
      "value": "DL",
      "districts": [
        {
          "name": "H. Buôn Đôn",
          "value": "DL.BD"
        },
        {
          "name": "H. Cư Kuin",
          "value": "DL.CK"
        },
        {
          "name": "H. Cư M'Gar",
          "value": "DL.CM"
        },
        {
          "name": "H. Ea H'Leo",
          "value": "DL.EL"
        },
        {
          "name": "H. Ea Kar",
          "value": "DL.EK"
        },
        {
          "name": "H. EA Súp",
          "value": "DL.ES"
        },
        {
          "name": "H. Krông Ana",
          "value": "DL.KA"
        },
        {
          "name": "H. Krông Bông",
          "value": "DL.KB"
        },
        {
          "name": "H. Krông Buk",
          "value": "DL.KK"
        },
        {
          "name": "H. Krông Năng",
          "value": "DL.FN"
        },
        {
          "name": "H. Krông Pắc",
          "value": "DL.KP"
        },
        {
          "name": "H. Lăk",
          "value": "DL.L"
        },
        {
          "name": "H. M'Đrăk",
          "value": "DL.MD"
        },
        {
          "name": "TP. Buôn Ma Thuột",
          "value": "DL.TP.BM"
        },
        {
          "name": "TX. Buôn Hồ",
          "value": "DL.BH"
        }
      ]
    },
    {
      "city": "Đăk Nông",
      "value": "DG",
      "districts": [
        {
          "name": "H. Cư Jut",
          "value": "DG.CJ"
        },
        {
          "name": "H. Đăk GLong",
          "value": "DG.DG"
        },
        {
          "name": "H. Đăk Mil",
          "value": "DG.DM"
        },
        {
          "name": "H. Đăk R'Lấp",
          "value": "DG.GL"
        },
        {
          "name": "H. Đăk Song",
          "value": "DG.DS"
        },
        {
          "name": "H. Krông Nô",
          "value": "DG.KN"
        },
        {
          "name": "H. Tuy Đức",
          "value": "DG.TD"
        },
        {
          "name": "TX. Gia Nghĩa",
          "value": "DG.TX.GN"
        }
      ]
    },
    {
      "city": "Điện Biên",
      "value": "DB",
      "districts": [
        {
          "name": "H. Điện Biên",
          "value": "DB.DB"
        },
        {
          "name": "H. Điện Biên Đông",
          "value": "DB.DBD"
        },
        {
          "name": "H. Mường Ảng",
          "value": "DB.MA"
        },
        {
          "name": "H. Mường Chà",
          "value": "DB.MC"
        },
        {
          "name": "H. Mường Nhé",
          "value": "DB.MN"
        },
        {
          "name": "H. Nậm Pồ",
          "value": "DB.NP"
        },
        {
          "name": "H. Thanh Uyên",
          "value": "DB.TU"
        },
        {
          "name": "H. Tủa Chùa",
          "value": "DB.TC"
        },
        {
          "name": "H. Tuần Giáo",
          "value": "DB.TG"
        },
        {
          "name": "TP. Điện Biên",
          "value": "DB.TP.DB"
        },
        {
          "name": "TX. Mường Lay",
          "value": "DB.TX.ML"
        }
      ]
    },
    {
      "city": "Đồng Tháp",
      "value": "DT",
      "districts": [
        {
          "name": "H. Cao Lãnh",
          "value": "DT.CL"
        },
        {
          "name": "H. Châu Thành",
          "value": "DT.CT"
        },
        {
          "name": "H. Hồng Hạnh",
          "value": "DT.HH"
        },
        {
          "name": "H. Hồng Ngự",
          "value": "DT.HN"
        },
        {
          "name": "H. Lai Vung",
          "value": "DT.LV"
        },
        {
          "name": "H. Lấp Vò",
          "value": "DT.LO"
        },
        {
          "name": "H. Tam Nông",
          "value": "DT.TN"
        },
        {
          "name": "H. Tân Hồng",
          "value": "DT.TH"
        },
        {
          "name": "H. Thanh Bình",
          "value": "DT.TB"
        },
        {
          "name": "H. Tháp Mười",
          "value": "DT.TM"
        },
        {
          "name": "TP. Cao Lãnh",
          "value": "DT.TP.CL"
        },
        {
          "name": "TP. Sa Đéc",
          "value": "DT.TP.SD"
        },
        {
          "name": "TX. Hồng Ngự",
          "value": "DT.TX.HN"
        }
      ]
    },
    {
      "city": "Gia Lai",
      "value": "GL",
      "districts": [
        {
          "name": "H. Chư Păh",
          "value": "GL.CP"
        },
        {
          "name": "H. Chư Prông",
          "value": "GL.CG"
        },
        {
          "name": "H. Chư Pưh",
          "value": "GL.CPU"
        },
        {
          "name": "H. Chư Sê",
          "value": "GL.CS"
        },
        {
          "name": "H. Đăk Đoa",
          "value": "GL.DD"
        },
        {
          "name": "H. Đăk Pơ",
          "value": "GL.DP"
        },
        {
          "name": "H. Đức Cơ",
          "value": "GL.DC"
        },
        {
          "name": "H. Ia Grai",
          "value": "GL.IG"
        },
        {
          "name": "H. Ia Pa",
          "value": "GL.IP"
        },
        {
          "name": "H. K'Bang",
          "value": "GL.K"
        },
        {
          "name": "H. Kông Chro",
          "value": "GL.KC"
        },
        {
          "name": "H. Krông Pa",
          "value": "GL.KP"
        },
        {
          "name": "H. Mang Yang",
          "value": "GL.MY"
        },
        {
          "name": "H. Phú Thiện",
          "value": "GL.PT"
        },
        {
          "name": "TP. Pleiku",
          "value": "GL.TP.P"
        },
        {
          "name": "TX. A Yun Pa",
          "value": "GL.TX.AYP"
        },
        {
          "name": "TX. An Khê",
          "value": "GL.TX.AK"
        }
      ]
    },
    {
      "city": "Hà Giang",
      "value": "HGG",
      "districts": [
        {
          "name": "H. Bắc Mê",
          "value": "HGG.BM"
        },
        {
          "name": "H. Bắc Quang",
          "value": "HGG.BQ"
        },
        {
          "name": "H. Đồng Văn",
          "value": "HGG.DV"
        },
        {
          "name": "H. Hoàng Su Phì",
          "value": "HGG.HSP"
        },
        {
          "name": "H. Mèo Vạc",
          "value": "HGG.MV"
        },
        {
          "name": "H. Quản Bạ",
          "value": "HGG.QB"
        },
        {
          "name": "H. Quang Bình",
          "value": "HGG.QH"
        },
        {
          "name": "H. Vị Xuyên",
          "value": "HGG.VX"
        },
        {
          "name": "H. Xín Mần",
          "value": "HGG.XM"
        },
        {
          "name": "H. Yên Minh",
          "value": "HGG.YM"
        },
        {
          "name": "TP. Hà Giang",
          "value": "HGG.TP.HG"
        }
      ]
    },
    {
      "city": "Hà Nam",
      "value": "HN",
      "districts": [
        {
          "name": "H. Bình Lục",
          "value": "HN.BL"
        },
        {
          "name": "H. Duy Tiên",
          "value": "HN.DT"
        },
        {
          "name": "H. Kim Bảng",
          "value": "HN.KB"
        },
        {
          "name": "H. Lý Nhân",
          "value": "HN.LN"
        },
        {
          "name": "H. Thanh Liêm",
          "value": "HN.TL"
        },
        {
          "name": "TP. Phủ Lý",
          "value": "HN.TP.PL"
        }
      ]
    },
    {
      "city": "Hà Nội",
      "value": "002",
      "districts": [
        {
          "name": "H. Ba Vì",
          "value": "002.BV"
        },
        {
          "name": "H. Chương Mỹ",
          "value": "002.CM"
        },
        {
          "name": "H. Đan Phượng",
          "value": "002.DP"
        },
        {
          "name": "H. Đông Anh",
          "value": "002.DA"
        },
        {
          "name": "H. Gia Lâm",
          "value": "002.GL"
        },
        {
          "name": "H. Hoài Đức",
          "value": "002.HD"
        },
        {
          "name": "H. Mê Linh",
          "value": "002.ML"
        },
        {
          "name": "H. Mỹ Đức",
          "value": "002.MD"
        },
        {
          "name": "H. Phú Xuyên",
          "value": "002.PX"
        },
        {
          "name": "H. Phúc Thọ",
          "value": "002.PT"
        },
        {
          "name": "H. Quốc Oai",
          "value": "002.QO	002.QO"
        },
        {
          "name": "H. Sóc Sơn",
          "value": "002.SS"
        },
        {
          "name": "H. Thạch Thất",
          "value": "002.HTT"
        },
        {
          "name": "H. Thanh Oai",
          "value": "002.TO"
        },
        {
          "name": "H. Thanh Trì",
          "value": "002.TT"
        },
        {
          "name": "H. Thường Tín",
          "value": "002.THT"
        },
        {
          "name": "H. Từ Liêm",
          "value": "002.TL"
        }, , ,
        {
          "name": "H. ứng Hòa",
          "value": "002.UH"
        },
        {
          "name": "Q. Ba Đình",
          "value": "002.BD"
        },
        {
          "name": "Q. Bắc Từ Liêm",
          "value": "002.BTL"
        },
        {
          "name": "Q. Cầu Giấy",
          "value": "002.CG"
        },
        {
          "name": "Q. Đống Đa",
          "value": "002.DD"
        },
        {
          "name": "Q. Hà Đông",
          "value": "002.QHD"
        },
        {
          "name": "Q. Hai Bà Trưng",
          "value": "002.HBT"
        },
        {
          "name": "Q. Hoàn Kiếm",
          "value": "002.HK"
        },
        {
          "name": "Q. Hoàng Mai",
          "value": "002.HM"
        },
        {
          "name": "Q. Long Biên",
          "value": "002.LB"
        },
        {
          "name": "Q. Nam Từ Liêm",
          "value": "002.NTL"
        },
        {
          "name": "Q. Tây Hồ",
          "value": "002.TH"
        },
        {
          "name": "Q. Thanh Xuân",
          "value": "002.TX"
        },
        {
          "name": "TX. Sơn Tây",
          "value": "002.TX.ST"
        }
      ]
    },
    {
      "city": "Hà Tĩnh",
      "value": "HAT",
      "districts": [
        {
          "name": "H. Cẩm Xuyên",
          "value": "HAT.CX"
        },
        {
          "name": "H. Can Lộc",
          "value": "HAT.CL"
        },
        {
          "name": "H. Đức Thọ",
          "value": "HAT.DT"
        },
        {
          "name": "H. Hương Khê",
          "value": "HAT.HK"
        },
        {
          "name": "H. Hương Sơn",
          "value": "HAT.HS"
        },
        {
          "name": "H. Lộc Hà",
          "value": "HAT.LH"
        },
        {
          "name": "H. Nghi Xuân",
          "value": "HAT.NX"
        },
        {
          "name": "H. Thạch Hà",
          "value": "HAT.TH"
        },
        {
          "name": "H. Vũ Quang",
          "value": "HAT.VQ"
        },
        {
          "name": "Tp. Hà Tĩnh",
          "value": "HAT.TP.HT"
        },
        {
          "name": "TX. Hồng Lĩnh",
          "value": "HAT.TX.HL"
        },
        {
          "name": "TX. Kỳ Anh",
          "value": "HAT.TX.KA"
        }
      ]
    },
    {
      "city": "Hải Dương",
      "value": "HD",
      "districts": [
        {
          "name": "H. Bình Giang",
          "value": "HD.BG"
        },
        {
          "name": "H. Cẩm Giàng",
          "value": "HD.CG"
        },
        {
          "name": "H. Đức Thọ",
          "value": "HAT.DT"
        },
        {
          "name": "H. Gia Lộc",
          "value": "HD.GL"
        },
        {
          "name": "H. Kim Thành",
          "value": "HD.KT"
        },
        {
          "name": "H. Kinh Môn",
          "value": "HD.KM"
        },
        {
          "name": "H. Nam Sách",
          "value": "HD.NS"
        },
        {
          "name": "H. Ninh Giang",
          "value": "HD.NG"
        },
        {
          "name": "H. Thanh Hà",
          "value": "HD.TH"
        },
        {
          "name": "H. Thanh Miện",
          "value": "HD.TM"
        },
        {
          "name": "H. Tứ Kỳ",
          "value": "HD.TK"
        },
        {
          "name": "TP. Hải Dương",
          "value": "HD.TP.HD"
        },
        {
          "name": "TX. Chí Linh",
          "value": "HD.TX.CL"
        }
      ]
    },
    {
      "city": "Hải Phòng",
      "value": "HP",
      "districts": [
        {
          "name": "H. An Dương",
          "value": "HP.AD"
        },
        {
          "name": "H. An Hải",
          "value": "HP.AN"
        },
        {
          "name": "H. An Lão",
          "value": "HP.AL"
        },
        {
          "name": "H. Bạch Long Vĩ",
          "value": "HP.BLV"
        },
        {
          "name": "H. Cát Hải",
          "value": "HP.CH"
        },
        {
          "name": "H. Kiến Thụy",
          "value": "HP.KT"
        },
        {
          "name": "H. Thủy Nguyên",
          "value": "HP.TN"
        },
        {
          "name": "H. Tiên Lãng",
          "value": "HP.TL"
        },
        {
          "name": "H. Vĩnh Bảo",
          "value": "HP.VB"
        },
        {
          "name": "Q. Đồ Sơn",
          "value": "HP.DS"
        },
        {
          "name": "Q. Dương Kinh",
          "value": "HP.DK"
        },
        {
          "name": "Q. Hải An",
          "value": "HP.HA"
        },
        {
          "name": "Q. Hồng Bàng",
          "value": "HP.HB"
        },
        {
          "name": "Q. Kiến An",
          "value": "HP.KA"
        },
        {
          "name": "Q. Lê Chân",
          "value": "HP.LC"
        },
        {
          "name": "Q. Ngô Quyền",
          "value": "HP.NQ"
        }
      ]
    },
    {
      "city": "Hậu Giang",
      "value": "HG",
      "districts": [
        {
          "name": "H. Châu Thành",
          "value": "HG.CT"
        },
        {
          "name": "H. Châu Thành A",
          "value": "HG.CTA"
        },
        {
          "name": "H. Phụng Hiệp",
          "value": "HG.PH"
        },
        {
          "name": "H. Vị Thủy",
          "value": "HG.VT"
        },
        {
          "name": "TP. Vị Thanh",
          "value": "HG.TP.VT"
        },
        {
          "name": "H. Kiến Thụy",
          "value": "HP.KT"
        },
        {
          "name": "TP. Vị Thanh",
          "value": "HG.TP.VT"
        },
        {
          "name": "H. Tiên Lãng",
          "value": "HP.TL"
        },
        {
          "name": "TX. Long Mỹ",
          "value": "HG.TX.LM"
        },
        {
          "name": "TX. Ngã Bảy",
          "value": "HG.TX.NB"
        }
      ]
    },
    {
      "city": "Hồ Chí Minh",
      "value": "001",
      "districts": [
        {
          "name": "H. Bình Chánh",
          "value": "001.BC"
        },
        {
          "name": "H. Cần Giờ",
          "value": "001.CG"
        },
        {
          "name": "H. Củ Chi",
          "value": "001.CC"
        },
        {
          "name": "H. Hóc Môn",
          "value": "001.HM"
        },
        {
          "name": "H. Nhà Bè",
          "value": "001.NB"
        },
        {
          "name": "Q. 1",
          "value": "001.01"
        },
        {
          "name": "Q. 10",
          "value": "001.10"
        },
        {
          "name": "Q. 11",
          "value": "001.11"
        },
        {
          "name": "Q. 12",
          "value": "001.12"
        },
        {
          "name": "Q. 2",
          "value": "001.02"
        },
        {
          "name": "Q. 3",
          "value": "001.03"
        },
        {
          "name": "Q. 4",
          "value": "001.04"
        },
        {
          "name": "Q. 5",
          "value": "001.05"
        },
        {
          "name": "Q. 6",
          "value": "001.06"
        },
        {
          "name": "Q. 7",
          "value": "001.07"
        },
        {
          "name": "Q. 8",
          "value": "001.08"
        },
        {
          "name": "Q. 9",
          "value": "001.09"
        },
        {
          "name": "Q. Bình Tân",
          "value": "001.BT"
        },
        {
          "name": "Q. Bình Thạnh",
          "value": "001.BT"
        },
        {
          "name": "Q. Gò Vấp",
          "value": "001.GV"
        },
        {
          "name": "Q. Phú Nhuận",
          "value": "001.PN"
        },
        {
          "name": "Q. Tân Bình",
          "value": "001.TB"
        },
        {
          "name": "Q. Tân Phú",
          "value": "001.TP"
        },
        {
          "name": "Q. Thủ Đức",
          "value": "001.TD"
        }
      ]
    },
    {
      "city": "Hòa Bình",
      "value": "HG",
      "districts": [
        {
          "name": "H. Cao Phong",
          "value": "HB.CP"
        },
        {
          "name": "H. Đà Bắc",
          "value": "HB.DB"
        },
        {
          "name": "H. Kim Bôi",
          "value": "HB.KB"
        },
        {
          "name": "H. Kỳ Sơn",
          "value": "HB.KS"
        },
        {
          "name": "H. Lạc Sơn",
          "value": "HB.LS"
        },
        {
          "name": "H. Lạc Thủy",
          "value": "HB.LT"
        },
        {
          "name": "H. Lương Sơn",
          "value": "HB.LN"
        },
        {
          "name": "H. Mai Châu",
          "value": "HB.MC"
        },
        {
          "name": "H. Tân Lạc",
          "value": "HB.TL"
        },
        {
          "name": "H. Yên Thủy",
          "value": "HB.YT"
        },
        {
          "name": "Tp. Hòa Bình",
          "value": "HB.TP.HB"
        }
      ]
    },
    {
      "city": "Hưng Yên",
      "value": "HG",
      "districts": [
        {
          "name": "H. Ân Thi",
          "value": "HY.AT"
        },
        {
          "name": "H. Khoái Châu",
          "value": "HY.KC"
        },
        {
          "name": "H. Kim Động",
          "value": "HY.KD"
        },
        {
          "name": "H. Mỹ Hào",
          "value": "HY.MH"
        },
        {
          "name": "H. Phù Cừ",
          "value": "HY.PC"
        },
        {
          "name": "H. Tiên Lữ",
          "value": "HY.TL"
        },
        {
          "name": "H. Văn Giang",
          "value": "HY.VG"
        },
        {
          "name": "H. Văn Lâm",
          "value": "HY.VL"
        },
        {
          "name": "H. Yên Mỹ",
          "value": "HY.YM"
        },
        {
          "name": "TP. Hưng Yên",
          "value": "HY.TP.HY"
        }
      ]
    },
    {
      "city": "Khánh Hòa",
      "value": "KH",
      "districts": [
        {
          "name": "H. Cam Lâm",
          "value": "KH.CL"
        },
        {
          "name": "H. Diên Khánh",
          "value": "KH.DK"
        },
        {
          "name": "H. Khánh Sơn",
          "value": "KH.KS"
        },
        {
          "name": "H. Khánh Vĩnh",
          "value": "KH.KV"
        },
        {
          "name": "H. Trường Sa",
          "value": "KH.TS"
        },
        {
          "name": "H. Vạn Ninh",
          "value": "KH.VN"
        },
        {
          "name": "TP. Cam Ranh",
          "value": "KH.TP.CR"
        },
        {
          "name": "TP. Nha Trang",
          "value": "KH.TP.NT"
        },
        {
          "name": "TX. Ninh Hòa",
          "value": "KH.TX.NH"
        }
      ]
    },
    {
      "city": "Kiên Giang",
      "value": "KG",
      "districts": [
        {
          "name": "H. An Biên",
          "value": "KG.AB"
        },
        {
          "name": "H. An Minh",
          "value": "KG.AM"
        },
        {
          "name": "H. Châu Thành",
          "value": "KG.CT"
        },
        {
          "name": "H. Giang Thành",
          "value": "KG.GT"
        },
        {
          "name": "H. Giồng Riềng",
          "value": "KG.GR"
        },
        {
          "name": "H. Gò Quao",
          "value": "KG.GQ"
        },
        {
          "name": "H. Hòn Đất",
          "value": "KG.HD"
        },
        {
          "name": "H. Kiên Biên",
          "value": "KG.KB"
        },
        {
          "name": "H. Kiên Hải",
          "value": "KG.KH"
        },
        {
          "name": "H. Kiên Lương",
          "value": "KG.KL"
        },
        {
          "name": "H. Phú Quốc",
          "value": "KG.PQ"
        },
        {
          "name": "H. Tân Hiệp",
          "value": "KG.TH"
        },
        {
          "name": "H. U Minh Thượng",
          "value": "KG.UMT"
        },
        {
          "name": "TP. Rạch Giá",
          "value": "KG.RG"
        },
        {
          "name": "TX. Hà Tiên",
          "value": "KG.TX.HT"
        }
      ]
    },
    {
      "city": "Kon Tum",
      "value": "KT",
      "districts": [
        {
          "name": "H. Đăk Glei",
          "value": "KT.DG"
        },
        {
          "name": "H. Đăk Hà",
          "value": "KT.DH"
        },
        {
          "name": "H. Đăk Tô",
          "value": "KT.DT"
        },
        {
          "name": "H. Ia H'Drai",
          "value": "KT.IH"
        },
        {
          "name": "H. Kon Plông",
          "value": "KT.KP"
        },
        {
          "name": "H. Kông Rẫy",
          "value": "KT.KR"
        },
        {
          "name": "H. Ngọc Hồi",
          "value": "KT.NH"
        },
        {
          "name": "H. Sa Thầy",
          "value": "KT.ST"
        },
        {
          "name": "H. Tu Mơ Rông",
          "value": "KT.TM"
        },
        {
          "name": "TP. Kon Tum",
          "value": "KT.TP.KT"
        }
      ]
    },
    {
      "city": "Lai Châu",
      "value": "LC",
      "districts": [
        {
          "name": "H. Mường Tè",
          "value": "LC.MT"
        },
        {
          "name": "H. Nậm Nhùn",
          "value": "LC.NN"
        },
        {
          "name": "H. Phong Thổ",
          "value": "LC.PT"
        },
        {
          "name": "H. Sìn Hồ",
          "value": "LC.SH"
        },
        {
          "name": "H. Tam Đường",
          "value": "LC.TD"
        },
        {
          "name": "H. Tân Uyên",
          "value": "LC.TU"
        },
        {
          "name": "H. Than Uyên",
          "value": "LC.TY"
        },
        {
          "name": "TP. Lai Châu",
          "value": "LC.TP.LC"
        },
        {
          "name": "H. Tu Mơ Rông",
          "value": "KT.TM"
        },
        {
          "name": "TP. Kon Tum",
          "value": "KT.TP.KT"
        }
      ]
    },
    {
      "city": "Lâm Đồng",
      "value": "LD",
      "districts": [
        {
          "name": "H. Bảo Lâm",
          "value": "LD.BL"
        },
        {
          "name": "H. Cát Tiên",
          "value": "LD.CT"
        },
        {
          "name": "H. Đạ Huoai",
          "value": "LD.DH"
        },
        {
          "name": "H. Đạ Tẻh",
          "value": "LD.DT"
        },
        {
          "name": "H. Đam Rông",
          "value": "LD.DR"
        },
        {
          "name": "H. Di Linh",
          "value": "LD.DL"
        },
        {
          "name": "H. Đơn Dương",
          "value": "LD.DD"
        },
        {
          "name": "H. Đức Trọng",
          "value": "LD.DG"
        },
        {
          "name": "H. Lạc Dương",
          "value": "LD.LD"
        },
        {
          "name": "H. Lâm Hà",
          "value": "LD.LH"
        },
        {
          "name": "TP. Bảo Lộc",
          "value": "LD.TP.BL"
        },
        {
          "name": "TP. Đà Lạt",
          "value": "LD.TP.DL"
        },
        {
          "name": "H. Lâm Hà",
          "value": "LD.LH"
        }
      ]
    },
    {
      "city": "Lạng Sơn",
      "value": "LS",
      "districts": [
        {
          "name": "H. Bắc Sơn",
          "value": "LS.BS"
        },
        {
          "name": "H. Bình Gia",
          "value": "LS.BG"
        },
        {
          "name": "H. Cao Lộc",
          "value": "LS.CL"
        },
        {
          "name": "H. Chi Lăng",
          "value": "LS.CG"
        },
        {
          "name": "H. Đình Lập",
          "value": "LS.DL"
        },
        {
          "name": "H. Hữu Lũng",
          "value": "LS.HL"
        },
        {
          "name": "H. Lộc Bình",
          "value": "LS.LB"
        },
        {
          "name": "H. Tràng Định",
          "value": "LS.TD"
        },
        {
          "name": "H. Văn Lãng",
          "value": "LS.VL"
        },
        {
          "name": "H. Văn Quan",
          "value": "LS.VQ"
        },
        {
          "name": "TP. Lạng Sơn",
          "value": "LS.TP.LS"
        }
      ]
    },
    {
      "city": "Lào Cai",
      "value": "LI",
      "districts": [
        {
          "name": "H. Bắc Hà",
          "value": "LI.BH"
        },
        {
          "name": "H. Bảo Thắng",
          "value": "LI.BT"
        },
        {
          "name": "H. Bảo Yên",
          "value": "LI.BY"
        },
        {
          "name": "H. Bát Xát",
          "value": "LI.BX"
        },
        {
          "name": "H. Mường Khương",
          "value": "LI.MK"
        },
        {
          "name": "H. Sa Pa",
          "value": "LI.SP"
        },
        {
          "name": "H. Si Ma Cai",
          "value": "LI.SMC"
        },
        {
          "name": "H. Văn Bàn",
          "value": "LI.VB"
        },
        {
          "name": "TP. Lào Cai",
          "value": "LI.TP.LC"
        }
      ]
    },
    {
      "city": "Long An",
      "value": "LA",
      "districts": [
        {
          "name": "H. Bến Lức",
          "value": "LA.BL"
        },
        {
          "name": "H. Cần Đước",
          "value": "LA.CD"
        },
        {
          "name": "H. Cần Giuộc",
          "value": "LA.CG"
        },
        {
          "name": "H. Châu Thành",
          "value": "LA.CT"
        },
        {
          "name": "H. Đức Hòa",
          "value": "LA.DH"
        },
        {
          "name": "H. Đức Huệ",
          "value": "LA.DE"
        },
        {
          "name": "H. Mộc Hóa",
          "value": "LA.MH"
        },
        {
          "name": "H. Tân Hưng",
          "value": "LA.TH"
        },
        {
          "name": "H. Tân Thạnh",
          "value": "LA.TT"
        },
        {
          "name": "H. Tân Trụ",
          "value": "LA.TU"
        },
        {
          "name": "H. Thạnh Hóa",
          "value": "LA.TA"
        },
        {
          "name": "H. Thủ Thừa",
          "value": "LA.TTA"
        },
        {
          "name": "H. Vĩnh Hưng",
          "value": "LA.VH"
        },
        {
          "name": "TP. Tân An",
          "value": "LA.TP.TA"
        },
        {
          "name": "TX. Kiến Tường",
          "value": "LA.TX.KT"
        }
      ]
    },
    {
      "city": "Nam Định",
      "value": "ND",
      "districts": [
        {
          "name": "H. Giao Thủy",
          "value": "ND.GT"
        },
        {
          "name": "H. Hải Hậu",
          "value": "ND.HH"
        },
        {
          "name": "H. Mỹ Lộc",
          "value": "ND.ML"
        },
        {
          "name": "H. Nam Trực",
          "value": "ND.NT"
        },
        {
          "name": "H. Nghĩa Hưng",
          "value": "ND.NH"
        },
        {
          "name": "H. Trực Ninh",
          "value": "ND.TN"
        },
        {
          "name": "H. Vụ Bản",
          "value": "ND.VB"
        },
        {
          "name": "H. Xuân Trường",
          "value": "ND.XT"
        },
        {
          "name": "H. ý Yên",
          "value": "ND.YY"
        },
        {
          "name": "TP. Nam Định",
          "value": "ND.TP.ND"
        }
      ]
    },
    {
      "city": "Nghệ An",
      "value": "NA",
      "districts": [
        {
          "name": "H. Anh Sơn",
          "value": "NA.AS"
        },
        {
          "name": "H. Con Cuông",
          "value": "NA.CC"
        },
        {
          "name": "H. Diễn Châu",
          "value": "NA.DC"
        },
        {
          "name": "H. Đô Lương",
          "value": "NA.DL"
        },
        {
          "name": "H. Hưng Nguyên",
          "value": "NA.HN"
        },
        {
          "name": "H. Kỳ Sơn",
          "value": "NA.KS"
        },
        {
          "name": "H. Nam Đàn",
          "value": "NA.ND"
        },
        {
          "name": "H. Nghi Lộc",
          "value": "NA.NL"
        },
        {
          "name": "H. Nghĩa Đàn",
          "value": "NA.NGD"
        },
        {
          "name": "H. Quế Phong",
          "value": "NA.QP"
        },
        {
          "name": "H. Quỳ Châu",
          "value": "NA.QC"
        },
        {
          "name": "H. Quỳ Hợp",
          "value": "NA.QH"
        },
        {
          "name": "H. Quỳnh Lưu",
          "value": "NA.QL"
        },
        {
          "name": "H. Tân Kỳ",
          "value": "NA.TK"
        },
        {
          "name": "H. Tân Quỳ",
          "value": "NA.TQ"
        },
        {
          "name": "H. Thanh Chương",
          "value": "NA.TC"
        },
        {
          "name": "H. Tương Dương",
          "value": "NA.TD"
        },
        {
          "name": "H. Yên Thành",
          "value": "NA.YT"
        },
        {
          "name": "TP. Vinh",
          "value": "NA.TP.V"
        },
        {
          "name": "TX. Cửa Lò",
          "value": "NA.CL"
        },
        {
          "name": "TX. Hoàng Mai",
          "value": "NA.TX.HM"
        },
        {
          "name": "TX. Thái Hòa",
          "value": "NA.TX.TH"
        }
      ]
    },
    {
      "city": "Ninh Bình",
      "value": "NB",
      "districts": [
        {
          "name": "H. Gia Viễn",
          "value": "NB.GV"
        },
        {
          "name": "H. Hoa Lưg",
          "value": "NB.HL"
        },
        {
          "name": "H. Kim Sơn",
          "value": "NB.KS"
        },
        {
          "name": "H. Nho Quan",
          "value": "NB.NQ"
        },
        {
          "name": "H. Yên Khánh",
          "value": "NB.YK"
        },
        {
          "name": "H. Yên Mô",
          "value": "NA.KS"
        },
        {
          "name": "TP. Ninh Bình",
          "value": "NB.TP.NB"
        },
        {
          "name": "H. Nghi Lộc",
          "value": "NA.NL"
        },
        {
          "name": "TP. Tam Điệp",
          "value": "NB.TP.TD"
        },
        {
          "name": "TX. Ninh Bình",
          "value": "NB.TX.NB"
        }
      ]
    },
    {
      "city": "Ninh Bình",
      "value": "NB",
      "districts": [
        {
          "name": "H. Gia Viễn",
          "value": "NB.GV"
        },
        {
          "name": "H. Hoa Lưg",
          "value": "NB.HL"
        },
        {
          "name": "H. Kim Sơn",
          "value": "NB.KS"
        },
        {
          "name": "H. Nho Quan",
          "value": "NB.NQ"
        },
        {
          "name": "H. Yên Khánh",
          "value": "NB.YK"
        },
        {
          "name": "H. Yên Mô",
          "value": "NA.KS"
        },
        {
          "name": "TP. Ninh Bình",
          "value": "NB.TP.NB"
        },
        {
          "name": "H. Nghi Lộc",
          "value": "NA.NL"
        },
        {
          "name": "TP. Tam Điệp",
          "value": "NB.TP.TD"
        },
        {
          "name": "TX. Ninh Bình",
          "value": "NB.TX.NB"
        }
      ]
    },
    {
      "city": "Ninh Thuận",
      "value": "NT",
      "districts": [
        {
          "name": "H. Bác ái",
          "value": "NT.BA"
        },
        {
          "name": "H. Ninh Hải",
          "value": "NT.NH"
        },
        {
          "name": "H. Ninh Hải",
          "value": "NT.NH"
        },
        {
          "name": "H. Ninh Phước",
          "value": "NT.NP"
        },
        {
          "name": "H. Ninh Sơn",
          "value": "NT.NS"
        },
        {
          "name": "H. Thuận Bắc",
          "value": "NT.TB"
        },
        {
          "name": "H. Thuận Nam",
          "value": "NT.TN"
        },
        {
          "name": "TP. Phan Rang Tháp Chàm",
          "value": "NT.TP.PRTC"
        }
      ]
    },
    {
      "city": "Phú Thọ",
      "value": "PT",
      "districts": [
        {
          "name": "Phú Thọ",
          "value": "PT.CK"
        },
        {
          "name": "H. Đoan Hùng",
          "value": "PT.DH"
        },
        {
          "name": "H. Hạ Hòa",
          "value": "PT.HH"
        },
        {
          "name": "H. Lâm Thao",
          "value": "PT.LT"
        },
        {
          "name": "H. Phù Ninh",
          "value": "PT.PN"
        },
        {
          "name": "H. Sông Thao",
          "value": "PT.ST"
        },
        {
          "name": "H. Tam Nông",
          "value": "PT.TN"
        },
        {
          "name": "H. Tân Sơn",
          "value": "PT.TS"
        },
        {
          "name": "H. Thanh Thủy",
          "value": "PT.TT"
        },
        {
          "name": "H. Yên Lập",
          "value": "PT.YL"
        },
        {
          "name": "TP. Việt Trì",
          "value": "PT.TP.VT"
        },
        {
          "name": "TX. Phú Thọ",
          "value": "PT.TX.PT"
        }
      ]
    },
    {
      "city": "Phú Yên",
      "value": "PY",
      "districts": [
        {
          "name": "H. Đông Hòa",
          "value": "PY.DH"
        },
        {
          "name": "H. Đoan Hùng",
          "value": "PT.DH"
        },
        {
          "name": "H. Đồng Xuân",
          "value": "PY.DX"
        },
        {
          "name": "H. Phú Hòa",
          "value": "PY.PH"
        },
        {
          "name": "H. Sơn Hòa",
          "value": "PY.SA"
        },
        {
          "name": "H. Sông Hinh",
          "value": "PY.SH"
        },
        {
          "name": "H. Tây Hòa",
          "value": "PY.THA"
        },
        {
          "name": "H. Tuy An",
          "value": "PY.TA"
        },
        {
          "name": "TP. Tuy Hòa",
          "value": "PY.TP.TH"
        },
        {
          "name": "TX. Sông Cầu",
          "value": "PY.TX.SC"
        }
      ]
    },
    {
      "city": "Quảng Bình",
      "value": "QB",
      "districts": [
        {
          "name": "H. Bố Trạch",
          "value": "QB.BT"
        },
        {
          "name": "H. Lệ Thủy",
          "value": "QB.LT"
        },
        {
          "name": "H. Minh Hóa",
          "value": "QB.MH"
        },
        {
          "name": "H. Quảng Ninh",
          "value": "QB.QN"
        },
        {
          "name": "H. Quảng Trạch",
          "value": "QB.QT"
        },
        {
          "name": "H. Tuyên Hóa",
          "value": "QB.TH"
        },
        {
          "name": "TP. Đồng Hới",
          "value": "QB.TP.DH"
        },
        {
          "name": "TX. Ba Đồn",
          "value": "QB.TX.BD"
        }
      ]
    },
    {
      "city": "Quảng Nam",
      "value": "QM",
      "districts": [
        {
          "name": "H. Bắc Trà My",
          "value": "QM.BTM"
        },
        {
          "name": "H. Đại Lộc",
          "value": "QM.DL"
        },
        {
          "name": "H. Đông Giang",
          "value": "QM.DG"
        },
        {
          "name": "H. Duy Xuyên",
          "value": "QM.DX"
        },
        {
          "name": "H. Hiệp Đức",
          "value": "QM.HD"
        },
        {
          "name": "H. Nam Giang",
          "value": "QM.NG"
        },
        {
          "name": "H. Nam Trà My",
          "value": "QM.NTM"
        },
        {
          "name": "H. Nông Sơn",
          "value": "QM.NS"
        },
        {
          "name": "H. Núi Thành",
          "value": "QM.NT"
        },
        {
          "name": "H. Phú Ninh",
          "value": "QM.PN"
        },
        {
          "name": "H. Phước Sơn",
          "value": "QM.PS"
        },
        {
          "name": "H. Quế Sơn",
          "value": "QM.QS"
        },
        {
          "name": "H. Tây Giang",
          "value": "QM.TG"
        },
        {
          "name": "H. Thăng Bình",
          "value": "QM.TB"
        },
        {
          "name": "H. Tiên Phước",
          "value": "QM.TP"
        },
        {
          "name": "TP. Hội An",
          "value": "QM.TP.HA"
        },
        {
          "name": "TP. Tam Kỳ",
          "value": "QM.TP.TK"
        },
        {
          "name": "TX. Điện Bàn",
          "value": "QM.TX.DB"
        }
      ]
    },
    {
      "city": "Quảng Ngãi",
      "value": "QI",
      "districts": [
        {
          "name": "H. Ba Tơ",
          "value": "QI.BT"
        },
        {
          "name": "H. Bình Sơn",
          "value": "QI.BS"
        },
        {
          "name": "H. Đức Phổ",
          "value": "QI.DP"
        },
        {
          "name": "H. Lý Sơn",
          "value": "QI.LS"
        },
        {
          "name": "H. Minh Long",
          "value": "QI.ML"
        },
        {
          "name": "H. Mộ Đức",
          "value": "QI.MD"
        },
        {
          "name": "H. Nghĩa Hành",
          "value": "QI.NH"
        },
        {
          "name": "H. Sơn Hà",
          "value": "QI.SH"
        },
        {
          "name": "H. Sơn Tây",
          "value": "QI.ST"
        },
        {
          "name": "H. Sơn Tịnh",
          "value": "QI.SOT"
        },
        {
          "name": "H. Tây Trà",
          "value": "QI.TT"
        },
        {
          "name": "H. Trà Bồng",
          "value": "QI.TB"
        },
        {
          "name": "H. Tư Nghĩa",
          "value": "QI.TN"
        },
        {
          "name": "TP. Quảng Ngãi",
          "value": "QI.TP.QN"
        }
      ]
    },
    {
      "city": "Quảng Ninh",
      "value": "QN",
      "districts": [
        {
          "name": "H. Ba Chẽ",
          "value": "QN.BC"
        },
        {
          "name": "H. Bình Liêu",
          "value": "QN.BL"
        },
        {
          "name": "H. Cô Tô",
          "value": "QN.CT"
        },
        {
          "name": "H. Đầm Hà",
          "value": "QN.DH"
        },
        {
          "name": "H. Đông Triều",
          "value": "QN.DT"
        },
        {
          "name": "H. Hải Hà",
          "value": "QN.HH"
        },
        {
          "name": "H. Hoành Bồ",
          "value": "QN.HB"
        },
        {
          "name": "H. Tiên Yên",
          "value": "QN.TY"
        },
        {
          "name": "H. Vân Đồn",
          "value": "QN.VD"
        },
        {
          "name": "TP. Cẩm Phả",
          "value": "QN.TP.CP"
        },
        {
          "name": "TP. Hạ Long",
          "value": "QN.TP.HL"
        },
        {
          "name": "TP. Hòn Gai",
          "value": "QN.TP.HG"
        },
        {
          "name": "TP. Móng Cái",
          "value": "QN.TP.MC"
        },
        {
          "name": "TP. Uông Bí",
          "value": "QN.TP.UB"
        },
        {
          "name": "TX. Quảng Yên",
          "value": "QN.TX.QY"
        }
      ]
    },
    {
      "city": "Quảng Trị",
      "value": "QT",
      "districts": [
        {
          "name": "H. Cam Lộ",
          "value": "QT.CL"
        },
        {
          "name": "H. Cổn Cỏ",
          "value": "QT.CC"
        },
        {
          "name": "H. Đakrông",
          "value": "QT.D"
        },
        {
          "name": "H. Gio Linh",
          "value": "QT.GL"
        },
        {
          "name": "H. Hải Lăng",
          "value": "QT.HL"
        },
        {
          "name": "H. Hướng Hóa",
          "value": "QT.HH"
        },
        {
          "name": "H. Triệu Phong",
          "value": "QT.TP"
        },
        {
          "name": "H. Vĩnh Linh",
          "value": "QT.VL"
        },
        {
          "name": "TP. Đông Hà",
          "value": "QT.TP.DH"
        },
        {
          "name": "TX. Quảng Trị",
          "value": "QT.TX.QT"
        }
      ]
    },
    {
      "city": "Sóc Trăng",
      "value": "ST",
      "districts": [
        {
          "name": "H. Cần Đề",
          "value": "ST.CD"
        },
        {
          "name": "H. Châu Thành",
          "value": "ST.CT"
        },
        {
          "name": "H. Cù Lao Dung",
          "value": "ST.CLD"
        },
        {
          "name": "H. Hòa Mỹ",
          "value": "ST.HM"
        },
        {
          "name": "H. Kế Sách",
          "value": "ST.KS"
        },
        {
          "name": "H. Long Phú",
          "value": "ST.LP"
        },
        {
          "name": "H. Mỹ Tú",
          "value": "ST.MT"
        },
        {
          "name": "H. Mỹ Xuyên",
          "value": "ST.MX"
        },
        {
          "name": "H. Thạnh Trị",
          "value": "ST.TT"
        },
        {
          "name": "H. Trần Đề",
          "value": "ST.TD"
        },
        {
          "name": "TP. Sóc Trăng",
          "value": "ST.TP.ST"
        },
        {
          "name": "TX. Ngã Năm",
          "value": "ST.TX.NN"
        },
        {
          "name": "TX. Vĩnh Châu",
          "value": "ST.TX.VC"
        }
      ]
    },
    {
      "city": "Sơn La",
      "value": "SL",
      "districts": [
        {
          "name": "H. Bắc Yên",
          "value": "SL.BY"
        },
        {
          "name": "H. Mai Sơn",
          "value": "SL.MS"
        },
        {
          "name": "H. Mộc Châu",
          "value": "SL.MC"
        },
        {
          "name": "H. Mường La",
          "value": "SL.ML"
        },
        {
          "name": "H. Phù Yên",
          "value": "SL.PY"
        },
        {
          "name": "H. Quỳnh Nhai",
          "value": "SL.QN"
        },
        {
          "name": "H. Sông Mã",
          "value": "SL.SM"
        },
        {
          "name": "H. Sốp Cộp",
          "value": "SL.SC"
        },
        {
          "name": "H. Thuận Châu",
          "value": "SL.TC"
        },
        {
          "name": "H. Vân Hồ",
          "value": "SL.VH"
        },
        {
          "name": "H. Yên Châu",
          "value": "SL.YC"
        },
        {
          "name": "TP. Sơn La",
          "value": "SL.TP.SL"
        }
      ]
    },
    {
      "city": "Tây Ninh",
      "value": "TN",
      "districts": [
        {
          "name": "H. Bến Cầu",
          "value": "TN.BC"
        },
        {
          "name": "H. Châu Thành",
          "value": "TN.CT"
        },
        {
          "name": "H. Dương Minh Châu",
          "value": "TN.DMC"
        },
        {
          "name": "H. Gò Dầu",
          "value": "TN.GD"
        },
        {
          "name": "H. Hòa Thành",
          "value": "TN.HT"
        },
        {
          "name": "H. Tân Biên",
          "value": "TN.TB"
        },
        {
          "name": "H. Tân Châu",
          "value": "TN.TC"
        },
        {
          "name": "H. Trảng Bàng",
          "value": "TN.TG"
        },
        {
          "name": "TP. Tây Ninh",
          "value": "TN.TP.TN"
        }
      ]
    },
    {
      "city": "Thái Bình",
      "value": "TB",
      "districts": [
        {
          "name": "H. Đông Hưng",
          "value": "TB.DH"
        },
        {
          "name": "H. Hưng Hà",
          "value": "TB.HH"
        },
        {
          "name": "H. Kiến Xương",
          "value": "TB.KX"
        },
        {
          "name": "H. Quỳnh Phụ",
          "value": "TB.QP"
        },
        {
          "name": "H. Thái Thụy",
          "value": "TB.TT"
        },
        {
          "name": "H. Tiền Hải",
          "value": "TB.TH"
        },
        {
          "name": "H. Vũ Thư",
          "value": "TB.VT"
        },
        {
          "name": "TP. Thái Bình",
          "value": "TB.TP.TB"
        }
      ]
    },
    {
      "city": "Thái Nguyên",
      "value": "THN",
      "districts": [
        {
          "name": "H. Đại Từ",
          "value": "THN.DT"
        },
        {
          "name": "H. Định Hóa",
          "value": "THN.DH"
        },
        {
          "name": "H. Đồng Hỷ",
          "value": "THN.DY"
        },
        {
          "name": "H. Phú Bình",
          "value": "THN.PB"
        },
        {
          "name": "H. Phú Lương",
          "value": "THN.PL"
        },
        {
          "name": "H. Võ Nhai",
          "value": "THN.VN"
        },
        {
          "name": "TP. Thái Nguyên",
          "value": "THN.TP.TN"
        },
        {
          "name": "TX. Phổ Yên",
          "value": "THN.PY"
        },
        {
          "name": "TX. Sông Công",
          "value": "THN.TX.SC"
        }
      ]
    },
    {
      "city": "Thanh Hóa",
      "value": "TH",
      "districts": [
        {
          "name": "H. Bá Thước",
          "value": "TH.BT"
        },
        {
          "name": "H. Cẩm Thủy",
          "value": "TH.CT"
        },
        {
          "name": "H. Đông Sơn",
          "value": "TH.DS"
        },
        {
          "name": "H. Hà Hải",
          "value": "TH.HI"
        },
        {
          "name": "H. Hà Trung",
          "value": "TH.HT"
        },
        {
          "name": "H. Hậu Lộc",
          "value": "TH.HL"
        },
        {
          "name": "H. Hoằng Hóa",
          "value": "TH.HH"
        },
        {
          "name": "H. Lang Chánh",
          "value": "TH.LC"
        },
        {
          "name": "H. Mường Lát",
          "value": "TH.ML"
        },
        {
          "name": "H. Nga Sơn",
          "value": "TH.NS"
        },
        {
          "name": "H. Ngọc Lặc",
          "value": "TH.NL"
        },
        {
          "name": "H. Như Thanh",
          "value": "TH.NT"
        },
        {
          "name": "H. Như Xuân",
          "value": "TH.NX"
        },
        {
          "name": "H. Như Thanh",
          "value": "TH.NX"
        },
        {
          "name": "H. Nông Cống",
          "value": "TH.NC"
        },
        {
          "name": "H. Quan Hóa",
          "value": "TH.QH"
        },
        {
          "name": "H. Quang Sơn",
          "value": "TH.QS"
        },
        {
          "name": "H. Quảng Xương",
          "value": "TH.QX"
        },
        {
          "name": "H. Thạch Thành",
          "value": "TH.TT"
        },
        {
          "name": "H. Thiệu Hóa",
          "value": "TH.TH"
        },
        {
          "name": "H. Thọ Xuân",
          "value": "TH.TX"
        },
        {
          "name": "H. Thường Xuân",
          "value": "TH.THX"
        },
        {
          "name": "H. Tĩnh Gia",
          "value": "TH.TG"
        },
        {
          "name": "H. Triệu Sơn",
          "value": "TH.TS"
        },
        {
          "name": "H. Vĩnh Lộc",
          "value": "TH.VL"
        },
        {
          "name": "H. Yên Định",
          "value": "TH.YD"
        },
        {
          "name": "TP. Thanh Hóa",
          "value": "TH.TP.TH"
        },
        {
          "name": "TX. Bỉm Sơn",
          "value": "TH.TX.BS"
        },
        {
          "name": "TX. Sầm Sơn",
          "value": "TH.TX.SS"
        }
      ]
    },
    {
      "city": "Thừa Thiên Huế",
      "value": "TTH",
      "districts": [
        {
          "name": "H. A Lưới",
          "value": "TTH.AL"
        },
        {
          "name": "H. Hội An",
          "value": "TTH.HN"
        },
        {
          "name": "H. Nam Đông",
          "value": "TTH.ND"
        },
        {
          "name": "H. Núi Thành",
          "value": "TTH.NT"
        },
        {
          "name": "H. Phong Điền",
          "value": "TTH.PD"
        },
        {
          "name": "H. Phú Lộc",
          "value": "TTH.PL"
        },
        {
          "name": "H. Phú Vang",
          "value": "TTH.PV"
        },
        {
          "name": "H. Quảng Điền",
          "value": "TTH.QD"
        },
        {
          "name": "TP. Huế",
          "value": "TTH.TP.H"
        },
        {
          "name": "TX. Hương Thủy",
          "value": "TTH.TX.HT"
        },
        {
          "name": "TX. Hương Trà",
          "value": "TTH.TX.HA"
        }
      ]
    },
    {
      "city": "Tiền Giang",
      "value": "TG",
      "districts": [
        {
          "name": "H. An Phú Đông",
          "value": "TG.APD"
        },
        {
          "name": "H. Cái Bè",
          "value": "TG.CB"
        },
        {
          "name": "H. Cai Lậy",
          "value": "TG.CL"
        },
        {
          "name": "H. Châu Thành",
          "value": "TG.CT"
        },
        {
          "name": "H. Chợ Gạo",
          "value": "TG.CG"
        },
        {
          "name": "H. Gò Công Đông",
          "value": "TG.GCD"
        },
        {
          "name": "H. Gò Công Tây",
          "value": "TG.GCT"
        },
        {
          "name": "H. Tân Phú Đông",
          "value": "TG.TPD"
        },
        {
          "name": "H. Tân Phước",
          "value": "TG.TP"
        },
        {
          "name": "TP. Mỹ Tho",
          "value": "TG.TP.MT"
        },
        {
          "name": "TX. Cai Lậy",
          "value": "TG.TX.CL"
        },
        {
          "name": "TX. Gò Công",
          "value": "TG.TX.GC"
        }
      ]
    },
    {
      "city": "Trà Vinh",
      "value": "TV",
      "districts": [
        {
          "name": "H. Càng Long",
          "value": "TV.CL"
        },
        {
          "name": "H. Cầu Kè",
          "value": "TV.CK"
        },
        {
          "name": "H. Cầu Ngang",
          "value": "TV.CN"
        },
        {
          "name": "H. Châu Thành",
          "value": "TV.CT"
        },
        {
          "name": "H. Duyên Hải",
          "value": "TV.DH"
        },
        {
          "name": "H. Tiểu Cần",
          "value": "TV.TC"
        },
        {
          "name": "H. Trà Cú",
          "value": "TV.TU"
        },
        {
          "name": "TP. Trà Vinh",
          "value": "TV.TP.TV"
        }
      ]
    },
    {
      "city": "Tuyên Quang",
      "value": "TQ",
      "districts": [
        {
          "name": "H. Chiêm Hóa",
          "value": "TQ.CH"
        },
        {
          "name": "H. Hàm Yên",
          "value": "TQ.HY"
        },
        {
          "name": "H. Lâm Bình",
          "value": "TQ.LB"
        },
        {
          "name": "H. Na Hang",
          "value": "TQ.NH"
        },
        {
          "name": "H. Sơn Dương",
          "value": "TQ.SD"
        },
        {
          "name": "H. Yên Sơn",
          "value": "TQ.YS"
        },
        {
          "name": "TP. Tuyên Quang",
          "value": "TQ.TP.TQ"
        }
      ]
    },
    {
      "city": "Vĩnh Long",
      "value": "VL",
      "districts": [
        {
          "name": "H. Bình Tân",
          "value": "VL.BT"
        },
        {
          "name": "H. Long Hồ",
          "value": "VL.LH"
        },
        {
          "name": "H. Mang Thít",
          "value": "VL.MT"
        },
        {
          "name": "H. Tam Bình",
          "value": "VL.TB"
        },
        {
          "name": "H. Trà Ôn",
          "value": "VL.TO"
        },
        {
          "name": "H. Vũng Liêm",
          "value": "VL.VL"
        },
        {
          "name": "TP. Vĩnh Long",
          "value": "VL.TP.VL"
        },
        {
          "name": "TX. Bình Minh",
          "value": "VL.TX.BM"
        },
        {
          "name": "TP. Vĩnh Long",
          "value": "VL.TP.VL"
        }
      ]
    },
    {
      "city": "Vĩnh Phúc",
      "value": "VP",
      "districts": [
        {
          "name": "H. Bình Xuyên",
          "value": "VP.BX"
        },
        {
          "name": "H. Lập Thạch",
          "value": "VP.LT"
        },
        {
          "name": "H. Mê Linh",
          "value": "VP.ML"
        },
        {
          "name": "H. Sông Lô",
          "value": "VP.SL"
        },
        {
          "name": "H. Tam Đảo",
          "value": "VP.TAD"
        },
        {
          "name": "H. Tam Dương",
          "value": "VP.TD"
        },
        {
          "name": "H. Vĩnh Tường",
          "value": "VP.VT"
        },
        {
          "name": "H. Yên Lạc",
          "value": "VP.YL"
        },
        {
          "name": "TP. Vĩnh Yên",
          "value": "VP.TP.VY"
        },
        {
          "name": "TX. Phúc Yên",
          "value": "VP.TX.PY"
        }
      ]
    },
    {
      "city": "Yên Bái",
      "value": "YB",
      "districts": [
        {
          "name": "H. Lục Yên",
          "value": "YB.LY"
        },
        {
          "name": "H. Mù Căng Chải",
          "value": "YB.MCC"
        },
        {
          "name": "H. Trạm Tấu",
          "value": "YB.TT"
        },
        {
          "name": "H. Trấn Yên",
          "value": "YB.TY"
        },
        {
          "name": "H. Văn Chấn",
          "value": "YB.VC"
        },
        {
          "name": "H. Văn Yên",
          "value": "YB.VY"
        },
        {
          "name": "H. Yên Bình",
          "value": "YB.YB"
        },
        {
          "name": "TP. Yên Bái",
          "value": "YB.TP.YB"
        },
        {
          "name": "TX. Nghĩa Lộ",
          "value": "YB.TX.NL"
        }
      ]
    }
  ]


  districts = [];
  districtsChange(e) {
    this.cityData.filter(element => {
      if (element.display === e.target.value) {
        this.districts = element.districts;
      }
    });

  }


  getuserForm(userData) {
    let registerForm: any = {};
    return registerForm = {
      "root": {
        "customer": [
          {
            "mobile": this.mobile_no,
            "email": this.dataFromSalesForce ? this.reterivedSalesForceData.Email : this.CRMretrievedData.email,
            "firstname": this.dataFromSalesForce ? this.reterivedSalesForceData.FirstName : this.CRMretrievedData.firstName,
            "lastname": this.dataFromSalesForce ? this.reterivedSalesForceData.LastName : this.CRMretrievedData.lastName,
            "custom_fields": {
              "field": [
                {
                  "name": "street_name",
                  "value": userData?.address ? userData?.address : null
                },
                {
                  "name": "city",
                  "value": userData?.cities ? userData?.cities : null
                },
                {
                  "name": "district",
                  "value": userData?.district ? userData?.district : null
                },
                {
                  "name": "wards",
                  "value": userData?.ward ? userData?.ward : null
                }
              ]
            }
          }
        ]
      }
    }
  }









}


