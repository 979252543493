import { Component, OnInit, TemplateRef } from '@angular/core';
import { Itraceability } from 'src/app/models/itraceability';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tracebility-landing',
  templateUrl: './tracebility-landing.component.html',
  styleUrls: ['./tracebility-landing.component.scss']
})
export class TracebilityLandingComponent implements OnInit {
  scancodeData = null;
  prodCategory:string;
  scanScode:any
  loader:boolean = false;
  modalRef: BsModalRef;
  config = {
    ignoreBackdropClick: true
  };
  constructor(private apiService: ApiService, private route:ActivatedRoute,private modalService: BsModalService, private router: Router) { 

    window.scrollTo({top: 0});
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
   
   
    // this.loader = true;
    // this.scanScode = this.route.snapshot.queryParams.u
    // this.traceabilityService.getTraceability(this.scanScode).subscribe((res: any) => {
    //   sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
    //   this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //   console.log(this.scancodeData, 'this.scancodeData');
    //   //this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //   this.prodCategory = this.scancodeData.product.categoryCode;
    //   this.getTraceabilityContent();
    //   this.loader = false;
    // },err=>{
    //   if(err){
    //     this.loader = false;
    //   }
    // })
   
  }

  traceabilityContent: {};
  productImage: String;
  content = [];
  primaryProductTitle: String;
  primaryShortProductInfo: String;
  productCode: String;
  factoryTitleInfo: String;
  batchCode: String;
  manufacturedDate: String;
  expiryDate: String;
  entranceDate: String;
  productName:  String;
  factoryShortProductDescription: String;
  manufactCountry: string;
  milkCode:any;
  iSprintData: any;

  ngOnInit(): void {
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"))  
    let sku = this.iSprintData.sku;
    this.productCode = this.iSprintData.sku;
    if(!this.iSprintData.is_isprint){
      this.apiService.getGpasProductImage(sku).subscribe(
        (res: any) => {
          console.log(res.data, 'getGpasProductImage');
          this.productImage = res.data[0]?.image;
          console.log(this.productImage);
        },
        (err) => {
          console.log(err)
        }
      );
    }else{
      this.productImage = this.iSprintData.product_image
    }
   
    this.getTraceabilityContent();
    this.getProductDescCode();
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  openModal(template: TemplateRef<any>) {
    //if(this.milkCode == 1){
      this.modalRef = this.modalService.show(template, this.config);
    //}
  }
  gotoProdDesc(){
     if(this.milkCode == 1 && this.prodCategory == 'Grow'){
      this.router.navigate(['/product-description']);
      this.modalRef.hide();
     }
     else if(this.milkCode == 1 && this.prodCategory == 'Similac'){
      this.router.navigate(['/similac-mom-product']);
      this.modalRef.hide();
     }
      
  }
  
  getTraceabilityContent() {
    this.batchCode = this.iSprintData.batch_number
    this.manufacturedDate = this.iSprintData.manufacture_date
    this.expiryDate = this.iSprintData.expiry_date
    

      this.milkCode = this.iSprintData.is_milk_code
      this.entranceDate = this.iSprintData.vietnam_entrance_date
      this.prodCategory = this.iSprintData.category_code;
      console.log(this.prodCategory, this.milkCode)
      

    if(this.iSprintData.is_isprint == true){
      console.log(this.productImage, this.iSprintData.product_image)
      // this.productImage = this.iSprintData.product_image
      this.primaryProductTitle = this.iSprintData.product_name.vi_VN;
      this.primaryShortProductInfo = this.iSprintData.product_info.vi_VN
      this.factoryTitleInfo = this.iSprintData.factory_description.vi_VN
      this.factoryShortProductDescription = this.iSprintData.short_factory_description.vi_VN
      this.manufactCountry = this.iSprintData.origin?.vi_VN
      console.log(this.manufactCountry, 'this.manufactCountry')

    }else{
      // this.primaryProductTitle = this.iSprintData.product_description;
      // this.primaryShortProductInfo = this.iSprintData.product_description_vn;
      this.primaryProductTitle = this.iSprintData.product_description_vn;
      this.primaryShortProductInfo = this.iSprintData.product_info_vn;
      this.manufactCountry = this.iSprintData.origin
      console.log(this.manufactCountry, 'this.manufactCountry')
      let factory = this.iSprintData.factory_description_vn.split("\n\n");
      this.factoryTitleInfo = factory[0];
      this.factoryShortProductDescription = factory[1];
    
    }
  
  }
  subCatCode:any;
  getProductDescCode() {
    // this.content = this.scancodeData?.product?.txt;
    // if( this.content)
    // this.content.forEach((item: any) => {
    
    //   if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //     let parsedPrimaryValue = this.parseFunction(item.value);
    //     this.subCatCode = parsedPrimaryValue.value;
    //     console.log(this.subCatCode, 'this.subCatCode')
    //   }
     
   
    // });
    this.subCatCode =  this.iSprintData.sub_category_code;
    console.log(this.subCatCode, 'this.subCatCode')
  }
  gotoHome(){
    sessionStorage.setItem('gotoHomePage', 'gotoHome')
  }
}
