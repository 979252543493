<app-header></app-header>
<section class="signUpContainer">
    <section class="formContainer pb-4">
        <img class="img-fluid centeredImg" src="../../../assets/images/check.png" />
        <!-- <div class="" *ngIf="showInvalidContent">
            <h2 class="darKBlueBoldTitle mb-0 text-center">{{ 'ADDITIONAL_SALES_FORCE_SIGNUP.TOP_TITLE_ONE' | translate }}
            </h2>
            <h2 class="darKBlueBoldTitle text-center">{{ 'ADDITIONAL_SALES_FORCE_SIGNUP.TOP_TITLE_TWO' | translate }}</h2>
        </div>
        <div class="" *ngIf="showValidContent">
            <h2 class="darKBlueBoldTitle mb-0 text-center"> Chỉ còn một bước cuối cùng để</h2>
            <h2 class="darKBlueBoldTitle text-center">trở thành thành viên của</h2>
        </div> -->
        <h2 class="darKBlueBoldTitle mb-0 text-center">Xin chúc mừng! <br />
            Bạn đã chính thức là thành viên của </h2>
        <img class="img-fluid shareCareImg mt-0 mb-0" src="../../../assets/images/care&share-01.png" />
        <img class="img-fluid centeredImg  one mt-0" src="../../../assets/images/doublearrodowncircle.png" />

        <!-- <h2 class="darKBlueBoldTitle text-center mb-5" *ngIf="showInvalidContent">{{ 'ADDITIONAL_SALES_FORCE_SIGNUP.TITLE_MIDDLE' | translate }}</h2> -->
        <!-- <div class="" *ngIf="showValidContent">
            <h2 class="darKBlueBoldTitle text-center">Vui lòng nhập địa chỉ chính xác</h2>
            <h2 class="darKBlueBoldTitle text-center mb-5">để nhận quà</h2>
        </div>
        <p class="textGrey" *ngIf="milkCode == '0' || milkCode == undefined">{{ 'ADDITIONAL_SALES_FORCE_SIGNUP.PARA1' | translate }}</p> -->
       
       
        <div id="accordion">
            <div class="card">
            <h2 class="darKBlueBoldTitle text-center" *ngIf="milkCode == 0">Bổ sung thông tin địa chỉ</h2>
              <div *ngIf="milkCode == 1" class="card-header">
                <a class="card-link" aria-expanded="false" data-toggle="collapse" href="#collapseOne">
                    Bổ sung thông tin địa chỉ (tuỳ chọn)
                    <img src="../../../assets/images/arrow.png" class="arroimg" />
                </a>
              </div>
              <div id="collapseOne" class="collapse" [ngClass]="{'show': milkCode == 0}" data-parent="#accordion">
                <div class="card-body">
                    <form class="" [formGroup]="additionSalesForm" (ngSubmit)="onAdditionalSalesSubmit()">
                        <div class="form-group mb-2" >
                            <input type="text" class="form-control" formControlName="address"
                                [ngClass]="{'is-invalid': submitted && salesFormVal.address.errors}" id="exampleInputEmail1"
                                aria-describedby="emailHelp" placeholder=""
                                required>
                            <label [ngClass]="{'isinvalid': submitted && salesFormVal.address.errors}">{{ 'ADDITIONAL_SALES_FORCE_SIGNUP.LABEL1' | translate }}</label>
            
                            <img *ngIf=" submitted && !salesFormVal.address.errors" class="img-fluid tickmarkImage"
                                src="../../../assets/images/tickmark.svg" />
                            <!-- <div *ngIf="submitted && salesFormVal.address.errors" class="invalid-feedback">
                                <div *ngIf="salesFormVal.address.errors.required">Address is Required</div>
                            </div>  -->
                        </div>
                        <!-- <ng-select [allowClear]="true" [items]="items" [disabled]="disabled" (data)="refreshValue($event)"
                        (selected)="selected($event)" (removed)="removed($event)" (typed)="typed($event)"
                        placeholder="No city selected" formControlName="select">
                        </ng-select> -->
            
                        <!-- should be hidden for milk code -->
                        <div class="form-group mb-2">
                            <!-- <label for="exampleFormControlSelect1">Example select</label> -->
                            <select class="form-control" id="exampleFormControlSelect1" (change)="districtsChange($event)" formControlName="cities"
                                [ngClass]="{'is-invalid': submitted && salesFormVal.cities.errors}">
                                <option value="" disabled selected>Tỉnh/ Thành phố</option>
                                <option *ngFor="let cities of cityData" [value]="cities.display">{{ cities.display }}</option>
                            </select>
                           
                            <!-- <div *ngIf="submitted && salesFormVal.cities.errors" class="invalid-feedback">
                                <div *ngIf="salesFormVal.cities.errors.required">Address is Required</div>
                                <div *ngIf="salesFormVal.cities.errors.minlength">Password must be at least 6 characters</div>
                            </div> -->
                        </div>
                        <!-- should be hidden for milk code -->
            
                        <!-- should be hidden for milk code -->
                        <div class="form-group mb-2">
                            <!-- <label for="exampleFormControlSelect1">Example select</label> -->
                            <select class="form-control" id="exampleFormControlSelect1" formControlName="district"
                                [ngClass]="{'is-invalid': submitted && salesFormVal.district.errors}">
                                <option value="" disabled selected>Quận/ Huyện</option>
                                <option *ngFor='let dis of districts' [value]="dis.display">{{ dis.display }}</option>
            
                            </select>
                            
                            <!-- <div *ngIf="submitted && salesFormVal.district.errors" class="invalid-feedback">
                                <div *ngIf="salesFormVal.district.errors.required">Vui lòng hoàn tất các trường thông tin</div>
                            </div> -->
                        </div>
            
                        <!-- should be hidden for milk code -->
                        <div class="form-group mb-2">
                            <!-- <label for="exampleFormControlSelect1">Example select</label> -->
                            <!-- <select class="form-control" id="exampleFormControlSelect1" formControlName="ward"
                                [ngClass]="{'is-invalid': submitted && salesFormVal.ward.errors}">
                                <option value="" disabled selected>Phường/ Xã</option>
                                <option *ngFor='let ward of wards' [value]="ward">{{ ward }}</option>
                            </select> -->
                            
                            <!-- <div *ngIf="submitted && salesFormVal.ward.errors" class="invalid-feedback">
                                <div *ngIf="salesFormVal.ward.errors.required">Vui lòng hoàn tất các trường thông tin</div>
                                <div *ngIf="salesFormVal.ward.errors.minlength">Password must be at least 6 characters</div>
                            </div> -->
                            <input type="text" class="form-control" formControlName="ward"
                            [ngClass]="{'is-invalid': submitted && salesFormVal.ward.errors}"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp" placeholder=""
                              required>
                            <label>Phường/ Xã</label>
                        </div>
            
                        <div *ngIf="submitted && additionSalesForm.invalid" class="isinvalid">
                            <div>Vui lòng hoàn tất các trường thông tin</div>
                        </div>
            
                        
            
                        <button type="submit" class="btn btn-primary btn-block orangeBtn my-4">{{
                            'ADDITIONAL_SALES_FORCE_SIGNUP.BTN_TEXT' | translate }}</button>

                            <p class="textGrey text-justify mb-0 mt-3">{{ 'ADDITIONAL_SALES_FORCE_SIGNUP.PARA2' | translate }}</p>
            
                    </form>
                </div>
              </div>
            </div>
        </div>
       
        <hr *ngIf="milkCode == 1" />

        <button *ngIf="milkCode == 1" type="button" class="btn btn-primary btn-block orangeBtn my-4" routerLink="/signup-success">Tiếp theo</button>
    
        <!-- <div>
            <h2>Hello country/ state/ cities </h2>
        
            <select class="form-control" (change)="districtsChange($event)" >
              <option>Select</option>
              <option *ngFor="let c of city" [ngValue]="c.city">{{ c.city }}</option>
            </select>
        
            <select class="form-control" (change)="wardsChange($event)">
              <option>Select</option>
              <option *ngFor='let district of districts' [ngValue]="district.name">{{ district.name }}</option>
            </select>
        
            <select class="form-control">
              <option>Select</option>
              <option *ngFor='let ward of wards' [ngValue]="ward">{{ ward }}</option>
            </select>
          </div> -->

          

    </section>
</section>
<app-auth-footer></app-auth-footer>
<app-nav-menu></app-nav-menu>