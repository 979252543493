import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryListService {
  jsondata: any;
  constructor() {
    this.jsondata = [
      {"Bà Rịa Vũng Tàu": 
      {"districts": 
      [
        {"code": "BRVT.CD", "display": "H. Châu Đức"}, 
        {"code": "BRVT.COD", "display": "H. Côn Đảo"}, 
        {"code": "BRVT.DD", "display": "H. Đất Đỏ"}, 
        {"code": "BRVT.LD", "display": "H. Long Đất"}, 
        {"code": "BRVT.LL", "display": "H. Long Điền"}, 
        {"code": "BRVT.TT", "display": "H. Tân Thành"}, 
        {"code": "BRVT.XM", "display": "H. Xuyên Mộc"}, 
        {"code": "BRVT.TP.BR", "display": "TP. Bà Rịa"}, 
        {"code": "BRVT.TP.VT", "display": "TP. Vũng Tàu"}, 
        {"code": "BRVT.TX.PM", "display": "TX. Phú Mỹ"}
      ], 
      "code": "BRVT", "display": "Bà Rịa Vũng Tàu"}, 
      
      "Hà Nam": 
      {"districts": 
      [
        {"code": "HN.BL", "display": "H. Bình Lục"}, 
        {"code": "HN.DT", "display": "H. Duy Tiên"}, 
        {"code": "HN.KB", "display": "H. Kim Bảng"}, 
        {"code": "HN.LN", "display": "H. Lý Nhân"}, 
        {"code": "HN.TL", "display": "H. Thanh Liêm"}, 
        {"code": "HN.TP.PL", "display": "TP. Phủ Lý"}
      ], 
      "code": "HN", "display": "Hà Nam"}, 
      
      "Hòa Bình": 
      {"districts": 
      [
        {"code": "HB.CP", "display": "H. Cao Phong"}, 
        {"code": "HB.DB", "display": "H. Đà Bắc"}, 
        {"code": "HB.KB", "display": "H. Kim Bôi"}, 
        {"code": "HB.KS", "display": "H. Kỳ Sơn"}, 
        {"code": "HB.LS", "display": "H. Lạc Sơn"}, 
        {"code": "HB.LT", "display": "H. Lạc Thủy"}, 
        {"code": "HB.LN", "display": "H. Lương Sơn"}, 
        {"code": "HB.MC", "display": "H. Mai Châu"}, 
        {"code": "HB.TL", "display": "H. Tân Lạc"}, 
        {"code": "HB.YT", "display": "H. Yên Thủy"}, 
        {"code": "HB.TP.HB", "display": "Tp. Hòa Bình"}
      ], 
      "code": "HB", "display": "Hòa Bình"}, 
      
      "Ninh Thuận": 
      {"districts": 
      [
        {"code": "NT.BA", "display": "H. Bác ái"}, 
        {"code": "NT.NH", "display": "H. Ninh Hải"}, 
        {"code": "NT.NP", "display": "H. Ninh Phước"}, 
        {"code": "NT.NS", "display": "H. Ninh Sơn"}, 
        {"code": "NT.TB", "display": "H. Thuận Bắc"}, 
        {"code": "NT.TN", "display": "H. Thuận Nam"}, 
        {"code": "NT.TP.PRTC", "display": "TP. Phan Rang Tháp Chàm"}
      ], 
      "code": "NT", "display": "Ninh Thuận"}, 
      
      "Bắc Ninh": 
      {"districts": 
      [
        {"code": "BN.GB", "display": "H. Gia Bình"}, 
        {"code": "BN.LT", "display": "H. Lương Tài"}, 
        {"code": "BN.QV", "display": "H. Quế Võ"}, 
        {"code": "BN.TT", "display": "H. Thuận Thành"}, 
        {"code": "BN.TD", "display": "H. Tiên Du"}, 
        {"code": "BN.YP", "display": "H. Yên Phong"}, 
        {"code": "BN.TP.BN", "display": "TP. Bắc Ninh"}, 
        {"code": "BN.TX.TS", "display": "TX. Từ Sơn"}
      ], 
      "code": "BN", "display": "Bắc Ninh"}, 
      
      "Cần Thơ": 
      {"districts": 
      [
        {"code": "CT.CD", "display": "H. Cờ Đỏ"}, 
        {"code": "CT.PD", "display": "H. Phong Điền"}, 
        {"code": "CT.TL", "display": "H. Thới Lai"}, 
        {"code": "CT.VT", "display": "H. Vĩnh Thạnh"}, 
        {"code": "CT.BT", "display": "Q. Bình Thủy"}, 
        {"code": "CT.CR", "display": "Q. Cái Răng"}, 
        {"code": "CT.NK", "display": "Q. Ninh Kiều"}, 
        {"code": "CT.OM", "display": "Q. Ô Môn"}, 
        {"code": "CT.TN", "display": "Q. Thốt Nốt"}
      ], 
      "code": "CT", "display": "Cần Thơ"}, 
      
      "Thanh Hóa": 
      {"districts": 
      [
        {"code": "TH.BT", "display": "H. Bá Thước"}, 
        {"code": "TH.CT", "display": "H. Cẩm Thủy"}, 
        {"code": "TH.DS", "display": "H. Đông Sơn"}, 
        {"code": "TH.HI", "display": "H. Hà Hải"}, 
        {"code": "TH.HT", "display": "H. Hà Trung"}, 
        {"code": "TH.HL", "display": "H. Hậu Lộc"}, 
        {"code": "TH.HH", "display": "H. Hoằng Hóa"}, 
        {"code": "TH.LC", "display": "H. Lang Chánh"}, 
        {"code": "TH.ML", "display": "H. Mường Lát"}, 
        {"code": "TH.NS", "display": "H. Nga Sơn"}, 
        {"code": "TH.NL", "display": "H. Ngọc Lặc"}, 
        {"code": "TH.NT", "display": "H. Như Thanh"}, 
        {"code": "TH.NX", "display": "H. Như Xuân"}, 
        {"code": "TH.NC", "display": "H. Nông Cống"}, 
        {"code": "TH.QH", "display": "H. Quan Hóa"}, 
        {"code": "TH.QS", "display": "H. Quang Sơn"}, 
        {"code": "TH.QX", "display": "H. Quảng Xương"}, 
        {"code": "TH.TT", "display": "H. Thạch Thành"}, 
        {"code": "TH.TH", "display": "H. Thiệu Hóa"}, 
        {"code": "TH.TX", "display": "H. Thọ Xuân"}, 
        {"code": "TH.THX", "display": "H. Thường Xuân"}, 
        {"code": "TH.TG", "display": "H. Tĩnh Gia"}, 
        {"code": "TH.TS", "display": "H. Triệu Sơn"}, 
        {"code": "TH.VL", "display": "H. Vĩnh Lộc"}, 
        {"code": "TH.YD", "display": "H. Yên Định"}, 
        {"code": "TH.TP.TH", "display": "TP. Thanh Hóa"}, 
        {"code": "TH.TX.BS", "display": "TX. Bỉm Sơn"}, 
        {"code": "TH.TX.SS", "display": "TX. Sầm Sơn"}
      ], 
      "code": "TH", "display": "Thanh Hóa"}, 
      
      "Long An": 
      {"districts": 
      [
        {"code": "LA.BL", "display": "H. Bến Lức"}, 
        {"code": "LA.CD", "display": "H. Cần Đước"}, 
        {"code": "LA.CG", "display": "H. Cần Giuộc"}, 
        {"code": "LA.CT", "display": "H. Châu Thành"}, 
        {"code": "LA.DH", "display": "H. Đức Hòa"},
         {"code": "LA.DE", "display": "H. Đức Huệ"}, 
         {"code": "LA.MH", "display": "H. Mộc Hóa"}, 
         {"code": "LA.TH", "display": "H. Tân Hưng"}, 
         {"code": "LA.TT", "display": "H. Tân Thạnh"}, 
         {"code": "LA.TU", "display": "H. Tân Trụ"}, 
         {"code": "LA.TA", "display": "H. Thạnh Hóa"}, 
         {"code": "LA.TTA", "display": "H. Thủ Thừa"}, 
         {"code": "LA.VH", "display": "H. Vĩnh Hưng"}, 
         {"code": "LA.TP.TA", "display": "TP. Tân An"}, 
         {"code": "LA.TX.KT", "display": "TX. Kiến Tường"}
        ], 
        "code": "LA", "display": "Long An"}, 
        
        "Đăk Nông": 
        {"districts": 
        [
          {"code": "DG.CJ", "display": "H. Cư Jut"}, 
          {"code": "DG.DG", "display": "H. Đăk GLong"}, 
          {"code": "DG.DM", "display": "H. Đăk Mil"}, 
          {"code": "DG.GL", "display": "H. Đăk R'Lấp"}, 
          {"code": "DG.DS", "display": "H. Đăk Song"}, 
          {"code": "DG.KN", "display": "H. Krông Nô"}, 
          {"code": "DG.TD", "display": "H. Tuy Đức"}, 
          {"code": "DG.TX.GN", "display": "TX. Gia Nghĩa"}
        ], 
        "code": "DG", "display": "Đăk Nông"}, 
        
        "Hải Phòng": 
        {"districts": 
        [
          {"code": "HP.AD", "display": "H. An Dương"}, 
          {"code": "HP.AN", "display": "H. An Hải"}, 
          {"code": "HP.AL", "display": "H. An Lão"}, 
          {"code": "HP.BLV", "display": "H. Bạch Long Vĩ"}, 
          {"code": "HP.CH", "display": "H. Cát Hải"}, 
          {"code": "HP.KT", "display": "H. Kiến Thụy"}, 
          {"code": "HP.TN", "display": "H. Thủy Nguyên"}, 
          {"code": "HP.TL", "display": "H. Tiên Lãng"}, 
          {"code": "HP.VB", "display": "H. Vĩnh Bảo"}, 
          {"code": "HP.DS", "display": "Q. Đồ Sơn"}, 
          {"code": "HP.DK", "display": "Q. Dương Kinh"}, 
          {"code": "HP.HA", "display": "Q. Hải An"}, 
          {"code": "HP.HB", "display": "Q. Hồng Bàng"}, 
          {"code": "HP.KA", "display": "Q. Kiến An"}, 
          {"code": "HP.LC", "display": "Q. Lê Chân"}, 
          {"code": "HP.NQ", "display": "Q. Ngô Quyền"}
        ], 
        "code": "HP", "display": "Hải Phòng"}, 
        
        "Quảng Trị": 
        {"districts": 
        [
          {"code": "QT.CL", "display": "H. Cam Lộ"}, 
          {"code": "QT.CC", "display": "H. Cổn Cỏ"}, 
          {"code": "QT.D", "display": "H. Đakrông"}, 
          {"code": "QT.GL", "display": "H. Gio Linh"}, 
          {"code": "QT.HL", "display": "H. Hải Lăng"}, 
          {"code": "QT.HH", "display": "H. Hướng Hóa"}, 
          {"code": "QT.TP", "display": "H. Triệu Phong"}, 
          {"code": "QT.VL", "display": "H. Vĩnh Linh"}, 
          {"code": "QT.TP.DH", "display": "TP. Đông Hà"}, 
          {"code": "QT.TX.QT", "display": "TX. Quảng Trị"}
        ], 
        "code": "QT", "display": "Quảng Trị"}, 
        
        "Đồng Tháp": 
        {"districts": 
        [
          {"code": "DT.CL", "display": "H. Cao Lãnh"}, 
          {"code": "DT.CT", "display": "H. Châu Thành"}, 
          {"code": "DT.HH", "display": "H. Hồng Hạnh"}, 
          {"code": "DT.HN", "display": "H. Hồng Ngự"}, 
          {"code": "DT.LV", "display": "H. Lai Vung"}, 
          {"code": "DT.LO", "display": "H. Lấp Vò"}, 
          {"code": "DT.TN", "display": "H. Tam Nông"}, 
          {"code": "DT.TH", "display": "H. Tân Hồng"}, 
          {"code": "DT.TB", "display": "H. Thanh Bình"}, 
          {"code": "DT.TM", "display": "H. Tháp Mười"}, 
          {"code": "DT.TP.CL", "display": "TP. Cao Lãnh"}, 
          {"code": "DT.TP.SD", "display": "TP. Sa Đéc"}, 
          {"code": "DT.TX.HN", "display": "TX. Hồng Ngự"}
        ], 
        "code": "DT", "display": "Đồng Tháp"}, 
        
        "Lâm Đồng": 
        {"districts": 
        [
          {"code": "LD.BL", "display": "H. Bảo Lâm"}, 
          {"code": "LD.CT", "display": "H. Cát Tiên"}, 
          {"code": "LD.DH", "display": "H. Đạ Huoai"}, 
          {"code": "LD.DT", "display": "H. Đạ Tẻh"}, 
          {"code": "LD.DR", "display": "H. Đam Rông"}, 
          {"code": "LD.DL", "display": "H. Di Linh"}, 
          {"code": "LD.DD", "display": "H. Đơn Dương"}, 
          {"code": "LD.DG", "display": "H. Đức Trọng"}, 
          {"code": "LD.LD", "display": "H. Lạc Dương"}, 
          {"code": "LD.LH", "display": "H. Lâm Hà"}, 
          {"code": "LD.TP.BL", "display": "TP. Bảo Lộc"}, 
          {"code": "LD.TP.DL", "display": "TP. Đà Lạt"}
        ], "code": "LD", "display": "Lâm Đồng"}, 
        
        "Điện Biên": 
        {"districts": 
        [
          {"code": "DB.DB", "display": "H. Điện Biên"}, 
          {"code": "DB.DBD", "display": "H. Điện Biên Đông"}, 
          {"code": "DB.MA", "display": "H. Mường Ảng"}, 
          {"code": "DB.MC", "display": "H. Mường Chà"}, 
          {"code": "DB.MN", "display": "H. Mường Nhé"}, 
          {"code": "DB.NP", "display": "H. Nậm Pồ"}, 
          {"code": "DB.TU", "display": "H. Thanh Uyên"}, 
          {"code": "DB.TC", "display": "H. Tủa Chùa"}, 
          {"code": "DB.TG", "display": "H. Tuần Giáo"}, 
          {"code": "DB.TP.DB", "display": "TP. Điện Biên"}, 
          {"code": "DB.TX.ML", "display": "TX. Mường Lay"}
        ], "code": "DB", "display": "Điện Biên"}, 
        
        "Tây Ninh": 
        {"districts": 
        [
          {"code": "TN.BC", "display": "H. Bến Cầu"}, 
          {"code": "TN.CT", "display": "H. Châu Thành"}, 
          {"code": "TN.DMC", "display": "H. Dương Minh Châu"}, 
          {"code": "TN.GD", "display": "H. Gò Dầu"}, 
          {"code": "TN.HT", "display": "H. Hòa Thành"}, 
          {"code": "TN.TB", "display": "H. Tân Biên"}, 
          {"code": "TN.TC", "display": "H. Tân Châu"}, 
          {"code": "TN.TG", "display": "H. Trảng Bàng"}, 
          {"code": "TN.TP.TN", "display": "TP. Tây Ninh"}
        ], "code": "TN", "display": "Tây Ninh"}, 
        
        "Bình Thuận": 
        {"districts": 
        [
          {"code": "BTN.BB", "display": "H. Bắc Bình"}, 
          {"code": "BTN.DL", "display": "H. Đức Linh"}, 
          {"code": "BTN.HT", "display": "H. Hàm Tân"}, 
          {"code": "BTN.HTB", "display": "H. Hàm Thuận Bắc"}, 
          {"code": "BTN.HTN", "display": "H. Hàm Thuận Nam"}, 
          {"code": "BTN.PQ", "display": "H. Phú Quý"}, 
          {"code": "BTN.TL", "display": "H. Tánh Linh"}, 
          {"code": "BTN.TP", "display": "H. Tuy Phong"}, 
          {"code": "BTN.TP.PT", "display": "TP. Phan Thiết"}, 
          {"code": "BTN.TX.LG", "display": "TX. La Gi"}
        ], "code": "BTN", "display": "Bình Thuận"}, 
        
        "Phú Yên": 
        {"districts": 
        [
          {"code": "PY.DH", "display": "H. Đông Hòa"}, 
          {"code": "PY.DX", "display": "H. Đồng Xuân"}, 
          {"code": "PY.PH", "display": "H. Phú Hòa"}, 
          {"code": "PY.SA", "display": "H. Sơn Hòa"}, 
          {"code": "PY.SH", "display": "H. Sông Hinh"}, 
          {"code": "PY.THA", "display": "H. Tây Hòa"}, 
          {"code": "PY.TA", "display": "H. Tuy An"}, 
          {"code": "PY.TP.TH", "display": "TP. Tuy Hòa"}, 
          {"code": "PY.TX.SC", "display": "TX. Sông Cầu"}
        ], 
        "code": "PY", "display": "Phú Yên"}, 
        
        "Ninh Bình": 
        {"districts": 
        [
          {"code": "NB.GV", "display": "H. Gia Viễn"}, 
          {"code": "NB.HL", "display": "H. Hoa Lư"}, 
          {"code": "NB.KS", "display": "H. Kim Sơn"}, 
          {"code": "NB.NQ", "display": "H. Nho Quan"}, 
          {"code": "NB.YK", "display": "H. Yên Khánh"}, 
          {"code": "NB.YM", "display": "H. Yên Mô"}, 
          {"code": "NB.TP.NB", "display": "TP. Ninh Bình"}, 
          {"code": "NB.TP.TD", "display": "TP. Tam Điệp"}, 
          {"code": "NB.TX.NB", "display": "TX. Ninh Bình"}
        ], 
        "code": "NB", "display": "Ninh Bình"},
        
        "Trà Vinh": 
        {"districts": 
        [
          {"code": "TV.CL", "display": "H. Càng Long"}, 
          {"code": "TV.CK", "display": "H. Cầu Kè"}, 
          {"code": "TV.CN", "display": "H. Cầu Ngang"}, 
          {"code": "TV.CT", "display": "H. Châu Thành"}, 
          {"code": "TV.DH", "display": "H. Duyên Hải"}, 
          {"code": "TV.TC", "display": "H. Tiểu Cần"}, 
          {"code": "TV.TU", "display": "H. Trà Cú"}, 
          {"code": "TV.TP.TV", "display": "TP. Trà Vinh"}
        ], 
        "code": "TV", "display": "Trà Vinh"}, 
        
        "Hà Giang": 
        {"districts": 
        [
          {"code": "HGG.BM", "display": "H. Bắc Mê"}, 
          {"code": "HGG.BQ", "display": "H. Bắc Quang"}, 
          {"code": "HGG.DV", "display": "H. Đồng Văn"}, 
          {"code": "HGG.HSP", "display": "H. Hoàng Su Phì"}, 
          {"code": "HGG.MV", "display": "H. Mèo Vạc"}, 
          {"code": "HGG.QB", "display": "H. Quản Bạ"}, 
          {"code": "HGG.QH", "display": "H. Quang Bình"}, 
          {"code": "HGG.VX", "display": "H. Vị Xuyên"}, 
          {"code": "HGG.XM", "display": "H. Xín Mần"}, 
          {"code": "HGG.YM", "display": "H. Yên Minh"}, 
          {"code": "HGG.TP.HG", "display": "TP. Hà Giang"}
        ], 
        "code": "HGG", "display": "Hà Giang"}, 
        
        "Kon Tum": 
        {"districts": 
        [
          {"code": "KT.DG", "display": "H. Đăk Glei"}, 
          {"code": "KT.DH", "display": "H. Đăk Hà"}, 
          {"code": "KT.DT", "display": "H. Đăk Tô"}, 
          {"code": "KT.IH", "display": "H. Ia H'Drai"}, 
          {"code": "KT.KP", "display": "H. Kon Plông"}, 
          {"code": "KT.KR", "display": "H. Kông Rẫy"}, 
          {"code": "KT.NH", "display": "H. Ngọc Hồi"}, 
          {"code": "KT.ST", "display": "H. Sa Thầy"}, 
          {"code": "KT.TM", "display": "H. Tu Mơ Rông"}, 
          {"code": "KT.TP.KT", "display": "TP. Kon Tum"}
        ], 
        "code": "KT", "display": "Kon Tum"}, 
        
        "Quảng Ninh": 
        {"districts": 
        [
          {"code": "QN.BC", "display": "H. Ba Chẽ"}, 
          {"code": "QN.BL", "display": "H. Bình Liêu"}, 
          {"code": "QN.CT", "display": "H. Cô Tô"}, 
          {"code": "QN.DH", "display": "H. Đầm Hà"}, 
          {"code": "QN.DT", "display": "H. Đông Triều"}, 
          {"code": "QN.HH", "display": "H. Hải Hà"}, 
          {"code": "QN.HB", "display": "H. Hoành Bồ"}, 
          {"code": "QN.TY", "display": "H. Tiên Yên"}, 
          {"code": "QN.VD", "display": "H. Vân Đồn"}, 
          {"code": "QN.TP.CP", "display": "TP. Cẩm Phả"}, 
          {"code": "QN.TP.HL", "display": "TP. Hạ Long"}, 
          {"code": "QN.TP.HG", "display": "TP. Hòn Gai"}, 
          {"code": "QN.TP.MC", "display": "TP. Móng Cái"}, 
          {"code": "QN.TP.UB", "display": "TP. Uông Bí"}, 
          {"code": "QN.TX.QY", "display": "TX. Quảng Yên"}
        ], 
        "code": "QN", "display": "Quảng Ninh"}, 
        
        "Lai Châu": 
        {"districts": 
        [
          {"code": "LC.MT", "display": "H. Mường Tè"},
           {"code": "LC.NN", "display": "H. Nậm Nhùn"}, 
           {"code": "LC.PT", "display": "H. Phong Thổ"}, 
           {"code": "LC.SH", "display": "H. Sìn Hồ"}, 
           {"code": "LC.TD", "display": "H. Tam Đường"}, 
           {"code": "LC.TU", "display": "H. Tân Uyên"}, 
           {"code": "LC.TY", "display": "H. Than Uyên"},
           {"code": "LC.TP.LC", "display": "TP. Lai Châu"}
          ], 
          "code": "LC", "display": "Lai Châu"}, 
          
          "Đà Nẵng": 
          {"districts":
           [
             {"code": "003.HV", "display": "H. Hòa Vang"}, 
             {"code": "003.HS", "display": "H. Hoàng Sa"}, 
             {"code": "003.CL", "display": "Q. Cẩm Lệ"}, 
             {"code": "003.HC", "display": "Q. Hải Châu"}, 
             {"code": "003.LC", "display": "Q. Liên Chiểu"}, 
             {"code": "003.NHS", "display": "Q. Ngũ Hành Sơn"}, 
             {"code": "003.ST", "display": "Q. Sơn Trà"}, 
             {"code": "003.TK", "display": "Q. Thanh Khê"}
            ], 
            "code": "3", "display": "Đà Nẵng"}, 
            
            "Cà Mau": 
            {"districts": 
            [
              {"code": "CM.CN", "display": "H. Cái Nước"}, 
              {"code": "CM.DD", "display": "H. Đầm Dơi"}, 
              {"code": "CM.NC", "display": "H. Năm Căn"}, 
              {"code": "CM.NH", "display": "H. Ngọc Hiển"}, 
              {"code": "CM.PT", "display": "H. Phú Tân"}, 
              {"code": "CM.TB", "display": "H. Thới Bình"}, 
              {"code": "CM.TVT", "display": "H. Trần Văn Thời"}, 
              {"code": "CM.UM", "display": "H. U Minh"}, 
              {"code": "CM.TP.CM", "display": "TP. Cà Mau"}
            ], 
            "code": "CM", "display": "Cà Mau"}, 
            
            "Bạc Liêu": 
            {"districts": 
            [
              {"code": "BL.DH", "display": "H. Đông Hải"}, 
              {"code": "BL.HB", "display": "H. Hòa Bình"}, 
              {"code": "BL.HD", "display": "H. Hồng Dân"}, 
              {"code": "BL.PL", "display": "H. Phước Long"}, 
              {"code": "BL.TT", "display": "H. Thạnh Trị"}, 
              {"code": "BL.VL", "display": "H. Vĩnh Lợi"}, 
              {"code": "BL.TP.BL", "display": "TP. Bạc Liêu"}, 
              {"code": "BL.TX.GR", "display": "TX. Giá Rai"}
            ], 
            "code": "BL", "display": "Bạc Liêu"}, 
            
            "Phú Thọ": 
            {"districts": 
            [
              {"code": "PT.CK", "display": "H. Cẩm Khê"}, 
              {"code": "PT.DH", "display": "H. Đoan Hùng"}, 
              {"code": "PT.HH", "display": "H. Hạ Hòa"}, 
              {"code": "PT.LT", "display": "H. Lâm Thao"}, 
              {"code": "PT.PN", "display": "H. Phù Ninh"}, 
              {"code": "PT.ST", "display": "H. Sông Thao"}, 
              {"code": "PT.TN", "display": "H. Tam Nông"}, 
              {"code": "PT.TAS", "display": "H. Tân Sơn"}, 
              {"code": "PT.TB", "display": "H. Thanh Ba"}, 
              {"code": "PT.TS", "display": "H. Thanh Sơn"}, 
              {"code": "PT.TT", "display": "H. Thanh Thủy"}, 
              {"code": "PT.YL", "display": "H. Yên Lập"}, 
              {"code": "PT.TP.VT", "display": "TP. Việt Trì"}, 
              {"code": "PT.TX.PT", "display": "TX. Phú Thọ"}
            ], 
            "code": "PT", "display": "Phú Thọ"}, 
            
            "Quảng Nam": 
            {"districts": 
            [
              {"code": "QM.BTM", "display": "H. Bắc Trà My"}, 
              {"code": "QM.DL", "display": "H. Đại Lộc"}, 
              {"code": "QM.DG", "display": "H. Đông Giang"}, 
              {"code": "QM.DX", "display": "H. Duy Xuyên"}, 
              {"code": "QM.HD", "display": "H. Hiệp Đức"}, 
              {"code": "QM.NG", "display": "H. Nam Giang"}, 
              {"code": "QM.NTM", "display": "H. Nam Trà My"}, 
              {"code": "QM.NS", "display": "H. Nông Sơn"}, 
              {"code": "QM.NT", "display": "H. Núi Thành"}, 
              {"code": "QM.PN", "display": "H. Phú Ninh"}, 
              {"code": "QM.PS", "display": "H. Phước Sơn"}, 
              {"code": "QM.QS", "display": "H. Quế Sơn"}, 
              {"code": "QM.TG", "display": "H. Tây Giang"}, 
              {"code": "QM.TB", "display": "H. Thăng Bình"}, 
              {"code": "QM.TP", "display": "H. Tiên Phước"}, 
              {"code": "QM.TP.HA", "display": "TP. Hội An"}, 
              {"code": "QM.TP.TK", "display": "TP. Tam Kỳ"}, 
              {"code": "QM.TX.DB", "display": "TX. Điện Bàn"}
            ], 
            "code": "QM", "display": "Quảng Nam"}, 
            
            "Sơn La": 
            {"districts": 
            [
              {"code": "SL.BY", "display": "H. Bắc Yên"}, 
              {"code": "SL.MS", "display": "H. Mai Sơn"}, 
              {"code": "SL.MC", "display": "H. Mộc Châu"}, 
              {"code": "SL.ML", "display": "H. Mường La"}, 
              {"code": "SL.PY", "display": "H. Phù Yên"}, 
              {"code": "SL.QN", "display": "H. Quỳnh Nhai"}, 
              {"code": "SL.SM", "display": "H. Sông Mã"}, 
              {"code": "SL.SC", "display": "H. Sốp Cộp"}, 
              {"code": "SL.TC", "display": "H. Thuận Châu"}, 
              {"code": "SL.VH", "display": "H. Vân Hồ"}, 
              {"code": "SL.YC", "display": "H. Yên Châu"}, 
              {"code": "SL.TP.SL", "display": "TP. Sơn La"}
            ], 
            "code": "SL", "display": "Sơn La"}, 
            
            "Bình Phước": 
            {"districts": 
            [
              {"code": "BP.BD", "display": "H. Bù Đăng"}, 
              {"code": "BP.BP", "display": "H. Bù Đốp"}, 
              {"code": "BP.BGM", "display": "H. Bù Gia Mập"}, 
              {"code": "BP.TT", "display": "H. Chơn Thành"}, 
              {"code": "BP.DP", "display": "H. Đồng Phú"}, 
              {"code": "BP.HQ", "display": "H. Hớn Quản"}, 
              {"code": "BP.LN", "display": "H. Lộc Ninh"}, 
              {"code": "BP.PR", "display": "H. Phú Riềng"}, 
              {"code": "BP.TX.BL", "display": "TX. Bình Long"}, 
              {"code": "BP.TX.DX", "display": "TX. Đồng Xoài"}, 
              {"code": "BP.TX.PL", "display": "TX. Phước Long"}
            ], 
            "code": "BP", "display": "Bình Phước"}, 
            
            "Vĩnh Phúc": 
            {"districts": 
            [
              {"code": "VP.BX", "display": "H. Bình Xuyên"}, 
              {"code": "VP.LT", "display": "H. Lập Thạch"}, 
              {"code": "VP.ML", "display": "H. Mê Linh"}, 
              {"code": "VP.SL", "display": "H. Sông Lô"}, 
              {"code": "VP.TAD", "display": "H. Tam Đảo"},
              {"code": "VP.TD", "display": "H. Tam Dương"}, 
              {"code": "VP.VT", "display": "H. Vĩnh Tường"}, 
              {"code": "VP.YL", "display": "H. Yên Lạc"}, 
              {"code": "VP.TP.VY", "display": "TP. Vĩnh Yên"}, 
              {"code": "VP.TX.PY", "display": "TX. Phúc Yên"}
            ], 
            "code": "VP", "display": "Vĩnh Phúc"}, 
            
            "Hà Nội": 
            {"districts": 
            [
              {"code": "002.BV", "display": "H. Ba Vì"}, 
              {"code": "002.CM", "display": "H. Chương Mỹ"}, 
              {"code": "002.DP", "display": "H. Đan Phượng"}, 
              {"code": "002.DA", "display": "H. Đông Anh"}, 
              {"code": "002.GL", "display": "H. Gia Lâm"}, 
              {"code": "002.HD", "display": "H. Hoài Đức"}, 
              {"code": "002.ML", "display": "H. Mê Linh"}, 
              {"code": "002.MD", "display": "H. Mỹ Đức"}, 
              {"code": "002.PX", "display": "H. Phú Xuyên"}, 
              {"code": "002.PT", "display": "H. Phúc Thọ"}, 
              {"code": "002.QO", "display": "H. Quốc Oai"}, 
              {"code": "002.SS", "display": "H. Sóc Sơn"}, 
              {"code": "002.HTT", "display": "H. Thạch Thất"}, 
              {"code": "002.TO", "display": "H. Thanh Oai"}, 
              {"code": "002.TT", "display": "H. Thanh Trì"}, 
              {"code": "002.THT", "display": "H. Thường Tín"}, 
              {"code": "002.TL", "display": "H. Từ Liêm"}, 
              {"code": "002.UH", "display": "H. ứng Hòa"}, 
              {"code": "002.BD", "display": "Q. Ba Đình"}, 
              {"code": "002.BTL", "display": "Q. Bắc Từ Liêm"}, 
              {"code": "002.CG", "display": "Q. Cầu Giấy"}, 
              {"code": "002.DD", "display": "Q. Đống Đa"}, 
              {"code": "002.QHD", "display": "Q. Hà Đông"}, 
              {"code": "002.HBT", "display": "Q. Hai Bà Trưng"}, 
              {"code": "002.HK", "display": "Q. Hoàn Kiếm"}, 
              {"code": "002.HM", "display": "Q. Hoàng Mai"},
               {"code": "002.LB", "display": "Q. Long Biên"}, 
               {"code": "002.NTL", "display": "Q. Nam Từ Liêm"}, 
               {"code": "002.TH", "display": "Q. Tây Hồ"}, 
               {"code": "002.TX", "display": "Q. Thanh Xuân"}, 
               {"code": "002.TX.ST", "display": "TX. Sơn Tây"}
              ],
               "code": "2", "display": "Hà Nội"}, 
               
               "An Giang": 
               {"districts": 
               [
                 {"code": "AG.AP", "display": "H. An Phú"}, 
                 {"code": "AG.CP", "display": "H. Châu Phú"}, 
                 {"code": "AG.CT", "display": "H. Châu Thành"}, 
                 {"code": "AG.CM", "display": "H. Chợ Mới"}, 
                 {"code": "AG.PT", "display": "H. Phú Tân"}, 
                 {"code": "AG.TS", "display": "H. Thoại Sơn"}, 
                 {"code": "AG.TB", "display": "H. Tịnh Biên"}, 
                 {"code": "AG.TT", "display": "H. Tri Tôn"}, 
                 {"code": "AG.VT", "display": "H. Vĩnh Trinh"}, 
                 {"code": "AG.TP.CD", "display": "TP. Châu Đốc"}, 
                 {"code": "AG.TP.LX", "display": "TP. Long Xuyên"}, 
                 {"code": "AG.TX.TC", "display": "TX. Tân Châu"}
                ], 
                "code": "AG", "display": "An Giang"}, 
                
                "Yên Bái": 
                {"districts": 
                [
                  {"code": "YB.LY", "display": "H. Lục Yên"}, 
                  {"code": "YB.MCC", "display": "H. Mù Căng Chải"}, 
                  {"code": "YB.TT", "display": "H. Trạm Tấu"}, 
                  {"code": "YB.TY", "display": "H. Trấn Yên"}, 
                  {"code": "YB.VC", "display": "H. Văn Chấn"}, 
                  {"code": "YB.VY", "display": "H. Văn Yên"}, 
                  {"code": "YB.YB", "display": "H. Yên Bình"}, 
                  {"code": "YB.TP.YB", "display": "TP. Yên Bái"}, 
                  {"code": "YB.TX.NL", "display": "TX. Nghĩa Lộ"}
                ], 
                "code": "YB", "display": "Yên Bái"}, 
                
                "Thái Bình": 
                {"districts": 
                [
                  {"code": "TB.DH", "display": "H. Đông Hưng"}, 
                  {"code": "TB.HH", "display": "H. Hưng Hà"}, 
                  {"code": "TB.KX", "display": "H. Kiến Xương"}, 
                  {"code": "TB.QP", "display": "H. Quỳnh Phụ"}, 
                  {"code": "TB.TT", "display": "H. Thái Thụy"}, 
                  {"code": "TB.TH", "display": "H. Tiền Hải"}, 
                  {"code": "TB.VT", "display": "H. Vũ Thư"}, 
                  {"code": "TB.TP.TB", "display": "TP. Thái Bình"}
                ],
                 "code": "TB", "display": "Thái Bình"}, 
                 
                 "Bình Định": 
                 {"districts": [
                   {"code": "BH.AL", "display": "H. An Lão"}, 
                   {"code": "BH.AN", "display": "H. An Nhơn"}, 
                   {"code": "BH.CB", "display": "H. Cư Bang"}, 
                   {"code": "BH.HA", "display": "H. Hoài Ân"}, 
                   {"code": "BH.HN", "display": "H. Hoài Nhơn"}, 
                   {"code": "BH.PC", "display": "H. Phù Cát"}, 
                   {"code": "BH.PM", "display": "H. Phù Mỹ"}, 
                   {"code": "BH.TS", "display": "H. Tây Sơn"}, 
                   {"code": "BH.TP", "display": "H. Tuy Phước"}, 
                   {"code": "BH.VC", "display": "H. Vân Canh"}, 
                   {"code": "BH.VT", "display": "H. Vĩnh Thạnh"}, 
                   {"code": "BH.TP.QN", "display": "TP. Quy Nhơn"}, 
                   {"code": "BH.TX.AN", "display": "TX. An Nhơn"}
                  ], 
                  "code": "BH", "display": "Bình Định"}, 
                  
                  "Bắc Giang": 
                  {"districts": 
                  [
                    {"code": "BG.HH", "display": "H. Hiệp Hòa"}, 
                    {"code": "BG.LG", "display": "H. Lạng Giang"}, 
                    {"code": "BG.LM", "display": "H. Lục Nam"}, 
                    {"code": "BG.LN", "display": "H. Lục Ngạn"}, 
                    {"code": "BG.SD", "display": "H. Sơn Động"}, 
                    {"code": "BG.TY", "display": "H. Tân Yên"}, 
                    {"code": "BG.VY", "display": "H. Việt Yên"}, 
                    {"code": "BG.YD", "display": "H. Yên Dũng"}, 
                    {"code": "BG.YT", "display": "H. Yên Thế"}, 
                    {"code": "BG.TP.BG", "display": "TP. Bắc Giang"}
                  ], 
                  "code": "BG", "display": "Bắc Giang"}, 
                  
                  "Bến Tre": 
                  {"districts": 
                  [
                    {"code": "BT.BT", "display": "H. Ba Tri"}, 
                    {"code": "BT.BD", "display": "H. Bình Đại"}, 
                    {"code": "BT.CT", "display": "H. Châu Thành"}, 
                    {"code": "BT.CL", "display": "H. Chợ Lách"}, 
                    {"code": "BT.GT", "display": "H. Giồng Trôm"}, 
                    {"code": "BT.MCB", "display": "H. Mỏ Cày Bắc"}, 
                    {"code": "BT.MCN", "display": "H. Mỏ Cày Nam"}, 
                    {"code": "BT.TP", "display": "H. Thạnh Phú"}, 
                    {"code": "BT.TP.BT", "display": "TP. Bến Tre"}
                  ], 
                  "code": "BT", "display": "Bến Tre"}, 
                  
                  "Lạng Sơn": 
                  {"districts": 
                  [
                    {"code": "LS.BS", "display": "H. Bắc Sơn"}, 
                    {"code": "LS.BG", "display": "H. Bình Gia"}, 
                    {"code": "LS.CL", "display": "H. Cao Lộc"}, 
                    {"code": "LS.CG", "display": "H. Chi Lăng"}, 
                    {"code": "LS.DL", "display": "H. Đình Lập"}, 
                    {"code": "LS.HL", "display": "H. Hữu Lũng"}, 
                    {"code": "LS.LB", "display": "H. Lộc Bình"}, 
                    {"code": "LS.TD", "display": "H. Tràng Định"}, 
                    {"code": "LS.VL", "display": "H. Văn Lãng"}, 
                    {"code": "LS.VQ", "display": "H. Văn Quan"}, 
                    {"code": "LS.TP.LS", "display": "TP. Lạng Sơn"}
                  ], 
                  "code": "LS", "display": "Lạng Sơn"}, 
                  
                  "Cao Bằng": 
                  {"districts": 
                  [
                    {"code": "CB.BL", "display": "H. Bảo Lạc"}, 
                    {"code": "CB.BLM", "display": "H. Bảo Lâm"}, 
                    {"code": "CB.HL", "display": "H. Hạ Lang"}, 
                    {"code": "CB.HQ", "display": "H. Hà Quảng"}, 
                    {"code": "CB.HA", "display": "H. Hòa An"}, 
                    {"code": "CB.NB", "display": "H. Nguyên Bình"}, 
                    {"code": "CB.PH", "display": "H. Phục Hòa"}, 
                    {"code": "CB.QU", "display": "H. Quảng Uyên"}, 
                    {"code": "CB.TA", "display": "H. Thạch An"}, 
                    {"code": "CB.TN", "display": "H. Thông Nông"}, 
                    {"code": "CB.TL", "display": "H. Trà Lĩnh"}, 
                    {"code": "CB.TK", "display": "H. Trùng Khánh"}, 
                    {"code": "CB.TP.CB", "display": "TP. Cao Bằng"}
                  ], 
                  "code": "CB", "display": "Cao Bằng"}, 
                  
                  "Hồ Chí Minh": 
                  {"districts": 
                  [{"code": "001.BC", "display": "H. Bình Chánh"}, 
                  {"code": "001.CG", "display": "H. Cần Giờ"}, 
                  {"code": "001.CC", "display": "H. Củ Chi"}, 
                  {"code": "001.HM", "display": "H. Hóc Môn"}, 
                  {"code": "001.NB", "display": "H. Nhà Bè"}, 
                  {"code": "1.01", "display": "Q. 1"}, 
                  {"code": "1.1", "display": "Q. 10"}, 
                  {"code": "1.11", "display": "Q. 11"}, 
                  {"code": "1.12", "display": "Q. 12"}, 
                  {"code": "1.02", "display": "Q. 2"}, 
                  {"code": "1.03", "display": "Q. 3"}, 
                  {"code": "1.04", "display": "Q. 4"}, 
                  {"code": "1.05", "display": "Q. 5"}, 
                  {"code": "1.06", "display": "Q. 6"}, 
                  {"code": "1.07", "display": "Q. 7"}, 
                  {"code": "1.08", "display": "Q. 8"}, 
                  {"code": "1.09", "display": "Q. 9"}, 
                  {"code": "001.BB", "display": "Q. Bình Tân"}, 
                  {"code": "001.BT", "display": "Q. Bình Thạnh"}, 
                  {"code": "001.GV", "display": "Q. Gò Vấp"}, 
                  {"code": "001.PN", "display": "Q. Phú Nhuận"}, 
                  {"code": "001.TB", "display": "Q. Tân Bình"}, 
                  {"code": "001.TP", "display": "Q. Tân Phú"}, 
                  {"code": "001.TD", "display": "Q. Thủ Đức"}
                ],
                 "code": "1", "display": "Hồ Chí Minh"}, 
                 
                 "Đồng Nai": 
                 {"districts": 
                 [
                   {"code": "DN.CM", "display": "H. Cẩm Mỹ"}, 
                   {"code": "DN.DQ", "display": "H. Định Quán"}, 
                   {"code": "DN.LT", "display": "H. Long Thành"}, 
                   {"code": "DN.NT", "display": "H. Nhơn Trạch"}, 
                   {"code": "DN.TP", "display": "H. Tân Phú"}, 
                   {"code": "DN.TN", "display": "H. Thống Nhất"}, 
                   {"code": "DN.TB", "display": "H. Trảng Bom"}, 
                   {"code": "DN.VC", "display": "H. Vĩnh Cửu"}, 
                   {"code": "DN.XL", "display": "H. Xuân Lộc"}, 
                   {"code": "DN.TP.BH", "display": "TP. Biên Hòa"}, 
                   {"code": "DN.TX.LK", "display": "TX. Long Khánh"}
                  ], 
                  "code": "DN", "display": "Đồng Nai"},
                  
                  "Nghệ An": 
                  {"districts": 
                  [
                    {"code": "NA.AS", "display": "H. Anh Sơn"}, 
                    {"code": "NA.CC", "display": "H. Con Cuông"}, 
                    {"code": "NA.DC", "display": "H. Diễn Châu"}, 
                    {"code": "NA.DL", "display": "H. Đô Lương"}, 
                    {"code": "NA.HN", "display": "H. Hưng Nguyên"}, 
                    {"code": "NA.KS", "display": "H. Kỳ Sơn"}, 
                    {"code": "NA.ND", "display": "H. Nam Đàn"}, 
                    {"code": "NA.NL", "display": "H. Nghi Lộc"}, 
                    {"code": "NA.NGD", "display": "H. Nghĩa Đàn"}, 
                    {"code": "NA.QP", "display": "H. Quế Phong"}, 
                    {"code": "NA.QC", "display": "H. Quỳ Châu"}, 
                    {"code": "NA.QH", "display": "H. Quỳ Hợp"}, 
                    {"code": "NA.QL", "display": "H. Quỳnh Lưu"}, 
                    {"code": "NA.TK", "display": "H. Tân Kỳ"}, 
                    {"code": "NA.TQ", "display": "H. Tân Quỳ"}, 
                    {"code": "NA.TC", "display": "H. Thanh Chương"}, 
                    {"code": "NA.TD", "display": "H. Tương Dương"}, 
                    {"code": "NA.YT", "display": "H. Yên Thành"}, 
                    {"code": "NA.TP.V", "display": "TP. Vinh"},
                    {"code": "NA.CL", "display": "TX. Cửa Lò"}, 
                    {"code": "NA.TX.HM", "display": "TX. Hoàng Mai"}, 
                    {"code": "NA.TX.TH", "display": "TX. Thái Hòa"}
                  ], 
                  "code": "NA", "display": "Nghệ An"}, 
                  
                  "Hậu Giang": 
                  {"districts": 
                  [
                    {"code": "HG.CT", "display": "H. Châu Thành"}, 
                    {"code": "HG.CTA", "display": "H. Châu Thành A"}, 
                    {"code": "HG.PH", "display": "H. Phụng Hiệp"}, 
                    {"code": "HG.VT", "display": "H. Vị Thủy"}, 
                    {"code": "HG.TP.VT", "display": "TP. Vị Thanh"}, 
                    {"code": "HG.TX.LM", "display": "TX. Long Mỹ"}, 
                    {"code": "HG.TX.NB", "display": "TX. Ngã Bảy"}
                  ], 
                  "code": "HG", "display": "Hậu Giang"}, 
                  
                  "Hưng Yên": 
                  {"districts": 
                  [
                    {"code": "HY.AT", "display": "H. Ân Thi"}, 
                    {"code": "HY.KC", "display": "H. Khoái Châu"}, 
                    {"code": "HY.KD", "display": "H. Kim Động"}, 
                    {"code": "HY.MH", "display": "H. Mỹ Hào"}, 
                    {"code": "HY.PC", "display": "H. Phù Cừ"}, 
                    {"code": "HY.TL", "display": "H. Tiên Lữ"}, 
                    {"code": "HY.VG", "display": "H. Văn Giang"}, 
                    {"code": "HY.VL", "display": "H. Văn Lâm"}, 
                    {"code": "HY.YM", "display": "H. Yên Mỹ"}, 
                    {"code": "HY.TP.HY", "display": "TP. Hưng Yên"}
                  ], 
                  "code": "HY", "display": "Hưng Yên"}, 
                  
                  "Hải Dương": 
                  {"districts": 
                  [
                    {"code": "HD.BG", "display": "H. Bình Giang"}, 
                    {"code": "HD.CG", "display": "H. Cẩm Giàng"}, 
                    {"code": "HD.GL", "display": "H. Gia Lộc"}, 
                    {"code": "HD.KT", "display": "H. Kim Thành"}, 
                    {"code": "HD.KM", "display": "H. Kinh Môn"}, 
                    {"code": "HD.NS", "display": "H. Nam Sách"}, 
                    {"code": "HD.NG", "display": "H. Ninh Giang"}, 
                    {"code": "HD.TH", "display": "H. Thanh Hà"}, 
                    {"code": "HD.TM", "display": "H. Thanh Miện"}, 
                    {"code": "HD.TK", "display": "H. Tứ Kỳ"}, 
                    {"code": "HD.TP.HD", "display": "TP. Hải Dương"}, 
                    {"code": "HD.TX.CL", "display": "TX. Chí Linh"}
                  ], 
                  "code": "HD", "display": "Hải Dương"}, 
                  
                  "Bình Dương": 
                  {"districts": 
                  [
                    {"code": "BD.BTU", "display": "H. Bắc Tân Uyên"}, 
                    {"code": "BD.BB", "display": "H. Bàu Bàng"}, 
                    {"code": "BD.DT", "display": "H. Dầu Tiếng"}, 
                    {"code": "BD.LT", "display": "H. Lái Thiêu"}, 
                    {"code": "BD.PG", "display": "H. Phú Giáo"}, 
                    {"code": "BD.TA", "display": "H. Thuận An"}, 
                    {"code": "BD.TP.M", "display": "TP. Mới"}, 
                    {"code": "BD.TP.TD", "display": "TP. Thủ Dầu Một"}, 
                    {"code": "BD.TX.BC", "display": "TX. Bến Cát"}, 
                    {"code": "BD.TX.DA", "display": "TX. Dĩ An"}, 
                    {"code": "BD.TX.TU", "display": "TX. Tân Uyên"}, 
                    {"code": "BD.TX.TA", "display": "TX. Thuận An"}
                  ], 
                  "code": "BD", "display": "Bình Dương"}, 
                  
                  "Gia Lai":
                   {"districts": 
                   [
                     {"code": "GL.CP", "display": "H. Chư Păh"}, 
                     {"code": "GL.CG", "display": "H. Chư Prông"}, 
                     {"code": "GL.CPU", "display": "H. Chư Pưh"}, 
                     {"code": "GL.CS", "display": "H. Chư Sê"}, 
                     {"code": "GL.DD", "display": "H. Đăk Đoa"}, 
                     {"code": "GL.DP", "display": "H. Đăk Pơ"}, 
                     {"code": "GL.DC", "display": "H. Đức Cơ"}, 
                     {"code": "GL.IG", "display": "H. Ia Grai"}, 
                     {"code": "GL.IP", "display": "H. Ia Pa"}, 
                     {"code": "GL.K", "display": "H. K'Bang"}, 
                     {"code": "GL.KC", "display": "H. Kông Chro"}, 
                     {"code": "GL.KP", "display": "H. Krông Pa"}, 
                     {"code": "GL.MY", "display": "H. Mang Yang"}, 
                     {"code": "GL.PT", "display": "H. Phú Thiện"}, 
                     {"code": "GL.TP.P", "display": "TP. Pleiku"}, 
                     {"code": "GL.TX.AYP", "display": "TX. A Yun Pa"}, 
                     {"code": "GL.TX.AK", "display": "TX. An Khê"}
                    ], 
                    "code": "GL", "display": "Gia Lai"}, 
                    
                    "Bắc Cạn": 
                    {"districts": 
                    [
                      {"code": "BC.BB", "display": "H. Ba Bể"}, 
                      {"code": "BC.BT", "display": "H. Bạch Thông"}, 
                      {"code": "BC.CD", "display": "H. Chợ Đồn"}, 
                      {"code": "BC.CM", "display": "H. Chợ Mới"}, 
                      {"code": "BC.NR", "display": "H. Na Rì"}, 
                      {"code": "BC.NS", "display": "H. Ngân Sơn"}, 
                      {"code": "BC.PN", "display": "H. Pắc Nặm"},
                      {"code": "BC.TX.BC", "display": "TX. Bắc Cạn"}
                    ], 
                    "code": "BC", "display": "Bắc Cạn"}, 
                    
                    "Quảng Bình": 
                    {"districts": 
                    [
                    {"code": "QB.BT", "display": "H. Bố Trạch"}, 
                    {"code": "QB.LT", "display": "H. Lệ Thủy"}, 
                    {"code": "QB.MH", "display": "H. Minh Hóa"}, 
                    {"code": "QB.QN", "display": "H. Quảng Ninh"}, 
                    {"code": "QB.QT", "display": "H. Quảng Trạch"}, 
                    {"code": "QB.TH", "display": "H. Tuyên Hóa"}, 
                    {"code": "QB.TP.DH", "display": "TP. Đồng Hới"}, 
                    {"code": "QB.TX.BD", "display": "TX. Ba Đồn"}
                  ], 
                  "code": "QB", "display": "Quảng Bình"}, 
                  
                  "Sóc Trăng": 
                  {"districts": 
                  [
                    {"code": "ST.CD", "display": "H. Cần Đề"}, 
                    {"code": "ST.CT", "display": "H. Châu Thành"}, 
                    {"code": "ST.CLD", "display": "H. Cù Lao Dung"}, 
                    {"code": "ST.HM", "display": "H. Hòa Mỹ"}, 
                    {"code": "ST.KS", "display": "H. Kế Sách"}, 
                    {"code": "ST.LP", "display": "H. Long Phú"}, 
                    {"code": "ST.MT", "display": "H. Mỹ Tú"}, 
                    {"code": "ST.MX", "display": "H. Mỹ Xuyên"}, 
                    {"code": "ST.TT", "display": "H. Thạnh Trị"}, 
                    {"code": "ST.TD", "display": "H. Trần Đề"}, 
                    {"code": "ST.TP.ST", "display": "TP. Sóc Trăng"}, 
                    {"code": "ST.TX.NN", "display": "TX. Ngã Năm"}, 
                    {"code": "ST.TX.VC", "display": "TX. Vĩnh Châu"}
                  ], 
                  "code": "ST", "display": "Sóc Trăng"}, 
                  
                  "Thái Nguyên": 
                  {"districts": 
                  [
                    {"code": "THN.DT", "display": "H. Đại Từ"}, 
                    {"code": "THN.DH", "display": "H. Định Hóa"}, 
                    {"code": "THN.DY", "display": "H. Đồng Hỷ"}, 
                    {"code": "THN.PB", "display": "H. Phú Bình"}, 
                    {"code": "THN.PL", "display": "H. Phú Lương"}, 
                    {"code": "THN.VN", "display": "H. Võ Nhai"}, 
                    {"code": "THN.TP.TN", "display": "TP. Thái Nguyên"}, 
                    {"code": "THN.PY", "display": "TX. Phổ Yên"},
                     {"code": "THN.TX.SC", "display": "TX. Sông Công"}
                    ], 
                    "code": "THN", "display": "Thái Nguyên"}, 
                    
                    "Kiên Giang": 
                    {"districts": 
                    [
                      {"code": "KG.AB", "display": "H. An Biên"}, 
                      {"code": "KG.AM", "display": "H. An Minh"}, 
                      {"code": "KG.CT", "display": "H. Châu Thành"}, 
                      {"code": "KG.GT", "display": "H. Giang Thành"}, 
                      {"code": "KG.GR", "display": "H. Giồng Riềng"}, 
                      {"code": "KG.GQ", "display": "H. Gò Quao"}, 
                      {"code": "KG.HD", "display": "H. Hòn Đất"}, 
                      {"code": "KG.KB", "display": "H. Kiên Biên"}, 
                      {"code": "KG.KH", "display": "H. Kiên Hải"}, 
                      {"code": "KG.KL", "display": "H. Kiên Lương"}, 
                      {"code": "KG.PQ", "display": "H. Phú Quốc"}, 
                      {"code": "KG.TH", "display": "H. Tân Hiệp"}, 
                      {"code": "KG.UMT", "display": "H. U Minh Thượng"}, 
                      {"code": "KG.VT", "display": "H. Vĩnh Thuận"}, 
                      {"code": "KG.RG", "display": "TP. Rạch Giá"}, 
                      {"code": "KG.TX.HT", "display": "TX. Hà Tiên"}
                    ], 
                    "code": "KG", "display": "Kiên Giang"}, 
                    
                    "Tuyên Quang": 
                    {"districts": 
                    [
                      {"code": "TQ.CH", "display": "H. Chiêm Hóa"}, 
                      {"code": "TQ.HY", "display": "H. Hàm Yên"}, 
                      {"code": "TQ.LB", "display": "H. Lâm Bình"}, 
                      {"code": "TQ.NH", "display": "H. Na Hang"}, 
                      {"code": "TQ.SD", "display": "H. Sơn Dương"}, 
                      {"code": "TQ.YS", "display": "H. Yên Sơn"}, 
                      {"code": "TQ.TP.TQ", "display": "TP. Tuyên Quang"}
                    ], 
                    "code": "TQ", "display": "Tuyên Quang"}, 
                    
                    "Thừa Thiên Huế": 
                    {"districts": 
                    [
                      {"code": "TTH.AL", "display": "H. A Lưới"}, 
                      {"code": "TTH.HN", "display": "H. Hội An"}, 
                      {"code": "TTH.ND", "display": "H. Nam Đông"}, 
                      {"code": "TTH.NT", "display": "H. Núi Thành"}, 
                      {"code": "TTH.PD", "display": "H. Phong Điền"}, 
                      {"code": "TTH.PL", "display": "H. Phú Lộc"}, 
                      {"code": "TTH.PV", "display": "H. Phú Vang"}, 
                      {"code": "TTH.QD", "display": "H. Quảng Điền"}, 
                      {"code": "TTH.TP.H", "display": "TP. Huế"}, 
                      {"code": "TTH.TX.HT", "display": "TX. Hương Thủy"}, 
                      {"code": "TTH.TX.HA", "display": "TX. Hương Trà"}
                    ], 
                    "code": "TTH", "display": "Thừa Thiên Huế"}, 
                    
                    "Vĩnh Long": 
                    {"districts": 
                    [
                      {"code": "VL.BT", "display": "H. Bình Tân"}, 
                      {"code": "VL.LH", "display": "H. Long Hồ"}, 
                      {"code": "VL.MT", "display": "H. Mang Thít"}, 
                      {"code": "VL.TB", "display": "H. Tam Bình"}, 
                      {"code": "VL.TO", "display": "H. Trà Ôn"}, 
                      {"code": "VL.VL", "display": "H. Vũng Liêm"}, 
                      {"code": "VL.TP.VL", "display": "TP. Vĩnh Long"}, 
                      {"code": "VL.TX.BM", "display": "TX. Bình Minh"}
                    ], 
                    "code": "VL", "display": "Vĩnh Long"}, 
                    
                    "Hà Tĩnh": 
                    {"districts": 
                    [
                      {"code": "HAT.CX", "display": "H. Cẩm Xuyên"}, 
                      {"code": "HAT.CL", "display": "H. Can Lộc"}, 
                      {"code": "HAT.DT", "display": "H. Đức Thọ"}, 
                      {"code": "HAT.HK", "display": "H. Hương Khê"}, 
                      {"code": "HAT.HS", "display": "H. Hương Sơn"}, 
                      {"code": "HAT.LH", "display": "H. Lộc Hà"}, 
                      {"code": "HAT.NX", "display": "H. Nghi Xuân"}, 
                      {"code": "HAT.TH", "display": "H. Thạch Hà"}, 
                      {"code": "HAT.VQ", "display": "H. Vũ Quang"}, 
                      {"code": "HAT.TP.HT", "display": "Tp. Hà Tĩnh"}, 
                      {"code": "HAT.TX.HL", "display": "TX. Hồng Lĩnh"},
                      {"code": "HAT.TX.KA", "display": "TX. Kỳ Anh"}
                    ], 
                    "code": "HAT", "display": "Hà Tĩnh"}, 
                    
                    "Khánh Hòa": 
                    {"districts": 
                    [
                      {"code": "KH.CL", "display": "H. Cam Lâm"}, 
                      {"code": "KH.DK", "display": "H. Diên Khánh"}, 
                      {"code": "KH.KS", "display": "H. Khánh Sơn"}, 
                      {"code": "KH.KV", "display": "H. Khánh Vĩnh"}, 
                      {"code": "KH.TS", "display": "H. Trường Sa"}, 
                      {"code": "KH.VN", "display": "H. Vạn Ninh"}, 
                      {"code": "KH.TP.CR", "display": "TP. Cam Ranh"}, 
                      {"code": "KH.TP.NT", "display": "TP. Nha Trang"}, 
                      {"code": "KH.TX.NH", "display": "TX. Ninh Hòa"}
                    ], 
                    "code": "KH", "display": "Khánh Hòa"}, 
                    
                    "Đăk Lăk": 
                    {"districts": 
                    [
                      {"code": "DL.BD", "display": "H. Buôn Đôn"}, 
                      {"code": "DL.CK", "display": "H. Cư Kuin"}, 
                      {"code": "DL.CM", "display": "H. Cư M'Gar"}, 
                      {"code": "DL.EL", "display": "H. Ea H'Leo"}, 
                      {"code": "DL.EK", "display": "H. Ea Kar"}, 
                      {"code": "DL.ES", "display": "H. EA Súp"}, 
                      {"code": "DL.KA", "display": "H. Krông Ana"}, 
                      {"code": "DL.KB", "display": "H. Krông Bông"}, 
                      {"code": "DL.KK", "display": "H. Krông Buk"}, 
                      {"code": "DL.FN", "display": "H. Krông Năng"}, 
                      {"code": "DL.KP", "display": "H. Krông Pắc"}, 
                      {"code": "DL.L", "display": "H. Lăk"}, 
                      {"code": "DL.MD", "display": "H. M'Đrăk"}, 
                      {"code": "DL.TP.BM", "display": "TP. Buôn Ma Thuột"}, 
                      {"code": "DL.BH", "display": "TX. Buôn Hồ"}
                    ], 
                    "code": "DL", "display": "Đăk Lăk"}, 
                    
                    "Nam Định": 
                    {"districts": 
                    [
                      {"code": "ND.GT", "display": "H. Giao Thủy"}, 
                      {"code": "ND.HH", "display": "H. Hải Hậu"}, 
                      {"code": "ND.ML", "display": "H. Mỹ Lộc"}, 
                      {"code": "ND.NT", "display": "H. Nam Trực"}, 
                      {"code": "ND.NH", "display": "H. Nghĩa Hưng"}, 
                      {"code": "ND.TN", "display": "H. Trực Ninh"}, 
                      {"code": "ND.VB", "display": "H. Vụ Bản"}, 
                      {"code": "ND.XT", "display": "H. Xuân Trường"}, 
                      {"code": "ND.YY", "display": "H. ý Yên"}, 
                      {"code": "ND.TP.ND", "display": "TP. Nam Định"}
                    ], 
                    "code": "ND", "display": "Nam Định"}, 
                    
                    "Tiền Giang": 
                    {"districts": 
                    [
                      {"code": "TG.APD", "display": "H. An Phú Đông"}, 
                      {"code": "TG.CB", "display": "H. Cái Bè"}, 
                      {"code": "TG.CL", "display": "H. Cai Lậy"}, 
                      {"code": "TG.CT", "display": "H. Châu Thành"}, 
                      {"code": "TG.CG", "display": "H. Chợ Gạo"}, 
                      {"code": "TG.GCD", "display": "H. Gò Công Đông"}, 
                      {"code": "TG.GCT", "display": "H. Gò Công Tây"}, 
                      {"code": "TG.TPD", "display": "H. Tân Phú Đông"}, 
                      {"code": "TG.TP", "display": "H. Tân Phước"}, 
                      {"code": "TG.TP.MT", "display": "TP. Mỹ Tho"}, 
                      {"code": "TG.TX.CL", "display": "TX. Cai Lậy"}, 
                      {"code": "TG.TX.GC", "display": "TX. Gò Công"}
                    ], 
                    "code": "TG", "display": "Tiền Giang"}, 
                    
                    "Quảng Ngãi": 
                    {"districts": 
                    [
                      {"code": "QI.BT", "display": "H. Ba Tơ"}, 
                      {"code": "QI.BS", "display": "H. Bình Sơn"}, 
                      {"code": "QI.DP", "display": "H. Đức Phổ"}, 
                      {"code": "QI.LS", "display": "H. Lý Sơn"}, 
                      {"code": "QI.ML", "display": "H. Minh Long"}, 
                      {"code": "QI.MD", "display": "H. Mộ Đức"}, 
                      {"code": "QI.NH", "display": "H. Nghĩa Hành"}, 
                      {"code": "QI.SH", "display": "H. Sơn Hà"}, 
                      {"code": "QI.ST", "display": "H. Sơn Tây"}, 
                      {"code": "QI.SOT", "display": "H. Sơn Tịnh"}, 
                      {"code": "QI.TT", "display": "H. Tây Trà"}, 
                      {"code": "QI.TB", "display": "H. Trà Bồng"}, 
                      {"code": "QI.TN", "display": "H. Tư Nghĩa"}, 
                      {"code": "QI.TP.QN", "display": "TP. Quảng Ngãi"}
                    ], 
                    "code": "QI", "display": "Quảng Ngãi"}, 
                    
                    "Lào Cai": 
                    {"districts": 
                    [
                      {"code": "LI.BH", "display": "H. Bắc Hà"}, 
                      {"code": "LI.BT", "display": "H. Bảo Thắng"}, 
                      {"code": "LI.BY", "display": "H. Bảo Yên"}, 
                      {"code": "LI.BX", "display": "H. Bát Xát"}, 
                      {"code": "LI.MK", "display": "H. Mường Khương"}, 
                      {"code": "LI.SP", "display": "H. Sa Pa"}, 
                      {"code": "LI.SMC", "display": "H. Si Ma Cai"}, 
                      {"code": "LI.VB", "display": "H. Văn Bàn"}, 
                      {"code": "LI.TP.LC", "display": "TP. Lào Cai"}
                    ], 
                    "code": "LI", "display": "Lào Cai"}
                  }









    ]
  }
}
