import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import jsQR from 'jsqr';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import {  BsModalService,ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
@Component({
  selector: 'app-profile-home',
  templateUrl: './profile-home.component.html',
  styleUrls: ['./profile-home.component.scss']
})
export class ProfileHomeComponent implements OnInit {
  scanScode: any;
  authToken:any;
  seriesCode1: any;
  seriesCode2: any;
  showScanner: Boolean = false;
  showProfile: Boolean = true;
  latitude:any;
  scancodeData:any;
  langitude:any;
  customerData: any;
  signupVoucher: any;
  myDate = new Date();
  custom_isGrow: boolean = false;
  eligible_nonmilk_grow: boolean = false;
  eligible_nonmilk_similac: boolean = false;
  non_redemmed_grow: any = [];
  non_redemmed_similac: any = [];
  redemmed: boolean = false;
  is_not_expired: boolean = false;
  non_redeemed_grow: any = [];
  non_redeemed_similac: any = [];
  game_is_eligible: boolean = false;
  cp_non_similac: any = [];

  scannedDate: any;
  remaining_grow_chances: any;
  scanCode: any;
  categoryCode: any;

  all_nonmilk_array: any = [];
  is_grow: boolean = false;
  nonmilk_grow_array: any = [];
  nonmilk_similac_array: any = [];
  expiryDate: any;
  minDate:Date;
  maxDate:Date;
  myDateValue: Date;
  is_eligilbe_grow: boolean;
  growRewardList:any;
  similacRewardList:any;
  FinalMilkcode:any;
  remaining_similac_chances: any;

  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('qrCode1Modal', { static: false}) qrCode1Modal: ModalDirective;
  @ViewChild('testModal', { static: false}) testModal: ModalDirective;
  @ViewChild('successQrCodeModal', { static: true }) successQrCodeModal: ModalDirective;
  @ViewChild('successQrCodeModalOne', { static: true }) successQrCodeModalOne: ModalDirective;
  @ViewChild('milkCodeModal', { static: true }) milkCodeModal: ModalDirective;
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false
  };
  @ViewChild('invalidQrCodeModal', { static: true }) invalidQrCodeModal: ModalDirective;
  config = {
    ignoreBackdropClick: true
  };
  constructor(private router: Router, private apiService: ApiService, private spinner: NgxSpinnerService,
    private modalService: BsModalService) { 
    window.scrollTo({top: 0});
    this.myDate.setDate(this.myDate.getDate() + 30);
    this.authToken = sessionStorage.getItem('authToken');

    window.scrollTo({top: 0});
    this.scanCode = sessionStorage.getItem('scanCode');
    if(this.scanCode != "undefined"){
    let qrCodeData = JSON.parse(sessionStorage.getItem('productdata'));
    this.categoryCode = qrCodeData?.product?.categoryCode;
    this.categoryCode == "Similac" ? this.is_grow = false : this.is_grow = true;
  }else{
    console.log('cleared local storage---')
    // sessionStorage.clear();
  }

    setTimeout(()=>{
      this.remaining_grow_chances = JSON.parse(sessionStorage.getItem('grow_nonmilk_remaining_count'));
      this.remaining_similac_chances = JSON.parse(sessionStorage.getItem('similac_nonmilk_remaining_count'));
      
      this.remaining_grow_chances  = this.remaining_grow_chances < 0 ? this.remaining_grow_chances = 0 : 0 + this.remaining_grow_chances
      this.remaining_similac_chances  = this.remaining_similac_chances < 0 ? this.remaining_similac_chances = 0 : 0 + this.remaining_similac_chances
      if(!this.authToken){
        this.remaining_grow_chances = 5;
        this.remaining_similac_chances = 5;
  
      }
  
    },2000)
   


    let today = new Date();
    this.scannedDate = moment(today).format('YYYY-MM-DD');

    let endDate = moment(today).add(30, 'days');
    this.expiryDate = moment(endDate).format('YYYY-MM-DD');

    }

  

  ngOnInit(): void {
    this.getProductDescCode();
    this.myDateValue = new Date();
  }
  openQrcode1Popup() {
    this.qrCode1Modal.show();
  }
  closeModalOne() {
    this.successQrCodeModalOne.hide();
  }
  openQrCodeOneModal() {
    this.successQrCodeModalOne.show();
  }
  onDateChange(newDate: Date) {
    console.log(newDate);
  }
  successQrModal(){
    this.successQrCodeModal.show();
  }
  closeSuccessModal(){
    this.successQrCodeModal.hide();
    this.router.navigate(['/user-profile']);
  }
  onClickScanner() {
    this.modalService.hide();
    this.showScanner = true;
    this.showProfile = false;

    if (this.showScanner) {
      this.startVideo();
    }

  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
  }
  closeQrCode1Modal() {
    this.qrCode1Modal.hide();
  }

  startVideo() {
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 }
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }

  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
    if (code) {
      let scannedCode = code.data
      let getAuthKey = sessionStorage.getItem('authToken');
      window.location.href = scannedCode;
    //   this.spinner.show();
    //   this.apiService.getTraceability(scannedCode).subscribe((res: any) => {
    //     this.spinner.hide();
    //     sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
    //     this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //     console.log(this.scancodeData, 'productdata')
    //     if(res.result.code == '0'){
    //         if(res.result.data.uaidInfo.type == 1 || res.result.data.uaidInfo.type == 7){
    //         this.router.navigate(['/home']);
    //         }else if(res.result.data.uaidInfo.type == 2){
    //         if(getAuthKey){
    //             console.log('available Auth kery', getAuthKey)
    //       this.apiService.getPosition().then(pos => {
    //         let lat = pos.lat
    //         let lang = pos.lng
    //         let latitude = lat.toString()
    //         let langitude = lang.toString()
    //         let getMobile = sessionStorage.getItem('mobileNo')
    //         let getAuthKey = sessionStorage.getItem('authToken')
    //         let productName = sessionStorage.getItem('productName');
    //         let formData = {
    //             "scan_code": scannedCode,
    //             "mobile": getMobile,
    //             "cap_device_id": env.DEVICE_ID,
    //             "custom_fields": [
    //               {
    //                 "name": "TransLat",
    //                 "value": latitude
    //               },
    //               {
    //                 "name": "TransLong",
    //                 "value": langitude
    //              },
    //              {
    //                 "name": "productName",
    //                 "value": productName
    //              },
    //              { 
    //               "name":"categorycode",
    //               "value":this.categoryCode ? this.categoryCode : ''
    //             },
    //             {
    //               "name":"is_milk",
    //               "value": this.is_milk
    //             },
    //             {
    //               "name": "is_grow",
    //               "value": this.is_grow
    //             },
    //             {
    //               "name": "itemcode",
    //               "value": this.subCatCode
    //             },
    //             {
    //               "name": "scanned_date",
    //               "value": this.scannedDate
    //             },
    //             {
    //               "name": "expiration_date",
    //               "value": this.expiryDate
    //             },
    //             {
    //               "name": "is_eligible_for_game",
    //               "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
    //             },
    //             {
    //               "name": "is_redeemed",
    //               "value": false
    //             },
    //             {
    //               "name": "gift_id",
    //               "value": 'null'
    //             },
    //             {
    //               "name": "gift_delivery_date",
    //               "value": 'null'
    //             }
    
    //           ]
    //         }
    //         this.spinner.show();
    //         this.apiService.addTransaction(formData, getAuthKey).pipe(first())
    //         .subscribe((res:any) => {
    //           console.log("res", res?.result?.data?.product?.txt);
    //           let milkcodeFind = res?.result?.data?.product?.txt;
    //           if(milkcodeFind){
    //             milkcodeFind.forEach((item: any) => {
    //               if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //                 let parsedPrimaryValue = this.parseFunction(item.value);
    //                 this.FinalMilkcode = parsedPrimaryValue.value;
    //                 console.log(this.FinalMilkcode, 'FinalMilkcode')
    //               }
    //             });
    //           }
    
    //           this.spinner.hide();
    //           this.getNonMilkCount();
    //           this.showScanner = false;
    //           this.toggleVideoMedia();
    
    //           if(this.FinalMilkcode == '1'){ 
    //             this.openMilkCodeModal();
    //           }
    
    //           if(this.FinalMilkcode == '0'){
    //           let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
    //           let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
    //           console.log(SimilacCount, 'GrowCount')
    //           console.log(GrowCount, 'GrowCount')
             
              
    //           if(this.is_grow){
    //             if(this.remaining_grow_chances < 5){
    //               this.successQrCodeModal.show();
    //             }
    //             else{
    //               this.router.navigate(['/lucky-draw-quota-exceed']);
    //             }
    //           }
    //           else{
    //             if(this.remaining_similac_chances < 5){
    //               console.log(this.remaining_similac_chances, 'this.remaining_similac_chances')
    //               this.successQrCodeModal.show();
    //             }
    //             else{
    //               console.log(this.remaining_similac_chances, 'this.remaining_similac_chances2')
    //               this.router.navigate(['/lucky-draw-quota-exceed']);
    //             }
    //           }  
    //         } 
    //           // this.getCustomer();
    //         }, err => {
    //           this.showScanner = false;
    //           this.spinner.hide();
    //           // this.apiService.showToaster(err);
    //           if(err?.error?.message == "Given code already verified"){
    //             this.openinvalidQrCodeModal()
    //           }else if('Đã xảy ra lỗi. Vui lòng thử lại sau' == "Invalid scanned code" || err == "Wrong scanned code"){
    //             // this.qrCode1Modal.show();
    //           }
              
    //           this.toggleVideoMedia();
    //         })
    //       })
    //         }else{
    //             this.router.navigate(['/sign-up']);
    //         }
            
    //         }
    //     }
    // },err=>{
    //         console.log(err, 'err========')
    // })


    } 
    else {
      setTimeout(() => { this.checkImage(); }, 100)
    }
  }
  getNonMilkCount(){
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')

    this.apiService.getTransaction(mobile_no, getAuthKey, startOfMonth, endOfMonth).pipe(first())
    .subscribe((res:any)=>{
      let custom_fields : any;
      let result = res?.customer?.transactions;
      let transcation = result?.transaction;
      if(transcation){
        custom_fields = transcation.map(val =>{
          return val.custom_fields['field']
        });
      }
      if(custom_fields){
        this.getTransactionCount(custom_fields);
      }
    })
  }

  gotToRewardsGrow() {
    this.router.navigate(['/rewards',{product: 'grow'}]);
  }

  gotToRewardsSimilac() {
    this.router.navigate(['/rewards', {product: 'similac'}]);
  }
  
  goToSignUp(){
    if(!this.authToken){
      this.router.navigate(["/sign-up/1"]);
    }
  }
  goToLibrary(){
    sessionStorage.setItem('enableLibrary','library');
    if(!this.authToken){
      this.router.navigate(["/sign-up/1"]);
    }
  }
  openinvalidQrCodeModal() {
    this.invalidQrCodeModal.show()
  }
  invalidQrCodeHide() {
    this.invalidQrCodeModal.hide()
  }


  getPreviousTranscation(){
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth   = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
  
    this.spinner.show();
    this.apiService.getTransaction(mobile_no, getAuthKey, startOfMonth, endOfMonth).pipe(first())
    .subscribe(res=>{
      let result = res['customer']['transactions'];
      let transcation = result['transaction'];
      transcation.filter(val =>{
        
        let custom_fields = val.custom_fields['field'];
        custom_fields.push({name: 'transaction_id', value: val.number})
        this.getTransactionCount(custom_fields);
        
      });
  })
  }
  
 
  getTransactionCount(custom_fields1){
    let  non_redeemed_grow = [];
    let  non_redeemed_similac = [];
    let nonmilk_similac_array = [];
    let nonmilk_grow_array = [];
    let all_nonmilk_array = []

    custom_fields1.filter(custom_fields=>{

      let a = custom_fields.findIndex(val=>val.name=='is_grow');
      let b = custom_fields.findIndex(val=>val.name=='is_milk');   
      if(a > b){
        let temp = custom_fields[a];
        custom_fields[a] = custom_fields[b];
        custom_fields[b] = temp;
      }
      let c = custom_fields.findIndex(val=>val.name=='is_redeemed');
      let d = custom_fields.findIndex(val=>val.name=='is_milk');
      if(c > d){
        let temp = custom_fields[c];
        custom_fields[c] = custom_fields[d];
        custom_fields[d] = temp;
      }
  
      custom_fields.filter(val =>{
  
        if(val.name == 'is_grow'){
          val.value == "true" ? this.custom_isGrow = true : this.custom_isGrow = false;
        }
  
        if(val.name == "expiration_date"){
          this.scannedDate < val.value ? this.is_not_expired = true : this.is_not_expired= false;
        }
  
        if(val.name == "is_eligible_for_game"){
          val.value == "true" ? this.game_is_eligible = true : this.game_is_eligible = false;
        }
  
        if(val.name == 'is_redeemed'){
          val.value == "true" ? this.redemmed = true : this.redemmed = false;
        }
       
        if(val.name == 'is_milk' && val.value == "false"){
          all_nonmilk_array.push(custom_fields);
          if(this.custom_isGrow){
            nonmilk_grow_array.push(custom_fields);    
          }
          else{
            nonmilk_similac_array.push(custom_fields);
          }
          if(this.custom_isGrow && this.is_not_expired && this.redemmed == false && this.game_is_eligible){
            non_redeemed_grow.push(custom_fields)
          }
          if(this.custom_isGrow == false && this.is_not_expired && this.redemmed == false && this.game_is_eligible){
            non_redeemed_similac.push(custom_fields);
          }
        }
  
      })
      sessionStorage.setItem('non_redeemed_grow', JSON.stringify(non_redeemed_grow));
      sessionStorage.setItem('non_redeemed_similac', JSON.stringify(non_redeemed_similac));
  
      let all_nonmilk_count = all_nonmilk_array.length; //given final count of non-milk(both grow and similac)
      let grow_nonmilk_count = nonmilk_grow_array.length; // gives final count non-milk grow
      let similac_nonmilk_count = nonmilk_similac_array.length // gives final count non-milk similac
  
      sessionStorage.setItem('similacMilCount', JSON.stringify(similac_nonmilk_count));
      sessionStorage.setItem('growMilkCount', JSON.stringify(grow_nonmilk_count));
   
      let remain_nonmilk_grow_count = grow_nonmilk_count;
      let remain_nonmilk_similac_count = similac_nonmilk_count;

      sessionStorage.setItem('grow_nonmilk_remaining_count', remain_nonmilk_grow_count.toString());
      sessionStorage.setItem('similac_nonmilk_remaining_count', remain_nonmilk_similac_count.toString());
  
  
      // grow_nonmilk_count <= 5 ? this.eligible_nonmilk_grow = true : this.eligible_nonmilk_grow = false;
      // similac_nonmilk_count <= 5 ? this.eligible_nonmilk_similac = true : this.eligible_nonmilk_similac = false;

    })
   
  }


  content: any;
  subCatCode: any;
  milkCode: any;
  is_milk: any;
  getProductDescCode() {
    this.content = this.scancodeData?.product?.txt;
  if(this.content){
    this.content.forEach((item: any) => {
    
      if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
        let parsedPrimaryValue = this.parseFunction(item.value);
        this.subCatCode = parsedPrimaryValue.value;
      }
     
      if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
        let parsedPrimaryValue = this.parseFunction(item.value);
        this.milkCode = parsedPrimaryValue.value;
        this.milkCode == 0 ? this.is_milk = false : this.is_milk = true;
      }
    });
  }
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  growOrSimilac(){
    if(this.is_grow && this.eligible_nonmilk_grow){
      this.modalService.hide();
      this.router.navigate(['/grow-spinwheel']);
    }
    if(this.is_grow && this.is_eligilbe_grow == false){
      this.router.navigate(['/lucky-draw-quota-exceed']);
    }
    if(this.is_grow == false && this.eligible_nonmilk_similac){
      this.modalService.hide();
      this.router.navigate(['/similac-spinwheel']);
    }
  }

  openMilkCodeModal() {
    this.milkCodeModal.show()
  }
  milkCodeHide() {
    this.milkCodeModal.hide();
      this.router.navigate(['user-profile']);
  }
  closeScanner(){
    this.showScanner = false;
    this.showProfile = true;
  }
}
