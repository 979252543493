<app-header></app-header>

<section class="signUpContainer">

  <!-- signup-01 starts-->
  <img class="img-fluid" src="../../../assets/images/caresharebannertwo.png" />
  <form [formGroup]="signUpFormOne" class="formContainer pb-5" (ngSubmit)="onSignUpSubmit()">
    <div class="form-group">
      <input type="number" class="form-control" formControlName="phoneNumber" (change)="getMobNum($event)" id="exampleInputEmail1"
        aria-describedby="emailHelp" placeholder=""
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        [ngClass]="{'is-invalid': submitted && SignUpFormVal.phoneNumber.errors}" required>
      <label for="exampleInputEmail1">{{ 'SIGNUP.LABEL_SIGNUP_ONE' | translate }}</label>

      <div *ngIf="submitted && SignUpFormVal.phoneNumber.errors" class="invalid-feedback">
        <div *ngIf="SignUpFormVal.phoneNumber.errors.required">Vui lòng nhập số điện thoại</div>
        <div *ngIf="SignUpFormVal.phoneNumber.errors.pattern">
          Số điện thoại di động không hợp lệ
      </div> 
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-block orangeBtn mt-3">{{ 'SIGNUP.SIGNUP_ONE_BTN_TEXT' | translate}}</button>
  </form>
  <!-- signup-01 starts-->


  <!-- signup-04 starts -->

  <!-- signup-04 starts -->

  <!--Using items input-->
</section>
<app-auth-footer></app-auth-footer>
<app-nav-menu></app-nav-menu>