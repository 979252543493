<app-header></app-header>
<section class="signUpContainer">
    <section class="formContainer">

        <!-- forgot mobile number form -->
        <section *ngIf="showMobileForm" class="mt-3">
            <h1 class="darKBlueBoldTitle mb-0 text-center">{{ 'FORGOT_PASSWORD.TOP_TITLE' | translate }}</h1>
            <!-- <p class="mb-0 mt-3 text-center"> {{ 'FORGOT_PASSWORD.PARA1' | translate }}</p>
            <p class="mb-0 text-center"> {{ 'FORGOT_PASSWORD.PARA2' | translate }}</p> -->
            <form [formGroup]="ForgotMobileForm" class="mt-5" (ngSubmit)="onMobileFormSubmit()">
                <div class="form-group mb-2">
                    <input type="number" class="form-control"
                        [ngClass]="{'is-invalid': submittedMobileForm && mobileFormVal.phoneNumber.errors}" readonly
                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" formControlName="phoneNumber"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        [ngClass]="{'is-invalid': submittedMobileForm && mobileFormVal.phoneNumber.errors}" required>
                    <label for="exampleInputEmail1">{{ 'FORGOT_PASSWORD.LABEL' | translate }}</label>
                    <div *ngIf="submittedMobileForm && mobileFormVal.phoneNumber.errors" class="invalid-feedback">
                        <div *ngIf="mobileFormVal.phoneNumber.errors.required">PhoneNumber is Required</div>
                        <div *ngIf="mobileFormVal.phoneNumber.errors.pattern">
                            Invalid Mobile Number
                        </div>
                    </div>
                </div>
                <!-- <button type="submit" class="btn btn-primary btn-block orangeBtn mt-3">{{
                    'FORGOT_PASSWORD.BTN_TEXT' | translate }}</button> -->
                    <button type="submit" class="btn btn-primary btn-block orangeBtn mt-3">Tiếp</button>
            </form>
        </section>

        <!-- forgotOtpform -->
        <section class="mt-3" *ngIf="showOtpForm">
            <p class="mb-0 text-center">{{ 'FORGOT_PASSWORD.FORGOT_OTP_PARA' | translate }}</p>
            <p class="text-center">({{addMobNum}}), vui lòng {{ 'FORGOT_PASSWORD.FORGOT_OTP_PARA1' | translate }}</p>
            <form [formGroup]="ForgotOtpForm" class="mt-4" (ngSubmit)="onOtpFormSubmit()">
                <div class="form-group mt-3">
                    <input type="text" class="form-control" formControlName="otp" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder=""
                        [ngClass]="{'is-invalid': submittedOtpForm && otpFormVal.otp.errors || otpError}" maxlength="6" required>
                    <label for="exampleInputEmail1">{{ 'FORGOT_PASSWORD.LABEL1' | translate }}</label>
                    <div *ngIf="submittedOtpForm &&  otpFormVal.otp.errors || otpError" class="invalid-feedback">
                        <div class="text-left"> {{ 'OTP.OTP_ERROR_MESSAGE'| translate }} </div>
                    </div>
                </div>
                <!-- <button type="submit" class="btn btn-primary btn-block orangeBtn">{{ 'FORGOT_PASSWORD.OTP_BTN_TEXT'|
                    translate }}</button> -->

                    <div class="row justify-content-center">
                        <div class="col">
                          <button type="button" class="btn btn-primary btn-block orangeBtnOutline" routerLink="/sign-up">{{ 'OTP.BTN_LEFT' |
                            translate
                            }}</button>
                        </div>
                        <div class="col">
                          <button type="submit" class="btn btn-primary btn-block orangeBtn">{{ 'OTP.BTN_RIGHT' | translate
                            }}</button>
                        </div>
                      </div>

                <!-- <p class="mt-3">{{ 'OTP.BOTTOM_DESC_ONE' | translate }} <span class="linkText" (click)="resendOtp()"> {{ 'OTP.BOTTOM_DESC_TWO'
                        | translate }} </span></p> -->

                        <div [ngClass]="!showResendOtp? 'otpStart' : ''" class="text-center">
                            <p class="mt-3">{{ 'OTP.BOTTOM_DESC_ONE' | translate }}<br>  
                              <span (click)="!showResendOtp ? '' : resendOtp()"  [ngClass]="!showResendOtp? '' : 'linkText'">
                                  {{ 'OTP.BOTTOM_DESC_THREE' | translate }}
                                  <span *ngIf="!showResendOtp"><countdown #cd [config]="{leftTime: 60,format: 'm:ss',notify: [ 2, 5 ]}"
                                    (event)="handleEvent($event)"></countdown></span>
                                  <span *ngIf="showResendOtp">0:00</span>
                              </span>
                            </p>
                          </div> 

            </form>
        </section>

        <!-- password and confirmPasswordForm section -->
        <section class="mt-3" *ngIf="showPasswordForm">
            <h1 class="darKBlueBoldTitle mb-0 text-center">{{ 'FORGOT_PASSWORD.TOP_TITLE' | translate }}</h1>
            <form [formGroup]="passwordForm" (ngSubmit)="onPasswordFormSubmit()" class="mt-4">
                <div class="form-group mb-2 mt-4 pr">
                    <input [type]="show ? 'text' : 'password'" class="form-control" formControlName="password" id="exampleInputEmail1"
                        aria-describedby="passwordHelp" placeholder=""
                        [ngClass]="{'is-invalid': submittedPasswordForm && passwordFormVal.password.errors}" required>
                    <label for="exampleInputEmail1">{{ 'FORGOT_PASSWORD.LABEL3' | translate }}</label>
                    <img src="../../../assets/images/eye.png" class="showPassImg" (click)="showPassword()" alt="eye" />
                    <!-- <div *ngIf="submittedPasswordForm && passwordFormVal.password.errors" class="invalid-feedback">
                        <div *ngIf="passwordFormVal.password.errors.required">password is required</div>
                        <div *ngIf="passwordFormVal.password.errors.minlength">password must be have minimum 5 characters</div>
                    </div> -->
                </div>

                <ul class="customListBlock my-3">
                    <p> {{ 'SALES_FORCE_SIGNUP.LIST_TITLE' | translate }} </p>
                    <li>
                        <p><img src="../../../assets/images/ellipse-525-copy-9.png" class="ellipseImg">{{
                            'FORGOT_PASSWORD.LIST_TEXT1' | translate }}</p>
                    </li>
                    <li>
                        <p><img src="../../../assets/images/ellipse-525-copy-9.png" class="ellipseImg">{{
                            'FORGOT_PASSWORD.LIST_TEXT2' | translate }}</p>
                    </li>
                    <li>
                        <p><img src="../../../assets/images/ellipse-525-copy-9.png" class="ellipseImg">{{
                            'FORGOT_PASSWORD.LIST_TEXT3' | translate }}</p>
                    </li>
                </ul>
                <div class="form-group mb-2 pr">
                    <input [type]="show1 ? 'text' : 'password'" class="form-control" formControlName="confirmPassword"
                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""
                        [ngClass]="{'is-invalid': submittedPasswordForm && passwordFormVal.confirmPassword.errors}"
                        required>
                    <label for="exampleInputEmail1">{{ 'FORGOT_PASSWORD.LABEL4' | translate }}</label>
                    <img src="../../../assets/images/eye.png" class="showPassImg" (click)="showPassword1()" alt="eye" />
                    <div *ngIf="submittedPasswordForm && passwordFormVal.confirmPassword.errors"
                        class="invalid-feedback">
                        <div *ngIf="passwordFormVal.confirmPassword.errors.required">{{ 'FORGOT_PASSWORD.ERROR' | translate }}</div>
                        <div *ngIf="passwordFormVal.confirmPassword.errors.mustMatch">Mật khẩu chưa khớp. Vui lòng nhập lại</div>
                        <!-- <div *ngIf="passwordFormVal.confirmPassword.errors.minlength">password must be have minimum 5 characters</div> -->
                    </div>
                </div>
                <button type="submit" class="btn btn-primary btn-block orangeBtn mt-3" [disabled]="hiddenBtn">{{ 'FORGOT_PASSWORD.OTP_BTN_TEXT'|
                    translate }}</button>
            </form>
        </section>
        <!--  -->
        <!-- successfull password section -->
        <section class="mt-3 d-flex justify-content-center align-self-center h-100 flex-column" *ngIf="showSuccess">
            <img class="img-fluid centeredImg" src="../../../assets/images/check.png" />
        <h2 class="darKBlueBoldTitle mb-0 text-center">{{ 'FORGOT_PASSWORD.SUCCESS_TEXT' | translate }}</h2>
        <button type="button" class="btn btn-primary btn-block orangeBtn mt-5" (click)="changedPasswordSuccess()">{{ 'FORGOT_PASSWORD.BTN_SUCCESS'| translate }}</button>
        </section>

    </section>
</section>
<app-auth-footer></app-auth-footer>
<app-nav-menu></app-nav-menu>