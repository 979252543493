<app-header></app-header>

<section class="ensureGlueProductsContainer">

    <div class="bannerOut">
        <img class="img-fluid" src="../../../assets/images/glucerna-product/glucerna-vanilla-banner.png" alt="glucerna-vanilla-banner" />    
    </div>

    <!-- Glucerna Product - Vanilla Starts Here -->
    <!-- <h2 class="text-center mt20">Glucerna - Vanilla</h2> -->
    <section *ngIf="subCatCode == '3006'" class="ensureBgOut glucernaBgOut">
        <div class="container">
            <div class="row mt20">
                <div class="col-12">
                    <h1 class="darkPinkForeColor">Glucerna hương vani</h1>
                </div>
            </div>
        </div>
        <div class="ensureBg ptb13060 darkPinkBgColor glucernaVanillaImg">
            <div class="topProdOut purpleTopBg">
                <i class="luaMachGlucerna">
                    <img class="img-fluid" src="../../../assets/images/glucerna-product/glucerna-vanilla.png" alt="glucerna-lua-mach" />
                </i>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="whiteTxt mb20 text-center d-block">Thực phẩm dinh dưỡng y học</small>
                        <p class="whiteTxt font-italic SiBrandonGrotesqueBold">
                            Thông tin dưới đây là thông tin trên nhãn được cơ quan có thẩm quyền phê duyệt
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">
                            với hơn 50 nghiên cứu lâm sàng trong suốt 30 năm. <span class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></span> là sản phẩm dinh dưỡng đầy 
                            đủ và cân đối cho người đái tháo đường, tiền đái tháo đường và đái tháo đường thai kỳ**.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="whiteTxt">
                            <span class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></span> được chứng minh lâm sàng giúp kiểm soát 
                            tốt đường huyết, tăng cường sức khỏe tim mạch. Hệ dưỡng chất mới cải tiến với hàm lượng Inositol, 
                            Vitamin D, Sắt, Canxi, Kẽm cao hơn<sup>***.</sup> <span class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></span> 
                            đầy đủ các dưỡng chất, có thể sử dụng thay thế bữa ăn chính hoặc bữa phụ.
                        </p>
                        <small class="whiteTxt">
                            <span class="mandFld">(*)</span> Khảo sát của IQVIA từ tháng 09/2018 đến tháng 8/2019<br>
                            <span class="mandFld">(**)</span> Khuyến nghị phụ nữ mang thai không dùng quá 3 ly pha chuẩn mỗi ngày. <br>
                            <span class="mandFld">(***)</span> So với công thức Glucerna cũ.
                        </small>
                        <div class="text-center mt20 mb20 d-flex justify-content-center">
                            <a 
                                href="https://www.lazada.vn/glucerna/?q=All-Products&from=wangpu&langFlag=vi&pageTypeId=2" 
                                target="_blank" 
                                class="orangeBtn boxShadaowDarkPink plr darkPinkForeColor">Mua ngay</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="extraLightSandalBg">
            <div class="carouselOut mt0">
                <h3 class="text-uppercase darkPinkForeColor text-center SiBrandonGrotesqueBlack pt40 pb20 fs21">hệ dưỡng chất mới</h3>
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="img-fluid"
                                src="../../../assets/images/glucerna-product/28.png"
                                alt="28" />
                            <div class="carousel-caption d-md-block">
                                <ul class="text-left">
                                    <li>
                                        <p>
                                            Dinh dưỡng đầy đủ, cân đối với 28 vitamin và khoáng chất, tăng cường hàm lượng vitamin D (+ 150%), 
                                            sắt (+ 50%), canxi (+ 30%), kẽm (+ 25%)<sup>***</sup>.
                                        </p>
                                        <small class="d-block blackColor">(<sup>***</sup>)So với công thức Glucerna cũ</small>
                                    </li>
                                    <li>
                                        <p>
                                            Vitamin D và canxi giúp xây dựng và duy trì xương chắc khoẻ.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Kẽm và sắt hỗ trợ hệ miễn dịch.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/glucerna-product/heart.png"
                                alt="heart" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Hỗn hợp chất béo đặc chế giàu acid béo không no một nối đôi (MUFA) và acid béo 
                                    không no nhiều nối đôi (PUFA) tốt cho tim mạch.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/glucerna-product/time.png"
                                alt="time" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Hệ bột đường tiên tiến có chỉ số đường huyết thấp và được tiêu hoá từ từ, hàm 
                                    lượng Inositol tăng 4 lần<sup>***</sup>, hỗ trợ kiểm soát tốt đường huyết.
                                </p>
                                <small class="d-block text-center blackColor">(<sup>***</sup>)So với công thức Glucerna cũ</small>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators darkPinkIndicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <a class="carousel-control-prev darkPinkGradient" href="#carouselExampleIndicators" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </a>
                    <a class="carousel-control-next darkPinkGradient" href="#carouselExampleIndicators" role="button"
                        data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="mt60 mb30">Không chứa gluten và rất ít lactose. Phù hợp cho người bất dung nạp lactose.</p>
                    </div>
                </div>
            </div>
            <div class="accordionOut ">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header darkPinkGradient" id="headingOne">
                                        <a href="javascript:;" class="btn btn-link" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Công dụng
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        >
                                        <div class="card-body">
                                            <p>
                                                <strong class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></strong> là sản phẩm dinh dưỡng chuyên biệt với công thức đầy đủ và cân đối giúp kiểm 
                                                soát đường huyết† cho người đái tháo đường, tiền đái tháo đường và đái tháo đường thai kỳ.
                                            </p>
                                            <small class="d-block blackColor">† Như một phần của chương trình quản lý đái tháo đường bao gồm chế độ ăn, tập thể dục và thuốc theo chỉ định.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header darkPinkGradient" id="headingTwo">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            hướng dẫn sử dụng & Bảo quản
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        >
                                        <div class="card-body">
                                            <div class="row mb20">
                                                <div class="col-12">
                                                    <img class="img-fluid" src="../../../assets/images/glucerna-product/to-brew.png" alt="to-brew" />
                                                </div>
                                            </div>
                                            <h5 class="darkPinkForeColor">hướng dẫn sử dụng</h5>
                                            <p>
                                                Để pha 1 ly 237 ml, cho 200 ml nước chín để nguội vào ly. Vừa từ từ cho vào ly 5 muỗng gạt 
                                                ngang (muỗng có sẵn trong hộp) tương đương 52,1 g bột 
                                                <strong class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></strong>, vừa khuấy đều cho đến 
                                                khi bột tan hết.
                                            </p>
                                            <h5 class="darkPinkForeColor">Bảo quản</h5>
                                            <p>
                                                Hộp đã mở phải được đậy kín, giữ ở nơi khô mát nhưng không được để trong tủ lạnh. Khi đã mở hộp, 
                                                sử dụng tối đa trong vòng 3 tuần.<br><br>
                                                <strong class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></strong> đã pha nên dùng ngay hoặc 
                                                đậy kín, giữ lạnh và dùng trong vòng 24 giờ.
                                            </p>
                                            <h5 class="darkPinkForeColor">Nuôi ăn qua ống thông</h5>
                                            <p>
                                                Theo hướng dẫn của bác sĩ/chuyên gia về dinh dưỡng. Khi bắt đầu nuôi ăn qua ống thông, 
                                                phải điều chỉnh lưu lượng, thể tích và độ pha loãng tùy thuộc vào tình trạng và sự dung 
                                                nạp của người bệnh. Lưu ý đề phòng sự nhiễm khuẩn trong quá trình chuẩn bị và nuôi ăn 
                                                qua ống thông.
                                            </p>
                                            <h5 class="darkPinkForeColor">Lưu ý quan trọng</h5>
                                            <p>
                                                Sử dụng cho người bệnh với sự giám sát của nhân viên y tế. Không dùng cho người bệnh 
                                                galactosemia. KHÔNG DÙNG QUA ĐƯỜNG TĨNH MẠCH. Không dùng cho trẻ em dưới 13 tuổi trừ 
                                                khi có chỉ định của thầy thuốc hoặc chuyên viên y tế.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header darkPinkGradient" id="headingThree">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            Thành phần & Phân tích thành phần
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        >
                                        <div class="card-body">
                                            <h5 class="darkPinkForeColor">Thành phần</h5>
                                            <p>
                                                Maltodextrin, DẦU THỰC VẬT (dầu hướng dương giàu oleic, dầu đậu nành), canxi caseinat, 
                                                sucromalt, đạm đậu nành tinh chế, maltitol, đạm sữa cô đặc, KHOÁNG CHẤT (kali clorid, 
                                                magiê sulfat, canxi carbonat, kali citrat, natri phosphat, natri clorid, magiê phosphat, 
                                                natri citrat, kali hydroxid, kẽm sulfat, sắt sulfat, mangan sulfat, đồng sulfat, crôm clorid, 
                                                kali iodid, natri molybdat, natri selenit), fructose, fructo-oligosaccharid (FOS), 
                                                isomaltulose, myo-inositol, chất xơ yến mạch, hương vani tổng hợp, carboxymethyl cellulose, 
                                                cholin clorid, lecithin, gôm xanthan, VITAMIN (ascorbyl palmitat, acid ascorbic, vitamin E, 
                                                niacinamid, canxi d-pantothenat, pyridoxin hydroclorid, thiamin clorid hydroclorid, 
                                                riboflavin, vitamin A palmitat, acid folic, phylloquinon, d-biotin, cholecalciferol, 
                                                cyanocobalamin), taurin, l-carnitin, hỗn hợp tocopherol.
                                            </p>
                                            <span class="mt20 mb20 d-block">
                                                <img class="img-fluid" src="../../../assets/images/glucerna-product/glucerna-vanilla-chart.png" alt="glucerna-vanilla-chart" />
                                            </span>
                                            <p>Pha chuẩn 220g bột hòa tan trong nước để có 1 lít Glucerna.</p>
                                            <p>◊ %RNI cho nam giới từ 19 – 60 tuổi, cho nữ giới và các lứa tuổi khác vui lòng tham khảo tại www.glucerna.com.vn.</p>
                                            <p>Sử dụng trước hạn dùng in ở đáy hộp. Đóng gói trong môi trường khí trơ.</p>
                                            <h5 class="darkPinkForeColor">Ngày sản xuất (MFG) và hạn sử dụng (EXP) xem ở đáy hộp.</h5>
                                            <p>
                                                <strong>Sản xuất tại:</strong> ABBOTT LABORATORIES SA. Camino de Purchil, 
                                                68 18004 Granada, Tây Ban Nha. A subsidiary of Abbott Laboratories, 
                                                North Chicago, IL 60064, USA.<br><br>

                                                <strong>Đăng ký bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A, 
                                                toà nhà Handi Resco,521 Kim Mã, Ba Đình, Hà Nội.<br><br>

                                                <strong>Nhập khẩu & phân phối:</strong><br>
                                                Công ty TNHH Dinh dưỡng 3A (Việt Nam)<br>
                                                Centec Tower, 72-74 Nguyễn Thị Minh Khai,<br>
                                                phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Glucerna Product - Vanilla Ends Here -->

    <!-- Glucerna Product - Lua Mach Starts Here -->
    <!-- <h2 class="text-center">Glucerna - Lua Mach</h2> -->
    <section *ngIf="subCatCode == '3007'" class="ensureBgOut glucernaBgOut">
        <div class="container">
            <div class="row mt40">
                <div class="col-12">
                    <h1 class="darkPinkForeColor">Glucerna hương lúa mạch</h1>
                </div>
            </div>
        </div>
        <div class="ensureBg darkPinkBgColor glucernaLuaMachImg">
            <div class="topProdOut purpleTopBg">
                <i class="luaMachGlucerna">
                    <img class="img-fluid" src="../../../assets/images/glucerna-product/glucerna-lua-mach.png" alt="glucerna-lua-mach" />
                </i>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="whiteTxt mb20 text-center d-block">Thực phẩm dinh dưỡng y học</small>
                        <p class="whiteTxt font-italic SiBrandonGrotesqueBold">
                            Thông tin dưới đây là thông tin trên nhãn được cơ quan có thẩm quyền phê duyệt
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">
                            với hơn 50 nghiên cứu lâm sàng trong suốt 30 năm. Glucerna® là sản phẩm dinh dưỡng đầy đủ và cân đối cho 
                            người đái tháo đường, tiền đái tháo đường và đái tháo đường thai kỳ**.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="whiteTxt">
                            <span class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></span> được chứng minh lâm sàng giúp kiểm soát 
                            tốt đường huyết, tăng cường sức khỏe tim mạch. Hệ dưỡng chất mới cải tiến với hàm lượng Inositol, 
                            Vitamin D, Sắt, Canxi, Kẽm cao hơn<sup>***</sup>. <span class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></span> 
                            đầy đủ các dưỡng chất, có thể sử dụng thay thế bữa ăn chính hoặc bữa phụ.
                        </p>
                        <small class="whiteTxt">
                            (<sup>*</sup>) Khảo sát của IQVIA từ tháng 09/2018 đến tháng 8/2019<br>
                            (<sup>**</sup>)Khuyến nghị phụ nữ mang thai không dùng quá 3 ly pha chuẩn mỗi ngày.<br>
                            (<sup>***</sup>)So với công thức Glucerna cũ.
                        </small>
                        <div class="text-center mt20 mb20 d-flex justify-content-center">
                            <a 
                                href="https://www.lazada.vn/glucerna/?q=All-Products&from=wangpu&langFlag=vi&pageTypeId=2" 
                                target="_blank" 
                                class="orangeBtn boxShadaowDarkPink plr darkPinkForeColor">Mua ngay</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="extraLightSandalBg">
            <div class="carouselOut mt0">
                <h3 class="text-uppercase darkPinkForeColor text-center SiBrandonGrotesqueBlack pt40 pb20 fs21">hệ dưỡng chất mới</h3>
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="img-fluid"
                                src="../../../assets/images/glucerna-product/28.png"
                                alt="28" />
                            <div class="carousel-caption d-md-block">
                                <ul class="text-left">
                                    <li>
                                        <p>
                                            Dinh dưỡng đầy đủ, cân đối với 28 vitamin và khoáng chất, tăng cường hàm lượng vitamin D (+ 150%), 
                                            sắt (+ 50%), canxi (+ 30%), kẽm (+ 25%)<sup>***</sup>.
                                        </p>
                                        <small class="d-block blackColor">(<sup>***</sup>)So với công thức Glucerna cũ</small>
                                    </li>
                                    <li>
                                        <p>
                                            Vitamin D và canxi giúp xây dựng và duy trì xương chắc khoẻ.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Kẽm và sắt hỗ trợ hệ miễn dịch.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/glucerna-product/heart.png"
                                alt="heart" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Hỗn hợp chất béo đặc chế giàu acid béo không no một nối đôi (MUFA) và acid béo 
                                    không no nhiều nối đôi (PUFA) tốt cho tim mạch.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/glucerna-product/time.png"
                                alt="time" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Hệ bột đường tiên tiến có chỉ số đường huyết thấp và được tiêu hoá từ từ, hàm 
                                    lượng Inositol tăng 4 lần<sup>***</sup>, hỗ trợ kiểm soát tốt đường huyết.
                                </p>
                                <small class="d-block text-center blackColor">(<sup>***</sup>)So với công thức Glucerna cũ</small>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators darkPinkIndicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <a class="carousel-control-prev darkPinkGradient" href="#carouselExampleIndicators" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </a>
                    <a class="carousel-control-next darkPinkGradient" href="#carouselExampleIndicators" role="button"
                        data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="mt60 mb30">Không chứa gluten và rất ít lactose. Phù hợp cho người bất dung nạp lactose.</p>
                    </div>
                </div>
            </div>
            <div class="accordionOut">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header darkPinkGradient" id="headingOne">
                                        <a href="javascript:;" class="btn btn-link" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Công dụng
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        >
                                        <div class="card-body">
                                            <p>
                                                <strong class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></strong> là sản phẩm dinh dưỡng 
                                                chuyên biệt với công thức đầy đủ và cân đối giúp kiểm soát đường huyết† cho người đái tháo 
                                                đường, tiền đái tháo đường và đái tháo đường thai kỳ.
                                            </p>
                                            <small class="d-block blackColor">
                                                † Như một phần của chương trình quản lý đái tháo đường bao gồm chế độ ăn, tập thể dục và 
                                                thuốc theo chỉ định.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header darkPinkGradient" id="headingTwo">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            hướng dẫn sử dụng & Bảo quản
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        >
                                        <div class="card-body">
                                            <div class="row mb20">
                                                <div class="col-12">
                                                    <img class="img-fluid" src="../../../assets/images/glucerna-product/to-brew.png" alt="to-brew" />
                                                </div>
                                            </div>
                                            <h5 class="darkPinkForeColor">hướng dẫn sử dụng</h5>
                                            <p>
                                                Để pha 1 ly 237 ml, cho 200 ml nước chín để nguội vào ly. Vừa từ từ cho vào ly 5 muỗng gạt 
                                                ngang (muỗng có sẵn trong hộp) tương đương 52,1 g bột 
                                                <strong class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></strong>, vừa khuấy đều cho đến 
                                                khi bột tan hết.
                                            </p>
                                            <h5 class="darkPinkForeColor">Bảo quản</h5>
                                            <p>
                                                Hộp đã mở phải được đậy kín, giữ ở nơi khô mát nhưng không được để trong tủ lạnh. Khi đã mở hộp, 
                                                sử dụng tối đa trong vòng 3 tuần.<br><br>
                                                <strong class="SiBrandonGrotesqueBold">Glucerna<sup>®</sup></strong> đã pha nên dùng ngay hoặc 
                                                đậy kín, giữ lạnh và dùng trong vòng 24 giờ.
                                            </p>
                                            <h5 class="darkPinkForeColor">Nuôi ăn qua ống thông</h5>
                                            <p>
                                                Theo hướng dẫn của bác sĩ/chuyên gia về dinh dưỡng. Khi bắt đầu nuôi ăn qua ống thông, 
                                                phải điều chỉnh lưu lượng, thể tích và độ pha loãng tùy thuộc vào tình trạng và sự dung 
                                                nạp của người bệnh. Lưu ý đề phòng sự nhiễm khuẩn trong quá trình chuẩn bị và nuôi ăn 
                                                qua ống thông.
                                            </p>
                                            <h5 class="darkPinkForeColor">Lưu ý quan trọng</h5>
                                            <p>
                                                Sử dụng cho người bệnh với sự giám sát của nhân viên y tế. Không dùng cho người bệnh 
                                                galactosemia. KHÔNG DÙNG QUA ĐƯỜNG TĨNH MẠCH. Không dùng cho trẻ em dưới 13 tuổi trừ 
                                                khi có chỉ định của thầy thuốc hoặc chuyên viên y tế.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header darkPinkGradient" id="headingThree">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            Thành phần & Phân tích thành phần
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        >
                                        <div class="card-body">
                                            <h5 class="darkPinkForeColor">Thành phần</h5>
                                            <p>
                                                Maltodextrin, DẦU THỰC VẬT (dầu hướng dương giàu oleic, dầu đậu nành), canxi caseinat, 
                                                sucromalt, đạm đậu nành tinh chế, maltitol, đạm sữa cô đặc, KHOÁNG CHẤT (kali clorid, 
                                                magiê sulfat, canxi carbonat, kali citrat, natri phosphat, natri clorid, magiê phosphat, 
                                                natri citrat, kali hydroxid, kẽm sulfat, sắt sulfat, mangan sulfat, đồng sulfat, crôm clorid, 
                                                kali iodid, natri molybdat, natri selenit), fructose, fructo-oligosaccharid (FOS), 
                                                isomaltulose, myo-inositol, chất xơ yến mạch, hương vani tổng hợp, carboxymethyl cellulose, 
                                                cholin clorid, lecithin, gôm xanthan, VITAMIN (ascorbyl palmitat, acid ascorbic, vitamin E, 
                                                niacinamid, canxi d-pantothenat, pyridoxin hydroclorid, thiamin clorid hydroclorid, 
                                                riboflavin, vitamin A palmitat, acid folic, phylloquinon, d-biotin, cholecalciferol, 
                                                cyanocobalamin), taurin, l-carnitin, hỗn hợp tocopherol.
                                            </p>
                                            <span class="mt20 mb20 d-block">
                                                <img class="img-fluid" src="../../../assets/images/glucerna-product/glucerna-vanilla-chart.png" alt="glucerna-vanilla-chart" />
                                            </span>
                                            <p>
                                                Pha chuẩn 220g bột hòa tan trong nước để có 1 lít Glucerna.<br>
                                                ◊ %RNI cho nam giới từ 19 – 60 tuổi, cho nữ giới và các lứa tuổi khác vui lòng tham khảo 
                                                tại www.glucerna.com.vn.<br>
                                                Sử dụng trước hạn dùng in ở đáy hộp. Đóng gói trong môi trường khí trơ.
                                            </p>
                                            <h5 class="darkPinkForeColor">Ngày sản xuất (MFG) và hạn sử dụng (EXP) xem ở đáy hộp.</h5>
                                            <p>
                                                <strong>Sản xuất tại:</strong> ABBOTT LABORATORIES SA. Camino de Purchil, 
                                                68 18004 Granada, Tây Ban Nha. A subsidiary of Abbott Laboratories, 
                                                North Chicago, IL 60064, USA.<br><br>

                                                <strong>Đăng ký bởi:</strong> VPĐD Abbott Laboratories GmbH, Tầng 7-8, tháp A, 
                                                toà nhà Handi Resco,521 Kim Mã, Ba Đình, Hà Nội.<br><br>

                                                <strong>Nhập khẩu & phân phối:</strong><br>
                                                Công ty TNHH Dinh dưỡng 3A (Việt Nam)<br>
                                                Centec Tower, 72-74 Nguyễn Thị Minh Khai,<br>
                                                phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Glucerna Product - Lua Mach Ends Here -->

</section>

<div class="manufacturerShareAndCareContainer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="mb-0 careShareText text-uppercase">care & share</h3>
                <h3 class="homeText text-uppercase">home</h3>
                <p>Tham gia để nhận được <br><strong>các lợi ích hấp dẫn</strong></p>
                <button class="orangeBtn" routerLink="/sign-up/1">Đăng ký ngay</button>
                <p>Liên hệ hotline <a class="telNum" href="tel:19001519">19001519 </a><br>để biết thêm chi tiết <br><small>GLU-C-171-21</small>
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
<app-nav-menu></app-nav-menu>