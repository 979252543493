import { Component, OnInit, TemplateRef,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService,ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { RouterService } from 'src/app/services/router.service';


@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  scancodeData = null;
  prodCategory:string;
  content = [];
  subCatCode:any;
  milkCode:any;
  previousUrl: Observable<string> =  this.routerService.previousUrl$;
  showGrowTab = false; 
  showSimilacTab = false; 
  activeTabSimilac:any;
  activeTabGrow:any;
  defaultActiveTab:boolean = true;

  @ViewChild('milkCodeModal', { static: true }) milkCodeModal: ModalDirective;
  config = {
    ignoreBackdropClick: true
  };
  iSprintData: any;
  constructor(private modalService: BsModalService, private route: ActivatedRoute, private router: Router, private routerService: RouterService) {
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;
    this.activeTabSimilac =  sessionStorage.getItem('activeSimilac');
    this.activeTabGrow =  sessionStorage.getItem('activeGrow');
    console.log(this.activeTabSimilac, this.activeTabGrow , 'activeTabGrow & activeTabSimilac')
    if(this.activeTabSimilac || this.activeTabGrow){
      this.defaultActiveTab = false;
    }
   }

  ngOnInit(): void {
    this.getProductDescCode();
    // this.showGrowTab = true;


    this.routerService.previousUrl$.subscribe((previousUrl: string) => {
      console.log('previousurl', previousUrl);
        // this.showGrowTab = true;
        // this.showSimilacTab;
        // this.showGrowTab;
        // this.showSimilacTab = true;
    });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  milkCodeInner:any;
  subCatCodeInner:any;
  prodName:any;
  openMilkCodeModal(milkCode, subCatCode, prodName) {
    this.milkCodeInner = milkCode;
    this.subCatCodeInner = subCatCode;
    this.prodName = prodName
    console.log(this.milkCodeInner, this.subCatCodeInner,this.prodName, 'mil and subcat');
    if(milkCode == '1'){
      this.milkCodeModal.show()
    }else if(milkCode == '0'){
      let data = {
        milkCode: this.milkCodeInner,
        subCatCode: this.subCatCodeInner,
        prodName: this.prodName
      }
      if(this.prodName == 'grow'){
        this.router.navigate(['/product-description', data]);
      }else if(this.prodName == 'similac'){
        this.router.navigate(['/similac-mom-product', data]);
      }
    }
  }
  milkCodeHide() {
    this.milkCodeModal.hide()
  }
  gotoProdDesc(){
    let data = {
      milkCode: this.milkCodeInner,
      subCatCode: this.subCatCodeInner,
      prodName: this.prodName
    }
    if(this.prodName == 'grow'){
      this.router.navigate(['/product-description', data]);
    }else if(this.prodName == 'similac'){
      this.router.navigate(['/similac-mom-product', data]);
    }
  }
//   openModal(template: TemplateRef<any>) {
//     //if(this.milkCode == 1){
//       this.modalRef = this.modalService.show(template);
//     //}
//   }
  
//   gotoProdDesc(){
//     if(this.milkCode == 1 && this.prodCategory == 'Grow'){
//      this.router.navigate(['/product-description', {subCatCode: this.subCatCode, milkCode: this.milkCode}]);
//      this.modalRef.hide();
//     }
//     else if(this.milkCode == 1 && this.prodCategory == 'Similac'){
//      this.router.navigate(['/similac-mom-product', {subCatCode: this.subCatCode, milkCode: this.milkCode}]);
//     //  this.router.navigate(['/otp', {test: '123'}]);
//      this.modalRef.hide();
//     }
//  }

  getProductDescCode() {
    this.subCatCode = this.iSprintData?.sub_category_code;
    this.milkCode = this.iSprintData?.is_milk_code;
    // this.content = this.scancodeData?.product?.txt;
    // if(this.content){
    //   this.content.forEach((item: any) => {
    
    //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.subCatCode = parsedPrimaryValue.value;
    //     }
       
    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;
    //     }
    //   });
    // }
  }
  ngOnDestroy(){
    sessionStorage.removeItem('activeSimilac');
    sessionStorage.removeItem('activeGrow');

  }
}
