import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moiIcon'
})
export class MoiIconPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(new RegExp('mới'), `<span class="moi-icon"></span>`);
  }

}
