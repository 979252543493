import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.scss']
})
export class AuthFooterComponent implements OnInit {
  scancodeData:any;
  prodCategory:any;
  iSprintData: any;

  constructor() { }

  ngOnInit(): void {
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;
  }

}
