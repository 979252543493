import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {v4 as uuidv4} from 'uuid';
import { BugsnagService } from '../services/bugsnag.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  scanScode = null;
  typeCheck:number;
  loader:boolean = true;
  errMsg:any;
  redirect:any;
  productName:any;
  iSprintData: any;
  categoryCode: any;
  constructor(private route: ActivatedRoute, private router: Router,private traceabilityService: ApiService,private toastr: ToastrService, private spinner: NgxSpinnerService, private bsService: BugsnagService) {
    if (this.route.snapshot.queryParams.c) {
      this.scanScode = this.route.snapshot.queryParams.c;
      console.log(this.scanScode, 'this.scanScode 12');
    } else if (this.route.snapshot.queryParams.u) {
      this.scanScode = this.route.snapshot.queryParams.u;
      console.log(this.scanScode, 'this.scanScode 12');
    }
    if(this.scanScode === undefined || this.scanScode === null || this.scanScode === '') {
      this.loader = false;
      this.router.navigate(['profile-home']);  
    }
    else{
      sessionStorage.setItem('scanCode',this.scanScode);
    }
   }

  ngOnInit(): void {
    // if(this.loader){
    //   this.getTraceabilityContent();
    // }
    this.getProductInformation()
     

  }
  parseFunction(value) {
    return JSON.parse(value);
  }

  // getTraceabilityContent() {
  //   let myuuid = uuidv4();
  //   let loggerFormData = {
  //     "unique_id": myuuid,
  //     "message": JSON.stringify(this.scanScode)
  //   }
  //   let scancoderesp;
  //   this.traceabilityService.getTraceability(this.scanScode).subscribe((res: any) => {
  //     this.loader = false;
  //     // if(res.result.data.product.categoryCode == "Ensure" || res.result.data.product.categoryCode == "Glucerna"){
  //     //   this.router.navigate(['/holding-page'])
  //     //   return;
  //     // }
  //     scancoderesp = res;
  //     let myuuid = uuidv4();
  //     let loggerFormData = {
  //       "unique_id": myuuid,
  //       "message": JSON.stringify(res)
  //     }
  //     // if(res.result.message == "Please scan a valid QR code."){
  //     //   this.loader = false;
  //     //   alert(res.result.message)
  //     // }
  //     if(res.result.code == '0'){
  //       let product = this.parseFunction(res.result.data.product.productName);
  //       this.productName = product.en_US;
  //       sessionStorage.setItem('productName',this.productName);
  //       sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
  //       console.log('productdata',JSON.parse(sessionStorage.getItem('productdata')))
  //       // this.getProductInfo(res.result.data);
  //       if(res.result.data.uaidInfo.type == 1 || res.result.data.uaidInfo.type == 7){
  //         console.log('test 1----')
  //         this.router.navigate(['/home']);
  //         // if(res.result.data.product.categoryCode == "Grow"){
  //         //  this.router.navigate(['/home-grow']);
  //         //  // window.location.href = env.GrowSubDomain+`?u=${this.scanScode}`
  //         // }else if(res.result.data.product.categoryCode == "Similac"){
  //         //   this.router.navigate(['/home-similac']);
  //         //   // window.location.href = env.SimilacSubDomain+`?u=${this.scanScode}`
  //         // }
  //       }else if(res.result.data.uaidInfo.type == 2){
  //         console.log('test 2----')
  //         this.router.navigate(['/sign-up']);
  //         //window.location.href = `https://vn.abbottcareshare.sttarter.com/?u=${this.scanScode}`
  //       }
  //     }
  //     else{
  //       this.loader = false;
  //       // this.errMsg = 'Unable to fetch product information'
  //       // if(res.result.message)
  //       // this.errMsg = res.result.message
  //       // alert(this.errMsg)
  //       this.traceabilityService.showErrorMsg('Please scan a valid QR code.')
  //       setTimeout(() => {
  //         this.router.navigate(['/home']);
  //       }, 5000);
  //     }
  //   },err=>{
  //         this.loader = false;
  //        this.bsService.notifyError(err, this.scanScode);
  //         this.errMsg = 'Unable to fetch product information'
  //         this.traceabilityService.showErrorMsg('Unable to fetch product information')
  //         // if(err)
  //         // this.traceabilityService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
  //         console.log(err, 'err========')
  //         this.errMsg = err;
  //         //alert(this.errMsg)
  //   })
  // }

  //Todo:
  // Call product info api, 
  // type:1 --> product info traceability page, type 2--> signup page
  getProductInformation(){

    this.traceabilityService.productInfo(this.scanScode).subscribe((res)=>{
      console.log(res)
      this.loader = true;
      sessionStorage.setItem('iSprintData', JSON.stringify(res["result"]));
      this.iSprintData = sessionStorage.getItem("iSprintData");
      if(this.iSprintData?.is_isprint){
        console.log(this.iSprintData?.product_name.vi_VN)
        sessionStorage.setItem('productName',this.iSprintData?.product_name.vi_VN);

      }else{
        sessionStorage.setItem('productName',this.iSprintData?.product_name);

      }
      sessionStorage.setItem('productdata', JSON.stringify(this.iSprintData));
      this.iSprintData =  res["result"];
      this.categoryCode = this.iSprintData.category_code
      console.log("type is",this.iSprintData.type)

      if(this.iSprintData.type == 1){
       
          this.loader = false;
          this.router.navigate(['/home'])
      }
      else if(this.iSprintData.type == 2){
        this.loader = false;
        this.router.navigate(['sign-up'])
      }
      

    },(err)=>{
      console.log(err);
      this.loader = false;
      this.traceabilityService.showErrorMsg("Please scan a valid QR code.")
      setTimeout(() => {
        this.router.navigate(['/profile-home']);
      }, 4000);
    })

  }

//   getProductInfo(itemScanned) {
//     let milkCode: any;
//     let resultData = itemScanned
//     let content = resultData.product.txt;
//     content.forEach((item: any) => {
      

//       if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
//         let parsedPrimaryValue = this.parseFunction(item.value);
//         milkCode = parsedPrimaryValue.value;
//         console.log(milkCode, 'this.milkCode');
//       }
  
//       if (item.orders == 12) {
//         let productitemcode = this.parseFunction(item.value);
//         let scannedProductValue = productitemcode.value;
//         console.log(scannedProductValue, 'scannedProductValue---')
//         if(milkCode == 0 && (scannedProductValue == '2010' || scannedProductValue == '2011')){
//           console.log('scanned product is SMOM non-milk')
//           this.traceabilityService.checkSmomNonMilk(true)
//         }
//         else{
//           this.traceabilityService.checkSmomNonMilk(false)
//         }
//      }
//     });

// }
































}
