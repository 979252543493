import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment as env } from "../../environments/environment";
import { map, delay, tap, take, first } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy{
  private timer: Subscription;
  private _user = new BehaviorSubject(null);
  user$ = this._user.asObservable();

  private storageEventListener(event: StorageEvent) { 
    if (event.storageArea === sessionStorage) {
      if (event.key === 'logout-event') {
        this.stopTokenTimer();
        sessionStorage.removeItem('authToken');
        // this._user.next(null);
      }
      if (event.key === 'login-event') {
        this.stopTokenTimer();
        console.log('login-event')
        // let getAuthKey = sessionStorage.getItem('authKey');
        // sessionStorage.setItem('authToken', getAuthKey)
        
        // location.reload();
      }
    }
   }

  constructor(private router: Router, private http: HttpClient, 
    private apiService: ApiService, private spinner: NgxSpinnerService) {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  // login(formData) { 
  //   let headers = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   return this.http.post(env.AUTH_BASE_URL+"/auth/v1/token/generate",formData,{headers:headers})
  //   .pipe(
  //     map((x) => {
  //       this._user.next({x});
  //       this.setLocalStorage(x);
  //       sessionStorage.setItem('login-event', 'login' + Math.random());
  //       this.startTokenTimer();
  //       return x;
  //     })
  //   );
  //  }




 
  logout() {
    // remove user from local storage and set current user to null
    sessionStorage.clear();
    this.clearLocalStorage();
    this.router.navigate(['/sign-up']);
}

  refreshToken() { 
    this.spinner.show();
    let mobileNumber = sessionStorage.getItem('mobileNo')
    let getAuthKey = sessionStorage.getItem('authKey');
    let tokenReGenerate = {
      "mobile": mobileNumber,
      "deviceId": env.DEVICE_ID,
      "brand" : env.BRAND,
      "key": getAuthKey
    }
    this.apiService.tokenReGenerate(tokenReGenerate)
    .subscribe(res=>{     
      if(res['status'].code == 200){
        console.log('refresh token called--')
        this.spinner.hide();
        sessionStorage.setItem('authToken', res['auth'].token)
        this.setLocalStorage();
        this.startTokenTimer();
        // window.location.reload()
      }
      else{
        this.spinner.hide();
        this.logout();
        this.apiService.showToaster(res['status'].message)
      }
    },err=>{
      this.spinner.hide();
      this.logout();
      console.log(err, 'error data')
   })
  }

  setLocalStorage() {
    sessionStorage.setItem('login-event', 'login' + Math.random());
  }

  clearLocalStorage() {
    sessionStorage.setItem('logout-event', 'logout' + Math.random());
  }

  getTokenRemainingTime() {
  const accessToken = sessionStorage.getItem('authToken');
  if (!accessToken) {
    return 0;
  }
  const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
  const expires = new Date(jwtToken.exp * 1000);
  return expires.getTime() - Date.now();
}

startTokenTimer() {
  console.log('inside start token timer')
  const timeout = this.getTokenRemainingTime();
  console.log(timeout, 'timeout result (remaining time)')
  this.timer = of(true)
    .pipe(
      delay(timeout),
      tap(() => this.refreshToken())
    )
    .subscribe();
}

 stopTokenTimer() {
   console.log('stop timer--')
  this.timer?.unsubscribe();
}

}
