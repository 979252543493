import { Injectable } from '@angular/core';
import { environment as env } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Md5 } from 'ts-md5/dist/md5';
@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) {

  }

  behavsubject = new BehaviorSubject(<boolean>false);
  childName = new BehaviorSubject(null);
  SMOMnonmilk = new BehaviorSubject(<boolean>false);
  yearList = [];
  yearMotherDOBList = [];

  trackChildMilk() {
    return ({
      child_1_milkcode: "member_type",
      child_2_milkcode: "test_date_type",
      child_3_milkcode: "age_range_one",
      child_4_milkcode: "ssnNumber",
    })
  }

  getTraceability(scanCode: any) {
    // nfQzC4mkNF1e
    let headers = new HttpHeaders({
      'country-code': 'VN',
      'language': 'en_US',
    })
    return this.http.get(`${env.API_URl}/v1/traceability/scancode?scan_code=${scanCode}`, { headers: headers });
  }

  tokenGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(env.AUTH_BASE_URL + "/auth/v1/token/generate", formData, { headers: headers });
  }

  passwordValidate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL + "/auth/v1/password/validate", formData, { headers: headers });
  }

  otpGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL + "/auth/v1/otp/generate", formData, { headers: headers });
  }

  otpValidate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL + "/auth/v1/otp/validate", formData, { headers: headers });
  }

  getSalesForceData(mobile: string) {
    let headers = new HttpHeaders({
      'country-code': 'VN',
      'language': 'en_us'
    });
    return this.http.get(env.SALESFORCE_BASE_URL + `/salesforce/contact?mobile=${mobile}`, { headers: headers });
  }

  forgotPassword(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL + "/auth/v1/password/forget", formData, { headers: headers });
  }

  getCapCustomer(mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + "/customer/get", { headers: headers });
    // return this.http.get(env.API_BASE_URL+"/customer/get?user_id=true",{headers:headers});
  }


  changePassword(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL + "/auth/v1/password/change", formData, { headers: headers });
  }

  addCapCustomer(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(env.API_BASE_URL + "/customer/add", formData, { headers: headers });
  }

  updateCapCustomer(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(env.API_BASE_URL + "/customer/update", formData, { headers: headers });
  }

  setSalesForceUser(value) {
    this.behavsubject.next(value);
  }

  setChild(name) {
    this.childName.next(name);
  }


  //  Get Current location
  getPosition(): Promise<any> {
    console.log('test--')

    return new Promise((resolve, reject) => {
      if (navigator.geolocation)
        navigator.geolocation.getCurrentPosition(resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude,accuracy: Math.floor(resp.coords.accuracy) });
        },
          err => {
            // if(err.message == "User denied Geolocation"){
            //  let errMessage = "User denied Geolocation. Please allow geolocation to proceed"
            //   this.spinner.hide();
            //   this.showToasterGeo(errMessage);
            // }
            // console.log(err, 'geo error')
            //reject(err);
            resolve({ lng: 'undefined', lat: 'undefined' });
          });
    });

  }
  addTransaction(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      'authorization': getAuthKey,
      'country-code': 'VN',
      'language': 'en_US',
    })
    return this.http.post(env.SALESFORCE_BASE_URL + "/earnpoints/scancode", formData, { headers: headers });
  }

  productInfo(scanCode){
    let headers = new HttpHeaders({
      'country-code': 'VN',
      'language': 'en_US',
    })
    return this.http.get(env.API_URl + `/v1/vn/productinfo?scan_code=${scanCode}`, { headers: headers });
  
  }
  postGpassEarnPoints(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      authorization: getAuthKey,
      'country-code': 'VN',
      language: 'en_US',
    });
    return this.http.post(env.SALESFORCE_BASE_URL + '/vn/scancode', formData, {
      headers: headers,
    });
  }
  getGpasProductImage(sku) {
    return this.http.get(
      env.STTARTER_GAMEPLAY_REWARD + `/gpassproductimages?sku=${sku}`
    );
  }

  updateTrans(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      'authorization': getAuthKey,
      'country-code': 'VN',
      'language': 'en_US',
    })
    return this.http.put(env.SALESFORCE_BASE_URL + "/transaction/update", formData, { headers: headers });
  }

  gameAllocate(formData: any, mobileNo: string, getAuthKey: string) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(env.API_BASE_URL + "/game/allocate", formData, { headers: headers });
  }

  gamePlay(mobileNo: string, getAuthKey: string, id: any) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + `/games/play?mobile=${mobileNo}&id=${id}`, { headers: headers });
  }

  sttarterGetGameplayReward(id: any) {
    return this.http.get(env.STTARTER_GAMEPLAY_REWARD + `/giftallocation?productcode=${id}`);
    //return this.http.get(env.STTARTER_GAMEPLAY_REWARD+`/giftallocation`);
  }
  sttarterFirsttimeScanGameId() {
    return this.http.get(env.STTARTER_GAMEPLAY_FIRSTTIME_SCAN);
  }
  sttarterLuckyDrawGameId() {
    return this.http.get(env.STTARTER_GAMEPLAY_LUCKY_DRAW);
  }
  similacTermsandConditions() {
    return this.http.get(env.STTARTER_GAMEPLAY_REWARD + `/contentblocks`);
  }

  showToaster(msg: any) {
    this.toastr.info(msg);
  }

  showErrorMsg(msg: any) {
    this.toastr.error(msg, 'Error', {
      timeOut: 5000
    });
  }

  checkExistingEmail(email: any) {
    let headers = new HttpHeaders({
      'country-code': 'VN',
      'language': 'en_US',
    });
    return this.http.get(env.EMAIL_CHECK_API + `/email/validate?email=${email}`, { headers: headers });
  }


  voucherList() {
    let headers = new HttpHeaders({
      'country-code': 'VN',
      'language': 'en_US',
    });
    return this.http.get(env.EMAIL_CHECK_API + `/voucher/summary`, { headers: headers });
  }

  getTransaction(mobileNo, getAuthKey, start_date, end_date) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + `/customer/transactions/get?start_date=${start_date}&end_date=${end_date}&limit=100`, { headers: headers });
  }

  getTransactionfull(mobileNo, getAuthKey) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + `/customer/transactions/get?limit=100`, { headers: headers });
  }

  logout() {
    // remove user from local storage and set current user to null
    sessionStorage.clear();
    this.router.navigate(['/sign-up']);
  }

  customerImageUpload(formData: any, getAuthKey: string) {
    let headers = new HttpHeaders({
      'authorization': getAuthKey,
      // 'country-code':'PH',
      'country-code': 'VN',
      'language': 'en_US',
    })
    return this.http.post(env.SALESFORCE_BASE_URL + "/image/upload", formData, { headers: headers });
  }
  getSavedImage(mobileNo: string, getAuthKey: string, uniqueId: string, format: any) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + `/image?filename=${uniqueId}${format}`, { headers: headers });
  }

  getSavedImageFinal(mobileNo: string, getAuthKey: string, uniqueId: string) {
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.get(env.API_BASE_URL + `/image?filename=${uniqueId}`, { headers: headers });
  }

  updateCapCustomerIdentity(formData: any, mobileNo: string, getAuthKey: string) {
    console.log(mobileNo, 'mobileNo===')
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(env.API_BASE_URL + "/customer/update_identity", formData, { headers: headers });
  }


  findRange() {
    let curr_date = new Date();
    let yearValues: any = [];

    let start = moment(curr_date).format('YYYY-MM-DD');
    let end = moment(curr_date).subtract(10, 'years').format('YYYY-MM-DD')

    console.log(start, 'start date in 1')
    console.log(end, 'end date in 1')

    let startDate = moment(start);
    let endDate = moment(end)

    let dateList = this.getDaysBetweenDates(endDate, startDate);
    console.log(dateList, 'in 1');

    dateList.filter(dates => {
      let dateSplit = dates.split("-");
      yearValues.push(dateSplit[2]);
    })

    return this.yearList = yearValues

  }
  yearList1: any;
  findRange1() {
    let curr_date = new Date();
    let yearValues: any = [];

    let start = moment(curr_date).subtract(25, 'years').format('YYYY-MM-DD');
    let end = moment(curr_date).subtract(92, 'years').format('YYYY-MM-DD')

    console.log(start, 'start date in 1')
    console.log(end, 'end date in 1')

    let startDate = moment(start);
    let endDate = moment(end)

    let dateList = this.getDaysBetweenDates(endDate, startDate);
    console.log(dateList, 'in 1');

    dateList.filter(dates => {
      let dateSplit = dates.split("-");
      yearValues.push(dateSplit[2]);
    })

    return this.yearList1 = yearValues

  }

  getDaysBetweenDates(startDate, endDate) {
    let now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('DD-MM-YYYY'));
      now.add(1, 'years');
    }
    return dates;

  };


  checkSmomNonMilk(value) {
    this.SMOMnonmilk.next(value)
  }

  tokenReGenerate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(env.AUTH_BASE_URL + "/auth/v1/token/regenerate", formData, { headers: headers });
  }

  findMotherDOBRange() { // add 10 months
    let curr_date = new Date();
    let yearValues: any = [];

    let start = moment(curr_date).format('YYYY-MM-DD');
    let endDOB = moment(curr_date).add(10, 'months').format('YYYY-MM-DD')

    console.log(start, 'start date in MDOB')
    console.log(endDOB, 'endDOB--')

    if (moment(start).format('YYYY') != moment(endDOB).format('YYYY')) {
      this.yearMotherDOBList.push(moment(start).format('YYYY'))
      this.yearMotherDOBList.push(moment(endDOB).format('YYYY'))
    }
    else {
      this.yearMotherDOBList.push(moment(start).format('YYYY'))
    }
    return this.yearMotherDOBList;

  }


  setRememberUser(value) {
    this.behavsubject.next(value);
  }

  getCoupons(coupon_code, mobileNo, getAuthKey) {
    let headers = new HttpHeaders({
      Authorization:
        'Basic ' + btoa(`${env.TILL_ID}:${Md5.hashStr('abbott123')}`),
    });
    // &format=json
    return this.http.get(env.GAME_MAP_API + `/coupon/get?code=${coupon_code}&format=json`, { headers: headers });
  }

  getCouponsSeriesCode(id, mobileNo, getAuthKey) {
    let headers = new HttpHeaders({
      Authorization:
        'Basic ' + btoa(`${env.TILL_ID}:${Md5.hashStr('abbott123')}`),
    });
    return this.http.get(env.GAME_MAP_API + `/coupon/series?format=json&id=${id}`, { headers: headers });
  }
  addUser(formData: any) {
    let headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(`abbottvntemppage:${Md5.hashStr('abbott123')}`)
    });

    //  let headers = new HttpHeaders({
    //   'country_code': 'PH',
    //   'language':'en_us'
    // });
    return this.http.post(env.HOLDING_URL + "/customer/add", formData, { headers: headers });
  }


  newGameAllocation(formData:any, mobileNo: string, getAuthKey: string){
    // let headers = new HttpHeaders({
    //   'username': env.TILL_ID,
    //   'password': env.TILL_PASS,
    // });
    let headers = new HttpHeaders({
      'cap_authorization': getAuthKey,
      'cap_brand': env.BRAND,
      'cap_device_id': env.DEVICE_ID,
      'cap_mobile': mobileNo,
      'Content-Type': 'application/json'
    });
    return this.http.post(env.WRAPPER_MIDDLEWARE_API + "/gameallocation", formData, { headers: headers });
    
  }

}
