import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import jsQR from 'jsqr';
import { Location } from '@angular/common';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { AuthService } from 'src/app/auth/auth.service';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
declare var $: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signInForm: FormGroup;
  submittedForm: boolean = false;
  scanCode: any;
  sessionId: any;
  checkQRCode2: any;
  sessionIdValue: any;
  mobile_no: any;
  passwordError: boolean = false;
  latitude: any;
  langitude: any;
  categoryCode: any;
  scancodeData: any;
  prodCategory: any;
  content: any;
  subCatCode: any;
  is_grow: any = false;
  milkCode: any
  productCode: any;
  scannedDate: any;
  expiryDate: any;
  myDate = new Date();
  nonmilk_similac_array = [];
  nonmilk_grow_array = [];
  all_nonmilk_array = [];
  isGrow = false;
  eligible = false;
  is_milk: boolean = false;
  showScanner: Boolean = false;
  showProfile: Boolean = true;
  myDateValue: Date;
  is_eligilbe_grow: boolean;
  is_eligilbe_ensure: boolean;
  is_eligilbe_glucerna: boolean;
  is_eligilbe_pediasure: boolean;
  minDate: Date;
  maxDate: Date;
  termsContent: any;
  growtermsContent: any;
  SimilacTotalCount: any;
  GrowTotalCount: any;
  EnsureTotalCount: any;
  GlucernaTotalCount: any;
  PediasureTotalCount: any;
  saveInfo = true;
  hideAddMoreMember = false;
  addMembersForm: FormGroup;
  submitted = false;
  addMembersForm2: FormGroup;
  submittedForm2 = false;
  hideAddMoreMember2 = false;
  addMembersForm3: FormGroup;
  submittedForm3 = false;
  hideAddMoreMember3 = false;
  addMembersForm4: FormGroup;
  submittedForm4 = false;
  hideAddMoreMember4 = false;
  show1: any;
  rememberVal: any;

  Ensure: any;
  Glucerna: any;
  Pediasure: any;
  is_ensure: any;
  is_glucerna: any;
  is_pediasure: any;
  formDatas: any
  eligible_nonmilk_ensure: any;
  eligible_nonmilk_glucerna: any
  eligible_nonmilk_pediasure: any
  custom_isEnsure: boolean;
  custom_isGlucerna: boolean;
  custom_isPediasure: boolean;
  parentUserImage: any;
  statusprofile: boolean = false;
  selectedProfile: {};
  firstName: any;
  lastName: any;
  adultDob: any;
  isRedirectToSignup: boolean = false;
  scannedCode: string;
  iSprintData: any;
  confidence: any;

  global_pediasure_Month_count:any;
  global_ensure_Month_count:any;
  global_glucerna_Month_count:any;
  global_grow_Month_count:any;
  global_similac_Month_count:any;

  remain_grow_Month_count:any;
  remain_similac_Month_count:any;
  remain_ensure_Month_count:any;
  remain_glucerna_Month_count:any;
  remain_pediasure_Month_count:any;



  public get SignInFormControl(): any {
    return this.signInForm.controls
  }
  @ViewChild('invalidQrCodeModal', { static: true }) invalidQrCodeModal: ModalDirective;

  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('successQrCodeModal', { static: true }) successQrCodeModal: ModalDirective;
  @ViewChild('qrCode1Modal', { static: false }) qrCode1Modal: ModalDirective;
  @ViewChild('testModal', { static: false }) testModal: ModalDirective;
  @ViewChild('milkCodeModal', { static: true }) milkCodeModal: ModalDirective;
  @ViewChild('termsSimilac', { static: true }) termsSimilac: ModalDirective;
  @ViewChild('addMoreMembers', { static: true }) addMoreMembers: ModalDirective;
  @ViewChild('tryagaiinAddTransModal', { static: true }) tryagaiinAddTransModal: ModalDirective;
  @ViewChild('tryagainSmomModal', { static: true }) tryagainSmomModal: ModalDirective;
  @ViewChild('tryagaiinChildAddTransModal', { static: true }) tryagaiinChildAddTransModal: ModalDirective;


  config = {
    ignoreBackdropClick: true
  };
  modalRef: BsModalRef;
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false
  };

  noChild: any = [];
  formIndex: any;
  status: any = [false, false, false, false];
  selectedChild: any = null;

  //-------image upload variables-------
  visible = false;
  uniqueId: string;
  fileupload: any;
  capturedImage: any
  finalUserImage: any = null;
  profileImage: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  fileInput: any;

  enableAdd = false;

  smom_NonMilk_product: any;
  track_milkCode: any = {};

  yearList: any = [];
  yearList1: any;
  motherDOByear: any;
  constructor(private fb: FormBuilder, private apiService: ApiService, private router: Router, private authService: AuthService, private bsService: BugsnagService,
    private route: ActivatedRoute, private toastr: ToastrService, private spinner: NgxSpinnerService, private modalService: BsModalService, private _location: Location,) {
    this.scanCode = sessionStorage.getItem('scanCode');
    this.myDate.setDate(this.myDate.getDate() + 30);
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    console.log(this.iSprintData, 'isprintdata');

    this.categoryCode = this.iSprintData?.category_code;
    this.checkQRCode2 = this.iSprintData?.type;
    if (this.scanCode != "undefined") {
      // let qrCodeData = JSON.parse(sessionStorage.getItem('productdata'));

      
      this.categoryCode = this.iSprintData?.category_code;
      this.checkQRCode2 = this.iSprintData?.type;

      this.categoryCode == "Similac" ? this.is_grow = false : this.is_grow = true;

      if (this.categoryCode == "Ensure") {
        this.is_grow = 'null'
        this.is_ensure = true;
      } else if (this.categoryCode == "Glucerna") {
        this.is_grow = 'null'
        this.is_glucerna = true;
      } else if (this.categoryCode == "PediaSure") {
        this.is_grow = 'null'
        this.is_pediasure = true;
      }
      console.log(this.is_grow, 'this.is grow')
      console.log(this.is_ensure, 'this.is_ensure')
      console.log(this.is_glucerna, 'this.is_glucerna')
      console.log(this.is_pediasure, 'this.is_pediasure')

      // let scannedCode = qrCodeData;
      this.productCode = this.iSprintData?.product_code ? this.iSprintData?.product_code : '';

      let today = new Date();
      this.scannedDate = moment(today).format('YYYY-MM-DD');

      let endDate = moment(today).add(30, 'days');
      this.expiryDate = moment(endDate).format('YYYY-MM-DD');

    } else {
      console.log('cleared local storage---')
    }
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    console.log("scancode", this.scannedCode);
    this.scannedCode = sessionStorage.getItem("scanCode");
    console.log(this.iSprintData,"sign-in component it is")
    this.prodCategory = this.iSprintData?.category_code;

     //Hardcoded the product values based on Glucerna
    // this.categoryCode = 'Glucerna';
    // this.prodCategory = 'Glucerna';

  }


  ngOnInit(): void {
    // Open popup by default on page loads
   //this.successQrCodeModal.show();

    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    sessionStorage.removeItem('activeSimilac');
    sessionStorage.removeItem('activeGrow');
    this.sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    this.mobile_no = sessionStorage.getItem('mobileNo');
    this.SignInForm();
    this.signInForm.controls["mobile_no"].disable();
    this.getProductDescCode();
    this.similacTerms();
    this.yearList = this.apiService.findRange();
    this.yearList1 = this.apiService.findRange1();
    console.log(this.yearList1, 'this.yearList1')
    this.motherDOByear = this.apiService.findMotherDOBRange();
    console.log(this.motherDOByear, 'this.motherDOByear')

    this.addMembersForm = this.fb.group({
      surName: this.isPregnent ? [''] : ['', Validators.required],
      name: this.isPregnent ? [''] : ['', Validators.required],
      day: this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna' ? [''] : ['', Validators.required],
      month: this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna' ? [''] : ['', Validators.required],
      year: ['', Validators.required],
      babyAge: [''],
      termsAndConditions: ['', Validators.required],
      similacmomtype: [''],
      typeofdiabetes: this.prodCategory == 'Glucerna' ? ['', Validators.required] : ['']

    })

    this.addMembersForm2 = this.fb.group({
      surName: this.isPregnent ? [''] : ['', Validators.required],
      name: this.isPregnent ? [''] : ['', Validators.required],
      day: this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna' ? [''] : ['', Validators.required],
      month: this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna' ? [''] : ['', Validators.required],
      year: ['', Validators.required],
      babyAge: [''],
      termsAndConditions: ['', Validators.required],
      similacmomtype: [''],
      typeofdiabetes: this.prodCategory == 'Glucerna' ? ['', Validators.required] : ['']
    })

    this.addMembersForm3 = this.fb.group({
      surName: this.isPregnent ? [''] : ['', Validators.required],
      name: this.isPregnent ? [''] : ['', Validators.required],
      day: this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna' ? [''] : ['', Validators.required],
      month: this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna' ? [''] : ['', Validators.required],
      year: ['', Validators.required],
      babyAge: [''],
      termsAndConditions: ['', Validators.required],
      similacmomtype: [''],
      typeofdiabetes: this.prodCategory == 'Glucerna' ? ['', Validators.required] : ['']
    })
    this.addMembersForm4 = this.fb.group({
      surName: this.isPregnent ? [''] : ['', Validators.required],
      name: this.isPregnent ? [''] : ['', Validators.required],
      day: [''],
      month: [''],
      year: ['', Validators.required],
      babyAge: [''],
      termsAndConditions: ['', Validators.required],
      similacmomtype: [''],
      typeofdiabetes: this.prodCategory == 'Glucerna' ? ['', Validators.required] : ['']
    })

    this.checkProduct();

    this.track_milkCode = this.apiService.trackChildMilk();


  }

  checkProduct() {
    this.apiService.SMOMnonmilk.subscribe(res => {
      this.smom_NonMilk_product = res;
      console.log(this.smom_NonMilk_product, 'this.smom_NonMilk_product in login')
    })
  }
  //--------------add conditional validators-----------

  addConditionalValidators() {
    let babyAge = this.addMembersForm.get('babyAge');
    let day = this.addMembersForm.get('day')
    let month = this.addMembersForm.get('month')
    let year = this.addMembersForm.get('year')
    let surName = this.addMembersForm.get('surName')
    let name = this.addMembersForm.get('name')
    if (this.milkCode == 1) {
      babyAge.clearValidators();
      if (this.isPregnent == true) {
        surName.clearValidators();
        name.clearValidators();
      } else {
        surName.setValidators([Validators.required]);
        name.setValidators([Validators.required]);
      }

    }
    if (this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') {
      // babyAge.setValidators([Validators.required]);
      day.clearValidators();
      month.clearValidators();
      //year.clearValidators();

    }
    else {
      // babyAge.clearValidators();
      day.setValidators([Validators.required]);
      month.setValidators([Validators.required]);
      year.setValidators([Validators.required]);
    }
    babyAge.updateValueAndValidity();
    day.updateValueAndValidity();
    month.updateValueAndValidity();
    year.updateValueAndValidity();
  }

  // form one
  onSubmitaddMembersForm() {
    this.submitted = true;
    console.log('test')
    if (this.addMembersForm.invalid) {
      console.log('invalid')
      console.log(this.addMembersForm.controls, 'controls')
      return;
    }
    else {
      let childForm: any;
      console.log(this.addMembersForm.value, 'form val--')
      let ChildData = this.addMembersForm.value
      console.log(this.formIndex, 'this.formIndexin side submit---')
      if (this.formIndex == 0) {
        //----------CHILD 1 INFO ADDED------------//
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_1",
                      "value": ChildData?.name
                    },
                    {
                      "name": "babys_surname_1",
                      "value": ChildData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": ChildData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": ChildData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 1 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_1_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_1",
            "value": ChildData?.babyAge
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_1",
            "value": ChildData?.day && ChildData?.month && ChildData?.year ? ChildData.year + '-' + ChildData.month + '-' + ChildData.day : "",
          })
        }


      }
      if (this.formIndex == 1) {
        //----------CHILD 2 INFO ADDED------------//
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_2",
                      "value": ChildData?.name
                    },
                    {
                      "name": "babys_surname_2",
                      "value": ChildData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": ChildData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": ChildData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 2 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_2_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) { // if milk code, send child age
          addField.push({
            "name": "babys_age_2",
            "value": ChildData?.babyAge
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_2",
            "value": ChildData?.day && ChildData?.month && ChildData?.year ? ChildData.year + '-' + ChildData.month + '-' + ChildData.day : "",
          })
        }

      }
      if (this.formIndex == 2) {
        //----------CHILD 3 INFO ADDED------------//
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_3",
                      "value": ChildData?.name
                    },
                    {
                      "name": "babys_surname_3",
                      "value": ChildData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": ChildData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": ChildData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 3 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_3_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_3",
            "value": ChildData?.babyAge
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_3",
            "value": ChildData?.day && ChildData?.month && ChildData?.year ? ChildData.year + '-' + ChildData.month + '-' + ChildData.day : "",
          })
        }


      }
      if (this.formIndex == 3) {
        //----------CHILD 4 INFO ADDED------------//
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_4",
                      "value": ChildData?.name
                    },
                    {
                      "name": "babys_surname_4",
                      "value": ChildData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": ChildData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": ChildData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 4 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_4_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_4",
            "value": ChildData?.babyAge
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_4",
            "value": ChildData?.day && ChildData?.month && ChildData?.year ? ChildData.year + '-' + ChildData.month + '-' + ChildData.day : "",
          })
        }

      }

      console.log(childForm, 'childForm')

      let authToken = sessionStorage.getItem('authToken');
      this.spinner.show();
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(childForm)
      }
      let updatecustresp
      this.apiService.updateCapCustomer(childForm, this.mobile_no, authToken).pipe(first())
        .subscribe(res => {
          updatecustresp = res;
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.globalChildArray = [];
            this.spinner.show();
            this.apiService.getCapCustomer(this.mobile_no, authToken).pipe(first())
              .subscribe((res: any) => {
                console.log('get customer 1 ===========')
                this.spinner.hide();
                let userInfo = res['customers']['customer'][0].custom_fields['field']
                console.log(userInfo, 'userInfo 1 ==========')
                let image = userInfo.filter(res => res.name == 'profileimage');
                this.profileImage = image[0]?.value;

                console.log(this.profileImage, 'image');
                let val = this.showChildList(userInfo, this.profileImage);
                if (val) {
                  this.hideAddMoreMember = false;
                  this.saveInfo = true;
                  this.addMembersForm.reset();
                  this.submitted = false;
                  // this.finalUserImage = null;
                }
              })

          }
          else {
            console.log(res['status'].message)
          }
        }, err => {
          this.bsService.notifyError(err, childForm);
          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          this.spinner.hide()
          console.log(err, '=== get customer error =======')
        });




    }
  }
  get addMembersFormVal() {
    return this.addMembersForm.controls;
  }

  // form two
  onSubmitaddMembersForm2() {
    this.submittedForm2 = true;
    if (this.addMembersForm2.invalid) {
      return;
    }
  }
  get addMembersFormVal2() {
    return this.addMembersForm2.controls;
  }

  // form three
  onSubmitaddMembersForm3() {
    this.submittedForm3 = true;
    if (this.addMembersForm2.invalid) {
      return;
    }
  }
  get addMembersFormVal3() {
    return this.addMembersForm3.controls;
  }
  onSubmitaddMembersForm4() {
    this.submittedForm4 = true;
    alert();

    if (this.prodCategory == 'Glucerna' || this.prodCategory == 'Ensure') {
      this.addMembersForm4.get('day').clearValidators();
      this.addMembersForm4.get('month').clearValidators();
    } else {
      this.addMembersForm4.get('day').setValidators([Validators.required]);
      this.addMembersForm4.get('month').setValidators([Validators.required]);
    }
    if (this.addMembersForm2.invalid) {
      return;
    }
  }
  get addMembersFormVal4() {
    return this.addMembersForm4.controls;
  }
  onClickScanner() {
    this.router.navigate(['/scanner']);
    // this.modalService.hide();
    // this.showScanner = true;
    // this.showProfile = false;

    // if (this.showScanner) {
    //   this.startVideo();
    // }
  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
  }
  rememval: any;
  SignInForm() {
    let mobnoWitZero = sessionStorage.getItem('addMobNum');
    this.signInForm = this.fb.group({
      mobile_no: [mobnoWitZero, [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(5)]],
    })
    this.rememval = sessionStorage.getItem('rememval');
    let mobno = sessionStorage.getItem('mobileNo');
    let passwd = sessionStorage.getItem('passwd');
    console.log(this.rememval, mobno, passwd, 'rem pass======')
    if (this.rememval == 'true') {
      this.signInForm.patchValue({
        mobile_no: mobno,
        password: passwd,
      })

    } else if (this.rememval == 'false') {
      this.signInForm.patchValue({
        mobile_no: mobno,
        password: '',
      })
    }
  }

  closeTestModal() {
    this.testModal.hide();
  }
  closeQrCode1Modal() {
    this.qrCode1Modal.hide();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);

  }
  qrScan(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);

  }
  goBack() {
    this._location.back();
    // this.navigation.back();
  }
  addMoreMembersModal() {
    console.log('test')
    let getAuthKey = sessionStorage.getItem('authToken')
    let getcustresp
    this.spinner.show();
    this.apiService.getCapCustomer(this.mobile_no, getAuthKey).subscribe((res: any) => {
      this.spinner.hide();
      if (res['status'].code == 200) {
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        getcustresp = res;
        console.log(res)
        this.firstName = res?.customers?.customer[0]?.firstname
        this.lastName = res?.customers?.customer[0]?.lastname
        let userInfo = res?.customers?.customer[0]?.custom_fields?.field;
        let image = userInfo.filter(res => res.name == 'profileimage');
        let dob = userInfo.filter(res => res.name == 'adultdob')
        this.adultDob = dob[0]?.value
        let profileImage = image[0]?.value;
        console.log(profileImage, 'image');
        let savedimg
        this.apiService.getSavedImageFinal(this.mobile_no, getAuthKey, profileImage).pipe(first())
          .subscribe((res: any) => {
            savedimg = res;
            this.spinner.hide();
            this.parentUserImage = res.file_path;  //-----------master user image
            console.log(this.parentUserImage, 'image get')
          }, err => {
            let myuuid = uuidv4();
            let loggerFormData = {
              "unique_id": myuuid,
              "message": JSON.stringify(savedimg)
            }
            this.bsService.notifyError(err, profileImage);
          })
      }
    }, err => {
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(getcustresp)
      }
      this.bsService.notifyError(err, getAuthKey);
    })
    this.addMoreMembers.show();

  }

  clickProfile(event) {
    $('#showActive').toggleClass('active');
    $('#showActive1').toggleClass('active');

    // console.log(event)
    this.enableAdd = true;
    this.statusprofile = !this.statusprofile;
    // console.log(this.statusprofile)
    this.selectedProfile = {
      name: this.firstName,
      age: '',
      surname: this.lastName,
      dob: this.adultDob,
      image: event
    }
  }


  addMoreMembersHide() {
    this.addMoreMembers.hide();
  }

  addMoreMembersClose() {
    this.addMoreMembers.hide();
    this.router.navigate(['/user-profile'])

  }

  globalChildArray: any = [];
  signInFormSubmit() {
    let custom_fields: any
    this.submittedForm = true;
    if (this.signInForm.invalid) {
      return
    }
    else if (this.signInForm.valid) {
      this.passwordError = false;
      this.spinner.show();
      let mobileno = this.signInForm.value ? this.signInForm.value.mobile_no : '';
      let password = this.signInForm.value ? this.signInForm.value.password : '';
      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": this.mobile_no,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionIdValue,
        "password": password,
      }
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(formData)
      }
      let passval
      this.apiService.passwordValidate(formData).pipe(first())
        .subscribe(res => {
          passval = res
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
          if (res['status'].code == 200) {
            sessionStorage.setItem('authToken', res['auth'].token)
            sessionStorage.setItem('authKey', res['auth'].key)
            this.authService.setLocalStorage();
            this.authService.startTokenTimer();
            let authTokenList = sessionStorage.getItem('authToken')
            console.log(authTokenList, 'authTokenList')
            //this.toastr.success(res['status'].message)
            if (res['user'].userRegisteredForPassword == true || res['status'].code == 1515) {
              if (this.checkQRCode2 == 2) {


                console.log('inside A---')
                console.log(this.checkQRCode2, 'this.checkQRCode2 type 2')
                let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
                let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
                let gettransresp
                this.spinner.show();
                this.apiService.getTransactionfull(this.mobile_no, authTokenList).pipe(first()).subscribe(res => {
                  this.spinner.hide();
                  gettransresp = res;
                  let myuuid = uuidv4();
                  let loggerFormData = {
                    "unique_id": myuuid,
                    "message": JSON.stringify(res)
                  }
                  if (res['status'].code == 200) {

                    let result = res['customer']['transactions'];
                    let transcation = result['transaction'];
                    let transNumber = transcation[0]?.number;
                    sessionStorage.setItem('transaction_number', transNumber)
                    console.log(transcation, 'transcation')
                    if (transcation?.length == 0) {
                      if (this.checkQRCode2 == 2) {
                        console.log('lenth of trans')
                        sessionStorage.setItem('isGeneric', 'true');
                        this.spinner.hide()
                        this.isRedirectToSignup = true
                        this.router.navigate(['/crm-signup'])
                        return;
                      } else {
                        console.log('lenth of trans else==')
                        this.router.navigate(['/user-profile'])
                      }

                    }
                  } else if (res['customer']['item_status'].code == '1012') {
                    this.spinner.hide()
                    this.isRedirectToSignup = true
                    this.router.navigate(['/crm-signup'])
                  }

                }, err => {
                  let myuuid = uuidv4();
                  let loggerFormData = {
                    "unique_id": myuuid,
                    "message": JSON.stringify(gettransresp)
                  }

                  this.bsService.notifyError(err, formData);
                })

                setTimeout(() => {

                  this.spinner.show();
                  this.apiService.getTransactionfull(this.mobile_no, authTokenList).pipe(first())
                    .subscribe(res => {
                      this.spinner.hide();
                      let gettransresp;
                      let myuuid = uuidv4();
                      let loggerFormData = {
                        "unique_id": myuuid,
                        "message": JSON.stringify(res)
                      }
                      //if(res['status'].code == 200){

                      let result = res['customer']['transactions'];
                      let transcation = result['transaction'];
                      let transNumber = transcation[0]?.number;
                      sessionStorage.setItem('transaction_number', transNumber)
                      console.log(transcation, 'transcation')

                      // if(transcation?.length > 0){

                      console.log('inside B---')
                      if (transcation) {
                        custom_fields = transcation.map(val => {
                          return val.custom_fields['field']
                        });
                      }
                      console.log(custom_fields, 'custom_fields test')
                      if (custom_fields) {
                        this.getTransactionCount(custom_fields);
                      }

                      let getMobile = sessionStorage.getItem('mobileNo')
                      let getAuthKey = sessionStorage.getItem('authToken')
                      let productName = sessionStorage.getItem('productName');

                      this.spinner.hide();
                      let getcustresp;
                      this.spinner.show();
                      this.apiService.getCapCustomer(this.mobile_no, getAuthKey).pipe(first())
                        .subscribe((res: any) => {
                          this.spinner.hide();
                          let myuuid = uuidv4();
                          let loggerFormData = {
                            "unique_id": myuuid,
                            "message": JSON.stringify(res)
                          }
                          getcustresp = res;
                          console.log('get customer 2 ===========')
                          if (res['status'].code == 200) {
                            this.spinner.hide();
                            console.log('inside C---')
                            let transList = res?.customers?.customer[0]?.transactions?.transaction;
                            if (transList.length == 0) {
                              console.log(transList, 'transList');

                              let userInfo = res['customers']['customer'][0].custom_fields['field']
                              console.log(userInfo, 'userInfo 2 ==========')
                              let image = userInfo.filter(res => res.name == 'profileimage');
                              this.profileImage = image[0]?.value;

                              console.log(this.profileImage, 'image');

                              //if (transcation?.length > 0) {

                              if (this.smom_NonMilk_product) {
                                this.addTransactionForSMOM()
                              }
                              else {
                                if (!this.isRedirectToSignup) {
                                  this.addMoreMembersModal();
                                  let returnSuccess = this.showChildList(userInfo, this.profileImage);
                                }
                              }
                              //}
                              // else {
                              //   if (this.checkQRCode2 == 2) {
                              //     sessionStorage.setItem('isGeneric', 'true');
                              //     this.router.navigate(['/crm-signup'])
                              //     return;
                              //   } else {
                              //     this.router.navigate(['/user-profile'])

                              //   }
                              // }

                            } else {
                              let userInfo = res['customers']['customer'][0].custom_fields['field']
                              console.log(userInfo, 'userInfo 3 ==========')
                              let image = userInfo.filter(res => res.name == 'profileimage');
                              this.profileImage = image[0]?.value;

                              console.log(this.profileImage, 'image');

                              //if (transcation?.length > 0) {
                              if (this.smom_NonMilk_product) {
                                this.addTransactionForSMOM()
                              }
                              else {
                                if (!this.isRedirectToSignup) {
                                  this.addMoreMembersModal();
                                  let returnSuccess = this.showChildList(userInfo, this.profileImage);
                                }
                              }
                              //}
                              //else {
                              // if (this.checkQRCode2 == 2) {
                              //   sessionStorage.setItem('isGeneric', 'true');
                              //   this.router.navigate(['/crm-signup'])
                              //   return;
                              // } else {
                              //   this.router.navigate(['/user-profile'])

                              // }
                              // }


                              //---------------commented original add transcation----------------------------

                              //   this.apiService.getPosition().then(pos=>{
                              //   let lat = pos.lat
                              //   let lang = pos.lng
                              //   this.latitude = lat.toString()
                              //   this.langitude = lang.toString()
                              //   let getMobile = sessionStorage.getItem('mobileNo')
                              //   let getAuthKey = sessionStorage.getItem('authToken')
                              //   let productName = sessionStorage.getItem('productName');
                              //   let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
                              //   let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
                              //   let formData = {
                              //       "scan_code": this.scanCode,
                              //       "mobile": getMobile,
                              //       "cap_device_id": env.DEVICE_ID,
                              //       "custom_fields": [
                              //         {
                              //           "name": "TransLat",
                              //           "value": this.latitude
                              //         },
                              //         {
                              //           "name": "TransLong",
                              //           "value": this.langitude
                              //        },
                              //        {
                              //           "name": "productName",
                              //           "value": productName
                              //        },
                              //        { 
                              //         "name":"category_code_trans",
                              //         "value":this.categoryCode ? this.categoryCode : ''
                              //       },                              
                              //       {
                              //         "name":"is_milk",
                              //         "value": this.is_milk

                              //       },
                              //       {
                              //         "name": "is_grow",
                              //         "value": this.is_grow

                              //       },
                              //       {
                              //         "name": "itemcode",
                              //         "value": this.subCatCode
                              //       },
                              //       {
                              //         "name": "scanned_date",
                              //         "value": this.scannedDate
                              //       },
                              //       {
                              //         "name": "expiration_date",
                              //         "value": this.expiryDate
                              //       },
                              //       // {
                              //       //   "name": "is_eligible_for_game",
                              //       //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
                              //       // },
                              //       {
                              //         "name": "is_eligible_for_game",
                              //         "value": this.is_grow == true ? (GrowCount < 5 ? 'true' : 'false') : (SimilacCount < 5 ? 'true' : 'false')
                              //       },
                              //       {
                              //         "name": "is_redeemed",
                              //         "value": false
                              //       },
                              //       {
                              //         "name": "gift_id",
                              //         "value": 'null'
                              //       },
                              //       {
                              //         "name": "gift_delivery_date",
                              //         "value": 'null'
                              //       },
                              //       {
                              //         "name": "child_info",
                              //         "value": this.selectedChild?.name
                              //       }

                              //     ]
                              //   }
                              //   this.spinner.show();
                              //   this.apiService.addTransaction(formData, getAuthKey).pipe(first())
                              //   .subscribe(res=>{
                              //     this.spinner.hide();
                              //     this.getNonMilkCount();
                              //     if(this.milkCode == '1'){
                              //       this.openMilkCodeModal()
                              //     }

                              //     if(this.milkCode == '0'){
                              //       setTimeout(() => {
                              //         this.spinner.show();
                              //       let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
                              //       let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
                              //       this.SimilacTotalCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
                              //       this.GrowTotalCount = JSON.parse(sessionStorage.getItem('growMilkCount'))

                              //       if(this.is_grow){
                              //         if(GrowCount <= 5){
                              //           this.successQrCodeModal.show();
                              //         }
                              //         else{
                              //           this.router.navigate(['/lucky-draw-quota-exceed']);
                              //         }
                              //       }
                              //       else{
                              //         if(SimilacCount <= 5){
                              //           this.successQrCodeModal.show();
                              //           console.log(SimilacCount, 'SimilacCount')
                              //         }
                              //         else{
                              //           this.router.navigate(['/lucky-draw-quota-exceed']);
                              //           console.log(SimilacCount, 'SimilacCount')
                              //         }
                              //       }   
                              //       let getAuthKey = sessionStorage.getItem('authToken')
                              //       this.spinner.show();
                              //       this.apiService.getCapCustomer(this.mobile_no, getAuthKey).pipe(first())
                              //       .subscribe(res => {
                              //         this.spinner.hide();
                              //         let custMilkCode = res['customers']['customer'][0]['custom_fields']['field']
                              //         custMilkCode.forEach((item: any) => {
                              //          // if(this.milkCode == '0'){
                              //             if(item.name == "milk_code"){
                              //               if(item.value == '1'){
                              //                 let registerForm = {
                              //                   "root": {
                              //                     "customer": [
                              //                       {
                              //                         "mobile":this.mobile_no,
                              //                         "custom_fields":{ 
                              //                           "field":[ 
                              //                             {
                              //                               "name": "milk_code",
                              //                               "value": '0'
                              //                           },
                              //                           ]
                              //                         }
                              //                       }
                              //                     ]
                              //                   }
                              //                 }
                              //                 this.spinner.show();
                              //                 this.apiService.updateCapCustomer(registerForm, this.mobile_no, getAuthKey).pipe(first())
                              //                 .subscribe(res=>{
                              //                   this.spinner.hide();
                              //                   if(res['status'].code == 200){
                              //                     if(this.is_grow){
                              //                       if(GrowCount <= 5){
                              //                         this.successQrCodeModal.show();
                              //                       }
                              //                       else{
                              //                         this.router.navigate(['/lucky-draw-quota-exceed']);
                              //                       }
                              //                     }
                              //                     else {
                              //                       if(SimilacCount <= 5){
                              //                         this.successQrCodeModal.show();
                              //                         console.log(SimilacCount, 'SimilacCount')
                              //                       }
                              //                       else{
                              //                         this.router.navigate(['/lucky-draw-quota-exceed']);
                              //                         console.log(SimilacCount, 'SimilacCount')
                              //                       }
                              //                     }     
                              //                   }
                              //                   else{
                              //                     console.log(res['status'].message)
                              //                     //this.toastr.info(res['status'].message)
                              //                   }
                              //                 },err => {
                              //                   this.spinner.hide()
                              //                   console.log(err, '=== get customer error =======')
                              //                 });
                              //               }
                              //             }else{
                              //               if(this.is_grow){
                              //                 if(GrowCount <= 5){
                              //                   this.successQrCodeModal.show();
                              //                 }
                              //                 else{
                              //                   this.router.navigate(['/lucky-draw-quota-exceed']);
                              //                 }
                              //               }
                              //               else{
                              //                 if(SimilacCount <= 5){
                              //                   this.successQrCodeModal.show();
                              //                   console.log(SimilacCount, 'SimilacCount')
                              //                 }
                              //                 else{
                              //                   this.router.navigate(['/lucky-draw-quota-exceed']);
                              //                   console.log(SimilacCount, 'SimilacCount')
                              //                 }
                              //               }                

                              //             }
                              //           //}
                              //         })

                              //         },err=>{
                              //           this.spinner.hide();
                              //         }) 
                              //         this.spinner.hide();
                              //       }, 2000);

                              //     }




                              //   },err=>{
                              //     this.spinner.hide();
                              //    //this.apiService.showToaster(err)
                              //    this.openinvalidQrCodeModal()

                              //     console.log(err, '=== get customer error =======')
                              //   })
                              // })



                            }

                            // console.log(transList, 'transList');
                          }
                          else if (res['status'].code == 500 || res['customers']['customer'][0].item_status.code == '1012') {
                            console.log('this route====')
                            this.isRedirectToSignup = true
                            this.router.navigateByUrl('/crm-signup')

                          }
                        }, err => {
                          let myuuid = uuidv4();
                          let loggerFormData = {
                            "unique_id": myuuid,
                            "message": JSON.stringify(getcustresp)
                          }

                          this.bsService.notifyError(err);
                          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                          this.spinner.hide();
                        })
                      //   this.router.navigate(['/user-profile'])
                      //   return;
                      // }else{
                      //   if(this.checkQRCode2 == 2){
                      //     sessionStorage.setItem('isGeneric', 'true');
                      //     this.router.navigate(['/crm-signup'])
                      //     return;
                      //   } else{
                      //     this.router.navigate(['/user-profile'])

                      //   }

                      //}
                    }, err => {
                      let myuuid = uuidv4();
                      let loggerFormData = {
                        "unique_id": myuuid,
                        "message": JSON.stringify(gettransresp)
                      }

                      this.bsService.notifyError(err);
                    })

                  // }

                }, 2000);

              }
              else if (this.checkQRCode2 == 1 || this.checkQRCode2 == undefined) {
                this.getNonMilkCount();
                let goToLibrary = sessionStorage.getItem('enableLibrary')
                let gotoHome = sessionStorage.getItem('gotoHomePage')
                if (goToLibrary == 'library') {
                  this.router.navigate(['library']);
                } else if (gotoHome == 'gotoHome') {
                  this.router.navigate(['profile-home']);
                }
                else {
                  this.router.navigate(['user-profile']);
                }
              }
            }
          }
          else if (res['status'].code == 1505) { 
            this.apiService.showErrorMsg(res['status'].message);   //invalid session
            this.router.navigate(['/sign-up']);
            console.log(res['status'].message)
          }
          else if (res['status'].code == 1528) {
            this.apiService.showErrorMsg(res['status'].message);  //password does not match
            console.log(res['status'].message)
            this.passwordError = true;
          }
          else {
            console.log(res['status'].message)
            this.apiService.showErrorMsg(res['status'].message);
            //other errors
          }
        },
          err => {
            this.bsService.notifyError(err, formData);
            this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
            this.spinner.hide();
            console.log(err, 'login error')
          })
    }
    else {
      let getAuthKey = sessionStorage.getItem('authKey');
      let getcustresp
      this.spinner.show();
      this.apiService.getCapCustomer(this.mobile_no, getAuthKey).pipe(first())
        .subscribe(res => {
          this.spinner.hide();
          getcustresp = res;
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          console.log('get customer 3 ===========')
          console.log('this roter 2===')
          this.spinner.hide()
          if (res['customers']['customer'][0].item_status.code == '1012') {
            this.router.navigate(['/salesforce-signup']);
            //this.toastr.info(res['customers']['customer'][0].item_status.message) 
          }
        }, err => {

          this.bsService.notifyError(err, getAuthKey);
        })

    }

  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  custom_isGrow: any = false;
  eligible_nonmilk_grow: any = false;
  eligible_nonmilk_similac: any = false;
  getNonMilkCount() {
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');

    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')
    let transresp;
    this.spinner.show();
    this.apiService.getTransactionfull(mobile_no, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        transresp = res;
        let custom_fields: any;
        let result = res?.customer?.transactions;
        let transcation = result?.transaction;
        let transNumber = transcation[0]?.number;
        sessionStorage.setItem('transaction_number', transNumber)
        if (transcation) {
          custom_fields = transcation.map(val => {
            return val.custom_fields['field']
          });
        }
        if (custom_fields) {
          this.getTransactionCount(custom_fields);
        }

      }, err => {
        this.bsService.notifyError(err, getAuthKey);
      })
  }

  // non_redemmed_grow: any = [];
  // non_redemmed_similac: any = [];
  redemmed: boolean = false;
  is_not_expired: boolean = false;
  // non_redeemed_grow: any = [];
  // non_redeemed_similac: any = [];
  game_is_eligible: boolean = false;
  global_grow_count: any;
  global_similac_count: any;
  global_ensure_count: any;
  global_glucerna_count: any
  global_pediasure_count: any
  global_non_redemmed_grow: any = [];
  global_non_redemmed_ensure: any = [];
  global_non_redemmed_glucerna: any = [];
  global_non_redemmed_pediasure: any = [];
  getTransactionCount(custom_fields1) {
    this.global_non_redemmed_grow = [];
    this.global_non_redemmed_ensure = [];
    this.global_non_redemmed_glucerna = [];
    this.global_non_redemmed_pediasure = [];
    let non_redeemed_grow = [];
    let non_redeemed_similac = [];
    let nonmilk_similac_array = [];
    let nonmilk_grow_array = [];
    let all_nonmilk_array = []

    let non_redeemed_ensure = [];
    let non_redeemed_glucerna = [];
    let non_redeemed_pediasure = [];
    let nonmilk_ensure_array = [];
    let nonmilk_glucerna_array = [];
    let nonmilk_pediasure_array = [];

    custom_fields1.filter(custom_fields => {

      console.log(custom_fields, 'filter custom');
      if (custom_fields?.name == 'is_grow') {
        let a = custom_fields.findIndex(val => val?.name == 'is_grow');
        let b = custom_fields.findIndex(val => val?.name == 'is_milk');
        if (a > b) {
          let temp = custom_fields[a];
          custom_fields[a] = custom_fields[b];
          custom_fields[b] = temp;
        }
      }
      if (custom_fields?.name == 'is_ensure') {
        let a = custom_fields.findIndex(val => val?.name == 'is_ensure');
        let b = custom_fields.findIndex(val => val?.name == 'is_milk');
        if (a > b) {
          let temp = custom_fields[a];
          custom_fields[a] = custom_fields[b];
          custom_fields[b] = temp;
        }
      }
      if (custom_fields?.name == 'is_glucerna') {
        let a = custom_fields.findIndex(val => val?.name == 'is_glucerna');
        let b = custom_fields.findIndex(val => val?.name == 'is_milk');
        if (a > b) {
          let temp = custom_fields[a];
          custom_fields[a] = custom_fields[b];
          custom_fields[b] = temp;
        }
      }

      if (custom_fields?.name == 'is_pediasure') {
        let a = custom_fields.findIndex(val => val?.name == 'is_pediasure');
        let b = custom_fields.findIndex(val => val?.name == 'is_milk');
        if (a > b) {
          let temp = custom_fields[a];
          custom_fields[a] = custom_fields[b];
          custom_fields[b] = temp;
        }
      }



      let c = custom_fields.findIndex(val => val?.name == 'is_redeemed');
      let d = custom_fields.findIndex(val => val?.name == 'is_milk');
      if (c > d) {
        let temp = custom_fields[c];
        custom_fields[c] = custom_fields[d];
        custom_fields[d] = temp;
      }

      console.log(custom_fields, 'custom_fields-----');
      custom_fields.filter(val => {
        console.log(val, 'val----')
        if (val?.name == 'is_grow') {
          //val.value == "true" ? this.custom_isGrow = true : this.custom_isGrow = false;
          if (val.value == "true") {
            this.custom_isGrow = true;
            this.custom_isEnsure = false;
            this.custom_isGlucerna = false;
            this.custom_isPediasure = false;
          } else if (val.value == "false") {
            this.custom_isGrow = false;
            this.custom_isEnsure = false;
            this.custom_isGlucerna = false;
            this.custom_isPediasure = false;
          }
          console.log(val.value, 'is_grow_value')
          console.log(this.custom_isGrow, 'custom_isGrow value')
          console.log(this.custom_isGrow, 'custom_isGrow value')
        }

        if (val.name == 'is_ensure') {
          // val.value == "true" ? this.custom_isEnsure = true : this.custom_isGrow = 'null';
          if (val.value == "true") {
            this.custom_isGrow = 'null';
            this.custom_isEnsure = true;
            this.custom_isGlucerna = false;
            this.custom_isPediasure = false;
          } else {
            this.custom_isGrow = 'null';
            this.custom_isEnsure = false;
            this.custom_isGlucerna = false;
            this.custom_isPediasure = false;
          }
          console.log(val.value, 'is_ensure_value')
          console.log(this.custom_isEnsure, 'custom_isEnsure value')
          console.log(this.custom_isGrow, 'custom_isGrow value')
        }

        if (val.name == 'is_glucerna') {

          //val.value == "true" ? this.custom_isGlucerna = true : this.custom_isGrow = false;
          if (val.value == "true") {
            this.custom_isGrow = 'null';
            this.custom_isEnsure = false;
            this.custom_isGlucerna = true;
            this.custom_isPediasure = false;
          } else {
            this.custom_isGrow = 'null';
            this.custom_isEnsure = false;
            this.custom_isGlucerna = false;
            this.custom_isPediasure = false;
          }
          console.log(val.value, 'is_glucerna_value')
          console.log(this.custom_isGlucerna, 'custom_isGlucerna value')
          console.log(this.custom_isGrow, 'custom_isGrow value')
        }

        if (val.name == 'is_pediasure') {

          //val.value == "true" ? this.custom_isGlucerna = true : this.custom_isGrow = false;
          if (val.value == "true") {
            this.custom_isGrow = 'null';
            this.custom_isEnsure = false;
            this.custom_isGlucerna = false;
            this.custom_isPediasure = true;
          } else {
            this.custom_isGrow = 'null';
            this.custom_isEnsure = false;
            this.custom_isGlucerna = false;
            this.custom_isPediasure = false;
          }
          console.log(val.value, 'is_glucerna_value')
          console.log(this.custom_isPediasure, 'custom_isPediasure value')
          console.log(this.custom_isGrow, 'custom_isGrow value')
        }


        if (val.name == "expiration_date") {
          this.scannedDate < val.value ? this.is_not_expired = true : this.is_not_expired = false;
        }

        if (val.name == "is_eligible_for_game") {
          val.value == "true" ? this.game_is_eligible = true : this.game_is_eligible = false;
        }

        if (val.name == 'is_redeemed') {
          val.value == "true" ? this.redemmed = true : this.redemmed = false;
        }

        if (val.name == 'is_milk' && val.value == "false") {
          all_nonmilk_array.push(custom_fields);
          if (this.custom_isEnsure) {
            console.log(this.custom_isEnsure, 'this.custom_isEnsure')
            nonmilk_ensure_array.push(custom_fields);
            console.log(nonmilk_ensure_array, 'nonmilk_ensure_array')
          }
          else if (this.custom_isGlucerna) {
            console.log(this.custom_isGlucerna, 'this.custom_isGlucerna')
            nonmilk_glucerna_array.push(custom_fields);
            console.log(nonmilk_glucerna_array, 'nonmilk_glucerna_array')
          }
          else if (this.custom_isPediasure) {
            console.log(this.custom_isPediasure, 'this.custom_isPediasure')
            nonmilk_pediasure_array.push(custom_fields);
            console.log(nonmilk_pediasure_array, 'nonmilk_pediasure_array')
          }
          else if ((this.custom_isGrow == true && this.custom_isGrow != 'null')) {
            console.log(this.custom_isGrow, 'this.custom_isGrow')
            nonmilk_grow_array.push(custom_fields);
            console.log(nonmilk_grow_array, 'nonmilk_grow_array')
          }
          else if ((this.custom_isGrow == false && this.custom_isGrow != 'null')) {
            console.log(this.custom_isGrow, 'this.custom_isGrow')
            nonmilk_similac_array.push(custom_fields);
            console.log(nonmilk_similac_array, 'nonmilk_similac_array')
          }
          if (this.custom_isEnsure && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
            console.log(this.custom_isEnsure, 'this.custom_isEnsure')
            non_redeemed_ensure.push(custom_fields)
            console.log(non_redeemed_ensure, 'non_redeemed_ensure')
          }
          if (this.custom_isGlucerna && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
            console.log(this.custom_isGlucerna, 'this.custom_isGlucerna')
            non_redeemed_glucerna.push(custom_fields)
            console.log(non_redeemed_glucerna, 'non_redeemed_glucerna')
          }
          if (this.custom_isPediasure && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
            console.log(this.custom_isPediasure, 'this.custom_isPediasure')
            non_redeemed_pediasure.push(custom_fields)
            console.log(non_redeemed_pediasure, 'non_redeemed_pediasure')
          }
          if ((this.custom_isGrow == true && this.custom_isGrow != 'null') && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
            console.log(this.custom_isGrow, 'this.custom_isGrow')
            non_redeemed_grow.push(custom_fields)
            console.log(non_redeemed_grow, 'non_redeemed_grow')
          }
          if ((this.custom_isGrow == false && this.custom_isGrow != 'null') && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
            console.log(this.custom_isGrow, 'this.custom_isGrow')
            non_redeemed_similac.push(custom_fields);
            console.log(non_redeemed_similac, 'non_redeemed_similac')
          }
        }

      })



      sessionStorage.setItem('grow_chances', JSON.stringify(non_redeemed_grow.length));
      sessionStorage.setItem('similac_chances', JSON.stringify(non_redeemed_similac.length));

      sessionStorage.setItem('ensure_chances', JSON.stringify(non_redeemed_ensure.length));
      sessionStorage.setItem('glucerna_chances', JSON.stringify(non_redeemed_glucerna.length));
      sessionStorage.setItem('pediasure_chances', JSON.stringify(non_redeemed_pediasure.length));


      sessionStorage.setItem('non_redeemed_grow', JSON.stringify(non_redeemed_grow));
      sessionStorage.setItem('non_redeemed_similac', JSON.stringify(non_redeemed_similac));

      sessionStorage.setItem('non_redeemed_ensure', JSON.stringify(non_redeemed_ensure));
      sessionStorage.setItem('non_redeemed_glucerna', JSON.stringify(non_redeemed_glucerna));
      sessionStorage.setItem('non_redeemed_pediasure', JSON.stringify(non_redeemed_pediasure));

      let all_nonmilk_count = all_nonmilk_array.length; //given final count of non-milk(both grow and similac)
      let grow_nonmilk_count = nonmilk_grow_array.length; // gives final count non-milk grow
      let similac_nonmilk_count = nonmilk_similac_array.length // gives final count non-milk similac

      let ensure_nonmilk_count = nonmilk_ensure_array.length; // gives final count non-milk Ensure
      let glucerna_nonmilk_count = nonmilk_glucerna_array.length // gives final count non-milk Glucerna
      let pediasure_nonmilk_count = nonmilk_pediasure_array.length // gives final count non-milk Pediasure

      console.log(all_nonmilk_count, 'all_nonmilk_count---')
      console.log(nonmilk_grow_array, 'nonmilk_grow_array---')
      console.log(nonmilk_similac_array, 'nonmilk_similac_array---')

      console.log(nonmilk_ensure_array, 'nonmilk_ensure_array---')
      console.log(nonmilk_glucerna_array, 'nonmilk_glucerna_array---')
      console.log(nonmilk_pediasure_array, 'nonmilk_pediasure_array---')

      // Montly wise Scanned count of each product

      let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
      let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');

      this.global_grow_Month_count = nonmilk_grow_array.filter((date:any) =>{
        return date[0].name == 'scanned_date' ? date[0].value >= startOfMonth && date[0].value <= endOfMonth : 'null'
      })
      this.global_grow_Month_count = this.global_grow_Month_count?.length
      this.remain_grow_Month_count = 5 - this.global_grow_Month_count
      sessionStorage.setItem('global_grow_Month_count', JSON.stringify(this.global_grow_Month_count));

      this.global_similac_Month_count = nonmilk_similac_array.filter((date:any) =>{
        return date[0].name == 'scanned_date' ? date[0].value >= startOfMonth && date[0].value <= endOfMonth : 'null'
      })
      this.global_similac_Month_count = this.global_similac_Month_count?.length
      this.remain_similac_Month_count = 5 - this.global_similac_Month_count
      sessionStorage.setItem('global_similac_Month_count', JSON.stringify(this.global_similac_Month_count));

      this.global_ensure_Month_count = nonmilk_ensure_array.filter((date:any) =>{
        return date[0].name == 'scanned_date' ? date[0].value >= startOfMonth && date[0].value <= endOfMonth : 'null'
      })
      this.global_ensure_Month_count = this.global_ensure_Month_count?.length
      this.remain_ensure_Month_count = 5 - this.global_ensure_Month_count
      sessionStorage.setItem('global_ensure_Month_count', JSON.stringify(this.global_ensure_Month_count));
      

      this.global_glucerna_Month_count = nonmilk_glucerna_array.filter((date:any) =>{
        return date[0].name == 'scanned_date' ? date[0].value >= startOfMonth && date[0].value <= endOfMonth : 'null'
      })
      this.global_glucerna_Month_count = this.global_glucerna_Month_count?.length
      this.remain_glucerna_Month_count = 5 - this.global_glucerna_Month_count
      sessionStorage.setItem('global_glucerna_Month_count', JSON.stringify(this.global_glucerna_Month_count));
      

      this.global_pediasure_Month_count = nonmilk_pediasure_array.filter((date:any) =>{
        return date[0].name == 'scanned_date' ? date[0].value >= startOfMonth && date[0].value <= endOfMonth : 'null'
      })
      this.global_pediasure_Month_count = this.global_pediasure_Month_count?.length
      this.remain_pediasure_Month_count = 5 - this.global_pediasure_Month_count
      sessionStorage.setItem('global_pediasure_Month_count', JSON.stringify(this.global_pediasure_Month_count));
      

      




      sessionStorage.setItem('similacMilCount', JSON.stringify(similac_nonmilk_count));
      sessionStorage.setItem('growMilkCount', JSON.stringify(grow_nonmilk_count));

      sessionStorage.setItem('ensureMilCount', JSON.stringify(ensure_nonmilk_count));
      sessionStorage.setItem('glucernaMilkCount', JSON.stringify(glucerna_nonmilk_count));
      sessionStorage.setItem('pediasureMilkCount', JSON.stringify(pediasure_nonmilk_count));

      let remain_nonmilk_grow_count =  grow_nonmilk_count;
      let remain_nonmilk_similac_count =  similac_nonmilk_count;

      let remain_nonmilk_ensure_count =  ensure_nonmilk_count;
      let remain_nonmilk_glucerna_count =  glucerna_nonmilk_count;
      let remain_nonmilk_pediasure_count =  pediasure_nonmilk_count;

      this.global_grow_count = remain_nonmilk_grow_count
      this.global_similac_count = remain_nonmilk_similac_count

      this.global_ensure_count = remain_nonmilk_ensure_count
      this.global_glucerna_count = remain_nonmilk_glucerna_count
      this.global_pediasure_count = remain_nonmilk_pediasure_count

      console.log(this.global_grow_count, ' this.global_grow_count')
      console.log(this.global_similac_count, ' this.global_similac_count')
      console.log(this.global_ensure_count, ' this.global_ensure_count')
      console.log(this.global_glucerna_count, ' this.global_glucerna_count')
      console.log(this.global_pediasure_count, ' this.global_pediasure_count')

      sessionStorage.setItem('grow_nonmilk_remaining_count', JSON.stringify(remain_nonmilk_grow_count));
      sessionStorage.setItem('similac_nonmilk_remaining_count', JSON.stringify(remain_nonmilk_similac_count));

      sessionStorage.setItem('ensure_nonmilk_remaining_count', JSON.stringify(remain_nonmilk_ensure_count));
      sessionStorage.setItem('glucerna_nonmilk_remaining_count', JSON.stringify(remain_nonmilk_glucerna_count));
      sessionStorage.setItem('pediasure_nonmilk_remaining_count', JSON.stringify(remain_nonmilk_pediasure_count));

      // all_nonmilk_count >= 1 ? this.eligible = true : this.eligible = false;
      // grow_nonmilk_count >= 1 ? this.eligible_nonmilk_grow = true : this.eligible_nonmilk_grow = false;
      // similac_nonmilk_count >= 1 ? this.eligible_nonmilk_similac = true : this.eligible_nonmilk_similac = false;

      // ensure_nonmilk_count >= 1 ? this.eligible_nonmilk_ensure = true : this.eligible_nonmilk_ensure = false;
      // glucerna_nonmilk_count >= 1 ? this.eligible_nonmilk_glucerna = true : this.eligible_nonmilk_glucerna = false;
      // pediasure_nonmilk_count >= 1 ? this.eligible_nonmilk_pediasure = true : this.eligible_nonmilk_pediasure = false;

    })

  }


  growOrSimilac() {
    if (this.is_grow && this.eligible_nonmilk_grow) {
      this.modalService.hide();
      this.router.navigate(['/grow-spinwheel']);
    }
    if (this.is_grow && this.is_eligilbe_grow == false) {
      this.router.navigate(['/lucky-draw-quota-exceed']);
    }
    if (this.is_grow == false && this.eligible_nonmilk_similac) {
      this.modalService.hide();
      this.router.navigate(['/similac-spinwheel']);
    }

    if (this.is_ensure && this.eligible_nonmilk_ensure) {
      this.modalService.hide();
      this.router.navigate(['/ensure-spin-wheel']);
    }
    if (this.is_ensure && this.is_eligilbe_ensure == false) {
      this.router.navigate(['/lucky-draw-quota-exceed']);
    }

    if (this.is_glucerna && this.eligible_nonmilk_glucerna) {
      this.modalService.hide();
      this.router.navigate(['/glucerna-spin-wheel']);
    }
    if (this.is_glucerna && this.is_eligilbe_glucerna == false) {
      this.router.navigate(['/lucky-draw-quota-exceed']);
    }

    if (this.is_pediasure && this.eligible_nonmilk_pediasure) {
      this.modalService.hide();
      this.router.navigate(['/pediasure-spinwheel']);
    }
    if (this.is_pediasure && this.is_eligilbe_pediasure == false) {
      this.router.navigate(['/lucky-draw-quota-exceed']);
    }

  }

  openinvalidQrCodeModal() {
    console.log('inside show invalid popup')
    this.invalidQrCodeModal.show()
  }
  invalidQrCodeHide() {
    this.router.navigate(['/user-profile'])
    this.invalidQrCodeModal.hide()
  }
  parseFunction(value) {
    return JSON.parse(value);
  }
  closeSuccessModal() {
    this.successQrCodeModal.hide();
    this.router.navigate(['/user-profile']);
  }

  getProductDescCode() {
    this.content = this.scancodeData?.product?.txt;
    this.subCatCode = this.iSprintData?.sub_category_code;
    this.milkCode = this.iSprintData?.is_milk_code;
    this.milkCode == 0 || this.milkCode == undefined ? this.is_milk = false : this.is_milk = true;
    // if (this.content) {
    //   this.content.forEach((item: any) => {

    //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.subCatCode = parsedPrimaryValue.value;
    //     }

    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;
    //       this.milkCode == 0 || this.milkCode == undefined ? this.is_milk = false : this.is_milk = true;
    //     }
    //     console.log(this.milkCode, 'this.milkCode')
    //   });
    // }
  }

  startVideo() {
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 }
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }

  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
    if (code) {
      let scannedCode = code.data
      this.scannedCode = scannedCode;
      let getAuthKey = sessionStorage.getItem('authToken')
      let traceresp

      this.apiService.getTraceability(scannedCode).subscribe((res: any) => {
           let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(traceresp)
        }
        

        sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
        this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
        if (res.result.code == '0') {
          this.apiService.productInfo(this.scannedCode).subscribe((res)=>{
            console.log(res)
            sessionStorage.setItem('iSprintData', JSON.stringify(res["result"]));
            this.iSprintData =  res["result"];
            this.categoryCode = this.iSprintData.category_code
            console.log("type is",this.iSprintData.type)
      
            if(this.iSprintData.type == 1){
                this.router.navigate(['/home'])
            }
            else if(this.iSprintData.type == 2){
              if (getAuthKey) {
                this.addTransactioninApp();
              } else {
                this.router.navigate(['/sign-up']);
              }
            }
            
      
          },(err)=>{
            console.log(err)
          })
      
        }else{
          this.apiService.showErrorMsg('Please scan a valid QR code.')

        }
      },
      err => {
        this.bsService.notifyError(err, scannedCode);
        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
        }
      )

    



      // this.apiService.getTraceability(scannedCode).subscribe((res: any) => {
      //   traceresp = res;
      //   let myuuid = uuidv4();
      //   let loggerFormData = {
      //     "unique_id": myuuid,
      //     "message": JSON.stringify(traceresp)
      //   }
        

      //   sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
      //   this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
      //   if (res.result.code == '0') {
      //     if (res.result.data.uaidInfo.type == 1 || res.result.data.uaidInfo.type == 7) {
      //       this.router.navigate(['/home']);
      //     } else if (res.result.data.uaidInfo.type == 2) {

      //       if (getAuthKey) {
      //         this.addTransactioninApp();
      //       } else {
      //         this.router.navigate(['/sign-up']);
      //       }

      //     }
      //   }
      //   else {
      //     this.apiService.showErrorMsg('Please scan a valid QR code.')
      //   }
      // }, err => {
      //   this.bsService.notifyError(err, scannedCode);
      //   this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
      //   // console.log(err, 'err========')
      //   // this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
      // })

    }
    else {
      setTimeout(() => { this.checkImage(); }, 100)
    }
  }


  // Add Transaction In-App

  addTransactioninApp() {
    this.apiService.getPosition().then(pos => {
      let lat = pos.lat
      let lang = pos.lng
      let latitude = lat.toString()
      let langitude = lang.toString()
      let getMobile = sessionStorage.getItem('mobileNo')
      let getAuthKey = sessionStorage.getItem('authToken')
      let productName = sessionStorage.getItem('productName');
      let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
      let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
      let EnsureCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
      let GlucernaCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
      let PediasureCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))

      if(this.iSprintData?.is_isprint){
        if (this.categoryCode == 'Similac' || this.categoryCode == 'Grow') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_grow == true ? (GrowCount < 5 ? 'true' : 'true') : (SimilacCount < 5 ? 'true' : 'true')
                // "value": this.is_grow == true ? (GrowCount >=1 ? 'true' : 'false') : (SimilacCount >= 1 ? 'true' : 'false')
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Ensure') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_ensure",
                "value": this.is_ensure ? this.is_ensure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_ensure == true ? (EnsureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Glucerna') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_glucerna",
                "value": this.is_glucerna ? this.is_glucerna : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_glucerna == true ? (GlucernaCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'PediaSure') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_pediasure",
                "value": this.is_pediasure ? this.is_pediasure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_pediasure == true ? (PediasureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }

      }else{
        if (this.categoryCode == 'Similac' || this.categoryCode == 'Grow') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_grow == true ? (GrowCount >= 1 ? 'true' : 'false') : (SimilacCount >= 1 ? 'true' : 'false')
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Ensure') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_ensure",
                "value": this.is_ensure ? this.is_ensure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_ensure == true ? (EnsureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Glucerna') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_glucerna",
                "value": this.is_glucerna ? this.is_glucerna : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_glucerna == true ? (GlucernaCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'PediaSure') {
          this.formDatas = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": latitude
              },
              {
                "name": "TransLong",
                "value": langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_pediasure",
                "value": this.is_pediasure ? this.is_pediasure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_pediasure == true ? (PediasureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }

      }
      
      // this.spinner.show();
      let addtranresp;

      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(this.formDatas)
      }
      this.spinner.show();
      this.apiService.postGpassEarnPoints(this.formDatas, getAuthKey).pipe(first())
        .subscribe((res: any) => {
          console.log(res,"addTransactioninApp function")
          this.spinner.hide();
          addtranresp = res;
          if (res?.result?.response?.status?.code == 200) {
            let transData = res?.result?.response?.transactions?.transaction[0].side_effects?.effect
            if (transData[0]?.discount_code == "true") {
              sessionStorage.setItem('firsttimegift', 'true')
              this.router.navigate(['/signup-success'])

            }
            console.log(transData, 'add trans resp 1')
          }

          this.spinner.hide();
          this.getNonMilkCount();
          this.showScanner = false;
          this.toggleVideoMedia();
          // this.getCustomer();
          if (this.milkCode == '1') {
            this.openMilkCodeModal();
          }
          if (this.milkCode == '0') {
            let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
            let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))

            let EnsureCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
            let GlucernaCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
            let PediasureCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))

            if (this.is_ensure) {
              if (this.global_ensure_Month_count <= 5) {
                this.successQrCodeModal.show();
              }
              else {
                this.router.navigate(['/lucky-draw-quota-exceed']);
              }
            }
            else if (this.is_glucerna) {
              if (this.global_glucerna_Month_count <= 5) {
                this.successQrCodeModal.show();
              }
              else {
                this.router.navigate(['/lucky-draw-quota-exceed']);
              }
            }
            else if (this.is_pediasure) {
              if (this.global_pediasure_Month_count <= 5) {
                this.successQrCodeModal.show();
              }
              else {
                this.router.navigate(['/lucky-draw-quota-exceed']);
              }
            }
            else if (this.is_grow) {
              if (this.global_grow_Month_count <= 5) {
                this.successQrCodeModal.show();
              }
              else {
                this.router.navigate(['/lucky-draw-quota-exceed']);
              }
            }
            else {
              if (this.global_similac_Month_count <= 5) {
                this.successQrCodeModal.show();
              }
              else {
                this.router.navigate(['/lucky-draw-quota-exceed']);
              }
            }
          }
        }, err => {
          if (err.error.code == 400) {
            console.log('duplicate code')
            //this.opentryagainMdl();
            this.openinvalidQrCodeModal()
          } else {
            console.log('try again')
            this.tryagaiinAddTransModal.show();
          }
          this.bsService.notifyError(err, this.formDatas);
          //this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          this.showScanner = false;
          // this.spinner.hide();
          // this.apiService.showToaster(err);
          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          console.log('err', 'Đã xảy ra lỗi. Vui lòng thử lại sau');
          if (err.error.message == "Given code already verified") {
            this.openinvalidQrCodeModal()
          } else if (err.error.message == "Invalid scanned code" || err == "Wrong scanned code") {
            // this.qrCode1Modal.show();
          }

          this.toggleVideoMedia();
        })
    })
  }

  openMilkCodeModal() {
    this.milkCodeModal.show()
  }
  milkCodeHide() {
    this.milkCodeModal.hide();
    this.is_grow ? sessionStorage.setItem('activeGrow', 'activeGrow') : sessionStorage.setItem('activeSimilac', 'activeSimilac');
    if (this.is_ensure) {
      sessionStorage.setItem('activeEnsure', 'activeEnsure')
    } else if (this.is_glucerna) {
      sessionStorage.setItem('activeGlucerna', 'activeGlucerna')
    } else if (this.is_pediasure) {
      sessionStorage.setItem('activePediasure', 'activePediasure')
    }
    this.router.navigate(['library']);
    // this.router.navigate(['user-profile']);
  }

  passwordInput(ev) {
    if (ev.target.value == '') {
      this.passwordError = false;
    }
  }

  opensimilacTerms() {
    this.termsSimilac.show()
  }
  termsSimilacHide() {
    this.termsSimilac.hide()
  }
  openSimilacTermsandConditions() {
    this.opensimilacTerms();
  }
  ensuretermsContent: any;
  glucernatermsContent: any;
  pediasureltermsContent: any;
  similacTerms() {
    this.apiService.similacTermsandConditions().subscribe((res: any) => {
      res.data.forEach(element => {
        if (element.contentkey == 'Terms_and_conditions_Similac') {
          this.termsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Grow') {
          this.growtermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Gold') {
          this.ensuretermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Glucerna') {
          this.glucernatermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_PediaSure') {
          this.pediasureltermsContent = element.content;
        }

      })
    })
  }


  onClickSaveInfoOne(i) {
    this.addConditionalValidators();
    this.formIndex = i;
    console.log(this.formIndex, 'this.formIndex---')
    this.saveInfo = false;
    this.hideAddMoreMember = true;
    this.uploadedImage = null;
  }
  backBtnOne() {
    this.saveInfo = true;
    this.hideAddMoreMember = false;
    this.submitted = false;
  }

  onClickSaveInfoTwo() {
    this.saveInfo = false;
    this.hideAddMoreMember2 = true;
  }
  backBtnTwo() {
    this.saveInfo = true;
    this.hideAddMoreMember2 = false;
    this.submittedForm2 = false;
  }

  onClickSaveInfoThree() {
    this.saveInfo = false;
    this.hideAddMoreMember3 = true;
  }
  backBtnThree() {
    this.saveInfo = true;
    this.hideAddMoreMember3 = false;
    this.submittedForm3 = false;
  }

  onClickSaveInfoFour() {
    this.saveInfo = false;
    this.hideAddMoreMember4 = true;
  }
  backBtnFour() {
    this.saveInfo = true;
    this.hideAddMoreMember4 = false;
    this.submittedForm4 = false;
  }

  //-------------image upload functions------------------------

  imageCropped(event: ImageCroppedEvent) {
    console.log(event, 'cropped')
    this.croppedImage = event.base64;
    this.fileInput = base64ToFile(this.croppedImage);

  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log(this.imageChangedEvent, 'this.imageChangedEvent')
    console.log(event.target.files.length, 'event.target.files.length--')
    if (event.target.files.length > 0) {
      this.visible = true;
      console.log(this.visible, 'inside if')
      this.fileupload = event.target.files[0]
    }
  }

  count = 0;
  uploadedImage: any = null;
  uploadImage() {
    this.count++;
    this.visible = false
    let getMobile = sessionStorage.getItem('mobileNo')
    let getAuthKey = sessionStorage.getItem('authToken')

    const imageData = new FormData();

    imageData.append('image', this.fileInput)
    imageData.append('mobile', getMobile)
    imageData.append('cap_device_id', env.DEVICE_ID)

    if (this.formIndex == 0) {
      imageData.append('image_custom_field', 'baby1_profileimage')
    }
    if (this.formIndex == 1) {
      imageData.append('image_custom_field', 'baby2_profileimage')
    }
    if (this.formIndex == 2) {
      imageData.append('image_custom_field', 'baby3_profileimage')
    }
    if (this.formIndex == 3) {
      imageData.append('image_custom_field', 'baby4_profileimage')
    }
    let myuuid = uuidv4();
    let loggerFormData = {
      "unique_id": myuuid,
      "message": JSON.stringify(imageData)
    }
    this.spinner.show();
    this.apiService.customerImageUpload(imageData, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }

        this.spinner.hide();
        console.log(res, 'image upload')
        let uniqueId = res.result.image_id
        let extension = res.result.image_extension
        this.apiService.getSavedImage(getMobile, getAuthKey, uniqueId, extension).pipe(first())
          .subscribe((res: any) => {
            console.log(res.file_path, 'image get')
            // this.finalUserImage = res.file_path;
            this.uploadedImage = res.file_path;
            this.visible = false;
            console.log(this.visible, 'visible result in final upload fn.')
            console.log(this.uploadedImage, 'this.uploadedImage')
          }, err => {
            if (err) {
              this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
              this.spinner.hide();
            }
          })
      }, err => {
        this.bsService.notifyError(err);
        if (err) {
          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          this.spinner.hide();
        }
      })
  }

  imageLoaded() {
    console.log(this.count, 'this.count img load')
    if (this.count < 1) {
      this.showCropper = true;
      this.visible = true
      console.log('Image loaded');
    }
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }
  show() {
    this.visible = true
  }

  customerData: any;


  //---------------on add child pop-up load the image and child name------------------

  showChildList(userInfo, profileImage) {
    console.log(userInfo, 'userInfo')
    let childArray = [];

    //-----------------store child Image -----------------

    let child1_image = userInfo.filter(res => res.name == 'baby1_profileimage');
    child1_image = child1_image.length > 0 ? child1_image[0].value : '';
    console.log(child1_image, 'child1_image')

    let child2_image = userInfo.filter(res => res.name == 'baby2_profileimage');
    child2_image = child2_image.length > 0 ? child2_image[0].value : '';
    console.log(child2_image, 'child2_image')

    let child3_image = userInfo.filter(res => res.name == 'baby3_profileimage');
    child3_image = child3_image.length > 0 ? child3_image[0].value : ''
    console.log(child3_image, 'child3_image')

    let child4_image = userInfo.filter(res => res.name == 'baby4_profileimage');
    child4_image = child4_image.length > 0 ? child4_image[0].value : '';
    console.log(child4_image, 'child4_image')

    //  babys_surname_1
    //  babys_age_1
    //  date_of_birth_1
    let babySurname1 = userInfo.filter(res => res.name == 'babys_surname_1');
    babySurname1 = babySurname1[0]?.value;
    let babySurname2 = userInfo.filter(res => res.name == 'babys_surname_2');
    babySurname2 = babySurname2[0]?.value;
    let babySurname3 = userInfo.filter(res => res.name == 'babys_surname_3');
    babySurname3 = babySurname3[0]?.value;
    let babySurname4 = userInfo.filter(res => res.name == 'babys_surname_4');
    babySurname4 = babySurname4[0]?.value;

    let babyAge1 = userInfo.filter(res => res.name == 'babys_age_1');
    babyAge1 = babyAge1[0]?.value;
    let babyAge2 = userInfo.filter(res => res.name == 'babys_age_2');
    babyAge2 = babyAge2[0]?.value;
    let babyAge3 = userInfo.filter(res => res.name == 'babys_age_3');
    babyAge3 = babyAge3[0]?.value;
    let babyAge4 = userInfo.filter(res => res.name == 'babys_age_4');
    babyAge4 = babyAge4[0]?.value;

    let babyDOB1 = userInfo.filter(res => res.name == 'date_of_birth_1');
    babyDOB1 = babyDOB1[0]?.value;
    let babyDOB2 = userInfo.filter(res => res.name == 'date_of_birth_2');
    babyDOB2 = babyDOB2[0]?.value;
    let babyDOB3 = userInfo.filter(res => res.name == 'date_of_birth_3');
    babyDOB3 = babyDOB3[0]?.value;
    let babyDOB4 = userInfo.filter(res => res.name == 'date_of_birth_4');
    babyDOB4 = babyDOB4[0]?.value;

    //-------------------create child Array--------------------------------

    userInfo.filter(val => {
      if (val.name == 'babys_name_1' && val.value != "") {
        this.globalChildArray.push({ name: val.value, child: 'present', child_image: child1_image, surname: babySurname1, age: babyAge1, dob: babyDOB1 })
        childArray.push(val)
      }
      if (val.name == 'babys_name_2' && val.value != "") {
        this.globalChildArray.push({ name: val.value, child: 'present', child_image: child2_image, surname: babySurname2, age: babyAge2, dob: babyDOB2 })
        childArray.push(val)
      }
      if (val.name == 'babys_name_3' && val.value != "") {
        this.globalChildArray.push({ name: val.value, child: 'present', child_image: child3_image, surname: babySurname3, age: babyAge3, dob: babyDOB3 })
        childArray.push(val)
      }
      if (val.name == 'babys_name_4' && val.value != "") {
        this.globalChildArray.push({ name: val.value, child: 'present', child_image: child4_image, surname: babySurname4, age: babyAge4, dob: babyDOB4 })
        childArray.push(val)
      }
    })
    let noChildCount = 4 - childArray.length
    console.log(childArray, 'childArray')
    for (let i = 0; i < noChildCount; i++) {
      console.log(i, 'iii---')
      this.globalChildArray.push({ name: '', child: 'absent' })
    }
    console.log(this.globalChildArray, 'this.globalChildArray')

    let getMobile = sessionStorage.getItem('mobileNo')
    let getAuthKey = sessionStorage.getItem('authToken')

    if (!this.isRedirectToSignup) {
      this.spinner.show();
      this.globalChildArray.forEach((ele, i) => {
        this.apiService.getSavedImageFinal(getMobile, getAuthKey, ele.child_image).pipe(first())
          .subscribe((res: any) => {
            console.log(res, ":res")
            if (res.code == 200) {
              this.spinner.hide();
              console.log(res.file_path, 'res.file_path---')
              ele.display_childImage = res.file_path;
              this.visible = false;
            } else {
              this.spinner.hide();
              console.log("res.code: ", res.code)
            }

          }, err => {
            this.bsService.notifyError(err);
            this.spinner.hide();
            this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
            console.log(err)
          })

      })

      console.log(this.globalChildArray, 'after image load')
    }



    return 1;
  }


  clickEvent(i, child) {
    console.log(child, 'child')
    console.log(i, 'i')
    this.enableAdd = true;
    console.log(this.enableAdd, 'this.enableAdd--')
    this.status = [];
    this.selectedChild = child;
    this.status[i] = !this.status[i];
  }

  // removeActive(){
  //   var element1 = document.getElementById("ImgPresent");
  //   var element2 = document.getElementById("noImg");
  //   element1.classList.remove("active");
  //   element2.classList.remove("active");
  // }


  //----------------add transaction for child------------------
  formData1: any;
  addTranscationForChild() {
    console.log(this.selectedChild, 'this.selectedChild')
    if (!this.selectedChild) {
      this.selectedChild = this.selectedProfile
    }
    this.selectedChild = !this.selectedChild ? this.selectedProfile : this.selectedChild
    console.log(this.selectedChild)
    this.apiService.getPosition().then(pos => {
      let lat = pos.lat
      let lang = pos.lng
      let confidence = pos.accuracy;
      this.latitude = lat.toString()
      this.langitude = lang.toString()
      this.confidence = confidence?.toString()
      let getMobile = sessionStorage.getItem('mobileNo')
      let getAuthKey = sessionStorage.getItem('authToken')
      let productName = sessionStorage.getItem('productName');
      let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
      let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
      let EnsureCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
      let GlucernaCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
      let PediasureCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))


      if(this.iSprintData?.is_isprint){
        if (this.categoryCode == 'Similac' || this.categoryCode == 'Grow') {
          this.formData1 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_grow == true ? (GrowCount >= 1 ? 'true' : 'false') : (SimilacCount >= 1 ? 'true' : 'false')
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": this.selectedChild?.name
              }
  
            ],
            "extended_fields": { // tracking child 1 milk code
              "field": [
                {
                  "name": 'serial_number',
                  "value": this.selectedChild?.age == undefined ? "" : this.selectedChild?.age
                },
                {
                  "name": 'discount-description',
                  "value": this.selectedChild?.surname
                },
                {
                  "name": 'size',
                  "value": this.selectedChild?.dob
                }
              ]
            }
          }
        } else if (this.categoryCode == 'Ensure') {
          this.formData1 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_ensure",
                "value": this.is_ensure ? this.is_ensure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_ensure == true ? (EnsureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": this.selectedChild?.name
              }
  
            ],
            "extended_fields": { // tracking child 1 milk code
              "field": [
                {
                  "name": 'serial_number',
                  "value": this.selectedChild?.age == undefined ? "" : this.selectedChild?.age
                },
                {
                  "name": 'discount-description',
                  "value": this.selectedChild?.surname
                },
                {
                  "name": 'size',
                  "value": this.selectedChild?.dob
                }
              ]
            }
          }
        }
        else if (this.categoryCode == 'Glucerna') {
          this.formData1 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_glucerna",
                "value": this.is_glucerna ? this.is_glucerna : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_glucerna == true ? (GlucernaCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": this.selectedChild?.name
              }
  
            ],
            "extended_fields": { // tracking child 1 milk code
              "field": [
                {
                  "name": 'serial_number',
                  "value": this.selectedChild?.age == undefined ? "" : this.selectedChild?.age
                },
                {
                  "name": 'discount-description',
                  "value": this.selectedChild?.surname
                },
                {
                  "name": 'size',
                  "value": this.selectedChild?.dob
                }
              ]
            }
          }
        }
        else if (this.categoryCode == 'PediaSure') {
          this.formData1 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_pediasure",
                "value": this.is_pediasure ? this.is_pediasure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_pediasure == true ? (PediasureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": this.selectedChild?.name
              }
  
            ],
            "extended_fields": { // tracking child 1 milk code
              "field": [
                {
                  "name": 'serial_number',
                  "value": this.selectedChild?.age == undefined ? "" : this.selectedChild?.age
                },
                {
                  "name": 'discount-description',
                  "value": this.selectedChild?.surname
                },
                {
                  "name": 'size',
                  "value": this.selectedChild?.dob
                }
              ]
            }
          }
        }
      }else{
        if (this.categoryCode == 'Similac' || this.categoryCode == 'Grow') {
          this.formData1 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_grow == true ? (GrowCount >= 1 ? 'true' : 'false') : (SimilacCount >= 1 ? 'true' : 'false')
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Ensure') {
          this.formData1 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_ensure",
                "value": this.is_ensure ? this.is_ensure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_ensure == true ? (EnsureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Glucerna') {
          this.formData1 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_glucerna",
                "value": this.is_glucerna ? this.is_glucerna : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_glucerna == true ? (GlucernaCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'PediaSure') {
          this.formData1 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_pediasure",
                "value": this.is_pediasure ? this.is_pediasure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_pediasure == true ? (PediasureCount >=1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }

      }
      

      console.log(this.formData1, 'formData--')
      let transresp;
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(this.formData1)
      }


      this.spinner.show();
      this.apiService.postGpassEarnPoints(this.formData1, getAuthKey).pipe(first())
        .subscribe((res: any) => {
          this.spinner.hide();
          transresp = res;
          console.log(res, "addTranscationForChild function");
          // let myuuid = uuidv4();
          // let loggerFormData = {
          //   "unique_id": myuuid,
          //   "message": JSON.stringify(res)
          // }
          if (res?.result?.response?.status?.code == 200) {
            let transData = res?.result?.response?.transactions?.transaction[0].side_effects?.effect
            if (transData[0]?.discount_code == "true") {
              sessionStorage.setItem('firsttimegift', 'true')
              this.router.navigate(['/signup-success'])

            }
            console.log(transData, 'add trans resp 1')
          }
          this.spinner.hide();
          this.addMoreMembersHide();
          this.getNonMilkCount();
          if (this.milkCode == '1') {
            this.addMoreMembersHide();
            this.openMilkCodeModal()
          }

          if (this.milkCode == '0') {
            setTimeout(() => {
              this.spinner.show();
              let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
              let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
              this.SimilacTotalCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
              this.GrowTotalCount = JSON.parse(sessionStorage.getItem('growMilkCount'))

              let EnsureCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
              let GlucernaCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
              let PediasureCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))
              this.EnsureTotalCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
              this.GlucernaTotalCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
              this.PediasureTotalCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))

              console.log(SimilacCount, 'SimilacCount')
              console.log(GrowCount, 'GrowCount')
              console.log(this.SimilacTotalCount, 'this.SimilacTotalCount')
              console.log(this.GrowTotalCount, 'this.GrowTotalCount')
              console.log(EnsureCount, 'EnsureCount')
              console.log(GlucernaCount, 'GlucernaCount')
              console.log(PediasureCount, 'PediasureCount')
              console.log(this.EnsureTotalCount, 'this.EnsureTotalCount')
              console.log(this.GlucernaTotalCount, 'this.GlucernaTotalCount')
              console.log(this.PediasureTotalCount, 'this.PediasureTotalCount')

              if (this.is_ensure) {
                if (this.global_ensure_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else if (this.is_glucerna) {
                if (this.global_glucerna_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else if (this.is_pediasure) {
                if (this.global_pediasure_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else if (this.is_grow) {
                if (this.global_grow_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else {
                if (this.global_similac_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                  console.log(this.global_similac_Month_count, 'SimilacCount')
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                  console.log(this.global_similac_Month_count, 'SimilacCount')
                }
              }
              let getAuthKey = sessionStorage.getItem('authToken')
              this.spinner.show();
              let myuuid = uuidv4();
              let loggerFormData = {
                "unique_id": myuuid,
                "message": JSON.stringify(this.mobile_no)
              }
              let getcustresp;
              this.apiService.getCapCustomer(this.mobile_no, getAuthKey).pipe(first())
                .subscribe(res => {
                  this.spinner.hide();
                  let myuuid = uuidv4();
                  let loggerFormData = {
                    "unique_id": myuuid,
                    "message": JSON.stringify(res)
                  }
                  getcustresp = res;
                  console.log('get customer 4 ===========')
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    let custMilkCode = res['customers']['customer'][0]['custom_fields']['field']
                    custMilkCode.forEach((item: any) => {
                      // if(this.milkCode == '0'){
                      if (item.name == "milk_code") {
                        if (item.value == '1') {
                          let registerForm = {
                            "root": {
                              "customer": [
                                {
                                  "mobile": this.mobile_no,
                                  "custom_fields": {
                                    "field": [
                                      {
                                        "name": "milk_code",
                                        "value": '0'
                                      },
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                          let myuuid = uuidv4();
                          let loggerFormData = {
                            "unique_id": myuuid,
                            "message": JSON.stringify(registerForm)
                          }
                          this.spinner.show();
                          let updatecustresp;
                          this.apiService.updateCapCustomer(registerForm, this.mobile_no, getAuthKey).pipe(first())
                            .subscribe(res => {
                              updatecustresp = res;
                              let myuuid = uuidv4();
                              let loggerFormData = {
                                "unique_id": myuuid,
                                "message": JSON.stringify(res)
                              }
                              this.spinner.hide();
                              if (res['status'].code == 200) {
                                this.addMoreMembersHide();
                                if (this.is_ensure) {
                                  if (this.global_ensure_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else if (this.is_glucerna) {
                                  if (this.global_glucerna_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else if (this.is_pediasure) {
                                  if (this.global_pediasure_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else if (this.is_grow) {
                                  if (this.global_grow_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else {
                                  if (this.global_similac_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                    console.log(this.global_similac_Month_count, 'SimilacCount')
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                    console.log(this.global_similac_Month_count, 'SimilacCount')
                                  }
                                }
                              }
                              else {
                                console.log(res['status'].message)
                              }
                            }, err => {
                              this.bsService.notifyError(err, registerForm);
                              this.spinner.hide()
                              this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                              console.log(err, '=== get customer error =======')
                            });
                        }
                      } else {
                        if (this.is_ensure) {
                          if (this.global_ensure_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else if (this.is_glucerna) {
                          if (this.global_glucerna_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else if (this.is_pediasure) {
                          if (this.global_pediasure_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else if (this.is_grow) {
                          if (this.global_grow_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else {
                          if (this.global_similac_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                            console.log(this.global_similac_Month_count, 'SimilacCount')
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                            console.log(this.global_similac_Month_count, 'SimilacCount')
                          }
                        }

                      }
                      //}
                    })
                  }
                  else if (res['status'].code == 500 || res['customers']['customer'][0].item_status.code == '1012') {
                    console.log('this route 1 a ====')
                    this.isRedirectToSignup = true
                    this.router.navigateByUrl('/crm-signup')

                  }

                }, err => {
                  this.bsService.notifyError(err);
                  this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                  this.spinner.hide();
                })
              this.spinner.hide();
            }, 2000);

          }




        }, err => {
          if (err.error.code == 400) {
            console.log('duplicate code')
            //this.opentryagainMdl();
            this.openinvalidQrCodeModal()
          } else {
            console.log('try again')
            this.tryagaiinChildAddTransModal.show();
          }
          this.bsService.notifyError(err, this.formData1);
          //this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          this.spinner.hide();
          this.addMoreMembersHide();
          //this.openinvalidQrCodeModal()
          console.log(err, '=== get customer error =======')
        })
    }, err => {
      this.bsService.notifyError(err);
    })

  }


  formData2: any;
  addTransactionForSMOM() {
    console.log('inside update smom--')
    this.apiService.getPosition().then(pos => {
      let lat = pos.lat
      let lang = pos.lng
      this.latitude = lat.toString()
      this.langitude = lang.toString()
      let getMobile = sessionStorage.getItem('mobileNo')
      let getAuthKey = sessionStorage.getItem('authToken')
      let productName = sessionStorage.getItem('productName');
      let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
      let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
      let EnsureCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
      let GlucernaCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
      let PediasureCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))

      if(this.iSprintData?.is_isprint){
        if (this.categoryCode == 'Similac' || this.categoryCode == 'Grow') {
          this.formData2 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
               // "value": this.is_grow == true ? (GrowCount >=1 ? 'true' : 'false') : (SimilacCount >= 1 ? 'true' : 'false')
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": ""
              }
  
            ]
          }
        } else if (this.categoryCode == 'Ensure') {
          this.formData2 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_ensure",
                "value": this.is_ensure ? this.is_ensure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_ensure == true ? (EnsureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": ""
              }
  
            ]
          }
        } else if (this.categoryCode == 'Glucerna') {
          this.formData2 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
  
              {
                "name": "is_glucerna",
                "value": this.is_glucerna ? this.is_glucerna : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_glucerna == true ? (GlucernaCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": ""
              }
  
            ]
          }
        } else if (this.categoryCode == 'PediaSure') {
          this.formData2 = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
  
              {
                "name": "is_pediasure",
                "value": this.is_pediasure ? this.is_pediasure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_pediasure == true ? (PediasureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              {
                "name": "child_info",
                "value": ""
              }
  
            ]
          }
        }
      }else{
        if (this.categoryCode == 'Similac' || this.categoryCode == 'Grow') {
          this.formData2 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_grow == true ? (GrowCount >= 1 ? 'true' : 'false') : (SimilacCount >= 1 ? 'true' : 'false')
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Ensure') {
          this.formData2 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_ensure",
                "value": this.is_ensure ? this.is_ensure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_ensure == true ? (EnsureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Glucerna') {
          this.formData2 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_glucerna",
                "value": this.is_glucerna ? this.is_glucerna : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_glucerna == true ? (GlucernaCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'PediaSure') {
          this.formData2 = {
            "scan_code": this.scannedCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_pediasure",
                "value": this.is_pediasure ? this.is_pediasure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_pediasure == true ? (PediasureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }

      }
      

      console.log(this.formData2, 'this.formData2--')
      this.spinner.show();
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(this.formData2)
      }
      let addtransresp;
      this.apiService.postGpassEarnPoints(this.formData2, getAuthKey).pipe(first())
        .subscribe((res: any) => {
          this.spinner.hide();
          addtransresp = res;
          console.log(res,"addTranscationForChild function");
          if (res?.result?.response?.status?.code == 200) {
            let transData = res?.result?.response?.transactions?.transaction[0].side_effects?.effect
            if (transData[0]?.discount_code == "true") {
              sessionStorage.setItem('firsttimegift', 'true')
              this.router.navigate(['/signup-success'])
            }
            console.log(transData, 'add trans resp 1')
          }
          this.spinner.hide();
          this.getNonMilkCount();
          if (this.milkCode == '1') {
            this.openMilkCodeModal()
          }

          if (this.milkCode == '0') {
            setTimeout(() => {
              this.spinner.show();
              let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
              let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
              this.SimilacTotalCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
              this.GrowTotalCount = JSON.parse(sessionStorage.getItem('growMilkCount'))

              let EnsureCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
              let GlucernaCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
              let PediasureCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))
              this.EnsureTotalCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
              this.GlucernaTotalCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
              this.PediasureTotalCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))

              console.log(SimilacCount, 'SimilacCount')
              console.log(GrowCount, 'GrowCount')
              console.log(this.SimilacTotalCount, 'this.SimilacTotalCount')
              console.log(this.GrowTotalCount, 'this.GrowTotalCount')
              console.log(EnsureCount, 'EnsureCount')
              console.log(GlucernaCount, 'GlucernaCount')
              console.log(PediasureCount, 'PediasureCount')
              console.log(this.EnsureTotalCount, 'this.EnsureTotalCount')
              console.log(this.GlucernaTotalCount, 'this.GlucernaTotalCount')
              console.log(this.PediasureTotalCount, 'this.PediasureTotalCount')

              if (this.is_ensure) {
                if (this.global_ensure_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else if (this.is_glucerna) {
                if (this.global_glucerna_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else if (this.is_pediasure) {
                if (this.global_pediasure_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else if (this.is_grow) {
                if (this.global_grow_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                }
              }
              else {
                if (this.global_similac_Month_count <= 5) {
                  this.addMoreMembersHide();
                  this.successQrCodeModal.show();
                  console.log(this.global_similac_Month_count, 'SimilacCount')
                }
                else {
                  this.router.navigate(['/lucky-draw-quota-exceed']);
                  console.log(this.global_similac_Month_count, 'SimilacCount')
                }
              }

              let getAuthKey = sessionStorage.getItem('authToken')
              this.spinner.show();
              let getcustresp;
              this.apiService.getCapCustomer(this.mobile_no, getAuthKey).pipe(first())
                .subscribe(res => {
                  this.spinner.hide();
                  getcustresp = res;
                  let myuuid = uuidv4();
                  let loggerFormData = {
                    "unique_id": myuuid,
                    "message": JSON.stringify(res)
                  }
                  console.log('get customer 6 ===========')
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    let custMilkCode = res['customers']['customer'][0]['custom_fields']['field']
                    custMilkCode.forEach((item: any) => {
                      // if(this.milkCode == '0'){
                      if (item.name == "milk_code") {
                        if (item.value == '1') {
                          let registerForm = {
                            "root": {
                              "customer": [
                                {
                                  "mobile": this.mobile_no,
                                  "custom_fields": {
                                    "field": [
                                      {
                                        "name": "milk_code",
                                        "value": '0'
                                      },
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                          this.spinner.show();
                          let myuuid = uuidv4();
                          let loggerFormData = {
                            "unique_id": myuuid,
                            "message": JSON.stringify(registerForm)
                          }
                          let updatecustresp;
                          this.apiService.updateCapCustomer(registerForm, this.mobile_no, getAuthKey).pipe(first())
                            .subscribe(res => {
                              updatecustresp = res;
                              let myuuid = uuidv4();
                              let loggerFormData = {
                                "unique_id": myuuid,
                                "message": JSON.stringify(res)
                              }
                              this.spinner.hide();
                              if (res['status'].code == 200) {
                                if (this.is_ensure) {
                                  if (this.global_ensure_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else if (this.is_glucerna) {
                                  if (this.global_glucerna_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else if (this.is_pediasure) {
                                  if (this.global_pediasure_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else if (this.is_grow) {
                                  if (this.global_grow_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                  }
                                }
                                else {
                                  if (this.global_similac_Month_count <= 5) {
                                    this.addMoreMembersHide();
                                    this.successQrCodeModal.show();
                                    console.log(this.global_similac_Month_count, 'SimilacCount')
                                  }
                                  else {
                                    this.router.navigate(['/lucky-draw-quota-exceed']);
                                    console.log(this.global_similac_Month_count, 'SimilacCount')
                                  }
                                }
                              }
                              else {
                                console.log(res['status'].message)
                                //this.toastr.info(res['status'].message)
                              }
                            }, err => {
                              this.bsService.notifyError(err, registerForm);
                              this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                              this.spinner.hide()
                              console.log(err, '=== get customer error =======')
                            });
                        }
                      } else {
                        if (this.is_ensure) {
                          if (this.global_ensure_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else if (this.is_glucerna) {
                          if (this.global_glucerna_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else if (this.is_pediasure) {
                          if (this.global_pediasure_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else if (this.is_grow) {
                          if (this.global_grow_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                          }
                        }
                        else {
                          if (this.global_similac_Month_count <= 5) {
                            this.addMoreMembersHide();
                            this.successQrCodeModal.show();
                            console.log(this.global_similac_Month_count, 'SimilacCount')
                          }
                          else {
                            this.router.navigate(['/lucky-draw-quota-exceed']);
                            console.log(this.global_similac_Month_count, 'SimilacCount')
                          }
                        }

                      }
                      //}
                    })
                  }
                  else if (res['status'].code == 500 || res['customers']['customer'][0].item_status.code == '1012') {
                    console.log('this route 1 a ====')
                    this.isRedirectToSignup = true
                    this.router.navigateByUrl('/crm-signup')

                  }

                }, err => {
                  this.bsService.notifyError(err);
                  this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                  this.spinner.hide();
                })
              this.spinner.hide();
            }, 2000);

          }

        }, err => {
          if (err.error.code == 400) {
            console.log('duplicate code')
            //this.opentryagainMdl();
            this.openinvalidQrCodeModal()
          } else {
            console.log('try again')
            this.tryagainSmomModal.show();
          }
          this.bsService.notifyError(err, this.formData2);
          // this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
          this.spinner.hide();
          //this.openinvalidQrCodeModal()
          console.log(err, '=== get customer error =======')
        })
    })


  }

  showPassword() {
    this.show1 = !this.show1;
  }

  rememberUser(value) {
    console.log(value, 'rem val')
    this.apiService.setRememberUser(value);
    let mob = this.signInForm.value ? this.signInForm.value.mobile_no : '';
    let password = this.signInForm.value ? this.signInForm.value.password : '';
    sessionStorage.setItem('rememval', value)
    //sessionStorage.setItem('mobileNumber', mob);
    sessionStorage.setItem('passwd', password);
  }


  isPregnent: boolean = true;
  momtype(event) {
    console.log(event.target.value, 'momtype')
    if (event.target.value == 'Tôi đang mang thai') {
      this.isPregnent = true;
    } else if (event.target.value == 'Tôi đã có con') {
      this.isPregnent = false;
    }
  }

}
