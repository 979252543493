<app-header></app-header>
<section class="userProfileOut">
    <p class="return"><img class="backImg" src="../../../assets/images/orangearrowback.png" (click)="goBack()"> {{
        'USER_PROFILE_DETAIL.TITLE' | translate }}</p>
    <h2 class="TitleInfo text-center mb30">{{ 'USER_PROFILE_DETAIL.TITLE1' | translate }}</h2>
    <div class="profileContent" *ngIf="hideProfileContent && custDetail">
        <div class="row align-items-top">
            <div class="col-4">
                <i class="profilePic" *ngIf="!parentUserImage"></i>
                <i class="profilePicNew" *ngIf="parentUserImage"><img [src]="parentUserImage" alt="profile bg" /></i>
            </div>
            <!-- THESE DETAILS ARE DYNAMIC SO NOT ADDING IN TRANSLATION FILE -->
            <div class="col-8">
                <div class="profileDetails">
                    <h6>{{custDetail?.firstname + ' ' + custDetail?.lastname}}</h6>
                    <p>{{custDetail?.mobile}}</p>
                    <p>{{custDetail?.email}}</p>
                    <p>{{address}}</p>
                    <button class="blueBtn mt-3 btn-block" (click)="onClickEditProfile()">{{
                        'USER_PROFILE_DETAIL.EDIT_CHILD' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="editUserProfile" *ngIf="showEditProfile">
        <a class="backBtn" (click)="closeEditProfile()">
            <img src="../../../assets/images/back.png" alt="back" />
            <span>{{ 'USER_EDIT_PROFILE.RETURN' | translate }}</span>
        </a>
        <i class="profilePic">
            <!-- <img src="../../../assets/images/profile_bg.png" alt="profile picture" /> -->

            <!-- <i *ngIf="!finalUserImage"><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i>
            <i *ngIf="finalUserImage"><img [src]="finalUserImage" alt="profile bg" /></i> -->

            <i *ngIf="!parentUserImage"></i>
            <i *ngIf="parentUserImage"><img [src]="parentUserImage" alt="profile bg" /></i>
        </i>
        <div class="text-center">
            <a class="blueBtn">{{ 'USER_EDIT_PROFILE.CHANGE_AVATAR' | translate }}</a>

            <form>
                <div class="imageUploadWrap">
                    <label class="imageUploadLabel edit" for="upload">
                        <input id="upload" type="file" name="profile" (change)="fileChangeEvent($event)" />
                    </label>
                    <ngx-popup [(ngModel)]="visible" [ngModelOptions]="{standalone: true}">
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                            [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="3 / 3"
                            [resizeToWidth]="256" [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                            [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                            [style.display]="showCropper ? null : 'none'" format="png"
                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
                        <button class="uploadImageBtn" (click)="uploadImage()">upload</button>
                    </ngx-popup>
                </div>
            </form>
        </div>
        <form [formGroup]="editProfileForm" (ngSubmit)="onSubmitEditProfileForm()">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="surName"
                            [ngClass]="{'is-invalid': submitted && editFormVal.surName.errors}" required />
                        <label>{{ 'USER_EDIT_PROFILE.SURNAME' | translate }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="name"
                            [ngClass]="{'is-invalid': submitted && editFormVal.name.errors}" required />
                        <label>{{ 'USER_EDIT_PROFILE.NAME' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="phoneNumber"
                            [ngClass]="{'is-invalid': submitted && editFormVal.phoneNumber.errors}" required disabled/>
                        <label>{{ 'USER_EDIT_PROFILE.PHONE_NUMBER' | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input type="email" class="form-control" formControlName="email"
                            [ngClass]="{'is-invalid': submitted && editFormVal.email.errors}" required />
                        <label>{{ 'USER_EDIT_PROFILE.EMAIL' | translate }}</label>
                    </div>
                </div>
            </div>
            <h6>{{ 'USER_EDIT_PROFILE.ADDRESS' | translate }}</h6>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <select class="form-control" placement="bottom" (change)="districtsChange($event)"
                            formControlName="city" [ngClass]="{'is-invalid': submitted && editFormVal.city.errors}">
                            <option value="" disabled selected>Tỉnh/ Thành phố</option>
                            <option *ngFor="let cities of cityData" [value]="cities.display">{{ cities.display }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <select class="form-control" placement="bottom" formControlName="district"
                            [ngClass]="{'is-invalid': submitted && editFormVal.district.errors}">
                            <option value="" disabled selected>Quận/ Huyện</option>
                            <option *ngFor='let dis of districts' [value]="dis.display">{{ dis.display }}</option>
                        </select>


                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="ward"
                            [ngClass]="{'is-invalid': submitted && editFormVal.ward.errors}" placeholder="" />
                        <label>Phường/ Xã</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="minCity"
                            [ngClass]="{'is-invalid': submitted && editFormVal.minCity.errors}" required />
                        <label>Số nhà, tên đường</label>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button class="orangeBtn">{{ 'USER_EDIT_PROFILE.UPDATE_INFO' | translate }}</button>
            </div>
        </form>
    </div>
    <h2 class="productTitleInfo mb30">{{ 'USER_PROFILE_DETAIL.TITLE2' | translate }}</h2>
    <section>
        <div *ngFor="let child of globalChildArray; let i = index">
            <div class="profileContent userProfileContent" *ngIf="parentCard[i] && child.child == 'present'">
                <div class="row align-items-top">
                    <div class="col-4">
                        <i class="profilePic" *ngIf="!child?.display_childImage"></i>
                        <i class="profilePicNew" *ngIf="child?.display_childImage"><img [src]="child?.display_childImage" alt="profile bg" /></i>
                    </div>


                    <div class="col-8">
                        <p class="primaryContent">{{child.name}} {{child.surname}}</p>
                        <!-- <p class="dateContent mb-0" *ngIf="child?.child_age && child?.dob && ( milkCode == 0 || milkCode == 'undefined')">{{child?.child_age}} tuổi - {{child?.dob | date:'dd/MM/yyyy'}}</p>
                        <p class="dateContent mb-0" *ngIf="!child?.child_age && !child?.dob && ( milkCode == 0 || milkCode == 'undefined')">ngày sinh</p>
                        <p class="dateContent mb-0" *ngIf="child?.age_range && milkCode == 1">{{child?.age_range}}</p>
                        <p class="dateContent mb-0" *ngIf="!child?.age_range && milkCode == 1">Độ tuổi</p> -->
                           
                         <p class="dateContent mb-0" *ngIf="child?.ch_is_milk == 'false' && child?.dob != ''">{{child?.child_age}} tuổi - {{child?.dob | date:'dd/MM/yyyy'}}</p>
                        <p class="dateContent mb-0" *ngIf="child?.ch_is_milk == 'true'">{{child?.age_range}}</p>
                     
                        <div class="row">
                            <div class="col-6 pr-1">
                                <button class="blueBtn mt-3 btn-block" (click)="onClickChildCardEdit(i)">{{
                                    'USER_PROFILE_DETAIL.EDIT_CHILD' | translate }}</button>
                            </div>
                            <div class="col-6 pl-1">
                                <button class="blueBtn mt-3 btn-block" (click)="onClickViewCard(i)">{{
                                    'USER_PROFILE_DETAIL.VIEW_DETAILS' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row mt-3" *ngIf="showViewCard[i]">
                    <div class="col">
                        <ul class="filterLists">
                            <li>
                                <div class="backBtnBlock">
                                    <a class="headerBlock" (click)="closeViewCard(i)"><img class="backImg"
                                            src="../../../assets/images/blueArrowBack.png">{{
                                        'USER_PROFILE_DETAIL.TITLE3' | translate }}</a>
                                </div>
                                <div *ngIf="child?.childHistory">
                                <ul class="filterDetails mt-3" *ngFor="let element of child?.childHistory">
                                    <li>
                                        <div class="row">
                                            <div class="col-4">
                                          
                                                    <img *ngIf="element?.product_code == '2010'" src="../../../assets/images/homesimilaclist1.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2011'" src="../../../assets/images/homesimilaclist2.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2007'" src="../../../assets/images/similacnewbornonelist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2005'"  src="../../../assets/images/similactwolist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2003'"  src="../../../assets/images/similactotalconfortonelist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2008'" src="../../../assets/images/similacisomillist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2012'"  src="../../../assets/images/similacneosurelist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2001'"  src="../../../assets/images/similacalimentumlist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2003'"  src="../../../assets/images/similaceyeq3list.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2006'" src="../../../assets/images/similac-total-comfort-1list.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2009'" src="../../../assets/images/isomilpluslist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2002'" src="../../../assets/images/similaceye4list.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '2004'"  src="../../../assets/images/similac-total-comfort-2list.png" alt="filter product" />
                
                                                    <img *ngIf="element?.product_code == '1001'"  src="../../../assets/images/abbott-grow-1-list.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '1002'"  src="../../../assets/images/abbott-grow-2-list.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '1003'" src="../../../assets/images/abbott-grow-3-list.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '1004'" src="../../../assets/images/abbott-grow-4-list.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '1005'"  src="../../../assets/images/grow-6-hireslist.png" alt="filter product" />
                                                    <img *ngIf="element?.product_code == '1006'"  src="../../../assets/images/grow-3-hireslist.png" alt="filter product" />


                                        <img
                                        *ngIf="
                                          element?.product_code == '3001' ||
                                          element?.product_code == '3001' ||
                                          element?.product_code == '3001' ||
                                          element?.product_code == '3001' ||
                                          element?.product_code == '3001'
                                        "
                                        src="../../../assets/images/loyalty-program/ensure-shield-850-g-fs-vanilla-850-g-2.png"
                                        alt="filter product"
                                      />
                                      <img
                                        *ngIf="
                                          element?.product_code == '3004' ||
                                          element?.product_code == '3004' ||
                                          element?.product_code == '3004' ||
                                          element?.product_code == '3004' ||
                                          element?.product_code == '3004'
                                        "
                                        src="../../../assets/images/loyalty-program/ensure-gold-coffee-850-g-a-02.png"
                                        alt="filter product"
                                      />
                                      <img
                                        *ngIf="
                                          element?.product_code == '3002' ||
                                          element?.product_code == '3002' ||
                                          element?.product_code == '3002' ||
                                          element?.product_code == '3002' ||
                                          element?.product_code == '3002'
                                        "
                                        src="../../../assets/images/loyalty-program/ensure-shield-850-g-ls-vanilla-850-g-2.png"
                                        alt="filter product"
                                      />
                                      <img
                                        *ngIf="
                                          element?.product_code == '3003' ||
                                          element?.product_code == '3003' ||
                                          element?.product_code == '3003' ||
                                          element?.product_code == '3003' ||
                                          element?.product_code == '3003'
                                        "
                                        src="../../../assets/images/loyalty-program/ensure-gold-huongluamach-850-g-01.png"
                                        alt="filter product"
                                      />
                                      <img
                                        *ngIf="
                                          element?.product_code == '3005' ||
                                          element?.product_code == '3005' ||
                                          element?.product_code == '3005' ||
                                          element?.product_code == '3005' ||
                                          element?.product_code == '3005'
                                        "
                                        src="../../../assets/images/loyalty-program/ensure-gold-huong-hanh-nhan-850-g-12.png"
                                        alt="filter product"
                                      />


                                      <img
                                      *ngIf="
                                        element?.product_code == '3006' ||
                                        element?.product_code == '3006' ||
                                        element?.product_code == '3006' ||
                                        element?.product_code == '3006'
                                      "
                                      src="../../../assets/images/loyalty-program/abbott-glucerna-h-ng-vani-850-g.png"
                                      alt="filter product"
                                    />
                                    <img
                                      *ngIf="
                                        element?.product_code == '3007' ||
                                        element?.product_code == '3007' ||
                                        element?.product_code == '3007' ||
                                        element?.product_code == '3007'
                                      "
                                      src="../../../assets/images/loyalty-program/abbott-glucerna-h-ng-l-a-m-ch-850-g.png"
                                      alt="filter product"
                                    />

                                    <img
                                    *ngIf="
                                      element?.product_code == '4001' ||
                                      element?.product_code == '4001' ||
                                      element?.product_code == '4001' ||
                                      element?.product_code == '4001'
                                    "
                                    src="../../../assets/images/pediasure-lucky.png"
                                    alt="filter product"
                                  />

                                  
                                            </div>
                                            <div class="col-8">
                                                <p *ngIf="element?.product_code"><label> Mã sản phẩm:</label><strong> {{element?.product_code}}</strong></p>
                                                <p *ngIf="element?.scanned_date"><label> Ngày quét mã: </label><strong> {{element?.scanned_date | date:'dd/MM/yyyy'}}</strong></p>
                                                <div [hidden]="list?.custom_fields?.field[13]?.name == 'first_time_scan' && element?.product_code == 'true'">
                                                <p *ngIf="element?.expiry_date && element?.gift_id != 'null'"><label> Ngày lượt quay hết hạn: </label><strong> {{element?.expiry_date | date:'dd/MM/yyyy'}}</strong>
                                                </p>
                                                
                                                <p><label *ngIf="element?.eligible_for_game && element?.is_milk && element?.redemmed && element?.gift_id && element?.gift_id != 'null'">Tình trạng lượt quay: </label>
                                                    <span><strong *ngIf="element?.eligible_for_game == 'true' && element?.redemmed == 'false' && element?.is_milk == 'false'" > Chưa sử dụng</strong></span>
                                                    <strong *ngIf="element?.eligible_for_game == 'false' && element?.is_milk == 'false'"> Hết lượt quy đổi </strong>
                                                    <strong *ngIf="element?.is_milk == 'true' && element?.gift_id != 'null'"> Không áp dụng quy đổi </strong> 
                                                     <span><strong *ngIf="element?.gift_id != 'null' && element?.eligible_for_game == 'true' && element?.redemmed == 'true'"> Đã sử dụng – đã trúng quà </strong></span>
                                                    <!-- <span><strong *ngIf="element?.gift_id == 'null' && element?.eligible_for_game == 'true' && element?.redemmed == 'true'"> Đã sử dụng </strong> </span> -->
                                                </p>
                                                   </div> 
                                            </div>
                                        </div>
                                    </li>
                                    <li style="border-bottom: 1px solid #ccc;
                                    padding-bottom: 10px;">
                                        <div class="row">
                                            <div class="col-4">
                                                
                                                    <img *ngIf="element?.gift_id == 'P-005678'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476170790.jpg" alt="filter product" />
                                                    <img *ngIf="element?.gift_id == 'P-005679'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476416925.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005681'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476480880.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005591'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476603775.png" />
                                                    <img *ngIf="element?.gift_id == 'P-005689'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476663989.png" />
                                                    <img *ngIf="element?.gift_id == 'P-005690'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476725765.png" />
                                                    <img *ngIf="element?.gift_id == 'P-005612'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476768454.png" />
                                                    <img *ngIf="element?.gift_id == 'P-005680'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1615476809091.png" />
                                                    <img *ngIf="element?.gift_id == 'P-005682'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616494289358.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005683'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616494651240.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005685'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495254300.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005686'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495450060.png" />
                                                    <img *ngIf="element?.gift_id == 'P-005687'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495525807.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005688'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495617452.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005691'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495706296.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005692'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495848682.jpg" />
                                                    <img *ngIf="element?.gift_id == 'P-005684'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495986668.jpg" />

                                                    <img *ngIf="element?.gift_id == 'P-008651'"
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648143587413.png" />
                                                    <img *ngIf="element?.gift_id == 'P-008653'"
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648143765500.png" />
                                                    <img *ngIf="element?.gift_id == 'P-008652'"
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648143651792.png" />


                                                    <img *ngIf="element?.gift_id == 'P-008647'"
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648144126966.png" />
                                                    <img *ngIf="element?.gift_id == 'P-008649'"
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648144308121.png" />
                                                    <img *ngIf="element?.gift_id == 'P-008648'"
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1648144339336.png" />


                                                    <img
                                                    *ngIf="
                                                    element?.gift_id == 'P-008711'
                                                    "
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892961918.png"
                                                    />
                                                    <img
                                                    *ngIf="
                                                    element?.gift_id == 'P-008712'
                                                   
                                                    "
                                                    src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892994574.png"
                                                />
                                                <img
                                                *ngIf="
                                                element?.gift_id == 'P-008713'
                                                   
                                                "
                                                src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892926234.png"
                                                />
                                                <img
                                                *ngIf="
                                                element?.gift_id == 'P-008714'
                                               
                                                "
                                                src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1650892859481.png"
                                            />

                                            <img *ngIf="element?.gift_id == 'P-008791'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495706296.jpg" />
                                            <img *ngIf="element?.gift_id == 'P-008792'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495848682.jpg" />
                                            <img *ngIf="element?.gift_id == 'P-008793'" src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/f148dba495e5391a7790087cbb5fc3e2-1616495986668.jpg" />
                                            </div>
                                            <div class="col-8">
                                               
                                                <h6 *ngIf="element?.gift_id == 'P-005678'">Túi đi sinh cho mẹ</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005679'">Set 2 ly thuỷ tinh Lock & Lock</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005681'">Bộ xếp hình lắp ráp sáng tạo</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005591'">Bộ đồ chơi xúc cát</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005689'">Ly sứ Abbott Grow</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005690'">Bộ đồ chơi câu cá</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005612'">Bộ đồ chơi bóng rổ</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005680'">Bộ bình và dĩa ăn cho bé</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005682'">Túi đi sinh cho mẹ</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005683'">Gấu trạng nhí</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005685'">Bộ đồ chơi xúc cát</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005686'">Balo kéo Similac</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005687'">Bảng học nam châm</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005688'">Bộ bàn ghế học</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005691'">Xe Scooter 3 trong 1</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005692'">Bộ bowling đa năng</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-005684'">Bộ đồ chơi bé tập làm hoạ sĩ</h6>
                                                
                                                <h6 *ngIf="element?.gift_id == 'P-008710'"> Cốc thuỷ tinh cao cấp</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008711'"> Bình nước khủng long</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008712'">Khủng long nhồi bông</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008713'">  Vali khủng long</h6>

                                               

                                                <h6 *ngIf="element?.gift_id == 'P-008651'" >
                                                    Máy massage hồng ngoại đa năng</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008653'" >
                                                    Máy đo huyết áp</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008652'" >
                                                    Ba lô thể thao tiện dụng</h6>
            
                                                <h6 *ngIf="element?.gift_id == 'P-008647'" >
                                                    Cân điện tử sức khoẻ</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008648'" >
                                                    Nhiệt kế điện tử</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008649'" >
                                                    Bộ đầu đọc và cảm biến đo đường huyết liên tục FreeStyle Libre</h6>
                                            
                                                    <h6 *ngIf="element?.gift_id == 'P-008791'">  Bình giữ nhiệt Lock & Lock 330ml (màu ngẫu nhiên)</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008792'"> Bình giữ nhiệt Lock & Lock 400ml</h6>
                                                <h6 *ngIf="element?.gift_id == 'P-008793'">Bình nước Lock & Lock 500ml</h6>
                                            
                                                </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="addMemberblock mb30" *ngIf="child.child == 'absent'">
                <div class="row align-items-center h-100">
                    <div class="col-12 text-center">
                        <p class="mb-0" (click)="addMoreMembersModal1(i)"><img class="addImgCircle"
                                src="../../../assets/images/addcirclerounded.png">{{
                            'USER_PROFILE_DETAIL.ADD_MEMBERS' | translate }}</p>
                    </div>
                </div>
            </div>

            <!-- </div>  original close-->



            <div class="childInfoBlockOut mb30 mt-4" *ngIf="showChild[i]">
                <a class="headerBlock" (click)="closeChildCard(i)"><img class="backImg"
                        src="../../../assets/images/blueArrowBack.png">{{ 'USER_PROFILE_DETAIL.TITLE3' | translate
                    }}</a>
                <div class="text-center">
                    <!-- <i class="profilePic"></i> -->

                    <!-- <i class="profilePic" *ngIf="child.child_image == ''"></i>
                    <i class="profilePicNew" *ngIf="child.child_image != ''"><img [src]="child.display_childImage" alt="profile bg" /></i> -->

                    <i class="profilePic" *ngIf="!child?.display_childImage"></i>
                    <i class="profilePicNew" *ngIf="child?.display_childImage"><img [src]="child.display_childImage" alt="profile bg" /></i>

                   

                    <button class="blueBtn mt-3">{{ 'USER_PROFILE_DETAIL.CHANGE_AVATAR' | translate }}</button>

                    <form>
                        <div class="imageUploadWrap">
                            <label class="imageUploadLabel edit" for="upload">
                                <input class="uploadChild" id="upload" type="file" name="profile"
                                    (change)="fileChangeEvent($event)" />
                            </label>
                            <ngx-popup [(ngModel)]="visible" [ngModelOptions]="{standalone: true}">
                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="3 / 3"
                                    [resizeToWidth]="256" [cropperMinWidth]="128" [onlyScaleDown]="true"
                                    [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
                                    [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
                                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                    (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                                <button class="uploadImageBtn" (click)="uploadImage()">upload</button>
                            </ngx-popup>
                        </div>
                    </form>

                </div>
                <form [formGroup]="childForm1" class="formContainer mt-4" (ngSubmit)="onSubmitChildForm(i)">
                    <div class="row" *ngIf="(prodCategory == 'Grow' || prodCategory == 'PediaSure' )">
                        <div class="col-6">
                            <div class="form-group mb-2">
                                <input type="text" class="form-control"
                                    [ngClass]="{'is-invalid': submittedChild1 && formVal.surName.errors}"
                                    id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""
                                    formControlName="surName" required>
                                <label for="exampleInputEmail1">{{ 'USER_PROFILE_DETAIL.SURNAME' | translate }}</label>

                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-2">
                                <input type="text" class="form-control"
                                    [ngClass]="{'is-invalid': submittedChild1 && formVal.name.errors}"
                                    id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""
                                    formControlName="name" required>
                                <label for="exampleInputEmail1">{{ 'USER_PROFILE_DETAIL.NAME' | translate }}</label>
                            </div>
                        </div>

                        <!-- *ngIf="(child?.ch_is_milk && child?.ch_is_milk == 'false')" -->
                        <div class="col-12">
                            <p>{{ 'USER_PROFILE_DETAIL.DOB' | translate }}</p>

                        </div>
                        <!-- *ngIf="(child?.ch_is_milk && child?.ch_is_milk == 'false')" -->
                        <div class="col pr-0"  >
                            <div class="form-group mb-2">
                                <select class="form-control" id="exampleFormControlSelect1" formControlName="day"
                                    [ngClass]="{'is-invalid': submittedChild1 &&  formVal.day.errors}">
                                    <option value="" disabled selected>{{ 'USER_PROFILE_DETAIL.DAY' | translate }}
                                    </option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option>29</option>
                                    <option>30</option>
                                    <option>31</option>
                                </select>
                                <!-- <div *ngIf="submittedChild1 && crmFormVal.milkcodeForm.controls[i].status == 'INVALID'" class="invalid-feedback">
                    <div *ngIf="submittedChild1 && crmFormVal.milkcodeForm.controls[i].controls?.day?.errors?.required">Day is Required</div>
                </div> -->
                            </div>
                        </div>

                        <!-- *ngIf="(child?.ch_is_milk && child?.ch_is_milk == 'false')" -->
                        <div class="col" >
                            <div class="form-group mb-2">
                                <select class="form-control" id="exampleFormControlSelect1" formControlName="month"
                                    [ngClass]="{'is-invalid': submittedChild1 && formVal.month.errors}">
                                    <option value="" disabled selected>{{ 'USER_PROFILE_DETAIL.MONTH' | translate }}
                                    </option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
<!-- 
                                    <option>tháng Giêng</option>
                                    <option>tháng 2</option>
                                    <option>tháng Ba</option>
                                    <option>Tháng tư</option>
                                    <option>có thể</option>
                                    <option>Tháng sáu</option>
                                    <option>Tháng bảy</option>
                                    <option>tháng Tám</option>
                                    <option>Tháng Chín</option>
                                    <option>Tháng Mười</option>
                                    <option>Tháng mười một</option>
                                    <option>Tháng mười hai</option> -->
                                </select>
                                <!-- <div *ngIf="submittedChild1 && crmFormVal.milkcodeForm.controls[i].status == 'INVALID'" class="invalid-feedback">
                    <div *ngIf="submittedChild1 && crmFormVal.milkcodeForm.controls[i].controls?.month?.errors?.required">Month is Required</div>
                </div> -->
                            </div>
                        </div>
                        <!-- *ngIf="(child?.ch_is_milk && child?.ch_is_milk == 'false')" -->
                        <div class="col pl-0"  >
                            <div class="form-group mb-2">
                                <select class="form-control" id="exampleFormControlSelect1" formControlName="year"
                                    [ngClass]="{'is-invalid': submittedChild1 && formVal.year.errors}">
                                    <option value="" disabled selected>{{ 'USER_PROFILE_DETAIL.YEAR' | translate }}
                                    </option>
                                    <option *ngFor="let year of yearList" [value]="year">{{year}}</option>

                                </select>
                                <!-- <div *ngIf="submittedChild1 && crmFormVal.milkcodeForm.controls[i].status == 'INVALID'" class="invalid-feedback">
                    <div *ngIf="submittedChild1 && crmFormVal.milkcodeForm.controls[i].controls?.year?.errors?.required">Year is Required</div>
                </div> -->
                            </div>
                        </div>
                        <!-- *ngIf="(child?.ch_is_milk && child?.ch_is_milk == 'true')" -->
                        <div class="col-12"  *ngIf="(child?.ch_is_milk && child?.show_nonmilk == false)">
                            <p>{{ 'USER_PROFILE_DETAIL.BABY_AGE' | translate }}</p>
                        </div>
                        <!-- *ngIf="(child?.ch_is_milk && child?.ch_is_milk == 'true')" -->
                        <!-- <div class="col-12"  *ngIf="(child?.ch_is_milk && child?.show_nonmilk == false)">
                            <div class="form-group mb-2">
                                <select class="form-control" id="exampleFormControlSelect1" formControlName="ageRange"
                                    [ngClass]="{'is-invalid': submittedChild1 && formVal.ageRange.errors}">
                                    
                                    <option value="" disabled selected>chọn bên dưới</option>
                                    <option>0 - 6 tháng tuổi</option>
                                    <option>6 - 12 tháng tuổi</option>
                                    <option>12 - 18 tháng tuổi</option>
                                    <option>18 - 24 tháng tuổi</option>
                                </select>
                               
                            </div>
                        </div> -->
                    </div>



                        <!-- Similac -->
                    <!-- <p>Similac</p> -->
                    <div *ngIf="(prodCategory == 'Similac')"> 
                        <div class="form-group mb-2">
                            <select class="form-control" (change)="momtype($event)" formControlName="similacmomtype">
                                <option value="" selected disabled> Tôi đang mang thai / Tôi đã có con</option>
                                <option>Tôi đang mang thai</option>
                                <option>Tôi đã có con</option>
                            </select>
                        </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <input 
                                    type="text" 
                                    required 
                                    formControlName="surName" 
                                    [ngClass]="{'is-invalid': !isPregnent && submitted && formVal.surName.errors}" />
                                <label *ngIf="isPregnent">Họ của bé (tuỳ chọn)</label>
                                <label *ngIf="!isPregnent">Họ của bé </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <input 
                                    type="text" 
                                    required 
                                    formControlName="name" 
                                    [ngClass]="{'is-invalid': !isPregnent && submitted && formVal.name.errors}"/>
                                <label *ngIf="isPregnent">Tên của bé (tuỳ chọn)</label>
                                <label *ngIf="!isPregnent">Tên của bé </label>
                            </div>
                        </div>
                    </div>
                    <div class="nonMilkCode">
                    
                        <h6 *ngIf="!isPregnent">Ngày sinh của bé</h6>
                        <h6 *ngIf="isPregnent">Ngày dự sinh của mẹ</h6>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <select 
                                        class="form-control" 
                                        placement="bottom" 
                                        formControlName="day" 
                                        [ngClass]="{'is-invalid': submitted && formVal.day.errors}">
                                        <option value="" selected>Ngày</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>18</option>
                                        <option>19</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>23</option>
                                        <option>24</option>
                                        <option>25</option>
                                        <option>26</option>
                                        <option>27</option>
                                        <option>28</option>
                                        <option>29</option>
                                        <option>30</option>
                                        <option>31</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <select 
                                        class="form-control" 
                                        placement="bottom" 
                                        formControlName="month" 
                                        [ngClass]="{'is-invalid': submitted && formVal.month.errors}">
                                        <option value="" selected>Tháng</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <!-- <option>tháng Giêng</option>
                                        <option>tháng 2</option>
                                        <option>tháng Ba</option>
                                        <option>Tháng tư</option>
                                        <option>có thể</option>
                                        <option>Tháng sáu</option>
                                        <option>Tháng bảy</option>
                                        <option>tháng Tám</option>
                                        <option>Tháng Chín</option>
                                        <option>Tháng Mười</option>
                                        <option>Tháng mười một</option>
                                        <option>Tháng mười hai</option> -->
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                  
                                    <select *ngIf="isPregnent"
                                    class="form-control" 
                                    placement="bottom" 
                                    formControlName="year" 
                                    [ngClass]="{'is-invalid': submitted && addMembersFormVal.year.errors}">
                                    <option value="" selected>Năm</option>
                                    <option [hidden]="i >= 2" *ngFor="let year2 of motherDOByear; let i = index" [value]="year2">{{year2}}</option>
                                </select>
                                <select *ngIf="!isPregnent"
                                    class="form-control" 
                                    placement="bottom" 
                                    formControlName="year" 
                                    [ngClass]="{'is-invalid': submitted && addMembersFormVal.year.errors}">
                                    <option value="" selected>Năm</option>
                                    <option *ngFor="let year of yearList" [value]="year">{{year}}</option>
                                </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                        <!-- Glucerna -->
                        <!-- <p>Glucerna</p> -->
                        <div *ngIf="(prodCategory == 'Glucerna')">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="surName" 
                                        [ngClass]="{'is-invalid': submitted && formVal.surName.errors}" />
                                    <label>{{ 'ADD_MORE_MEMBERS.SURNAME' | translate }}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="name" 
                                        [ngClass]="{'is-invalid': submitted && formVal.name.errors}"/>
                                    <label>{{ 'ADD_MORE_MEMBERS.NAME' | translate }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <select class="form-control" id="exampleFormControlSelect1"  formControlName="typeofdiabetes"
                            [ngClass]="{'is-invalid': submitted && formVal.typeofdiabetes.errors}">
                                <option value="" selected disabled >Tuýp đái tháo đường</option>
                                <option>Tuýp 1</option>
                                <option>Tuýp 2</option>
                            </select>
                        </div>
                        <div class="nonMilkCode" >
                            <h6 class="mt-2">Sinh nhật</h6>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="day" 
                                            [ngClass]="{'is-invalid': submitted && formVal.day.errors}">
                                            <option value="" selected>Ngày (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <option>13</option>
                                            <option>14</option>
                                            <option>15</option>
                                            <option>16</option>
                                            <option>17</option>
                                            <option>18</option>
                                            <option>19</option>
                                            <option>20</option>
                                            <option>21</option>
                                            <option>22</option>
                                            <option>23</option>
                                            <option>24</option>
                                            <option>25</option>
                                            <option>26</option>
                                            <option>27</option>
                                            <option>28</option>
                                            <option>29</option>
                                            <option>30</option>
                                            <option>31</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="month" 
                                            [ngClass]="{'is-invalid': submitted && formVal.month.errors}">
                                            <option value="" selected>Tháng (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <!-- <option>tháng Giêng</option>
                                            <option>tháng 2</option>
                                            <option>tháng Ba</option>
                                            <option>Tháng tư</option>
                                            <option>có thể</option>
                                            <option>Tháng sáu</option>
                                            <option>Tháng bảy</option>
                                            <option>tháng Tám</option>
                                            <option>Tháng Chín</option>
                                            <option>Tháng Mười</option>
                                            <option>Tháng mười một</option>
                                            <option>Tháng mười hai</option> -->
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="year" 
                                            [ngClass]="{'is-invalid': submitted && formVal.year.errors}">
                                            <option value="" selected>Năm</option>
                                            <option *ngFor="let year1 of yearList1" [value]="year1">{{year1}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <!-- Ensure -->
                    <!-- <p>Ensure</p> -->
                    <div *ngIf="(prodCategory == 'Ensure')">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="surName" 
                                        [ngClass]="{'is-invalid': submitted && formVal.surName.errors}" />
                                    <label>{{ 'ADD_MORE_MEMBERS.SURNAME' | translate }}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="name" 
                                        [ngClass]="{'is-invalid': submitted && formVal.name.errors}"/>
                                    <label>{{ 'ADD_MORE_MEMBERS.NAME' | translate }}</label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="nonMilkCode" >
                            <h6>Sinh nhật</h6>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="day" 
                                            [ngClass]="{'is-invalid': submitted && formVal.day.errors}">
                                            <option value="" selected>Ngày (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <option>13</option>
                                            <option>14</option>
                                            <option>15</option>
                                            <option>16</option>
                                            <option>17</option>
                                            <option>18</option>
                                            <option>19</option>
                                            <option>20</option>
                                            <option>21</option>
                                            <option>22</option>
                                            <option>23</option>
                                            <option>24</option>
                                            <option>25</option>
                                            <option>26</option>
                                            <option>27</option>
                                            <option>28</option>
                                            <option>29</option>
                                            <option>30</option>
                                            <option>31</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="month" 
                                            [ngClass]="{'is-invalid': submitted && formVal.month.errors}">
                                            <option value="" selected>Tháng (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <!-- <option>tháng Giêng</option>
                                            <option>tháng 2</option>
                                            <option>tháng Ba</option>
                                            <option>Tháng tư</option>
                                            <option>có thể</option>
                                            <option>Tháng sáu</option>
                                            <option>Tháng bảy</option>
                                            <option>tháng Tám</option>
                                            <option>Tháng Chín</option>
                                            <option>Tháng Mười</option>
                                            <option>Tháng mười một</option>
                                            <option>Tháng mười hai</option> -->
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="year" 
                                            [ngClass]="{'is-invalid': submitted && formVal.year.errors}">
                                            <option value="" selected>Năm</option>
                                            <option *ngFor="let year1 of yearList1" [value]="year1">{{year1}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <button type="submit" class="orangeBtn btn-block lh20 mt-3 mb-3">{{ 'USER_PROFILE_DETAIL.UPDATE_BTN'
                        |
                        translate }}</button>
                </form>
            </div>


        </div>
        <!--test-->

        
    </section>
   
</section>

<div bsModal #addMoreMembers1="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm addMoreMembersOut">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close pull-right closeBtn" aria-label="Close"
                    (click)="addMoreMembersHide1()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="body">
                    <section class="addMoreMember">
                        <h2>{{ 'ADD_MORE_MEMBERS.ADD_MORE_MEMBERS' | translate }}</h2>
                        <!-- <i><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i> -->

                        
                          <i *ngIf="submitIndex == 0 && !childImageUpload[0]"><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i>
                          <i *ngIf="submitIndex == 0 && childImageUpload[0]"><img [src]="childImageUpload[0]" alt="profile bg" /></i>
                       
                          <i *ngIf="submitIndex == 1 && !childImageUpload[1]"><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i>
                          <i *ngIf="submitIndex == 1 && childImageUpload[1]"><img [src]="childImageUpload[1]" alt="profile bg" /></i>
                    
                          <i *ngIf="submitIndex == 2 && !childImageUpload[2]"><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i>
                          <i *ngIf="submitIndex == 2 && childImageUpload[2]"><img [src]="childImageUpload[2]" alt="profile bg" /></i>

                          <i *ngIf="submitIndex == 3 && !childImageUpload[3]"><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i>
                          <i *ngIf="submitIndex == 3 && childImageUpload[3]"><img [src]="childImageUpload[3]" alt="profile bg" /></i>

                        <!-- class="profilePic"  -->
                        <!-- <i *ngIf="child.display_childImage == 'undefined'"></i>
                        <i *ngIf="child.display_childImage != 'undefined'"><img [src]="child.display_childImage" alt="profile bg" /></i> -->
                        <div class="text-center">
                            <a class="blueBtn">{{ 'ADD_MORE_MEMBERS.CHANGE_AVATAR' | translate }}</a>

                             <form>
                                <div class="imageUploadWrap">
                                    <label class="imageUploadLabel edit" for="upload">
                                        <input class="uploadNewChild" id="upload" type="file" name="profile" (change)="fileChangeEvent($event)" />
                                    </label>
                                    <ngx-popup [(ngModel)]="visible" [ngModelOptions]="{standalone: true}">
                                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                            [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="3 / 3"
                                            [resizeToWidth]="256" [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                                            [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                                            [style.display]="showCropper ? null : 'none'" format="png"
                                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                            (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
                                        <button class="uploadImageBtn" (click)="uploadImage()">upload</button>
                                    </ngx-popup>
                                </div>
                            </form>

                        </div>
                        <div class="productUserOut">
                            <h3>{{ 'ADD_MORE_MEMBERS.PRODUCT_USER_INFORMATION' | translate }}</h3>
                            <form [formGroup]="addMembersForm" (ngSubmit)="onSubmitaddMembersForm()">
                                <div *ngIf="(prodCategory == 'PediaSure' || prodCategory == 'Grow')">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" required formControlName="surName"
                                                    [ngClass]="{'is-invalid': submitted && formVal.surName.errors}" />
                                                <label>{{ 'ADD_MORE_MEMBERS.SURNAME' | translate }}</label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" required formControlName="name"
                                                    [ngClass]="{'is-invalid': submitted && formVal.name.errors}" />
                                                <label>{{ 'ADD_MORE_MEMBERS.NAME' | translate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nonMilkCode">
                                        <h6>{{ 'ADD_MORE_MEMBERS.DOB' | translate }}</h6>
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <select class="form-control" placement="bottom" formControlName="day"
                                                        [ngClass]="{'is-invalid': submitted && formVal.day.errors}">
                                                        <option value="" selected>Ngày</option>
                                                        <option>01</option>
                                                        <option>02</option>
                                                        <option>03</option>
                                                        <option>04</option>
                                                        <option>05</option>
                                                        <option>06</option>
                                                        <option>07</option>
                                                        <option>08</option>
                                                        <option>09</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                        <option>13</option>
                                                        <option>14</option>
                                                        <option>15</option>
                                                        <option>16</option>
                                                        <option>17</option>
                                                        <option>18</option>
                                                        <option>19</option>
                                                        <option>20</option>
                                                        <option>21</option>
                                                        <option>22</option>
                                                        <option>23</option>
                                                        <option>24</option>
                                                        <option>25</option>
                                                        <option>26</option>
                                                        <option>27</option>
                                                        <option>28</option>
                                                        <option>29</option>
                                                        <option>30</option>
                                                        <option>31</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <select class="form-control" placement="bottom" formControlName="month"
                                                        [ngClass]="{'is-invalid': submitted && formVal.month.errors}">
                                                        <option value="" selected>Tháng</option>
                                                        <!-- <option>tháng Giêng</option>
                                                        <option>tháng 2</option>
                                                        <option>tháng Ba</option>
                                                        <option>Tháng tư</option>
                                                        <option>có thể</option>
                                                        <option>Tháng sáu</option>
                                                        <option>Tháng bảy</option>
                                                        <option>tháng Tám</option>
                                                        <option>Tháng Chín</option>
                                                        <option>Tháng Mười</option>
                                                        <option>Tháng mười một</option>
                                                        <option>Tháng mười hai</option> -->
                                                        <option>01</option>
                                                        <option>02</option>
                                                        <option>03</option>
                                                        <option>04</option>
                                                        <option>05</option>
                                                        <option>06</option>
                                                        <option>07</option>
                                                        <option>08</option>
                                                        <option>09</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <select class="form-control" placement="bottom" formControlName="year"
                                                        [ngClass]="{'is-invalid': submitted && formVal.year.errors}">
                                                        <option value="" selected>Năm</option>
                                                        <option *ngFor="let year of yearList" [value]="year">{{year}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="milkCode" *ngIf="milkCode == 1">
                                        <h6>{{ 'ADD_MORE_MEMBERS.BABYS_AGE' | translate }}</h6>
                                        <div class="form-group">
                                            <select class="form-control" placement="bottom" formControlName="babyAge"
                                                [ngClass]="{'is-invalid': submitted && formVal.babyAge.errors}">
                                                
                                                <option value="" disabled selected>chọn bên dưới</option>
                                                <option>0 - 6 tháng tuổi</option>
                                                <option>6 - 12 tháng tuổi</option>
                                                <option>12 - 18 tháng tuổi</option>
                                                <option>18 - 24 tháng tuổi</option>
                                            </select>
                                        </div>
                                    </div> -->
                                </div>
                                           <!-- Similac -->
                    <!-- <p>Similac</p> -->
                    <div *ngIf="(prodCategory == 'Similac')"> 
                        <div class="form-group mb-2">
                            <select class="form-control" (change)="momtype($event)" formControlName="similacmomtype">
                                <option value="" selected disabled> Tôi đang mang thai / Tôi đã có con</option>
                                <option>Tôi đang mang thai</option>
                                <option>Tôi đã có con</option>
                            </select>
                        </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <input 
                                    type="text" 
                                    required 
                                    formControlName="surName" 
                                    [ngClass]="{'is-invalid': !isPregnent && submitted && addMembersFormVal.surName.errors}" />
                                <label *ngIf="isPregnent">Họ của bé (tuỳ chọn)</label>
                                <label *ngIf="!isPregnent">Họ của bé </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <input 
                                    type="text" 
                                    required 
                                    formControlName="name" 
                                    [ngClass]="{'is-invalid': !isPregnent && submitted && addMembersFormVal.name.errors}"/>
                                <label *ngIf="isPregnent">Tên của bé (tuỳ chọn)</label>
                                <label *ngIf="!isPregnent">Tên của bé </label>
                            </div>
                        </div>
                    </div>
                    <div class="nonMilkCode">
                        <h6 *ngIf="!isPregnent">Ngày sinh của bé</h6>
                        <h6 *ngIf="isPregnent">Ngày dự sinh của mẹ</h6>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <select 
                                        class="form-control" 
                                        placement="bottom" 
                                        formControlName="day" 
                                        [ngClass]="{'is-invalid': submitted && addMembersFormVal.day.errors}">
                                        <option value="" selected>Ngày</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <option>13</option>
                                        <option>14</option>
                                        <option>15</option>
                                        <option>16</option>
                                        <option>17</option>
                                        <option>18</option>
                                        <option>19</option>
                                        <option>20</option>
                                        <option>21</option>
                                        <option>22</option>
                                        <option>23</option>
                                        <option>24</option>
                                        <option>25</option>
                                        <option>26</option>
                                        <option>27</option>
                                        <option>28</option>
                                        <option>29</option>
                                        <option>30</option>
                                        <option>31</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <select 
                                        class="form-control" 
                                        placement="bottom" 
                                        formControlName="month" 
                                        [ngClass]="{'is-invalid': submitted && addMembersFormVal.month.errors}">
                                        <option value="" selected>Tháng</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                        <option>10</option>
                                        <option>11</option>
                                        <option>12</option>
                                        <!-- <option>tháng Giêng</option>
                                        <option>tháng 2</option>
                                        <option>tháng Ba</option>
                                        <option>Tháng tư</option>
                                        <option>có thể</option>
                                        <option>Tháng sáu</option>
                                        <option>Tháng bảy</option>
                                        <option>tháng Tám</option>
                                        <option>Tháng Chín</option>
                                        <option>Tháng Mười</option>
                                        <option>Tháng mười một</option>
                                        <option>Tháng mười hai</option> -->
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                  
                                    <select *ngIf="isPregnent"
                                    class="form-control" 
                                    placement="bottom" 
                                    formControlName="year" 
                                    [ngClass]="{'is-invalid': submitted && addMembersFormVal.year.errors}">
                                    <option value="" selected>Năm</option>
                                    <option [hidden]="i >= 2" *ngFor="let year2 of motherDOByear; let i = index" [value]="year2">{{year2}}</option>
                                </select>
                                <select *ngIf="!isPregnent"
                                    class="form-control" 
                                    placement="bottom" 
                                    formControlName="year" 
                                    [ngClass]="{'is-invalid': submitted && addMembersFormVal.year.errors}">
                                    <option value="" selected>Năm</option>
                                    <option *ngFor="let year of yearList" [value]="year">{{year}}</option>
                                </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                        <!-- Glucerna -->
                        <!-- <p>Glucerna</p> -->
                        <div *ngIf="(prodCategory == 'Glucerna')">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="surName" 
                                        [ngClass]="{'is-invalid': submitted && addMembersFormVal.surName.errors}" />
                                    <label>{{ 'ADD_MORE_MEMBERS.SURNAME' | translate }}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="name" 
                                        [ngClass]="{'is-invalid': submitted && addMembersFormVal.name.errors}"/>
                                    <label>{{ 'ADD_MORE_MEMBERS.NAME' | translate }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <select class="form-control" id="exampleFormControlSelect1"  formControlName="typeofdiabetes"
                            [ngClass]="{'is-invalid': submitted && formVal.typeofdiabetes.errors}">
                                <option value="" selected disabled >Tuýp đái tháo đường</option>
                                <option>Tuýp 1</option>
                                <option>Tuýp 2</option>
                            </select>
                        </div>
                        <div class="nonMilkCode" >
                            <h6 class="mt-2">Sinh nhật</h6>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="day" 
                                            [ngClass]="{'is-invalid': submitted && addMembersFormVal.day.errors}">
                                            <option value="" selected>Ngày (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <option>13</option>
                                            <option>14</option>
                                            <option>15</option>
                                            <option>16</option>
                                            <option>17</option>
                                            <option>18</option>
                                            <option>19</option>
                                            <option>20</option>
                                            <option>21</option>
                                            <option>22</option>
                                            <option>23</option>
                                            <option>24</option>
                                            <option>25</option>
                                            <option>26</option>
                                            <option>27</option>
                                            <option>28</option>
                                            <option>29</option>
                                            <option>30</option>
                                            <option>31</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="month" 
                                            [ngClass]="{'is-invalid': submitted && addMembersFormVal.month.errors}">
                                            <option value="" selected>Tháng (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <!-- <option>tháng Giêng</option>
                                            <option>tháng 2</option>
                                            <option>tháng Ba</option>
                                            <option>Tháng tư</option>
                                            <option>có thể</option>
                                            <option>Tháng sáu</option>
                                            <option>Tháng bảy</option>
                                            <option>tháng Tám</option>
                                            <option>Tháng Chín</option>
                                            <option>Tháng Mười</option>
                                            <option>Tháng mười một</option>
                                            <option>Tháng mười hai</option> -->
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="year" 
                                            [ngClass]="{'is-invalid': submitted && addMembersFormVal.year.errors}">
                                            <option value="" selected>Năm</option>
                                            <option *ngFor="let year1 of yearList1" [value]="year1">{{year1}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <!-- Ensure -->
                    <!-- <p>Ensure</p> -->
                    <div *ngIf="(prodCategory == 'Ensure')">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="surName" 
                                        [ngClass]="{'is-invalid': submitted && addMembersFormVal.surName.errors}" />
                                    <label>{{ 'ADD_MORE_MEMBERS.SURNAME' | translate }}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        required 
                                        formControlName="name" 
                                        [ngClass]="{'is-invalid': submitted && addMembersFormVal.name.errors}"/>
                                    <label>{{ 'ADD_MORE_MEMBERS.NAME' | translate }}</label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="nonMilkCode" >
                            <h6>Sinh nhật</h6>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="day" 
                                            [ngClass]="{'is-invalid': submitted && addMembersFormVal.day.errors}">
                                            <option value="" selected>Ngày (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <option>13</option>
                                            <option>14</option>
                                            <option>15</option>
                                            <option>16</option>
                                            <option>17</option>
                                            <option>18</option>
                                            <option>19</option>
                                            <option>20</option>
                                            <option>21</option>
                                            <option>22</option>
                                            <option>23</option>
                                            <option>24</option>
                                            <option>25</option>
                                            <option>26</option>
                                            <option>27</option>
                                            <option>28</option>
                                            <option>29</option>
                                            <option>30</option>
                                            <option>31</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="month" 
                                            [ngClass]="{'is-invalid': submitted && addMembersFormVal.month.errors}">
                                            <option value="" selected>Tháng (tuỳ chọn)</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <!-- <option>tháng Giêng</option>
                                            <option>tháng 2</option>
                                            <option>tháng Ba</option>
                                            <option>Tháng tư</option>
                                            <option>có thể</option>
                                            <option>Tháng sáu</option>
                                            <option>Tháng bảy</option>
                                            <option>tháng Tám</option>
                                            <option>Tháng Chín</option>
                                            <option>Tháng Mười</option>
                                            <option>Tháng mười một</option>
                                            <option>Tháng mười hai</option> -->
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <select 
                                            class="form-control" 
                                            placement="bottom" 
                                            formControlName="year" 
                                            [ngClass]="{'is-invalid': submitted && addMembersFormVal.year.errors}">
                                            <option value="" selected>Năm</option>
                                            <option *ngFor="let year1 of yearList1" [value]="year1">{{year}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                                <div class="checkbox">
                                    <input type="checkbox" name="checktest1" id="checktest1"
                                        formControlName="termsAndConditions"
                                        [ngClass]="{'is-invalid': submitted && addMembersFormVal.termsAndConditions.errors}"
                                        required />
                                    <label for="checktest1">{{ 'ADD_MORE_MEMBERS.TERMS_AND_CONDITIONS' | translate
                                        }}</label>
                                </div>
                                <p>{{ 'ADD_MORE_MEMBERS.INCASE_PARTICIPANT' | translate }}</p>
                                <button class="orangeBtn">{{ 'ADD_MORE_MEMBERS.ADD_MEMBERS' | translate }}</button>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>

<app-dynamic-footer code="COR-C-21-21"></app-dynamic-footer>
<app-nav-menu></app-nav-menu>