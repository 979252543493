import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TracebilityLandingComponent } from './pages/tracebility-landing/tracebility-landing.component';
import { HomeComponent } from './home/home.component';
import { ManufacturingHollandComponent } from './pages/manufacturing-holland/manufacturing-holland.component';
import { ManufacturingGranadaComponent } from './pages/manufacturing-granada/manufacturing-granada.component';
import { ManufacturingUsComponent } from './pages/manufacturing-us/manufacturing-us.component';
import { ManufacturingCootehillComponent } from './pages/manufacturing-cootehill/manufacturing-cootehill.component';
import { ManufacturingSingaporeComponent } from './pages/manufacturing-singapore/manufacturing-singapore.component';
import { QcGrowComponent } from './pages/qc-grow/qc-grow.component';
import { ProductDecriptionGrowComponent } from './pages/product-decription-grow/product-decription-grow.component';
import { SimilacMomProductComponent } from './pages/similac-mom-product/similac-mom-product.component';
import { HomeSimilacComponent } from './pages/home-similac/home-similac.component';
import { SignInComponent } from './member-care/sign-in/sign-in.component';
import { SignupComponent } from './pages/signup/signup.component';
import { OtpComponent } from './pages/otp/otp.component';
import { SalesforceSignupComponent } from './pages/salesforce-signup/salesforce-signup.component';
import { AdditionalSalesSignupComponent } from './pages/additional-sales-signup/additional-sales-signup.component';
import { CrmSignupComponent } from './pages/crm-signup/crm-signup.component';
import { SignupSuccessComponent } from './signup-success/signup-success.component';
import { TempProfileComponent } from './pages/temp-profile/temp-profile.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { GrowSpinWheelComponent } from './pages/grow-spin-wheel/grow-spin-wheel.component';
import { SimilacSpinWheelComponent } from './pages/similac-spin-wheel/similac-spin-wheel.component';

import { RewardsComponent } from './pages/rewards/rewards.component';
import { ProfileHomeComponent } from './pages/profile-home/profile-home.component';
import { LibraryComponent } from './pages/library/library.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AuthGuardGuard } from './auth/auth-guard.guard';
import { LuckyDrawQuotaExceedComponent } from './pages/lucky-draw-quota-exceed/lucky-draw-quota-exceed.component';
import { KnowledgeHubComponent } from './pages/knowledge-hub/knowledge-hub.component';
import { SearchScannerComponent } from './pages/search-scanner/search-scanner.component';
import { UserProfileDetailComponent } from './pages/user-profile-detail/user-profile-detail.component';
import { TempHoldingComponent } from './pages/temp-holding/temp-holding.component';

import { EnsureProductComponent } from './ensure-product/ensure-product.component';
import { EnsureQcComponent } from './ensure-qc/ensure-qc.component';
import { GlucernaProductComponent } from './glucerna-product/glucerna-product.component';
import { GlucernaQcComponent } from './glucerna-qc/glucerna-qc.component';
import { PediasureQcComponent } from './pediasure-qc/pediasure-qc.component';
import { PediasureProductComponent } from './pediasure-product/pediasure-product.component';
import { EnsureSpinWheelComponent } from './pages/ensure-spin-wheel/ensure-spin-wheel.component';
import { GlucernaSpinWheelComponent } from './pages/glucerna-spin-wheel/glucerna-spin-wheel.component';
import { PediasureSpinwheelComponent } from './pages/pediasure-spinwheel/pediasure-spinwheel.component';


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/landing-page',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    //path: 'landing-page',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: TracebilityLandingComponent,
  },
  {
    path: 'home-similac',
    component: HomeSimilacComponent,
  },
  {
    path: 'manufacturing-holland',
    component: ManufacturingHollandComponent,
  },
  {
    path: 'manufacturing-granada',
    component: ManufacturingGranadaComponent,
  },
  {
    path: 'manufacturing-us',
    component: ManufacturingUsComponent,
  },
  {
    path: 'manufacturing-cootehill',
    component: ManufacturingCootehillComponent,
  },
  {
    path: 'manufacturing-singapore',
    component: ManufacturingSingaporeComponent,
  },
  {
    path: 'qc',
    component: QcGrowComponent,
  },
  {
    path: 'product-description',
    component: ProductDecriptionGrowComponent,
  },
  {
    path: 'similac-mom-product',
    component: SimilacMomProductComponent,
  },
  {
    path: 'signin',
    component: SignInComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'sign-up',
    component: SignupComponent,
  },
  {
    path: 'sign-up/:id',
    component: SignupComponent,
  },
  {
    path: 'otp',
    component: OtpComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'salesforce-signup',
    component: SalesforceSignupComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'additional-salesforce-signup',
    component: AdditionalSalesSignupComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'crm-signup',
    component: CrmSignupComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'signup-success',
    component: SignupSuccessComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'temp-profile',
    component: TempProfileComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'grow-spinwheel',
    component: GrowSpinWheelComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'grow-spinwheel/:id',
    component: GrowSpinWheelComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'similac-spinwheel',
    component: SimilacSpinWheelComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'similac-spinwheel/:id',
    component: SimilacSpinWheelComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'rewards',
    component: RewardsComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'profile-home',
    component: ProfileHomeComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'library',
    component: LibraryComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    // canActivate: [AuthGuardGuard]
  },
  {
    path: 'lucky-draw-quota-exceed',
    component: LuckyDrawQuotaExceedComponent,
  },
  {
    path: 'knowledge',
    component: KnowledgeHubComponent,
  },
  {
    path: 'scanner',
    component: SearchScannerComponent,
  },
  {
    path: 'user-profile-detail',
    component: UserProfileDetailComponent,
  },
  {
    path: 'ensure-product',
    component: EnsureProductComponent,
  },
  {
    path: 'ensure-qc',
    component: EnsureQcComponent,
  },
  {
    path: 'glucerna-product',
    component: GlucernaProductComponent,
  },
  {
    path: 'glucerna-qc',
    component: GlucernaQcComponent,
  },
  {
    path: 'pediasure-product',
    component: PediasureProductComponent,
  },
  {
    path: 'pediausre-qc',
    component: PediasureQcComponent,
  },
  {
    path: 'ensure-spin-wheel',
    component: EnsureSpinWheelComponent,
  },
  {
    path: 'glucerna-spin-wheel',
    component: GlucernaSpinWheelComponent,
  },
  {
    path: 'ensure-spin-wheel/:id',
    component: EnsureSpinWheelComponent,
  },
  {
    path: 'glucerna-spin-wheel/:id',
    component: GlucernaSpinWheelComponent,
  },
  {
    path: 'holding-page',
    component: TempHoldingComponent,
  },
  {
    path: 'pediasure-spinwheel',
    component: PediasureSpinwheelComponent,
  },
  {
    path: 'pediasure-spinwheel/:id',
    component: PediasureSpinwheelComponent,
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
