import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiService } from '../../services/api.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-temp-holding',
  templateUrl: './temp-holding.component.html',
  styleUrls: ['./temp-holding.component.scss']
})
export class TempHoldingComponent implements OnInit {
  scanScode = null;
  mobSignupForm: FormGroup;
  submitted = false;
  initialBtn:boolean = true;
  successBtn:boolean = false;
  //showSuccess:boolean = false;
  //mobRegx = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/g
  mobRegx = /^(84|0[3|5|7|8|9])+([0-9]{9}|[0-9]{9})$/g
  constructor(  private formBuilder: FormBuilder, private apiService: ApiService,private route: ActivatedRoute,private spinner: NgxSpinnerService,) { 
    this.scanScode = this.route.snapshot.queryParams.u;
    console.log(this.scanScode, 'scanScode');
  }

  ngOnInit(): void {
    this.mobSignupForm = this.formBuilder.group({
      mobilenum: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
    });
  }
  get f() { return this.mobSignupForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.mobSignupForm.invalid) {
      this.spinner.hide();
        return;
    }else if (this.mobSignupForm.valid) {     // Proceed here if form is valid
      let submitedData = this.mobSignupForm.value ? this.mobSignupForm.value.mobilenum.toString() : '';
      submitedData = submitedData.replace(/\b0+/g, '');
      console.log(submitedData, 'submited')
      // let formData = {
      //   "root":{
      //     "customer":[
      //       {
      //         "mobile": submitedData,
      //       }
      //     ]
      //   }
      // }
      let formData = {
        "root":{
          "customer":[
            {
              "mobile": submitedData,
              "custom_fields": {
                "field": [
                    {
                        "name": "signupQRcode",
                        "value": this.scanScode
                    },
                ]
              }

            }
          ]
        }
      }
      // let formData = {
      //   "mobile": submitedData
      // }
      this.apiService.addUser(formData).pipe(first())
      .subscribe(res=>{
        this.spinner.hide();
        this.successBtn = true;
        this.initialBtn = false;
        console.log(res, '======= resp =====');
      },err=> {
        console.log(err, '=== error list =======')
      });
    }
  }
}
