<app-header [hidden]="showScanner"></app-header>
<!-- <app-profile-home-breadcrumb [hidden]="showScanner"></app-profile-home-breadcrumb> -->
<section class="similacMomProductContainer" [hidden]="showScanner">
  <!-- <img class="img-fluid" (click)="goToSignUp()" *ngIf="categoryCode == 'Similac' || categoryCode == 'Grow'" src="../../../assets/images/bannercareshare.png"> -->

  <img
    class="img-fluid"
    (click)="goToSignUp()"
    src="../../../assets/images/profile-home/welcome.png"
    alt="banner"
  />

  <section class="similacInnerSectionOut pt-3">
    <section class="similacVannilaOut">
      <!-- <img class="img-fluid" *ngIf="categoryCode == 'Similac' || categoryCode == 'Grow'" src="../../../assets/images/benefitsImage.png" /> -->
      <img
        class="img-fluid"
        src="../../../assets/images//profile-home/benefits.png"
        alt="benefits"
      />
      <small class="textBlack">{{ "PROFILE_HOME.PARA1" | translate }}</small>
      <!-- <div class="row justify-content-center mt-4">
                <div class="col-4 pr-0 text-right">
                    <img class="leftImageWithContent" src="../../../assets/images/giftImage2.png" />
                </div>
                <div class="col-8 pl-0">
                    <p class="blueTitleLarge mb-0">{{ 'PROFILE_HOME.PARA2_LINE1' | translate }}</p>
                    <p class="blueTitleLarge mt-0">{{ 'PROFILE_HOME.PARA2_LINE2' | translate }}</p>
                </div>
            </div> -->

      <!-- Temp Removed -->

      <!-- <p class="mt-3 textBlack">{{ 'PROFILE_HOME.PARA3' | translate }}</p> -->

      <!-- <small class="d-block mt-3">{{ 'PROFILE_HOME.SMALL_TEXT1' | translate }}</small>
            <small class="d-block">{{ 'PROFILE_HOME.SMALL_TEXT2' | translate }}</small>
            <div class="btnsOut">
                <div class="row">
                    <div class="col-6">
                        <button class="orangeBtn" (click)="gotToRewardsGrow()">{{ 'PROFILE_HOME.GROW_BTN_TEXT' |
                            translate }}</button>
                    </div>
                    <div class="col-6">
                        <button class="orangeBtn" (click)="gotToRewardsSimilac()">{{ 'PROFILE_HOME.SIMILAC_BTN_TEXT' |
                            translate }}</button>
                    </div>
                </div>
            </div> -->
    </section>
  </section>
  <div class="text-center mb-4">
    <button class="blueBtn" routerLink="/sign-up/1">Tham gia ngay</button>
  </div>
  <!-- 
    <section>
        <div class="container">
            <div class="btnsOut">
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="orangeBtn mb-5" (click)="gotToRewardsGrow()">{{ 'PROFILE_HOME.GROW_BTN_TEXT' |
                            translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

  <!-- <img class="img-fluid" src="../../../assets/images/gift-banner.png">

    <section class="orangeBlock text-center">
        <div class="row my-3">
            <div class="col-4 text-right pr-0">
                <img class="leftImageWithContent" src="../../../assets/images/factoryimagerounded.png" />
            </div>
            <div class="col-8 text-center pl-0">
                <p class="boldWhiteText mb-0">{{ 'PROFILE_HOME.RIGHT_DESC_LINE1' | translate }}</p>
                <p class="boldWhiteText mb-0">{{ 'PROFILE_HOME.RIGHT_DESC_LINE2' | translate }}</p>
                <p class="boldWhiteText">{{ 'PROFILE_HOME.RIGHT_DESC_LINE3' | translate }}</p>
            </div>
        </div>

        <p>{{ 'PROFILE_HOME.PARA4' | translate }}</p>

        <div class="row">
            <div class="col-8 pr-0">
                <img class="img-fluid mobileQrImage" src="../../../assets/images/mobileqrimage.png" />
            </div>
            <div class="col-4 pl-0 text-left pt-4">
                <p class="mb-0">{{ 'PROFILE_HOME.RIGHT_DESC1_LINE1' | translate }}</p>
                <p class="mb-0">{{ 'PROFILE_HOME.RIGHT_DESC1_LINE2' | translate }}</p>
                <p class="text-uppercase mb-0 textBold">{{ 'PROFILE_HOME.RIGHT_DESC1_LINE3' | translate }}</p>
                <p class="text-uppercase textBold mb-2">{{ 'PROFILE_HOME.RIGHT_DESC1_LINE4' | translate }}</p>
                <button class="btn blueBtn btn-primary btn-block" (click)=" onClickScanner()">{{
                    'PROFILE_HOME.RIGHT_DESC_BTN' | translate }}</button>
            </div>
        </div>
        <small class="d-block">{{ 'PROFILE_HOME.SMALL_TEXT3' | translate }}</small>
    </section>

    <section class="similacInnerSectionOut pt-3 pb-0">
        <section class="similacVannilaOut">
            <div class="row justify-content-center mt-3">
                <div class="col-4 pr-0 text-right">
                    <img class="leftImageWithContent" src="../../../assets/images/book.png" />
                </div>
                <div class="col-8 pl-0">
                    <p class="blueTitleLarge mb-0">{{ 'PROFILE_HOME.RIGHT_DESC2_LINE1' | translate }}</p>
                    <p class="blueTitleLarge mt-0">{{ 'PROFILE_HOME.RIGHT_DESC2_LINE2' | translate }}</p>
                </div>
            </div>

            <p class="textBlack text-center mt-3">{{ 'PROFILE_HOME.PARA5' | translate }}</p>
            <div class="text-center">
                <button class="orangeBtn mt-3" (click)="goToLibrary()">{{ 'PROFILE_HOME.BTN_TEXT3' | translate
                    }}</button>
            </div>
        </section>
    </section>

    <img class="img-fluid" src="../../../assets/images/profilemiddlebanner2.png">

    <section class="blueBlock text-center pt-5 px-3">
        <p class="mb-0 blueTextBold">{{ 'PROFILE_HOME.PARA6_LINE_1' | translate }}</p>
        <p class="blueTextBold">{{ 'PROFILE_HOME.PARA6_LINE_2' | translate }}</p>

        <p class="text-justify">{{ 'PROFILE_HOME.PARA7' | translate }}</p>

        <ul>
            <li>
                <div class="row">
                    <div class="col">
                        <img class="" src="../../../assets/images/bluelistImage1.png">
                        <p class="mb-0">{{ 'PROFILE_HOME.LIST_IMAGE_TEXT_LINE1' | translate }}</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="row">
                    <div class="col">
                        <img class="" src="../../../assets/images/bluelistImage2.png">
                        <p class="mb-0">{{ 'PROFILE_HOME.LIST_IMAGE_TEXT1_LINE1' | translate }}</p>
                        <p>{{ 'PROFILE_HOME.LIST_IMAGE_TEXT1_LINE2' | translate }}</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="row">
                    <div class="col">
                        <img class="" src="../../../assets/images/bluelistImage3.png">
                        <p class="mb-0">{{ 'PROFILE_HOME.LIST_IMAGE_TEXT2_LINE1' | translate }}</p>
                        <p>{{ 'PROFILE_HOME.LIST_IMAGE_TEXT2_LINE2' | translate }}</p>
                    </div>
                </div>

            </li>
        </ul>

        <p class="mb-0 blueTextBold mt-3">{{ 'PROFILE_HOME.PARA8_LINE1' | translate }}</p>
        <p class="mb-0 blueTextBold">{{ 'PROFILE_HOME.PARA8_LINE2' | translate }}</p>

        <ul class="nav nav-tabs tabsContainer" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="grow-tab" data-toggle="tab" href="#grow" role="tab" aria-controls="grow"
                    aria-selected="true">{{ 'PROFILE_HOME.SIMILAC_SECTION.LEFT_BTN' | translate
                    }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="similac-tab" data-toggle="tab" href="#similac" role="tab"
                    aria-controls="similac" aria-selected="false">{{ 'PROFILE_HOME.SIMILAC_SECTION.RIGHT_BTN' |
                    translate
                    }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="grow" role="tabpanel" aria-labelledby="grow-tab">

                <section>
                    <div class="cardsBlock">
                        <ul>
                            <li>
                                <div class="row">
                                    <div class="col">
                                        <img class="" src="../../../assets/images/growlist1.png">
                                        <p class="textSmall">{{ 'PROFILE_HOME.GROW_SECTION.LIST_PARA1' | translate }}
                                            <span class="textBold">{{
                                                'PROFILE_HOME.GROW_SECTION.LIST_PARA1_BOLD' | translate }}</span>
                                        </p>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div class="row">
                                    <div class="col">
                                        <img class="" src="../../../assets/images/growlist2.png">
                                        <p class="textSmall">{{ 'PROFILE_HOME.GROW_SECTION.LIST_PARA2' | translate }}
                                            <span class="textBold"> {{
                                                'PROFILE_HOME.GROW_SECTION.LIST_PARA2_BOLD' | translate }} </span>
                                        </p>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div class="row">
                                    <div class="col">
                                        <img class="" src="../../../assets/images/growlist3.png">
                                        <p class="textSmall">{{ 'PROFILE_HOME.GROW_SECTION.LIST_PARA3' | translate
                                            }}<span class="textBold">{{ 'PROFILE_HOME.GROW_SECTION.LIST_PARA3_BOLD' |
                                                translate }}</span> </p>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </section>
            </div>
            <div class="tab-pane fade" id="similac" role="tabpanel" aria-labelledby="similac-tab">
                <section>
                    <div class="cardsBlock">
                        <ul>
                            <li>
                                <div class="row">
                                    <div class="col">
                                        <img class="" src="../../../assets/images/homesimilaclist1.png">
                                        <p class="textSmall">{{ 'PROFILE_HOME.SIMILAC_SECTION.LIST_PARA1_BEFORE' |
                                            translate }}<span class="textBold">{{
                                                'PROFILE_HOME.SIMILAC_SECTION.SIMILAC_EYEQ' | translate }}</span> {{
                                            'PROFILE_HOME.SIMILAC_SECTION.LIST_PARA1_AFTER' | translate }}</p>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div class="row">
                                    <div class="col">
                                        <img class="" src="../../../assets/images/homesimilaclist2.png">
                                        <p class="textSmall">{{ 'PROFILE_HOME.SIMILAC_SECTION.LIST_PARA2_BEFORE' |
                                            translate }}<span class="textBold">{{
                                                'PROFILE_HOME.SIMILAC_SECTION.SIMILAC_EYEQ' |
                                                translate
                                                }}</span>{{ 'PROFILE_HOME.SIMILAC_SECTION.LIST_PARA2_AFTER' | translate
                                            }}</p>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div class="row">
                                    <div class="col">
                                        <img class="" src="../../../assets/images/homesimilaclist3.png">
                                        <p class="textSmall">{{ 'PROFILE_HOME.SIMILAC_SECTION.LIST_PARA3' | translate }}
                                            <span class="textBold">{{
                                                'PROFILE_HOME.SIMILAC_SECTION.SIMILAC_EYEQ4' | translate }}</span>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>

            </div>
        </div>

    </section> -->

  <div class="homeTabsOut">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          <img
            class="img-fluid"
            src="../../../assets/images/loyalty-program/lp2-ci2.png"
            alt="lp1-ci1"
          />
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link active"
          id="profile-tab"
          data-toggle="tab"
          href="#profile"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          <img
            class="img-fluid"
            src="../../../assets/images/loyalty-program/lp1-ci2.png"
            alt="lp1-ci1"
          />
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="contact-tab"
          data-toggle="tab"
          href="#contact"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          <img
            class="img-fluid"
            src="../../../assets/images/loyalty-program/lp3-ci2.png"
            alt="lp1-ci1"
          />
        </a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="contents nutritionalPrd">
          <h1>
            Tính năng truy xuất nguồn gốc <br />
            sản phẩm dinh dưỡng* của Abbott
          </h1>
          <p class="text-justify mt20">
            Mỗi sản phẩm dinh dưỡng của Abbott muốn được sản xuất, nhập cảnh và
            lưu thông trên thị trường đến tay người tiêu dùng đều phải qua khâu
            kiểm định khắt khe. Truy xuất nguồn gốc của từng lon sản phẩm giúp
            bạn biết được thông tin nhà máy, ngày nhập cảnh, quy trình kiểm định
            chất lượng, …của lon sản phẩm.
          </p>
          <small class="text-center d-block">
            <sup>*</sup>Áp dụng cho một số sản phẩm trong chương trình Care &
            Share
          </small>
          <strong class="SiBrandonGrotesqueBold mt20 d-block text-center">
            Quét mã QR1 dưới đáy lon để<br />
            TRUY XUẤT NGUỒN GỐC LON SỮA
          </strong>
          <div class="text-center mt20">
            <button class="blueBtn" routerLink="/scanner">
              Quét mã QR1 ngay
            </button>
          </div>
        </div>
        <div class="bottomImg">
          <img
            class="img-fluid"
            src="../../../assets/images/loyalty-program/qr-code1.png"
            alt="qr-code1"
          />
        </div>
      </div>
      <div
        class="tab-pane fade show active"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="contents">
          <h1>Chương trình quà tặng <br />sống khoẻ an vui cho cả nhà</h1>
          <p class="mt20 text-justify">
            Tham gia chương trình, bạn có cơ hội nhận được những phần quà cực kỳ
            hấp dẫn và hữu ích cho tất cả các thành viên trong gia đình, từ các
            vật dụng chăm sóc sức khỏe, cho đến những món đồ chơi thông minh. Sở
            hữu quà tặng từ Abbott ngay tại đây bạn nhé<sup>**</sup>!
          </p>
          <small class="text-center d-block">
            <sup>**</sup>Quà tặng không áp dụng cho các sản phẩm dinh dưỡng công
            thức cho trẻ đến 24 tháng tuổi
          </small>
          <div class="text-center mt20">
            <button class="blueBtn" routerLink="/rewards">
              Khám phá quà tặng hấp dẫn
            </button>
          </div>
        </div>
        <div class="bottomImg">
          <img
            class="img-fluid"
            src="../../../assets/images/loyalty-program/gift-banner.png"
            alt="gift-banner"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="contact"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <div class="contents">
          <h1>Khám phá kiến thức khoa học chăm sóc sức khoẻ cho cả nhà</h1>
          <p class="text-justify mt20">
            Bên cạnh đó, bạn còn được khám phá nguồn kiến thức hữu ích, đa dạng
            về khoa học dinh dưỡng; từ bí quyết tăng cường sức đề kháng, sức
            khỏe khối cơ cho cha mẹ, kiểm soát đường huyết cho thành viên đái
            tháo đường; đến bí quyết nuôi con phát triển toàn diện.
          </p>
          <div class="text-center mt20">
            <button class="blueBtn" routerLink="/sign-up/1">
              Đăng ký ngay
            </button>
          </div>
        </div>
        <div class="bottomImg">
          <img
            class="img-fluid"
            src="../../../assets/images/loyalty-program/group-family.png"
            alt="group-family"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Loaylty Program - Homepage-01 -->
  <!-- <section class="loyaltyProgramOut">
        <div class="bottomImg">
            <img class="img-fluid" src="../../../assets/images/loyalty-program/gift-banner.png" alt="gift-banner" />
        </div>
        <div class="lighterBlueBg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Tận hưởng muôn vàn lợi ích từ Abbott <br> Care & Share Home chỉ với 3 bước</h1>
                        <p class="text-center">Để tham gia, bạn chỉ cần làm theo 3 bước đơn giản dưới đây</p>
                    </div>
                </div>
                <div class="row mt20">
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c1.png" alt="c1" />
                        <p class="text-center mt10">Mua sản phẩm, quét mã QR dưới đáy lon và nắp lon</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c2.png" alt="c2" />
                        <p class="text-center mt10">Đăng nhập/Đăng ký</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c3.png" alt="c3" />
                        <p class="text-center mt10">Tận hưởng các lợi ích</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

  <!-- Loaylty Program - Homepage-02 -->
  <!-- <section class="loyaltyProgramOut">
        <div class="topImgOut greyGradientBg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center">
                            <button class="blueBtn" routerLink="/sign-up/1">Tham gia ngay</button>
                        </div>
                        <ul class="threeCircleImage">
                            <li>
                                <img class="img-fluid" src="../../../assets/images/loyalty-program/lp2-ci1.png"
                                    alt="loyalty program" />
                            </li>
                            <li>
                                <img class="img-fluid" src="../../../assets/images/loyalty-program/lp2-ci2.png"
                                    alt="loyalty program" />
                            </li>
                            <li>
                                <img class="img-fluid" src="../../../assets/images/loyalty-program/lp1-ci3.png"
                                    alt="loyalty program" />
                            </li>
                        </ul>
                        <h1>Tính năng truy xuất nguồn gốc <br> sản phẩm dinh dưỡng* của Abbott</h1>
                        <p class="text-justify mt20">
                            Mỗi sản phẩm dinh dưỡng của Abbott muốn được sản xuất, nhập cảnh và lưu thông trên thị trường đến tay người tiêu dùng đều phải qua khâu kiểm 
                            định khắt khe. Truy xuất nguồn gốc của từng lon sản phẩm giúp bạn biết được thông tin nhà máy, ngày nhập cảnh, quy trình kiểm định chất lượng,
                            …của lon sản phẩm.
                        </p>
                        <small class="text-center d-block">
                            <sup>*</sup>Áp dụng cho một số sản phẩm trong chương trình Care & Share
                            tuổi
                        </small>
                        <strong class="SiBrandonGrotesqueBold mt20 d-block text-center">
                            Quét mã QR1 dưới đáy lon để<br>
                            TRUY XUẤT NGUỒN GỐC LON SỮA
                        </strong>
                        <div class="text-center mt20">
                            <button class="blueBtn" routerLink="/scanner">Quét mã QR1 ngay</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomImg">
            <img class="img-fluid" src="../../../assets/images/loyalty-program/qr-code1.png" alt="qr-code1" />
        </div>
        <div class="lighterBlueBg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Tận hưởng muôn vàn lợi ích từ Abbott <br> Care & Share Home chỉ với 3 bước</h1>
                        <p class="text-center">Để tham gia, bạn chỉ cần làm theo 3 bước đơn giản dưới đây</p>
                    </div>
                </div>
                <div class="row mt20 mb30">
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c1.png" alt="c1" />
                        <p class="text-center mt10">Mua sản phẩm, quét mã QR dưới đáy lon và nắp lon</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c2.png" alt="c2" />
                        <p class="text-center mt10">Đăng nhập/Đăng ký</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c3.png" alt="c3" />
                        <p class="text-center mt10">Tận hưởng các lợi ích</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

  <!-- Loaylty Program - Homepage-03 -->
  <!-- <section class="loyaltyProgramOut">
        <div class="topImgOut">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center">
                            <button class="blueBtn" routerLink="/sign-up/1">Tham gia ngay</button>
                        </div>
                        <ul class="threeCircleImage">
                            <li>
                                <img class="img-fluid" src="../../../assets/images/loyalty-program/lp2-ci1.png"
                                    alt="loyalty program" />
                            </li>
                            <li>
                                <img class="img-fluid" src="../../../assets/images/loyalty-program/lp3-ci2.png"
                                    alt="loyalty program" />
                            </li>
                            <li>
                                <img class="img-fluid" src="../../../assets/images/loyalty-program/lp1-ci1.png"
                                    alt="loyalty program" />
                            </li>
                        </ul>
                        <h1>Khám phá kiến thức khoa học chăm sóc sức khoẻ cho cả nhà</h1>
                        <p class="text-justify mt20">
                            Bên cạnh đó, bạn còn được khám phá nguồn kiến thức hữu ích, đa dạng về khoa học dinh dưỡng; từ bí quyết tăng cường sức đề kháng, 
                            sức khỏe khối cơ cho cha mẹ, kiểm soát đường huyết cho thành viên đái tháo đường; đến bí quyết nuôi con phát triển toàn diện.
                        </p>
                        <div class="text-center mt20">
                            <button class="blueBtn" routerLink="/sign-up/1">Đăng ký ngay</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomImg">
            <img class="img-fluid" src="../../../assets/images/loyalty-program/group-family.png" alt="group-family" />
        </div>
        <div class="lighterBlueBg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Tận hưởng muôn vàn lợi ích từ Abbott <br> Care & Share Home chỉ với 3 bước</h1>
                        <p class="text-center">Để tham gia, bạn chỉ cần làm theo 3 bước đơn giản dưới đây</p>
                    </div>
                </div>
                <div class="row mt20">
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c1.png" alt="c1" />
                        <p class="text-center mt10">Mua sản phẩm, quét mã QR dưới đáy lon và nắp lon</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c2.png" alt="c2" />
                        <p class="text-center mt10">Đăng nhập/Đăng ký</p>
                    </div>
                    <div class="col-4">
                        <img class="img-fluid" src="../../../assets/images/loyalty-program/c3.png" alt="c3" />
                        <p class="text-center mt10">Tận hưởng các lợi ích</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

  <section class="tabsAccordionOut">
    <div class="container">
      <div class="row newSection">
        <div class="d-block m-auto">
          <p class="font-blue text-center mb-1">
            Tận hưởng muôn vàn lợi ích từ Abbott<br />
            Care & Share Home chỉ với 3 bước
          </p>
          <p class="fs12 mx-auto mb-3 mt-1">
            Để tham gia, bạn chỉ cần làm theo 3 bước đơn giản dưới đây
          </p>
        </div>
        <div class="d-flex justify-content-around majorImages mt-2">
          <div class="col-4">
            <img src="../../../assets/images/bluelistImage1.png" />
            <p>Mua sản phẩm, quét mã QR dưới đáy lon và nắp lon</p>
          </div>
          <div class="col-4">
            <img src="../../../assets/images/bluelistImage2.png" />
            <p class="mWidth mx-auto">Đăng nhập/ Đăng ký</p>
          </div>
          <div class="col-4">
            <img src="../../../assets/images/bluelistImage3.png" />
            <p class="mWidth mx-auto">Tận hưởng các lợi ích</p>
          </div>
        </div>
        <!-- <div class="row minorImages px-2 mt-1 mb-4">
          <a><img src="../../../assets/images/laz2.png" /></a>
          <a><img src="../../../assets/images/shop2.png" /></a>
          <a><img src="../../../assets/images/tiki2.png" /></a>
        </div> -->
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h1>Áp dụng cho các dòng sản phẩm</h1>
          <div class="tabsOut">
            <ul class="nav nav-tabs tabsContainer" id="myTab" role="tablist">
              <li class="nav-item active">
                <a
                  class="nav-link active"
                  id="grow-tab"
                  data-toggle="tab"
                  href="#grow"
                  role="tab"
                  aria-controls="grow"
                  aria-selected="false"
                  >Trẻ em</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="similac-tab"
                  data-toggle="tab"
                  href="#similac"
                  role="tab"
                  aria-controls="similac"
                  aria-selected="true"
                  >Người lớn</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="grow"
                role="tabpanel"
                aria-labelledby="grow-tab"
              >
                <div id="accordion" class="accordionOut">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <button
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Abbott Grow
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      class="collapse"
                      aria-labelledby="headingOne"
                    >
                      <div class="card-body">
                        <ul class="lists">
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/grow-3-hires.png"
                                  alt="grow-3-hires"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Abbott Grow Gold 3+</h6>
                                <p>
                                  Thực phẩm bổ sung cho trẻ từ <br />
                                  3 đến 6 tuổi
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/grow-6-hires.png"
                                  alt="grow-6-hires"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Abbott Grow Gold 6+</h6>
                                <p>
                                  Thực phẩm bổ sung cho trẻ từ <br />
                                  6 tuổi trở lên
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/abbott-grow-1-900-g-min-0111-copy-3.png"
                                  alt="abbott-grow-1-900-g-min-0111-copy-3"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Abbott Grow 4</h6>
                                <p>
                                  Thực phẩm bổ sung cho trẻ từ 2 <br />
                                  tuổi trở lên
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <button
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Similac
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                    >
                      <div class="card-body">
                        <ul class="lists">
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/similac-mom-strawberry-900-g-copy.png"
                                  alt="similac-mom-strawberry-900-g-copy"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Similac MOM hương vani</h6>
                                <p>
                                  Thực phẩm bổ sung cho bà mẹ <br />
                                  mang thai và cho con bú
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/similac-mom-strawberry-900-g-copy.png"
                                  alt="similac-mom-strawberry-900-g-copy"
                                />
                              </div>
                              <div class="col-9">
                                <h6>
                                  Similac MOM <br />
                                  hương sữa chua dâu
                                </h6>
                                <p>
                                  Thực phẩm bổ sung cho bà mẹ <br />
                                  mang thai và cho con bú
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/layer-2.png"
                                  alt="layer-2"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Similac 4</h6>
                                <p>Thực phẩm bổ sung cho trẻ 2-6 tuổi</p>
                              </div>
                            </div>
                          </li>
                        
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <button
                        class="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Pediasure
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse show"
                      aria-labelledby="headingThree"
                    >
                      <div class="card-body">
                        <ul class="lists">
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/pediasure.png"
                                  alt="pediasure"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Pediasure hương vani</h6>
                                <p>
                                  Thực phẩm dinh dưỡng y học cho bé <br />từ
                                  1-10 tuổi
                                </p>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/pediasure-ten-plus.png"
                                  alt="pediasure"
                                />
                              </div>
                              <div class="col-9">
                                <h6>PediaSure 10+ hương vani</h6>
                                <p>
                                  Thực phẩm bổ sung dành cho trẻ từ 10 tuổi trở
                                  lên
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="similac"
                role="tabpanel"
                aria-labelledby="similac-tab"
              >
                <div id="accordion" class="accordionOut">
                  <div class="card">
                    <div class="card-header" id="headingOne1">
                      <button
                        class="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne1"
                        aria-expanded="true"
                        aria-controls="collapseOne1"
                      >
                        Ensure Gold
                      </button>
                    </div>
                    <div
                      id="collapseOne1"
                      class="collapse show"
                      aria-labelledby="headingOne1"
                    >
                      <div class="card-body">
                        <ul class="lists">
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/lon-Ensure-Armour-FS-850g.png"
                                  alt="ensure-shield-850-g-fs-vanilla-850-g-2"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Ensure Gold hương vani</h6>
                                <p>Thực phẩm dinh dưỡng y học</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/lon-Ensure-Armour-COFFEE-850g.png"
                                  alt="ensure-gold-coffee-850-g-a-02"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Ensure Gold hương cà phê</h6>
                                <p>Thực phẩm dinh dưỡng y học</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/lon-Ensure-Armour-VANI-LS-850g.png"
                                  alt="lon Ensure Armour VANI LS 850g"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Ensure Gold hương vani ít ngọt</h6>
                                <p>Thực phẩm dinh dưỡng y học</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/lon-Ensure-Armour-LUA-MACH-850g.png"
                                  alt="lon Ensure Armour LUA MACH 850g"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Ensure Gold hương lúa mạch</h6>
                                <p>Thực phẩm dinh dưỡng y học</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/ensure-gold-huong-hanh-nhan-850-g-12.png"
                                  alt="ensure-gold-huong-hanh-nhan-850-g-12"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Ensure Gold Green Health</h6>
                                <p>Thực phẩm dinh dưỡng y học</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo2">
                      <button
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo2"
                        aria-expanded="false"
                        aria-controls="collapseTwo2"
                      >
                        Glucerna
                      </button>
                    </div>
                    <div
                      id="collapseTwo2"
                      class="collapse"
                      aria-labelledby="headingTwo2"
                    >
                      <div class="card-body">
                        <ul class="lists">
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/abbott-glucerna-h-ng-vani-850-g.png"
                                  alt="abbott-glucerna-h-ng-vani-850-g"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Glucerna hương vani</h6>
                                <p>
                                  Thực phẩm dinh dưỡng y học.<br />
                                  Dinh dưỡng chuyên biệt cho người đái tháo
                                  đường
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-3">
                                <img
                                  class="img-fluid"
                                  src="../../../assets/images/loyalty-program/abbott-glucerna-h-ng-l-a-m-ch-850-g.png"
                                  alt="abbott-glucerna-h-ng-l-a-m-ch-850-g"
                                />
                              </div>
                              <div class="col-9">
                                <h6>Glucerna hương lúa mạch</h6>
                                <p>
                                  Thực phẩm dinh dưỡng y học.<br />
                                  Dinh dưỡng chuyên biệt cho người đái tháo
                                  đường
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div
    bsModal
    #invalidQrCodeModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
    [config]="config"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content videoModalContainer">
        <div class="modal-body">
          <section class="checkMilkcodeMdl text-center">
            <button
              type="button"
              class="close pull-right closeBtn"
              aria-label="Close"
              (click)="invalidQrCodeHide()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h2 class="blueTitle pt-3">
              {{ "INVALIDQR_CODE1.TITLE1" | translate }}
            </h2>
            <h2 class="blueTitle pt-0">
              {{ "INVALIDQR_CODE1.TITLE2" | translate }}
            </h2>
            <!-- <div>
                            <p class="pt-2 mb-0"> {{ 'INVALIDQR_CODE1.PARA1' | translate }}</p>
                            <a class="links d-block" routerLink=""> {{ 'INVALIDQR_CODE1.PARA2' | translate }}</a>
                            <p class="mb-0 pt-0"> {{ 'INVALIDQR_CODE1.PARA3' | translate }}</p>
                            <a class="pt-3 mb-4 links d-block"> {{ 'INVALIDQR_CODE1.LINK_TEXT' | translate }}</a>
                        </div> -->
            <div>
              <p class="pt-2 mb-0">
                {{ "INVALIDQR_CODE1.CODE2_PARA1" | translate }}
                <a class="links">
                  {{ "INVALIDQR_CODE1.CODE2_LINK" | translate }}</a
                >
                {{ "INVALIDQR_CODE1.CODE2_PARA2" | translate }}
              </p>
            </div>

            <div class="blueCard mt-4">
              <p class="topText">
                {{ "INVALIDQR_CODE1.CARD_TEXT_TOP" | translate }}
              </p>
              <p class="colorBlackBold pt-2 mb-0">
                {{ "INVALIDQR_CODE1.CARD_PARA" | translate }}
              </p>
              <p class="colorBlackBold pt-0">
                {{ "INVALIDQR_CODE1.CARD_PARA1" | translate }}
              </p>
              <h2 class="blueTitle">
                {{ "INVALIDQR_CODE1.CARD_BOTTOM_NUMBER" | translate }}
              </h2>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
<app-profile-home-footer [hidden]="showScanner"></app-profile-home-footer>
<app-nav-menu [hidden]="showScanner"></app-nav-menu>

<!-- testModal -->
<div
  bsModal
  #testModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content" style="height: 200px">
      <img class="qrImage" src="../../assets/images/qrimage.png" />
      <div class="modal-body p-0">
        <div class="couponModalContainer qrCodeModalContainer">
          <ul class="voucherCardList">
            <li>
              <div class="voucherCard">
                <i class="closeBtn" (click)="closeQrCode1Modal()"></i>
                <p class="mt-2">Scratch and scan the second</p>
                <p>hidden QR code to earn points!</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- qrcode1modal -->
<div
  bsModal
  #qrCode1Modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content" style="height: 200px">
      <img class="qrImage" src="../../assets/images/qrimage.png" />
      <div class="modal-body p-0">
        <div class="couponModalContainer qrCodeModalContainer">
          <ul class="voucherCardList">
            <li>
              <div class="voucherCard">
                <i class="closeBtn" (click)="closeQrCode1Modal()"></i>
                <p class="mt-2">Scratch and scan the second</p>
                <p>hidden QR code to earn points!</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #successQrCodeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content videoModalContainer">
      <div class="modal-body">
        <section class="qrCodeScanModal">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="closeSuccessModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="contents">
            <h1>{{ "QR_CODE_SCAN_POP_UP.TITLE" | translate }}</h1>
            <p *ngIf="this.categoryCode == 'Grow'">
              {{ "QR_CODE_SCAN_POP_UP.DESC1" | translate }}
              {{ myDate | date: "dd/MM/yyyy" }}.
            </p>
            <p *ngIf="this.categoryCode == 'Similac'">
              {{ "QR_CODE_SCAN_POP_UP.DESC1A" | translate }}
              {{ myDate | date: "dd/MM/yyyy" }}.
            </p>
            <a
              href="javascript:;"
              class="orangeBtn"
              (click)="growOrSimilac()"
              >{{ "QR_CODE_SCAN_POP_UP.JOIN_THE_LUCKY_WHEEL" | translate }}</a
            >
            <p *ngIf="this.categoryCode == 'Grow'">
              {{ "QR_CODE_SCAN_POP_UP.DESC2" | translate }}
              {{ global_grow_count }}
              {{ "QR_CODE_SCAN_POP_UP.DESC3" | translate }}
            </p>
            <p *ngIf="this.categoryCode == 'Similac'">
              {{ "QR_CODE_SCAN_POP_UP.DESC2" | translate }}
              {{ global_similac_count }}
              {{ "QR_CODE_SCAN_POP_UP.DESC3A" | translate }}
            </p>
            <a class="blueBtn" (click)="onClickScanner()">{{
              "QR_CODE_SCAN_POP_UP.SCAN_QR_CODE" | translate
            }}</a>
            <p class="code">COR-C-22-21</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #successQrCodeModalOne="bs-modal"
  class="modal fade"
  [hidden]="showScanner"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content videoModalContainer">
      <div class="modal-body">
        <section class="bonusRedemptionOut">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="closeModalOne()"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <h1>{{ "BONUS_REDEMPTION.BONUS_REDEMPTION_TITLE" | translate }}</h1>
          <div class="row">
            <div class="col-6">
              <div class="scanDetails">
                <img
                  src="../../../assets/images/scan_code.png"
                  alt="scan code"
                />
                <p>{{ "BONUS_REDEMPTION.QR_CODE" | translate }}</p>
              </div>
            </div>
            <div class="col-6">
              <div class="scanDetails">
                <img
                  src="../../../assets/images/mobile_scan.png"
                  alt="mobile code"
                />
                <p>
                  {{ "BONUS_REDEMPTION.ASSITIVE_APPLICATIONS" | translate }}
                </p>
              </div>
            </div>
          </div>
          <button class="orangeBtn" (click)="onClickScanner()">
            {{ "SCAN_QR_CODE_NOW" | translate }}
          </button>
          <div class="questionOut">
            <h6>{{ "BONUS_REDEMPTION.QUESTIONS" | translate }}</h6>
            <p>
              {{ "BONUS_REDEMPTION.CUSTOMER_SERVICE1" | translate }}<br />
              {{ "BONUS_REDEMPTION.CUSTOMER_SERVICE2" | translate }}
            </p>
            <a class="telNum" href="tel:19001519">19001519 </a>
          </div>
          <p class="code">COR-C-22-21</p>
        </section>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #milkCodeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content videoModalContainer">
      <div class="modal-body">
        <section class="checkMilkcodeMdl text-center">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="milkCodeHide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h2 class="blueTitle pt-3">{{ "MILK_CODE.TITLE1" | translate }}</h2>
          <h2 class="blueTitle pt-0">{{ "MILK_CODE.TITLE2" | translate }}</h2>
          <p class="mb-0 pt-0" style="font-size: 4.3vw">
            {{ "MILK_CODE.PARA" | translate }}<br />
            {{ "MILK_CODE.PARA2" | translate }}<br />
            {{ "MILK_CODE.PARA3" | translate }}<br />
            {{ "MILK_CODE.PARA4" | translate }}
          </p>
          <!-- <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA2' | translate }}</p>
                        <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA3' | translate }}</p>
                        <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA4' | translate }}</p> -->
          <button class="orangeBtn my-4" (click)="milkCodeHide()">
            {{ "MILK_CODE.BTN_TEXT" | translate }}
          </button>
          <a class="pt-3 mb-4 links d-block">
            {{ "MILK_CODE.LINK_TEXT" | translate }}</a
          >
          <div class="blueCard mt-4">
            <p class="topText">{{ "MILK_CODE.CARD_TEXT_TOP" | translate }}</p>
            <p class="colorBlackBold pt-2 mb-0">
              {{ "MILK_CODE.CARD_PARA" | translate }}
            </p>
            <p class="colorBlackBold pt-0">
              {{ "MILK_CODE.CARD_PARA1" | translate }}
            </p>
            <h2 class="blueTitle">
              {{ "MILK_CODE.CARD_BOTTOM_NUMBER" | translate }}
            </h2>
          </div>
          <p class="py-4">{{ "MILK_CODE.CODE" | translate }}</p>
        </section>
      </div>
    </div>
  </div>
</div>

<div [hidden]="!showScanner">
  <div class="video-row" fxFlex="100">
    <video class="video" #video autoplay playsinline></video>
  </div>
  <div fxFlex="100" style="display: none">
    <canvas class="video" id="canvas" #canvas></canvas>
  </div>
  <button class="cameraBtn" (click)="closeScanner()">Đóng</button>
</div>
