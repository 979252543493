<app-header></app-header>

<section class="ensureGlueProductsContainer">

    <div class="bannerOut">
        <img *ngIf="subCatCode == '3005'" class="img-fluid" src="../../../assets/images/ensure-product/ensure-coffee-banner.png" alt="ensure-green-health-banner" />
        <!-- <img *ngIf="subCatCode == '3001' || subCatCode == '3002' || subCatCode == '3003'" class="img-fluid" src="../../../assets/images/ensure-product/ensure-coffee-banner.png" alt="ensure-vanilla-banner" /> -->
        <img *ngIf="subCatCode == '3001'" class="img-fluid" src="../../../assets/images/ensure-product/vanilla/ensure-vanilla-banner.png" alt="ensurevanilla-banner" />
        <img *ngIf="subCatCode == '3002'" class="img-fluid" src="../../../assets/images/ensure-product/vanilla-less-sugar/ensure-vanilla-less-sugar-banner.png" alt="vanilla-less-sugar" />
        <img *ngIf="subCatCode == '3003'" class="img-fluid" src="../../../assets/images/ensure-product/lua-mach/ensure-lua-mach-banner.png" alt="ensure-lua-mach" />    
        <img *ngIf="subCatCode == '3004'" class="img-fluid" src="../../../assets/images/ensure-product/coffee/ensure-coffee-banner.png" alt="ensure-coffee-banner" />    
    </div>

    <!-- Ensure Product - Vanilla Starts Here -->
    <!-- <h2 class="text-center">Ensure - Vanilla</h2> -->
    <section *ngIf="subCatCode == '3001'" class="ensureBgOut">
        <div class="container">
            <div class="row mt40">
                <div class="col-12">
                    <h1>ENSURE GOLD hương vani</h1>
                </div>
            </div>
        </div>
        <div class="ensureBg">
            <div class="topProdOut">
                <i class="vanillaLessSugar">
                    <img class="img-fluid" src="../../../assets/images/ensure-product/vanilla/ensure-vanilla.png" alt="ensure-vanilla" />
                </i>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="whiteTxt text-center d-block mb20">Thực phẩm dinh dưỡng y học</small>
                        <p class="whiteTxt font-italic SiBrandonGrotesqueBold">
                            Thông tin dưới đây là thông tin trên nhãn
                            được cơ quan có thẩm quyền phê duyệt
                        </p>
                    </div>
                </div>
                <div class="ensureGoldOut">
                    <div class="row">
                        <div class="col-5">
                            <img class="img-fluid" src="../../../assets/images/ensure-product/ensure-gold.png" alt="ensure-gold" />
                        </div>
                        <div class="col-7">
                            <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">
                                ENSURE gold - CÔNG THỨC DINH DƯỠNG ĐƯỢC CHỨNG MINH LÂM SÀNG GIÚP TĂNG CƯỜNG SỨC KHỎE, 
                                THỂ CHẤT VÀ CHẤT LƯỢNG CUỘC SỐNG.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="whiteTxt">
                            Ensure Gold là công thức dinh dưỡng đầy đủ và cân đối*. 
                            Với hơn 30 nghiên cứu khoa học trong suốt 50 năm có mặt, Ensure được chứng minh lâm sàng giúp phục 
                            hồi và tăng cường sức khỏe, tăng cường miễn dịch và chất lượng cuộc sống.
                        </p>
                        <p class="whiteTxt">
                            ENSURE GOLD <span class="moi-icon"></span> với hệ dưỡng chất StrengthPro gồm HMB, YBG và nhiều dưỡng chất khác.
                        </p>
                        <small class="whiteTxt">(*) Theo FDA và luật cộng đồng châu Âu 1999/21/EC</small>
                        <div class="text-center mt20 mb20 d-flex justify-content-center">
                            <a href="https://www.lazada.vn/shop/ensure/?path=promotion-31880-0.htm&tab=promotion" target="_blank" class="orangeBtn boxShadaow plr">Mua ngay</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="milkSpillOverBg">
            <div class="carouselOut">
                <div id="carouselIndicators5" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/hmb-c-b-p.png"
                                alt="dam" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>HMB</strong> và <strong>Đạm</strong> chất lượng cao giúp bảo vệ, xây dựng  và phát triển khối cơ.
                                </p>
                                <br>
                                <p class="caption-satus">
                                    * Theo FDA và Luật Cộng đồng châu Âu 1999/21/EC
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/ybg-khi-n.png"
                                alt="28" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>YBG (Beta Glucan từ nấm men)</strong> và <strong>12 dưỡng chất</strong> giúp tăng cường miễn dịch theo 5 cách thức**.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/heart.png"
                                alt="heart" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>Giàu Omega-3, Omega-6,  Omega-9</strong> nguồn gốc thực vật hỗ trợ sức khỏe tim mạch.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/28-vitamin-con-ng.png"
                                alt="vitamin-con" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>28 vitamin</strong> và <strong>khoáng chất thiết yếu</strong> giúp cơ thể khỏe mạnh.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/fos.png"
                                alt="fos" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Chất xơ <strong>FOS</strong> hỗ trợ tiêu hóa khỏe mạnh. <strong>Các chất chống oxy hóa</strong> (Betacaroten, vitamin C, E, Kẽm và Selen) giúp bảo vệ cơ thể.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/canxi.png"
                                alt="shield" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Giàu <strong>Canxi, Phospho Và Vitamin D</strong> giúp xương chắc khỏe.
                                </p>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselIndicators5" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="1"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="2"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="3"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="4"></li>
                    </ol>
                    <a class="carousel-control-prev" href="#carouselIndicators5" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </a>
                    <a class="carousel-control-next" href="#carouselIndicators5" role="button"
                        data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
        </div>
        <div class="grayLinearBg">
            <div class="accordionOut mt40">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <a href="javascript:;" class="btn btn-link" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Công dụng
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        >
                                        <div class="card-body">
                                            <p>
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> bổ sung dinh dưỡng đầy 
                                                đủ và cân đối, vitamin, khoáng chất giúp phục hồi và tăng cường sức khỏe.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            đối tượng sử dụng
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        >
                                        <div class="card-body">
                                            <p>
                                                Người lớn, người ăn uống kém, người bệnh cần phục hồi nhanh.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            hướng dẫn sử dụng & Bảo quản
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        >
                                        <div class="card-body">
                                            <h5>hướng dẫn sử dụng</h5>
                                            <p>
                                                Để pha 230 ml, cho 185 ml nước chín nguội vào ly, vừa từ từ cho vào 6 muỗng gạt 
                                                ngang (muỗng có sẵn trong hộp, mỗi muỗng tương đương 10,1 g) hay 60,6 g bột 
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> vừa khuấy đều cho đến khi 
                                                bột tan hết.
                                            </p>
                                            <p class="threeCircleImgOut">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/ensure-product/185ml.png"
                                                    alt="185ml" />
                                            </p>
                                            <div class="whiteBox plr0 gradientBorder">
                                                <div class="topGoldenSnippet">Bổ sung chế độ ăn </div>
                                                <div class="container">
                                                    <div class="row rowm0">
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-x2.png"
                                                                alt="blue-x2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Uống 2 ly/ ngày
                                                            </p>
                                                        </div>
                                                        <div class="col-2 col text-center">
                                                            <p class="darkOrangeForeColor SiBrandonGrotesqueBold mt40">
                                                                hoặc
                                                            </p>
                                                        </div>
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-group-2.png"
                                                                alt="blue-group-2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Theo hướng dẫn của chuyên viên dinh dưỡng
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="linearOrangeOut mt20 mb20">
                                                <strong>Thay thế hoàn toàn bữa ăn qua ống thông cho người
                                                    bệnh:</strong> Theo hướng dẫn của bác sĩ hoặc chuyên viên dinh
                                                dưỡng.
                                            </div>
                                            <div class="whiteBox gradientBorder">
                                                <div class="topGoldenSnippet">Khi pha theo đúng hướng dẫn</div>
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-3.png"
                                                                alt="orange-group-3" />
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-4.png"
                                                                alt="orange-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="mt20 mb20">chú ý</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                Sử dụng <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> dưới sự giám sát của bác sĩ hoặc chuyên gia dinh dưỡng, 
                                                cần theo đúng các hướng dẫn.
                                            </div>
                                            <div class="whiteBox pt50 mt30 gradientBorder">
                                                <div class="topGoldenSnippet">
                                                    Ensure Gold
                                                </div>
                                                <ul>
                                                    <li>
                                                        <p>Không chứa Gluten. Rất ít Trans-fat và Lactose, phù hợp cho người bất dung nạp Lactose </p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho người bệnh Galactosemia</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng qua đường tĩnh mạch</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho trẻ em trừ khi có hướng dẫn của chuyên gia y tế</p>
                                                    </li>
                                                    <li>
                                                        <p>Sử dụng cho người bệnh với sự giám sát của nhân viên y tế</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt20 mb20">Bảo quản</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                <ul>
                                                    <li>
                                                        <p>Bảo quản hộp chưa mở ở nhiệt độ phòng</p>
                                                    </li>
                                                    <li>
                                                        <p>Hộp đã mở phải được đậy kín và bảo quản ở nơi khô mát, nhưng không cho vào tủ lạnh </p>
                                                    </li>
                                                    <li>
                                                        <p>Khi đã mở phải sử dụng trong vòng 3 tuần</p>
                                                    </li>
                                                    <li>
                                                        <p><strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> đã pha phải dùng ngay hay đậy kín cho vào tủ lạnh và dùng trong vòng 24 giờ.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt30">DÙNG NUÔI ĂN QUA ỐNG THÔNG</h5>
                                            <p>
                                                Theo hướng dẫn của bác sĩ/ chuyên gia dinh dưỡng. Khi bắt đầu nuôi ăn qua ống thông, 
                                                lưu lượng, thể tích và độ pha loãng được điều chỉnh tùy thuộc vào tình trạng và sự 
                                                dung nạp của người bệnh. Lưu ý đề phòng sự nhiễm khuẩn trong quá trình chuẩn bị và 
                                                nuôi ăn qua ống thông.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                            Thành phần & Phân tích thành phần
                                        </a>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        >
                                        <div class="card-body">
                                            <h5 class="mt20">Thành phần</h5>
                                            <p>
                                                Tinh bột bắp thuỷ phân, <strong>DẦU THỰC VẬT</strong> (dầu hướng dương giàu oleic, dầu đậu nành, dầu hạt cải), 
                                                sucrose, natri caseinat, đạm đậu nành tinh chế, oligofructose, đạm whey cô đặc, 
                                                <strong>KHOÁNG CHẤT</strong> (kali citrat, tricanxi phosphat, magiê sulfat, magiê clorid, kali clorid, natri citrat, 
                                                dikali phosphat, natri clorid, canxi carbonat, kali hydroxid, sắt sulfat, kẽm sulfat, mangan sulfat, đồng sulfat, 
                                                crôm clorid, kali iodid, natri molybdat, natri selenat), 
                                                <strong>canxi β-hydroxy-β-methylbutyrat monohydrat (CaHMB)</strong>, hương liệu vani tổng hợp, 
                                                <strong>Beta Glucan từ nấm men (YBG), VITAMIN </strong> (acid ascorbic, ascorbyl palmitat, vitamin E, hỗn hợp tocopherol, 
                                                canxi pantothenat, niacinamid, pyridoxin hydroclorid, riboflavin, thiamin hydroclorid, vitamin A palmitat, acid folic, beta caroten, 
                                                phylloquinon, biotin, vitamin D3, cyanocobalamin), cholin clorid, taurin, L-carnitin.
                                            </p>
                                            <div class="row mt20 mb20">
                                                <div class="col-12">
                                                    <img
                                                    class="img-fluid"
                                                    src="../../../assets/images/ensure-product/ensure-ingredient-chart.png"
                                                    alt="ensure-ingredient-chart" />
                                                </div>
                                            </div>
                                            <p>
                                                (*) Cách pha chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold:</strong> 
                                                263,6 g bột pha trong nước để được 1 lít <strong class="SiBrandonGrotesqueBold">Ensure Gold.</strong>
                                                Cách pha 1 ly chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> 230 ml: 6 muỗng bột (tương đương 60,6 g) pha trong 185 
                                                ml nước. <br>
                                                (**) Đối với nam giới 19-60 tuổi. %RNI cho nữ giới và các lứa tuổi khác, 
                                                xem tại www.ensure.com.vn. 
                                            </p>
                                            <h5 class="mt20">Ngày sản xuất (MFG) và hạn sử dụng (EXP) xem ở đáy hộp.</h5>
                                            <p>
                                                <strong>Sản xuất tại:</strong> Abbott Manufacturing Singapore
                                                Private Limited<br>
                                                26 Tuas South Avenue 10, Singapore 637437
                                                A subsidiary of Abbott Laboratories, North Chicago, IL 60064,
                                                USA<br><br>

                                                <strong>Xuất xứ:</strong> Singapore<br><br>

                                                <strong>Đăng ký bởi:</strong> Chi nhánh công ty TNHH Dinh dưỡng 3A (Việt Nam) tại thành phố Biên Hòa tỉnh Đồng Nai. Số 259/8/38, quốc lộ 51, KP1, phường Long Bình Tân, thành phố Biên Hòa, tỉnh Đồng Nai.
                                                <br><br>

                                                <strong>Nhập khẩu & phân phối:</strong><br>
                                                Công ty TNHH Dinh dưỡng 3A (Việt Nam)
                                                Centec Tower, 72-74 Nguyễn Thị Minh Khai,
                                                phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Ensure Product - Vanilla Ends Here -->
    
    <!-- Ensure Product - Vanilla Less Sugar Starts Here -->
    <!-- <h2 class="text-center">Ensure - Vanilla Less Sugar</h2> -->
    <section *ngIf="subCatCode == '3002'" class="ensureBgOut">
        <div class="container">
            <div class="row mt40">
                <div class="col-12">
                    <h1>ENSURE GOLD <br> hương vani ít ngọt</h1>
                </div>
            </div>
        </div>
        <div class="ensureBg">
            <div class="topProdOut">
                <i class="vanillaLessSugar">
                    <img class="img-fluid" src="../../../assets/images/ensure-product/vanilla-less-sugar/ensure-vanilla-less-sugar.png" alt="ensure-vanilla-less-sugar" />
                </i>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="whiteTxt text-center d-block mb20">Thực phẩm dinh dưỡng y học</small>
                        <p class="whiteTxt font-italic SiBrandonGrotesqueBold">
                            Thông tin dưới đây là thông tin trên nhãn
                            được cơ quan có thẩm quyền phê duyệt
                        </p>
                    </div>
                </div>
                <div class="ensureGoldOut">
                    <div class="row">
                        <div class="col-5">
                            <img class="img-fluid" src="../../../assets/images/ensure-product/ensure-gold.png" alt="ensure-gold" />
                        </div>
                        <div class="col-7">
                            <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">
                                ENSURE gold - CÔNG THỨC DINH DƯỠNG ĐƯỢC CHỨNG MINH LÂM SÀNG giúp TĂNG CƯỜNG SỨC KHỎE,
                                THỂ CHẤT VÀ CHẤT LƯỢNG CUỘC SỐNG.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="whiteTxt">
                            Ensure Gold là công thức dinh dưỡng đầy đủ và cân đối*. 
                            Với hơn 30 nghiên cứu khoa học trong suốt 50 năm có mặt, Ensure được chứng minh lâm sàng giúp phục 
                            hồi và tăng cường sức khỏe, tăng cường miễn dịch và chất lượng cuộc sống.
                        </p>
                        <p class="whiteTxt">
                            ENSURE GOLDn <span class="moi-icon"></span> với hệ dưỡng chất StrengthPro gồm HMB, YBG và nhiều dưỡng chất khác.
                        </p>
                        <small class="whiteTxt">(*) Theo FDA và luật cộng đồng châu Âu 1999/21/EC</small>
                        <div class="text-center mt20 mb20 d-flex justify-content-center">
                            <a href="https://www.lazada.vn/shop/ensure/?path=promotion-31880-0.htm&tab=promotion" target="_blank" class="orangeBtn boxShadaow plr">Mua ngay</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="milkSpillOverBg">
            <div class="carouselOut">
                <div id="carouselIndicators5" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/hmb-c-b-p.png"
                                alt="dam" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>HMB</strong> và <strong>Đạm</strong> chất lượng cao giúp bảo vệ, xây dựng  và phát triển khối cơ.
                                </p>
                                <br>
                                <p class="caption-satus">
                                    * Theo FDA và Luật Cộng đồng châu Âu 1999/21/EC
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/ybg-khi-n.png"
                                alt="28" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>YBG (Beta Glucan từ nấm men)</strong> và <strong>12 dưỡng chất</strong> giúp tăng cường miễn dịch theo 5 cách thức**.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/heart.png"
                                alt="heart" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>Giàu Omega-3, Omega-6,  Omega-9</strong> nguồn gốc thực vật hỗ trợ sức khỏe tim mạch.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/28-vitamin-con-ng.png"
                                alt="vitamin-con" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>28 vitamin</strong> và <strong>khoáng chất thiết yếu</strong> giúp cơ thể khỏe mạnh.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/fos.png"
                                alt="fos" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Chất xơ <strong>FOS</strong> hỗ trợ tiêu hóa khỏe mạnh. <strong>Các chất chống oxy hóa</strong> (Betacaroten, vitamin C, E, Kẽm và Selen) giúp bảo vệ cơ thể.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/canxi.png"
                                alt="shield" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Giàu <strong>Canxi, Phospho Và Vitamin D</strong> giúp xương chắc khỏe.
                                </p>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselIndicators5" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="1"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="2"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="3"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="4"></li>
                    </ol>
                    <a class="carousel-control-prev" href="#carouselIndicators5" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </a>
                    <a class="carousel-control-next" href="#carouselIndicators5" role="button"
                        data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
        </div>
        <div class="grayLinearBg">
            <div class="accordionOut mt40">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <a href="javascript:;" class="btn btn-link" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Công dụng
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        >
                                        <div class="card-body">
                                            <p>
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> bổ sung dinh dưỡng đầy 
                                                đủ và cân đối, vitamin, khoáng chất giúp phục hồi và tăng cường sức khỏe.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            đối tượng sử dụng
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        >
                                        <div class="card-body">
                                            <p>
                                                Người lớn, người ăn uống kém, người bệnh cần phục hồi nhanh.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            hướng dẫn sử dụng & Bảo quản
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        >
                                        <div class="card-body">
                                            <h5>hướng dẫn sử dụng</h5>
                                            <p>
                                                Để pha 230 ml, cho 185 ml nước chín nguội vào ly, vừa từ từ cho vào 6 muỗng gạt 
                                                ngang (muỗng có sẵn trong hộp, mỗi muỗng tương đương 10,1 g) hay 60,6 g bột 
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> vừa khuấy đều cho đến khi bột tan hết.
                                            </p>
                                            <p class="threeCircleImgOut">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/ensure-product/185ml.png"
                                                    alt="185ml" />
                                            </p>
                                            <div class="whiteBox plr0 gradientBorder">
                                                <div class="topGoldenSnippet">Bổ sung chế độ ăn </div>
                                                <div class="container">
                                                    <div class="row rowm0">
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-x2.png"
                                                                alt="blue-x2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Uống 2 ly/ ngày
                                                            </p>
                                                        </div>
                                                        <div class="col-2 col text-center">
                                                            <p class="darkOrangeForeColor SiBrandonGrotesqueBold mt40">
                                                                hoặc
                                                            </p>
                                                        </div>
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-group-2.png"
                                                                alt="blue-group-2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Theo hướng dẫn của chuyên viên dinh dưỡng
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="linearOrangeOut mt20 mb20">
                                                <strong>Thay thế hoàn toàn bữa ăn qua ống thông cho người
                                                    bệnh:</strong> Theo hướng dẫn của bác sĩ hoặc chuyên viên dinh
                                                dưỡng.
                                            </div>
                                            <div class="whiteBox gradientBorder">
                                                <div class="topGoldenSnippet">Khi pha theo đúng hướng dẫn</div>
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-3.png"
                                                                alt="orange-group-3" />
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-4.png"
                                                                alt="orange-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="mt20 mb20">chú ý</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                Sử dụng <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> dưới sự giám sát của bác sĩ hoặc chuyên gia dinh dưỡng, 
                                                cần theo đúng các hướng dẫn.
                                            </div>
                                            <div class="whiteBox pt50 mt30 gradientBorder">
                                                <div class="topGoldenSnippet">
                                                    Ensure Gold
                                                </div>
                                                <ul>
                                                    <li>
                                                        <p>Không chứa Gluten. Rất ít Trans-fat và Lactose, phù hợp cho người bất dung nạp Lactose </p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho người bệnh Galactosemia</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng qua đường tĩnh mạch</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho trẻ em trừ khi có hướng dẫn của chuyên gia y tế</p>
                                                    </li>
                                                    <li>
                                                        <p>Sử dụng cho người bệnh với sự giám sát của nhân viên y tế</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt20 mb20">Bảo quản</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                <ul>
                                                    <li>
                                                        <p>Bảo quản hộp chưa mở ở nhiệt độ phòng</p>
                                                    </li>
                                                    <li>
                                                        <p>Hộp đã mở phải được đậy kín và bảo quản ở nơi khô mát, nhưng không cho vào tủ lạnh </p>
                                                    </li>
                                                    <li>
                                                        <p>Khi đã mở phải sử dụng trong vòng 3 tuần</p>
                                                    </li>
                                                    <li>
                                                        <p><strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> đã pha phải dùng ngay hay đậy kín cho vào tủ lạnh và dùng trong vòng 24 giờ.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt30">DÙNG NUÔI ĂN QUA ỐNG THÔNG</h5>
                                            <p>
                                                Theo hướng dẫn của bác sĩ/ chuyên gia dinh dưỡng. Khi bắt đầu nuôi ăn qua ống thông, 
                                                lưu lượng, thể tích và độ pha loãng được điều chỉnh tùy thuộc vào tình trạng và sự 
                                                dung nạp của người bệnh. Lưu ý đề phòng sự nhiễm khuẩn trong quá trình chuẩn bị và 
                                                nuôi ăn qua ống thông.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                            Thành phần & Phân tích thành phần
                                        </a>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        >
                                        <div class="card-body">
                                            <h5 class="mt20">Thành phần</h5>
                                            <p>
                                                Tinh bột bắp thuỷ phân, <strong>DẦU THỰC VẬT</strong> (dầu hướng dương giàu oleic, dầu đậu nành, 
                                                dầu hạt cải), natri caseinat, đạm đậu nành tinh chế,oligofructose, sucrose, 
                                                đạm whey cô đặc, <strong>KHOÁNG CHẤT</strong> (kali citrat, tricanxi phosphat, magiê sulfat, 
                                                magiê clorid, kali clorid, natri citrat, dikali hydrophosphat, natri clorid, 
                                                canxi carbonat, kali hydroxid, sắt sulfat, kẽm sulfat, mangan sulfat, đồng sulfat, 
                                                crôm clorid, kali iodid, natri molybdat, natri selenat), canxi 
                                                β-hydroxy-β-methylbutyrat monohydrat (CaHMB), hương vani tổng hợp, cholin clorid, 
                                                <strong>VITAMIN</strong> (acid ascorbic, ascorbyl palmitat, vitamin E, hỗn hợp tocopherol, 
                                                canxi pantothenat, niacinamid, pyridoxin hydroclorid, riboflavin, thiamin hydroclorid, 
                                                vitamin A palmitat, acid folic, beta caroten, phylloquinon, biotin, vitamin D₃, 
                                                cyanocobalamin), taurin, L-carnitin.       
                                            </p>
                                            <div class="row mt20 mb20">
                                                <div class="col-12">
                                                    <img
                                                    class="img-fluid"
                                                    src="../../../assets/images/ensure-product/ensure-ingredient-chart.png"
                                                    alt="ensure-ingredient-chart" />
                                                </div>
                                            </div>
                                            <p>
                                                (*) Cách pha chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold:</strong> 
                                                263,6 g bột pha trong nước để được 1 lít <strong class="SiBrandonGrotesqueBold">Ensure Gold.</strong>
                                                Cách pha 1 ly chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> 230 ml: 6 muỗng bột (tương đương 60,6 g) pha trong 185 
                                                ml nước. <br>
                                                (**) Đối với nam giới 19-60 tuổi. %RNI cho nữ giới và các lứa tuổi khác, 
                                                xem tại www.ensure.com.vn. 
                                            </p>
                                            <h5 class="mt20">Ngày sản xuất (MFG) và hạn sử dụng (EXP) xem ở đáy hộp.</h5>
                                            <p>
                                                <strong>Sản xuất tại:</strong> Abbott Manufacturing Singapore
                                                Private Limited<br>
                                                26 Tuas South Avenue 10, Singapore 637437
                                                A subsidiary of Abbott Laboratories, North Chicago, IL 60064,
                                                USA<br><br>

                                                <strong>Xuất xứ:</strong> Singapore<br><br>

                                                <strong>Đăng ký bởi:</strong> Chi nhánh công ty TNHH Dinh dưỡng 3A (Việt Nam) tại thành phố Biên Hòa tỉnh Đồng Nai. Số 259/8/38, quốc lộ 51, KP1, phường Long Bình Tân, thành phố Biên Hòa, tỉnh Đồng Nai.
                                                <br><br>

                                                <strong>Nhập khẩu & phân phối:</strong><br>
                                                Công ty TNHH Dinh dưỡng 3A (Việt Nam)
                                                Centec Tower, 72-74 Nguyễn Thị Minh Khai,
                                                phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Ensure Product - Vanilla Less Sugar Ends Here -->

    <!-- Ensure Product - Lua Mach Starts Here -->
    <!-- <h2 class="text-center">Ensure - Lua Mach</h2> -->
    <section *ngIf="subCatCode == '3003'" class="ensureBgOut">
        <div class="container">
            <div class="row mt40">
                <div class="col-12">
                    <h1>ENSURE GOLD <br> hương lúa mạch</h1>
                </div>
            </div>
        </div>
        <div class="ensureBg wheatImg">
            <div class="topProdOut">
                <i class="luaMach">
                    <img class="img-fluid" src="../../../assets/images/ensure-product/lua-mach/ensure-lua-mach.png" alt="ensure-lua-mach" />
                </i>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="whiteTxt text-center d-block mb20">Thực phẩm dinh dưỡng y học</small>
                        <p class="whiteTxt font-italic SiBrandonGrotesqueBold">
                            Thông tin dưới đây là thông tin trên nhãn
                            được cơ quan có thẩm quyền phê duyệt
                        </p>
                    </div>
                </div>
                <div class="ensureGoldOut">
                    <div class="row">
                        <div class="col-5">
                            <img class="img-fluid" src="../../../assets/images/ensure-product/ensure-gold.png" alt="ensure-gold" />
                        </div>
                        <div class="col-7">
                            <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">
                                ENSURE - CÔNG THỨC DINH DƯỠNG ĐƯỢC CHỨNG MINH LÂM SÀNG GIÚP TĂNG CƯỜNG SỨC KHỎE, 
                                THỂ CHẤT VÀ CHẤT LƯỢNG CUỘC SỐNG.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="whiteTxt">
                            Ensure Gold là công thức dinh dưỡng đầy đủ và cân đối*. Với hơn 30 nghiên cứu khoa học trong suốt 50 năm có mặt, 
                            Ensure được chứng minh lâm sàng giúp phục hồi và tăng cường sức khỏe, tăng cường miễn dịch và chất lượng cuộc sống.
                        </p>
                        <p class="whiteTxt">
                            ENSURE GOLD <span class="moi-icon"></span> với hệ dưỡng chất StrengthPro gồm HMB, YBG và nhiều dưỡng chất khác.
                        </p>
                        <small class="whiteTxt">(*) Theo FDA và luật cộng đồng châu Âu 1999/21/EC</small>
                        <div class="text-center mt20 mb20 d-flex justify-content-center">
                            <a href="https://www.lazada.vn/shop/ensure/?path=promotion-31880-0.htm&tab=promotion" target="_blank" class="orangeBtn boxShadaow plr">Mua ngay</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="milkSpillOverBg">
            <div class="carouselOut">
                <div id="carouselIndicators5" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/hmb-c-b-p.png"
                                alt="dam" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>HMB</strong> và <strong>Đạm</strong> chất lượng cao giúp bảo vệ, xây dựng  và phát triển khối cơ.
                                </p>
                                <br>
                                <p class="caption-satus">
                                    * Theo FDA và Luật Cộng đồng châu Âu 1999/21/EC
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/ybg-khi-n.png"
                                alt="28" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>YBG (Beta Glucan từ nấm men)</strong> và <strong>12 dưỡng chất</strong> giúp tăng cường miễn dịch theo 5 cách thức**.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/heart.png"
                                alt="heart" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>Giàu Omega-3, Omega-6,  Omega-9</strong> nguồn gốc thực vật hỗ trợ sức khỏe tim mạch.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/28-vitamin-con-ng.png"
                                alt="vitamin-con" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>28 vitamin</strong> và <strong>khoáng chất thiết yếu</strong> giúp cơ thể khỏe mạnh.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/fos.png"
                                alt="fos" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Chất xơ <strong>FOS</strong> hỗ trợ tiêu hóa khỏe mạnh. <strong>Các chất chống oxy hóa</strong> (Betacaroten, vitamin C, E, Kẽm và Selen) giúp bảo vệ cơ thể.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/canxi.png"
                                alt="shield" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Giàu <strong>Canxi, Phospho Và Vitamin D</strong> giúp xương chắc khỏe.
                                </p>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselIndicators5" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="1"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="2"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="3"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="4"></li>
                    </ol>
                    <a class="carousel-control-prev" href="#carouselIndicators5" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </a>
                    <a class="carousel-control-next" href="#carouselIndicators5" role="button"
                        data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
        </div>
        <div class="grayLinearBg">
            <div class="accordionOut mt40">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <a href="javascript:;" class="btn btn-link" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Công dụng
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        >
                                        <div class="card-body">
                                            <p>
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> bổ sung dinh dưỡng đầy 
                                                đủ và cân đối, vitamin, khoáng chất giúp phục hồi và tăng cường sức khỏe.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            đối tượng sử dụng
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        >
                                        <div class="card-body">
                                            <p>
                                                Người lớn, người ăn uống kém, người bệnh cần phục hồi nhanh.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            hướng dẫn sử dụng & Bảo quản
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        >
                                        <div class="card-body">
                                            <h5>hướng dẫn sử dụng</h5>
                                            <p>
                                                Để pha 230 ml, cho 185 ml nước chín nguội vào ly, vừa từ từ cho vào 6 muỗng gạt 
                                                ngang (muỗng có sẵn trong hộp, mỗi muỗng tương đương 10,1 g) hay 60,6 g bột 
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> vừa khuấy đều cho đến 
                                                khi bột tan hết.
                                            </p>
                                            <p class="threeCircleImgOut">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/ensure-product/185ml.png"
                                                    alt="185ml" />
                                            </p>
                                            <div class="whiteBox plr0 gradientBorder">
                                                <div class="topGoldenSnippet">Bổ sung chế độ ăn </div>
                                                <div class="container">
                                                    <div class="row rowm0">
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-x2.png"
                                                                alt="blue-x2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Uống 2 ly/ ngày
                                                            </p>
                                                        </div>
                                                        <div class="col-2 col text-center">
                                                            <p class="darkOrangeForeColor SiBrandonGrotesqueBold mt40">
                                                                hoặc
                                                            </p>
                                                        </div>
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-group-2.png"
                                                                alt="blue-group-2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Theo hướng dẫn của chuyên viên dinh dưỡng
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="linearOrangeOut mt20 mb20">
                                                <strong>Thay thế hoàn toàn bữa ăn qua ống thông cho người
                                                    bệnh:</strong> Theo hướng dẫn của bác sĩ hoặc chuyên viên dinh
                                                dưỡng.
                                            </div>
                                            <div class="whiteBox gradientBorder">
                                                <div class="topGoldenSnippet">Khi pha theo đúng hướng dẫn</div>
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-3.png"
                                                                alt="orange-group-3" />
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-4.png"
                                                                alt="orange-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="mt20 mb20">chú ý</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                Sử dụng <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> dưới sự giám sát 
                                                của bác sĩ hoặc chuyên gia dinh dưỡng, cần theo đúng các hướng dẫn.
                                            </div>
                                            <div class="whiteBox pt50 mt30 gradientBorder">
                                                <div class="topGoldenSnippet">
                                                    Ensure Gold
                                                </div>
                                                <ul>
                                                    <li>
                                                        <p>
                                                            Không chứa Gluten. Rất ít Trans-fat và Lactose, phù hợp cho 
                                                            người bất dung nạp Lactose 
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho người bệnh Galactosemia</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng qua đường tĩnh mạch</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho trẻ em trừ khi có hướng dẫn của chuyên gia y tế</p>
                                                    </li>
                                                    <li>
                                                        <p>Sử dụng cho người bệnh với sự giám sát của nhân viên y tế</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt20 mb20">Bảo quản</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                <ul>
                                                    <li>
                                                        <p>Bảo quản hộp chưa mở ở nhiệt độ phòng</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Hộp đã mở phải được đậy kín và bảo quản ở nơi khô mát, nhưng 
                                                            không cho vào tủ lạnh 
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Khi đã mở phải sử dụng trong vòng 3 tuần</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> đã pha phải dùng 
                                                            ngay hay đậy kín cho vào tủ lạnh và dùng trong vòng 24 giờ.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt30">DÙNG NUÔI ĂN QUA ỐNG THÔNG</h5>
                                            <p>
                                                Theo hướng dẫn của bác sĩ/ chuyên gia dinh dưỡng. Khi bắt đầu nuôi ăn qua ống thông, 
                                                lưu lượng, thể tích và độ pha loãng được điều chỉnh tùy thuộc vào tình trạng và sự 
                                                dung nạp của người bệnh. Lưu ý đề phòng sự nhiễm khuẩn trong quá trình chuẩn bị và 
                                                nuôi ăn qua ống thông.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                            Thành phần & Phân tích thành phần
                                        </a>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        >
                                        <div class="card-body">
                                            <h5 class="mt20">Thành phần</h5>
                                            <p>
                                                Tinh bột bắp thuỷ phân, <strong>DẦU THỰC VẬT</strong> (dầu hướng dương giàu oleic, dầu đậu nành, dầu hạt cải), 
                                                sucrose, natri caseinat, đạm đậu nành tinh chế, oligofructose, đạm whey cô đặc, 
                                                <strong>KHOÁNG CHẤT</strong> (kali citrat, tricanxi phosphat, magiê sulfat, magiê clorid, kali clorid, natri citrat, 
                                                dikali phosphat, natri clorid, canxi carbonat, kali hydroxid, sắt sulfat, kẽm sulfat, mangan sulfat, đồng sulfat, 
                                                crôm clorid, kali iodid, natri molybdat, natri selenat), 
                                                <strong>canxi β-hydroxy-β-methylbutyrat monohydrat (CaHMB)</strong>, hương liệu vani tổng hợp, 
                                                <strong>Beta Glucan từ nấm men (YBG), VITAMIN </strong> (acid ascorbic, ascorbyl palmitat, vitamin E, hỗn hợp tocopherol, 
                                                canxi pantothenat, niacinamid, pyridoxin hydroclorid, riboflavin, thiamin hydroclorid, vitamin A palmitat, acid folic, beta caroten, 
                                                phylloquinon, biotin, vitamin D3, cyanocobalamin), cholin clorid, taurin, L-carnitin.
                                            </p>
                                            <div class="row mt20 mb20">
                                                <div class="col-12">
                                                    <img
                                                    class="img-fluid"
                                                    src="../../../assets/images/ensure-product/ensure-ingredient-chart.png"
                                                    alt="ensure-ingredient-chart" />
                                                </div>
                                            </div>
                                            <p>
                                                (*) Cách pha chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold:</strong> 
                                                263,6 g bột pha trong nước để được 1 lít <strong class="SiBrandonGrotesqueBold">Ensure Gold.</strong>
                                                Cách pha 1 ly chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> 230 ml: 6 muỗng bột (tương đương 60,6 g) pha trong 185 
                                                ml nước. <br>
                                                (**) Đối với nam giới 19-60 tuổi. %RNI cho nữ giới và các lứa tuổi khác, 
                                                xem tại www.ensure.com.vn. 
                                            </p>
                                            <h5 class="mt20">Ngày sản xuất (MFG) và hạn sử dụng (EXP) xem ở đáy hộp.</h5>
                                            <p>
                                                <strong>Sản xuất tại:</strong> Abbott Manufacturing Singapore
                                                Private Limited<br>
                                                26 Tuas South Avenue 10, Singapore 637437
                                                A subsidiary of Abbott Laboratories, North Chicago, IL 60064,
                                                USA<br><br>

                                                <strong>Xuất xứ:</strong> Singapore<br><br>

                                                <strong>Đăng ký bởi:</strong> Chi nhánh công ty TNHH Dinh dưỡng 3A (Việt Nam) tại thành phố Biên Hòa tỉnh Đồng Nai. Số 259/8/38, quốc lộ 51, KP1, phường Long Bình Tân, thành phố Biên Hòa, tỉnh Đồng Nai.
                                                <br><br>

                                                <strong>Nhập khẩu & phân phối:</strong><br>
                                                Công ty TNHH Dinh dưỡng 3A (Việt Nam)
                                                Centec Tower, 72-74 Nguyễn Thị Minh Khai,
                                                phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Ensure Product - Lua Mach Ends Here -->

    <!-- Ensure Product - Coffee Starts Here -->
    <!-- <h2 class="text-center">Ensure - Coffee</h2> -->
    <section *ngIf="subCatCode == '3004'" class="ensureBgOut">
        <div class="container">
            <div class="row mt40">
                <div class="col-12">
                    <h1>ENSURE GOLD hương cà phê</h1>
                </div>
            </div>
        </div>
        <div class="ensureBg coffeeImg">
            <div class="topProdOut">
                <i class="coffee">
                    <img class="img-fluid" src="../../../assets/images/ensure-product/coffee/ensure-coffee.png" alt="ensure-coffee" />
                </i>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="whiteTxt text-center d-block mb20">Thực phẩm dinh dưỡng y học</small>
                        <p class="whiteTxt font-italic SiBrandonGrotesqueBold">
                            Thông tin dưới đây là thông tin trên nhãn
                            được cơ quan có thẩm quyền phê duyệt
                        </p>
                    </div>
                </div>
                <div class="ensureGoldOut">
                    <div class="row">
                        <div class="col-5">
                            <img class="img-fluid" src="../../../assets/images/ensure-product/ensure-gold.png" alt="ensure-gold" />
                        </div>
                        <div class="col-7">
                            <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">
                                ENSURE gold - CÔNG THỨC DINH DƯỠNG ĐƯỢC CHỨNG MINH LÂM SÀNG giúp TĂNG CƯỜNG SỨC KHỎE, THỂ CHẤT VÀ CHẤT LƯỢNG CUỘC SỐNG.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="whiteTxt">
                            Ensure Gold là công thức dinh dưỡng đầy đủ và cân đối*. Với hơn 30 nghiên cứu khoa học trong suốt 50 năm có mặt, 
                            Ensure được chứng minh lâm sàng giúp phục hồi và tăng cường sức khỏe, tăng cường miễn dịch và chất lượng cuộc sống.
                            <br>
                            ENSURE GOLD <span class="moi-icon"></span> với hệ dưỡng chất StrengthPro gồm HMB, YBG và nhiều dưỡng chất khác.
                            <br>
                            (*) Theo FDA và luật cộng đồng châu Âu 1999/21/EC
                        </p>
                        <div class="text-center mt20 mb20 d-flex justify-content-center">
                            <a href="https://www.lazada.vn/shop/ensure/?path=promotion-31880-0.htm&tab=promotion" target="_blank" class="orangeBtn boxShadaow plr">Mua ngay</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="milkSpillOverBg">
            <div class="carouselOut">
                <div id="carouselIndicators5" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/hmb-c-b-p.png"
                                alt="dam" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>HMB</strong> và <strong>Đạm</strong> chất lượng cao giúp bảo vệ, xây dựng  và phát triển khối cơ.
                                </p>
                                <br>
                                <p class="caption-satus">
                                    * Theo FDA và Luật Cộng đồng châu Âu 1999/21/EC
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/ybg-khi-n.png"
                                alt="28" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>YBG (Beta Glucan từ nấm men)</strong> và <strong>12 dưỡng chất</strong> giúp tăng cường miễn dịch theo 5 cách thức**.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/heart.png"
                                alt="heart" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>Giàu Omega-3, Omega-6,  Omega-9</strong> nguồn gốc thực vật hỗ trợ sức khỏe tim mạch.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/28-vitamin-con-ng.png"
                                alt="vitamin-con" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    <strong>28 vitamin</strong> và <strong>khoáng chất thiết yếu</strong> giúp cơ thể khỏe mạnh.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/fos.png"
                                alt="fos" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Chất xơ <strong>FOS</strong> hỗ trợ tiêu hóa khỏe mạnh. <strong>Các chất chống oxy hóa</strong> (Betacaroten, vitamin C, E, Kẽm và Selen) giúp bảo vệ cơ thể.
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-fluid"
                                src="../../../assets/images/ensure-product/canxi.png"
                                alt="shield" />
                            <div class="carousel-caption d-md-block">
                                <p>
                                    Giàu <strong>Canxi, Phospho Và Vitamin D</strong> giúp xương chắc khỏe.
                                </p>
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselIndicators5" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="1"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="2"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="3"></li>
                        <li data-target="#carouselIndicators5" data-slide-to="4"></li>
                    </ol>
                    <a class="carousel-control-prev" href="#carouselIndicators5" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </a>
                    <a class="carousel-control-next" href="#carouselIndicators5" role="button"
                        data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
        </div>
        <div class="grayLinearBg">
            <div class="accordionOut mt40">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <a href="javascript:;" class="btn btn-link" data-toggle="collapse"
                                            data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Công dụng
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        >
                                        <div class="card-body">
                                            <p>
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong>
                                                bổ sung dinh dưỡng đầy đủ và cân đối, vitamin, khoáng chất giúp phục hồi và tăng cường sức khỏe.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            đối tượng sử dụng
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        >
                                        <div class="card-body">
                                            <p>
                                                Người lớn, người ăn uống kém, người bệnh cần phục hồi nhanh.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            hướng dẫn sử dụng & Bảo quản
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        >
                                        <div class="card-body">
                                            <h5>hướng dẫn sử dụng</h5>
                                            <p>
                                                Để pha 230 ml, cho 185 ml nước chín nguội vào ly, vừa từ từ cho vào 6 muỗng gạt ngang (muỗng có sẵn trong hộp, mỗi muỗng tương đương 10,1 g) hay 60,6 g bột
                                                <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong>
                                                vừa khuấy đều cho đến khi bột tan hết.
                                            </p>
                                            <p class="threeCircleImgOut">
                                                <img class="img-fluid"
                                                    src="../../../assets/images/ensure-product/185ml.png"
                                                    alt="185ml" />
                                            </p>
                                            <div class="whiteBox plr0 gradientBorder">
                                                <div class="topGoldenSnippet">Bổ sung chế độ ăn </div>
                                                <div class="container">
                                                    <div class="row rowm0">
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-x2.png"
                                                                alt="blue-x2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Uống 2 ly/ ngày
                                                            </p>
                                                        </div>
                                                        <div class="col-2 col text-center">
                                                            <p class="darkOrangeForeColor SiBrandonGrotesqueBold mt40">
                                                                hoặc
                                                            </p>
                                                        </div>
                                                        <div class="col-5 col">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/blue-group-2.png"
                                                                alt="blue-group-2" />
                                                            <p class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                Theo hướng dẫn của chuyên viên dinh dưỡng
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="linearOrangeOut mt20 mb20">
                                                <strong>Thay thế hoàn toàn bữa ăn qua ống thông cho người
                                                    bệnh:</strong> Theo hướng dẫn của bác sĩ hoặc chuyên viên dinh
                                                dưỡng.
                                            </div>
                                            <div class="whiteBox gradientBorder">
                                                <div class="topGoldenSnippet">Khi pha theo đúng hướng dẫn</div>
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-3.png"
                                                                alt="orange-group-3" />
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <img class="img-fluid"
                                                                src="../../../assets/images/ensure-product/orange-group-4.png"
                                                                alt="orange-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="mt20 mb20">chú ý</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                Sử dụng <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> dưới sự giám sát 
                                                của bác sĩ hoặc chuyên gia dinh dưỡng, cần theo đúng các hướng dẫn.
                                            </div>
                                            <div class="whiteBox pt50 mt30 gradientBorder">
                                                <div class="topGoldenSnippet">
                                                    Ensure Gold
                                                </div>
                                                <ul>
                                                    <li>
                                                        <p>
                                                            Không chứa Gluten. Rất ít Trans-fat và Lactose, phù hợp cho 
                                                            người bất dung nạp Lactose 
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho người bệnh Galactosemia</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng qua đường tĩnh mạch</p>
                                                    </li>
                                                    <li>
                                                        <p>Không dùng cho trẻ em trừ khi có hướng dẫn của chuyên gia y tế</p>
                                                    </li>
                                                    <li>
                                                        <p>Sử dụng cho người bệnh với sự giám sát của nhân viên y tế</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt20 mb20">Bảo quản</h5>
                                            <div class="whiteBox p20 gradientBorder">
                                                <ul>
                                                    <li>
                                                        <p>Bảo quản hộp chưa mở ở nhiệt độ phòng</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Hộp đã mở phải được đậy kín và bảo quản ở nơi khô mát, nhưng 
                                                            không cho vào tủ lạnh 
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Khi đã mở phải sử dụng trong vòng 3 tuần</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> đã pha phải dùng 
                                                            ngay hay đậy kín cho vào tủ lạnh và dùng trong vòng 24 giờ.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="mt30">DÙNG NUÔI ĂN QUA ỐNG THÔNG</h5>
                                            <p>
                                                Theo hướng dẫn của bác sĩ/ chuyên gia dinh dưỡng. Khi bắt đầu nuôi ăn qua ống thông, 
                                                lưu lượng, thể tích và độ pha loãng được điều chỉnh tùy thuộc vào tình trạng và sự 
                                                dung nạp của người bệnh. Lưu ý đề phòng sự nhiễm khuẩn trong quá trình chuẩn bị và 
                                                nuôi ăn qua ống thông.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                            Thành phần & Phân tích thành phần
                                        </a>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        >
                                        <div class="card-body">
                                            <h5 class="mt20">Thành phần</h5>
                                            <p>
                                                Tinh bột bắp thuỷ phân, <strong>DẦU THỰC VẬT</strong> (dầu hướng dương giàu oleic, dầu đậu nành, 
                                                dầu hạt cải), sucrose, natri caseinat, đạm đậu nành tinh chế, oligofructose, 
                                                đạm whey cô đặc, <strong>KHOÁNG CHẤT</strong> (kali citrat, tricanxi phosphat, magiê sulfat, 
                                                magiê clorid, kali clorid, natri citrat, dikali hydrophosphat, natri clorid, 
                                                canxi carbonat, kali hydroxid, sắt sulfat, kẽm sulfat, mangan sulfat, đồng sulfat, 
                                                crôm clorid, kali iodid, natri molybdat, natri selenat), canxi 
                                                β-hydroxy-β-methylbutyrat monohydrat (CaHMB), hương cà phê tổng hợp, màu caramen tự 
                                                nhiên, cholin clorid, <strong>VITAMIN</strong> (acid ascorbic, ascorbyl palmitat, vitamin E, hỗn hợp 
                                                tocopherol, canxi pantothenat, niacinamid, pyridoxin hydroclorid, riboflavin, thiamin 
                                                hydroclorid, vitamin A palmitat, acid folic, beta caroten, phylloquinon, biotin, 
                                                vitamin D₃, cyanocobalamin), taurin, L-carnitin.
                                            </p>
                                            <div class="row mt20 mb20">
                                                <div class="col-12">
                                                    <img
                                                    class="img-fluid"
                                                    src="../../../assets/images/ensure-product/ensure-ingredient-chart.png"
                                                    alt="ensure-ingredient-chart" />
                                                </div>
                                            </div>
                                            <p>
                                                (*) Cách pha chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold:</strong> 
                                                263,6 g bột pha trong nước để được 1 lít <strong class="SiBrandonGrotesqueBold">Ensure Gold.</strong>
                                                Cách pha 1 ly chuẩn <strong class="SiBrandonGrotesqueBold">Ensure Gold</strong> 230 ml: 6 muỗng bột (tương đương 60,6 g) pha trong 185 
                                                ml nước. <br>
                                                (**) Đối với nam giới 19-60 tuổi. %RNI cho nữ giới và các lứa tuổi khác, 
                                                xem tại www.ensure.com.vn. 
                                            </p>
                                            <h5 class="mt20">Ngày sản xuất (MFG) và hạn sử dụng (EXP) xem ở đáy hộp.</h5>
                                            <p>
                                                <strong>Sản xuất tại:</strong> Abbott Manufacturing Singapore
                                                Private Limited<br>
                                                26 Tuas South Avenue 10, Singapore 637437
                                                A subsidiary of Abbott Laboratories, North Chicago, IL 60064,
                                                USA<br><br>

                                                <strong>Xuất xứ:</strong> Singapore<br><br>

                                                <strong>Đăng ký bởi:</strong> Chi nhánh công ty TNHH Dinh dưỡng 3A (Việt Nam) tại thành phố Biên Hòa tỉnh Đồng Nai. Số 259/8/38, quốc lộ 51, KP1, phường Long Bình Tân, thành phố Biên Hòa, tỉnh Đồng Nai.
                                                
                                                <br><br>

                                                <strong>Nhập khẩu & phân phối:</strong><br>
                                                Công ty TNHH Dinh dưỡng 3A (Việt Nam)
                                                Centec Tower, 72-74 Nguyễn Thị Minh Khai,
                                                phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Ensure Product - Coffee Ends Here -->

        <!-- Ensure Product - Green Health Starts Here -->
    <!-- <h2 class="text-center mt20">Ensure - Green Health</h2> -->
    <section *ngIf="subCatCode == '3005'" class="ensureBgOut">
        <div class="container">
            <div class="row mt40">
                <div class="col-12">
                    <h1>ENSURE GOLD GREEN HEALTH <small>DINH DƯỠNG XANH, SỐNG KHỎE AN LÀNH</small></h1>
                </div>
            </div>
        </div>
        <div class="ensureBg pb0 greenHealthImg">
            <div class="topProdOut">
                <i class="greenHealth">
                    <img class="img-fluid" src="../../../assets/images/ensure-product/ensure-green-health.png" alt="ensure-green-health" />
                </i>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="whiteTxt text-center d-block mb20 greenHealthTxt">Thực phẩm dinh dưỡng y học</small>
                        <p class="whiteTxt font-italic SiBrandonGrotesqueBold">Thông tin dưới đây là thông tin trên nhãn
                            được cơ quan có thẩm quyền phê duyệt</p>
                        <p class="yellowForeColor text-uppercase SiBrandonGrotesqueBold">ENSURE - CÔNG THỨC DINH DƯỠNG ĐƯỢC
                            CHỨNG MINH LÂM SÀNG GIÚP TĂNG CƯỜNG SỨC KHỎE, THỂ CHẤT VÀ CHẤT LƯỢNG CUỘC SỐNG.</p>
                        <p class="whiteTxt">Với hơn 30 nghiên cứu lâm sàng trong suốt 45 năm, Ensure là dinh dưỡng đầy đủ và
                            cân đối(*), được chứng minh giúp đáp ứng nhu cầu dinh dưỡng cho người lớn tuổi, hỗ trợ tăng
                            cường sức khỏe, thể chất và chất lượng cuộc sống.</p>
                        <small class="whiteTxt">(*) Theo FDA và luật cộng đồng Châu Âu 1999/21/EC</small>
                        <div class="text-center mt20 mb20 d-flex justify-content-center">
                            <a href="https://www.lazada.vn/shop/ensure/?path=promotion-31880-0.htm&tab=promotion" target="_blank" class="orangeBtn boxShadaow plr">Mua ngay</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="greenHealthBg">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="contents">
                                <h5>công dụng</h5>
                                <p>
                                    <strong class="SiBrandonGrotesqueBold">Ensure<sup>® </sup></strong> 
                                    <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong> 
                                    mới bổ sung dinh dưỡng đầy đủ và cân đối, giúp phục hồi
                                    và tăng cường sức khỏe.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grayLinearBg mt66">
                    <div class="carouselOut">
                        <div id="carouselIndicators1" class="carousel slide" data-ride="carousel" data-interval="false">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="img-fluid"
                                        src="../../../assets/images/ensure-product/dam.png"
                                        alt="dam" />
                                    <div class="carousel-caption d-md-block">
                                        <p>
                                            Đạm thực vật chất lượng cao (từ đậu nành, gạo và hạt quinoa (diêm mạch)), cùng HMB 
                                            (β-hydroxy-β-methylbutyrat) hỗ trợ xây dựng và phát triển khối cơ.
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid"
                                        src="../../../assets/images/ensure-product/28.png"
                                        alt="28" />
                                    <div class="carousel-caption d-md-block">
                                        <p>
                                            28 vitamin và khoáng chất thiết yếu giúp cơ thể khỏe mạnh. Giàu Canxi, Phospho và Vitamin D 
                                            giúp xương chắc khỏe.
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid"
                                        src="../../../assets/images/ensure-product/fos.png"
                                        alt="fos" />
                                    <div class="carousel-caption d-md-block">
                                        <p>
                                            Chất xơ FOS nuôi dưỡng vi sinh vật có lợi và giúp hệ tiêu hóa khỏe mạnh.
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid"
                                        src="../../../assets/images/ensure-product/heart.png"
                                        alt="heart" />
                                    <div class="carousel-caption d-md-block">
                                        <p>
                                            Hỗn hợp chất béo thực vật giàu acid béo Omega 3-6-9 tốt cho tim mạch. Hàm lượng 
                                            acid béo no và cholesterol thấp có lợi cho chế độ ăn lành mạnh.
                                        </p>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <img class="img-fluid"
                                        src="../../../assets/images/ensure-product/shield.png"
                                        alt="shield" />
                                    <div class="carousel-caption d-md-block">
                                        <p>
                                            Các chất chống oxi – hóa (beta caroten, vitamin C, E, kẽm và selen) giúp bảo vệ cơ thể.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <ol class="carousel-indicators">
                                <li data-target="#carouselIndicators1" data-slide-to="0" class="active"></li>
                                <li data-target="#carouselIndicators1" data-slide-to="1"></li>
                                <li data-target="#carouselIndicators1" data-slide-to="2"></li>
                                <li data-target="#carouselIndicators1" data-slide-to="3"></li>
                                <li data-target="#carouselIndicators1" data-slide-to="4"></li>
                            </ol>
                            <a class="carousel-control-prev" href="#carouselIndicators1" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            </a>
                            <a class="carousel-control-next" href="#carouselIndicators1" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            </a>
                        </div>
                    </div>
                    <div class="accordionOut mt70">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <a href="javascript:;" class="btn btn-link" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    đối tượng sử dụng
                                                </a>
                                            </div>
                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                                >
                                                <div class="card-body">
                                                    <p>Người lớn, người ăn uống kém, người bệnh cần phục hồi nhanh.</p>
                                                    <p><strong class="SiBrandonGrotesqueBold">Ensure<sup>® </sup></strong>
                                                        <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong> đặc biệt thích hợp cho những người
                                                        thích dùng đạm thực vật, người ăn chay.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                    hướng dẫn sử dụng & Bảo quản
                                                </a>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                                >
                                                <div class="card-body">
                                                    <h5>hướng dẫn sử dụng</h5>
                                                    <p>
                                                        Để pha 230 ml, cho 187 ml nước chín để nguội vào ly, vừa từ từ cho
                                                        vào 6 muỗng gạt
                                                        ngang (muỗng có sẵn trong hộp) hay 60,6 g bột <strong
                                                            class="SiBrandonGrotesqueBold">Ensure<sup>® </sup></strong>
                                                        <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong>
                                                        vừa khuấy đều cho đến khi bột tan hết.
                                                    </p>
                                                    <p class="threeCircleImgOut">
                                                        <img class="img-fluid"
                                                            src="../../../assets/images/ensure-product/vector-smart-object.png"
                                                            alt="vector-smart-object" />
                                                    </p>
                                                    <div class="whiteBox">
                                                        <div class="topOrangeSnippet">Bổ sung chế độ ăn </div>
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <img class="img-fluid"
                                                                        src="../../../assets/images/ensure-product/group-2.png"
                                                                        alt="group-2" />
                                                                    <p
                                                                        class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                        Uống 2 ly/ ngày</p>
                                                                </div>
                                                                <div class="col-2">
                                                                    <p
                                                                        class="darkOrangeForeColor SiBrandonGrotesqueBold mt40">
                                                                        hoặc</p>
                                                                </div>
                                                                <div class="col-5">
                                                                    <img class="img-fluid"
                                                                        src="../../../assets/images/ensure-product/group-3.png"
                                                                        alt="group-3" />
                                                                    <p
                                                                        class="indigoForeColor SiBrandonGrotesqueBold text-center">
                                                                        Theo hướng dẫn của chuyên viên dinh dưỡng</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="linearOrangeOut mt20 mb20">
                                                        <strong>Thay thế hoàn toàn bữa ăn qua ống thông cho người
                                                            bệnh:</strong> Theo hướng dẫn của bác sĩ hoặc chuyên viên dinh
                                                        dưỡng.
                                                    </div>
                                                    <div class="whiteBox">
                                                        <div class="topOrangeSnippet">Khi pha theo đúng hướng dẫn</div>
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="img-fluid"
                                                                        src="../../../assets/images/ensure-product/group-4.png"
                                                                        alt="group-4" />
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="img-fluid"
                                                                        src="../../../assets/images/ensure-product/group-5.png"
                                                                        alt="group-5" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5 class="mt20 mb20">chú ý</h5>
                                                    <div class="whiteBox p20">
                                                        Sử dụng <strong
                                                            class="SiBrandonGrotesqueBold">Ensure<sup>® </sup></strong> 
                                                        <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong> dưới sự giám sát của bác sĩ hoặc
                                                        chuyên gia dinh dưỡng, cần theo đúng các hướng dẫn.
                                                    </div>
                                                    <div class="whiteBox pt50 mt30">
                                                        <div class="topOrangeSnippet">
                                                        <strong>Ensure<sup>® </sup></strong> <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <p>Không dùng cho người dị ứng với sữa</p>
                                                            </li>
                                                            <li>
                                                                <p>Không dùng cho người bệnh Galactosemia</p>
                                                            </li>
                                                            <li>
                                                                <p>Không dùng qua đường tĩnh mạch</p>
                                                            </li>
                                                            <li>
                                                                <p>Không dùng cho trẻ em trừ khi có hướng dẫn của chuyên gia
                                                                    y tế</p>
                                                            </li>
                                                            <li>
                                                                <p>Rất ít Trans-fat và Lactose</p>
                                                            </li>
                                                            <li>
                                                                <p>Sử dụng cho người bệnh với sự giám sát của nhân viên y tế
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <h5 class="mt20 mb20">Bảo quản</h5>
                                                    <div class="whiteBox p20 mb20">
                                                        <ul>
                                                            <li>
                                                                <p>Bảo quản hộp chưa mở ở nhiệt độ phòng</p>
                                                            </li>
                                                            <li>
                                                                <p>Hộp đã mở phải được đậy kín và bảo quản ở nơi khô mát,
                                                                    nhưng không cho vào tủ lạnh </p>
                                                            </li>
                                                            <li>
                                                                <p>Khi đã mở phải sử dụng trong vòng 3 tuần</p>
                                                            </li>
                                                            <li>
                                                                <p><strong>Ensure<sup>® </sup></strong> 
                                                                <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong> đã pha phải dùng ngay hay
                                                                đậy kín cho vào tủ lạnh và dùng trong vòng 24 giờ.</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <h5>DÙNG NUÔI ĂN QUA ỐNG THÔNG</h5>
                                                    <p>
                                                        Theo hướng dẫn của bác sĩ/ chuyên gia dinh dưỡng. Khi bắt đầu nuôi
                                                        ăn qua ống thông, lưu lượng, thể tích và độ pha loãng được điều
                                                        chỉnh tùy thuộc vào tình trạng và sự dung nạp của người bệnh. Lưu ý
                                                        đề phòng sự nhiễm khuẩn trong quá trình chuẩn bị và nuôi ăn qua ống
                                                        thông.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <a href="javascript:;" class="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                    Thành phần & Phân tích thành phần
                                                </a>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                                >
                                                <div class="card-body">
                                                    <h5 class="mt20">Thành phần</h5>
                                                    <p>
                                                        Tinh bột bắp thủy phân, đạm thực vật (đạm đậu nành tinh chế, bột
                                                        quinoa, đạm gạo),
                                                        <strong>DẦU THỰC VẬT</strong> (dầu hướng dương giàu oleic, dầu đậu
                                                        nành, dầu hạt cải), sucrose,
                                                        oligofructose, <strong>KHOÁNG CHẤT</strong> (kali citrat, magiê
                                                        hydrophosphat, tricanxi phosphat,
                                                        kali clorid, natri citrat, natri clorid, dikali hydrophosphat, canxi
                                                        carbonat,
                                                        kali hydroxid, kẽm sulfat, sắt sulfat, mangan sulfat, đồng sulfat,
                                                        kali iodid,
                                                        natri molybdat, crôm clorid, natri selenat), canxi
                                                        β-hydroxy-β-methylbutyrat
                                                        monohydrat (CaHMB), hương hạnh nhân tự nhiên và tổng hợp, lecithin
                                                        đậu nành,
                                                        cholin clorid, <strong>VITAMIN</strong> (acid ascorbic, ascorbyl
                                                        palmitat, vitamin E, hỗn hợp tocopherol,
                                                        canxi pantothenat, niacinamid, pyridoxin hydroclorid, riboflavin,
                                                        vitamin A palmitat,
                                                        thiamin hydroclorid, beta caroten, acid folic, phylloquinon, biotin,
                                                        vitamin D₃,
                                                        cyanocobalamin), taurin, L-carnitin.
                                                    </p>
                                                    <div class="row mt20 mb20">
                                                        <div class="col-12">
                                                            <img
                                                            class="img-fluid"
                                                            src="../../../assets/images/ensure-product/ensure-green-health-chart.png"
                                                            alt="ensure-green-health-chart" />
                                                        </div>
                                                    </div>
                                                    <p>
                                                        (*) Cách pha chuẩn <strong>Ensure<sup>® </sup></strong> <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong>: 263 g bột pha trong nước để được 1
                                                        lít <strong>Ensure<sup>® </sup></strong> <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong>. Cách pha 1 ly chuẩn
                                                        <strong>Ensure<sup>® </sup></strong> <strong class="SiBrandonGrotesqueBold goldGreenHealth"><span>Gold</span> Green Health</strong> 230 ml: 6 muỗng bột (tương đương 60,6
                                                        g) pha trong 187 ml nước.
                                                    </p>
                                                    <h5 class="mt20">Ngày sản xuất (MFG) và hạn sử dụng (EXP) xem ở đáy hộp.
                                                    </h5>
                                                    <p>
                                                        <strong>Sản xuất tại:</strong> Abbott Manufacturing Singapore
                                                        Private Limited<br>
                                                        26 Tuas South Avenue 10, Singapore 637437
                                                        A subsidiary of Abbott Laboratories, North Chicago, IL 60064,
                                                        USA<br><br>
    
                                                        <strong>Xuất xứ:</strong> Singapore<br><br>
    
                                                        <strong>Đăng ký bởi:</strong> Chi nhánh công ty TNHH Dinh dưỡng 3A (Việt Nam) tại thành phố Biên Hòa tỉnh Đồng Nai. Số 259/8/38, quốc lộ 51, KP1, phường Long Bình Tân, thành phố Biên Hòa, tỉnh Đồng Nai.
                                                        <br><br>
    
                                                        <strong>Nhập khẩu & phân phối:</strong><br>
                                                        Công ty TNHH Dinh dưỡng 3A (Việt Nam)
                                                        Centec Tower, 72-74 Nguyễn Thị Minh Khai,
                                                        phường Võ Thị Sáu, quận 3, thành phố Hồ Chí Minh
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Ensure Product - Green Health Ends Here -->

</section>

<div class="manufacturerShareAndCareContainer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="mb-0 careShareText text-uppercase">care & share</h3>
                <h3 class="homeText text-uppercase">home</h3>
                <p>Tham gia để nhận được <br><strong>các lợi ích hấp dẫn</strong></p>
                <button class="orangeBtn" routerLink="/sign-up/1">Đăng ký ngay</button>
                <p>Liên hệ hotline <a class="telNum" href="tel:19001519">19001519 </a><br>để biết thêm chi tiết <br><small>ENS-C-332-23</small>
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
<app-nav-menu></app-nav-menu>