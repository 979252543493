import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import 'moment/locale/es'
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import jsQR from 'jsqr';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  milkCode: any;
  scancodeData: any;
  content: any = [];
  subCatCode: any;
  prodCategory: any;
  mobile_no: any;
  milkCodeCust: any
  custDetail: any
  transactinList: any;
  remaining_grow_chances: any;
  remaining_similac_chances: any;
  remaining_ensure_chances: any;
  remaining_glucerna_chances: any;
  remaining_pediasure_chances: any;
  gamelist: any;
  sttrData: any;
  maxDate: Date;
  currentDate;
  myDate = new Date();
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm.dd.yyyy',
  };

  public myForm: FormGroup;


  redemmed: boolean = false;
  is_not_expired: boolean = false;
  game_is_eligible: boolean = false;
  global_grow_count: any;
  global_similac_count: any;
  global_ensure_count: any;
  global_glucerna_count: any;
  global_pediasure_count: any;
  custom_isGrow: any = false;
  custom_isEnsure: boolean;
  custom_isGlucerna: boolean;
  custom_isPediasure: boolean;
  scannedDate: any;
  eligible_nonmilk_grow: any = false;
  eligible_nonmilk_similac: any = false;

  eligible_nonmilk_ensure: any;
  eligible_nonmilk_glucerna: any
  eligible_nonmilk_pediasure: any

  similac_array_length: any;
  grow_array_length: any;

  ensure_array_length: any;
  glucerna_array_length: any;
  pediasure_array_length: any;

  selectedProduct: string = 'true';
  address: any;
  locale = 'vi';
  locales = listLocales();

  grow_chance: any;
  similac_chance: any;
  ensure_chance: any;
  glucerna_chance: any;
  pediasure_chance: any;
  firstTimeScan: any;

  modalRef: BsModalRef;
  showScanner: Boolean = false;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  config = {
    ignoreBackdropClick: true
  };
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false
  };
  iSprintData: any;
  showCamera: boolean = true;

  global_pediasure_Month_count:any;
  global_ensure_Month_count:any;
  global_glucerna_Month_count:any;
  global_grow_Month_count:any;
  global_similac_Month_count:any;

  remain_grow_Month_count:any;
  remain_similac_Month_count:any;
  remain_ensure_Month_count:any;
  remain_glucerna_Month_count:any;
  remain_pediasure_Month_count:any;

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private router: Router, private localeService: BsLocaleService, private bsService: BugsnagService) {
    this.firstTimeScan = sessionStorage.getItem('firstTimeScan')
    console.log(this.firstTimeScan, 'this.firstTimeScan ')
    this.currentDate = moment.locale('vi');
    this.currentDate = moment(this.myDate).format('MMMM YYYY');

    this.localeService.use(this.locale);
    let getAuthKey = sessionStorage.getItem('authToken');
    this.mobile_no = sessionStorage.getItem('mobileNo');
    this.myDate.setDate(this.myDate.getDate());
    this.spinner.show();
    this.maxDate = new Date();
    let today = new Date();
    this.scannedDate = moment(today).format('YYYY-MM-DD');
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"))
    this.prodCategory = this.iSprintData?.category_code
    let gettransresp
    this.apiService.getTransactionfull(this.mobile_no, getAuthKey).pipe(first()).subscribe(res => {
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(res)
      }
      gettransresp = res;
      let result = res['customer']['transactions'];
      let transcation = result['transaction'];
      // console.log(transcation, 'transcation')
      // let filtertran = transcation.forEach(element => {
      //   element.custom_fields?.field.filter(nn => {
      //     return console.log(nn.name == 'Glucerna')
      //   })
      // });
      // console.log(filtertran, 'filtertran')




    }, err => {
      this.spinner.hide();
      this.bsService.notifyError(err, getAuthKey);
    })
    let getcustresp;
    this.apiService.getCapCustomer(this.mobile_no, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        getcustresp = res;
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        this.spinner.hide()
        let ward: any = '';
        let street: any = '';
        let district: any = '';
        let city: any;
        this.custDetail = res?.customers?.customer[0];
        console.log(this.custDetail, 'this.custDetail---')
        let milkCodeCust = res?.customers?.customer[0]?.custom_fields?.field;
        if (milkCodeCust)
          milkCodeCust.forEach((element: any) => {
            if (element.name == "wards" && element.value != "") {
              ward = element.value;
            }
            if (element.name == "street_name" && element.value != "") {
              street = element.value;
            }
            if (element.name == "district" && element.value != "") {
              district = element.value;
            }
            if (element.name == "city" && element.value != "") {
              city = element.value;
            }
            if (element.name == 'milk_code') {
              this.milkCodeCust = element.value;
            }
          });
        // this.address = ward != ''  && street != '' ? street + ', ' + ward + ', ' + district + ', ' + city:
        // street + ', ' + district + ', ' + city

        if (ward == '' && street == '') {
          if (city != '' && city != undefined) {
            this.address = district + ', ' + city
          } else {
            this.address = district
          }
        }
        else if (ward != '' && street != '') {
          this.address = street + ', ' + ward + ', ' + district + ', ' + city
        }
        else if (ward == '') {
          this.address = street + ', ' + district + ', ' + city
        }
        else if (street == '') {
          this.address = ward + ', ' + district + ', ' + city
        }


      }, err => {
        this.bsService.notifyError(err, getAuthKey);
        this.spinner.hide();
      })


    setTimeout(() => {
      // this.grow_chance = JSON.parse(sessionStorage.getItem('grow_chances'));
      // this.similac_chance =  JSON.parse(sessionStorage.getItem('similac_chances'));

      this.similac_array_length = JSON.parse(sessionStorage.getItem('similacMilCount'));
      this.grow_array_length = JSON.parse(sessionStorage.getItem('growMilkCount'));
      this.remaining_grow_chances = JSON.parse(sessionStorage.getItem('grow_nonmilk_remaining_count'));
      this.remaining_similac_chances = JSON.parse(sessionStorage.getItem('similac_nonmilk_remaining_count'));

      this.ensure_array_length = JSON.parse(sessionStorage.getItem('ensureMilCount'));
      this.glucerna_array_length = JSON.parse(sessionStorage.getItem('glucernaMilkCount'));
      this.pediasure_array_length = JSON.parse(sessionStorage.getItem('pediasureMilkCount'));
      this.remaining_ensure_chances = JSON.parse(sessionStorage.getItem('ensure_nonmilk_remaining_count'));
      this.remaining_glucerna_chances = JSON.parse(sessionStorage.getItem('glucerna_nonmilk_remaining_count'));
      this.remaining_pediasure_chances = JSON.parse(sessionStorage.getItem('pediasure_nonmilk_remaining_count'));

      if (this.similac_array_length == 0 || this.similac_array_length == null) {
        this.remaining_similac_chances = 5;
      }
      if (this.grow_array_length == 0 || this.grow_array_length == null) {
        this.remaining_grow_chances = 5;
      }

      if (this.ensure_array_length == 0 || this.ensure_array_length == null) {
        this.remaining_ensure_chances = 5;
      }
      if (this.glucerna_array_length == 0 || this.glucerna_array_length == null) {
        this.remaining_glucerna_chances = 5;
      }
      if (this.pediasure_array_length == 0 || this.pediasure_array_length == null) {
        this.remaining_pediasure_chances = 5;
      }

      this.remaining_grow_chances = this.remaining_grow_chances < 0 ? this.remaining_grow_chances = 0 : 0 + this.remaining_grow_chances
      this.remaining_similac_chances = this.remaining_similac_chances < 0 ? this.remaining_similac_chances = 0 : 0 + this.remaining_similac_chances

      this.remaining_ensure_chances = this.remaining_ensure_chances < 0 ? this.remaining_ensure_chances = 0 : 0 + this.remaining_ensure_chances
      this.remaining_glucerna_chances = this.remaining_glucerna_chances < 0 ? this.remaining_glucerna_chances = 0 : 0 + this.remaining_glucerna_chances
      this.remaining_pediasure_chances = this.remaining_pediasure_chances < 0 ? this.remaining_pediasure_chances = 0 : 0 + this.remaining_pediasure_chances
    }, 2000)

  }

  ngOnInit(): void {
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    
    this.myForm = this.formBuilder.group({
      // Empty string or null means no initial value. Can be also specific date for
      // example: {date: {year: 2018, month: 10, day: 9}} which sets this date to initial
      // value.

      myDate: [null, Validators.required]
      // other controls are here...
    });

    this.getProductDescCode();
    this.getAllTransactions();
    this.getUserImage();
    this.getPreviousTranscation();
  }

  goToUserDetail() {
    this.router.navigate(['/user-profile-detail']);
  }

  setDate(): void {
    // Set today date using the patchValue function
    let date = new Date();
    this.myForm.patchValue({
      myDate: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    });

  }


  clearDate(): void {
    // Clear the date using the patchValue function
    this.myForm.patchValue({ myDate: null });
  }

  getProductDescCode() {
    this.milkCode = this.iSprintData?.is_milk_code;
    this.subCatCode = this.iSprintData?.sub_category_code;
    // this.content = this.scancodeData?.product?.txt;
    // if (this.content) {
    //   this.content.forEach((item: any) => {
    //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.subCatCode = parsedPrimaryValue.value;
    //     }
    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;

    //     }
    //   });
    // }
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  filterTransData
  norecords
  getAllTransactions() {
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')
    this.spinner.show();
    let getTransresp;
    this.apiService.getTransaction(mobile_no, getAuthKey, startOfMonth, endOfMonth).pipe(first())
      .subscribe((res: any) => {
        getTransresp = res;
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        this.spinner.hide();
        let result = res?.customer?.transactions;
        let transcation = result?.transaction;
        let transactinList = transcation;
        transactinList.forEach(element => {
          let data = element?.custom_fields?.field.forEach(cust =>{ 
             if(cust.name == 'scanned_date'){
               element.scannedDate = cust.value
             }
             if(cust.name == 'gift_id'){
               element.giftId = cust.value
             }
             if(cust.name == 'gift_delivery_date'){
               element.giftdeliveryDate = cust.value
             }
             if(cust.name == 'is_milk'){
               element.isMilk = cust.value
             }
             if(cust.name == 'expiration_date'){
               element.expiryDate = cust.value
               this.scannedDate < cust.value ? element.isNotExpired = true : element.isNotExpired = false;
             }
             if(cust.name == 'is_grow'){
               element.isGrow = cust.value 
             }
             if(cust.name == 'is_ensure'){
              element.isEnsure = cust.value 
            }
            if(cust.name == 'is_glucerna'){
              element.isGlucerna = cust.value 
            }
            if(cust.name == 'is_pediasure'){
              element.isPediasure = cust.value 
            }
             if(cust.name == 'is_eligible_for_game'){
               element.eligibleForGame = cust.value 
             }
             if(cust.name == 'is_redeemed'){
               element.isRedemed = cust.value
             }
             if(cust.name == 'itemcode'){
              element.itemcode = cust.value
            }
            if(cust.name == 'first_time_scan'){
              element.first_time_scan = cust.value
            }
            if(cust.name == 'category_code_trans'){
              element.category_code_trans = cust.value
            }

            
           });
     
         });

         let filterdate = transactinList.filter((item:any) => {
            return item.scannedDate >= startOfMonth && item.scannedDate <= endOfMonth
         });

         console.log(filterdate, 'filterdate===')
         
        this.filterTransData = transactinList;
          this.transactinList = transactinList.filter(res =>(res.isGrow == 'true'))
          console.log(this.transactinList, 'transactinList===')
        if (this.transactinList?.length == 0) {
          this.norecords = 'Không tìm thấy giao dịch nào!!'
        }
      }, err => {
        this.spinner.hide();
        this.bsService.notifyError(err, getAuthKey);
      })
  }

  filterProducts(event) {
    let filterProdVal = event.target.value;
    this.selectedProduct = filterProdVal
    if (filterProdVal == 'true') {
      let transactinList = this.filterTransData.filter(res =>res.isGrow == 'true')
      this.transactinList = transactinList;
      console.log( this.transactinList, 'transactinList===')
    }
    if (filterProdVal == 'false') {
      let transactinList = this.filterTransData.filter(res =>res.isGrow == 'false')
      this.transactinList = transactinList;
      console.log( this.transactinList, 'transactinList===')
    }

    if (filterProdVal == 'is_glucerna') {
      this.transactinList = []
      let transactinList = this.filterTransData.filter(res =>res.isGlucerna == 'true')
      this.transactinList = transactinList;
      console.log( this.transactinList, 'transactinList===')

    } if (filterProdVal == 'is_pediasure') {
      this.transactinList = []
      let transactinList = this.filterTransData.filter(res =>res.isPediasure == 'true')
      this.transactinList = transactinList;
      console.log( this.transactinList, 'transactinList===')

    }
    else if (filterProdVal == 'is_ensure') {
      this.transactinList = []
      let transactinList = this.filterTransData.filter(res =>res.isEnsure == 'true')
      this.transactinList = transactinList;
      console.log( this.transactinList, 'transactinList===')
    }

    if (this.transactinList?.length == 0) {
      this.norecords = 'Không tìm thấy giao dịch nào!!'
    }
  }

  modelDate = '';

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  dateChanged(date) {
    this.transactinList = [];
    let startOfMonth = moment(date).format('YYYY-MM-DD');
    let endOfMonth = moment(date).clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')
    this.spinner.show();
    this.apiService.getTransaction(mobile_no, getAuthKey, startOfMonth, endOfMonth).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        let result = res?.customer?.transactions;
        let transcation = result?.transaction;
        transcation.forEach(element => {
          let data = element?.custom_fields?.field.forEach(cust =>{ 
             if(cust.name == 'scanned_date'){
               element.scannedDate = cust.value
             }
             if(cust.name == 'gift_id'){
               element.giftId = cust.value
             }
             if(cust.name == 'gift_delivery_date'){
               element.giftdeliveryDate = cust.value
             }
             if(cust.name == 'is_milk'){
               element.isMilk = cust.value
             }
             if(cust.name == 'expiration_date'){
               element.expiryDate = cust.value
               this.scannedDate < cust.value ? element.isNotExpired = true : element.isNotExpired = false;
             }
             if(cust.name == 'is_grow'){
               element.isGrow = cust.value 
             }
             if(cust.name == 'is_ensure'){
              element.isEnsure = cust.value 
            }
            if(cust.name == 'is_glucerna'){
              element.isGlucerna = cust.value 
            }
            if(cust.name == 'is_pediasure'){
              element.isPediasure = cust.value 
            }
             if(cust.name == 'is_eligible_for_game'){
               element.eligibleForGame = cust.value 
             }
             if(cust.name == 'is_redeemed'){
               element.isRedemed = cust.value
             }
             if(cust.name == 'itemcode'){
              element.itemcode = cust.value
            }
            if(cust.name == 'first_time_scan'){
              element.first_time_scan = cust.value
            }
           });
     
         });
         
         this.filterTransData = transcation;
        let transactinList = transcation;
        if (this.selectedProduct == 'false') {
          this.transactinList = transactinList.filter(res =>(res.isGrow == 'false'))
          console.log( this.transactinList, 'transactinList===')
        }
        else if (this.selectedProduct == 'true') {
          this.transactinList = transactinList.filter(res =>(res.isGrow == "true"))
          console.log( this.transactinList, 'transactinList===')
        }
        else if (this.selectedProduct == 'is_ensure') {
          this.transactinList = transactinList.filter(res =>(res.isEnsure == "true"))
          console.log( this.transactinList, 'transactinList===')
        }
        else if (this.selectedProduct == 'is_glucerna') {
          this.transactinList = transactinList.filter(res =>(res.isGlucerna == "true"))
          console.log( this.transactinList, 'transactinList===')
        }
        else if (this.selectedProduct == 'is_pediasure') {
          this.transactinList = transactinList.filter(res =>(res.isPediasure == "true"))
          console.log( this.transactinList, 'transactinList===')
        }
        if (this.transactinList == 0) {
          this.norecords = 'Không tìm thấy giao dịch nào!!'
        }
        if (transcation)
          transcation.filter(val => {
            let custom_fields = val.custom_fields['field'];
          });

      },err=>{
        if(err)
        this.spinner.hide();
      })
  }

  parentUserImage: any = null;
  getUserImage() {
    this.spinner.show();
    let getAuthKey = sessionStorage.getItem('authToken');
    let mobile_no = sessionStorage.getItem('mobileNo');
    this.apiService.getCapCustomer(mobile_no, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide()
        let userInfo = res?.customers?.customer[0]?.custom_fields?.field;
        let image = userInfo.filter(res => res.name == 'profileimage');
        let profileImage = image[0]?.value;
        console.log(profileImage, 'image');

        this.apiService.getSavedImageFinal(mobile_no, getAuthKey, profileImage).pipe(first())
          .subscribe((res: any) => {
            this.spinner.hide();
            this.parentUserImage = res.file_path;  //-----------master user image
            console.log(this.parentUserImage, 'image get')

          })
      })

  }

  getPreviousTranscation() {
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')
    // let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    // let endOfMonth   = moment().clone().endOf('month').add(1, 'days').format('YYYY-MM-DD');

    let curr_date = new Date();
    let start_date = moment(curr_date).subtract(31, 'days');
    let startOfMonth = moment(start_date).format('YYYY-MM-DD');

    let addtoCurrDate = moment(curr_date).add(1, 'days');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');

    this.spinner.show();
    this.apiService.getTransactionfull(this.mobile_no, getAuthKey).pipe(first()).subscribe(res => {
        this.spinner.hide();
        let custom_fields: any;
        let value: any;
        let result = res['customer']['transactions'];
        let transcation = result['transaction'];
        // console.log('inside ---')
        // console.log(transcation, 'transcation test')
        // if (transcation.length == 0) {
        //   this.grow_chance = 0;
        //   this.similac_chance = 0;
        // }
        // console.log(transcation, 'transcation ================== transcation')
        if (transcation) {
          custom_fields = transcation.map(val => {
            value = val.custom_fields['field']
            value.push({ name: 'transaction_id', value: val.number })
            return val.custom_fields['field']
          });
        }
       // console.log(custom_fields, 'custom_fields ================== custom_fields')
        if (custom_fields) {
          //console.log('testing======================================')
          this.getAllSpinCount(transcation)
          //this.getTransactionCount(custom_fields);

        }
      })
  }



  global_non_redemmed_grow: any = [];
  global_non_redemmed_ensure: any = [];
  global_non_redemmed_glucerna: any = [];
  global_non_redemmed_pediasure: any = [];

  getAllSpinCount(allTransactions) {

    let transList = allTransactions;
    transList.forEach((element) => {
      let data = element?.custom_fields?.field.forEach((cust) => {
        if (cust.name == 'itemcode') {
          element.itemcode = cust.value;
        }
        if (cust.name == 'scanned_date') {
          element.scannedDate = cust.value;
        }
        if (cust.name == 'gift_id') {
          element.giftId = cust.value;
        }
        if (cust.name == 'gift_delivery_date') {
          element.giftdeliveryDate = cust.value;
        }
        if (cust.name == 'is_milk') {
          element.isMilk = cust.value;
        }
        if (cust.name == 'expiration_date') {
          element.expiryDate = cust.value;
          this.scannedDate < cust.value
            ? (element.isNotExpired = true)
            : (element.isNotExpired = false);
        }
        if (cust.name == 'is_grow' && cust.value == 'true') {
          element.isGrow = cust.value;
        }
        if (cust.name == 'is_grow' && cust.value == 'false') {
          element.isSimilac = 'true';
        }
        if(cust.name == 'is_ensure'){
         element.isEnsure = cust.value 
       }
       if(cust.name == 'is_glucerna'){
         element.isGlucerna = cust.value 
       }
       if(cust.name == 'is_pediasure'){
         element.isPediasure = cust.value 
       }
        if (cust.name == 'is_eligible_for_game') {
          element.eligibleForGame = cust.value;
        }
        if (cust.name == 'is_redeemed') {
          element.isRedemed = cust.value;
        }
      });
    });

    console.log(transList, 'this.Transobj');


    //  New logic for restrict to max 5 per month
    let filteredtranslist = transList.filter((data) => {
      return (
        //data?.isSimilac == 'true' &&

        data?.isNotExpired == true &&

        data?.isRedemed == 'false' &&

        data?.isMilk == 'false' &&

        data?.eligibleForGame == 'true'
      );
    });

    let availabelTransactionList = transList.filter((data) => {
      return (
        //data?.isSimilac == 'true' &&

        data?.giftId == 'null' &&

        data?.isRedemed == 'false' &&

        data?.isNotExpired == true &&

        data?.isMilk == 'false' &&
        data?.eligibleForGame == 'true'
      );
    });

    let startOfMonth = moment().clone().startOf('month').add(45, 'days').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(46, 'days').format('YYYY-MM-DD');

    let consumedTranslist = transList.filter((data) => {
      return (
        //console.log(data?.isGrow, data?.isRedemed, data?.isNotExpired, data?.isMilk, data?.eligibleForGame, data?.scannedDate >= startOfMonth && data?.scannedDate <= endOfMonth) 
       
      // data?.isSimilac == 'true' &&

       data?.isRedemed == 'true' &&

       data?.isNotExpired == true &&

       data?.isMilk == 'false' &&

       data?.eligibleForGame == 'true' &&

       data?.giftdeliveryDate >= startOfMonth && data?.giftdeliveryDate <= endOfMonth
      
      
      );
    });

    console.log(consumedTranslist, 'consumedTranslist')
    console.log(filteredtranslist, 'filteredtranslist')


    let custom_fields_details = [];

    filteredtranslist.forEach((transac) => {

      custom_fields_details.push(transac.custom_fields.field);

    });
    custom_fields_details.forEach(element => {
      let data = element?.forEach(cust =>{ 
         if(cust.name == 'scanned_date'){
           element.scannedDate = cust.value
         }
         if(cust.name == 'gift_id'){
           element.giftId = cust.value
         }
         if(cust.name == 'gift_delivery_date'){
           element.giftdeliveryDate = cust.value
         }
         if(cust.name == 'is_milk'){
           element.isMilk = cust.value
         }
         if(cust.name == 'expiration_date'){
           element.expiryDate = cust.value
           this.scannedDate < cust.value ? element.isNotExpired = true : element.isNotExpired = false;
         }
         if(cust.name == 'is_grow'){
           element.isGrow = cust.value 
         }
         if(cust.name == 'is_ensure'){
          element.isEnsure = cust.value 
        }
        if(cust.name == 'is_glucerna'){
          element.isGlucerna = cust.value 
        }
        if(cust.name == 'is_pediasure'){
          element.isPediasure = cust.value 
        }
         if(cust.name == 'is_eligible_for_game'){
           element.eligibleForGame = cust.value 
         }
         if(cust.name == 'is_redeemed'){
           element.isRedemed = cust.value
         }
         if(cust.name == 'itemcode'){
          element.itemcode = cust.value
        }
        if(cust.name == 'first_time_scan'){
          element.first_time_scan = cust.value
        }
       });
 
     });

    console.log(

      custom_fields_details,

      'custom fields of the filtered transactions'

    );

    // New Logic restrict to max 5 per month

    // Grow
    let growfilteredtranslist = filteredtranslist.filter(data => data?.isGrow == 'true' );
    let growavailabelTransactionList = availabelTransactionList.filter(data => data?.isGrow == 'true')
    let growconsumedTranslist = consumedTranslist.filter(data => data?.isGrow == 'true' );
    let growmaxNumber = 5;
    let growgamesPlayed = growconsumedTranslist?.length;
    console.log(growmaxNumber, 'growmaxNumber')
    console.log(growgamesPlayed, 'growgamesPlayed')
    let growfinalChancesofMonth = growmaxNumber - growgamesPlayed;
    console.log(growfinalChancesofMonth, 'growinalfChancesofMonth')
    let growfinalObjects = [...growavailabelTransactionList, ...growconsumedTranslist]
    growfinalObjects = growfinalObjects.filter(data => data?.isRedemed == 'false' );
    growfinalObjects = growfinalObjects.splice(0,growfinalChancesofMonth)
    this.grow_chance = growfinalObjects?.length;
    sessionStorage.setItem('grow_chances',JSON.stringify(growfinalObjects.length));
    
    // Similac
    let Similacfilteredtranslist = filteredtranslist.filter(data => data?.isSimilac == 'true' );
    let SimilacavailabelTransactionList = availabelTransactionList.filter(data => data?.isSimilac == 'true')
    let SimilacconsumedTranslist = consumedTranslist.filter(data => data?.isSimilac == 'true' );
    let SimilacmaxNumber = 5;
    let SimilacgamesPlayed = SimilacconsumedTranslist?.length;
    console.log(SimilacmaxNumber, 'SimilacmaxNumber')
    console.log(SimilacgamesPlayed, 'SimilacgamesPlayed')
    let SimilacfinalChancesofMonth = SimilacmaxNumber - SimilacgamesPlayed;
    console.log(SimilacfinalChancesofMonth, 'SimilacinalfChancesofMonth')
    let SimilacfinalObjects = [...SimilacavailabelTransactionList, ...SimilacconsumedTranslist]
    SimilacfinalObjects = SimilacfinalObjects.filter(data => data?.isRedemed == 'false' );
    SimilacfinalObjects = SimilacfinalObjects.splice(0,SimilacfinalChancesofMonth)
    this.similac_chance = SimilacfinalObjects?.length;
    sessionStorage.setItem('similac_chances',JSON.stringify(SimilacfinalObjects.length));

    // Ensure
    let Ensurefilteredtranslist = filteredtranslist.filter(data => data?.isEnsure == 'true' );
    let EnsureavailabelTransactionList = availabelTransactionList.filter(data => data?.isEnsure == 'true')
    let EnsureconsumedTranslist = consumedTranslist.filter(data => data?.isEnsure == 'true' );
    let EnsuremaxNumber = 5;
    let EnsuregamesPlayed = EnsureconsumedTranslist?.length;
    console.log(EnsuremaxNumber, 'EnsuremaxNumber')
    console.log(EnsuregamesPlayed, 'EnsuregamesPlayed')
    let EnsurefinalChancesofMonth = EnsuremaxNumber - EnsuregamesPlayed;
    console.log(EnsurefinalChancesofMonth, 'EnsureinalfChancesofMonth')
    let EnsurefinalObjects = [...EnsureavailabelTransactionList, ...EnsureconsumedTranslist]
    EnsurefinalObjects = EnsurefinalObjects.filter(data => data?.isRedemed == 'false' );
    EnsurefinalObjects = EnsurefinalObjects.splice(0,EnsurefinalChancesofMonth)
    this.ensure_chance = EnsurefinalObjects?.length;
    sessionStorage.setItem('ensure_chances',JSON.stringify(EnsurefinalObjects.length));

     // Pediasure
     let Pediasurefilteredtranslist = filteredtranslist.filter(data => data?.isPediasure == 'true' );
     let PediasureavailabelTransactionList = availabelTransactionList.filter(data => data?.isPediasure == 'true')
     let PediasureconsumedTranslist = consumedTranslist.filter(data => data?.isPediasure == 'true' );
     let PediasuremaxNumber = 5;
     let PediasuregamesPlayed = PediasureconsumedTranslist?.length;
     console.log(PediasuremaxNumber, 'PediasuremaxNumber')
     console.log(PediasuregamesPlayed, 'PediasuregamesPlayed')
     let PediasurefinalChancesofMonth = PediasuremaxNumber - PediasuregamesPlayed;
     console.log(PediasurefinalChancesofMonth, 'PediasureinalfChancesofMonth')
     let PediasurefinalObjects = [...PediasureavailabelTransactionList, ...PediasureconsumedTranslist]
     PediasurefinalObjects = PediasurefinalObjects.filter(data => data?.isRedemed == 'false' );
     PediasurefinalObjects = PediasurefinalObjects.splice(0,PediasurefinalChancesofMonth)
     this.pediasure_chance = PediasurefinalObjects?.length;
     sessionStorage.setItem('pediasure_chances',JSON.stringify(PediasurefinalObjects.length));

     // Glucerna
     let Glucernafilteredtranslist = filteredtranslist.filter(data => data?.isGlucerna == 'true' );
     let GlucernaavailabelTransactionList = availabelTransactionList.filter(data => data?.isGlucerna == 'true')
     let GlucernaconsumedTranslist = consumedTranslist.filter(data => data?.isGlucerna == 'true' );
     let GlucernamaxNumber = 5;
     let GlucernagamesPlayed = GlucernaconsumedTranslist?.length;
     console.log(GlucernamaxNumber, 'GlucernamaxNumber')
     console.log(GlucernagamesPlayed, 'GlucernagamesPlayed')
     let GlucernafinalChancesofMonth = GlucernamaxNumber - GlucernagamesPlayed;
     console.log(GlucernafinalChancesofMonth, 'GlucernainalfChancesofMonth')
     let GlucernafinalObjects = [...GlucernaavailabelTransactionList, ...GlucernaconsumedTranslist]
     GlucernafinalObjects = GlucernafinalObjects.filter(data => data?.isRedemed == 'false' );
     GlucernafinalObjects = GlucernafinalObjects.splice(0,GlucernafinalChancesofMonth)
     this.glucerna_chance = GlucernafinalObjects?.length;
     sessionStorage.setItem('glucerna_chances',JSON.stringify(GlucernafinalObjects.length));
}
  // getTransactionCount(custom_fields1) {

  //   this.global_non_redemmed_grow = [];
  //   this.global_non_redemmed_pediasure = [];
  //   this.global_non_redemmed_ensure = [];
  //   this.global_non_redemmed_glucerna = [];
  //   this.global_non_redemmed_pediasure = [];
  //   let non_redeemed_grow = [];
  //   let non_redeemed_similac = [];
  //   let nonmilk_similac_array = [];
  //   let nonmilk_grow_array = [];
  //   let all_nonmilk_array = []

  //   let non_redeemed_ensure = [];
  //   let non_redeemed_glucerna = [];
  //   let non_redeemed_pediasure = [];
  //   let nonmilk_ensure_array = [];
  //   let nonmilk_glucerna_array = [];
  //   let nonmilk_pediasure_array = [];

  //   custom_fields1.filter(custom_fields => {

  //     console.log(custom_fields, 'filter custom');
  //     if (custom_fields?.name == 'is_grow') {
  //       let a = custom_fields.findIndex(val => val?.name == 'is_grow');
  //       let b = custom_fields.findIndex(val => val?.name == 'is_milk');
  //       if (a > b) {
  //         let temp = custom_fields[a];
  //         custom_fields[a] = custom_fields[b];
  //         custom_fields[b] = temp;
  //       }
  //     }
  //     if (custom_fields?.name == 'is_ensure') {
  //       let a = custom_fields.findIndex(val => val?.name == 'is_ensure');
  //       let b = custom_fields.findIndex(val => val?.name == 'is_milk');
  //       if (a > b) {
  //         let temp = custom_fields[a];
  //         custom_fields[a] = custom_fields[b];
  //         custom_fields[b] = temp;
  //       }
  //     }
  //     if (custom_fields?.name == 'is_glucerna') {
  //       let a = custom_fields.findIndex(val => val?.name == 'is_glucerna');
  //       let b = custom_fields.findIndex(val => val?.name == 'is_milk');
  //       if (a > b) {
  //         let temp = custom_fields[a];
  //         custom_fields[a] = custom_fields[b];
  //         custom_fields[b] = temp;
  //       }
  //     }

  //     if (custom_fields?.name == 'is_pediasure') {
  //       let a = custom_fields.findIndex(val => val?.name == 'is_pediasure');
  //       let b = custom_fields.findIndex(val => val?.name == 'is_milk');
  //       if (a > b) {
  //         let temp = custom_fields[a];
  //         custom_fields[a] = custom_fields[b];
  //         custom_fields[b] = temp;
  //       }
  //     }



  //     let c = custom_fields.findIndex(val => val?.name == 'is_redeemed');
  //     let d = custom_fields.findIndex(val => val?.name == 'is_milk');
  //     if (c > d) {
  //       let temp = custom_fields[c];
  //       custom_fields[c] = custom_fields[d];
  //       custom_fields[d] = temp;
  //     }

  //     // console.log(custom_fields, 'custom_fields-----');
  //     custom_fields.filter(val => {
  //       // console.log(val, 'val----')
        

  //       if (val.name == 'is_ensure') {
  //         // val.value == "true" ? this.custom_isEnsure = true : this.custom_isGrow = 'null';
  //         if (val.value == "true") {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = true;
  //           this.custom_isGlucerna = false;
  //           this.custom_isPediasure = false;
  //         } else {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //           this.custom_isPediasure = false;
  //         }
  //         // console.log(val.value, 'is_ensure_value')
  //         // console.log(this.custom_isEnsure, 'custom_isEnsure value')
  //         // console.log(this.custom_isGrow, 'custom_isGrow value')
  //       }

  //       if (val.name == 'is_glucerna') {

  //         //val.value == "true" ? this.custom_isGlucerna = true : this.custom_isGrow = false;
  //         if (val.value == "true") {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = true;
  //           this.custom_isPediasure = false;
  //         } else {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //           this.custom_isPediasure = false;
  //         }
  //         console.log(val.value, 'is_glucerna_value')
  //         console.log(this.custom_isGlucerna, 'custom_isGlucerna value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //       }

  //       if (val.name == 'is_pediasure') {

  //         //val.value == "true" ? this.custom_isGlucerna = true : this.custom_isGrow = false;
  //         if (val.value == "true") {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //           this.custom_isPediasure = true;
  //         } else {
  //           this.custom_isGrow = 'null';
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //           this.custom_isPediasure = false;
  //         }
  //         console.log(val.value, 'is_pediasure_value')
  //         console.log(this.custom_isPediasure, 'custom_isPediasure value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //       }
  //       if (val?.name == 'is_grow') {
  //         //val.value == "true" ? this.custom_isGrow = true : this.custom_isGrow = false;
  //         if (val.value == "true") {
  //           this.custom_isGrow = true;
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //           this.custom_isPediasure = false;
  //         } else if (val.value == "false") {
  //           this.custom_isGrow = false;
  //           this.custom_isEnsure = false;
  //           this.custom_isGlucerna = false;
  //           this.custom_isPediasure = false;
  //         }
  //         console.log(val.value, 'is_grow_value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //         console.log(this.custom_isGrow, 'custom_isGrow value')
  //       }


  //       if (val.name == "expiration_date") {
  //         this.scannedDate < val.value ? this.is_not_expired = true : this.is_not_expired = false;
  //       }

  //       if (val.name == "is_eligible_for_game") {
  //         val.value == "true" ? this.game_is_eligible = true : this.game_is_eligible = false;
  //       }

  //       if (val.name == 'is_redeemed') {
  //         val.value == "true" ? this.redemmed = true : this.redemmed = false;
  //       }

  //       if (val.name == 'is_milk' && val.value == "false") {
  //         all_nonmilk_array.push(custom_fields);
  //         if (this.custom_isEnsure) {
  //           console.log(this.custom_isEnsure, 'this.custom_isEnsure')
  //           nonmilk_ensure_array.push(custom_fields);
  //           console.log(nonmilk_ensure_array, 'nonmilk_ensure_array')
  //         }
  //         else if (this.custom_isGlucerna) {
  //           console.log(this.custom_isGlucerna, 'this.custom_isGlucerna')
  //           nonmilk_glucerna_array.push(custom_fields);
  //           console.log(nonmilk_glucerna_array, 'nonmilk_glucerna_array')
  //         }
  //         else if (this.custom_isPediasure) {
  //           console.log(this.custom_isPediasure, 'this.custom_isPediasure')
  //           nonmilk_pediasure_array.push(custom_fields);
  //           console.log(nonmilk_pediasure_array, 'nonmilk_pediasure_array')
  //         }
  //         else if ((this.custom_isGrow == true && this.custom_isGrow != 'null')) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           nonmilk_grow_array.push(custom_fields);
  //           console.log(nonmilk_grow_array, 'nonmilk_grow_array')
  //         }
  //         else if ((this.custom_isGrow == false && this.custom_isGrow != 'null')) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           nonmilk_similac_array.push(custom_fields);
  //           console.log(nonmilk_similac_array, 'nonmilk_similac_array')
  //         }
  //         if (this.custom_isEnsure && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isEnsure, 'this.custom_isEnsure')
  //           non_redeemed_ensure.push(custom_fields)
  //           console.log(non_redeemed_ensure, 'non_redeemed_ensure')
  //         }
  //         if (this.custom_isGlucerna && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isGlucerna, 'this.custom_isGlucerna')
  //           non_redeemed_glucerna.push(custom_fields)
  //           console.log(non_redeemed_glucerna, 'non_redeemed_glucerna')
  //         }
  //         if (this.custom_isPediasure && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isPediasure, 'this.custom_isPediasure')
  //           non_redeemed_pediasure.push(custom_fields)
  //           console.log(non_redeemed_pediasure, 'non_redeemed_pediasure')
  //         }
  //         if ((this.custom_isGrow == true && this.custom_isGrow != 'null') && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           non_redeemed_grow.push(custom_fields)
  //           console.log(non_redeemed_grow, 'non_redeemed_grow')
  //         }
  //         if ((this.custom_isGrow == false && this.custom_isGrow != 'null') && this.is_not_expired && this.redemmed == false && this.game_is_eligible) {
  //           console.log(this.custom_isGrow, 'this.custom_isGrow')
  //           non_redeemed_similac.push(custom_fields);
  //           console.log(non_redeemed_similac, 'non_redeemed_similac')
  //         }
  //       }

  //     })

  //     this.grow_chance = non_redeemed_grow.length;
  //     this.similac_chance = non_redeemed_similac.length;

  //     this.ensure_chance = non_redeemed_ensure.length;
  //     this.glucerna_chance = non_redeemed_glucerna.length;
  //     this.pediasure_chance = non_redeemed_pediasure.length;

  //     console.log(this.grow_chance, ' this.grow_chance')
  //     console.log(this.similac_chance, ' this.similac_chance')
  //     console.log(this.ensure_chance, ' this.ensure_chance')
  //     console.log(this.glucerna_chance, ' this.glucerna_chance')
  //     console.log(this.pediasure_chance, ' this.pediasure_chance')

  //     sessionStorage.setItem('grow_chances', JSON.stringify(non_redeemed_grow.length));
  //     sessionStorage.setItem('similac_chances', JSON.stringify(non_redeemed_similac.length));

  //     sessionStorage.setItem('ensure_chances', JSON.stringify(non_redeemed_ensure.length));
  //     sessionStorage.setItem('glucerna_chances', JSON.stringify(non_redeemed_glucerna.length));
  //     sessionStorage.setItem('pediasure_chances', JSON.stringify(non_redeemed_pediasure.length));


      

  //   })


  // }

  openModal(template: TemplateRef<any>) {
    this.showCamera = false
    this.modalRef = this.modalService.show(template, this.config);
  }



  //------------------ scanner code in user-profile

  onClickScanner() {
    this.modalService.hide();
    this.showScanner = true;

    if (this.showScanner) {
      this.startVideo();
    }
  }
  closeScanner() {
    this.showScanner = false;
  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
  }

  startVideo() {
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 }
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }
  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
    if (code) {
      let scannedCode = code.data
      let getAuthKey = sessionStorage.getItem('authToken');
      window.location.href = scannedCode;
    }
    else {
      setTimeout(() => { this.checkImage(); }, 100)
    }
  }

  logOut() {
    this.router.navigate(['/sign-up']);
    sessionStorage.clear();
  }
}
