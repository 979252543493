<app-header></app-header>
<!-- <app-no-animate-breadcrumb></app-no-animate-breadcrumb> -->

<div class="tracebilityLandingContainer noPadding">
  <figure class="bannerOut">
    <!-- Traceability Banner -->
    <img
      *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac'"
      src="../../../assets/images/traceability/banner.png"
      alt="traceability/banner"
    />

    <!-- Traceability Ensure Banner -->
    <img
      *ngIf="prodCategory == 'Ensure'"
      src="../../../assets/images/traceability/traceability_ensure_top.png"
      alt="traceability_ensure_top"
    />

    <!-- Traceability Glucerna Banner -->
    <img
      *ngIf="prodCategory == 'Glucerna'"
      src="../../../assets/images/traceability/traceability_glucerna_top.png"
      alt="traceability_glucerna_top"
    />

    <!-- Traceability Pediasure Banner -->
    <img
      *ngIf="prodCategory == 'PediaSure'"
      src="../../../assets/images/traceability/traceability_pediasure_top.png"
      alt="traceability_pediasure_top"
    />
    <div class="contents">
      <div class="container">
        <div class="row m-0">
          <div class="col-8 text-center">
            <p class="bannerText">
              {{ "PRODUCT_INFORMATION.BANNER_TEXT1" | translate }}
            </p>
            <img
              class="careShareLogo"
              src="../../../assets/images/share_care_logo.png"
            />
            <p class="bannerText mb15">
              {{ "PRODUCT_INFORMATION.BANNER_TEXT2" | translate }}
            </p>
            <a
              class="bannerBtn"
              href="javascript:;"
              (click)="gotoHome()"
              routerLink="/sign-up/0"
              >{{ "PRODUCT_INFORMATION.BANNER_BUTTON_TEXT" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </figure>
  <div class="contents">
    <div class="row">
      <div class="col-5">
        <div class="primaryImgOut">
          <img class="primaryImg" [src]="productImage" />
          <!-- <img class="assurity" src="../../../assets/images/assurity.png" /> -->
        </div>
        <img
          class="animateImg mt40 one"
          src="../../../assets/images/Product-arrow-animation-left-to-right.gif"
        />
      </div>
      <div class="col-7 pl0">
        <div class="innerContents first">
          <h3 *ngIf="primaryProductTitle">{{ primaryProductTitle }}</h3>
          <p
            *ngIf="primaryShortProductInfo"
            class="mh-130"
            [innerHTML]="primaryShortProductInfo | moiIcon | safe: 'html'"
          ></p>

          <div class="details">
            <p>
              <label>{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_CODE_LABEL"
                  | translate
              }}</label
              ><span> {{ productCode }}</span>
            </p>
          </div>

          <div *ngIf="milkCode == '1'">
            <a
              href="javascript:;"
              class="primaryBtn"
              (click)="openModal(template)"
              >{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT"
                  | translate
              }}</a
            >
          </div>
          <div *ngIf="milkCode == '0'">
            <a
              href="javascript:;"
              class="primaryBtn"
              *ngIf="prodCategory == 'Grow'"
              routerLink="/product-description"
              >{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT"
                  | translate
              }}</a
            >
            <a
              href="javascript:;"
              class="primaryBtn"
              *ngIf="prodCategory == 'Similac'"
              routerLink="/similac-mom-product"
              >{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT"
                  | translate
              }}</a
            >
            <a
              href="javascript:;"
              class="primaryBtn"
              *ngIf="prodCategory == 'Ensure'"
              routerLink="/ensure-product"
              >{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT"
                  | translate
              }}</a
            >
            <a
              href="javascript:;"
              class="primaryBtn"
              *ngIf="prodCategory == 'Glucerna'"
              routerLink="/glucerna-product"
              >{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT"
                  | translate
              }}</a
            >
            <a
              href="javascript:;"
              class="primaryBtn"
              *ngIf="prodCategory == 'PediaSure'"
              routerLink="/pediasure-product"
              >{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT"
                  | translate
              }}</a
            >
          </div>
          <!-- <a href="javascript:;"
                        class="primaryBtn" *ngIf="prodCategory == 'Grow'" routerLink="/product-description">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT' | translate }}</a>
                    <a href="javascript:;"
                        class="primaryBtn" *ngIf="prodCategory == 'Similac'" routerLink="/similac-mom-product">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT' | translate }}</a> -->
        </div>
      </div>
    </div>
    <div class="row mt40">
      <div class="col-7 pr-0">
        <div class="innerContents">
          <h3 *ngIf="factoryTitleInfo">{{ factoryTitleInfo | translate }}</h3>
          <p class="colorPrimary" *ngIf="factoryShortProductDescription">
            {{ factoryShortProductDescription | translate }}
          </p>
          <div class="details">
            <p *ngIf="batchCode">
              <label>{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_CODE_LABEL"
                  | translate
              }}</label
              ><span> {{ batchCode }}</span>
            </p>
            <p *ngIf="manufacturedDate">
              <label>{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_MFR_DATE_LABEL"
                  | translate
              }}</label
              ><span> {{ manufacturedDate }}</span>
            </p>
            <p *ngIf="expiryDate">
              <label>{{
                "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_EXP_DATE_LABEL"
                  | translate
              }}</label
              ><span> {{ expiryDate }}</span>
            </p>
          </div>
          <a
            href=""
            class="primaryBtn"
            routerLink="/manufacturing-holland"
            *ngIf="manufactCountry == 'Netherlands'"
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
          <a
            href=""
            class="primaryBtn"
            routerLink="/manufacturing-granada"
            *ngIf="
              manufactCountry == 'Spain' ||
              manufactCountry == 'Granada, Tây Ban Nha'
            "
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
          <a
            href=""
            class="primaryBtn"
            routerLink="/manufacturing-us"
            *ngIf="manufactCountry == 'US'"
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
          <a
            href=""
            class="primaryBtn"
            routerLink="/manufacturing-cootehill"
            *ngIf="
              manufactCountry == 'Ireland' ||
              manufactCountry == 'Cootehill, Ireland'
            "
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
          <a
            href=""
            class="primaryBtn"
            routerLink="/manufacturing-singapore"
            *ngIf="manufactCountry.includes('Singapore')"
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
        </div>
      </div>
      <div class="col-5">
        <img class="manufactIcon" src="../../../assets/images/building.png" />
        <img
          class="mt40 animateImg two"
          src="../../../assets/images/Product-arrow-animation-right-to-left.gif"
        />
      </div>
    </div>
    <div class="row mt40">
      <div class="col-5">
        <img
          class="primaryImg img-fluid"
          src="../../../assets/images/search.png"
        />
        <img
          class="mt40 animateImg three"
          src="../../../assets/images/Product-arrow-animation-left-to-right.gif"
        />
      </div>
      <div class="col-7 pl0">
        <div class="innerContents">
          <h3>
            {{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_NAME"
                | translate
            }}
          </h3>
          <!-- <p>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_DESC' | translate }}</p> -->
          <p>
            Abbott đảm bảo chất lượng và độ an toàn tối đa của từng sản phẩm,
            thông qua quy trình kiểm định đáp ứng tiêu chuẩn quốc tế cao nhất.
          </p>
          <a
            href=""
            *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac'"
            class="primaryBtn"
            routerLink="/qc"
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
          <a
            href=""
            *ngIf="prodCategory == 'Ensure'"
            class="primaryBtn"
            routerLink="/ensure-qc"
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
          <a
            href=""
            *ngIf="prodCategory == 'Glucerna'"
            class="primaryBtn"
            routerLink="/glucerna-qc"
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
          <a
            href=""
            *ngIf="prodCategory == 'PediaSure'"
            class="primaryBtn"
            routerLink="/pediausre-qc"
            >{{
              "PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_BUTTON_TEXT"
                | translate
            }}</a
          >
        </div>
      </div>
    </div>
    <div class="row mt40">
      <div class="col-10 offset-2">
        <div class="row align-items-center">
          <div class="col-7">
            <div class="innerContents last">
              <h3>
                {{
                  "PRODUCT_INFORMATION.PRODUCT_INFORMATION_4.PRODUCT_NAME"
                    | translate
                }}
              </h3>
              <div class="details">
                <div class="details">
                  <p *ngIf="entranceDate">
                    <span> {{ entranceDate | translate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5 pl0">
            <img
              class="primaryImg img-fluid"
              src="../../../assets/images/ship.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Grow banner -->
  <div class="growWidget mt40" *ngIf="prodCategory == 'Grow'">
    <div class="growContainer">
      <div class="text-center">
        <button
          class="orangeBtn"
          *ngIf="milkCode == '1'"
          (click)="openModal(template)"
        >
          {{ "MORE_INFORMATION_BTN_TXT" | translate }}
        </button>
        <button
          class="orangeBtn"
          *ngIf="milkCode == '0'"
          routerLink="/product-description"
        >
          {{ "MORE_INFORMATION_BTN_TXT" | translate }}
        </button>
      </div>
    </div>
  </div>
  <!-- Similac banner -->
  <div class="similacWidget mt40" *ngIf="prodCategory == 'Similac'">
    <div class="similacContainer">
      <img
        src="../../../assets/images/similac-bg.png"
        class="w-100"
        *ngIf="
          subCatCode == '2001' ||
          subCatCode == '2002' ||
          subCatCode == '2003' ||
          subCatCode == '2004' ||
          subCatCode == '2005' ||
          subCatCode == '2006' ||
          subCatCode == '2007' ||
          subCatCode == '2008' ||
          subCatCode == '2009' ||
          subCatCode == '2010' ||
          subCatCode == '2011' ||
          subCatCode == '2012' ||
          subCatCode == '2013' ||
          subCatCode == '2014' ||
          subCatCode == '2015' ||
          subCatCode == '2016' ||
          subCatCode == '2017'
        "
      />
      <img
        src="../../../assets/images/vigor/vignew.png"
        class="w-100"
        *ngIf="
          subCatCode == '2018' ||
          subCatCode == '2019' ||
          subCatCode == '2020' ||
          subCatCode == '2021'
        "
      />
      <div
        *ngIf="
          subCatCode == '2001' ||
          subCatCode == '2002' ||
          subCatCode == '2003' ||
          subCatCode == '2004' ||
          subCatCode == '2005' ||
          subCatCode == '2006' ||
          subCatCode == '2007' ||
          subCatCode == '2008' ||
          subCatCode == '2009' ||
          subCatCode == '2010' ||
          subCatCode == '2011' ||
          subCatCode == '2012' ||
          subCatCode == '2013' ||
          subCatCode == '2014' ||
          subCatCode == '2015' ||
          subCatCode == '2016' ||
          subCatCode == '2017'
        "
      >
        <button
          class="orangeBtn"
          *ngIf="milkCode == '1'"
          (click)="openModal(template)"
        >
          {{ "MORE_INFORMATION_BTN_TXT" | translate }}
        </button>
        <button
          class="orangeBtn"
          *ngIf="milkCode == '0'"
          routerLink="/similac-mom-product"
        >
          {{ "MORE_INFORMATION_BTN_TXT" | translate }}
        </button>
      </div>
      <div
        *ngIf="
          subCatCode == '2018' ||
          subCatCode == '2019' ||
          subCatCode == '2020' ||
          subCatCode == '2021'
        "
      >
        <a
          href="javascript:;"
          class="orangeBtn vigor"
          *ngIf="milkCode == '1'"
          (click)="openModal(template)"
        >
          <img src="../../../assets/images/vigor/ctavigor.png" alt="img" />
        </a>
        <a
          href="javascript:;"
          class="orangeBtn vigor"
          *ngIf="milkCode == '0'"
          routerLink="/similac-mom-product"
        >
          <img src="../../../assets/images/vigor/ctavigor.png" alt="img" />
        </a>
      </div>
    </div>
  </div>

  <div class="similacWidget mt40" *ngIf="prodCategory == 'Ensure'">
    <div class="traceabilityBannerOut">
        <div class="line-img">
            <img src="../../../assets/images/traceability/line-copy.png" class="w-100" />
        </div>
      <img
        src="../../../assets/images/traceability/traceability_ensure_bottom_new.png"
        class="w-100"
      />
      <button class="orangeBtn position1" routerLink="/ensure-product">
        Tìm hiểu thêm
      </button>
    </div>
  </div>

  <div class="similacWidget mt40" *ngIf="prodCategory == 'Glucerna'">
    <div class="traceabilityBannerOut">
      <img
        src="../../../assets/images/traceability/traceability_glucerna_bottom.png"
        class="w-100"
      />
      <button class="orangeBtn position2" routerLink="/glucerna-product">
        Tìm hiểu thêm
      </button>
    </div>
  </div>

    <div class="similacWidget mt40" *ngIf="prodCategory == 'PediaSure'">
      <!-- Traceability Pediasure Banner -->
      <div class="traceabilityBannerOut" *ngIf="subCatCode == '4001'">
        <img
          src="../../../assets/images/traceability/traceability_pediasure_bottom.png"
          class="w-100"
        />
        
        <button class="orangeBtn position3" routerLink="/pediasure-product">
          Tìm hiểu thêm
        </button>
      </div>
  
       <!-- Pediasure plus banner -->
        <div class="ps-banner-section" *ngIf="subCatCode == '4002'">
          <img src="../../../assets/images/pediasure-ten-plus-banner.png" />
  
          <a class="btn-section" routerLink="/pediasure-product"
            ><img src="../../../assets/images/btn.png"
          /></a>
        </div>
  </div>

  <div class="shareAndCareContainer">
    <h1>
      {{ "SHARE_AND_CARE_INFO.MAIN_TITLE1" | translate }} <br />
      <span>{{ "SHARE_AND_CARE_INFO.MAIN_TITLE2" | translate }}</span>
    </h1>
    <p>
      {{ "SHARE_AND_CARE_INFO.SUB_TITLE1" | translate }} <br />
      <strong>{{ "SHARE_AND_CARE_INFO.SUB_TITLE2" | translate }}</strong>
    </p>
    <div class="contents">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-4">
            <img src="../../../assets/images/group4.png" />
          </div>
          <div class="col-8">
            <!-- <h6>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.TITLE1' | translate }}</h6>
                        <p>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.DESC1' | translate }}</p> -->
            <h6>Tìm hiểu khoa học dinh dưỡng</h6>
            <p>
              Giúp bạn khám phá những kiến thức khoa học chăm sóc sức khỏe cả
              nhà.
            </p>
          </div>
        </div>
        <div class="row mt20 align-items-center">
          <div class="col-4">
            <img src="../../../assets/images/group2.png" />
          </div>
          <div class="col-8">
            <!-- <h6>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.TITLE2' | translate }}</h6>
                        <p>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.DESC2' | translate }}</p> -->
            <h6>Đổi thưởng hấp dẫn</h6>
            <p>Quét mã QR thứ 2 dưới nắp lon, nhận nhiều ưu đãi hấp dẫn.</p>
          </div>
        </div>
        <div class="row mt20 align-items-center">
          <div class="col-4">
            <img src="../../../assets/images/group3.png" />
          </div>
          <div class="col-8">
            <h6>
              {{ "SHARE_AND_CARE_INFO.NUTRITION_INFO.TITLE3" | translate }}
            </h6>
            <p>
              {{ "SHARE_AND_CARE_INFO.NUTRITION_INFO.DESC3" | translate }}
              {{ "SHARE_AND_CARE_INFO.NUTRITION_INFO.CALL_CENTER" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="registerNowContainer">
    <!-- <h4>{{ 'REGISTRATION_INFO.TITLE' | translate }}</h4> -->
    <h4>Đăng ký thành viên ngay bạn nhé!</h4>
    <i><img src="../../../assets/images/register.png" alt="register" /></i>
    <div class="text-center">
      <button class="orangeBtn" (click)="gotoHome()" routerLink="/sign-up/0">
        {{ "REGISTER_NOW_BTN_TXT" | translate }}
      </button>
    </div>
    <p>
      {{ "REGISTRATION_INFO.NUTRITION_ADVICE1" | translate }}
      <a class="telNum" href="tel:19001519">{{
        "REGISTRATION_INFO.CALL_CENTER" | translate
      }}</a>
      <br />
      {{ "REGISTRATION_INFO.NUTRITION_ADVICE2" | translate }}
    </p>
    <!-- <small>{{ 'REGISTRATION_INFO.CODE' | translate }}</small> -->
    <small *ngIf="prodCategory == 'Ensure'">ENS-C-332-23</small>
    <small *ngIf="prodCategory == 'Glucerna'">GLU-C-171-21</small>
    <p *ngIf="prodCategory == 'PediaSure'">
      <small *ngIf="subCatCode == '4001'">PED-C-76-22</small>
      <small *ngIf="subCatCode == '4002'">PED-C-391-22</small>
    </p>
  </div>
</div>

<ng-template #template>
  <div class="modal-body">
    <section class="checkMilkcodeMdl">
      <button
        type="button"
        class="close pull-right closeBtn"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <p>
        Vui lòng xác nhận bạn là nhân viên y tế hoặc có nhu cầu tìm hiểu thông
        tin sản phẩm
      </p>
      <div class="row mb-3">
        <div class="col-6">
          <a href="javascript:;" class="primaryBtn" (click)="modalRef.hide()"
            >Quay về trang chủ</a
          >
        </div>
        <div class="col-6">
          <a
            href="javascript:;"
            class="primaryBtn"
            *ngIf="prodCategory == 'Grow'"
            (click)="gotoProdDesc()"
            >Xác nhận</a
          >
          <a
            href="javascript:;"
            class="primaryBtn"
            *ngIf="prodCategory == 'Similac'"
            (click)="gotoProdDesc()"
            >Xác nhận</a
          >
        </div>
      </div>
    </section>
  </div>
</ng-template>

<app-loader *ngIf="loader"></app-loader>
<app-footer></app-footer>
<app-nav-menu></app-nav-menu>
