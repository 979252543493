import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-similac-mom-product',
  templateUrl: './similac-mom-product.component.html',
  styleUrls: ['./similac-mom-product.component.scss']
})
export class SimilacMomProductComponent implements OnInit {

  scancodeData = null;
  prodCategory: any;
  content = [];
  milkCode:any;
  subCatCode:any;
  showArrowFlag = false;
  routeParamsMilkCode:any;
  routeParamsSubCatCode:any;
  iSprintData: any;
  constructor(private route: ActivatedRoute) { 
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    console.log(this.iSprintData);
    let a = this.route.snapshot.paramMap.get('subCatCode');
    let b = this.route.snapshot.paramMap.get('milkCode');

    this.routeParamsMilkCode = this.route.snapshot.params.milkCode;
    this.routeParamsSubCatCode = this.route.snapshot.params.subCatCode;
    console.log( this.routeParamsMilkCode,this.routeParamsSubCatCode, ' this.routeParams')
    console.log('', a,b);
    
   
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getProductDescCode();
    if(this.routeParamsMilkCode || this.routeParamsSubCatCode){
      this.milkCode = this.routeParamsMilkCode;
      this.subCatCode = this.routeParamsSubCatCode;
    }
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  getProductDescCode() {
    this.subCatCode = this.iSprintData?.sub_category_code ? this.iSprintData?.sub_category_code : "";
    this.milkCode = this.iSprintData?.is_milk_code;
    console.log(this.subCatCode, this.milkCode)
    // this.content = this.scancodeData?.product?.txt;
    // if( this.content)
    // this.content.forEach((item: any) => {
    
    //   if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //     let parsedPrimaryValue = this.parseFunction(item.value);
    //     this.subCatCode = parsedPrimaryValue.value;
    //   }
     
    //   if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //     let parsedPrimaryValue = this.parseFunction(item.value);
    //     this.milkCode = parsedPrimaryValue.value;
    //   }
    // });
  }

  showArrowIcons() {
    this.showArrowFlag = this.showArrowFlag === true ? false : true;
  }
  gotoHome(){
    sessionStorage.setItem('gotoHomePage', 'gotoHome')
  }
  ngOnDestroy(){
    console.log('back from similac prod desc')
    sessionStorage.setItem('activeSimilac', 'activeSimilac')
  }

}
