import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-temp-profile',
  templateUrl: './temp-profile.component.html',
  styleUrls: ['./temp-profile.component.scss']
})
export class TempProfileComponent implements OnInit {

  constructor(private router: Router) { 
    window.scrollTo({top: 0}); 
  }

  ngOnInit(): void {
    
  }

}
