<app-header></app-header>
<section class="signUpContainer">
    <section class="formContainer pt80">
        <img class="img-fluid centeredImg" src="../../../assets/images/check.png" />

        <h2 class="darKBlueBoldTitle mb-0 text-center">
            {{ 'SIGNUP_SUCCESS.TITLE1' | translate }}
        </h2>
        <h2 class="darKBlueBoldTitle mb-0 text-center">
            {{ 'SIGNUP_SUCCESS.TITLE2' | translate }}
        </h2>
        <img class="img-fluid shareCareImg" src="../../../assets/images/care&share-01.png" />
        <p class="textGrey text-center mb-0">{{ 'SIGNUP_SUCCESS.PARA' | translate }} {{ 'SIGNUP_SUCCESS.PARA1' | translate }}</p>

        <!-- <button class="orangeBtn mt-3" (click)="openRewardPopup()">RewardPopup</button>

        <button class="orangeBtn mt-4" (click)="outOfGiftModal()">out-of-gift</button>

        <button class="orangeBtn mt-3" (click)="openMilkCodeModal()">Milkcode</button> -->
    </section>
</section>

<div bsModal #rewardModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="rewardModalHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'REWARD_MODAL.TITLE_TOP' | translate }}</h2>
                        <p class="colorSecondaryBlack pt-2" *ngIf="prodCategory == 'Similac'"> {{ 'REWARD_MODAL.PARA1' | translate }}</p>
                        <p class="colorSecondaryBlack pt-2" *ngIf="prodCategory == 'Grow'"> {{ 'REWARD_MODAL.PARA1B' | translate }}</p>
                        <p class="orangeText pt-2"> {{sttrData?.gift_name}}</p>

                        <div class="imgBlock">
                            <img class="img-fluid middleImage" [src]="sttrData?.gift_image" />
                        </div>
                        <!-- <p class="mb-0 pt-2">{{ 'REWARD_MODAL.PARA3' | translate }}</p>
                        <p class="pt-0"> {{myDate | date:'dd/MM/yyyy'}}</p> -->
                        <p class="mb-0 pt-2">Quà sẽ được gứi đến bạn trong khoảng thơi gian chậm nhất là 45 ngày.</p>

                        <button class="orangeBtn my-4 btn-block" (click)="rewardModalHide()"> {{ 'REWARD_MODAL.BTN_TEXT' | translate }}</button>
                        
                        <div class="blueCard mt-40">
                            <p class="topText"> {{ 'REWARD_MODAL.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'REWARD_MODAL.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'REWARD_MODAL.CARD_PARA1' | translate }}</p>

                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>

                        <p class="py-4" *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac'">{{ 'REWARD_MODAL.CODE' | translate }}</p>
                        <p class="py-4" *ngIf="prodCategory == 'Ensure'">ENS-C-506-21</p>
                        <p class="py-4" *ngIf="prodCategory == 'Glucerna'">GLU-C-200-21</p>
                        <p class="py-4" *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</p>

                </section>
            </div>
        </div>
    </div>
</div>



<div bsModal #ouOfGiftModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="ouOfGiftHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3 mb-0">{{ 'OUT_OF_GIFT.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'OUT_OF_GIFT.TITLE2' | translate }}</h2>
                        <p class="pt-2 mb-0">{{ 'OUT_OF_GIFT.PARA1' | translate }}</p>
                        <p class="pt-0 mb-0"> {{ 'OUT_OF_GIFT.PARA2' | translate }}</p>
                        <p class="pt-2 mb-0"> {{ 'OUT_OF_GIFT.PARA3' | translate }}</p>
                        <p class="mb-0 pt-0" *ngIf="prodCategory == 'Grow'"> {{ 'OUT_OF_GIFT.PARA4' | translate }}</p>
                        <p class="mb-0 pt-0" *ngIf="prodCategory == 'Similac'"> {{ 'OUT_OF_GIFT.PARA4A' | translate }}</p>
                        <p class="pt-0">{{ 'OUT_OF_GIFT.PARA5' | translate }} </p>
                        <button class="btn-block orangeBtn my-3" (click)="ouOfGiftHide()"> {{ 'OUT_OF_GIFT.BTN_TEXT' | translate }}</button>
                        <a class="pt-3 mb-4 links">{{ 'OUT_OF_GIFT.LINK_TEXT' | translate }}</a>
                        <div class="blueCard mt-40">
                            <p class="topText"> {{ 'OUT_OF_GIFT.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'OUT_OF_GIFT.CARD_PARA' | translate }} </p>
                            <p class="colorBlackBold pt-0">{{ 'OUT_OF_GIFT.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>
                        <p class="py-4" *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac'">{{ 'OUT_OF_GIFT.CODE' | translate }}</p>
    
                        <p class="py-4" *ngIf="prodCategory == 'Ensure'">ENS-C-506-21</p>
                        <p class="py-4" *ngIf="prodCategory == 'Glucerna'">GLU-C-200-21</p>
                        <p class="py-4" *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</p>
                </section>
            </div>
        </div>
    </div>
</div>


<!-- <div bsModal #milkCodeModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="milkCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'MILK_CODE.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'MILK_CODE.TITLE2' | translate }}</h2>
                        <p class=" mb-0 pt-0" style="font-size: 4.3vw;"> {{ 'MILK_CODE.PARA' | translate }}<br /> {{ 'MILK_CODE.PARA2' | translate }}<br /> {{ 'MILK_CODE.PARA3' | translate }}<br /> {{ 'MILK_CODE.PARA4' | translate }}</p> -->
                        <!-- <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA2' | translate }}</p>
                        <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA3' | translate }}</p>
                        <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA4' | translate }}</p> -->
                        <!-- <button class="orangeBtn my-4" (click)="milkCodeHide()"> {{ 'MILK_CODE.BTN_TEXT' | translate }}</button>
                        <a class="pt-3 mb-4 links d-block"> {{ 'MILK_CODE.LINK_TEXT' | translate }}</a>
                        <div class="blueCard mt-4">
                            <p class="topText"> {{ 'MILK_CODE.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'MILK_CODE.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'MILK_CODE.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>
                        <p class="py-4" *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac'">{{ 'MILK_CODE.CODE' | translate }}</p>
                    
                        <p class="py-4" *ngIf="prodCategory == 'Ensure'">ENS-C-506-21</p>
                        <p class="py-4" *ngIf="prodCategory == 'Glucerna'">GLU-C-200-21</p>
                        <p class="py-4" *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</p>
                </section>
            </div>
        </div>
    </div>
</div> -->
<div
  bsModal
  #milkCodeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div
      class="modal-content videoModalContainer"
      style="background-image: linear-gradient(to top, #ffce4185, #fcd64e80);"
    >
      <div class="modal-body">
        <section class="checkMilkcodeMdl text-center">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="milkCodeHide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <img
            src="../../../assets/images/img1.png"
            alt="Similac - Thank you Mom for becoming a member of Care & Share Home"
            class="modal-image"
          />
          <div class="giftcontent-1">
            <img
              class="gift-box-image"
              src="../../../assets/images/giftbox.png"
              alt="gift box"
            />
            <strong>Cẩm Nang Lần Đầu Làm Mẹ:</strong>
            mách mẹ bộ bí kíp chăm con nhàn tênh, được chia sẻ bởi các chuyên
            gia và mẹ bỉm sữa giàu kinh nghiệm.
          </div>
          <div class="container-box">
            <img class="rectangle-box" src="../../../assets/images/img02.png" />
            <div class="cta-btn1">
              <a
                href="https://www.family.abbott/content/dam/an/familyabbott/vn-vi/similac/eazy-ebook/sim_lp_eazy-ebook_20231110_pdf01.pdf"
                target="_blank"
              >
                <img
                  src="../../../assets/images/cta.png" alt="cta"
                />
              </a>
            </div>
          </div>
          <div class="giftcontent-2">
            <img
              class="gift-box-image"
              src="../../../assets/images/giftbox.png"
              alt="gift box"
            />
            <strong>Cẩm Nang Chăm Sóc Mẹ Sinh Mổ:</strong>
            chia sẻ đến mẹ những tips hữu ích về cách chăm sóc bé sinh mổ cũng
            như cách giúp mẹ sinh mổ hồi phục sau sinh.
          </div>
          <div class="container-box1">
            <img
              class="rectangle-box1"
              src="../../../assets/images/img3.png"
            />
            <div class="cta-btn2">
              <a href="https://www.family.abbott/content/dam/an/familyabbott/vn-vi/similac/c-section-ebook/sim_pl_c-section-ebook_20231208_pdf02.pdf" target="_blank">
                <img src="../../../assets/images/cta.png" alt="cta"/>
            </a>
            </div>
          </div>
          <span class="sim-c" aria-hidden="true"> SIM-C-484-23 </span>
        </section>
      </div>
    </div>
  </div>
</div>

<div bsModal #invalidQrCodeModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="invalidQrCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'INVALIDQR_CODE1.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'INVALIDQR_CODE1.TITLE2' | translate }}</h2>
                        <!-- <div>
                            <p class="pt-2 mb-0"> {{ 'INVALIDQR_CODE1.PARA1' | translate }}</p>
                            <a class="links d-block" routerLink=""> {{ 'INVALIDQR_CODE1.PARA2' | translate }}</a>
                            <p class="mb-0 pt-0"> {{ 'INVALIDQR_CODE1.PARA3' | translate }}</p>
                            <a class="pt-3 mb-4 links d-block"> {{ 'INVALIDQR_CODE1.LINK_TEXT' | translate }}</a>
                        </div> -->
                        <div>
                            <p class="pt-2 mb-0">{{ 'INVALIDQR_CODE1.CODE2_PARA1' | translate }} <a class="links" (click)="openSimilacTermsandConditions()"> {{ 'INVALIDQR_CODE1.CODE2_LINK' | translate }}</a> {{ 'INVALIDQR_CODE1.CODE2_PARA2' | translate }}</p>
                        </div>

                        <div class="blueCard mt-4">
                            <p class="topText"> {{ 'INVALIDQR_CODE1.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'INVALIDQR_CODE1.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'INVALIDQR_CODE1.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #tryagainModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                   
                    <h2 class="blueTitle pt-3">có gì đó không ổn!</h2>
                   
                    <button class="orangeBtn my-4" (click)="addTrans()"> Thử lại</button>

                        <div class="blueCard mt-4">
                            <p class="topText"> {{ 'INVALIDQR_CODE1.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'INVALIDQR_CODE1.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'INVALIDQR_CODE1.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>
                </section>
            </div>
        </div>
    </div>
</div>




<div bsModal #termsSimilac="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body rewardsLoginOut px-0">
                <section class="similacTermsMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="termsSimilacHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 class="text-uppercase textSecondaryBlue">Thể lệ chương trình</h3>

                    <div class="giftList p-0 mt-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="grow-tab1" 
                                    data-toggle="tab" href="#grow1" role="tab" aria-controls="home"
                                    aria-selected="true">{{ 'REWARDS_LOGIN.ABBOTT_GROW' |
                                    translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="similac-tab1"
                                    data-toggle="tab" href="#similac1" role="tab" aria-controls="profile"
                                    aria-selected="false">{{ 'REWARDS_LOGIN.SIMILAC'
                                    | translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="pediasure-tab1"
                                    data-toggle="tab" href="#pediasure1" role="tab" aria-controls="profile"
                                    aria-selected="false">PediaSure</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="ensure-tab1"
                                    data-toggle="tab" href="#ensure1" role="tab" aria-controls="profile"
                                    aria-selected="false">Ensure Gold</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="glucerna-tab1"
                                    data-toggle="tab" href="#glucerna1" role="tab" aria-controls="profile"
                                    aria-selected="false">Glucerna</a>
                            </li>
                           
                        </ul>
                        <div class="tab-content" id="myTabContent1">
                            <div id="grow1" role="tabpanel" aria-labelledby="grow-tab1" class="tab-pane fade show active" >
                                <div class="innerContent">
                                    <section *ngIf="growtermsContent" [innerHTML]="growtermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="similac1" role="tabpanel" aria-labelledby="similac-tab1" class="tab-pane fade show">
                                <div class="innerContent">
                                    <section *ngIf="termsContent" [innerHTML]="termsContent | safe: 'html'"></section>
                                </div>
                            </div>
                            <div id="pediasure1" role="tabpanel" aria-labelledby="pediasure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="pediasureltermsContent" [innerHTML]="pediasureltermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="ensure1" role="tabpanel" aria-labelledby="ensure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="ensuretermsContent" [innerHTML]="ensuretermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="glucerna1" role="tabpanel" aria-labelledby="glucerna-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="glucernatermsContent" [innerHTML]="glucernatermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<app-dynamic-footer code="COR-C-12-21"></app-dynamic-footer>