<app-header [hidden]="showScanner"></app-header>
<!-- <app-dynamic-no-animate-breadcrumb class="customBreadcrumb"
    textone="{{ 'BREADCRUMB.ATTRACTIVE_BONUS_REDEMPTION' | translate }}" [hidden]="showScanner">
</app-dynamic-no-animate-breadcrumb> -->
<div class="rewardsLoginOut" [hidden]="showScanner">
    <p class="giftProgram">{{ 'REWARDS_LOGIN.GIFT_PROGRAM' | translate }}</p>

    <div class="giftOut">
        <!-- <h1>{{ 'REWARDS_LOGIN.LOTS_OF_GIFTS' | translate }} <span>{{ 'REWARDS_LOGIN.WITH_BIG_KIDS' | translate }}</span>
        </h1> -->
        <h1>
            NHẬN QUÀ HẤP DẪN
            <span>SỐNG KHỎE AN VUI</span>
        </h1>
        <figure>
            <img src="../../../assets/images/rewards/gift-banner-copy.png" alt="gift-banner-copy" />
        </figure>
        <div class="text-center">
            <button class="orangeBtn" (click)="openModal(QR2Popup)">{{ 'SCAN_QR_CODE_NOW' | translate }}</button>
            <a href="javascript:;" (click)="openSimilacTermsandConditions()" class="tandc">{{ 'REWARDS_LOGIN.POLICY_TERMS_CONDITIONS' | translate }}</a>
        </div>
    </div>
    <!-- <div class="firstLoginGift">
        <div class="top"></div>
        <div class="body">
            <h1>{{ 'REWARDS_LOGIN.FIRST_LOGIN_GIFT' | translate }}</h1>
            <small>{{ 'REWARDS_LOGIN.LIMITED_AMOUNT' | translate }}</small>
            <div class="row">
                <div class="col-6">
                    <div class="loginDetails">
                        <img src="../../../assets/images/gift_grow_logo.png" alt="grow" />
                        <div class="loginMemebers">
                            <h1>{{growRewardList}}</h1>
                            <span>{{ 'REWARDS_LOGIN.THE_REST_OF_THE_GIFT' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="loginDetails">
                        <img src="../../../assets/images/gift_similac_logo.png" alt="similac" />
                        <p class="level">{{ 'REWARDS_LOGIN.APPLICATION_FOR_SIMILAC' | translate }}<img
                                src="../../../assets/images/iqplus.png" alt="iQ Plus" />{{ 'REWARDS_LOGIN.SIMILAC_MOM' |
                            translate }}<img src="../../../assets/images/iqplus.png" alt="iQ Plus" /> </p>
                        <div class="loginMemebers">
                            <h1>{{similacRewardList}}</h1>
                            <span>{{ 'REWARDS_LOGIN.THE_REST_OF_THE_GIFT' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    
    <!-- <div class="giftList">
        <h1>{{ 'REWARDS_LOGIN.GIFT_LIST' | translate }}</h1>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link " id="grow-tab" [ngClass]="(showActiveTabGrow) ? 'active': ''" data-toggle="tab"
                    href="#grow" role="tab" aria-controls="home" aria-selected="true">{{ 'REWARDS_LOGIN.ABBOTT_GROW' |
                    translate }}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="similac-tab" [ngClass]="(showActiveTabSimilac) ? 'active': ''" data-toggle="tab"
                    href="#similac" role="tab" aria-controls="profile" aria-selected="false">{{ 'REWARDS_LOGIN.SIMILAC'
                    | translate }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div id="grow" role="tabpanel" aria-labelledby="home-tab"
                [ngClass]="(showActiveTabGrow) ? 'tab-pane fade show active' : 'tab-pane fade show '">
                <div class="innerContent">
                    <h5>{{ 'REWARDS_LOGIN.FIRST_LOGIN_GIFT' | translate }}</h5>
                    <p>{{ 'REWARDS_LOGIN.SHARE_AND_CARE_HOME' | translate }}</p>
                    <img src="../../../assets/images/grow_tab_gift.png" alt="" />
                    <h5>{{ 'REWARDS_LOGIN.GIFT_GROW_PRODUCTS' | translate }}</h5>
                    <p>{{ 'REWARDS_LOGIN.ABBOTT_GROW_DESC' | translate }}</p>
                    <img src="../../../assets/images/spinwheel_gift.png" alt="" />
                </div>
                <div class="qrCodeScan">
                    <img class="roundedImage" src="../../../assets/images/qrscan.png" alt="qr scan" />
                   
                    <p class="luckyDraw" *ngIf="authToken">Bạn còn {{remaining_grow_chances}} lượt tham gia vòng quay may mắn với Abbott Grow trong tháng này</p>
                    
                    <div class="text-center">
                        <button class="orangeBtn" (click)="openModal(QR2Popup)">{{ 'SCAN_QR_CODE_NOW' | translate
                            }}</button><br>
                        
                    </div>
                    <p *ngIf="!authToken">Bạn có tối đa 5 lần quy đổi lượt quay may mắn mỗi tháng với các sản phẩm từ Abbott Grow, vui lòng kiểm tra kĩ trước khi quét mã</p>
                    <p *ngIf="authToken">{{ 'REWARDS_LOGIN.LUCKY_SPINS_REDEMPTION_BEFORE' | translate }} 5 {{
                        'REWARDS_LOGIN.LUCKY_SPINS_REDEMPTION_AFTER' | translate }}</p>
                </div>

                <div class="aboutNutrition" *ngIf="!authToken">
                    <div class="contents">
                        <h1>{{ 'REWARDS_LOGIN.PEACE_OF_MIND' | translate }}</h1>
                        <p>{{ 'REWARDS_LOGIN.SIGN_UP_AS_MEMEBER' | translate }}</p>
                    </div>
                    <div class="text-center">
                        <button class="orangeBtn" routerLink="/sign-up/1">{{ 'REGISTER_NOW' | translate }}</button>
                    </div>
                    <img src="../../../assets/images/about_nutrition_family.png" alt="nutrition" />
                </div>
            </div>
            <div id="similac" role="tabpanel" aria-labelledby="profile-tab"
                [ngClass]="(showActiveTabSimilac) ? 'tab-pane fade show active' : 'tab-pane fade show '">
                <div class="innerContent">
                    <h5>{{ 'REWARDS_LOGIN.FIRST_LOGIN_GIFT' | translate }}</h5>
                    <p>{{ 'REWARDS_LOGIN.SHARE_AND_CARE_HOME_SIMILAC' | translate }} <img
                            src="../../../assets/images/iqplus.png" alt="iQ Plus" /> {{ 'REWARDS_LOGIN.SIMILAC_MOM' |
                        translate }} <img src="../../../assets/images/iqplus.png" alt="iQ Plus" />. </p>
                    <img src="../../../assets/images/similac_tab_gift.png" alt="" />
                    <h5>{{ 'REWARDS_LOGIN.PRODUCT_GIFT' | translate }} <img
                            src="../../../assets/images/similac_iq_plus_4.png" alt="" /></h5>
                    <p>{{ 'REWARDS_LOGIN.QR_CODES_OF_SIMILAC' | translate }} <img
                            src="../../../assets/images/iqplus.png" alt="iQ Plus" /> {{ 'REWARDS_LOGIN.LUCKY_WHEEL' |
                        translate }}</p>
                    <img src="../../../assets/images/similac_spinwheel_prd4.png" alt="" />
                    <h5>{{ 'REWARDS_LOGIN.PRODUCT_GIFT' | translate }} <img class="similacMomLogo"
                            src="../../../assets/images/logo-similac-mom.png" alt="" /></h5>
                    <p>{{ 'REWARDS_LOGIN.QR_CODES_OF_SIMILAC_MOM' | translate }} <img
                            src="../../../assets/images/iqplus.png" alt="iQ Plus" />, {{ 'REWARDS_LOGIN.LUCKY_WHEEL1' |
                        translate }}</p>
                    <img src="../../../assets/images/similac_tab_prd_mom.png" alt="" />
                </div>

                <div class="qrCodeScan">
                    <img class="roundedImage" src="../../../assets/images/qrscan.png" alt="qr scan" />
                    
                    <p class="luckyDraw" *ngIf="authToken">Bạn vẫn có thể đổi thêm {{remaining_similac_chances}} lượt tham gia vòng quay may mắn với Similac trong tháng này</p>
                    
                    <div class="text-center">
                        <button class="orangeBtn" (click)="openModal(QR2Popup)">{{ 'SCAN_QR_CODE_NOW' | translate
                            }}</button><br>
                        
                    </div>
                    <p *ngIf="!authToken">Bạn có thể tiếp tục mua hàng và đổi thêm 5 lượt tham gia vòng quay may mắn với Similac trong tháng này.</p>
                    <p *ngIf="authToken">Bạn có tối đa 5 lần quy đổi lượt quay may mắn mỗi tháng với các sản phẩm từ với các sản phẩm
                        Similac <img src="../../../assets/images/iqplus.png" alt="iQ Plus" />4 và Similac Mom <img
                            src="../../../assets/images/iqplus.png" alt="iQ Plus" />, vui lòng kiểm tra kĩ trước khi
                        quét mã.</p>
                </div>
                <div class="aboutNutrition" *ngIf="!authToken">
                    <div class="contents">
                        <h1>{{ 'REWARDS_LOGIN.PEACE_OF_MIND' | translate }}</h1>
                        <p>{{ 'REWARDS_LOGIN.SIGN_UP_AS_MEMEBER' | translate }}</p>
                    </div>
                    <div class="text-center">
                        <button class="orangeBtn" routerLink="/sign-up/1">{{ 'REGISTER_NOW' | translate }}</button>
                    </div>
                    <img src="../../../assets/images/about_nutrition_family.png" alt="nutrition" />
                </div>
            </div>
        </div>
    </div> -->
    <div class="giftListOut">
        <div class="body">
            <h1>Danh sách quà tặng</h1>
            <div class="giftList">
                <ul class="nav nav-tabs first" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link " id="grow-tab" (click)="childTab()" [ngClass]="(showActiveTabGrow) ? 'active': ''" data-toggle="tab"
                            href="#grow" role="tab" aria-controls="home" aria-selected="true">Dòng sản phẩm<br>
                            trẻ em</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" (click)="adultTab()" id="similac-tab" [ngClass]="(showActiveTabSimilac) ? 'active': ''" data-toggle="tab"
                            href="#similac" role="tab" aria-controls="profile" aria-selected="false">Dòng sản phẩm<br>
                            người lớn</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div id="grow" role="tabpanel" aria-labelledby="home-tab"
                        [ngClass]="(showActiveTabGrow) ? 'tab-pane fade show active' : 'tab-pane fade show '">
                        <div class="scrollableTabOut">
                            <nav class="scrollableTab">
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" (click)="growClicked()">Abbott Grow Gold</a>
                                    <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" (click)="similacClicked()">Similac 4 & SMOM</a>
                                    <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact1" role="tab" aria-controls="nav-contact" aria-selected="false" (click)="pediasureClicked()">PediaSure</a>
                                </div>
                            </nav>
                            <div class="tab-content scrollableTabContent" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <div class="innerContent">
                                        <h5>Phần quà cho lần đăng nhập đầu tiên</h5>
                                        <p>
                                            Với lần đăng nhập đầu tiên trên Care & Share Home,  bạn có cơ hội nhận ngay 1 phần quà 
                                            từ Abbott Grow Gold (Số lượng giới hạn mỗi tháng)
                                        </p>
                                        <img src="../../../assets/images/oct24-games/grow-first.png" alt="gift-2-abbott-grow" />
                                        <h2 class="remainingGift">
                                            {{growRewardList}} 
                                            <small>Phần quà còn lại</small>
                                            <hr>
                                        </h2>
                                        <h5>Phần quà cho sản phẩm Abbott Grow Gold</h5>
                                        <p>
                                            Từ lần thứ 2 trở đi, cho mỗi lần mua và quét mã QR của các sản phẩm Abbott Grow Gold, bạn 
                                            được nhận ngay 1 lượt tham gia vòng quay may mắn để có cơ hội trúng các phần quà hấp dẫn.
                                        </p>
                                        <img src="../../../assets/images/Aug23-games/grw.png" alt="conffetti-abbott-grow" />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div class="innerContent">
                                        <h5>{{ 'REWARDS_LOGIN.FIRST_LOGIN_GIFT' | translate }}</h5>
                                        <p>{{ 'REWARDS_LOGIN.SHARE_AND_CARE_HOME_SIMILAC' | translate }} {{ 'REWARDS_LOGIN.SIMILAC_MOM' |
                                            translate }} <sup><img src="../../../assets/images/iqplus.png" alt="iQ Plus" /></sup>. </p>
                                            <img src="../../../assets/images/Jan23-games/rewards-similac-gift.png" alt="" />
                                            <h2 class="remainingGift">
                                            {{similacRewardList}} 
                                            <small>Phần quà còn lại</small>
                                            <hr>
                                        </h2>
                                        <h5>{{ 'REWARDS_LOGIN.PRODUCT_GIFT' | translate }} <img
                                                src="../../../assets/images/Similac4.png" alt="" /></h5>
                                       
                                                <p>{{ 'REWARDS_LOGIN.QR_CODES_OF_SIMILAC' | translate }} {{ 'REWARDS_LOGIN.LUCKY_WHEEL' |
                                            translate }}</p>
                                            
                                             <img src="../../../assets/images/Aug23-games/similac-conffetti.png" alt="" />
                                            <h5>{{ 'REWARDS_LOGIN.PRODUCT_GIFT' | translate }} <img class="similacMomLogo"
                                                src="../../../assets/images/logo-similac-mom.png" alt="" /></h5>
                                        <p>{{ 'REWARDS_LOGIN.QR_CODES_OF_SIMILAC_MOM' | translate }} <sup><img
                                            src="../../../assets/images/iqplus.png" alt="iQ Plus" /></sup>, {{ 'REWARDS_LOGIN.LUCKY_WHEEL1' |
                                            translate }}</p>
                                             <img src="../../../assets/images/Aug23-games/similac-conffetti.png" alt="" />
                                        </div>
                                </div>
                                <div class="tab-pane fade" id="nav-contact1" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div class="innerContent">
                                        <h5>Phần quà cho lần đăng nhập đầu tiên</h5>
                                        <p>
                                            Với lần đăng nhập đầu tiên trên Care & Share Home,  bạn có cơ hội nhận ngay 1 phần quà từ PediaSure
(Số lượng giới hạn & quà thực tế
có thể thay đổi mỗi tháng)

                                        </p>
                                        <img src="../../../assets/images/july2024/pediasure-first.png" alt="pediasure-gift" />
                                        <h2 class="remainingGift">
                                            {{pediasureRewardList}} 
                                            <small>Phần quà còn lại</small>
                                            <hr>
                                        </h2>
                                        <h5>Phần quà cho sản phẩm PediaSure</h5>
                                        <p>
                                            Từ lần thứ 2 trở đi, cho mỗi lần mua và quét mã QR của các sản phẩm PediaSure, bạn được nhận ngay 1 lượt tham gia vòng quay may mắn để có cơ hội trúng các phần quà hấp dẫn.
                                        </p>
                                        <img src="../../../assets/images/Aug23-games/pediasure-conffetti.png" alt="conffetti-pediasure" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="similac" role="tabpanel" aria-labelledby="profile-tab"
                        [ngClass]="(showActiveTabSimilac) ? 'tab-pane fade show active' : 'tab-pane fade show '">
                        <div class="subTabsOut">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" (click)="ensureClicked()">Ensure Gold</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" (click)="glucernaClicked()">Glucerna</a>
                                </li>
                            </ul>
                            <div class="tab-content subTabContent" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="innerContent">
                                        <h5>Phần quà cho lần đăng nhập đầu tiên</h5>
                                        <p>
                                            Với lần đăng nhập đầu tiên trên Care & Share Home,  bạn có cơ hội nhận ngay 1 phần quà 
                                            từ Ensure (Số lượng giới hạn mỗi tháng)
                                        </p>
                                        <img src="../../../assets/images/octgames2024/ensurefirst.png" alt="gift-2-copy" />
                                        <h2 class="remainingGift">
                                            {{ensureRewardList}} 
                                            <small>Phần quà còn lại</small>
                                            <hr>
                                        </h2>
                                        <h5>Phần quà cho sản phẩm Ensure</h5>
                                        <p>
                                            Từ lần thứ 2 trở đi, cho mỗi lần mua và quét mã QR của các sản phẩm Ensure, bạn được nhận 
                                            ngay 1 lượt tham gia vòng quay may mắn để có cơ hội trúng các phần quà hấp dẫn.
                                        </p>
                                        <img src="../../../assets/images/nov-games/novensurereward.png" alt="conffetti-ensure" />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="innerContent">
                                        <h5>Phần quà cho lần đăng nhập đầu tiên</h5>
                                        <p>
                                            Với lần đăng nhập đầu tiên trên Care & Share Home,  bạn có cơ hội nhận ngay 1 phần quà 
                                            từ Glucerna (Số lượng giới hạn mỗi tháng)
                                        </p>
                                        <img src="../../../assets/images/Jan24-games/glucernafirstjan24.png" alt="gift-2" />
                                        <h2 class="remainingGift">
                                            {{glucernaRewardList}} 
                                            <small>Phần quà còn lại</small>
                                            <hr>
                                        </h2>
                                        <h5>Phần quà cho sản phẩm Glucerna</h5>
                                        <p>
                                            Từ lần thứ 2 trở đi, cho mỗi lần mua và quét mã QR của các sản phẩm Glucerna, bạn được 
                                            nhận ngay 1 lượt tham gia vòng quay may mắn để có cơ hội trúng các phần quà hấp dẫn.
                                        </p>
                                        <img src="../../../assets/images/Aug23-games/glu.png" alt="conffetti" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  class="reward-screen">
                <p *ngIf="authToken">
                    <a href="https://www.family.abbott/vn-vi/ensure/wolf-winners.html" target="_blank">
                    <span class="custom-link">Danh sách khách hàng trúng thưởng</span>
                    </a>
                </p>
            </div>
        </div>
    </div>
    <div class="qrCodeScan">
        <img class="roundedImage" src="../../../assets/images/qrscan.png" alt="qr scan" />
        <!-- Rewards grow login -->
        <p class="luckyDraw" *ngIf="authToken && prodCategory == 'Grow'">Bạn còn {{remaining_grow_chances}} lượt tham gia vòng quay may mắn với {{prdName}} trong tháng này</p>
        <p class="luckyDraw" *ngIf="authToken && prodCategory == 'Similac'">Bạn còn {{remaining_similac_chances}} lượt tham gia vòng quay may mắn với {{prdName}} trong tháng này</p>
        <p class="luckyDraw" *ngIf="authToken && prodCategory == 'Ensure'">Bạn còn {{remaining_ensure_chances}} lượt tham gia vòng quay may mắn với {{prdName}} trong tháng này</p>
        <p class="luckyDraw" *ngIf="authToken && prodCategory == 'Glucerna'">Bạn còn {{remaining_glucerna_chances}} lượt tham gia vòng quay may mắn với {{prdName}} trong tháng này</p>
        
        
        <div class="text-center">
            <button class="orangeBtn" (click)="openModal(QR2Popup)">{{ 'SCAN_QR_CODE_NOW' | translate
                }}</button><br>
            <!-- <button class="orangeBtn" (click)="qrScan(qrScantemplate)">QR Scan</button> -->
        </div>
        <p *ngIf="!authToken">Bạn có tối đa 5 lần quy đổi lượt quay may mắn mỗi tháng với các sản phẩm từ {{prdName}}, vui lòng kiểm tra kĩ trước khi quét mã</p>
       
        
        <p *ngIf="authToken">Bạn có tối đa 5 lần quy đổi lượt quay may mắn mỗi tháng với các sản phẩm từ {{prdName}}, vui lòng kiểm tra kĩ trước khi quét mã</p>
    </div>
    <div class="traceabilityProducts" *ngIf="!authToken">
        <div class="aboutNutrition">
            <div class="contents">
                <h1>Vững tâm chăm sóc gia đình bạn với các thông tin khoa học về dinh dưỡng</h1>
                <p>Đăng ký thành viên để nhận được nhiều lợi ích hấp dẫn, hỗ trợ hành trình chăm sóc sức khỏe cho bản thân bạn và gia đình. </p>
                <div class="text-center">
                    <button class="orangeBtn" routerLink="/sign-up/1">Đăng ký ngay</button>
                </div>
            </div>
            <img class="aboutNutritionImg" src="../../../assets/images/about_nutrition.png" alt="about_nutrition" />
        </div>
        <div class="productTraceability">
            <img src="../../../assets/images/tracebility_products.png" alt="tracebility products" />
            <h1>{{ 'REWARDS_LOGIN.TRACEABILITY_PRODUCTS' | translate }}</h1>
            <small>{{ 'REWARDS_LOGIN.REPUTABLE_FACTORIES' | translate }}</small>
            <p>{{ 'REWARDS_LOGIN.ABBOTT_NUTRITIONAL_PRODUCTS' | translate }}</p>
            <div class="text-center">
                <button class="orangeBtn" (click)="openQrcodeOnePopup()">{{ 'FIND_OUT_NOW' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-body" [hidden]="showScanner">
        <section class="bonusRedemptionOut">
            <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>

            <h1>{{ 'BONUS_REDEMPTION.BONUS_REDEMPTION_TITLE' | translate }}</h1>
            <div class="row">
                <div class="col-6">
                    <div class="scanDetails">
                        <img src="../../../assets/images/scan_code.png" alt="scan code" />
                        <p>{{ 'BONUS_REDEMPTION.QR_CODE' | translate }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="scanDetails">
                        <img src="../../../assets/images/mobile_scan.png" alt="mobile code" />
                        <p>{{ 'BONUS_REDEMPTION.ASSITIVE_APPLICATIONS' | translate }}</p>
                    </div>
                </div>
            </div>
            <button class="orangeBtn" (click)="onClickScanner()">{{ 'SCAN_QR_CODE_NOW' | translate }}</button>
            <div class="questionOut">
                <h6>{{ 'BONUS_REDEMPTION.QUESTIONS' | translate }}</h6>
                <p>{{ 'BONUS_REDEMPTION.CUSTOMER_SERVICE1' | translate }}<br /> {{ 'BONUS_REDEMPTION.CUSTOMER_SERVICE2'
                    | translate }}</p>
                <a class="telNum" href="tel:19001519">19001519 </a>
            </div>
            <p class="code">COR-C-22-21</p>
        </section>
    </div>
</ng-template>

<ng-template #QR2Popup>
    <div class="modal-body" [hidden]="showScanner">
        <section class="bonusRedemptionOut">
            <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>

            <h1>{{ 'BONUS_REDEMPTION.BONUS_REDEMPTION_TITLE' | translate }}</h1>
            <div class="row">
                <div class="col-6">
                    <div class="scanDetails">
                        <img src="../../../assets/images/scan_code.png" alt="scan code" />
                        <p>{{ 'BONUS_REDEMPTION.QR_CODE_TYPE2' | translate }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="scanDetails">
                        <img src="../../../assets/images/mobile_scan.png" alt="mobile code" />
                        <p>{{ 'BONUS_REDEMPTION.ASSITIVE_APPLICATIONS' | translate }}</p>
                    </div>
                </div>
            </div>
            <button class="orangeBtn" (click)="onClickScanner()">{{ 'SCAN_QR_CODE_NOW' | translate }}</button>
            <div class="questionOut">
                <h6>{{ 'BONUS_REDEMPTION.QUESTIONS' | translate }}</h6>
                <p>{{ 'BONUS_REDEMPTION.CUSTOMER_SERVICE1' | translate }}<br /> {{ 'BONUS_REDEMPTION.CUSTOMER_SERVICE2'
                    | translate }}</p>
                <a class="telNum" href="tel:19001519">19001519 </a>
            </div>
            <p class="code">COR-C-22-21</p>
        </section>
    </div>
</ng-template>

<ng-template #qrScantemplate>
    <div class="modal-body" [hidden]="showScanner">
        <section class="qrCodeScanModal">
            <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="contents">
                <h1>{{ 'QR_CODE_SCAN_POP_UP.TITLE' | translate }}</h1>
                <p *ngIf="categoryCode == 'Grow'">{{ 'QR_CODE_SCAN_POP_UP.DESC1' | translate }} {{myDate | date:'dd/MM/yyyy'}}.</p>
                <p *ngIf="categoryCode == 'Similac'">{{ 'QR_CODE_SCAN_POP_UP.DESC1A' | translate }} {{myDate | date:'dd/MM/yyyy'}}.</p>
                <a href="javascript:;" class="orangeBtn" (click)="growOrSimilac()">{{ 'QR_CODE_SCAN_POP_UP.JOIN_THE_LUCKY_WHEEL' | translate }}</a>
                <p *ngIf="categoryCode == 'Grow'">{{ 'QR_CODE_SCAN_POP_UP.DESC2' | translate }} 3 {{ 'QR_CODE_SCAN_POP_UP.DESC3' | translate }}</p>
                <p *ngIf="categoryCode == 'Similac'">{{ 'QR_CODE_SCAN_POP_UP.DESC2' | translate }} 3 {{ 'QR_CODE_SCAN_POP_UP.DESC3A' | translate }}</p>
                <a class="blueBtn" (click)="onClickScanner()">{{ 'QR_CODE_SCAN_POP_UP.SCAN_QR_CODE' | translate }}</a>
                <p class="code">COR-C-22-21</p>
            </div>
        </section>
    </div>
</ng-template>


<app-dynamic-footer code="COR-C-19-21" [hidden]="showScanner"></app-dynamic-footer>
<app-nav-menu [hidden]="showScanner"></app-nav-menu>

<div bsModal #invalidQrCodeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="invalidQrCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'INVALIDQR_CODE1.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'INVALIDQR_CODE1.TITLE2' | translate }}</h2>
                    <!-- <div>
                            <p class="pt-2 mb-0"> {{ 'INVALIDQR_CODE1.PARA1' | translate }}</p>
                            <a class="links d-block" routerLink=""> {{ 'INVALIDQR_CODE1.PARA2' | translate }}</a>
                            <p class="mb-0 pt-0"> {{ 'INVALIDQR_CODE1.PARA3' | translate }}</p>
                            <a class="pt-3 mb-4 links d-block"> {{ 'INVALIDQR_CODE1.LINK_TEXT' | translate }}</a>
                        </div> -->
                    <div>
                        <p class="pt-2 mb-0">{{ 'INVALIDQR_CODE1.CODE2_PARA1' | translate }} <a class="links"> {{
                                'INVALIDQR_CODE1.CODE2_LINK' | translate }}</a> {{ 'INVALIDQR_CODE1.CODE2_PARA2' |
                            translate }}</p>
                    </div>

                    <div class="blueCard mt-4">
                        <p class="topText"> {{ 'INVALIDQR_CODE1.CARD_TEXT_TOP' | translate }}</p>
                        <p class="colorBlackBold pt-2 mb-0">{{ 'INVALIDQR_CODE1.CARD_PARA' | translate }}</p>
                        <p class="colorBlackBold pt-0">{{ 'INVALIDQR_CODE1.CARD_PARA1' | translate }}</p>
                        <h2 class="blueTitle">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</h2>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #successQrCodeModalOne="bs-modal" class="modal fade" [hidden]="showScanner" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body">
                <section class="bonusRedemptionOut">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="closeQrcodeOnePopup()">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <h1>{{ 'BONUS_REDEMPTION.BONUS_REDEMPTION_TITLE' | translate }}</h1>
                    <div class="row">
                        <div class="col-6">
                            <div class="scanDetails">
                                <img src="../../../assets/images/scan_code.png" alt="scan code" />
                                <p>{{ 'BONUS_REDEMPTION.QR_CODE' | translate }}</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="scanDetails">
                                <img src="../../../assets/images/mobile_scan.png" alt="mobile code" />
                                <p>{{ 'BONUS_REDEMPTION.ASSITIVE_APPLICATIONS' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <button class="orangeBtn" (click)="onClickScanner()">{{ 'SCAN_QR_CODE_NOW' | translate
                        }}</button>
                    <div class="questionOut">
                        <h6>{{ 'BONUS_REDEMPTION.QUESTIONS' | translate }}</h6>
                        <p>{{ 'BONUS_REDEMPTION.CUSTOMER_SERVICE1' | translate }}<br /> {{
                            'BONUS_REDEMPTION.CUSTOMER_SERVICE2'
                            | translate }}</p>
                        <a class="telNum" href="tel:19001519">19001519 </a>
                    </div>
                    <p class="code">COR-C-22-21</p>
                </section>
            </div>
        </div>
    </div>
</div>
<div bsModal #successQrCodeModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="qrCodeScanModal">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="closeSuccessModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
        
                    <div class="contents">
                        <h1>{{ 'QR_CODE_SCAN_POP_UP.TITLE' | translate }}</h1>
                        <p *ngIf="categoryCode == 'Grow'">{{ 'QR_CODE_SCAN_POP_UP.DESC1' | translate }} {{myDate | date:'dd/MM/yyyy'}}.</p>
                        <p *ngIf="categoryCode == 'Similac'">{{ 'QR_CODE_SCAN_POP_UP.DESC1A' | translate }} {{myDate | date:'dd/MM/yyyy'}}.</p>
                        <a href="javascript:;" class="orangeBtn" (click)="growOrSimilac()">{{ 'QR_CODE_SCAN_POP_UP.JOIN_THE_LUCKY_WHEEL' | translate }}</a>
                        <p *ngIf="categoryCode == 'Grow'">{{ 'QR_CODE_SCAN_POP_UP.DESC2' | translate }} {{global_grow_count}} {{ 'QR_CODE_SCAN_POP_UP.DESC3' | translate }}</p>
                        <p *ngIf="categoryCode == 'Similac'">{{ 'QR_CODE_SCAN_POP_UP.DESC2' | translate }} {{global_similac_count}} {{ 'QR_CODE_SCAN_POP_UP.DESC3A' | translate }}</p>
                        <a class="blueBtn" (click)="onClickScanner()">{{ 'QR_CODE_SCAN_POP_UP.SCAN_QR_CODE' | translate }}</a>
                        <p class="code">COR-C-22-21</p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #milkCodeModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="milkCodeHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h2 class="blueTitle pt-3">{{ 'MILK_CODE.TITLE1' | translate }}</h2>
                    <h2 class="blueTitle pt-0">{{ 'MILK_CODE.TITLE2' | translate }}</h2>
                        <p class=" mb-0 pt-0" style="font-size: 4.3vw;"> {{ 'MILK_CODE.PARA' | translate }}<br /> {{ 'MILK_CODE.PARA2' | translate }}<br /> {{ 'MILK_CODE.PARA3' | translate }}<br /> {{ 'MILK_CODE.PARA4' | translate }}</p>
                        <!-- <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA2' | translate }}</p>
                        <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA3' | translate }}</p>
                        <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA4' | translate }}</p> -->
                        <button class="orangeBtn my-4" (click)="milkCodeHide()"> {{ 'MILK_CODE.BTN_TEXT' | translate }}</button>
                        <a class="pt-3 mb-4 links d-block"> {{ 'MILK_CODE.LINK_TEXT' | translate }}</a>
                        <div class="blueCard mt-4">
                            <p class="topText"> {{ 'MILK_CODE.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'MILK_CODE.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'MILK_CODE.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle">{{ 'MILK_CODE.CARD_BOTTOM_NUMBER' | translate }}</h2>
                        </div>
                        <p class="py-4">{{ 'MILK_CODE.CODE' | translate }}</p>
                </section>
            </div>
        </div>
    </div>
</div>
<div bsModal #termsSimilac="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body rewardsLoginOut px-0">
                <section class="similacTermsMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="termsSimilacHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 class="text-uppercase textSecondaryBlue">Thể lệ chương trình</h3>

                    <div class="giftList p-0 mt-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="grow-tab1" 
                                    data-toggle="tab" href="#grow1" role="tab" aria-controls="home"
                                    aria-selected="true">{{ 'REWARDS_LOGIN.ABBOTT_GROW' |
                                    translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="similac-tab1"
                                    data-toggle="tab" href="#similac1" role="tab" aria-controls="profile"
                                    aria-selected="false">{{ 'REWARDS_LOGIN.SIMILAC'
                                    | translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="pediasure-tab1"
                                    data-toggle="tab" href="#pediasure1" role="tab" aria-controls="profile"
                                    aria-selected="false">PediaSure</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="ensure-tab1"
                                    data-toggle="tab" href="#ensure1" role="tab" aria-controls="profile"
                                    aria-selected="false">Ensure Gold</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="glucerna-tab1"
                                    data-toggle="tab" href="#glucerna1" role="tab" aria-controls="profile"
                                    aria-selected="false">Glucerna</a>
                            </li>
                           
                        </ul>
                        <div class="tab-content" id="myTabContent1">
                            <div id="grow1" role="tabpanel" aria-labelledby="grow-tab1" class="tab-pane fade show active" >
                                <div class="innerContent">
                                    <section *ngIf="growtermsContent" [innerHTML]="growtermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="similac1" role="tabpanel" aria-labelledby="similac-tab1" class="tab-pane fade show">
                                <div class="innerContent">
                                    <section *ngIf="termsContent" [innerHTML]="termsContent | safe: 'html'"></section>
                                </div>
                            </div>
                            <div id="pediasure1" role="tabpanel" aria-labelledby="pediasure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="pediasureltermsContent" [innerHTML]="pediasureltermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="ensure1" role="tabpanel" aria-labelledby="ensure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="ensuretermsContent" [innerHTML]="ensuretermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="glucerna1" role="tabpanel" aria-labelledby="glucerna-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="glucernatermsContent" [innerHTML]="glucernatermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>



<div [hidden]="!showScanner">
     <div class="video-row" fxFlex="100">
        <video class="video" #video autoplay playsinline></video>
    </div>
    <div fxFlex="100" style="display:none">
        <canvas class="video" id="canvas" #canvas></canvas>
    </div> 
    <button class="cameraBtn" (click)="closeScanner()">Đóng</button>
</div>