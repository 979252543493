import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from '../services/bugsnag.service';


@Component({
  selector: 'app-signup-success',
  templateUrl: './signup-success.component.html',
  styleUrls: ['./signup-success.component.scss']
})
export class SignupSuccessComponent implements OnInit {

  milkCode: any;
  scancodeData: any;
  content: any = [];
  subCatCode: any;
  prodCategory: any;
  sessionIdValue: any;
  mobile_no: any;
  passwordError: boolean = false;
  latitude: any;
  langitude: any;
  categoryCode: any;
  scanCode: any;
  sttrData: any;
  myDate = new Date();
  gameId: any;
  growNonMilk: any;
  SimilacMom: any;
  SimilacS4: any;
  termsContent: any;
  checkQRCode2: any;
  is_grow: any = false;
  productCode: any;
  scannedDate: any;
  expiryDate: any;
  transNumber: any;
  scandDate: any;

  custom_isGrow: any = false;
  custom_isEnsure: boolean;
  custom_isGlucerna: boolean;
  eligible_nonmilk_grow: any = false;
  eligible_nonmilk_similac: any = false;
  non_redemmed_grow: any = [];
  non_redemmed_similac: any = [];
  redemmed: any = false;
  is_not_expired: any = false;
  non_redeemed_grow: any = [];
  non_redeemed_similac: any = [];
  all_nonmilk_array: any = [];
  nonmilk_grow_array: any = [];
  nonmilk_similac_array: any = [];
  is_milk: boolean = false;
  growtermsContent: any;
  game_is_eligible: boolean = false;
  firstChildName: any;
  Ensure: any;
  Glucerna: any;
  Pediasure: any;
  is_ensure: any;
  is_glucerna: any;
  is_pediasure: any;
  formData: any
  eligible_nonmilk_ensure: any;
  eligible_nonmilk_glucerna: any;
  giftCheck: any;
  smom_NonMilk_product: any;
  spinDisable: boolean = false;
  user_gift: any;

  config = {
    ignoreBackdropClick: true
  };
  @ViewChild('rewardModal', { static: true }) rewardModal: ModalDirective;
  @ViewChild('ouOfGiftModal', { static: true }) ouOfGiftModal: ModalDirective;
  @ViewChild('milkCodeModal', { static: true }) milkCodeModal: ModalDirective;
  @ViewChild('invalidQrCodeModal', { static: true }) invalidQrCodeModal: ModalDirective;
  @ViewChild('termsSimilac', { static: true }) termsSimilac: ModalDirective;
  @ViewChild('tryagainModal', { static: true }) tryagainModal: ModalDirective;
  timestamp: any;
  transactionNumber: any;
  iSprintData: any;
  confidence: any;



  constructor(private modalService: BsModalService, private apiService: ApiService, private router: Router,
    private route: ActivatedRoute, private toastr: ToastrService, private spinner: NgxSpinnerService, private bsService: BugsnagService) {
    this.spinner.hide();
    this.timestamp = Date.now();
    console.log(this.timestamp, 'date now')
    this.myDate.setDate(this.myDate.getDate() + 45);
    window.scrollTo({ top: 0 });
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    console.log(this.iSprintData, 'isprintdata');
    this.giftCheck = sessionStorage.getItem('firsttimegift')
    this.scanCode = sessionStorage.getItem('scanCode');
    this.transactionNumber = sessionStorage.getItem('transaction_number')
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"))
    this.prodCategory = this.iSprintData?.category_code
    this.apiService.sttarterFirsttimeScanGameId().subscribe((res: any) => {
      this.spinner.hide();
      let brandId = res.data.filter(brandid => brandid.gamebrand == 'BrandId');
      this.gameId = brandId[0].gameid;

      let grownonmilk = res.data.filter(grownonmilk => grownonmilk.gamebrand == 'Grow Non-Milk');
      this.growNonMilk = grownonmilk[0].gameid;

      let SimilacMom = res.data.filter(SimilacMom => SimilacMom.gamebrand == 'SimilacMom');
      this.SimilacMom = SimilacMom[0].gameid;

      let SimilacS4 = res.data.filter(SimilacS4 => SimilacS4.gamebrand == 'SimilacS4');
      this.SimilacS4 = SimilacS4[0].gameid;

      let Ensure = res.data.filter(Ensure => Ensure.gamebrand == 'Ensure');
      this.Ensure = Ensure[0].gameid;

      let Glucerna = res.data.filter(Glucerna => Glucerna.gamebrand == 'Glucerna');
      this.Glucerna = Glucerna[0].gameid;

      let Pediasure = res.data.filter(Pediasure => Pediasure.gamebrand == 'Pediasure');
      this.Pediasure = Pediasure[0].gameid;

    }, err => {
      this.spinner.hide();
      this.bsService.notifyError(err);
    })


    this.scanCode = sessionStorage.getItem('scanCode');
    if (this.scanCode != "undefined") {
      // let qrCodeData = JSON.parse(sessionStorage.getItem('productdata'));
      // this.categoryCode = qrCodeData?.product?.categoryCode;
      // this.checkQRCode2 = qrCodeData?.uaidInfo?.type;

      this.categoryCode = this.iSprintData?.category_code;
      this.checkQRCode2 = this.iSprintData?.type

      this.categoryCode == "Similac" ? this.is_grow = false : this.is_grow = true;
      // this.categoryCode == "Ensure" ? this.is_grow = false : this.is_ensure = true;
      // this.categoryCode == "Glucerna" ? this.is_grow = false : this.is_glucerna = true;

      if (this.categoryCode == "Ensure") {
        this.is_grow = 'null'
        this.is_ensure = true;
      } else if (this.categoryCode == "Glucerna") {
        this.is_grow = 'null'
        this.is_glucerna = true;
      }
      else if (this.categoryCode == "PediaSure") {
        this.is_grow = 'null'
        this.is_pediasure = true;
      }

      console.log(this.is_grow, 'this.is grow')
      console.log(this.is_ensure, 'this.is_ensure')
      console.log(this.is_glucerna, 'this.is_glucerna')
      console.log(this.is_pediasure, 'this.is_pediasure')

      // let scannedCode = qrCodeData;
      // this.productCode = scannedCode?.product?.productCode;
      this.productCode = this.iSprintData?.product_code ? this.iSprintData?.product_code : '';

      let today = new Date();
      this.scannedDate = moment(today).format('YYYY-MM-DD');

      let endDate = moment(today).add(30, 'days');
      this.expiryDate = moment(endDate).format('YYYY-MM-DD');

    } else {
      console.log('cleared local storage---')
    }
  }

  ngOnInit(): void {
    // this.openMilkCodeModal();

    this.apiService.childName.subscribe(res => {
      this.spinner.hide();
      this.firstChildName = res;
      console.log(this.firstChildName, 'FirstChildName')
    })
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    
    this.getProductDescCode();
    this.similacTerms();


    this.apiService.SMOMnonmilk.subscribe(res => {
      this.spinner.hide();
      this.smom_NonMilk_product = res;
      console.log(this.smom_NonMilk_product, 'this.smom_NonMilk_product in login')
    })
  }

  addTrans() {
    this.tryagainModal.hide();
    this.apiService.getPosition().then(pos => {
      this.spinner.hide();
      let lat = pos.lat
      let lang = pos.lng
      let confidence = pos.accuracy;
      this.latitude = lat.toString()
      this.langitude = lang.toString()
      this.confidence = confidence?.toString()

      let getMobile = sessionStorage.getItem('mobileNo');
      let getAuthKey = sessionStorage.getItem('authToken')
      let productName = sessionStorage.getItem('productName');
      let SimilacCount = JSON.parse(sessionStorage.getItem('similacMilCount'))
      let GrowCount = JSON.parse(sessionStorage.getItem('growMilkCount'))
      let EnsureCount = JSON.parse(sessionStorage.getItem('ensureMilCount'))
      let GlucernaCount = JSON.parse(sessionStorage.getItem('glucernaMilkCount'))
      let PediasureCount = JSON.parse(sessionStorage.getItem('pediasureMilkCount'))
      if(this.iSprintData.is_isprint){
        if(this.categoryCode == 'Similac' || this.categoryCode == 'Grow'){
          this.formData = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              {
                "name": "is_eligible_for_game",
                "value": true,
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              },
              // {
              //   "name": "child_info",
              //   "value": this.firstChildName
              // }
  
            ]
          }
       
        if(!this.smom_NonMilk_product){
          this.formData['custom_fields'].push({
            "name": "child_info",
            "value": this.firstChildName
          })
        }
  
      } else if(this.categoryCode == 'Ensure'){
        this.formData = {
          "scan_code": this.scanCode,
          "mobile": getMobile,
          "cap_device_id": env.DEVICE_ID,
          "custom_fields": [
            {
              "name": "TransLat",
              "value": this.latitude
            },
            {
              "name": "TransLong",
              "value": this.langitude
            },
            {
              "name": "sfproductcode",
              "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
            },
            {
              "name": "productName",
              "value": productName
            },
            {
              "name": "category_code_trans",
              "value": this.categoryCode ? this.categoryCode : ''
            },
            {
              "name": "is_milk",
              "value": this.is_milk
  
            },
            {
              "name": "is_grow",
              "value": 'null'
  
            },
            {
              "name": "is_ensure",
              "value": this.is_ensure ? this.is_ensure : 'null'
            },
            {
              "name": "itemcode",
              "value": this.subCatCode
            },
            {
              "name": "scanned_date",
              "value": this.scannedDate
            },
            {
              "name": "expiration_date",
              "value": this.expiryDate
            },
            {
              "name": "is_eligible_for_game",
              "value": true,
            },
            {
              "name": "is_redeemed",
              "value": false
            },
            {
              "name": "gift_id",
              "value": 'null'
            },
            {
              "name": "gift_delivery_date",
              "value": 'null'
            },
            // {
            //   "name": "child_info",
            //   "value": this.firstChildName
            // }
  
          ]
        }
    }
    else if(this.categoryCode == 'Glucerna'){
      this.formData = {
        "scan_code": this.scanCode,
        "mobile": getMobile,
        "cap_device_id": env.DEVICE_ID,
        "custom_fields": [
          {
            "name": "TransLat",
            "value": this.latitude
          },
          {
            "name": "TransLong",
            "value": this.langitude
          },
          {
            "name": "sfproductcode",
            "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
          },
          {
            "name": "productName",
            "value": productName
          },
          {
            "name": "category_code_trans",
            "value": this.categoryCode ? this.categoryCode : ''
          },
          {
            "name": "is_milk",
            "value": this.is_milk
  
          },
          {
            "name": "is_grow",
            "value": 'null'
  
          },
          {
            "name": "is_glucerna",
            "value": this.is_glucerna ? this.is_glucerna : 'null'
          },
          {
            "name": "itemcode",
            "value": this.subCatCode
          },
          {
            "name": "scanned_date",
            "value": this.scannedDate
          },
          {
            "name": "expiration_date",
            "value": this.expiryDate
          },
          {
            "name": "is_eligible_for_game",
            "value": true,
          },
          {
            "name": "is_redeemed",
            "value": false
          },
          {
            "name": "gift_id",
            "value": 'null'
          },
          {
            "name": "gift_delivery_date",
            "value": 'null'
          },
          // {
          //   "name": "child_info",
          //   "value": this.firstChildName
          // }
  
        ]
      }
    }
    else if(this.categoryCode == 'PediaSure'){
      this.formData = {
        "scan_code": this.scanCode,
        "mobile": getMobile,
        "cap_device_id": env.DEVICE_ID,
        "custom_fields": [
          {
            "name": "TransLat",
            "value": this.latitude
          },
          {
            "name": "TransLong",
            "value": this.langitude
          },
          {
            "name": "sfproductcode",
            "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
          },
          {
            "name": "productName",
            "value": productName
          },
          {
            "name": "category_code_trans",
            "value": this.categoryCode ? this.categoryCode : ''
          },
          {
            "name": "is_milk",
            "value": this.is_milk
  
          },
          {
            "name": "is_grow",
            "value": 'null'
  
          },
          {
            "name": "is_pediasure",
            "value": this.is_pediasure ? this.is_pediasure : 'null'
          },
          {
            "name": "itemcode",
            "value": this.subCatCode
          },
          {
            "name": "scanned_date",
            "value": this.scannedDate
          },
          {
            "name": "expiration_date",
            "value": this.expiryDate
          },
          {
            "name": "is_eligible_for_game",
            "value": 'true',
          },
          {
            "name": "is_redeemed",
            "value": false
          },
          {
            "name": "gift_id",
            "value": 'null'
          },
          {
            "name": "gift_delivery_date",
            "value": 'null'
          },
          // {
          //   "name": "child_info",
          //   "value": this.firstChildName
          // }
  
        ]
      }
    }
      }else{
        if (this.categoryCode == 'Similac' || this.categoryCode == 'Grow') {
          this.formData = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            // customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": this.is_grow
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_grow == true ? (GrowCount >= 1 ? 'true' : 'false') : (SimilacCount >= 1 ? 'true' : 'false')
                "value": 'true',
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Ensure') {
          this.formData = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            // customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_ensure",
                "value": this.is_ensure ? this.is_ensure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_ensure == true ? (EnsureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true',
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'Glucerna') {
          this.formData = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            // customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_glucerna",
                "value": this.is_glucerna ? this.is_glucerna : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_glucerna == true ? (GlucernaCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true',
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
        else if (this.categoryCode == 'PediaSure') {
          this.formData = {
            "scan_code": this.scanCode,
            "mobile": getMobile,
            "cap_device_id": env.DEVICE_ID,
            latitude: this.latitude,
            longitude: this.langitude,
            confidence: this.confidence,
            product_name: this.iSprintData.product_description,
            // customer_name: this.firstName + this.lastName,
            "custom_fields": [
              {
                "name": "TransLat",
                "value": this.latitude
              },
              {
                "name": "TransLong",
                "value": this.langitude
              },
              {
                "name": "sfproductcode",
                "value":this.iSprintData?.product_code ? this.iSprintData?.product_code : ''
              },
              {
                "name": "productName",
                "value": productName
              },
              {
                "name": "category_code_trans",
                "value": this.categoryCode ? this.categoryCode : ''
              },
              {
                "name": "is_milk",
                "value": this.is_milk
  
              },
              {
                "name": "is_grow",
                "value": 'null'
  
              },
              {
                "name": "is_pediasure",
                "value": this.is_pediasure ? this.is_pediasure : 'null'
  
              },
              {
                "name": "itemcode",
                "value": this.subCatCode
              },
              {
                "name": "scanned_date",
                "value": this.scannedDate
              },
              {
                "name": "expiration_date",
                "value": this.expiryDate
              },
              // {
              //   "name": "is_eligible_for_game",
              //   "value": this.is_grow == true ? this.eligible_nonmilk_grow : this.eligible_nonmilk_similac
              // },
              {
                "name": "is_eligible_for_game",
                // "value": this.is_pediasure == true ? (PediasureCount >= 1 ? 'true' : 'false') : 'null'
                "value": 'true'
              },
              {
                "name": "is_redeemed",
                "value": false
              },
              {
                "name": "gift_id",
                "value": 'null'
              },
              {
                "name": "gift_delivery_date",
                "value": 'null'
              }
  
            ]
          }
        }
      }

      if (this.giftCheck == 'true') {
        // if (this.milkCode == '0') {
          let mobile_no = sessionStorage.getItem('mobileNo');
          let getAuthKey = sessionStorage.getItem('authToken')
          let gettransactionresp
          this.spinner.show();
          this.apiService.getTransactionfull(mobile_no, getAuthKey).pipe(first())
            .subscribe((res: any) => {
              gettransactionresp = res;
              this.spinner.hide();
              let custom_fields: any;
              let value: any;
              let result = res?.customer?.transactions;
              let transcation = result?.transaction
              this.transNumber = transcation[0]?.number;
              let transDate = transcation[0]?.custom_fields?.field[0]?.value
              this.scandDate = moment(transDate, "YYYY-MM-DD").add(45, 'days');
              if (this.milkCode == '1') {
                this.openMilkCodeModal();
              }
              if (this.milkCode == '0') {
                // New Gifts Ensure and Glucerna
                if (this.prodCategory == 'Ensure') {
                  let mobileNo = sessionStorage.getItem('mobileNo');
                  let authToken = sessionStorage.getItem('authToken');
                  let formdata = {
                    brandId: this.gameId,
                    gameId: this.Ensure,
                    mobile: parseInt(mobileNo),
                    transactionNumber: this.transNumber,
                    brandName: "Ensure",
                    countryCode: env.COUNTRY_CODE
                  };
                  this.spinner.show();
                  this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                    this.spinner.hide();
                    if (res?.result?.code == 200) {
                      this.spinDisable = false;
                      console.log(res, 'game api responce')
                      this.sttrData = res?.result?.actual_gift_code;
                      this.openRewardPopup();
                    }

                  }, err => {
                    this.spinDisable = false;
                    this.spinner.hide();
                    if (err?.error?.code == 500) {
                      if (
                        err?.error?.errorcode == 400 ||
                        err?.error?.errorcode == 600 ||
                        err?.error?.errorcode == 700 ||
                        err?.error?.errorcode == 701
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi. Vui lòng thử lại sau'
                        );
                      } else if (
                        err?.error?.errorcode == 1001
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 401 ||
                        err?.error?.errorcode == 801
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vui lòng đăng nhập lại và thử lại.'
                        );
                      } else if (
                        err?.error?.errorcode == 601
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 802 ||
                        err?.error?.errorcode == 803 ||
                        err?.error?.errorcode == 804
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 900
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                        );
                      } else if (
                        err?.error?.errorcode == 5402
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                        );
                      } else if (
                        err?.error?.errorcode == 5500
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                        );
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                    console.log(err, 'error')
                    this.bsService.notifyError(err);
                  })
                }

                // Glucerna =========== 
                else if (this.prodCategory == 'Glucerna') {
                  let mobileNo = sessionStorage.getItem('mobileNo');
                  let authToken = sessionStorage.getItem('authToken');
                  let formdata = {
                    brandId: this.gameId,
                    gameId: this.Glucerna,
                    mobile: parseInt(mobileNo),
                    transactionNumber: this.transNumber,
                    brandName: 'Glucerna',
                    countryCode: env.COUNTRY_CODE,
                  };
                  this.spinner.show();
                  this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                    this.spinner.hide();
                    if (res?.result?.code == 200) {
                      this.spinDisable = false;
                      console.log(res, 'game api responce')
                      this.sttrData = res?.result?.actual_gift_code;
                      this.openRewardPopup();
                    }

                  }, err => {
                    this.spinDisable = false;
                    this.spinner.hide();
                    if (err?.error?.code == 500) {
                      if (
                        err?.error?.errorcode == 400 ||
                        err?.error?.errorcode == 600 ||
                        err?.error?.errorcode == 700 ||
                        err?.error?.errorcode == 701
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi. Vui lòng thử lại sau'
                        );
                      } else if (
                        err?.error?.errorcode == 1001
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 401 ||
                        err?.error?.errorcode == 801
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vui lòng đăng nhập lại và thử lại.'
                        );
                      } else if (
                        err?.error?.errorcode == 601
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 802 ||
                        err?.error?.errorcode == 803 ||
                        err?.error?.errorcode == 804
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 900
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                        );
                      } else if (
                        err?.error?.errorcode == 5402
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                        );
                      } else if (
                        err?.error?.errorcode == 5500
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                        );
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                    console.log(err, 'error')
                    this.bsService.notifyError(err);
                  })



                }

                // Pedaisure ==== 

                else if (this.prodCategory == 'PediaSure') {

                  let mobileNo = sessionStorage.getItem('mobileNo');
                  let authToken = sessionStorage.getItem('authToken');
                  let formdata = {
                    brandId: this.gameId,
                    gameId: this.Pediasure,
                    mobile: parseInt(mobileNo),
                    transactionNumber: this.transNumber,
                    brandName: 'Pediasure',
                    countryCode: env.COUNTRY_CODE,
                  };
                  this.spinner.show();
                  this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                    this.spinner.hide();
                    if (res?.result?.code == 200) {
                      this.spinDisable = false;
                      console.log(res, 'game api responce')
                      this.sttrData = res?.result?.actual_gift_code;
                      this.openRewardPopup();
                    }

                  }, err => {
                    this.spinDisable = false;
                    this.spinner.hide();
                    if (err?.error?.code == 500) {
                      if (
                        err?.error?.errorcode == 400 ||
                        err?.error?.errorcode == 600 ||
                        err?.error?.errorcode == 700 ||
                        err?.error?.errorcode == 701
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi. Vui lòng thử lại sau'
                        );
                      } else if (
                        err?.error?.errorcode == 1001
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 401 ||
                        err?.error?.errorcode == 801
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vui lòng đăng nhập lại và thử lại.'
                        );
                      } else if (
                        err?.error?.errorcode == 601
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 802 ||
                        err?.error?.errorcode == 803 ||
                        err?.error?.errorcode == 804
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 900
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                        );
                      } else if (
                        err?.error?.errorcode == 5402
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                        );
                      } else if (
                        err?.error?.errorcode == 5500
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                        );
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                    console.log(err, 'error')
                    this.bsService.notifyError(err);
                  })

                }


                // Old Gifts Grow and Similac
                else if (this.prodCategory == 'Grow') {
                  let mobileNo = sessionStorage.getItem('mobileNo');
                  let authToken = sessionStorage.getItem('authToken');
                  let formdata = {
                    brandId: this.gameId,
                    gameId: this.growNonMilk,
                    mobile: parseInt(mobileNo),
                    transactionNumber: this.transNumber,
                    brandName: "Grow Non-Milk",
                    countryCode: env.COUNTRY_CODE
                  };
                  this.spinner.show();
                  this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                    this.spinner.hide();
                    if (res?.result?.code == 200) {
                      this.spinDisable = false;
                      console.log(res, 'game api responce')
                      this.sttrData = res?.result?.actual_gift_code;
                      this.openRewardPopup();
                    }

                  }, err => {
                    this.spinDisable = false;
                    this.spinner.hide();
                    if (err?.error?.code == 500) {
                      if (
                        err?.error?.errorcode == 400 ||
                        err?.error?.errorcode == 600 ||
                        err?.error?.errorcode == 700 ||
                        err?.error?.errorcode == 701
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi. Vui lòng thử lại sau'
                        );
                      } else if (
                        err?.error?.errorcode == 1001
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 401 ||
                        err?.error?.errorcode == 801
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vui lòng đăng nhập lại và thử lại.'
                        );
                      } else if (
                        err?.error?.errorcode == 601
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 802 ||
                        err?.error?.errorcode == 803 ||
                        err?.error?.errorcode == 804
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 900
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                        );
                      } else if (
                        err?.error?.errorcode == 5402
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                        );
                      } else if (
                        err?.error?.errorcode == 5500
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                        );
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                    console.log(err, 'error')
                    this.bsService.notifyError(err);
                  })
                }
                else if (this.prodCategory == 'Similac') {
                  if (this.subCatCode == '2010' || this.subCatCode == '2011') {
                    let mobileNo = sessionStorage.getItem('mobileNo');
                    let authToken = sessionStorage.getItem('authToken');
                    let formdata = {
                      brandId: this.gameId,
                      gameId: this.SimilacMom,
                      mobile: parseInt(mobileNo),
                      transactionNumber: this.transNumber,
                      brandName: "Similac Mom",
                      countryCode: env.COUNTRY_CODE
                    };
                    this.spinner.show();
                    this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                      this.spinner.hide();
                      if (res?.result?.code == 200) {
                        this.spinDisable = false;
                        console.log(res, 'game api responce')
                        this.sttrData = res?.result?.actual_gift_code;
                        this.openRewardPopup();
                      }

                    }, err => {
                      this.spinDisable = false;
                      this.spinner.hide();
                      if (err?.error?.code == 500) {
                        if (
                          err?.error?.errorcode == 400 ||
                          err?.error?.errorcode == 600 ||
                          err?.error?.errorcode == 700 ||
                          err?.error?.errorcode == 701
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Đã xảy ra lỗi. Vui lòng thử lại sau'
                          );
                        } else if (
                          err?.error?.errorcode == 1001
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                          );
                        } else if (
                          err?.error?.errorcode == 401 ||
                          err?.error?.errorcode == 801
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Vui lòng đăng nhập lại và thử lại.'
                          );
                        } else if (
                          err?.error?.errorcode == 601
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                          );
                        } else if (
                          err?.error?.errorcode == 802 ||
                          err?.error?.errorcode == 803 ||
                          err?.error?.errorcode == 804
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                          );
                        } else if (
                          err?.error?.errorcode == 900
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                          );
                        } else if (
                          err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                          );
                        } else if (
                          err?.error?.errorcode == 5402
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                          );
                        } else if (
                          err?.error?.errorcode == 5500
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                          );
                        } else {
                          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                        }
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                      console.log(err, 'error')
                      this.bsService.notifyError(err);
                    })
                  }
                  else {
                    let mobileNo = sessionStorage.getItem('mobileNo');
                    let authToken = sessionStorage.getItem('authToken');
                    let formdata = {
                      brandId: this.gameId,
                      gameId: this.SimilacS4,
                      mobile: parseInt(mobileNo),
                      transactionNumber: this.transNumber,
                      brandName: "Similac S4",
                      countryCode: env.COUNTRY_CODE
                    };
                    this.spinner.show();
                    this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                      this.spinner.hide();
                      if (res?.result?.code == 200) {
                        this.spinDisable = false;
                        console.log(res, 'game api responce')
                        this.sttrData = res?.result?.actual_gift_code;
                        this.openRewardPopup();
                      }

                    }, err => {
                      this.spinDisable = false;
                      this.spinner.hide();
                      if (err?.error?.code == 500) {
                        if (
                          err?.error?.errorcode == 400 ||
                          err?.error?.errorcode == 600 ||
                          err?.error?.errorcode == 700 ||
                          err?.error?.errorcode == 701
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Đã xảy ra lỗi. Vui lòng thử lại sau'
                          );
                        } else if (
                          err?.error?.errorcode == 1001
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                          );
                        } else if (
                          err?.error?.errorcode == 401 ||
                          err?.error?.errorcode == 801
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Vui lòng đăng nhập lại và thử lại.'
                          );
                        } else if (
                          err?.error?.errorcode == 601
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                          );
                        } else if (
                          err?.error?.errorcode == 802 ||
                          err?.error?.errorcode == 803 ||
                          err?.error?.errorcode == 804
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                          );
                        } else if (
                          err?.error?.errorcode == 900
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                          );
                        } else if (
                          err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                          );
                        } else if (
                          err?.error?.errorcode == 5402
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                          );
                        } else if (
                          err?.error?.errorcode == 5500
                        ) {
                          this.spinner.hide();
                          this.apiService.showToaster(
                            'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                          );
                        } else {
                          this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                        }
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                      console.log(err, 'error')
                      this.bsService.notifyError(err);
                    })
                  }
                }
              }
        // }

      }, err => {
        this.bsService.notifyError(err);
        this.spinner.hide();
      })
      } else {
        this.spinner.show();
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(this.formData)
        }

        let addtransresp;

        this.apiService.postGpassEarnPoints(this.formData, getAuthKey).pipe(first())
          .subscribe(res => {
            this.spinner.hide();
            addtransresp = res;
            this.spinner.hide();
            //getfullTransactions() {
              let mobile_no = sessionStorage.getItem('mobileNo');
              let getAuthKey = sessionStorage.getItem('authToken')
              let gettransactionresp
              this.spinner.show();
              this.apiService.getTransactionfull(mobile_no, getAuthKey).pipe(first())
                .subscribe((res: any) => {
                  gettransactionresp = res;
                  this.spinner.hide();
                  let custom_fields: any;
                  let value: any;
                  let result = res?.customer?.transactions;
                  let transcation = result?.transaction
                  this.transNumber = transcation[0]?.number;
                  let transDate = transcation[0]?.custom_fields?.field[0]?.value
                  this.scandDate = moment(transDate, "YYYY-MM-DD").add(45, 'days');
               
            //}
            // console.log(this.scancodeData?.uaidInfo?.type, 'this.scancodeData.uaidInfo.type == 2')

            if (this.milkCode == '1') {
              this.openMilkCodeModal();
            }
            if (this.milkCode == '0') {

              // New Gifts Ensure and Glucerna
              if (this.prodCategory == 'Ensure') {
                let mobileNo = sessionStorage.getItem('mobileNo');
                let authToken = sessionStorage.getItem('authToken');
                let formdata = {
                  brandId: this.gameId,
                  gameId: this.Ensure,
                  mobile: parseInt(mobileNo),
                  transactionNumber: this.transNumber,
                  brandName: "Ensure",
                  countryCode: env.COUNTRY_CODE
                };
                this.spinner.show();
                this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                  this.spinner.hide();
                  if (res?.result?.code == 200) {
                    this.spinDisable = false;
                    console.log(res, 'game api responce')
                    this.sttrData = res?.result?.actual_gift_code;
                    this.openRewardPopup();
                  }

                }, err => {
                  this.spinDisable = false;
                  this.spinner.hide();
                  if (err?.error?.code == 500) {
                    if (
                      err?.error?.errorcode == 400 ||
                      err?.error?.errorcode == 600 ||
                      err?.error?.errorcode == 700 ||
                      err?.error?.errorcode == 701
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi. Vui lòng thử lại sau'
                      );
                    } else if (
                      err?.error?.errorcode == 1001
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 401 ||
                      err?.error?.errorcode == 801
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vui lòng đăng nhập lại và thử lại.'
                      );
                    } else if (
                      err?.error?.errorcode == 601
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 802 ||
                      err?.error?.errorcode == 803 ||
                      err?.error?.errorcode == 804
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 900
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                      );
                    } else if (
                      err?.error?.errorcode == 5402
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                      );
                    } else if (
                      err?.error?.errorcode == 5500
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                      );
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                  } else {
                    this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                  }
                  console.log(err, 'error')
                  this.bsService.notifyError(err);
                })
              }

              else if (this.prodCategory == 'Glucerna') {
                let mobileNo = sessionStorage.getItem('mobileNo');
                let authToken = sessionStorage.getItem('authToken');
                let formdata = {
                  brandId: this.gameId,
                  gameId: this.Glucerna,
                  mobile: parseInt(mobileNo),
                  transactionNumber: this.transNumber,
                  brandName: 'Glucerna',
                  countryCode: env.COUNTRY_CODE,
                };
                this.spinner.show();
                this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                  this.spinner.hide();
                  if (res?.result?.code == 200) {
                    this.spinDisable = false;
                    console.log(res, 'game api responce')
                    this.sttrData = res?.result?.actual_gift_code;
                    this.openRewardPopup();
                  }

                }, err => {
                  this.spinDisable = false;
                  this.spinner.hide();
                  if (err?.error?.code == 500) {
                    if (
                      err?.error?.errorcode == 400 ||
                      err?.error?.errorcode == 600 ||
                      err?.error?.errorcode == 700 ||
                      err?.error?.errorcode == 701
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi. Vui lòng thử lại sau'
                      );
                    } else if (
                      err?.error?.errorcode == 1001
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 401 ||
                      err?.error?.errorcode == 801
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vui lòng đăng nhập lại và thử lại.'
                      );
                    } else if (
                      err?.error?.errorcode == 601
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 802 ||
                      err?.error?.errorcode == 803 ||
                      err?.error?.errorcode == 804
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 900
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                      );
                    } else if (
                      err?.error?.errorcode == 5402
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                      );
                    } else if (
                      err?.error?.errorcode == 5500
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                      );
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                  } else {
                    this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                  }
                  console.log(err, 'error')
                  this.bsService.notifyError(err);
                })
              }

              else if (this.prodCategory == 'PediaSure') {

                let mobileNo = sessionStorage.getItem('mobileNo');
                let authToken = sessionStorage.getItem('authToken');
                let formdata = {
                  brandId: this.gameId,
                  gameId: this.Pediasure,
                  mobile: parseInt(mobileNo),
                  transactionNumber: this.transNumber,
                  brandName: 'Pediasure',
                  countryCode: env.COUNTRY_CODE,
                };
                this.spinner.show();
                this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                  this.spinner.hide();
                  if (res?.result?.code == 200) {
                    this.spinDisable = false;
                    console.log(res, 'game api responce')
                    this.sttrData = res?.result?.actual_gift_code;
                    this.openRewardPopup();
                  }

                }, err => {
                  this.spinDisable = false;
                  this.spinner.hide();
                  if (err?.error?.code == 500) {
                    if (
                      err?.error?.errorcode == 400 ||
                      err?.error?.errorcode == 600 ||
                      err?.error?.errorcode == 700 ||
                      err?.error?.errorcode == 701
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi. Vui lòng thử lại sau'
                      );
                    } else if (
                      err?.error?.errorcode == 1001
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 401 ||
                      err?.error?.errorcode == 801
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vui lòng đăng nhập lại và thử lại.'
                      );
                    } else if (
                      err?.error?.errorcode == 601
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 802 ||
                      err?.error?.errorcode == 803 ||
                      err?.error?.errorcode == 804
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 900
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                      );
                    } else if (
                      err?.error?.errorcode == 5402
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                      );
                    } else if (
                      err?.error?.errorcode == 5500
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                      );
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                  } else {
                    this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                  }
                  console.log(err, 'error')
                  this.bsService.notifyError(err);
                })

              }


              // Old Gifts Grow and Similac
              else if (this.prodCategory == 'Grow') {
                let mobileNo = sessionStorage.getItem('mobileNo');
                let authToken = sessionStorage.getItem('authToken');
                let formdata = {
                  brandId: this.gameId,
                  gameId: this.growNonMilk,
                  mobile: parseInt(mobileNo),
                  transactionNumber: this.transNumber,
                  brandName: "Grow Non-Milk",
                  countryCode: env.COUNTRY_CODE
                };
                this.spinner.show();
                this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                  this.spinner.hide();
                  if (res?.result?.code == 200) {
                    this.spinDisable = false;
                    console.log(res, 'game api responce')
                    this.sttrData = res?.result?.actual_gift_code;
                    this.openRewardPopup();
                  }

                }, err => {
                  this.spinDisable = false;
                  this.spinner.hide();
                  if (err?.error?.code == 500) {
                    if (
                      err?.error?.errorcode == 400 ||
                      err?.error?.errorcode == 600 ||
                      err?.error?.errorcode == 700 ||
                      err?.error?.errorcode == 701
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi. Vui lòng thử lại sau'
                      );
                    } else if (
                      err?.error?.errorcode == 1001
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 401 ||
                      err?.error?.errorcode == 801
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vui lòng đăng nhập lại và thử lại.'
                      );
                    } else if (
                      err?.error?.errorcode == 601
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                      );
                    } else if (
                      err?.error?.errorcode == 802 ||
                      err?.error?.errorcode == 803 ||
                      err?.error?.errorcode == 804
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 900
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                      );
                    } else if (
                      err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                      );
                    } else if (
                      err?.error?.errorcode == 5402
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                      );
                    } else if (
                      err?.error?.errorcode == 5500
                    ) {
                      this.spinner.hide();
                      this.apiService.showToaster(
                        'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                      );
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                  } else {
                    this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                  }
                  console.log(err, 'error')
                  this.bsService.notifyError(err);
                })



              }
              else if (this.prodCategory == 'Similac') {
                if (this.subCatCode == '2010' || this.subCatCode == '2011') {
                  let mobileNo = sessionStorage.getItem('mobileNo');
                  let authToken = sessionStorage.getItem('authToken');
                  let formdata = {
                    brandId: this.gameId,
                    gameId: this.SimilacMom,
                    mobile: parseInt(mobileNo),
                    transactionNumber: this.transNumber,
                    brandName: "Similac Mom",
                    countryCode: env.COUNTRY_CODE
                  };
                  this.spinner.show();
                  this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                    this.spinner.hide();
                    if (res?.result?.code == 200) {
                      this.spinDisable = false;
                      console.log(res, 'game api responce')
                      this.sttrData = res?.result?.actual_gift_code;
                      this.openRewardPopup();
                    }

                  }, err => {
                    this.spinDisable = false;
                    this.spinner.hide();
                    if (err?.error?.code == 500) {
                      if (
                        err?.error?.errorcode == 400 ||
                        err?.error?.errorcode == 600 ||
                        err?.error?.errorcode == 700 ||
                        err?.error?.errorcode == 701
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi. Vui lòng thử lại sau'
                        );
                      } else if (
                        err?.error?.errorcode == 1001
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 401 ||
                        err?.error?.errorcode == 801
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vui lòng đăng nhập lại và thử lại.'
                        );
                      } else if (
                        err?.error?.errorcode == 601
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 802 ||
                        err?.error?.errorcode == 803 ||
                        err?.error?.errorcode == 804
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 900
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                        );
                      } else if (
                        err?.error?.errorcode == 5402
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                        );
                      } else if (
                        err?.error?.errorcode == 5500
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                        );
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                    console.log(err, 'error')
                    this.bsService.notifyError(err);
                  })

                } else {
                  let mobileNo = sessionStorage.getItem('mobileNo');
                  let authToken = sessionStorage.getItem('authToken');
                  let formdata = {
                    brandId: this.gameId,
                    gameId: this.SimilacS4,
                    mobile: parseInt(mobileNo),
                    transactionNumber: this.transNumber,
                    brandName: "Similac S4",
                    countryCode: env.COUNTRY_CODE
                  };
                  this.spinner.show();
                  this.apiService.newGameAllocation(formdata, mobileNo, authToken).subscribe((res: any) => {
                    this.spinner.hide();
                    if (res?.result?.code == 200) {
                      this.spinDisable = false;
                      console.log(res, 'game api responce')
                      this.sttrData = res?.result?.actual_gift_code;
                      this.openRewardPopup();
                    }

                  }, err => {
                    this.spinDisable = false;
                    this.spinner.hide();
                    if (err?.error?.code == 500) {
                      if (
                        err?.error?.errorcode == 400 ||
                        err?.error?.errorcode == 600 ||
                        err?.error?.errorcode == 700 ||
                        err?.error?.errorcode == 701
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi. Vui lòng thử lại sau'
                        );
                      } else if (
                        err?.error?.errorcode == 1001
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 401 ||
                        err?.error?.errorcode == 801
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vui lòng đăng nhập lại và thử lại.'
                        );
                      } else if (
                        err?.error?.errorcode == 601
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
                        );
                      } else if (
                        err?.error?.errorcode == 802 ||
                        err?.error?.errorcode == 803 ||
                        err?.error?.errorcode == 804
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 900
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
                        );
                      } else if (
                        err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
                        );
                      } else if (
                        err?.error?.errorcode == 5402
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
                        );
                      } else if (
                        err?.error?.errorcode == 5500
                      ) {
                        this.spinner.hide();
                        this.apiService.showToaster(
                          'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
                        );
                      } else {
                        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                      }
                    } else {
                      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
                    }
                    console.log(err, 'error')
                    this.bsService.notifyError(err);
                  })
                }
              }
            }
            // console.log(res, 'transaction resp');
            // this.router.navigate(['/temp-profile'])
            }, err => {
              this.bsService.notifyError(err);
              this.spinner.hide();
            })
          }, err => {
            this.spinner.hide();
            if (err.error.code == 400) {
              console.log('duplicate code')
              //this.opentryagainMdl();
              this.openinvalidQrCodeModal()
            } else {
              console.log('try again')
              this.opentryagainMdl();
            }
            this.bsService.notifyError(err, this.formData);
            this.spinner.hide();


            console.log(err, '=== get customer error =======')
          })
      }

    })
  }

  openRewardPopup() {
    this.rewardModal.show();
  }
  rewardModalHide() {
    this.rewardModal.hide();
    setTimeout(() => {
      sessionStorage.removeItem('firsttimegift')
      this.router.navigate(['user-profile']);
    }, 3000);
  }

  outOfGiftModal() {
    this.ouOfGiftModal.show();
  }
  ouOfGiftHide() {
    this.ouOfGiftModal.hide();
    setTimeout(() => {
      sessionStorage.removeItem('firsttimegift')
      this.router.navigate(['user-profile']);
    }, 3000);
  }

  openMilkCodeModal() {
    this.milkCodeModal.show()
  }
  // milkCodeHide() {
  //   this.milkCodeModal.hide();
  //   setTimeout(() => {
  //     sessionStorage.removeItem('firsttimegift')
  //     this.router.navigate(['/library']);
  //   }, 3000);
  // }
  milkCodeHide() {
    this.milkCodeModal.hide();
    this.is_grow ? sessionStorage.setItem('activeGrow', 'activeGrow') : sessionStorage.setItem('activeSimilac', 'activeSimilac');
    if (this.is_ensure) {
      sessionStorage.setItem('activeEnsure', 'activeEnsure')
    } else if (this.is_glucerna) {
      sessionStorage.setItem('activeGlucerna', 'activeGlucerna')
    } else if (this.is_pediasure) {
      sessionStorage.setItem('activePediasure', 'activePediasure')
    }
    this.router.navigate(['library']);
    // this.router.navigate(['user-profile']);
  }

  getProductDescCode() {
    this.subCatCode = this.iSprintData?.sub_category_code ? this.iSprintData?.sub_category_code : "";
    this.milkCode = this.iSprintData?.is_milk_code;
    console.log(this.subCatCode, this.milkCode)
    // this.content = this.scancodeData?.product?.txt;
    // if (this.content) {
    //   this.content.forEach((item: any) => {
    //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.subCatCode = parsedPrimaryValue.value;
    //     }
    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;
    //       this.milkCode == 0 ? this.is_milk = false : this.is_milk = true;
    //     }
    //   });
    // }
    this.getTransactionHistory();
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  openinvalidQrCodeModal() {
    this.invalidQrCodeModal.show()
  }
  invalidQrCodeHide() {
    sessionStorage.removeItem('firsttimegift')
    this.router.navigate(['/user-profile'])
    this.invalidQrCodeModal.hide()
  }

  getTransactionHistory() {
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')
    let gettransactionresp
    this.apiService.getTransactionfull(mobile_no, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        gettransactionresp = res
        let custom_fields: any;
        let value: any;
        let result = res?.customer?.transactions;
        let transcation = result?.transaction
        this.transNumber = transcation[0]?.number;
        let transDate = transcation[0]?.custom_fields?.field[0]?.value
        this.scandDate =  moment(transDate, "YYYY-MM-DD").add(45, 'days');
        if (this.iSprintData?.type == 2) {
          this.addTrans();
        }
        else if (this.iSprintData?.type == 1) {
          let goToLibrary = sessionStorage.getItem('enableLibrary')
          if (goToLibrary == 'library') {
            setTimeout(() => {
              sessionStorage.removeItem('firsttimegift')
              this.router.navigate(['library']);
            }, 5000);
          } else {
            setTimeout(() => {
              sessionStorage.removeItem('firsttimegift')
              this.router.navigate(['user-profile']);
            }, 5000);
          }
        }
        else {
          let goToLibrary = sessionStorage.getItem('enableLibrary')
          if (goToLibrary == 'library') {
            setTimeout(() => {
              sessionStorage.removeItem('firsttimegift')
              this.router.navigate(['library']);
            }, 5000);
          } else {
            setTimeout(() => {
              sessionStorage.removeItem('firsttimegift')
              this.router.navigate(['user-profile']);
            }, 5000);
          }
        }
        // if(transcation){
        //   custom_fields = transcation.map(val =>{
        //     value = val.custom_fields['field']
        //     value.push({name: 'transaction_id', value: val.number})
        //     return val.custom_fields['field']
        //   });
        // }
        // if(custom_fields){
        //   this.getTransactionCount(custom_fields);
        // }
      }, err => {
        this.bsService.notifyError(err);
        this.spinner.hide();
      })
  }




  // getTransactionCount(custom_fields1){
  //   let  non_redeemed_grow = [];
  //   let  non_redeemed_similac = [];
  //   let nonmilk_similac_array = [];
  //   let nonmilk_grow_array = [];
  //   let all_nonmilk_array = [];

  //   let  non_redeemed_ensure = [];
  //   let  non_redeemed_glucerna = [];
  //   let nonmilk_ensure_array = [];
  //   let nonmilk_glucerna_array = [];


  //   console.log(this.is_grow, 'is_grow')

  //   custom_fields1.filter(custom_fields=>{

  //     let a = custom_fields.findIndex(val=>val.name=='is_grow');
  //     let b = custom_fields.findIndex(val=>val.name=='is_milk');   
  //     if(a > b){
  //       let temp = custom_fields[a];
  //       custom_fields[a] = custom_fields[b];
  //       custom_fields[b] = temp;
  //     }
  //     let c = custom_fields.findIndex(val=>val.name=='is_redeemed');
  //     let d = custom_fields.findIndex(val=>val.name=='is_milk');
  //     if(c > d){
  //       let temp = custom_fields[c];
  //       custom_fields[c] = custom_fields[d];
  //       custom_fields[d] = temp;
  //     }

  //     custom_fields.filter(val =>{

  //       if(val.name == 'is_grow'){
  //         val.value == "true" ? this.custom_isGrow = true : this.custom_isGrow = false;
  //       }

  //       if(val.name == 'is_ensure'){
  //         val.value == "true" ? this.custom_isEnsure = true : this.custom_isGrow = false;
  //       }

  //       if(val.name == 'is_glucerna'){
  //         val.value == "true" ? this.custom_isGlucerna = true : this.custom_isGrow = false;
  //       }

  //       if(val.name == "expiration_date"){
  //         this.scannedDate < val.value ? this.is_not_expired = true : this.is_not_expired= false;
  //       }

  //       if(val.name == "is_eligible_for_game"){
  //         val.value == "true" ? this.game_is_eligible = true : this.game_is_eligible = false;
  //       }

  //       if(val.name == 'is_redeemed'){
  //         val.value == "true" ? this.redemmed = true : this.redemmed = false;
  //       }

  //       if(val.name == 'is_milk' && val.value == "false"){
  //         all_nonmilk_array.push(custom_fields);
  //         if(this.custom_isEnsure){
  //           nonmilk_ensure_array.push(custom_fields);    
  //         }
  //         else if(this.custom_isGlucerna){
  //           nonmilk_glucerna_array.push(custom_fields);    
  //         }
  //         else if(this.custom_isGrow){
  //           nonmilk_grow_array.push(custom_fields);    
  //         }
  //         else{
  //           nonmilk_similac_array.push(custom_fields);
  //         }
  //         if(this.custom_isEnsure && this.is_not_expired && this.redemmed == false && this.game_is_eligible){
  //           non_redeemed_ensure.push(custom_fields)
  //         }
  //         if(this.custom_isGlucerna && this.is_not_expired && this.redemmed == false && this.game_is_eligible){
  //           non_redeemed_glucerna.push(custom_fields)
  //         }
  //         if(this.custom_isGrow && this.is_not_expired && this.redemmed == false && this.game_is_eligible){
  //           non_redeemed_grow.push(custom_fields)
  //         }
  //         if(this.custom_isGrow == false && this.is_not_expired && this.redemmed == false && this.game_is_eligible){
  //           non_redeemed_similac.push(custom_fields);
  //         }
  //       }

  //     })

  //     sessionStorage.setItem('non_redeemed_grow', JSON.stringify(non_redeemed_grow));
  //     sessionStorage.setItem('non_redeemed_similac', JSON.stringify(non_redeemed_similac));

  //     sessionStorage.setItem('non_redeemed_ensure', JSON.stringify(non_redeemed_ensure));
  //     sessionStorage.setItem('non_redeemed_glucerna', JSON.stringify(non_redeemed_glucerna));

  //     let grow_nonmilk_count = nonmilk_grow_array.length; // gives final count non-milk grow
  //     let similac_nonmilk_count = nonmilk_similac_array.length // gives final count non-milk similac

  //     let ensure_nonmilk_count = nonmilk_ensure_array.length; // gives final count non-milk grow
  //     let glucerna_nonmilk_count = nonmilk_glucerna_array.length // gives final count non-milk similac

  //     sessionStorage.setItem('similacMilCount', JSON.stringify(similac_nonmilk_count));
  //     sessionStorage.setItem('growMilkCount', JSON.stringify(grow_nonmilk_count));

  //     sessionStorage.setItem('ensureMilCount', JSON.stringify(ensure_nonmilk_count));
  //     sessionStorage.setItem('glucernaMilkCount', JSON.stringify(glucerna_nonmilk_count));

  //     let remain_nonmilk_grow_count = 5 - grow_nonmilk_count;
  //     let remain_nonmilk_similac_count = 5 - similac_nonmilk_count;

  //     let remain_nonmilk_ensure_count = 5 - ensure_nonmilk_count;
  //     let remain_nonmilk_glucerna_count = 5 - glucerna_nonmilk_count;

  //     // this.global_grow_count = remain_nonmilk_grow_count
  //     // this.global_similac_count = remain_nonmilk_similac_count

  //     sessionStorage.setItem('grow_nonmilk_remaining_count', remain_nonmilk_grow_count.toString());
  //     sessionStorage.setItem('similac_nonmilk_remaining_count', remain_nonmilk_similac_count.toString());

  //     sessionStorage.setItem('ennsure_nonmilk_remaining_count', remain_nonmilk_ensure_count.toString());
  //     sessionStorage.setItem('glucerna_nonmilk_remaining_count', remain_nonmilk_glucerna_count.toString());

  //     grow_nonmilk_count <= 5 ? this.eligible_nonmilk_grow = true : this.eligible_nonmilk_grow = false;
  //     similac_nonmilk_count <= 5 ? this.eligible_nonmilk_similac = true : this.eligible_nonmilk_similac = false;

  //     ensure_nonmilk_count <= 5 ? this.eligible_nonmilk_ensure = true : this.eligible_nonmilk_ensure = false;
  //     glucerna_nonmilk_count <= 5 ? this.eligible_nonmilk_glucerna = true : this.eligible_nonmilk_glucerna = false;

  //   })   

  // }

  opensimilacTerms() {
    this.termsSimilac.show()
  }
  termsSimilacHide() {
    this.termsSimilac.hide()
  }
  openSimilacTermsandConditions() {
    this.opensimilacTerms();
  }
  opentryagainMdl() {
    this.tryagainModal.show();
  }
  ensuretermsContent: any;
  glucernatermsContent: any;
  pediasureltermsContent: any;
  similacTerms() {
    this.apiService.similacTermsandConditions().subscribe((res: any) => {
      this.spinner.hide();
      res.data.forEach(element => {
        if (element.contentkey == 'Terms_and_conditions_Similac') {
          this.termsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Grow') {
          this.growtermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Gold') {
          this.ensuretermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Glucerna') {
          this.glucernatermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_PediaSure') {
          this.pediasureltermsContent = element.content;
        }

      })
    }, err => {
      this.bsService.notifyError(err);
      this.spinner.hide();
    })
  }

  ngOnDestroy() {
    sessionStorage.removeItem('firsttimegift')
    console.log('signup-successdestroyed')
  }

}
