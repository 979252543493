import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import {environment as env} from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  ForgotMobileForm: FormGroup;
  submittedMobileForm = false;
  ForgotOtpForm: FormGroup;
  submittedOtpForm = false;
  passwordForm: FormGroup;
  submittedPasswordForm = false;
  showMobileForm = true;
  showOtpForm = false;
  showPasswordForm = false;
  showSuccess = false;
  mobile_no: any;
  sessionId: any;
  otpError: boolean = false;
  addMobNum:any;
  show:any;
  show1:any;
  showResendOtp: boolean = false;
  timeLeft: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: true,
    inputStyles: {
      'width': '50px',
      'height': '50px',
    },
    inputClass: true
  };
  hiddenBtn: boolean = false;
  constructor(private formBuilder: FormBuilder, private apiService: ApiService,
    private toastr: ToastrService, private spinner: NgxSpinnerService, private router: Router, private bsService: BugsnagService) {  
    window.scrollTo({top: 0}); 
    this.addMobNum = sessionStorage.getItem('addMobNum')
  }

  // Validators.pattern("^((\\+63-?)|0)?[0-9]{8,10}$"
  ngOnInit(): void {
    this.mobile_no = sessionStorage.getItem('mobileNo');
    this.ForgotMobileForm = this.formBuilder.group({
      phoneNumber: [this.addMobNum, [Validators.required]]
    });

    this.ForgotOtpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    })


    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required,Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$')]]
    },{
      validator: MustMatch('password', 'confirmPassword')
    })

    // this.ForgotMobileForm.controls["phoneNumber"].disable();

  }


  get mobileFormVal() {
    return this.ForgotMobileForm.controls;
  }

  onMobileFormSubmit() {
    this.submittedMobileForm = true;
    if (this.ForgotMobileForm.valid) {
     
      let formData = {
        "identifierType":"MOBILE",
        "identifierValue":this.mobile_no,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
      }
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(formData)
      }
      this.spinner.show();
      let tokengenresp
      this.apiService.tokenGenerate(formData).pipe(first())  //token generate api
      .subscribe(res=>{
        tokengenresp = res;
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(res)
        }
        this.spinner.hide();
        if(res['status'].code == 200){
          this.sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));

                this.showMobileForm = false;
                this.showPasswordForm = true;

        }
        else{   // token generate else
          //this.toastr.info(res['status'].message) 
          console.log(res['status'].message) 
        }
      },
      err=>{  // token generate error
        this.spinner.hide();
        this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
        console.log(err, 'login error')
        this.bsService.notifyError(err, formData);
        console.log(err)
      })

    }
    if (this.ForgotMobileForm.invalid) {
      return;
    }
  }

  get otpFormVal() {
    return this.ForgotOtpForm.controls;
  }

  onOtpFormSubmit() {
    this.submittedOtpForm = true;
  
    if (this.ForgotOtpForm.valid) {
        // this.showOtpForm = false;
        // this.showPasswordForm = true;

       let otpSubmitData = {
          "identifierType":"MOBILE",
          "identifierValue":this.mobile_no,
          "deviceId": env.DEVICE_ID,
          "brand" : env.BRAND,
          "sessionId": this.sessionId,
          "otp":this.ForgotOtpForm.value.otp
        }
        this.spinner.show();
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(otpSubmitData)
        }
        let otpsubmit;
        this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe(res=>{
          otpsubmit = res;
          this.spinner.show();
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
          if(res['status'].code == 200){
            sessionStorage.setItem('authToken', res['auth'].token)
            sessionStorage.setItem('authKey', res['auth'].key)
            // let getAuthKey = sessionStorage.getItem('authToken')
            // let getAuthToken = sessionStorage.getItem('authKey')
            this.showOtpForm = false;
            this.showSuccess = true;
            }
              else if(res['status'].code == 1506){
                this.otpError = true;
              }
              else{
                //this.toastr.info(res['status'].message);
                console.log(res['status'].message)
              }
            },
            err=>{
              console.log(err)
              this.spinner.hide();
              this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
              console.log(err, 'login error')
              this.bsService.notifyError(err, otpSubmitData);
            })  

    }
    if (this.ForgotOtpForm.invalid) {
      return;
    }
  }

  get passwordFormVal() {
    return this.passwordForm.controls;
  }


  onPasswordFormSubmit() {
    this.submittedPasswordForm = true;
    this.hiddenBtn = true;
    if (this.passwordForm.valid) {
      // this.showPasswordForm = false;
      // this.showSuccess = true;
      let authToken = sessionStorage.getItem('authToken')
      let userData = this.passwordForm.value
    //----------forgot password---------
          let formData = {
            "identifierType":"MOBILE",
            "identifierValue":this.mobile_no,
            "deviceId": env.DEVICE_ID,
            "brand": env.BRAND,
            "sessionId": this.sessionId,
            "password": userData.password,
            "confirmPassword": userData.confirmPassword
          }
          this.spinner.show();
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(formData)
          }
          let forgotpass;
          this.apiService.forgotPassword(formData).pipe(first())  // forgot password api
          .subscribe(res =>{
            this.hiddenBtn = false;
            forgotpass = res;
            this.spinner.show();
            let myuuid = uuidv4();
            let loggerFormData = {
              "unique_id": myuuid,
              "message": JSON.stringify(res)
            }
            if(res['status'].code == 200){


         //----otp generate------
        let otpGenerateData = {
          "identifierType":"MOBILE",
            "identifierValue":this.mobile_no,
            "deviceId": env.DEVICE_ID,
            "brand" : env.BRAND,
            "sessionId": this.sessionId
        }     
        this.spinner.show();
        let myuuid = uuidv4();
        let loggerFormData = {
          "unique_id": myuuid,
          "message": JSON.stringify(otpGenerateData)
        }
        let otpgen = res;
        this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
        .subscribe(res=>{
          this.spinner.show();
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
            if(res['status'].code == 200){  
              this.showPasswordForm = false;             
              this.showOtpForm = true;
            } 
            else{ // otp genearte else
              //this.toastr.error(res['status'].message)
            }
          },err=> { // otp genearte error
            this.spinner.hide();
            this.hiddenBtn = false;
            this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
            console.log(err, 'login error')
            this.bsService.notifyError(err, otpGenerateData);
            this.spinner.hide();
            console.log(err, '=== OTP gererate error list =======')
        }); 
    
    }
            else{ // forgot passsword else
              //this.toastr.info(res['status'].message);
              console.log(res['status'].message)
            }
          },err=>{ //forgot passsword error
            this.spinner.hide();
            this.hiddenBtn = false;
            this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
            console.log(err, 'login error')
            this.bsService.notifyError(err, formData);
            this.spinner.hide();
            console.log(err)
          })


    }
    if (this.passwordForm.invalid) {
      return;
    }
  }

changedPasswordSuccess(){
  this.router.navigateByUrl('/profile-home');
}

resendOtp(){
  this.showResendOtp = false
  let otpGenerateData = {
      "identifierType":"MOBILE",
      "identifierValue":this.mobile_no,
      "deviceId": env.DEVICE_ID,
      "brand" : env.BRAND,
      "sessionId": this.sessionId
  }      
  this.spinner.show();
  let myuuid = uuidv4();
  let loggerFormData = {
    "unique_id": myuuid,
    "message": JSON.stringify(otpGenerateData)
  }
  let resendotpres;
  this.apiService.otpGenerate(otpGenerateData).pipe(first())  // otp generate api
  .subscribe(res=>{
    resendotpres = res;
    let myuuid = uuidv4();
    let loggerFormData = {
      "unique_id": myuuid,
      "message": JSON.stringify(res)
    }
    this.spinner.hide();
      if(res['status'].code == 200){
        // this.router.navigate(['/otp']);
      } 
      else{
        //this.toastr.error(res['status'].message)
        console.log(res['status'].message)
      }
    },err=> {
      this.spinner.hide();
      this.apiService.showErrorMsg('Đã xảy ra lỗi. Vui lòng thử lại sau')
      console.log(err, 'login error')
      this.bsService.notifyError(err, otpGenerateData);
      console.log(err, '=== OTP gererate error list =======')
  });      

}

showPassword(){
  this.show = !this.show;
}
showPassword1(){
  this.show1 = !this.show1;
}


handleEvent(event) {
  console.log(event.left, 'event')
  this.timeLeft = event.left;
  if (this.timeLeft == 0) {
    this.showResendOtp = true;
  }
}








}
