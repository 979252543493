import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../environments/environment';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { CountryListService } from 'src/app/services/country-list.service';
import * as moment from 'moment';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-user-profile-detail',
  templateUrl: './user-profile-detail.component.html',
  styleUrls: ['./user-profile-detail.component.scss']
})
export class UserProfileDetailComponent implements OnInit {

  childForm1: FormGroup;
  editProfileForm: FormGroup;
  submitted = false;
  submittedChild1 = false;
  showChildCard = false;
  showView = false;
  showEditProfile = false;
  hideProfileContent = true;

  submittedChild2 = false;
  showChildCard2 = false;
  showView2 = false;

  submittedChild3 = false;
  showChildCard3 = false;
  showView3 = false;

  submittedChild4 = false;
  showChildCard4 = false;
  showView4 = false;
  addMembersForm: FormGroup;
  content: any;
  scancodeData: any;
  subCatCode: any;
  milkCode: any
  is_milk: boolean = false;
  custDetail: any;
  address: any;
  globalChildArray: any = [];
  profileImage: any;
  finalUserImage: any = null;
  patchDistrict: any;

  @ViewChild('addMoreMembers1', { static: true }) addMoreMembers1: ModalDirective;
  @ViewChild('addMoreMembers2', { static: true }) addMoreMembers2: ModalDirective;
  @ViewChild('addMoreMembers3', { static: true }) addMoreMembers3: ModalDirective;
  @ViewChild('addMoreMembers4', { static: true }) addMoreMembers4: ModalDirective;
  config = {
    ignoreBackdropClick: true
  };
  parentCard1 = true;
  parentCard2 = true;
  parentCard3 = true;
  parentCard4 = true;

  //-------image upload variables-------
  visible = false;
  uniqueId: string;
  fileupload: any;
  capturedImage: any

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  fileInput: any;

  countryList: any = [];
  cityData: any = [];
  mobile_no: any;
  submitIndex: any;

  childImageUpload: any = [null, null, null, null];

  yearList: any = [];
  yearList1: any = [];
  track_milkCode: any = {}

  show_nonmilk: any = [true, true, true, true]
  firstTimeScan: any;
  motherDOByear: any;
  iSprintData: any;

  constructor(private location: Location, private formBuilder: FormBuilder, private apiService: ApiService,
    private spinner: NgxSpinnerService, private countryListData: CountryListService) {
    this.firstTimeScan = sessionStorage.getItem('firstTimeScan')
    console.log(this.firstTimeScan, 'this.firstTimeScan ')

    this.countryList = this.countryListData.jsondata[0];
    console.log(this.countryList, 'this.countryList')
    this.cityData = Object.values(this.countryList);
    console.log(this.cityData, 'this.mainProvienceData')

    this.mobile_no = sessionStorage.getItem('mobileNo');
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"))
    this.prodCategory = this.iSprintData?.category_code
  }

  ngOnInit(): void {
    this.parentCard1 = true;
    this.parentCard2 = true;
    this.parentCard3 = true;
    this.parentCard4 = true;
    this.childForm1 = this.formBuilder.group({
      surName: ['', Validators.required],
      name: ['', Validators.required],
      day: ['', Validators.required],
      month: ['', Validators.required],
      year: [''],
      ageRange: [''],
      similacmomtype: [''],
      typeofdiabetes: ['']
    });

    this.editProfileForm = this.formBuilder.group({
      surName: ['', Validators.required],
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.required],
      city: ['', Validators.required],
      district: ['', Validators.required],
      ward: [''],
      minCity: ['', Validators.required]
    })

    this.addMembersForm = this.formBuilder.group({
      surName: ['', Validators.required],
      name: ['', Validators.required],
      day: ['', Validators.required],
      month: ['', Validators.required],
      year: [''],
      babyAge: [''],
      termsAndConditions: [''],
      similacmomtype: [''],
      typeofdiabetes: ['']

    })

    this.getProductDescCode();
    this.getUser();
    this.getAllTransactions();
    this.yearList = this.apiService.findRange();
    this.yearList1 = this.apiService.findRange1();
    console.log(this.yearList1, 'this.yearList1')
    this.motherDOByear = this.apiService.findMotherDOBRange();
    console.log(this.motherDOByear, 'this.motherDOByear')
    this.track_milkCode = this.apiService.trackChildMilk();

  }

  //----------add new child form submit----------
  onSubmitaddMembersForm() {
    this.submitted = true;
    if (this.addMembersForm.invalid) {
      return;
    }
    else {
      let childForm: any;
      let childData = this.addMembersForm.value;
      console.log(childData, 'childData---')
      console.log(childData, 'childData submit---')
      //----------------child 1 data added------------
      if (this.submitIndex == 0) {
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_1",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_1",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 1 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_1_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_1",
            //"value": childData?.babyAge
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_1",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }

      }

      //----------------child 2 data added------------
      if (this.submitIndex == 1) {
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_2",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_2",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 2 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_2_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) { // if milk code, send child age
          addField.push({
            "name": "babys_age_2",
            // "value": childData?.babyAge
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_2",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }

      }

      //----------------child 3 data added------------
      if (this.submitIndex == 2) {
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_3",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_3",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 3 milk code section
                  "field": [
                    {
                      "name": this.track_milkCode.child_3_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_3",
            // "value": childData?.babyAge
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_3",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }

      }

      //----------------child 4 data added------------
      if (this.submitIndex == 3) {
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_4",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_4",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 4 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_4_milkcode,
                      "value": this.is_milk == true ? 'true' : 'false'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        if (this.milkCode == 1) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_4",
            // "value": childData?.babyAge
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_4",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }

      }

      console.log(childForm, 'childForm')

      let authToken = sessionStorage.getItem('authToken');
      this.spinner.show();
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(childForm)
      }
      this.apiService.updateCapCustomer(childForm, this.mobile_no, authToken).pipe(first())
        .subscribe(res => {
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.addMoreMembersHide1();
            this.globalChildArray = [];
            this.getUser();
            this.getAllTransactions();
            this.addMembersForm.reset();
          }
          else {
            console.log(res['status'].message)
            this.addMembersForm.reset();
          }
        }, err => {
          this.spinner.hide()
          console.log(err, '=== get customer error =======')
        });



    }
  }
  get addMembersFormVal() {
    return this.addMembersForm.controls;
  }

  //------------------update Exsisting Child------------------
  onSubmitChildForm(index) {
    this.submittedChild1 = true;
    if (this.childForm1.invalid) {
      return;
    }
    else {
      let childForm: any;
      let childData = this.childForm1.value;
      console.log(childData, 'childData---')

      //-----------update 1st child Info----------------

      if (index == 0) {
        let child1_milk_status = this.globalChildArray[0]?.ch_is_milk;
        console.log(child1_milk_status, 'child1_milk_status')
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_1",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_1",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 1 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_1_milkcode,
                      "value": this.is_milk == true && child1_milk_status == 'false' ? 'false' :
                        this.is_milk == false && child1_milk_status == 'false' ? 'false' :
                          this.is_milk == false && child1_milk_status == 'true' ? 'false' :
                            this.is_milk == true && child1_milk_status == 'true' ? 'true' :
                              'true'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']

        // if (child1_milk_status == 'true' ) {  // if milk code, send child age
        //   addField.push({
        //     "name": "babys_age_1",
        //     "value": childData?.ageRange
        //   })
        // }
        // else {
        //   addField.push({  // if non - milk code, send child DOB
        //     "name": "date_of_birth_1",
        //     "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
        //   })
        // }

        if (!this.globalChildArray[0]?.show_nonmilk) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_1",
            // "value": childData?.ageRange
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_1",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }

      }

      //-----------update 2nd child Info----------------
      if (index == 1) {
        let child2_milk_status = this.globalChildArray[1]?.ch_is_milk;
        console.log(child2_milk_status, 'child2_milk_status')
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_2",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_2",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 2 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_2_milkcode,
                      // "value": this.is_milk == true ? 'true' : 'false'

                      "value": this.is_milk == true && child2_milk_status == 'false' ? 'false' :
                        this.is_milk == false && child2_milk_status == 'false' ? 'false' :
                          this.is_milk == false && child2_milk_status == 'true' ? 'false' :
                            this.is_milk == true && child2_milk_status == 'true' ? 'true' :
                              'true'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']

        // if (child2_milk_status == 'true') { // if milk code, send child age
        //   addField.push({
        //     "name": "babys_age_2",
        //     "value": childData?.ageRange
        //   })
        // }
        // else {
        //   addField.push({  // if non - milk code, send child DOB
        //     "name": "date_of_birth_2",
        //     "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
        //   })
        // }

        if (!this.globalChildArray[1]?.show_nonmilk) { // if milk code, send child age
          addField.push({
            "name": "babys_age_2",
            //"value": childData?.ageRange
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_2",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
      }

      //-----------update 3rd child Info----------------
      if (index == 2) {
        let child3_milk_status = this.globalChildArray[2]?.ch_is_milk;
        console.log(child3_milk_status, 'child3_milk_status')
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_3",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_3",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 3 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_3_milkcode,
                      // "value": this.is_milk == true ? 'true' : 'false'

                      "value": this.is_milk == true && child3_milk_status == 'false' ? 'false' :
                        this.is_milk == false && child3_milk_status == 'false' ? 'false' :
                          this.is_milk == false && child3_milk_status == 'true' ? 'false' :
                            this.is_milk == true && child3_milk_status == 'true' ? 'true' :
                              'true'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']
        // if (child3_milk_status == 'true') {  // if milk code, send child age
        //   addField.push({
        //     "name": "babys_age_3",
        //     "value": childData?.ageRange
        //   })
        // }
        // else {
        //   addField.push({  // if non - milk code, send child DOB
        //     "name": "date_of_birth_3",
        //     "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
        //   })
        // }

        if (!this.globalChildArray[2]?.show_nonmilk) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_3",
            // "value": childData?.ageRange
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_3",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }

      }

      //-----------update 4th child Info----------------

      if (index == 3) {
        let child4_milk_status = this.globalChildArray[3]?.ch_is_milk;
        console.log(child4_milk_status, 'child4_milk_status')
        childForm = {
          "root": {
            "customer": [
              {
                "mobile": this.mobile_no,
                "custom_fields": {
                  "field": [
                    {
                      "name": "babys_name_4",
                      "value": childData?.name
                    },
                    {
                      "name": "babys_surname_4",
                      "value": childData?.surName
                    },
                    {
                      "name": "similacmomtype",
                      "value": childData?.similacmomtype
                    },
                    {
                      "name": "typeofdiabetes",
                      "value": childData?.typeofdiabetes
                    }
                  ]
                },
                "extended_fields": { // tracking child 4 milk code
                  "field": [
                    {
                      "name": this.track_milkCode.child_4_milkcode,
                      // "value": this.is_milk == true ? 'true' : 'false'

                      "value": this.is_milk == true && child4_milk_status == 'false' ? 'false' :
                        this.is_milk == false && child4_milk_status == 'false' ? 'false' :
                          this.is_milk == false && child4_milk_status == 'true' ? 'false' :
                            this.is_milk == true && child4_milk_status == 'true' ? 'true' :
                              'true'
                    }
                  ]
                }

              }
            ]
          }
        }

        let addField = childForm['root']['customer'][0]['custom_fields']['field']

        // if (child4_milk_status == 'true') {  // if milk code, send child age
        //   addField.push({
        //     "name": "babys_age_4",
        //     "value": childData?.ageRange
        //   })
        // }
        // else {
        //   addField.push({  // if non - milk code, send child DOB
        //     "name": "date_of_birth_4",
        //     "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
        //   })
        // }

        if (!this.globalChildArray[3]?.show_nonmilk) {  // if milk code, send child age
          addField.push({
            "name": "babys_age_4",
            // "value": childData?.ageRange
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }
        else {
          addField.push({  // if non - milk code, send child DOB
            "name": "date_of_birth_4",
            "value": childData?.day && childData?.month && childData?.year ? childData.year + '-' + childData.month + '-' + childData.day : "",
          })
        }

      }


      console.log(childForm, 'childForm')

      let authToken = sessionStorage.getItem('authToken');
      this.spinner.show();
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(childForm)
      }
      this.apiService.updateCapCustomer(childForm, this.mobile_no, authToken).pipe(first())
        .subscribe(res => {
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.showChild[index] = false;
            this.parentCard[index] = true;
            this.globalChildArray = [];
            this.getUser();
            this.getAllTransactions();
          }
          else {
            console.log(res['status'].message)
          }
        }, err => {
          this.spinner.hide()
          console.log(err, '=== get customer error =======')
        });

    }
  }

  parentCard: any = [true, true, true, true];
  showChild: any = [false, false, false, false]
  childIndex: any;
  onClickChildCardEdit(i) {
    this.showChildCard = true;
    this.showView = false;
    this.submittedChild1 = false;
    this.parentCard1 = false;

    this.parentCard[i] = false;
    this.showChild[i] = true;
    this.childIndex = i;

    console.log(i, 'index--')
    this.patchChildData(i);

    // this.addConditionalValidators();

    this.showViewCard[i] = false;

    // this.visible = false;
    this.submitIndex = null;
    console.log(this.visible, 'on edit open---')
  }

  //--------------add conditional validators-----------
  addConditionalValidators(child_nonmilk) {
    let ageRange = this.childForm1.get('ageRange');
    let day = this.childForm1.get('day')
    let month = this.childForm1.get('month')
    let year = this.childForm1.get('year')
    if (!child_nonmilk) {
      ageRange.clearValidators();
      day.clearValidators();
      month.clearValidators();
      year.clearValidators();

    }
    else {
      ageRange.clearValidators();
      day.setValidators([Validators.required]);
      month.setValidators([Validators.required]);
      year.setValidators([Validators.required]);
    }
    ageRange.updateValueAndValidity();
    day.updateValueAndValidity();
    month.updateValueAndValidity();
    year.updateValueAndValidity();
  }

  //------------------------patch Child Details-----------------
  patchChildData(formIndex) {
    console.log(this.globalChildArray, 'globalChildArray inside patch')
    this.childForm1.reset();
    console.log(this.getmomtype, 'this.getmomtype')
    //------patch 1st child data----
    if (formIndex == 0) {
      console.log(formIndex, 'formIndex ')
      console.log(this.globalChildArray[1]?.age_range, 'this.globalChildArray[1]?.age_range')
      let childDOB: any;
      let childAge: any;
      this.showChild[1] = false;
      this.showChild[2] = false;
      this.showChild[3] = false;
      this.parentCard[1] = true;
      this.parentCard[2] = true;
      this.parentCard[3] = true;


      // this.milkCode == 0 ?
      //   childDOB = this.globalChildArray[0].dob.split("-") :
      //   childAge = this.globalChildArray[0].age1;

      // this.milkCode == 0 ?
      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[0]?.surname,
      //     name: this.globalChildArray[0]?.name,
      //     day: childDOB[2],
      //     month: childDOB[1],
      //     year: childDOB[0],
      //   }) :

      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[0]?.surname,
      //     name: this.globalChildArray[0]?.name,
      //     ageRange: this.globalChildArray[0]?.age_range
      //   })

      this.globalChildArray[0]?.ch_is_milk == "true" && this.milkCode == 0 ?
        this.globalChildArray[0].show_nonmilk = true :

        this.globalChildArray[0]?.ch_is_milk == "true" && this.milkCode == 1 ?
          this.globalChildArray[0].show_nonmilk = false :

          this.globalChildArray[0]?.ch_is_milk == "false" && this.milkCode == 0 ?
            this.globalChildArray[0].show_nonmilk = true :

            this.globalChildArray[0].show_nonmilk = true

      //manjus code
      if (this.globalChildArray[0]?.ch_is_milk == "")
        this.globalChildArray[0].show_nonmilk = true;

      this.addConditionalValidators(this.globalChildArray[0].show_nonmilk);

      this.globalChildArray[0].dob != "" ?
        childDOB = this.globalChildArray[0]?.dob.split("-") :
        childAge = this.globalChildArray[0].age1;
      console.log(childDOB, 'childDOB=============')

      console.log(this.globalChildArray[0]?.ch_is_milk, "this.globalChildArray[0]?.ch_is_milk")
      if (this.globalChildArray[0]?.ch_is_milk == "false" || this.globalChildArray[0]?.ch_is_milk == "") {
        // this.globalChildArray[0].show_nonmilk = true;
        // this.globalChildArray[0].ch_is_milk = true;

        this.childForm1.patchValue({
          surName: this.globalChildArray[0]?.surname,
          name: this.globalChildArray[0]?.name,
          typeofdiabetes: this.custDetail?.similacmomtype,
          similacmomtype: this.getmomtype,
          day: childDOB == undefined ? childDOB = 'Ngày' : childDOB[2],
          month: childDOB == undefined ? childDOB = 'Tháng' : childDOB[1],
          year: childDOB == undefined ? childDOB = 'Năm' : childDOB[0],
          // day:  '',
          // month:  '',
          // year:  '',
        })
        console.log(this.childForm1, 'this.childForm1')
        console.log(this.globalChildArray, 'this.globalChildArray')
      }
      if (this.globalChildArray[0]?.ch_is_milk == "true") {
        this.childForm1.patchValue({
          surName: this.globalChildArray[0]?.surname,
          name: this.globalChildArray[0]?.name,
          ageRange: this.globalChildArray[0]?.age_range,
          typeofdiabetes: this.typeodDiabetis,
          similacmomtype: this.getmomtype,
          day: childDOB == undefined ? childDOB = 'Ngày' : childDOB[2],
          month: childDOB == undefined ? childDOB = 'Tháng' : childDOB[1],
          year: childDOB == undefined ? childDOB = 'Năm' : childDOB[0],
        })

      }
    }

    //--------------patch 2nd child data------------------------
    if (formIndex == 1) {
      console.log(formIndex, 'formIndex ')
      console.log(this.globalChildArray[2]?.age_range, 'this.globalChildArray[1]?.age_range')
      let childDOB1: any;
      let childAge: any;
      this.showChild[0] = false;
      this.showChild[2] = false;
      this.showChild[3] = false;
      this.parentCard[0] = true;
      this.parentCard[2] = true;
      this.parentCard[3] = true;

      // this.milkCode == 0 ?
      //   childDOB = this.globalChildArray[1].dob.split("-") :
      //   childAge = this.globalChildArray[1].age2;


      // this.milkCode == 0 ?
      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[1]?.surname,
      //     name: this.globalChildArray[1]?.name,
      //     day: childDOB[2],
      //     month: childDOB[1],
      //     year: childDOB[0],
      //   })
      //   :
      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[1]?.surname,
      //     name: this.globalChildArray[1]?.name,
      //     ageRange:  this.globalChildArray[1]?.age_range
      //   })

      this.globalChildArray[1]?.ch_is_milk == "true" && this.milkCode == 0 ?
        this.globalChildArray[1].show_nonmilk = true :

        this.globalChildArray[1]?.ch_is_milk == "true" && this.milkCode == 1 ?
          this.globalChildArray[1].show_nonmilk = false :

          this.globalChildArray[1]?.ch_is_milk == "false" && this.milkCode == 0 ?
            this.globalChildArray[1].show_nonmilk = true :

            this.globalChildArray[1].show_nonmilk = true


      this.addConditionalValidators(this.globalChildArray[1].show_nonmilk);

      this.globalChildArray[1].dob != "" ?
        childDOB1 = this.globalChildArray[1]?.dob.split("-") :
        childAge = this.globalChildArray[1].age2;
      console.log(childDOB1, 'childDOB1')

      console.log(this.globalChildArray[1]?.ch_is_milk, "this.globalChildArray[1]?.ch_is_milk")
      if (this.globalChildArray[1]?.ch_is_milk == "false" || this.globalChildArray[1]?.ch_is_milk == "") {
        // this.globalChildArray[1].show_nonmilk = true;
        // this.globalChildArray[1].ch_is_milk = true;
        this.childForm1.patchValue({
          surName: this.globalChildArray[1]?.surname,
          name: this.globalChildArray[1]?.name,
          typeofdiabetes: this.typeodDiabetis,
          similacmomtype: this.getmomtype,
          day: childDOB1[2],
          month: childDOB1[1],
          year: childDOB1[0],
        })
      }
      if (this.globalChildArray[1]?.ch_is_milk == "true") {
        this.childForm1.patchValue({
          surName: this.globalChildArray[1]?.surname,
          name: this.globalChildArray[1]?.name,
          ageRange: this.globalChildArray[1]?.age_range,
          typeofdiabetes: this.typeodDiabetis,
          similacmomtype: this.getmomtype,
          day: childDOB1[2],
          month: childDOB1[1],
          year: childDOB1[0],
        })

      }
    }

    //--------------patch 3rd child data------------------------
    if (formIndex == 2) {
      console.log(formIndex, 'formIndex ')
      console.log(this.globalChildArray[2]?.age_range, 'this.globalChildArray[2]?.age_range')
      let childDOB2: any;
      let childAge: any;
      this.showChild[0] = false;
      this.showChild[1] = false;
      this.showChild[3] = false;
      this.parentCard[0] = true;
      this.parentCard[1] = true;
      this.parentCard[3] = true;

      // this.milkCode == 0 ?
      //   childDOB = this.globalChildArray[2].dob.split("-") :
      //   childAge = this.globalChildArray[2]?.age3;

      // this.milkCode == 0 ?
      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[2]?.surname,
      //     name: this.globalChildArray[2]?.name,
      //     day: childDOB[2],
      //     month: childDOB[1],
      //     year: childDOB[0],
      //   })
      //   :
      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[2]?.surname,
      //     name: this.globalChildArray[2]?.name,
      //     ageRange: this.globalChildArray[2]?.age_range
      //   })

      this.globalChildArray[2]?.ch_is_milk == "true" && this.milkCode == 0 ?
        this.globalChildArray[2].show_nonmilk = true :

        this.globalChildArray[2]?.ch_is_milk == "true" && this.milkCode == 1 ?
          this.globalChildArray[2].show_nonmilk = false :

          this.globalChildArray[2]?.ch_is_milk == "false" && this.milkCode == 0 ?
            this.globalChildArray[2].show_nonmilk = true :

            this.globalChildArray[2].show_nonmilk = true


      this.addConditionalValidators(this.globalChildArray[2].show_nonmilk);

      this.globalChildArray[2].dob != "" ?
        childDOB2 = this.globalChildArray[2]?.dob.split("-") :
        childAge = this.globalChildArray[2]?.age3;

      console.log(this.globalChildArray[2]?.ch_is_milk, "this.globalChildArray[2]?.ch_is_milk")
      if (this.globalChildArray[2]?.ch_is_milk == "false" || this.globalChildArray[2]?.ch_is_milk == "") {
        // this.globalChildArray[2].show_nonmilk = true;
        // this.globalChildArray[2].ch_is_milk = true;
        this.childForm1.patchValue({
          surName: this.globalChildArray[2]?.surname,
          name: this.globalChildArray[2]?.name,
          typeofdiabetes: this.typeodDiabetis,
          similacmomtype: this.getmomtype,
          day: childDOB2[2],
          month: childDOB2[1],
          year: childDOB2[0],
        })
      }
      if (this.globalChildArray[2]?.ch_is_milk == "true") {
        this.childForm1.patchValue({
          surName: this.globalChildArray[2]?.surname,
          name: this.globalChildArray[2]?.name,
          ageRange: this.globalChildArray[2]?.age_range,
          typeofdiabetes: this.typeodDiabetis,
          similacmomtype: this.getmomtype,
          day: childDOB2[2],
          month: childDOB2[1],
          year: childDOB2[0],
        })

      }


    }

    //--------------patch 4th child data------------------------
    if (formIndex == 3) {
      console.log(formIndex, 'formIndex ')
      console.log(this.globalChildArray[1]?.age_range, 'this.globalChildArray[1]?.age_range')
      let childDOB3: any;
      let childAge: any;
      this.showChild[0] = false;
      this.showChild[1] = false;
      this.showChild[2] = false;
      this.parentCard[0] = true;
      this.parentCard[1] = true;
      this.parentCard[2] = true;

      // this.milkCode == 0 ?
      //   childDOB = this.globalChildArray[3].dob.split("-") :
      //   childAge = this.globalChildArray[3].age4;

      // this.milkCode == 0 ?
      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[3]?.surname,
      //     name: this.globalChildArray[3]?.name,
      //     day: childDOB[2],
      //     month: childDOB[1],
      //     year: childDOB[0],
      //   })
      //   :
      //   this.childForm1.patchValue({
      //     surName: this.globalChildArray[3]?.surname,
      //     name: this.globalChildArray[3]?.name,
      //     ageRange: this.globalChildArray[3]?.age_range
      //   })

      this.globalChildArray[3]?.ch_is_milk == "true" && this.milkCode == 0 ?
        this.globalChildArray[3].show_nonmilk = true :

        this.globalChildArray[3]?.ch_is_milk == "true" && this.milkCode == 1 ?
          this.globalChildArray[3].show_nonmilk = false :

          this.globalChildArray[3]?.ch_is_milk == "false" && this.milkCode == 0 ?
            this.globalChildArray[3].show_nonmilk = true :

            this.globalChildArray[3].show_nonmilk = true


      this.addConditionalValidators(this.globalChildArray[3].show_nonmilk);

      this.globalChildArray[3].dob != "" ?
        childDOB3 = this.globalChildArray[3]?.dob.split("-") :
        childAge = this.globalChildArray[3]?.age3;

      console.log(this.globalChildArray[3]?.ch_is_milk, "this.globalChildArray[3]?.ch_is_milk")
      if (this.globalChildArray[3]?.ch_is_milk == "false" || this.globalChildArray[3]?.ch_is_milk == "") {
        // this.globalChildArray[3].show_nonmilk = true;
        // this.globalChildArray[3].ch_is_milk = true;
        this.childForm1.patchValue({
          surName: this.globalChildArray[3]?.surname,
          name: this.globalChildArray[3]?.name,
          typeofdiabetes: this.typeodDiabetis,
          similacmomtype: this.getmomtype,
          day: childDOB3[2],
          month: childDOB3[1],
          year: childDOB3[0],
        })
      }
      if (this.globalChildArray[3]?.ch_is_milk == "true") {
        this.childForm1.patchValue({
          surName: this.globalChildArray[3]?.surname,
          name: this.globalChildArray[3]?.name,
          ageRange: this.globalChildArray[3]?.age_range,
          typeofdiabetes: this.typeodDiabetis,
          similacmomtype: this.getmomtype,
          day: childDOB3[2],
          month: childDOB3[1],
          year: childDOB3[0],
        })

      }

    }

    if (formIndex == null) {
      console.log(formIndex, 'received on close')
      formIndex = null

    }


  }



  closeChildCard(i) {
    this.showChildCard = false;
    this.parentCard1 = true;

    this.showChild[i] = false;
    this.parentCard[i] = true;

    this.patchChildData(null)

    this.childForm1.reset();


  }

  showViewCard: any = [false, false, false, false]
  onClickViewCard(i) {
    this.showView = true;

    this.showViewCard[i] = true;
    this.showChildCard = false;
  }
  closeViewCard(i) {
    this.showView = false;

    this.showViewCard[i] = false;
  }
  get formVal() {
    return this.childForm1.controls;
  }

  goBack() {
    this.location.back();
  }



  onClickEditProfile() {
    this.hideProfileContent = false;
    this.showEditProfile = true;
    this.submitIndex = 6;

    this.childIndex = null;
  }
  closeEditProfile() {
    this.hideProfileContent = true;
    this.showEditProfile = false;
    this.submitted = false;
  }
  onSubmitEditProfileForm() {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      return;
    }

    else {
      let masterUser = this.editProfileForm.value
      console.log(masterUser, 'masterUser')
      let userForm = {
        "root": {
          "customer": [
            {
              "mobile": masterUser.phoneNumber,
              "email": masterUser.email,
              "firstname": masterUser.name,
              "lastname": masterUser.surName,
              "custom_fields": {
                "field": [
                  {
                    "name": "street_name",
                    "value": masterUser?.minCity ? masterUser?.minCity : ""
                  },
                  {
                    "name": "city",
                    "value": masterUser?.city ? masterUser?.city : ""
                  },
                  {
                    "name": "district",
                    "value": masterUser?.district ? masterUser?.district : ""
                  },
                  {
                    "name": "wards",
                    "value": masterUser?.ward ? masterUser?.ward : ""
                  }

                ]
              },

            }
          ]
        }
      }



      let authToken = sessionStorage.getItem('authToken');
      this.spinner.show();
      let myuuid = uuidv4();
      let loggerFormData = {
        "unique_id": myuuid,
        "message": JSON.stringify(userForm)
      }
      this.apiService.updateCapCustomer(userForm, masterUser.phoneNumber, authToken).pipe(first())
        .subscribe(res => {
          let myuuid = uuidv4();
          let loggerFormData = {
            "unique_id": myuuid,
            "message": JSON.stringify(res)
          }
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.globalChildArray = [];
            // this.getUser();
            // this.getAllTransactions();
            // this.hideProfileContent = true;
            // this.showEditProfile = false;
            // sessionStorage.setItem('mobileNo', masterUser.phoneNumber);


            if (masterUser?.email != this.custDetail?.email) {
              console.log('inside email update if')
              let updateEmail = {
                "root": {
                  "customer": [
                    {
                      "identifier": 'email',
                      "old_value": this.custDetail?.email,
                      //  "new_value": userForm?.root?.customer[0]?.email
                      "new_value": masterUser?.email
                    }
                  ]
                }
              }
              this.spinner.show();
              let token = sessionStorage.getItem('authToken');
              let getMobile = sessionStorage.getItem('mobileNo');
              this.apiService.updateCapCustomerIdentity(updateEmail, getMobile, token).pipe(first())
                .subscribe(res => {
                  console.log(res, '=== updated email ===')
                  this.spinner.hide();

                  this.getUser();
                  this.getAllTransactions();
                  this.hideProfileContent = true;
                  this.showEditProfile = false;
                  sessionStorage.setItem('mobileNo', masterUser.phoneNumber);
                }, err => {
                  console.log(err, 'err===')
                })
            }

            else {
              console.log('inside email update else')
              this.getUser();
              this.getAllTransactions();
              this.hideProfileContent = true;
              this.showEditProfile = false;
              sessionStorage.setItem('mobileNo', masterUser.phoneNumber);

            }



          }
          else {
            console.log(res['status'].message)
          }
        }, err => {
          this.spinner.hide()
          console.log(err, '=== get customer error =======')
        });




    }

  }

  get editFormVal() {
    return this.editProfileForm.controls;
  }

  addMemberValidators() {
    let babyAge = this.addMembersForm.get('babyAge');
    let day = this.addMembersForm.get('day')
    let month = this.addMembersForm.get('month')
    let year = this.addMembersForm.get('year')
    let typeofdiabetes = this.addMembersForm.get('typeofdiabetes')
    if (this.milkCode == 1 || this.prodCategory == 'Similc' || this.prodCategory == 'Grow' || this.prodCategory == 'PediaSure') {
      babyAge.clearValidators();
      day.setValidators([Validators.required]);
      month.setValidators([Validators.required]);
      year.setValidators([Validators.required]);
      typeofdiabetes.clearValidators();
    }
    else if (this.milkCode == 1 || this.prodCategory == 'Ensure' || this.prodCategory == 'Glucerna') {
      babyAge.clearValidators();
      day.clearValidators();
      month.clearValidators();
      year.setValidators([Validators.required]);
    }



    else {
      babyAge.clearValidators();
      day.setValidators([Validators.required]);
      month.setValidators([Validators.required]);
      year.setValidators([Validators.required]);

    }
    babyAge.updateValueAndValidity();
    day.updateValueAndValidity();
    month.updateValueAndValidity();
    year.updateValueAndValidity();

  }


  addMoreMembersModal1(i) {
    this.submitIndex = i;
    this.addMemberValidators();
    this.addMoreMembers1.show();
    console.log(this.submitIndex, 'in add more member')

    this.childIndex = null

    // let getAuthKey = sessionStorage.getItem('authToken');
    // let mobile_no = sessionStorage.getItem('mobileNo');

    //   this.globalChildArray.forEach((ele, i)=>{
    //     this.apiService.getSavedImageFinal(mobile_no, getAuthKey, ele.child_image).pipe(first())
    //    .subscribe((res: any) => {
    //      this.spinner.hide();
    //      console.log(res.file_path, 'res.file_path---')
    //      this.childImageUpload[i] = res.file_path;
    //      console.log(this.childImageUpload[i], 'this.childImageUpload[i]')
    //      console.log(this.submitIndex, 'submitIndex')
    //      this.visible = false;
    //    })

    //  })

  }
  addMoreMembersModal2() {
    this.addMoreMembers2.show();
  }
  addMoreMembersModal3() {
    this.addMoreMembers3.show();
  }
  addMoreMembersModal4() {
    this.addMoreMembers4.show();
  }
  addMoreMembersHide1() {
    this.addMoreMembers1.hide();
    this.submitted = false;
  }
  addMoreMembersHide2() {
    this.addMoreMembers2.hide();
    this.submitted = false;
  }
  addMoreMembersHide3() {
    this.addMoreMembers3.hide();
    this.submitted = false;
  }
  addMoreMembersHide4() {
    this.addMoreMembers4.hide();
    this.submitted = false;
  }
  parseFunction(value) {
    return JSON.parse(value);
  }
  prodCategory: any;
  getProductDescCode() {
    this.milkCode = this.iSprintData?.is_milk_code;
    this.subCatCode = this.iSprintData?.sub_category_code;
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //console.log("scancode", this.scancodeData);
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    // console.log(this.scancodeData, 'this.scancodeData')
    // this.content = this.scancodeData?.product?.txt;
    // if (this.content) {
    //   this.content.forEach((item: any) => {

    //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.subCatCode = parsedPrimaryValue.value;
    //     }

    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;
    //       this.milkCode == 0 || this.milkCode == undefined ? this.is_milk = false : this.is_milk = true;
    //     }
    //   });
    //   console.log(this.milkCode, 'this.milkCode at first')


    // }
  }
  getmomtype: any;
  typeodDiabetis: any;
  getUser() {
    this.spinner.show();
    let getAuthKey = sessionStorage.getItem('authToken');
    let mobile_no = sessionStorage.getItem('mobileNo');
    this.apiService.getCapCustomer(mobile_no, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide()
        let ward: any = '';
        let street: any = '';
        let district: any = '';
        let city: any = '';
        this.custDetail = res?.customers?.customer[0];
        let momtype = this.custDetail.custom_fields.field.filter(r => r.name == 'similacmomtype')
        console.log(momtype[0].value, 'momtype1')
        this.getmomtype = momtype[0].value;
        let diatype = this.custDetail.custom_fields.field.filter(r => r.name == 'typeofdiabetes')
        console.log(diatype[0].value, 'momtype1')
        this.typeodDiabetis = diatype[0].value;

        console.log(this.custDetail, 'this.custDetail---')
        //-------------patch customer details----------------

        this.editProfileForm.patchValue({
          surName: this.custDetail?.lastname,
          name: this.custDetail?.firstname,
          phoneNumber: this.custDetail?.mobile.substring(2),
          email: this.custDetail?.email,
        })

        let userInfo = res?.customers?.customer[0]?.custom_fields?.field;
        let extended_field = res?.customers?.customer[0]?.extended_fields?.field;

        let image = userInfo.filter(res => res.name == 'profileimage');
        this.profileImage = image[0]?.value;
        console.log(this.profileImage, 'image');

        // this.showChildList(userInfo, this.profileImage)
        this.showChildList(userInfo, extended_field, this.profileImage)
        if (userInfo)
          userInfo.forEach((element: any) => {
            if (element.name == "wards" && element.value != "") {
              ward = element.value;
              this.editProfileForm.patchValue({ ward: ward });
            }
            if (element.name == "street_name" && element.value != "") {
              street = element.value;
              this.editProfileForm.patchValue({ minCity: street });
            }
            if (element.name == "district" && element.value != "") {
              district = element.value;
              this.patchDistrict = district;
            }
            if (element.name == "city" && element.value != "") {
              city = element.value;
              this.editProfileForm.patchValue({ city: city });
            }
          });
        // this.address = ward != '' && street != '' ? street + ', ' + ward + ', ' + district + ', ' + city:
        // street + ', ' + district + ', ' + city

        if (ward == '' && street == '') {
          if (city != '' && city != undefined) {
            this.address = district + ', ' + city
          } else {
            this.address = district
          }
        }
        else if (ward != '' && street != '') {
          this.address = street + ', ' + ward + ', ' + district + ', ' + city
        }
        else if (ward == '') {
          this.address = street + ', ' + district + ', ' + city
        }
        else if (street == '') {
          this.address = ward + ', ' + district + ', ' + city
        }



        console.log(this.address, 'this.address---')
        this.findDistrict(city)
      },
        err => {
          console.log(err, 'err---')
        })

  }

  districts: any = [];
  //---------patch district with the city name-----------
  findDistrict(city) {
    console.log('inside find district')
    this.cityData.filter(element => {
      if (element.display == city) {
        this.districts = element.districts;
        console.log(this.patchDistrict, 'this.patchDistrict--')
        this.editProfileForm.patchValue({ district: this.patchDistrict })
      }
    });
  }

  //------------get districts on city select---------------
  districtsChange(e) {
    this.cityData.filter(element => {
      if (element.display === e.target.value) {
        this.districts = element.districts;
      }
    });

  }


  parentUserImage: any = null;
  showChildList(userInfo, extended_field, profileImage) {
    // child_1_milkcode : "member_type",
    //   child_2_milkcode : "test_date_type",
    //   child_3_milkcode : "age_range_one",
    //   child_4_milkcode: "ssnNumber",
    console.log(userInfo, 'userInfo')
    console.log(extended_field, 'extended_field')
    let childArray = [];

    //-----------------store child surname-----------------

    let surname1 = userInfo.filter(res => res.name == 'babys_surname_1');
    console.log(surname1, 'surname1')
    surname1 = surname1.length > 0 ? surname1[0].value : '';
    console.log(surname1, 'surname1')



    let surname2 = userInfo.filter(res => res.name == 'babys_surname_2');
    surname2 = surname2.length > 0 ? surname2[0].value : '';
    console.log(surname2, 'surname2')

    let surname3 = userInfo.filter(res => res.name == 'babys_surname_3');
    surname3 = surname3.length > 0 ? surname3[0].value : '';
    console.log(surname3, 'surname3')

    let surname4 = userInfo.filter(res => res.name == 'babys_surname_4');
    surname4 = surname4.length > 0 ? surname4[0].value : '';
    console.log(surname4, 'surname4')


    let typeofdiabetes = userInfo.filter(res => res.name == 'typeofdiabetes');
    console.log(typeofdiabetes, 'typeofdiabetes')
    typeofdiabetes = typeofdiabetes.length > 0 ? typeofdiabetes[0].value : '';
    console.log(typeofdiabetes, 'typeofdiabetes')


    let similacmomtype = userInfo.filter(res => res.name == 'similacmomtype');
    console.log(similacmomtype, 'similacmomtype')
    similacmomtype = similacmomtype.length > 0 ? similacmomtype[0].value : '';
    console.log(similacmomtype, 'similacmomtype')

    //-----------------store child DOB-----------------

    let dob1 = userInfo.filter(res => res.name == 'date_of_birth_1');
    dob1 = dob1.length > 0 ? dob1[0].value : '';
    console.log(dob1, 'dob1')

    let dob2 = userInfo.filter(res => res.name == 'date_of_birth_2');
    dob2 = dob2.length > 0 ? dob2[0].value : '';
    console.log(dob2, 'dob2')

    let dob3 = userInfo.filter(res => res.name == 'date_of_birth_3');
    dob3 = dob3.length > 0 ? dob3[0].value : '';
    console.log(dob3, 'dob3')

    let dob4 = userInfo.filter(res => res.name == 'date_of_birth_4');
    dob4 = dob4.length > 0 ? dob4[0].value : '';
    console.log(dob4, 'dob4')

    //-----------------store child Age Range-----------------

    let age1 = userInfo.filter(res => res.name == 'babys_age_1');
    age1 = age1.length > 0 ? age1[0].value : '';
    console.log(age1, 'age1')

    let age2 = userInfo.filter(res => res.name == 'babys_age_2');
    age2 = age2.length > 0 ? age2[0].value : '';
    console.log(age2, 'age2')

    let age3 = userInfo.filter(res => res.name == 'babys_age_3');
    age3 = age3.length > 0 ? age3[0].value : ''
    console.log(age3, 'age3')

    let age4 = userInfo.filter(res => res.name == 'babys_age_4');
    age4 = age4.length > 0 ? age4[0].value : '';
    console.log(age4, 'age4')

    //-----------------store child Image -----------------

    let child1_image = userInfo.filter(res => res.name == 'baby1_profileimage');
    child1_image = child1_image.length > 0 ? child1_image[0].value : '';
    console.log(child1_image, 'child1_image')

    let child2_image = userInfo.filter(res => res.name == 'baby2_profileimage');
    child2_image = child2_image.length > 0 ? child2_image[0].value : '';
    console.log(child2_image, 'child2_image')

    let child3_image = userInfo.filter(res => res.name == 'baby3_profileimage');
    child3_image = child3_image.length > 0 ? child3_image[0].value : ''
    console.log(child3_image, 'child3_image')

    let child4_image = userInfo.filter(res => res.name == 'baby4_profileimage');
    child4_image = child4_image.length > 0 ? child4_image[0].value : '';
    console.log(child4_image, 'child4_image')

    //---------------create age value--------------------------------------
    let curr_date = new Date();
    let today = moment(curr_date).format('YYYY-MM-DD');

    let date1 = moment([today]);
    let date2 = moment([dob1]);
    let date3 = moment([dob2]);
    let date4 = moment([dob3]);
    let date5 = moment([dob4]);

    let child1_age = date1.diff(date2, 'years')
    console.log(child1_age, 'child1_age')

    let child2_age = date1.diff(date3, 'years')
    console.log(child2_age, 'child1_age')

    let child3_age = date1.diff(date4, 'years')
    console.log(child3_age, 'child1_age')

    let child4_age = date1.diff(date5, 'years')
    console.log(child4_age, 'child1_age')

    //-----------------store child Milk code -----------------

    let ch1_milkcode = extended_field.filter(res => res.name == 'member_type');
    ch1_milkcode = ch1_milkcode.length > 0 ? ch1_milkcode[0].value : '';
    console.log(ch1_milkcode, 'ch1_milkcode')

    let ch2_milkcode = extended_field.filter(res => res.name == 'test_date_type');
    ch2_milkcode = ch2_milkcode.length > 0 ? ch2_milkcode[0].value : '';
    console.log(ch2_milkcode, 'ch2_milkcode')

    let ch3_milkcode = extended_field.filter(res => res.name == 'age_range_one');
    ch3_milkcode = ch3_milkcode.length > 0 ? ch3_milkcode[0].value : ''
    console.log(ch3_milkcode, 'ch3_milkcode')

    let ch4_milkcode = extended_field.filter(res => res.name == 'ssnNumber');
    ch4_milkcode = ch4_milkcode.length > 0 ? ch4_milkcode[0].value : '';
    console.log(ch4_milkcode, 'ch4_milkcode')


    //----------------- create child array-----------------------------
    console.log(this.milkCode, 'this.milkCode')

    userInfo.filter(val => {
      if (val.name == 'babys_name_1' && val.value != "") {
        // this.globalChildArray.push({ name: val.value, child: 'present', surname: surname1, dob: dob1, child_image: child1_image, child_age: child1_age, age_range: age1})

        this.milkCode == 0 ?
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname1, typeofdiabetes: typeofdiabetes, similacmomtype: similacmomtype, dob: dob1, child_image: child1_image, child_age: child1_age, ch_is_milk: ch1_milkcode, age_range: age1 }) :
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname1, age_range: age1, child_image: child1_image, child_age: child1_age, ch_is_milk: ch1_milkcode, dob: dob1 })

        childArray.push(val)
      }
      if (val.name == 'babys_name_2' && val.value != "") {
        // this.globalChildArray.push({ name: val.value, child: 'present', surname: surname2, dob: dob2, child_image: child2_image, child_age: child2_age, age_range: age2,})
        this.milkCode == 0 ?
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname2, typeofdiabetes: typeofdiabetes, similacmomtype: similacmomtype, dob: dob2, child_image: child2_image, child_age: child2_age, ch_is_milk: ch2_milkcode, age_range: age2 }) :
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname2, age_range: age2, child_image: child2_image, child_age: child2_age, ch_is_milk: ch2_milkcode, dob: dob2 })

        childArray.push(val)
      }
      if (val.name == 'babys_name_3' && val.value != "") {
        // this.globalChildArray.push({ name: val.value, child: 'present', surname: surname3, dob: dob3, child_image: child3_image, age_range: age3,})
        this.milkCode == 0 ?
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname3, typeofdiabetes: typeofdiabetes, similacmomtype: similacmomtype, dob: dob3, child_image: child3_image, child_age: child3_age, ch_is_milk: ch3_milkcode, age_range: age3 }) :
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname3, age_range: age3, child_image: child3_image, child_age: child3_age, ch_is_milk: ch3_milkcode, dob: dob3 })

        childArray.push(val)
      }
      if (val.name == 'babys_name_4' && val.value != "") {
        // this.globalChildArray.push({ name: val.value, child: 'present', surname: surname4, dob: dob4, child_image: child4_image, age_range: age4})
        this.milkCode == 0 ?
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname4, typeofdiabetes: typeofdiabetes, similacmomtype: similacmomtype, dob: dob4, child_image: child4_image, child_age: child4_age, ch_is_milk: ch4_milkcode, age_range: age4 }) :
          this.globalChildArray.push({ name: val.value, child: 'present', surname: surname4, age_range: age4, child_image: child4_image, child_age: child4_age, ch_is_milk: ch4_milkcode, dob: dob4, })
        childArray.push(val)
      }
    })
    let noChildCount = 4 - childArray.length
    console.log(childArray, 'childArray')
    for (let i = 0; i < noChildCount; i++) {
      console.log(i, 'iii---')
      this.globalChildArray.push({ name: '', child: 'absent' })
    }
    console.log(this.globalChildArray, 'this.globalChildArray')
    //------------display user image--------------
    let getMobile = sessionStorage.getItem('mobileNo')
    let getAuthKey = sessionStorage.getItem('authToken')
    this.spinner.show();
    this.apiService.getSavedImageFinal(getMobile, getAuthKey, profileImage).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        // this.finalUserImage = res.file_path;  //-----------master user image
        // console.log(this.finalUserImage, 'image get')

        this.parentUserImage = res.file_path;  //-----------master user image
        console.log(this.parentUserImage, 'image get')
        this.visible = false;
      })

    this.globalChildArray.forEach((ele, i) => {
      this.apiService.getSavedImageFinal(getMobile, getAuthKey, ele.child_image).pipe(first())
        .subscribe((res: any) => {
          this.spinner.hide();
          console.log(res.file_path, 'res.file_path---')
          ele.display_childImage = res.file_path;
          this.visible = false;
        })

    })

    console.log(this.globalChildArray, 'after image load')





    return 1;
  }


  //-------------image upload functions------------------------

  imageCropped(event: ImageCroppedEvent) {
    console.log(event, 'cropped')
    this.croppedImage = event.base64;
    this.fileInput = base64ToFile(this.croppedImage);

  }
  fileChangeEvent(event: any): void {
    console.log('inside file chnage event--')
    this.imageChangedEvent = event;
    console.log(this.imageChangedEvent, 'this.imageChangedEvent')
    console.log(event.target.files.length, 'event.target.files.length--')
    if (event.target.files.length > 0) {
      this.visible = true;
      console.log(this.visible, 'inside if')
      this.fileupload = event.target.files[0]
    }
    console.log(this.visible, 'in file event')
  }



  count = 0;
  uploadImage() {
    this.count++;
    this.visible = false
    let getMobile = sessionStorage.getItem('mobileNo')
    let getAuthKey = sessionStorage.getItem('authToken')

    const imageData = new FormData();
    imageData.append('image', this.fileInput)
    imageData.append('mobile', getMobile)
    imageData.append('cap_device_id', env.DEVICE_ID)


    if (this.childIndex == 0 || this.submitIndex == 0) {
      console.log('inside 1')
      imageData.append('image_custom_field', 'baby1_profileimage')
    }

    else if (this.childIndex == 1 || this.submitIndex == 1) {
      console.log('inside 2')
      imageData.append('image_custom_field', 'baby2_profileimage')

    }

    else if (this.childIndex == 2 || this.submitIndex == 2) {
      console.log('inside 3')
      imageData.append('image_custom_field', 'baby3_profileimage')

    }

    else if (this.childIndex == 3 || this.submitIndex == 3) {
      console.log('inside 4')
      imageData.append('image_custom_field', 'baby4_profileimage')

    }

    else if (this.submitIndex == 6) {
      console.log('inside 5')
    }

    else { }

    console.log(imageData, 'imageData----')

    this.spinner.show();
    this.apiService.customerImageUpload(imageData, getAuthKey).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        console.log(res, 'image upload')
        let uniqueId = res.result.image_id
        let extension = res.result.image_extension
        this.spinner.show();
        this.apiService.getSavedImage(getMobile, getAuthKey, uniqueId, extension).pipe(first())
          .subscribe((res: any) => {
            this.spinner.hide();
            console.log(res.file_path, 'image get')
            // this.finalUserImage = res.file_path;
            // this.finalUserImage = res.file_path;

            this.globalChildArray.filter(ele => {
              if (this.childIndex == 0 || this.submitIndex == 0) {
                console.log('inside image fetch 1')
                this.globalChildArray[0].display_childImage = res.file_path
                this.globalChildArray[0].test = 'in 1'
                this.childImageUpload[0] = res.file_path
                // this.childImageUpload = res.file_path
              }

              else if (this.childIndex == 1 || this.submitIndex == 1) {
                console.log('inside image fetch 2')
                this.globalChildArray[1].display_childImage = res.file_path
                this.globalChildArray[1].test = 'in 2';
                this.childImageUpload[1] = res.file_path
                // this.childImageUpload = res.file_path
              }

              else if (this.childIndex == 2 || this.submitIndex == 2) {
                console.log('inside image fetch 3')
                this.globalChildArray[2].display_childImage = res.file_path
                this.globalChildArray[2].test = 'in 3'
                this.childImageUpload[2] = res.file_path
                // this.childImageUpload = res.file_path
              }

              else if (this.childIndex == 3 || this.submitIndex == 3) {
                console.log('inside image fetch 4')
                this.globalChildArray[3].display_childImage = res.file_path
                this.globalChildArray[3].test = 'in 4'
                this.childImageUpload[3] = res.file_path
                // this.childImageUpload = res.file_path
              }

              else {
                console.log('inside image fetch 5')
                this.parentUserImage = res.file_path;
              }

              this.visible = false;
            })

            console.log(this.globalChildArray, 'this.globalChildArray in load')

            this.visible = false;
            console.log(this.visible, 'visible result in final upload fn.')
            console.log(this.finalUserImage, 'this.finalUserImage')

          })
      }, err => {
        if (err) {
          this.spinner.hide();
        }
      })
  }

  imageLoaded() {
    console.log(this.count, 'this.count img load')
    if (this.count < 1) {
      this.showCropper = true;
      this.visible = true
      console.log('Image loaded');
    }
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }
  show() {
    this.visible = true
  }

  //---------------add child transactions details to array--------------

  getAllTransactions() {
    let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');

    let mobile_no = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken')
    this.spinner.show();
    this.apiService.getTransaction(mobile_no, getAuthKey, startOfMonth, endOfMonth).pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();

        //-------------using map--------------
        let custom_fields_Array: any = [];
        let result = res?.customer?.transactions;
        let transcation = result?.transaction;
        if (transcation) {
          custom_fields_Array = transcation.map(val => {
            return val.custom_fields['field']
          });
        }
        if (custom_fields_Array) {
          this.childTransactionDetails(custom_fields_Array);  // collection of all transaction array
        }
      })
  }


  childTransactionDetails(custom_fields_Array) {
    let testnew: any = [];
    let trackName: any = [];
    console.log(custom_fields_Array, 'custom_fields_Array');

    let new_array = [...custom_fields_Array]

    new_array.sort((a, b) => {
      if (a[9].name == 'child_info' && b[9].name == 'child_info') {
        if (a[9].value < b[9].value) { return -1; }
        if (a[9].value > b[9].value) { return 1; }
        return 0
      }

    });

    console.log(new_array, 'sorted one---')



    // -----extract itemcode, expiry date, scanned date, redemmed, giftid, is_eligible, is_milk
    // ------fields and add it to the child Array for display

    new_array.filter(custom_array => {
      let itemcode = custom_array.filter(res => res.name == 'itemcode');
      itemcode = itemcode.length > 0 ? itemcode[0].value : '';
      console.log(itemcode, 'itemcode')


      let expiry_date = custom_array.filter(res => res.name == 'expiration_date');
      expiry_date = expiry_date.length > 0 ? expiry_date[0].value : '';
      console.log(expiry_date, 'expiry_date')


      let scanned_date = custom_array.filter(res => res.name == 'scanned_date');
      scanned_date = scanned_date.length > 0 ? scanned_date[0].value : '';
      console.log(scanned_date, 'scanned_date')


      let redemmed = custom_array.filter(res => res.name == 'is_redeemed');
      redemmed = redemmed.length > 0 ? redemmed[0].value : '';
      console.log(redemmed, 'redemmed')


      let gift_id = custom_array.filter(res => res.name == 'gift_id');
      gift_id = gift_id.length > 0 ? gift_id[0].value : '';
      console.log(gift_id, 'gift_id')


      let is_eligible_for_game = custom_array.filter(res => res.name == 'is_eligible_for_game');
      is_eligible_for_game = is_eligible_for_game.length > 0 ? is_eligible_for_game[0].value : '';
      console.log(is_eligible_for_game, 'is_eligible_for_game')


      let is_milk = custom_array.filter(res => res.name == 'is_milk');
      is_milk = is_milk.length > 0 ? is_milk[0].value : '';
      console.log(is_milk, 'is_milk')


      let qrcode = custom_array.filter(res => res.name == 'transqrcode');
      qrcode = qrcode.length > 0 ? qrcode[0].value : '';
      console.log(qrcode, 'qrcode')


      custom_array.filter((ele, i) => {
        if (ele.name == "child_info" && ele.value != '') {
          console.log(ele.value, 'ele name value--')

          //---------check if the name is already present
          if (trackName.includes(ele.value)) {
            console.log('true--')

          }
          else {
            console.log('false')
            testnew = []

            console.log(ele.value, 'ele.value---')

          }
          trackName.push(ele.value);
          console.log(trackName, 'trackName')

          this.globalChildArray.filter(val => {
            if (val.name == ele.value) {
              console.log('match found---')

              testnew.push({
                'product_code': itemcode,
                'expiry_date': expiry_date,
                'scanned_date': scanned_date,
                'redemmed': redemmed,
                'gift_id': gift_id,
                'eligible_for_game': is_eligible_for_game,
                'is_milk': is_milk,
                'qrcode': qrcode,
              })

              val.childHistory = testnew;

            }
          })
        }

      })
    })

    console.log(this.globalChildArray, 'after final add---')

  }


  isPregnent: boolean = true;
  momtype(event) {
    console.log(event.target.value, 'momtype')
    if (event.target.value == 'Tôi đang mang thai') {
      this.isPregnent = true;
    } else if (event.target.value == 'Tôi đã có con') {
      this.isPregnent = false;
    }
  }


}