import { Component, OnInit, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-lucky-draw-quota-exceed',
  templateUrl: './lucky-draw-quota-exceed.component.html',
  styleUrls: ['./lucky-draw-quota-exceed.component.scss']
})
export class LuckyDrawQuotaExceedComponent implements OnInit {
  termsContent:any;
  growtermsContent:any;
  scancodeData:any;
  prodCategory:any;
  content:any;
  subCatCode:any;
  SimilacCount:any;
  GrowCount:any;
  EnsureCount:any;
  GlucernaCount:any;
  PediasureCount:any;

  @ViewChild('termsSimilac', { static: true }) termsSimilac: ModalDirective;
  config = {
    ignoreBackdropClick: true
  };
  iSprintData: any;
  constructor( private apiService: ApiService) { 
    window.scrollTo({top: 0});
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;
    
    this.SimilacCount = JSON.parse(sessionStorage.getItem('global_similac_Month_count'))
    this.GrowCount = JSON.parse(sessionStorage.getItem('global_grow_Month_count'))
    this.EnsureCount = JSON.parse(sessionStorage.getItem('global_ensure_Month_count'))
    this.GlucernaCount = JSON.parse(sessionStorage.getItem('global_glucerna_Month_count'))
    this.PediasureCount = JSON.parse(sessionStorage.getItem('global_pediasure_Month_count'))
  }

  ngOnInit(): void {
    this.similacTerms();
  }
  opensimilacTerms(){
    this.termsSimilac.show()
  }
  termsSimilacHide(){
    this.termsSimilac.hide()
  }
  openSimilacTermsandConditions(){
    this.opensimilacTerms();
  }

  ensuretermsContent:any;
  glucernatermsContent:any;
  pediasureltermsContent:any;
  similacTerms() {
    this.apiService.similacTermsandConditions().subscribe((res: any) => {
      res.data.forEach(element => {
        if (element.contentkey == 'Terms_and_conditions_Similac') {
          this.termsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Grow') {
          this.growtermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Gold') {
          this.ensuretermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_Glucerna') {
          this.glucernatermsContent = element.content;
        }
        if (element.contentkey == 'Terms_and_conditions_PediaSure') {
          this.pediasureltermsContent = element.content;
        }

      })
    })
  }

}
