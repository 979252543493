import { Component, OnInit, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manufacturing-granada',
  templateUrl: './manufacturing-granada.component.html',
  styleUrls: ['./manufacturing-granada.component.scss']
})
export class ManufacturingGranadaComponent implements OnInit {

  scancodeData = null;
  prodCategory:string;
  modalRef: BsModalRef;
  content:[];
  productCode:any;
  milkCode:any;
  config = {
    ignoreBackdropClick: true
  };
  iSprintData: any;
  subCatCode: any;
  constructor(private traceabilityService: ApiService, private route:ActivatedRoute,private modalService: BsModalService, private router: Router) {
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;
    
    window.scrollTo({ top: 0 });
  }

  ngOnInit(): void {
    this.getTraceabilityContent();
  }
  parseFunction(value) {
    return JSON.parse(value);
  }
  getTraceabilityContent() {
    this.milkCode = this.iSprintData?.is_milk_code;
    this.subCatCode =  this.iSprintData.sub_category_code;
    // let resultData = this.scancodeData
    // this.content = resultData.product.txt;
    // this.content.forEach((item: any) => {
    //   // product code 
    //   this.productCode = resultData.product.productCode;
    //   if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //     let parsedPrimaryValue = this.parseFunction(item.value);
    //     this.milkCode = parsedPrimaryValue.value;
    //     console.log(this.milkCode, 'this.milkCode');
    //   }
    // })
  } 
  
  openModal(template: TemplateRef<any>) {
    //if(this.milkCode == 1){
      this.modalRef = this.modalService.show(template, this.config);
    //}
  }

  gotoProdDesc(){
     if(this.milkCode == 1 && this.prodCategory == 'Grow'){
      this.router.navigate(['/product-description']);
      this.modalRef.hide();
     }
     else if(this.milkCode == 1 && this.prodCategory == 'Similac'){
      this.router.navigate(['/similac-mom-product']);
      this.modalRef.hide();
     }
     else if(this.milkCode == 1 && this.prodCategory == 'Ensure'){
      this.router.navigate(['/ensure-product']);
      this.modalRef.hide();
     }
     else if(this.milkCode == 1 && this.prodCategory == 'Glucerna'){
      this.router.navigate(['/glucerna-product']);
      this.modalRef.hide();
     }
     else if(this.milkCode == 1 && this.prodCategory == 'Pediasure'){
      this.router.navigate(['/pediasure-product']);
      this.modalRef.hide();
     }
      
  }
  gotoHome(){
    sessionStorage.setItem('gotoHomePage', 'gotoHome')
  }

}
