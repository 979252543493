<app-header></app-header>
<!-- <app-qc-breadcrumb></app-qc-breadcrumb> -->
<div class=" qcContainer">
    <div class=" bannerContainer ">
        <div class="container">
        <div class="row">
            <div class="col-8">
                <div class="bannerContents">
                <h1>{{ 'QC_BANNER.BANNER_TITLE' | translate }}<br>
                    {{ 'QC_BANNER.BANNER_TITLE1' | translate }}</h1>
                <p >{{ 'QC_BANNER.BANNER_DESC' | translate }}</p>
                </div>
            </div>      
        </div>
        </div>
    </div>
    <div class="qcContents">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p>{{ 'QC_BANNER.QC_CONTENTS.DESC1' | translate }}</p>
                    <p>{{ 'QC_BANNER.QC_CONTENTS.DESC2' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="qualityInspection">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="inspectionHead">
                        <li>
                            <p>5 bước kiểm định chất lượng khắt khe theo tiêu chuẩn quốc tế</p>
                        </li>
                    </ul>
                    <ul class="parent">
                        <li>
                            <ul class="child">
                                <li>
                                    <img src="../../../assets/images/iq1.png" alt="iq" >
                                </li>
                                <li>
                                    <p>Chọn nguyên liệu tốt nhất từ những nhà cung cấp chất lượng</p>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                        </li>
                        <li>
                            <ul class="child">
                                <li>
                                    <img src="../../../assets/images/iq2.png" alt="iq" >
                                </li>
                                <li>
                                    <p>Kiểm định toàn bộ các nguyên liệu đầu vào để đảm bảo đáp ứng các tiêu chuẩn chất lượng cao của công ty</p>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                        </li>
                        <li>
                            <ul class="child">
                                <li>
                                    <img src="../../../assets/images/iq3.png" alt="iq" >
                                </li>
                                <li>
                                    <p>Tuân thủ quy trình kiểm tra và đảm bảo chất lượng nghiêm ngặt đáp ứng những tiêu chuẩn cao nhất của quốc tế</p>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                        </li>
                        <li>
                            <ul class="child">
                                <li>
                                    <img src="../../../assets/images/iq4.png" alt="iq" >
                                </li>
                                <li>
                                    <p>Kiểm tra từng bước trong quá trình sản xuất để đảm bảo sản phẩm có chất lượng cao nhất</p>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                        </li>
                        <li>
                            <ul class="child">
                                <li>
                                    <img src="../../../assets/images/iq5.png" alt="iq" >
                                </li>
                                <li>
                                    <p>Giữ lại và theo dõi mẫu lưu của từng lô sản phẩm để một lần nữa đảm bảo chất lượng khi phân phối ra thị trường</p>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                        </li>
                        <div class="clearfix"></div>
                    </ul>   
                </div>
            </div>
        </div>
    </div>
   
    <div class="qcStepssection">
        <div class="container">
        <div class="row">
            <div class="col-4">
                <div class="leftColumn ">
                <img src="../../../assets/images/01.png">
                </div>
            </div>
            <div class="col-8 pl0">
                <p>{{ 'QC_BANNER.QC_CONTENTS.5STEPS_SECTION.STEP1_DESC' | translate }}</p>
            </div>
        </div>
        <div class="row mt20">
            <div class="col-4">
                <div class="leftColumn">
                <img src="../../../assets/images/02.png">
                </div>
            </div>
            <div class="col-8 pl0">
                <p>{{ 'QC_BANNER.QC_CONTENTS.5STEPS_SECTION.STEP2_DESC' | translate }}</p>
            </div>
        </div>
        <div class="row mt20">
            <div class="col-4">
                <div class="leftColumn">
                <img src="../../../assets/images/03.png">
                </div>
            </div>
            <div class="col-8 pl0">
                <p>{{ 'QC_BANNER.QC_CONTENTS.5STEPS_SECTION.STEP3_DESC' | translate }}</p>
            </div>
        </div>
        <div class="row mt20">
            <div class="col-4">
                <div class="leftColumn">
              
                <img src="../../../assets/images/04.png">
                </div>
            </div>
            <div class="col-8 pl0">
                <p>{{ 'QC_BANNER.QC_CONTENTS.5STEPS_SECTION.STEP4_DESC' | translate }}</p>
            </div>
        </div>
        <div class="row mt20">
            <div class="col-4">
                <div class="leftColumn">
                
                <img src="../../../assets/images/05.png">
                </div>
            </div>
            <div class="col-8 pl0">
                <p>{{ 'QC_BANNER.QC_CONTENTS.5STEPS_SECTION.STEP5_DESC' | translate }}</p>
            </div>
        </div>
        
        </div>
    </div>

    <div class="bannerSec">
        <div class="growBanner" *ngIf="prodCategory == 'Grow'">
            <img src="/assets/images/Group1_border.png" />
            <span class="smallText">GRO-C-273-20</span>
            <button class="orangeBtn blueBg" *ngIf="milkCode == '1'" (click)="openModal(template)">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
            <button class="orangeBtn blueBg" *ngIf="milkCode == '0'" routerLink="/product-description">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
        </div>
        <div class="similacBanner" *ngIf="prodCategory == 'Similac'">
            <img src="../../../assets/images/similac-bg.png" class="w-100" *ngIf="subCatCode == '2001' || subCatCode == '2002' || subCatCode == '2003' || subCatCode == '2004' || subCatCode == '2005' || subCatCode == '2006' || subCatCode == '2007' || subCatCode == '2008' || subCatCode == '2009' || subCatCode == '2010' || subCatCode == '2011' || subCatCode == '2012' || subCatCode == '2013' || subCatCode == '2014' || subCatCode == '2015' || subCatCode == '2016' || subCatCode == '2017'"  />  
                <img src="../../../assets/images/vigor/vignew.png" class="w-100" *ngIf="subCatCode == '2018' || subCatCode == '2019' || subCatCode == '2020' || subCatCode == '2021'" />       
                <div *ngIf="subCatCode == '2001' || subCatCode == '2002' || subCatCode == '2003' || subCatCode == '2004' || subCatCode == '2005' || subCatCode == '2006' || subCatCode == '2007' || subCatCode == '2008' || subCatCode == '2009' || subCatCode == '2010' || subCatCode == '2011' || subCatCode == '2012' || subCatCode == '2013' || subCatCode == '2014' || subCatCode == '2015' || subCatCode == '2016' || subCatCode == '2017'">
                    <button class="orangeBtn" *ngIf="milkCode == '1'" (click)="openModal(template)">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
                    <button class="orangeBtn" *ngIf="milkCode == '0'" routerLink="/similac-mom-product">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
                </div>
                <div *ngIf="subCatCode == '2018' || subCatCode == '2019' || subCatCode == '2020' || subCatCode == '2021'">
                    <a href="javascript:;" class="orangeBtn vigor" *ngIf="milkCode == '1'" (click)="openModal(template)">
                        <img src="../../../assets/images/vigor/ctavigor.png" alt="img" />
                    </a>
                    <a href="javascript:;" class="orangeBtn vigor" *ngIf="milkCode == '0'" routerLink="/similac-mom-product">
                        <img src="../../../assets/images/vigor/ctavigor.png" alt="img" />
                    </a>
                </div>
        </div>
        <div class="similacWidget mt40" *ngIf="prodCategory == 'Ensure'">
        
        <div class="traceabilityBannerOut">
            <img src="../../../assets/images/traceability/traceability_ensure_bottom.png" class="w-100" />
            <button class="orangeBtn position1" routerLink="/ensure-product">Tìm hiểu thêm</button>
        </div>
    </div>
    
    <div class="similacWidget mt40" *ngIf="prodCategory == 'Glucerna'">
            
            <div class="traceabilityBannerOut">
                <img src="../../../assets/images/traceability/traceability_glucerna_bottom.png" class="w-100" />
                <button class="orangeBtn position2" routerLink="/glucerna-product">Tìm hiểu thêm</button>
            </div>
        </div>
    
        <div class="similacWidget mt40" *ngIf="prodCategory == 'PediaSure'">
            <!-- Traceability Pediasure Banner -->
            <div class="traceabilityBannerOut">
                <img src="../../../assets/images/traceability/traceability_pediasure_bottom.png" class="w-100" />
                <button class="orangeBtn position3" routerLink="/pediasure-product">Tìm hiểu thêm</button>
            </div>
        </div>
    </div>

    <div class="manufacturerShareAndCareContainer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-0 careShareText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.CARE_AND_SHARE' | translate }}</h3>
                    <h3 class="homeText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.HOME' | translate }}</h3>
                    <p>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE1' | translate }}
                        <br><strong>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE_STRONG' | translate }}</strong></p>
                    <button class="orangeBtn" (click)="gotoHome()" routerLink="/sign-up/0">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.JOIN_BUTTON' | translate }}</button>
                    <p>
                        {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE1' | translate }} 
                        <strong>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE_STRONG' | translate }} </strong><br>
                        {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE2' | translate }}
                        <small> {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.REGISTRATION_CODE' | translate }}</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-body">
        <section class="checkMilkcodeMdl">
            <button type="button" class="close pull-right closeBtn" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
                <p>Vui lòng xác nhận bạn là nhân viên y tế hoặc có nhu cầu tìm hiểu thông tin sản phẩm</p>
                <div class="row mb-3">
                    <div class="col-6">
                        <a href="javascript:;"
                        class="primaryBtn" (click)="modalRef.hide()">Quay về trang chủ</a>
                    </div>
                    <div class="col-6">
                        <a href="javascript:;"
                        class="primaryBtn" (click)="gotoProdDesc()">Xác nhận</a>
                       
                    </div>
                </div>
        </section>
    </div>
</ng-template>

<app-footer></app-footer>
<app-nav-menu></app-nav-menu>
