import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pediasure-qc',
  templateUrl: './pediasure-qc.component.html',
  styleUrls: ['./pediasure-qc.component.scss']
})
export class PediasureQcComponent implements OnInit {

  constructor() {
    window.scrollTo({ top: 0 });
   }
  

  ngOnInit(): void {
  }

}
