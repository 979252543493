<app-header></app-header>
<section class="QCContiner">
    <div class="QcOut">
        <div class="container">
            <div class="row">
                <div class="col-9">
                    <h1>Quy trình <br>kiểm định chất lượng</h1>
                    <p>Abbott đảm bảo chất lượng và độ an toàn tối đa của từng sản phẩm, thông  qua quy trình kiểm định đáp ứng tiêu  chuẩn quốc tế cao nhất.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>
                    Tại Abbott, chất lượng là ưu tiên hàng đầu của chúng tôi. Khi lựa chọn sản phẩm dinh dưỡng, 
                    chúng tôi hiểu điều quan trọng là các gia đình muốn biết về các dưỡng chất có trong sản phẩm, 
                    cách chúng được làm ra và cách chúng tôi kiểm tra nhằm đảm chất lượng sản phẩm.
                </p>
                <p>
                    Đây là năm trong số những bước quan trọng mà chúng tôi thực hiện để đảm bảo chất lượng và có 
                    được sự tin tưởng của bạn.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="yellowBorderSquare">
                    5 bước kiểm định chất lượng khắt khe theo tiêu chuẩn quốc tế
                </div>
            </div>
        </div>

        <div class="qualityControl">
            <div class="row align-items-center">
                <div class="col-4">
                    <img src="../../../assets/images/ensure-qc/01.png" alt="01" >
                </div>
                <div class="col-8">
                    <p>Chọn nguyên liệu tốt nhất từ những nhà cung cấp chất lượng. </p>
                </div>
            </div>
            <div class="row mt20 align-items-center">
                <div class="col-4">
                    <img src="../../../assets/images/ensure-qc/02.png" alt="02" >
                </div>
                <div class="col-8">
                    <p>Kiểm định toàn bộ các nguyên liệu đầu vào để đảm bảo đáp ứng các tiêu chuẩn chất lượng cao của công ty. </p>
                </div>
            </div>
            <div class="row mt20">
                <div class="col-4">
                    <img src="../../../assets/images/ensure-qc/03.png" alt="03" >
                </div>
                <div class="col-8">
                    <p>Tuân thủ quy trình kiểm tra và đảm bảo chất lượng nghiêm ngặt đáp ứng những tiêu chuẩn cao nhất của quốc tế. Toàn bộ quá trình sản xuất của nhà máy đều đạt chuẩn GMP và các tiêu chuẩn quốc tế khắt khe như tiêu chuẩn HACAPP, tiêu chuẩn ISO 9001, tiêu chuẩn 22000 và tiêu chuẩn FSSC.</p>
                </div>
            </div>
            <div class="row mt20">
                <div class="col-4">
                    <img src="../../../assets/images/ensure-qc/04.png" alt="04" >
                </div>
                <div class="col-8">
                    <p>Kiểm tra từng bước trong quá trình sản xuất để đảm bảo sản phẩm có chất lượng cao nhất. Quy trình sản xuất được thiết kế khép kín hoàn toàn không có sự can thiệp của con người và tự động vận hành bằng máy từ khâu quản lý nguyên liệu, sản xuất cho đến đống gói.</p>
                </div>
            </div>
            <div class="row mt20">
                <div class="col-4">
                    <img src="../../../assets/images/ensure-qc/05.png" alt="05" >
                </div>
                <div class="col-8">
                    <p>Giữ lại và theo dõi mẫu lưu của từng lô sản phẩm để một lần nữa đảm bảo chất lượng khi phân phối ra thị trường. Sản phẩm chỉ được đưa ra thị trường sau khi đạt tiêu chuẩn kiểm định chặt chẽ. Nhà phân phối các sản phẩm của Abbott phải tuân thủ nghiêm ngặt và đầy đủ quy trình bảo quản, vận chuyển của công ty, từ giám sát nhiệt độ, độ ẩm đèn vệ sinh, phòng cháy chữa cháy, phòng chống côn trùng,...</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Traceability Ensure Banner -->
    <div class="traceabilityBannerOut">
        <div class="line-img">
            <img src="../../assets/images/traceability/line-copy.png" class="w-100" />
        </div>
      <img
        src="../../assets/images/traceability/traceability_ensure_bottom_new.png"
        class="w-100"
      />
      <button class="orangeBtn position1" routerLink="/ensure-product">
        Tìm hiểu thêm
      </button>
    </div>
</section>

<div class="manufacturerShareAndCareContainer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="mb-0 careShareText text-uppercase">care & share</h3>
                <h3 class="homeText text-uppercase">home</h3>
                <p>Tham gia để nhận được <br><strong>các lợi ích hấp dẫn</strong></p>
                <button class="orangeBtn" routerLink="/sign-up/1">Đăng ký ngay</button>
                <p>Liên hệ hotline <a class="telNum" href="tel:19001519">19001519 </a><br>để biết thêm chi tiết <br><small>ENS-C-332-23</small>
                </p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
<app-nav-menu></app-nav-menu>