<app-header></app-header>
<!-- <section class="similacMomProductContainer">
    <section class="similacInnerSectionOut pt-3">
        <section class="similacVannilaOut">
            <div class="row my-3">
                <div class="col-4 text-right pr-0">
                    <img class="leftImageWithContent" src="../../../assets/images/book.png" />
                </div>
                <div class="col-7 text-center pl-0">
                    <p class="blueTitleLarge mb-0">
                        {{ 'KNOWLEDGE.DESC_PARA1' | translate }}
                    </p>
                    <p class="blueTitleLarge mt-0">
                        {{ 'KNOWLEDGE.DESC_PARA2' | translate }}
                    </p>
                </div>
            </div>
                <p class="textBlack text-center">{{ 'KNOWLEDGE.PARA1' | translate }}</p>
            <div class="row justify-content-center mt-4">
                <div class="col-8">
                    <img class="img-fluid" src="../../../assets/images/growgoldlogo.png">
                    <a class="orangeBtn btn-block mt-3" href="https://grow.com.vn/">
                        {{ 'KNOWLEDGE.BTN_TEXT' | translate }}
                    </a>
                    <img class="img-fluid mt-4" src="../../../assets/images/nu-i-d-ng-t-i-tr-nh.png">
                    <a class="orangeBtn btn-block mt-4" href="https://similac.com.vn/">
                        {{ 'KNOWLEDGE.BTN_TEXT' | translate }}
                    </a>
                </div>      
               
            </div>    
        </section>
    </section>
    <div>
        <img class="img-fluid" src="../../../assets/images/knowledgebottomimage.png">
    </div>
</section> -->

<section class="knowledgeHubOut">
    <div class="container">
        <div class="imgTxtOut">
            <div class="row mt20 mb20">
                <div class="col-3 col">
                    <img class="img-fluid" src="../../../assets/images/loyalty-program/lp3-ci2.png" alt="lp3-ci2" />
                </div>
                <div class="col-8 col text-left">
                    <h1>Khám phá kiến thức khoa học dinh dưỡng cho bạn và cả nhà</h1>
                </div>
            </div>
        </div>
        <p class="mb20 desc">
            Vững tâm chăm sóc gia đình bạn sống khỏe an vui với nguồn thông tin khoa học dinh dưỡng, truy cập ngay trang web của Similac, 
            Abbott Grow, PediaSure, Ensure Gold & Glucerna dưới đây bạn  nhé. 
        </p>
        <div class="prdCardsOut mt20 mb20">
            <div class="row">
                <div class="col-4">
                    <div class="prdCategoryCards">
                        <div class="imgOut">
                            <img class="img-fluid" src="../../../assets/images/loyalty-program/abbott_grow.png" alt="abbott_grow" />
                        </div>
                        <a href="https://grow.com.vn/" target="_blank" class="orangeBtn">Tìm hiểu thêm</a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="prdCategoryCards">
                        <div class="imgOut">
                            <img class="img-fluid" src="../../../assets/images/loyalty-program/similac-iq.png" alt="similac-iq" />
                        </div>
                        <a href="https://similac.com.vn/" target="_blank" class="orangeBtn">Tìm hiểu thêm</a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="prdCategoryCards">
                        <div class="imgOut">
                            <img class="img-fluid" src="../../../assets/images/loyalty-program/pediasure-logo.png" alt="pediasure-logo" />
                        </div>
                        <a href="https://pediasure.com.vn/" target="_blank" class="orangeBtn">Tìm hiểu thêm</a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="prdCategoryCards">
                        <div class="imgOut">
                            <img class="img-fluid" src="../../../assets/images/loyalty-program/logo-ensure-gold-hmb-blue.png" alt="logo-ensure-gold-hmb-blue" />
                        </div>
                        <a href=" https://ensure.vn/" target="_blank" class="orangeBtn">Tìm hiểu thêm</a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="prdCategoryCards">
                        <div class="imgOut">
                            <img class="img-fluid" src="../../../assets/images/loyalty-program/glucerna.png" alt="glucerna" />
                        </div>
                        <a href="https://glucerna.com.vn/" target="_blank" class="orangeBtn">Tìm hiểu thêm</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <img class="img-fluid" src="../../../assets/images/loyalty-program/family_group_without_bottom_curve.png" alt="family_group_without_bottom_curve" />
    </div>
</section>
<footer class="footer">
    <div class="footerContainer">
        <div class="footerTopSection mb-4">
            <div>
              <p class="text-center fs14 font-yellow fw-bolder">
                Mua sản phẩm, quét mã QR và tham gia chương trình tại:
              </p>
            </div>
            <div class="row fs10 d-flex justify-content-center mb-2">
              <div class="col-5">
                <div>
                  <p class="text-center">Kênh thương mại điện tử</p>
                </div>
                <div class="d-flex justify-content-center">
                  <a>
                    <img src="../../../assets/images/laz.png" class="mx-2" />
                  </a>
                  <a>
                    <img src="../../../assets/images/sbag.png" />
                  </a>
                  <a>
                    <img src="../../../assets/images/tiki.png" class="mx-2" />
                  </a>
                </div>
              </div>
              <div class="col-3 centerSection">
                <div class="d-block">
                  <p class="text-center">Siêu thị</p>
                  <div class="d-flex justify-content-center">
                    <a>
                      <img src="../../../assets/images/cartBlack.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-4 lastSection">
                <div class="d-block text-center">
                  <p class="text-center">Cửa hàng bán lẻ</p>
                  <div class="d-flex justify-content-center">
                    <a>
                      <img src="../../../assets/images/shopBlack.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <img src="../../../assets/images/abbot-logo-copy-3.png" alt="logo">
          </div>
          <div class="col-6 text-right">
           <p class="text-right">COR-C-146-21</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="m20">{{ 'FOOTER.FOOTER_TEXT1' | translate }}</p>
  
            <p>{{ 'FOOTER.FOOTER_TEXT2' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
<app-nav-menu></app-nav-menu>