<app-header></app-header>
<section class="signUpContainer">
  <form class="formContainer text-center" [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()">
    <P>{{ 'OTP.OTP_SENT_YOUR_PHONE' | translate }}<br> ({{addMobNum}}){{ 'OTP.TOP_TEXT_TWO' | translate }}</P>
    <!-- <P class="mb-0">{{ 'OTP.RECEIVE_A_CALL_FROM_MOBILE' | translate }}</P> -->
    <div class="form-group mt-3">
      <input type="text" class="form-control" formControlName="otp" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""
      [ngClass]="{'is-invalid': submitted && otpFormVal.otp.errors || otpError}"  maxlength="6" (keypress)="onlyNumbers($event)" required >
      <label for="exampleInputEmail1">{{ 'OTP.LABEL' | translate }}</label>
      <div *ngIf="submitted &&  otpFormVal.otp.errors || otpError " class="invalid-feedback">
        <div class="text-left"> {{ 'OTP.OTP_ERROR_MESSAGE'| translate }} </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col">
        <button type="button" class="btn btn-primary btn-block orangeBtnOutline" routerLink="/sign-up">{{ 'OTP.BTN_LEFT' |
          translate
          }}</button>
      </div>
      <div class="col">
        <button type="submit" class="btn btn-primary btn-block orangeBtn">{{ 'OTP.BTN_RIGHT' | translate
          }}</button>
      </div>
    </div>
      
      <!-- <p class="mt-3">{{ 'OTP.BOTTOM_DESC_ONE' | translate }} <span class="linkText" (click)="resendOtp()"> {{ 'OTP.BOTTOM_DESC_TWO' | translate }} </span></p> -->
      <div [ngClass]="!showResendOtp? 'otpStart' : ''">
        <p class="mt-3">{{ 'OTP.BOTTOM_DESC_ONE' | translate }}<br>  
          <span (click)="!showResendOtp ? '' : resendOtp()"  [ngClass]="!showResendOtp? '' : 'linkText'">
              {{ 'OTP.BOTTOM_DESC_THREE' | translate }}
              <span *ngIf="!showResendOtp"><countdown #cd [config]="{leftTime: 60,format: 'm:ss',notify: [ 2, 5 ]}"
                (event)="handleEvent($event)"></countdown></span>
              <span *ngIf="showResendOtp">0:00</span>
          </span>
        </p>
      </div>   
  </form>
</section>
<app-auth-footer></app-auth-footer>
<app-nav-menu></app-nav-menu>

