<app-header></app-header>
<!-- <app-dynamic-no-animate-breadcrumb textone="Trang chủ" texttwo="Quay số may mắn"></app-dynamic-no-animate-breadcrumb> -->

<div class="tracebilityLandingContainer noPadding">
    <figure class="bannerOut">
        <div class="row m-0">
            <div class="col-7 text-center pl-1 mt-1">
                <p class="bannerText">{{ 'PRODUCT_INFORMATION.BANNER_TEXT1' | translate }}</p>
                <img class="shareCareImg careShareLogo" src="../../../assets/images/care&share-01.png">
                <p class="bannerText mt17- mr-3">{{ 'PRODUCT_INFORMATION.BANNER_TEXT2' | translate }}</p>
            </div>      
        </div>
        <a href="" class="bannerBtn">{{ 'PRODUCT_INFORMATION.BANNER_BUTTON_TEXT' | translate }}</a>
    </figure>
    <div class="contents">
        <div class="row">
            <div class="col-5">
                <div class="primaryImgOut">
                    <img class="primaryImg" [src]="productImage" />
                    <!-- <img class="assurity" src="../../../assets/images/assurity.png" /> -->
                </div>
                <img class="animateImg mt40 one" src="../../../assets/images/Product-arrow-animation-left-to-right.gif" />
            </div>
            <div class="col-7 pl0">
                <div class="innerContents first">
                    <h3 *ngIf="primaryProductTitle">{{ primaryProductTitle  }}</h3>
                    <p  *ngIf="primaryShortProductInfo" [innerHTML]="primaryShortProductInfo | safe: 'html'"></p>
                    
                    <div class="details">
                        <p><label>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_CODE_LABEL' | translate }}</label><span>
                                {{ productCode }}</span></p>
                    </div>
                    
                    <a href="javascript:;"
                        class="primaryBtn" *ngIf="prodCategory == 'Grow'" routerLink="/product-description">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT' | translate }}</a>
                    <a href="javascript:;"
                        class="primaryBtn" *ngIf="prodCategory == 'Similac'" routerLink="/similac-mom-product">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_1.PRODUCT_BUTTON_TEXT' | translate }}</a>
                </div>
            </div>
        </div>
        <div class="row mt40">
            <div class="col-7 pr-0">
                <div class="innerContents">
                    <h3 *ngIf="factoryTitleInfo">{{ factoryTitleInfo | translate }}</h3>
                    <p class="colorPrimary" *ngIf="factoryShortProductDescription">
                        {{ factoryShortProductDescription | translate }}</p>
                    <div class="details">
                        <p *ngIf="batchCode">
                            <label>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_CODE_LABEL' | translate }}</label><span>
                                {{ batchCode }}</span></p>
                        <p *ngIf="manufacturedDate">
                            <label>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_MFR_DATE_LABEL' | translate }}</label><span>
                                {{ manufacturedDate }}</span></p>
                        <p *ngIf="expiryDate">
                            <label>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_EXP_DATE_LABEL' | translate }}</label><span>
                                {{ expiryDate }}</span></p>
                    </div>
                    <a href=""
                        class="primaryBtn" routerLink="/manufacturing-holland" *ngIf="manufactCountry == 'Netherlands'">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT' | translate }}</a>
                    <a href=""
                    class="primaryBtn" routerLink="/manufacturing-granada" *ngIf="manufactCountry == 'Spain'">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT' | translate }}</a>
                    <a href=""
                    class="primaryBtn" routerLink="/manufacturing-us" *ngIf="manufactCountry == 'US'">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT' | translate }}</a>
                    <a href=""
                    class="primaryBtn" routerLink="/manufacturing-cootehill" *ngIf="manufactCountry == ' Ireland'">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT' | translate }}</a>
                    <a href=""
                    class="primaryBtn" routerLink="/manufacturing-singapore" *ngIf="manufactCountry == 'Singapore'">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_2.PRODUCT_BUTTON_TEXT' | translate }}</a>
                </div>
            </div>
            <div class="col-5">
                <img class="manufactIcon" src="../../../assets/images/building.png" />
                <img class="mt40 animateImg two" src="../../../assets/images/Product-arrow-animation-right-to-left.gif" />
            </div>
        </div>
        <div class="row mt40">
            <div class="col-5">
                <img class="primaryImg" src="../../../assets/images/search.png" />
                <img class="mt40 animateImg three" src="../../../assets/images/Product-arrow-animation-left-to-right.gif" />
            </div>
            <div class="col-7 pl0">
                <div class="innerContents">
                    <h3>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_NAME' | translate }}</h3>
                    <p>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_DESC' | translate }}</p>
                    <a href=""
                        class="primaryBtn" routerLink="/qc">{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_3.PRODUCT_BUTTON_TEXT' | translate }}</a>
                </div>
            </div>
        </div>
        <div class="row mt40">
            <div class="col-10 offset-2">
                <div class="row">
                    <div class="col-7">
                        <div class="innerContents">
                            <h3>{{ 'PRODUCT_INFORMATION.PRODUCT_INFORMATION_4.PRODUCT_NAME' | translate }}</h3>
                            <div class="details">
                                <div class="details">
                                    <p *ngIf="entranceDate"><span> {{ entranceDate | translate }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-5 pl0">
                        <img class="primaryImg" src="../../../assets/images/ship.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Grow banner -->
    <div class="growWidget mt40" *ngIf="prodCategory == 'Grow'">
        <div class="growContainer">
            <div class="text-center">
                <button class="orangeBtn" routerLink="/product-description">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
            </div>
        </div>
    </div>
    <!-- Similac banner -->
    <div class="similacWidget mt40" *ngIf="prodCategory == 'Similac'">
        <div class="similacContainer">
            <button class="orangeBtn" routerLink="/similac-mom-product">{{ 'MORE_INFORMATION_BTN_TXT' | translate }}</button>
        </div>
    </div>
    <div class="shareAndCareContainer">
        <h1>{{ 'SHARE_AND_CARE_INFO.MAIN_TITLE1' | translate }} <br> <span>{{ 'SHARE_AND_CARE_INFO.MAIN_TITLE2' | translate }}</span></h1>
        <p>{{ 'SHARE_AND_CARE_INFO.SUB_TITLE1' | translate }} <br> <strong>{{ 'SHARE_AND_CARE_INFO.SUB_TITLE2' | translate }}</strong></p>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-4">
                        <img src="../../../assets/images/group4.png">
                    </div>
                    <div class="col-8">
                        <h6>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.TITLE1' | translate }}</h6>
                        <p>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.DESC1' | translate }}</p>
                    </div>
                </div>
                <div class="row mt20">
                    <div class="col-4">
                        <img src="../../../assets/images/group2.png">
                    </div>
                    <div class="col-8">
                        <h6>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.TITLE2' | translate }}</h6>
                        <p>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.DESC2' | translate }}</p>
                    </div>
                </div>
                <div class="row mt20">
                    <div class="col-4">
                        <img src="../../../assets/images/group3.png">
                    </div>
                    <div class="col-8">
                        <h6>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.TITLE3' | translate }}</h6>
                        <p>{{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.DESC3' | translate }} {{ 'SHARE_AND_CARE_INFO.NUTRITION_INFO.CALL_CENTER' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="registerNowContainer">
        <h4>{{ 'REGISTRATION_INFO.TITLE' | translate }}</h4>
        <i><img src="../../../assets/images/register.png" alt="register"></i>
        <div class="text-center">
            <button class="orangeBtn">{{ 'REGISTER_NOW_BTN_TXT' | translate }}</button>
        </div>
        <p>{{ 'REGISTRATION_INFO.NUTRITION_ADVICE1' | translate }} <a class="telNum" href="tel:19001519">{{ 'REGISTRATION_INFO.CALL_CENTER' | translate }}</a> <br> {{ 'REGISTRATION_INFO.NUTRITION_ADVICE2' | translate }}</p>
        <!-- <small>{{ 'REGISTRATION_INFO.CODE' | translate }}</small> -->
        <small *ngIf="prodCategory == 'Ensure'">ENS-C-470-21</small>
        <small *ngIf="prodCategory == 'Glucerna'">GLU-C-171-21</small>
        <small *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</small>
    </div>
</div>
<app-loader *ngIf="loader"></app-loader>
<app-footer></app-footer>
<app-nav-menu></app-nav-menu>
