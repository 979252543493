<app-header></app-header>
<!-- <app-grow-simi-breadcrumb></app-grow-simi-breadcrumb>product-description -->
<div class="productGrowContainer">
    <div class="banner">
        <img src="../../../assets/images/hero-banner-grow.png">
    </div>

    <!-- grow 1 Milk section Starts -->
    <!-- Old Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1001'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.SUB_TITLE' | translate }}<br>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.SUB_TITLE1' | translate }}</p>
                    </div>
                    <figure>
                        <img class="growContainerImg" src="../../../assets/images/grow-milk.png">
                    </figure>
                    <div class="blueBorder mb-4"></div>
                    <p>Thông tin khoa học</p>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT1' | translate }} <strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT_BOLD' | translate }} </strong>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT2' | translate }}</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/height001.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain002.png">
                        </div>
                    </div>
                    <div class="alignLeft">
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT2' | translate }}</p>
                        <p> {{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT4' | translate }}</p>
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT5' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p> <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD1' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT1' | translate }}
                            <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD2' | translate }}
                            </strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT2' | translate }}</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>
                    <div class="blueBorder"></div>
                    <div class="noteSection">
                        <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                        <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                    </div>
                    <div class="content">
                        <p class="text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT1' | translate }}</p>
                        <p class="mt10 text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT2' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT3.TEXT2' | translate }} </p>
                    </div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.USES.BOLD_TEXT' | translate
                            }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.USES.DESC' | translate }}</p>
                    <figure>
                        <img class="phase1" src="../../../assets/images/phase-1-circle-tcm-1329-139408.png">
                    </figure>
                    <div class="cardContainer">
                        <div class="card bgGreen">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object1.png">
                                </div>
                                <div class="col-9">
                                    <p class="textGreen">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD1.DESC1' | translate }}</p>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object2.png">
                                </div>
                                <div class="col-9">
                                    <p class="textGreen"> {{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD1.DESC2' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgBlue">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object.png">
                                </div>
                                <div class="col-9">
                                    <p class="textBlue"> {{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD2.DESC1' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgOrange">
                            <div class="row">
                                <div class="col-3">

                                    <img src="../../../assets/images/vector-smart-object3.png">

                                </div>
                                <div class="col-9">
                                    <p class="textOrange">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD3.DESC1' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="blueBorder m30"></div>
                    <div class="ingredients">
                        <p class="text-justify">
                            <strong>
                                {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.BOLD_TEXT' | translate }}
                            </strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC1' | translate }}
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.CANXI' | translate }}</span>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC2' | translate }}
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DHA' | translate }}</span>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC3' | translate }}
                        </p>
                    </div>

                    <figure>
                        <img src="../../../assets/images/nutrition-detail.png">
                    </figure>

                    <div class="blueBorder m30"></div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.STANDARD_PREP.BOLD_TEXT' |
                            translate }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.STANDARD_PREP.DESC' | translate
                        }}</p>
                    <figure>
                        <img src="../../../assets/images/usage-table.png">
                    </figure>
                    <div class="intructions">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.DESC' | translate }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP3.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP3.DESC' | translate
                            }}</p>

                    </div>
                    <figure>
                        <img src="../../../assets/images/how-to-use-table.png">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc">
                <img src="../../../assets/images/banner_nonMilk.png">
            </div>
        </div>
    </section>


    <!-- New Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1007'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.SUB_TITLE' | translate }}<br>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.SUB_TITLE1' | translate }}</p>
                    </div>
                    <figure>
                        <img class="growContainerImg" src="../../../assets/images/grow1.png">
                    </figure>
                    <div class="blueBorder mb-4"></div>
                    <p>Thông tin khoa học</p>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT1' | translate }} <strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT_BOLD' | translate }} </strong>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT2' | translate }}</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/height001.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain002.png">
                        </div>
                    </div>
                    <div class="alignLeft">
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT2' | translate }}</p>
                        <p> {{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT4' | translate }}</p>
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT5' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p> <strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD1' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT1' | translate }}
                            <strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD2' | translate }}
                            </strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT2' | translate }}</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>
                    <div class="blueBorder"></div>
                    <div class="noteSection">
                        <p class="text-left"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                        <p class="mt10 text-left">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                    </div>
                    <div class="content">
                        <p class="text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT1' | translate }}</p>
                        <p class="mt10 text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT2' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT3.TEXT2' | translate }} </p>
                    </div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.USES.BOLD_TEXT' | translate
                            }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.USES.DESC' | translate }}</p>
                    <figure>
                        <img class="phase1 w-100" src="../../../assets/images/growbottom1.png">
                    </figure>
                    <div class="ingredients">
                        <p class="text-justify">
                            <strong>
                                {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.BOLD_TEXT' | translate }}
                            </strong>
                            {{'MILKCODE_DESC_GROW.TEXT' | translate}}
                        </p>
                    </div>

                    <figure>
                        <img src="../../../assets/images/tbl.png">
                    </figure>

                    <div class="blueBorder m30"></div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.STANDARD_PREP.BOLD_TEXT' |
                            translate }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.STANDARD_PREP.DESC' | translate
                        }}</p>
                    <p class="text-justify mt20"><strong>Số lần đề nghị sử dụng:</strong></p>
                    <figure>
                        <img src="../../../assets/images/usage-table.png">
                    </figure>
                    <div class="intructions">
                        <p class="text-justify">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.DESC' | translate }}</p>
                        <p class="text-justify"><strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.BOLD_TEXT' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP3.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP3.DESC' | translate
                            }}</p>

                    </div>
                    <figure>
                        <img src="../../../assets/images/how-to-use-table.png">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc pr">
                <img src="../../../assets/images/bannerNew.png">
                <span>* So với công thức Abbott Grow 4 cũ</span>
            </div>
        </div>
    </section>


    <!-- grow 2 Milk section Starts -->

    <!-- Old Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1002'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.SUB_TITLE' | translate }}<br>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.SUB_TITLE1' | translate }}</p>
                    </div>
                    <figure>
                        <img class="growContainerImg" src="../../../assets/images/18819-grow-2-900-gr-tuticare-1.png">
                    </figure>
                    <div class="blueBorder mb-4"></div>
                    <p>Thông tin khoa học</p>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT1' | translate }} <strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT_BOLD' | translate }} </strong>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT2' | translate }}</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/height001.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain002.png">
                        </div>
                    </div>
                    <div class="alignLeft">
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT2' | translate }}</p>
                        <p> {{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT4' | translate }}</p>
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT5' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p> <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD1' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT1' | translate }}
                            <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD2' | translate }}
                            </strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT2' | translate }}</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>
                    <div class="blueBorder"></div>
                    <div class="noteSection">
                        <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                        <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                    </div>
                    <div class="content">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT1' | translate }}</p>
                        <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT.TEXT2' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT3.TEXT2' | translate }} </p>
                    </div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.USES.BOLD_TEXT' |
                            translate
                            }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.USES.DESC' | translate }}</p>
                    <figure>
                        <img class="phase1" src="../../../assets/images/phase-1-circle-tcm-1329-139408.png">
                    </figure>
                    <div class="cardContainer">
                        <div class="card bgGreen">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object1.png">
                                </div>
                                <div class="col-9">
                                    <p class="textGreen">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD1.DESC1' | translate }}</p>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object2.png">
                                </div>
                                <div class="col-9">
                                    <p class="textGreen"> {{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD1.DESC2' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgBlue">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object.png">
                                </div>
                                <div class="col-9">
                                    <p class="textBlue"> {{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD2.DESC1' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgOrange">
                            <div class="row">
                                <div class="col-3">

                                    <img src="../../../assets/images/vector-smart-object3.png">

                                </div>
                                <div class="col-9">
                                    <p class="textOrange">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD3.DESC1' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-justify">(1) EFSA Panel on Dieteic Products, Nutrition and Allergies, EFSA journal
                        2013. 11:3408.<br>
                        (2) Raiten DJ, Talbot JM, Walter JH. Assessment of nutrient requirements for infant formulas. J
                        Nutr, 1998; 128 (11): i-iv, 20595 - 22935.<br>
                        (3) Nelson SE et al: Absorption of fat and calcium by infants fed a milk-based formula
                        containing palm olein. J Amer Coll Nutr 1998, 17:327-332.</p>
                    <div class="blueBorder m30"></div>
                    <div class="ingredients">
                        <p class="text-justify">
                            <strong>
                                {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.BOLD_TEXT' | translate }}
                            </strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC1' | translate }}
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.CANXI' | translate }}</span>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC2' | translate }}
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DHA' | translate }}</span>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC3' | translate }}
                        </p>
                    </div>

                    <figure>
                        <img src="../../../assets/images/layer-704.png">
                    </figure>

                    <div class="blueBorder m30"></div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.STANDARD_PREP.BOLD_TEXT' |
                            translate }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.STANDARD_PREP.DESC' |
                        translate
                        }}</p>
                    <figure>
                        <img src="../../../assets/images/table_grow2.png">
                    </figure>
                    <div class="intructions">
                        <p class="text-justify">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.INSTRUCTIONS.DESC' | translate
                            }}<br>
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.INSTRUCTIONS.DESC1' | translate }}</span>
                        </p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP3.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.INSTRUCTIONS.STEP3.DESC' |
                            translate
                            }}</p>

                    </div>
                    <figure>
                        <img src="../../../assets/images/table2.png">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc pr">
                <img src="../../../assets/images/banner_nonMilk.png">
            </div>
        </div>
    </section>

    <!-- New Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1008'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.SUB_TITLE' | translate }}<br>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.SUB_TITLE1' | translate }}</p>
                    </div>
                    <figure>
                        <img class="growContainerImg" src="../../../assets/images/grow2.png">
                    </figure>
                    <div class="blueBorder mb-4"></div>
                    <p>Thông tin khoa học</p>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT1' | translate }} <strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT_BOLD' | translate }} </strong>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT2' | translate }}</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/height001.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain002.png">
                        </div>
                    </div>
                    <div class="alignLeft">
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT2' | translate }}</p>
                        <p> {{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT4' | translate }}</p>
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT5' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p> <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD1' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT1' | translate }}
                            <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD2' | translate }}
                            </strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT2' | translate }}</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>
                    <div class="blueBorder"></div>
                    <div class="noteSection">
                        <p class="text-left"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                        <p class="mt10 text-left">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                    </div>
                    <div class="content">
                        <p class="text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT1' | translate }}</p>
                        <p class="mt10 text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT.TEXT2' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.CONTENT3.TEXT2' | translate }} </p>
                    </div>
                    <p class="text-center"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.USES.BOLD_TEXT' |
                            translate
                            }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.USES.DESC' | translate }}</p>
                    <figure>
                        <img class="phase1 w-100" src="../../../assets/images/growbottom1.png">
                    </figure>

                    <p class="text-justify">(1) EFSA Panel on Dieteic Products, Nutrition and Allergies, EFSA journal
                        2013. 11:3408.<br>
                        (2) Raiten DJ, Talbot JM, Walter JH. Assessment of nutrient requirements for infant formulas. J
                        Nutr, 1998; 128 (11): i-iv, 20595 - 22935.<br>
                        (3) Nelson SE et al: Absorption of fat and calcium by infants fed a milk-based formula
                        containing palm olein. J Amer Coll Nutr 1998, 17:327-332.</p>
                    <div class="blueBorder my-5"></div>
                    <div class="ingredients">
                        <p class="text-justify">
                            <strong>
                                {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.BOLD_TEXT' | translate }}
                            </strong>
                            {{'MILKCODE_DESC_GROW1.TEXT' | translate}} <i>M. alpina.</i>
                        </p>
                    </div>

                    <figure>
                        <img src="../../../assets/images/tbl1.png">
                    </figure>

                    <div class="blueBorder m30"></div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.STANDARD_PREP.BOLD_TEXT' |
                            translate }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.STANDARD_PREP.DESC' |
                        translate
                        }}</p>
                    <figure>
                        <img src="../../../assets/images/table_grow2.png">
                    </figure>
                    <div class="intructions">
                        <p class="text-justify">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.INSTRUCTIONS.DESC' | translate
                            }}<br>
                            <span><sup>(4)</sup> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.INSTRUCTIONS.DESC1' | translate }}</span>
                        </p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.BOLD_TEXT' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP1.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.BOLD_TEXT' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP2.DESC' | translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INSTRUCTIONS.STEP3.BOLD_TEXT' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_2.INSTRUCTIONS.STEP3.DESC' |
                            translate
                            }}</p>

                    </div>
                    <figure>
                        <img src="../../../assets/images/table2.png">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc pr">
                <img src="../../../assets/images/bannerNew.png">
                <span>* So với công thức Abbott Grow 4 cũ</span>
            </div>
        </div>
    </section>

    <!-- grow 3 Milk section Starts -->

    <!-- Old Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1003'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.SUB_TITLE' | translate }}<br>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.SUB_TITLE1' | translate }}</p>
                    </div>
                    <figure>
                        <img class="growContainerImg"
                            src="../../../assets/images/sua-bot-abbott-grow-3-g-power-huong-vani-900-g-5661-0289062-8-fb-31-a-3467-e-7-d-242287-b-6-ec-50790-a-734.png">
                    </figure>
                    <div class="blueBorder mb-4"></div>
                    <p>Thông tin khoa học</p>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT1' | translate }} <strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT_BOLD' | translate }} </strong>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT2' | translate }}</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/height001.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain002.png">
                        </div>
                    </div>
                    <div class="alignLeft">
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT2' | translate }}</p>
                        <p> {{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT4' | translate }}</p>
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT5' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p> <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD1' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT1' | translate }}
                            <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD2' | translate }}
                            </strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT2' | translate }}</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>
                    <div class="blueBorder"></div>
                    <div class="noteSection">
                        <p><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                        <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                    </div>
                    <div class="content">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT1' | translate }}</p>
                        <p class="mt10">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT.TEXT2' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT3.TEXT2' | translate }} </p>
                    </div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.USES.BOLD_TEXT' |
                            translate
                            }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.USES.DESC' | translate }}</p>
                    <figure>
                        <img class="phase1" src="../../../assets/images/phase-1-circle-tcm-1329-139408.png">
                    </figure>
                    <div class="cardContainer">
                        <div class="card bgGreen">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object1.png">
                                </div>
                                <div class="col-9">
                                    <p class="textGreen">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD1.DESC1' | translate }}</p>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object2.png">
                                </div>
                                <div class="col-9">
                                    <p class="textGreen"> {{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD1.DESC2' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgBlue">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/vector-smart-object.png">
                                </div>
                                <div class="col-9">
                                    <p class="textBlue"> {{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD2.DESC1' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgOrange">
                            <div class="row">
                                <div class="col-3">

                                    <img src="../../../assets/images/vector-smart-object3.png">

                                </div>
                                <div class="col-9">
                                    <p class="textOrange">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION.DETAIL_CARD.CARD3.DESC1' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-justify">(1) EFSA Panel on Dieteic Products, Nutrition and Allergies, EFSA journal
                        2013. 11:3408.<br>
                        (2) Raiten DJ, Talbot JM, Walter JH. Assessment of nutrient requirements for infant formulas. J
                        Nutr, 1998; 128 (11): i-iv, 20595 - 22935.<br>
                        (3) Nelson SE et al: Absorption of fat and calcium by infants fed a milk-based formula
                        containing palm olein. J Amer Coll Nutr 1998, 17:327-332.</p>
                    <div class="blueBorder m30"></div>
                    <div class="ingredients">
                        <p class="text-justify">
                            <strong>
                                {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.BOLD_TEXT' | translate }}
                            </strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC1' | translate }}
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.CANXI' | translate }}</span>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC2' | translate }}
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DHA' | translate }}</span>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.DESC3' | translate }}
                        </p>
                    </div>

                    <figure>
                        <img src="../../../assets/images/layer-703.png">
                    </figure>

                    <div class="blueBorder m30"></div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.STANDARD_PREP.BOLD_TEXT' |
                            translate }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.STANDARD_PREP.DESC' |
                        translate
                        }}</p>
                    <figure>
                        <img src="../../../assets/images/table_grow3.png">
                    </figure>
                    <div class="intructions">
                        <p class="text-justify">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.DESC' | translate
                            }}<br>
                            <span>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.DESC1' | translate }}</span>
                        </p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP1.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP1.DESC' |
                            translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP2.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP2.DESC' |
                            translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP3.BOLD_TEXT' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP3.DESC' |
                            translate
                            }}</p>

                    </div>
                    <figure>
                        <img src="../../../assets/images/table3.png">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc pr">
                <img src="../../../assets/images/banner_nonMilk.png">
            </div>
        </div>
    </section>

    <!-- New Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1009'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.SUB_TITLE' | translate }}<br>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.SUB_TITLE1' | translate }}</p>
                    </div>
                    <figure>
                        <img class="growContainerImg"
                            src="../../../assets/images/grow3.png">
                    </figure>
                    <div class="blueBorder mb-4"></div>
                    <p>Thông tin khoa học</p>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT1' | translate }} <strong> {{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT_BOLD' | translate }} </strong>{{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT1.TEXT2' | translate }}</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/height001.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain002.png">
                        </div>
                    </div>
                    <div class="alignLeft">
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT2' | translate }}</p>
                        <p> {{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT4' | translate }}</p>
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT5' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p> <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD1' | translate
                                }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT1' | translate }}
                            <strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT_BOLD2' | translate }}
                            </strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT2.TEXT2' | translate }}</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>
                    <div class="blueBorder"></div>
                    <div class="noteSection">
                        <p class="text-left"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.STRONG' | translate }}</strong> {{
                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE1' | translate }}</p>
                        <p class="mt10 text-left">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.NOTE.NOTE2' | translate }}</p>
                    </div>
                    <div class="content">
                        <p class="text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.CONTENT.TEXT1' | translate }}</p>
                        <p class="mt10 text-center">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT.TEXT2' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.CONTENT3.TEXT2' | translate }} </p>
                    </div>
                    <p class="text-center"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.USES.BOLD_TEXT' |
                            translate
                            }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.USES.DESC' | translate }}</p>
                    <figure>
                        <img class="phase1 w-100" src="../../../assets/images/growbottom1.png">
                    </figure>
              
                    <p class="text-justify">(1) EFSA Panel on Dieteic Products, Nutrition and Allergies, EFSA journal
                        2013. 11:3408.<br>
                        (2) Raiten DJ, Talbot JM, Walter JH. Assessment of nutrient requirements for infant formulas. J
                        Nutr, 1998; 128 (11): i-iv, 20595 - 22935.<br>
                        (3) Nelson SE et al: Absorption of fat and calcium by infants fed a milk-based formula
                        containing palm olein. J Amer Coll Nutr 1998, 17:327-332.</p>
                    <div class="blueBorder my-5"></div>
                    <div class="ingredients">
                        <p class="text-justify">
                            <strong>
                                {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION.INGREDIENTS.BOLD_TEXT' | translate }}
                            </strong>
                           {{'MILKCODE_DESC_GROW2.TEXT' | translate}} <i>C. cohnii.</i>
                        </p>
                    </div>

                    <figure>
                        <img src="../../../assets/images/tbl2.png">
                    </figure>

                    <div class="blueBorder m30"></div>
                    <p class="text-justify"><strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.STANDARD_PREP.BOLD_TEXT' |
                            translate }}</strong>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.STANDARD_PREP.DESC' |
                        translate
                        }}</p>
                    <figure>
                        <img src="../../../assets/images/table_grow3.png">
                    </figure>
                    <div class="intructions">
                        <p class="text-justify">{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.DESC' | translate
                            }}<br>
                            <span><sup>(4)</sup>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.DESC1' | translate }}</span>
                        </p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP1.BOLD_TEXT' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP1.DESC' |
                            translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP2.BOLD_TEXT' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP2.DESC' |
                            translate
                            }}</p>
                        <p class="text-justify"><strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP3.BOLD_TEXT' | translate
                                }}</strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_3.INSTRUCTIONS.STEP3.DESC' |
                            translate
                            }}</p>

                    </div>
                    <figure>
                        <img src="../../../assets/images/table3.png">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc pr">
                <img src="../../../assets/images/bannerNew.png">
                <span>* So với công thức Abbott Grow 4 cũ</span>
            </div>
        </div>
    </section>


    <!-- grow 3+ Non Milk section Starts -->
    <section class="nonMilkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1006'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.SUB_TITLE' | translate }}</p>
                    </div>
                    <figure>
                        <img class="growContainerImg" src="../../../assets/images/grow-6-hires.png">
                    </figure>
                    <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT1' | translate }}</p>

                    <div class="productContent">
                        <p>Tuổi <strong>mẫu giáo</strong> là <strong>giai đoạn vàng</strong> quyết định sự phát triển về
                            chiều cao, cân nặng và trí não của trẻ</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/height.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain.png">
                        </div>
                    </div>
                    <div class="alignLeft">
                        <p>{{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT2' | translate }}</p>
                        <p> {{ 'PRODUCT_DESC_GROW.NON_MILK_SECTION.TEXT3' | translate }}</p>
                    </div>
                    <div class="productContent">
                        <p> <strong>Dinh dưỡng</strong> đóng vai trò
                            quan trọng nhất đến sự <strong>phát triển chiều cao </strong>của bé</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>
                    <div class="productContent">
                        <p>Abbott Grow Gold 3+ - <strong>Nguồn dinh dưỡng chất lượng</strong> cho trẻ mẫu giáo, giúp bé
                            phát triển tốt chiều cao,
                            não bộ, tăng cường hấp thu và
                            sức đề kháng cho bé</p>
                    </div>
                    <figure>
                        <img class="shieldImg" src="../../../assets/images/new-g-power-shield-270519-fa-01.png">
                    </figure>
                    <div class="imgBlock_1">
                        <figure>
                            <img src="../../../assets/images/calcium.png">
                        </figure>
                    </div>
                    <div class="imgBlock_2">
                        <figure>
                            <img src="../../../assets/images/dha.png">
                        </figure>
                    </div>
                    <div class="imgBlock_3">
                        <figure>
                            <img src="../../../assets/images/nutrition-1.png">
                        </figure>
                    </div>
                    <div class="dosageBlock">
                        <h4>Cách pha chuẩn để phát triển tiềm năng cao lớn của bé</h4>
                        <p>Pha 180g bột Abbott GROW GOLD 3+ với 875 ml nước để được 1 lít hoặc 3 muỗng gạt ngang (36 g)
                            bột với 175 ml nước để được 1 ly 200 ml.</p>
                        <figure>
                            <img src="../../../assets/images/how-to.png">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc">
                <img src="../../../assets/images/product-kv-grow.png">
            </div>
        </div>
    </section>

    <!-- grow 4 Non Milk section Starts -->

    <!-- Old Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1004'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.SUB_TITLE' | translate }}</p>
                    </div>

                    <figure>
                        <img class="growContainerImg"
                            src="../../../assets/images/abbott-grow-1-900-g-min-0111-copy-3.png">
                    </figure>
                       <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_TEXT' | translate }}
                           <sup>(1)</sup>
                        {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_TEXT1' | translate }}
                       </p>
        
                       <p class="textSmall text-center">(1) Dieatary Reference Intakes (DRIs): Recommended Dietary Allowances and Adequate Intakes, Elements, Food and Nutrition Board, National Academies.</p>
                     <div class="productContent">
                        <p>Tuổi <strong>mẫu giáo</strong> là <strong> giai đoạn vàng </strong>quyết định sự phát triển về chiều cao, cân nặng và trí não của trẻ</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/70.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain.png">
                        </div>
                    </div>
                    <p class="textSmall mb-0">*Nguồn tham khào: WHO Grow Chart</p>
                    <p class="textSmall text-left mt-0">**Nguồn tham khào:https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3400202</p>
                   
                    <div class="productContent">
                        <p> <strong>Dinh dưỡng</strong> đóng vai trò quan trọng nhất đến sự <strong> phát triển chiều cao
                            </strong>của bé</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>

                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.CONTENT3.TEXT2' | translate }} </p>
                    </div>

                    <figure>
                        <img class="phase1" src="../../../assets/images/phase-1-circle-tcm-1329-139408.png">
                    </figure>
                    <div class="cardContainer">
                        <div class="card bgGreen">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/layer-16.png">
                                </div>
                                <div class="col-9">
                                    <h4 class="textGreen">Chiều cao</h4>
                                    <p class="textGreen">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD1.DESC1' | translate
                                        }}<strong>
                                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD1.STRONG' |
                                            translate }}
                                        </strong></p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgBlue">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/layer-17.png">
                                </div>
                                <div class="col-9">
                                    <h4 class="textBlue">Cân nặng và sức đề kháng</h4>
                                    <p class="textBlue"> {{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD2.DESC1' | translate }}
                                        <strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD2.STRONG' |
                                            translate }}</strong><br>
                                        {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD2.DESC2' | translate
                                        }}
                                        <strong> {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD2.STRONG1' |
                                            translate }}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card bgOrange">
                            <div class="row">
                                <div class="col-3">
                                    <img src="../../../assets/images/layer-18.png">
                                </div>
                                <div class="col-9">
                                    <h4 class="textOrange">Trí não</h4>
                                    <p class="textOrange">{{
                                        'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD3.DESC1' | translate }}
                                        <strong>{{
                                            'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_CARD.CARD3.STRONG' | translate
                                            }}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-justify">(1) EFSA Panel on Dieteic Products, Nutrition and Allergies, EFSA journal 2013. 11:3408.<br>
                        (2) Raiten DJ, Talbot JM, Walter JH. Assessment of nutrient requirements for infant formulas. J Nutr, 1998; 128 (11): i-iv, 20595 - 22935.</p>
                    
                        <div class="productContent">
                            <p>Cách pha chuẩn để phát triển</p>
                            <p>tiềm năng cao lớn của bé</p>
                        </div>
                   
                    
                    <p>Pha 180g bột Abbott GROW 4 với 875 ml nước để được 1 lít hoặc 3 muỗng gạt ngang (36 g) bột với 175 ml nước để được 1 ly 200 ml.</p>
                    
                    <figure>
                        <img src="../../../assets/images/3-ly.png" class="phase1">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc pr">
                <img src="../../../assets/images/product-kv-grow.png">
            </div>
        </div>
    </section>

    <!-- New Code -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1010'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.SUB_TITLE' | translate }}</p>
                    </div>

                    <figure>
                        <img class="growContainerImg"
                            src="../../../assets/images/grow4.png">
                    </figure>
                       <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_TEXT' | translate }}
                           <sup>(1)</sup>
                        {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.DETAIL_TEXT1' | translate }}
                       </p>
        
                       <p class="textSmall text-center">(1) Dieatary Reference Intakes (DRIs): Recommended Dietary Allowances and Adequate Intakes, Elements, Food and Nutrition Board, National Academies.</p>
                     <div class="productContent">
                        <p>Tuổi <strong>mẫu giáo</strong> là <strong> giai đoạn vàng </strong>quyết định sự phát triển về chiều cao, cân nặng và trí não của trẻ</p>
                    </div>
                    <div class="row">
                        <div class="col-6 pr5">
                            <img class="colImg" src="../../../assets/images/70.png">
                        </div>
                        <div class="col-6 pl5">
                            <img class="colImg" src="../../../assets/images/brain.png">
                        </div>
                    </div>
                    <p class="textSmall mb-0">*Nguồn tham khào: WHO Grow Chart</p>
                    <p class="textSmall text-left mt-0">**Nguồn tham khào:https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3400202</p>
                   
                    <div class="productContent">
                        <p> <strong>Dinh dưỡng</strong> đóng vai trò quan trọng nhất đến sự <strong> phát triển chiều cao
                            </strong>của bé</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>

                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.CONTENT3.TEXT1' | translate }}<br> <strong>{{
                                'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.CONTENT3.TEXT_BOLD' | translate }}</strong>
                            {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_4.CONTENT3.TEXT2' | translate }} </p>
                    </div>

                    <figure>
                        <img class="phase1 w-100" src="../../../assets/images/growbottom4.png">
                    </figure>

                    
                        <div class="productContent">
                            <p>Cách pha chuẩn để phát triển</p>
                            <p>tiềm năng cao lớn của bé</p>
                        </div>
                   
                    
                    <p>Pha 180g bột Abbott GROW 4 với 875 ml nước để được 1 lít hoặc 3 muỗng gạt ngang (36 g) bột với 175 ml nước để được 1 ly 200 ml.</p>
                    
                    <figure>
                        <img src="../../../assets/images/3-ly.png" class="phase1">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc pr">
                <img src="../../../assets/images/bannerNew.png">
                <span>* So với công thức Abbott Grow 4 cũ</span>
            </div>
        </div>
    </section>

    <!-- grow 6+ Non Milk Section starts -->
    <section class="milkCode" *ngIf="(milkCode == '1' || milkCode == '0') && subCatCode == '1005'">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titleContainer">
                        <h4>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.TITLE' | translate }}</h4>
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.SUB_TITLE' | translate }}</p>
                    </div>

                    <figure>
                        <img class="growContainerImg" src="../../../assets/images/grow6+.png">
                    </figure>
                    <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.DETAIL_TEXT' | translate }}
                        <sup>(1)</sup>
                        {{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.DETAIL_TEXT1' | translate }}
                    </p>
        
                    <p class="textSmall text-center">(1) Dieatary Reference Intakes (DRIs): Recommended Dietary Allowances and Adequate Intakes, Elements, Food and Nutrition Board, National Academies.</p>
                                
                    <div class="productContent">
                        <p> <strong>Dinh dưỡng</strong> đóng vai trò quan trọng nhất đến sự <strong> phát triển chiều cao
                            </strong>của bé</p>
                    </div>
                    <figure>
                        <img src="../../../assets/images/nutrition.png">
                    </figure>

                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.TEXT1' | translate }}</p>
                    </div>
                    <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.TEXT2' | translate }}</p>
                    <figure>
                        <img src="../../../assets/images/new-g-power-shield-270519-fa-01.png" class="phase1">
                    </figure>
                    <figure>
                        <img src="../../../assets/images/calcium.png">
                    </figure>
                    <figure>
                        <img src="../../../assets/images/dha.png">
                    </figure>
                    <figure>
                        <img src="../../../assets/images/nutrition-1.png">
                    </figure>
                    <div class="productContent">
                        <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.STANDARD_TEXT' | translate }}</p>
                    </div>
                    <p>{{ 'PRODUCT_DESC_GROW.MILK_CODE_SECTION_6.USES_TEXT' | translate }}</p>
                    <figure>
                        <img src="../../../assets/images/3-ly.png" class="phase1">
                    </figure>
                </div>
            </div>
        </div>
        <div class="growWidget mt40">
            <div class="growContainerDesc">
                <img src="../../../assets/images/product-kv-grow.png">
            </div>
        </div>
    </section>

    <div class="manufacturerShareAndCareContainer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-0 careShareText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.CARE_AND_SHARE' | translate }}</h3>
                    <h3 class="homeText text-uppercase">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.HOME' | translate }}</h3>
                    <p>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE1' | translate }}
                        <br><strong>{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.MAIN_TITLE_STRONG' | translate }}</strong>
                    </p>
                    <button class="orangeBtn" (click)="gotoHome()" routerLink="/sign-up/0">{{ 'MANUFACTURING_SHARE_AND_CARE_INFO.JOIN_BUTTON' | translate }}</button>
                    <p>
                        {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE1' | translate }} <strong>{{
                            'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE_STRONG' | translate }} </strong><br>
                        {{ 'MANUFACTURING_SHARE_AND_CARE_INFO.SUB_TITLE2' | translate }}
                        <!-- Gow 1 , 3+  -->
                        <small *ngIf="((milkCode == '1' || milkCode == '0') && subCatCode == '1006')"> GRO-C-264-20</small>

                        <!-- Gow 2, 3,   -->
                        <small *ngIf="((milkCode == '1' || milkCode == '0') && subCatCode == '1001') || ((milkCode == '1' || milkCode == '0') && subCatCode == '1002') || ((milkCode == '1' || milkCode == '0') && subCatCode == '1003') || ((milkCode == '1' || milkCode == '0') && subCatCode == '1004')"> GRO-C-125-21</small>

                        <!-- Gow 4, 6   -->
                        <small *ngIf="((milkCode == '1' || milkCode == '0') && subCatCode == '1005')"> GRO-C-293-20</small>
                         
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
<app-nav-menu></app-nav-menu>