<footer class="footer">
    <div class="footerContainer">
        <div class="footerTopSection mb-4">
            <div>
              <p class="text-center fs14 font-yellow fw-bolder">
                Mua sản phẩm, quét mã QR và tham gia chương trình tại:
              </p>
            </div>
            <div class="row fs10 d-flex justify-content-center mb-2">
              <div class="col-5">
                <div>
                  <p class="text-center">Kênh thương mại điện tử</p>
                </div>
                <div class="d-flex justify-content-center">
                  <a>
                    <img src="../../../assets/images/laz.png" class="mx-2" />
                  </a>
                  <a>
                    <img src="../../../assets/images/sbag.png" />
                  </a>
                  <a>
                    <img src="../../../assets/images/tiki.png" class="mx-2" />
                  </a>
                </div>
              </div>
              <div class="col-3 centerSection">
                <div class="d-block">
                  <p class="text-center">Siêu thị</p>
                  <div class="d-flex justify-content-center">
                    <a>
                      <img src="../../../assets/images/cartBlack.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-4 lastSection">
                <div class="d-block text-center">
                  <p class="text-center">Cửa hàng bán lẻ</p>
                  <div class="d-flex justify-content-center">
                    <a>
                      <img src="../../../assets/images/shopBlack.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <img src="../../../assets/images/abbot-logo-copy-3.png" alt="logo">
                </div>
                <div class="col-6">
                    <!-- <p class="text-right">{{ 'FOOTER.FOOTER_TOP_CODE' | translate }}</p> -->
                    <p class="text-right" *ngIf="prodCategory == 'Ensure'">ENS-C-506-21</p>
                    <p class="text-right" *ngIf="prodCategory == 'Glucerna'">GLU-C-200-21</p>
                    <p class="text-right" *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="m20">{{ 'FOOTER.FOOTER_TEXT1' | translate }}</p>

                    <p>{{ 'FOOTER.FOOTER_TEXT2' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</footer>