<app-header></app-header>
<section class="signUpContainer">
    <section class="formContainer pt80">
        <img class="img-fluid centeredImg" src="../../../assets/images/check.png" />

        <h2 class="darKBlueBoldTitle mb-0 text-center">{{ 'SIGNUP_SUCCESS.TITLE1' | translate }}</h2>
        <img class="img-fluid shareCareImg" src="../../../assets/images/care&share-01.png" />
        <p class="textGrey text-center mb-0">{{ 'SIGNUP_SUCCESS.PARA' | translate }} {{ 'SIGNUP_SUCCESS.PARA1' | translate }}</p>
    </section>
</section>
<app-dynamic-footer code="COR-C-12-21"></app-dynamic-footer>

