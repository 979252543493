import { Component, OnInit } from '@angular/core';
import { Itraceability } from 'src/app/models/itraceability';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home-similac',
  templateUrl: './home-similac.component.html',
  styleUrls: ['./home-similac.component.scss']
})
export class HomeSimilacComponent implements OnInit {
  scancodeData = null;
  prodCategory:string;
  scanScode:any
  loader:boolean = false;
  iSprintData: any;
  milkCode: any;

  traceabilityContent: {};
  productImage: String;
  content = [];
  primaryProductTitle: String;
  primaryShortProductInfo: String;
  productCode: String;
  factoryTitleInfo: String;
  batchCode: String;
  manufacturedDate: String;
  expiryDate: String;
  entranceDate: String;
  productName:  String;
  factoryShortProductDescription: String;
  manufactCountry: string;
  constructor(private traceabilityService: ApiService, private route:ActivatedRoute) { 
    window.scrollTo({top: 0});
    this.iSprintData = JSON.parse(sessionStorage.getItem("iSprintData"));
    this.prodCategory = this.iSprintData?.category_code;

    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.loader = true;
    // this.scanScode = this.route.snapshot.queryParams.u
    // this.traceabilityService.getTraceability(this.scanScode).subscribe((res: any) => {
    //   sessionStorage.setItem('productdata', JSON.stringify(res.result.data));
    //   this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //   this.prodCategory = this.scancodeData.product.categoryCode;
    //   this.getTraceabilityContent();
    //   this.loader = false;
    // },err=>{
    //   if(err){
    //     this.loader = false;
    //   }
    // })
    // this.prodCategory = this.scancodeData?.product?.categoryCode;
  }


  ngOnInit(): void {
    this.getTraceabilityContent();
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  getTraceabilityContent() {
    this.batchCode = this.iSprintData.batch_number
    this.manufacturedDate = this.iSprintData.manufacture_date
    this.expiryDate = this.iSprintData.expiry_date
    this.manufactCountry = this.iSprintData.origin?.en_US
      this.milkCode = this.iSprintData.is_milk_code
      this.entranceDate = this.iSprintData.vietnam_entrance_date
      this.prodCategory = this.iSprintData.category_code;
      console.log(this.prodCategory, this.milkCode)


    if(this.iSprintData.is_isprint == true){
      console.log(this.productImage, this.iSprintData.product_image)
      // this.productImage = this.iSprintData.product_image
      this.primaryProductTitle = this.iSprintData?.product_name?.vi_VN;
      this.primaryShortProductInfo = this.iSprintData?.product_info?.vi_VN
      this.factoryTitleInfo = this.iSprintData?.factory_description?.vi_VN
      this.factoryShortProductDescription = this.iSprintData?.short_factory_description?.vi_VN

    }else{
      this.primaryProductTitle = this.iSprintData?.product_description;
      this.primaryShortProductInfo = this.iSprintData?.product_info?.vi_VN;
      let factory = this.iSprintData?.factory_description_vn.split("\n\n");
      this.factoryTitleInfo = factory[0];
      this.factoryShortProductDescription = factory[1];
    
    }
    
  //     let resultData = this.scancodeData
  //     this.productImage = resultData.product_image;
  //     this.content = resultData.product.txt;
  //     this.content.forEach((item: any) => {
        

  //       // product code 
  //       this.productCode = resultData.product.productCode;

  //       if (item.orders == 1) {
  //         let parsedPrimaryValue = this.parseFunction(item.value);
  //         this.primaryProductTitle = parsedPrimaryValue.vi_VN;
  //         let parsedProductName = this.parseFunction(item.name);
  //         this.productName = parsedProductName.vi_VN;
  //       }

  //       if (item.orders == 2) {
  //         let countryCode = this.parseFunction(item.value);
  //         this.manufactCountry = countryCode.vi_VN;
  //       }

  //       if (item.orders == 8) {
  //         let parsedShortProductInfo = this.parseFunction(item.value);
  //         this.primaryShortProductInfo = parsedShortProductInfo.vi_VN;
  //       }

  //       if (item.orders == 3) {
  //         let parsedFactoryInformation = this.parseFunction(item.value);
  //         this.factoryTitleInfo = parsedFactoryInformation.vi_VN;
  //       }

  //       // this is for batch code
  //       if (item.orders == 5) {
  //         let parsedBatchCode = this.parseFunction(item.value);
  //         this.batchCode = parsedBatchCode.value;
  //       }

  //       if (item.orders == 6) {
  //         let parsedDate = this.parseFunction(item.value);
  //         this.manufacturedDate = parsedDate.value;
  //       }

  //       if (item.orders == 7) {
  //         let parsedExpiryDate = this.parseFunction(item.value);
  //         this.expiryDate = parsedExpiryDate.value;
  //       }

  //       if (item.orders == 4) {
  //         let parsedEntranceDate = this.parseFunction(item.value);
  //         this.entranceDate = parsedEntranceDate.value;
  //       }

  //       if (item.orders == 9) {
  //         let parsedFactoryShortDescription = this.parseFunction(item.value);
  //         this.factoryShortProductDescription = parsedFactoryShortDescription.vi_VN;
  //       }
  //     });
  
  }
}
