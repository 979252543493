<app-header></app-header>
<!-- <app-dynamic-no-animate-breadcrumb textone="Trang chủ" texttwo="Quay số may mắn"></app-dynamic-no-animate-breadcrumb> -->
<section class="luckyDrawQuotaExceed">
    <h1 *ngIf="prodCategory == 'Grow'">{{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE' | translate }} {{GrowCount}} {{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE1' | translate }}</h1>
    <h1 *ngIf="prodCategory == 'Similac'">{{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE' | translate }} {{SimilacCount}} {{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE1' | translate }}</h1>
    <h1 *ngIf="prodCategory == 'Ensure'">{{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE' | translate }} {{EnsureCount}} {{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE1' | translate }}</h1>
    <h1 *ngIf="prodCategory == 'Glucerna'">{{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE' | translate }} {{GlucernaCount}} {{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE1' | translate }}</h1>
    <h1 *ngIf="prodCategory == 'PediaSure'">{{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE' | translate }} {{PediasureCount}} {{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_TITLE1' | translate }}</h1>
    <p>{{ 'LUCKY_DRAW_QUOTA_EXCEED.LUCKY_DRAW_DESC' | translate }}</p>
    <div class="links">
        <a href="javascript:;" class="orangeBtn" routerLink="/user-profile">{{ 'LUCKY_DRAW_QUOTA_EXCEED.VIEW_PROFILE' | translate }}</a>
        <a href="javascript:;" (click)="openSimilacTermsandConditions()" class="seeMoreBtn">{{ 'LUCKY_DRAW_QUOTA_EXCEED.SEE_MORE_RULES' | translate }}</a>
    </div>
    <div class="questionOut">
        <h6>{{ 'LUCKY_DRAW_QUOTA_EXCEED.QUESTIONS' | translate }}</h6>
        <p>{{ 'LUCKY_DRAW_QUOTA_EXCEED.CUSTOMER_SERVICE' | translate }}</p>
        <a class="telNum" href="tel:19001519">19001519 </a>
    </div>
</section>
<div bsModal #termsSimilac="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name" [config]="config">
    <div class="modal-dialog modal-sm">
        <div class="modal-content videoModalContainer">
            <div class="modal-body rewardsLoginOut px-0">
                <section class="similacTermsMdl text-center">
                    <button type="button" class="close pull-right closeBtn" aria-label="Close"
                        (click)="termsSimilacHide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 class="text-uppercase textSecondaryBlue">Thể lệ chương trình</h3>

                    <div class="giftList p-0 mt-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="grow-tab1" 
                                    data-toggle="tab" href="#grow1" role="tab" aria-controls="home"
                                    aria-selected="true">{{ 'REWARDS_LOGIN.ABBOTT_GROW' |
                                    translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="similac-tab1"
                                    data-toggle="tab" href="#similac1" role="tab" aria-controls="profile"
                                    aria-selected="false">{{ 'REWARDS_LOGIN.SIMILAC'
                                    | translate }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="pediasure-tab1"
                                    data-toggle="tab" href="#pediasure1" role="tab" aria-controls="profile"
                                    aria-selected="false">PediaSure</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="ensure-tab1"
                                    data-toggle="tab" href="#ensure1" role="tab" aria-controls="profile"
                                    aria-selected="false">Ensure Gold</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="glucerna-tab1"
                                    data-toggle="tab" href="#glucerna1" role="tab" aria-controls="profile"
                                    aria-selected="false">Glucerna</a>
                            </li>
                           
                        </ul>
                        <div class="tab-content" id="myTabContent1">
                            <div id="grow1" role="tabpanel" aria-labelledby="grow-tab1" class="tab-pane fade show active" >
                                <div class="innerContent">
                                    <section *ngIf="growtermsContent" [innerHTML]="growtermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="similac1" role="tabpanel" aria-labelledby="similac-tab1" class="tab-pane fade show">
                                <div class="innerContent">
                                    <section *ngIf="termsContent" [innerHTML]="termsContent | safe: 'html'"></section>
                                </div>
                            </div>
                            <div id="pediasure1" role="tabpanel" aria-labelledby="pediasure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="pediasureltermsContent" [innerHTML]="pediasureltermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="ensure1" role="tabpanel" aria-labelledby="ensure-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="ensuretermsContent" [innerHTML]="ensuretermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                            <div id="glucerna1" role="tabpanel" aria-labelledby="glucerna-tab1" class="tab-pane fade show" >
                                <div class="innerContent">
                                    <section *ngIf="glucernatermsContent" [innerHTML]="glucernatermsContent | safe: 'html'"></section>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<app-auth-footer></app-auth-footer>
<app-nav-menu></app-nav-menu>